import React,{ createContext, useState,useContext, useEffect } from 'react';
import io from 'socket.io-client';
import { SocketEventsEnum } from '../helpers/enums/SocketEventsEnum';
import { ChatContext } from './ChatContext';
import {GlobalNotificationContext} from "./GlobalNotification";

export const SocketContext = createContext();
export const SocketProvider = ({ children }) =>{
  const {notifications,setNotifications,desplegateNotification} = useContext(GlobalNotificationContext);    
  const [socket,setSocket] = useState(
    null
  )
  const [isConnected, setIsConnected] = useState(socket ? socket.connected : false);
  
  const newNotification = (callback) =>{
    socket.on('server:get_new_notification', (data) => { 
      console.log('notify')                   
      desplegateNotification(data)    
    })
  }

  const notificationCount = (callback) => {            
    socket.on('server:load_unread', (data) => { 
        
      callback(data.notifications);         
    });    
  }

  const loadUnreadLandRequests = (landRequestId, callback) => {        
    socket.on(`${SocketEventsEnum.LOAD_UNREAD_LAND_REQUESTS}${landRequestId}`, (data) => {
      callback(data);         
    });
  }

  const loadLandRequest = (userId, landRequestId) => {
    socket.emit(SocketEventsEnum.LOAD_LAND_REQUEST, JSON.stringify({userId, landRequestId}))
  }

  const setLandRequestAsViewed = (userId, landRequestId, landsId) => {
    socket.emit(`${SocketEventsEnum.SET_LAND_REQUEST_AS_VIEWED}${landRequestId}`, JSON.stringify({userId, landRequestId, landsId}))
  }

  const viewUserStatus = (userId, routeView, chatId) => {
    socket.emit(`${SocketEventsEnum.SET_USER_STATUS}`, JSON.stringify({userId, routeView, chatId}));
  }

  const listenChat = (callback) =>{    
    socket.on('server:get_new_message_chat', callback);
    return () => socket.off('server:get_new_message_chat', callback);
  }
  const listenMessagesChat = (id) =>{
    socket.emit('join-chat',id);    
  }
  const leaveMessagesChat = () =>{
    socket.emit('leaves-chat','');    
  }
  const  disconnect = () =>{
    if(socket){
      socket.disconnect();
    }
  }

  const connect = () => {
    setSocket(
      io(
        process.env.REACT_APP_SOCKET_URL,
        {    
          extraHeaders: {
            "authorization": localStorage.getItem('authorization')?
            localStorage.getItem('authorization'):'',
          },    
        }
      )
    )
    return socket?socket.connect:false;
  }

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    if(socket){
      socket.on('connect', onConnect);
      socket.on('disconnect', onDisconnect);
    }

  }, [socket]);

  return(
    <SocketContext.Provider value={{
      socket,
      isConnected,
      setSocket,
      newNotification,
      notificationCount,
      loadUnreadLandRequests,
      loadLandRequest,
      setLandRequestAsViewed,
      viewUserStatus,
      connect,
      disconnect,
      listenChat,
      listenMessagesChat,
      leaveMessagesChat}}>
      {children}
    </SocketContext.Provider>
  )

}



  
