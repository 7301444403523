import React from 'react'
import HeaderRegister from '../../components/HeaderRegister/HeaderRegister'
import StepSelector from '../../components/Register/StepSelector'
import { useRegister } from '../../context/RegisterContext'
import InfoPersonalDesarrollador from '../../components/InfoPersonalDesarrollador/InforPersonalDesarrollador'
import InfoPersonal from '../InfoPersonal/InfoPersonal'
import InfoPersonal1 from '../RegisterDesarrollador/InfoPersonal1'
import InfoEmpresarialOne from '../RegisterDesarrollador/InfoEmpresarial'
import InfoEmpresarialTwo from '../RegisterDesarrollador/InfoEmpresarial2'
import TypeCorredor from '../RegisterCorredor/RegisterCorredor'
import UserModelsEnum from '../../helpers/enums/UserModelsEnum'

export default function RegisterUserData() {
  const { step, userType } = useRegister()
  return (
    <>
      <HeaderRegister />
      <StepSelector step={step} userType={userType} />

      {userType === UserModelsEnum.Developer && step === 1 && (
        <InfoPersonalDesarrollador />
      )}
      {userType === UserModelsEnum.Developer && step === 2 && <InfoPersonal1 />}
      {userType === UserModelsEnum.Developer && step === 3 && <InfoEmpresarialOne />}
      {userType === UserModelsEnum.Developer && step === 4 && <InfoEmpresarialTwo />}
      {userType === UserModelsEnum.Broker && step === 1 && <TypeCorredor />}
      {userType === UserModelsEnum.Broker && step === 2 && <InfoPersonal />}
    </>
  )
}
