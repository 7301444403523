import Request from './Request';

export default async function sendSupportMessage(msg) {
   
    try {
        const response = await Request.post(`/supports`, msg);
        return response;
    } catch (error) {
        error && console.error(error);
        return error;
    }
    
  }