import React, {useState} from 'react';
import { Badge, Popover } from 'antd';
import './index.css';

function NotePopOver({note, dayMoment}) {
    
    return <Popover style={{zIndex:1051}} content={note.comment} title="Recordatorio"><div className="ant-picker-cell-inner">
        <Badge dot={true}>{dayMoment.date()}</Badge>
    </div></Popover>;
}

export default NotePopOver
