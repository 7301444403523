import React, {useState, useEffect} from 'react'
import ProfileBanner from '../../components/ProfileBanner'
import FormEditDesarrollador from '../../components/FormEditDesarrollador/FormEditDesarrollador'
import FormEditCorredor from '../../components/FormEditCorredor/FormEditCorredor'
import FormEditSUDOADMIN from '../../components/FormEditSUDO&ADMIN/'
import UserModelsEnum from '../../helpers/enums/UserModelsEnum'
import { useAuth } from '../../context/AuthContext'
import DeveloperStatesEnum from '../../helpers/enums/DeveloperStatesEnum'
import { Modal } from 'antd'
import { WarningOutlined } from '@material-ui/icons'
import { ButtonApp } from '../../components/button/button';

export default function Profile() {
  const { user, userData } = useAuth()
  const [visible, setVisible] = useState(false)
  useEffect(()=>{
    if(userData && userData.state === DeveloperStatesEnum.Validation){
      setVisible(true)
    }else{
      setVisible(false)
    }
    
  }, [userData])
  const handleVisible = () =>{
    setVisible(false)
  }
   const warning = () => {
    Modal.warning({
      title: 'Apreciado desarrollador.',
      content: 'Usted se encuentra en el proceso de validación URBAN-360, esto puede tomar aproximadamente de 3 a 4 días. Tan pronto se surta el proceso se notificará su correo electrónico',
    });
  };
  return (
    <div className='profile'>
      <ProfileBanner />
      {user && (user.model === UserModelsEnum.SUDO || user.model === UserModelsEnum.ADMIN) && <FormEditSUDOADMIN />}
      {user && user.model === UserModelsEnum.Developer && <FormEditDesarrollador />}
      {user && user.model === UserModelsEnum.Broker && <FormEditCorredor />}

      {userData && userData.state === DeveloperStatesEnum.Validation && 
      <Modal
      closable={false}
      visible={visible} 
      footer={
        <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ButtonApp content='Ok' primary onClick={handleVisible} />
        </div>
      } 
      warning> 
      <div style={{display: 'flex'}}>
        <WarningOutlined style={{color: '#FFA900', marginRight: '5px'}}/>
        <h5>Apreciado desarrollador</h5>
      </div>
      <p>
        'Usted se encuentra en el proceso de validación URBAN-360, 
        esto puede tomar aproximadamente de 3 a 4 días. 
        Tan pronto se surta el proceso se notificará su correo electrónico' 
      </p>
      </Modal>}
    </div>
  )
}
