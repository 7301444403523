import Request from './Request';

export default async function getLandsOffered(filter, brokerId) {
   
    try {
        const response = await Request.get(`/lands/offered/by-associate/${brokerId}`, { ...filter, showDetailsParam: 0, stateParam: filter.stateParam || "ON_SALE,BOUGHT,PAUSED,RETIRED,SOLD_OUT"});
        return response;       
        
    } catch (error) {
        error && console.error(error)
        return error
    }
    
  }