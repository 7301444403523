import React, {useState, useEffect, useCallback, useContext} from 'react';
import { Drawer, Input, Collapse, Avatar, Tooltip, Pagination, Button, Badge } from 'antd';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';

import './AssociateDrawer.css';
import HttpResponseEmun from '../../helpers/enums/HttpResponseEmun';
import { useAuth } from '../../context/AuthContext';
import UserModelsEnum from '../../helpers/enums/UserModelsEnum';
import getPicture from '../../helpers/getPicture';
import { ProfileModalContext } from '../../context/ProfileModalContext';
import ProfileModal from '../ProfileModal/ProfileModal';
import { getAllDevelopers } from '../../services/developers';
import SkeletonAssociateBroker from '../AssociateBrokerDrawer/SkeletonAssociateBroker/SkeletonAssociateBroker';
import AssociatedLandRequests from './AssociatedLandRequests/AssociatedLandRequests';

import iconChat from '../../assets/icons/iconChat.svg';
import { ChatContext } from '../../context/ChatContext';
import BlockButton from '../BlockButton/BlockButton';
import { associateStates } from '../../services/allies';
import UnblockButton from '../UnblockButton/UnblockButton';

const { Panel } = Collapse;

function AssociateDrawer({show, closeDrawer}) {

    const [drawerWidth, setDrawerWidth] = useState("640px");

    const [developersLoading, setDevelopersLoading] = useState(true);
    
    const [searchParam, setSearchParam] = useState(null);
    const [developers, setDevelopers] = useState([]);

    const [filter, setFilter] = useState({page: 0, showRows: 10, statusParam: 'ACTIVE' });

    const {userData} = useAuth();
    const { setProfileData } = useContext(ProfileModalContext);

    const { createAssociateChat, setBrokerAssociate, setDeveloperAssociate } = useContext(ChatContext);

    const viewProfile = (e, developer) => {
        e.stopPropagation();
        setProfileData({
            show: true,
            profileData: developer
        });
    }

    const getDevelopersCallback = useCallback(async () => {
        setDevelopersLoading(true);
        const response = await getAllDevelopers(filter);
        if (response.code === HttpResponseEmun.OK) {
            setDevelopers(response.data);
            setDevelopersLoading(false);
        }
    }, [filter, userData]);

    const refreshData = useCallback(() => {
        applyFilterCallback(searchParam);
    }, [searchParam]);

    const applyFilterCallback = useCallback(async (searchParam) => {
        setDevelopersLoading(true);
        const response = await getAllDevelopers({...filter, searchParam});
        if (response.code === HttpResponseEmun.OK) {
            setDevelopers(response.data);
            setDevelopersLoading(false);
        }
    }, [filter]);

    useEffect(() => {
        if(show){
            getDevelopersCallback();
        }
    }, [show, filter]);

    useEffect(() => {
      const debounceTimer = setTimeout(() => {
        if(searchParam !== null){
          applyFilterCallback(searchParam);
        }
      }, 800);
      return () => clearTimeout(debounceTimer);
    }, [searchParam]);

    useEffect(() => {
        if(window.innerWidth < 535) setDrawerWidth('100%');
    }, [window.innerWidth]);

    const genExtra = (developer) => {

        if(developer.associatedUserState === associateStates.inactive || developer.associatedUserState === associateStates.inactiveState){
            return <>
                <UnblockButton style={{paddingLeft: '.5rem'}} name={developer.contactPerson} userId={developer.admin._id} refresh={refreshData} />
                <Badge status='error' text='Bloqueado'></Badge>
            </>
        }

        return <><Tooltip title='Contactar'><Button 
        type="link" 
        icon={<img src={iconChat} style={{width: '30px'}}/>}
        className='button-row'
        onClick={e => {
            e.stopPropagation();
            setBrokerAssociate(userData.user);
            setDeveloperAssociate({...developer, associateType: developer._id});
            createAssociateChat();
        }}                        
      /></Tooltip>
      <BlockButton style={{paddingLeft: '.5rem'}} name={developer.contactPerson} userId={developer.admin._id} refresh={refreshData} />
      </>

    }

    return (
        <>
          <Drawer 
          width={drawerWidth} 
          title="Desarrolladores Aliados" 
          placement="right" 
          onClose={closeDrawer} 
          visible={show}
          headerStyle={{color: '#fff'}}>
            <Input onChange={e => setSearchParam(e.target.value)} value={searchParam} name="search" type="search" autoFocus style={{backgroundColor: "transparent"}} suffix={
            <SearchOutlined className="site-form-item-icon search-input-icon" />} placeholder="Buscar aliado" />
            
            {developersLoading ? 
                <SkeletonAssociateBroker />
            : null}

            {!developersLoading && <Collapse accordion className='mt-3 associate-collapse' expandIconPosition="end">
                {developers.content && developers.content.map((developer) => {

                const isActive = developer.associatedUserState === associateStates.active || developer.associatedUserState === associateStates.activeState;

                if(developer && developer.publicProfile === false) return null;
                
                return <Panel className={`developer-${developer.associatedUserState}`} extra={genExtra(developer)} collapsible={isActive ? '' : 'disabled'} header={
                    <>
                    {
                        isActive ? (
                            <Tooltip title="Ver perfil">
                                <Avatar onClick={(e) => viewProfile(e, developer)} style={{marginRight: '1rem'}} className="requestLand-avatar" icon={<UserOutlined />} src={getPicture(developer._id, developer.logo, UserModelsEnum.Developer)} size={30}/>
                            </Tooltip>
                        ) : (
                            <Avatar style={{marginRight: '1rem'}} className="requestLand-avatar" icon={<UserOutlined />} src={getPicture(developer._id, developer.logo, UserModelsEnum.Developer)} size={30}/>
                        )
                    }
                    {developer.contactPerson}
                    </>
                } key={developer._id}>
                    <AssociatedLandRequests developerId={developer._id} />
                </Panel>})}
            </Collapse>}

            <Pagination
                current={filter.page +1}
                onChange={(current, pageSize)=> setFilter({...filter, page: current -1})}
                defaultCurrent={1}
                total={developers.totalElements}
                showTotal={(total) => `Total ${total}`}
                responsive
                disabled={developers.totalElements >  developers.size ? false : true}
            />

          </Drawer>
          <ProfileModal />
        </>
      );
}

export default AssociateDrawer