import Request from './Request';

export default async function getChatById (chatId) {
    try{
        const response = await Request.get(`/chats/${chatId}`);
        return response;
    } catch (error) {
        error && console.error(error)
        return error
    }
}