import React, {useContext, useState, useEffect} from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {InfoCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, EyeOutlined} from '@ant-design/icons'
import {Context} from '../../../../../../context/lotsContext'
import CustomInput from '../../../../../CustomInput'
import { Col, Row, Select, Switch, Button, message, Upload, Space, Spin } from 'antd';
import uploadFileIcon from '../../../../../../assets/icons/uploadFileIcon.png'
import loadingFileIcon from '../../../../../../assets/icons/loadingFileIcon.png'
import loadedFileIcon from '../../../../../../assets/icons/loadedFileIcon.png'
import { useAuth } from '../../../../../../context/AuthContext'
import Request from '../../../../../../services/Request'
import ViewFile from '../../../../../ViewFile/ViewFile'
import CustomInputLand from '../../../../../CustomInputLand'
const { Option } = Select;

const props = {
  name: 'file',
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  headers: {
    authorization: 'authorization-text',
  },

  onChange(info) {
    if (info.file.status !== 'uploading') {
     
    }

    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const Spinner = ({status, file}) => {
  if (status === 1 && file) {
    return <div className='fileName'><Space size="middle"><Spin size="small" /></Space></div>
  } else if (status === 2) {
    return <div className='fileName'><span><CheckCircleOutlined  style={{color: '#00B74A'}}/>Enviada</span></div>
  }else if (status === 3) {
    return <div className='fileName'><span><CloseCircleOutlined  style={{color: '#F93154'}}/>Error</span></div>
  } else {
    return <div className='fileName'><span>{file}</span></div>
  }

}

const loadingInitialValue = {FPCT: false, FIT: false, FE: false, FCTL: false}

const LegalInformation = () => {
  const {
    state: { dataNewLots, AdditionalData, filesToUpload },
    dispatch,
  } = useContext(Context)
  const [files, setFiles] = useState({...filesToUpload})
  const [statusFile, setStatusFiles] = useState({FPCT: 0, FIT: 0, FE: 0, FCTL: 0})
  const [loading, setLoading] = useState(loadingInitialValue)
  const { user, userData } = useAuth()

  
  const onChangeFile = (e) => {
    setLoading({...loading, [e.target.name]: true})
    if (!e.target.files[0]) {
      setLoading(loadingInitialValue)
      return
    }
    const file = e.target.files[0]
    file.nameStep = e.target.name
    setTimeout(() => {
      setFiles({...files, type:{ ...files.type, [e.target.name]: file}})
      setLoading({...loading, [e.target.name]: false})
         }, 1000);
    
  }

  useEffect(() => {
    dispatch({type: 'SET_FILES', payload: files })
  } , [files])

  const getValuesDinamicInputs = (e) => {
    dispatch({type: 'SET_ADDITIONAL_DATA', payload: e})
     }
  return (
    <div className='container-legal-information'>
      <div className='container-fluid container-global'>
          
            <Row>
              <Col xs={{ span: 24}} md={{ span: 10, offset: 1 }}>
                  <div className='container block-from'>
                    <div className='form-row text-left mt-2'>
                      <label className='tit-input'>
                        Permiso del propietario para comercializar el Terreno
                        <span className='text-danger'> *</span>
                      </label>
                      <div className='form-group'>
                        <label
                          className={`tit-Archivobtn ${
                            loading.FPCT ? 'loading' : files.type.FPCT ? 'loaded' : 'upload'
                          }`}
                          htmlFor='FPCT'
                        >
                          <img
                            src={
                              loading.FPCT
                                ? loadingFileIcon
                                : files.type.FPCT
                                ? loadedFileIcon
                                : uploadFileIcon
                            }
                            className='imgDentroBtn'
                          />
                          {loading.FPCT
                            ? 'Cargando archivo'
                            : files.type.FPCT
                            ? 'Carga exitosa'
                            : 'Subir archivo'}
                            <ViewFile file={files.type.FPCT} show={!loading.FPCT && files.type.FPCT} />
                        </label>
                        <input
                          name='FPCT'
                          id='FPCT'
                          className= 'input-file'
                          type='file'
                          accept='application/pdf'
                          onChange={onChangeFile}
                        />
                      </div>
                      <Spinner status={files.status.FPCT} file={files.type.FPCT && files.type.FPCT.name} />
                    </div>
                    <div className='form-row text-left mt-2'>
                      <label className='tit-input'>
                        Información topográfica
                        <span className='text-danger'> *</span>
                      </label>
                      <div className='form-group'>
                        <label
                          className={`tit-Archivobtn ${
                            loading.FIT ? 'loading' : files.type.FIT ? 'loaded' : 'upload'
                          }`}
                          htmlFor='FIT'
                        >
                          <img
                            src={
                              loading.FIT
                                ? loadingFileIcon
                                : files.type.FIT
                                ? loadedFileIcon
                                : uploadFileIcon
                            }
                            className='imgDentroBtn'
                          />
                          {loading.FIT
                            ? 'Cargando archivo'
                            : files.type.FIT
                            ? 'Carga exitosa'
                            : 'Subir archivo'}
                            <ViewFile file={files.type.FIT} show={!loading.FIT && files.type.FIT} />
                        </label>
                        <input
                          name='FIT'
                          id='FIT'
                          className= 'input-file'
                          type='file'
                          accept='application/pdf'
                          onChange={onChangeFile}
                        />
                      </div>
                      <Spinner status={files.status.FIT} file={files.type.FIT && files.type.FIT.name} />
                    </div>
                    <div className='form-row text-left mt-2'>
                      <label className='tit-input'>
                        Escrituras
                        <span className='text-danger'> *</span>
                      </label>
                      <div className='form-group'>
                        <label
                          className={`tit-Archivobtn ${
                            loading.FE ? 'loading' : files.type.FE ? 'loaded' : 'upload'
                          }`}
                          htmlFor='FE'
                        >
                          <img
                            src={
                              loading.FE
                                ? loadingFileIcon
                                : files.type.FE
                                ? loadedFileIcon
                                : uploadFileIcon
                            }
                            className='imgDentroBtn'
                          />
                          {loading.FE
                            ? 'Cargando archivo'
                            : files.type.FE
                            ? 'Carga exitosa'
                            : 'Subir archivo'}
                            <ViewFile file={files.type.FE} show={!loading.FE && files.type.FE} />
                        </label>
                        <input
                          name='FE'
                          id='FE'
                          className= 'input-file'
                          type='file'
                          accept='application/pdf'
                          onChange={onChangeFile}
                        />
                      </div>
                      <Spinner status={files.status.FE} file={files.type.FE && files.type.FE.name} />
                    </div>
                    <div className='form-row text-left mt-2'>
                      <label className='tit-input'>
                        Certificado de Tradición y Libertad
                        <span className='text-danger'> *</span>
                      </label>
                      <div className='form-group'>
                        <label
                          className={`tit-Archivobtn ${
                            loading.FCTL ? 'loading' : files.type.FCTL ? 'loaded' : 'upload'
                          }`}
                          htmlFor='FCTL'
                        >
                          <img
                            src={
                              loading.FCTL
                                ? loadingFileIcon
                                : files.type.FCTL
                                ? loadedFileIcon
                                : uploadFileIcon
                            }
                            className='imgDentroBtn'
                          />
                          {loading.FCTL
                            ? 'Cargando archivo'
                            : files.type.FCTL
                            ? 'Carga exitosa'
                            : 'Subir archivo'}
                            <ViewFile file={files.type.FCTL} show={!loading.FCTL && files.type.FCTL} />
                        </label>
                        <input
                          name='FCTL'
                          id='FCTL'
                          className= 'input-file'
                          type='file'
                          accept='application/pdf'
                          onChange={onChangeFile}
                        />
                      </div>
                      <Spinner status={files.status.FCTL} file={files.type.FCTL && files.type.FCTL.name} />
                    </div>
                  </div>
              </Col>
              <Col xs={{ span: 24}} md={{ span: 10, offset: 1 }}>
                <div>
                  <CustomInputLand iconColor='gray' values={getValuesDinamicInputs} selectOptions={['file', 'email', 'number', 'text',  ]} />
                </div>

              </Col> 
            </Row>

      </div>
    </div>
  )
}

export default LegalInformation
