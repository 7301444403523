import React from "react";
import { Typography,Row,Col } from 'antd';
import { DateTime } from 'luxon';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import {ClockCircleOutlined,CheckOutlined,CloudDownloadOutlined} from "@ant-design/icons"
import Request from "../../../../services/Request";
import fileDownload from 'js-file-download';
//Quien envio el mensaje
const OWNER_MESSAGE = {
  addresseer:0,
  self:1
}
//Si el mensaje fue leido
const MESSAGE_STATUS = {
  sending:0,
  recived:1,
  readed:2
}

const { Title,Text,  } = Typography;
const BubbleChat = (params) =>{
  const {item} = params;
  const bubbleStyle = {
    backgroundColor:  item.me?"#00556D":"#272A30" , 
  }
  const descriptionStyle ={
    color:'#F8F8F8'}
  const messageStatus = {
    color:'#E4E4E4',
    fontSize:'10px'
  }
  
  const handleClick = async () => {
    if(item.type === 'FILE'){      
      const file = await Request.get(
        `/file/${item.message}`,
        {
          model: 'Chat',
        },
        { responseType: 'blob' }
      )      
      const blob = file.data;
      fileDownload(new File([blob], item.fileName),item.fileName);
    }
  }
  return (
    <div className={item.type === 'FILE'?'bubble bubble-file':'bubble bubble-message'} style={bubbleStyle} onClick={handleClick}>
      {!item.me && <Title style={{color:'#60CAF7'}} level={5} >{item.user}</Title>}      

      {item.type === 'FILE' && <Title style={{color:'#60CAF7'}} level={5} >{item.fileName}</Title>}      
      {item.type === 'FILE' && <CloudDownloadOutlined style={{alignSelf:'center', fontSize:50,color:"white"}} />}      
      {item.type != 'FILE' && <Text style={descriptionStyle} type="secondary">{item.message}</Text>}      
      {item.type !='FILE' && <Row>
        <Col className="d-md-block d-none" span={20}></Col>
        <Col span={3}>
          <Text className="text-nowrap" style={messageStatus} type="secondary">{
           DateTime.fromISO(item.time).toFormat('dd-MM-yyyy HH:mm') 
          }</Text>
        </Col>
        {item.me &&
        <Col className="ps-md-0 ms-md-0 ps-2 ms-5" span={1}>
          {item.status === "READ" && <DoneAllIcon style={messageStatus}/>}
          {item.status === "SENDING" && <ClockCircleOutlined style={messageStatus}/>}
          {item.status === "WAIT" && <CheckOutlined style={messageStatus}/>}
        </Col>}
      </Row>}
      {item.type === 'FILE' &&
      <div>
         <Text style={messageStatus} type="secondary">
            {
            DateTime.fromISO(item.time).toFormat('dd-MM-yyyy HH:mm') 
            }
          </Text>
      </div>
      }
    </div>
  )
}

export default  BubbleChat;