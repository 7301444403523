import React from 'react'
import { createContext, useState } from 'react'
import ToastContainer from '../components/Toasts'

const ToastsContext = createContext()
const { Provider, Consumer } = ToastsContext

function ToastsProvider({ children }) {
  const [toasts, setToasts] = useState([])
  const [toastsCount, setToastsCount] = useState(0)

  const createToastAction = ({ title, message, type }) => {
    const id = toastsCount
    setToastsCount(toastsCount + 1)
    setToasts((toasts) => [...toasts, { id, title, message, type }])
  }

  const removeToastAction = (id) => {
    setToasts((toasts) => toasts.filter((toast) => toast.id !== id))
  }

  const removeAllToastsAction = () => {
    setToasts([])
  }

  const removeFirstToastAction = () => {
    setToasts((toasts) => toasts.slice(1))
  }

  return (
    <Provider
      value={{
        toasts,
        toastsCount,
        createToastAction,
        removeToastAction,
        removeAllToastsAction,
        removeFirstToastAction,
      }}
    >
      {children}
      <ToastContainer />
    </Provider>
  )
}

export { ToastsProvider, Consumer as ToastsConsumer, ToastsContext }
