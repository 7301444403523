const UserModelsEnum = Object.freeze({
  Developer: 'Desarrollador',
  Broker: 'Corredor',
  User: 'User',
  SUDO: 'SUDO',
  ADMIN: 'ADMIN',
})

export const FaqUserTypes = Object.freeze({
  'Desarrollador': 'DEVELOPER',
  'Corredor': 'BROKER',
})

export default UserModelsEnum
