import Request from './Request';

export const getAreaMin = async () => {
    try {
        return await Request.get("/ranges?stateParam=ACTIVE&ordering=startRange,asc");
    } catch (error) {
        if(error) console.error(error);
        return error;
    }
}

export const getAreaMax = async () => {
    try {
        return await Request.get("/ranges?stateParam=ACTIVE&ordering=endRange,desc");
    } catch (error) {
        if(error) console.error(error);
        return error;
    }
}