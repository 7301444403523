import React from 'react'
import UserModelsEnum from '../../../helpers/enums/UserModelsEnum'
import './index.css'

export default function StepSelector({ step, userType }) {
  return (
    <div className='step-selector'>
      <div className='container'>
        <div className='row row-selecCorredor'>
          <div
            className={`col info-select text-center ${
              (userType ===  UserModelsEnum.Developer && (step === 1 || step === 2)) ||
              (userType ===  UserModelsEnum.Broker && step === 1)
                ? 'active'
                : ''
            }`}
          >
            <span className='links'>
              1.{' '}
              {userType ===  UserModelsEnum.Developer
                ? 'Información Personal'
                : 'Tipo de Corredor'}
            </span>
          </div>
          <div
            className={`col info-select text-center ${
              (userType ===  UserModelsEnum.Developer && (step === 3 || step === 4)) ||
              (userType ===  UserModelsEnum.Broker && step === 2)
                ? 'active'
                : ''
            }`}
          >
            <span className='links'>
              2.{' '}
              {userType ===  UserModelsEnum.Developer
                ? 'Información Empresarial'
                : 'Información Personal'}
            </span>
          </div>
          <div
            className={`col info-select text-center ${
              (userType ===  UserModelsEnum.Developer && step > 4) ||
              (userType ===  UserModelsEnum.Broker && step > 2)
                ? 'active'
                : ''
            }`}
          >
            <span className='links'>
              3. {userType ===  UserModelsEnum.Developer ? 'Verificación' : 'Foto'}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
