import HttpResponseEmun from '../helpers/enums/HttpResponseEmun'
import Request from './Request'

export const updatePassword = async (password) => {
  try {
    const response = await Request.post('/users/change-password', { password })
    if (response.status === HttpResponseEmun.OK) {
      return true
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const recoveryPassword = async (email) => {
  try {
    const response = await Request.post('/public/users/recovery-password', {
      email,
    })
    if (response.status === HttpResponseEmun.OK) {
      return true
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const unloggedUpdatePassword = async (password, token) => {
  try {
    localStorage.setItem('authorization', token)
    const response = await Request.post('/users/change-password', {
      password,
    })
    localStorage.removeItem('authorization')
    if (response.status === HttpResponseEmun.OK) {
      return true
    }
  } catch (error) {
    console.error(error)
  }
  return false
}
