import React, { useState, useContext} from 'react';
import { message, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useAuth } from '../../../../context/AuthContext';
import './BatchDiary.css'
import { ButtonApp } from '../../../button/button';
import {Context} from '../../../../context/lotsContext';
import CustomInput from './CustomInput';
import Request from '../../../../services/Request';
import HttpResponseEmun from '../../../../helpers/enums/HttpResponseEmun';

const defaultDate = () => {
  /*const todayString = ;
  const todayArray = todayString.split("/").map(e => e.length === 1 ? "0"+e : e);
  return `${todayArray[1]}/${todayArray[0]}/${todayArray[2]}`;*/

  const todayString = new Date()
  .toLocaleDateString()
  .split("/")
  .map(e => e.length === 1 ? "0"+e : e)
  .join("/");

  return new Date(todayString);
}


const BatchDiary = ({setShowSpinner}) => {
  const [loading, setLoading] = useState(false)
  const [notes, setNotes] = useState([]);
  const {
    state: { isVisibleHB, currentLots },
    dispatch,
  } = useContext(Context)
  const {user, userData} = useAuth()

const goBack = () => {
  dispatch({
    type: 'SET_IS_VISIBLE_HB',
    payload:{
      ...isVisibleHB,
      option: true,
      type: 'details',
    }
  });
}

const handleBatchDiary = async() =>{
  let err = false
  notes.forEach((item)=>{
    if(item.comment === '') err = true
    if(item.date === '') item.date = defaultDate()
  })
  if(err && notes.length > 1){
    return message.warning('Debe llenar o eliminar las notas vacias')
  }else if(err && notes.length === 1){
    return message.warning('Debe llenar todos los campos')
  }
  setShowSpinner(true);

  let error = false;

  for (const note of notes) {
    const res = await Request.post(`/lands/${isVisibleHB.lot._id}/add-notes`, note);
    if(res.status === HttpResponseEmun.OK){
      dispatch({
        type: 'SET_IS_VISIBLE_HB',
        payload:{
          toggleState: {
              ...isVisibleHB.toggleState
          },
          option: true, 
          type: 'details', 
          lot: res.data,
        }
      });
      const updatedLots = currentLots.content.map(item => {
        if(item._id === res.data._id) return res.data;
        return item;
      });
      dispatch({ type: 'SET_CURRENT_LOTS', payload: { ...currentLots, content: updatedLots } })
    }else{
      error = true;
    }
  }
  if(error){
    message.error('Ocurrió un problema al enviar las notas')
  } else {
    message.success('Notas enviadas con éxito')
  }
  setShowSpinner(false)
}

    return (
    <div className='container-details'>
      <Row className='rows-lot' style={{alignItems: 'end'}}>
        <span className='subtitle1 subtitle_link' onClick={goBack}><ArrowLeftOutlined /> Ficha del Lote</span>
        <span className='subtitle2'>{isVisibleHB.lot.registrationNumber && isVisibleHB.lot.registrationNumber}</span>
      </Row>
        <CustomInput values={(e)=> setNotes(e)} />
        <div className='button-save'>
          <ButtonApp onClick={handleBatchDiary} content='Guardar notas' primary />
        </div>
    </div>
  )
}

export default BatchDiary;