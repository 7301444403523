import React, {useEffect, useState, useRef, useCallback} from 'react'

import { useLocation } from 'react-router-dom'
import { Button, Collapse, Empty, Input, Pagination } from 'antd';
import { QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';
import useFetchFaqs from '../../hooks/useFetchFaqs';
import { useMemo } from 'react';

import './index.css';
import FaqSkeleton from './FaqSkeleton/FaqSkeleton';

const { Panel } = Collapse;

function FaqPage() {

    const [expandAll, setExpandAll] = useState(false);
    const [activeKeys, setActiveKeys] = useState([]);
    const [searchParam, setSearchParam] = useState(null);
    const [haveScrolled, setHaveScrolled] = useState(true);

    const location = useLocation();
    const searchParams = useMemo(() => new URLSearchParams(location.search), [location]);

    const {loading, response: faqs, filter, setFilter} = useFetchFaqs();

    const collapseRef = useRef();

    const toggleExpand = () => {
        if(!expandAll) setExpandAll(true);
        else {
            setExpandAll(false);
            setActiveKeys([]);
        }
    }

    const addActiveKey = (key) => {
        setActiveKeys(key);
    }

    const getHeader = (question) => {
        return <strong className='py-3'>{question}</strong>;
    }

    const applySearchParam = useCallback(() => {
        setFilter({...filter, searchParam});
    }, [filter, searchParam]);

    const toggleHaveScrolled = useCallback(() => {
        setHaveScrolled(!haveScrolled);
    })

    useEffect(() => {

        if(!loading && faqs.content.length > 0 && expandAll){
            setActiveKeys(faqs.content.map(faq => faq._id));
        }

    }, [faqs, loading, expandAll])

    useEffect(() => {
        const newPage = searchParams.get('page');
        if(newPage !== filter.page) setFilter({...filter, page: newPage});

    }, [searchParams])

    useEffect(() => {
        if(!loading && !haveScrolled){
            const selectedQuestion = searchParams.get('question');
            if(selectedQuestion){
                if(!activeKeys.includes(selectedQuestion)) setActiveKeys([...activeKeys, selectedQuestion]);
                const questionElement = collapseRef.current.querySelector('#id'+selectedQuestion);
                questionElement.scrollIntoView({ behavior: "smooth" });
                toggleHaveScrolled();
            }
        }
    }, [searchParams, loading, haveScrolled])

    useEffect(() => {
        toggleHaveScrolled();
    }, [location.search])

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
          if(searchParam !== null){
            applySearchParam();
          }
        }, 800);
        return () => clearTimeout(debounceTimer);
      }, [searchParam]);

    return (
        <div className='container pb-4'>
            <h2 
            className='text-center d-flex align-items-center justify-content-center gap-2' 
            style={{margin: '1.5rem 0'}}>FAQ <QuestionCircleOutlined title="Preguntas Frecuentes" /></h2>

            <Input onChange={(e) => setSearchParam(e.target.value)} value={searchParam} className='mb-4 shadow' size="large" placeholder="Buscar pregunta..." prefix={<SearchOutlined />} />

            <div className="d-flex justify-content-end mb-2">
                <Button type="text" onClick={toggleExpand}>{expandAll ? "Ocultar todos" : "Expandir todos"}</Button>
            </div>

            {loading && <FaqSkeleton />}

            {!loading && faqs.content.length === 0 && (
                <Empty 
                description={
                <span>
                    No se encontraron datos
                </span>}
                image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}

            <div ref={collapseRef}>
                <Collapse onChange={addActiveKey} className='shadow question-collapse' activeKey={activeKeys}>
                    {faqs && faqs.content && faqs.content.length > 0 && faqs.content.map(faq => {
                        return <Panel id={'id'+faq._id} header={getHeader(faq.question)} key={faq._id}>
                            <p>{faq.answer}</p>
                        </Panel>
                    })}
                </Collapse>
            </div>

            <div className='mt-2 d-flex justify-content-end'>
                <Pagination
                    current={filter.page +1}
                    onChange={(current, pageSize)=> setFilter({...filter, page: current -1})}
                    defaultCurrent={1}
                    total={faqs.totalElements}
                    showTotal={(total) => `Total ${total}`}
                    responsive
                    disabled={faqs.totalElements >  faqs.size ? false : true}
                />
            </div>

        </div>
    )
}

export default FaqPage