import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useRegister } from '../../context/RegisterContext'
import useModals from '../../hooks/useModals'
import useToasts from '../../hooks/useToasts'
import { useHistory } from 'react-router-dom'
import departamentos from '../../helpers/states'
import './InfoEmpresarial.css'

const infoEmpresarialTwo = () => {
  const { setStep, userToRegister, setUserToRegister, registerUser } =
    useRegister()
  const { openPoliciesModal } = useModals()
  const { addToast } = useToasts()
  const history = useHistory();
  const onSubmit = (formData) => {
   
    setUserToRegister({
      ...userToRegister,
      department: formData.departamento,
      city: formData.ciudad,
      address: formData.direccion,
      contactPerson: formData.nombreConEmpre,
      sameInfoPerson: formData.Eslamismapersona,
    });        
    
    
  }

  useEffect(() => {
    if (
      userToRegister.department !== '' &&
      userToRegister.city !== '' &&
      userToRegister.address !== '' &&
      userToRegister.contactPerson !== ''
    ) {
      openPoliciesModal({
        onConfirm: () => {
          registerUser()
        },
        onCancel: () => {
          addToast({
            type: 'error',
            title: 'Políticas de privacidad',
            message:
              'Por favor acepte las políticas de privacidad para registrar su cuenta',
          })
        },
      })
    }
  }, [userToRegister])

  const validationSchema = Yup.object().shape({
    departamento: Yup.string().required('Este campo es obligatorio'),
    ciudad: Yup.string().required('Este campo es obligatorio'),
    direccion: Yup.string().required('Este campo es obligatorio'),
    nombreConEmpre: Yup.string().required('Este campo es obligatorio'),
  })

  const formik = useFormik({
    initialValues: {
      departamento: userToRegister.department,
      ciudad: userToRegister.city,
      nombreConEmpre: userToRegister.contactPerson,
      direccion: userToRegister.address,
      Eslamismapersona: userToRegister.sameInfoPerson,
    },

    onSubmit,

    validationSchema,
  })

  useEffect(() => {
    if (userToRegister.department !== '') {
      const cit = departamentos.find(
        (depar) => depar.departamento === userToRegister.department
      ).ciudades
      setCiudes({
        ciudades: cit,
      })
    }
  }, [])

  let permiteGuardar = false

  if (
    formik.values.departamento != '' &&
    formik.values.ciudad != '' &&
    formik.values.direccion != '' &&
    formik.values.nombreConEmpre != ''
  ) {
    permiteGuardar = true
  }

  const onClickEslaMismaPersona = (e) => {
    if (e.target.checked === true) {
      formik.setFieldValue(
        'nombreConEmpre',
        userToRegister.names + ' ' + userToRegister.lastNames
      );
    } else {
      formik.setFieldValue('nombreConEmpre', '');
      formik.setFieldTouched('nombreConEmpre', true);
      formik.setFieldError('nombreConEmpre', 'Este campo es obligatorio');
    }
    formik.setFieldValue('Eslamismapersona', e.target.checked)
  }
  
  const onChangeNombreConEmpre = (valor) => {
    formik.setFieldValue('nombreConEmpre', valor)
  }

  const [ciudad, setCiudes] = useState({
    ciudades: [],
  })
  const cambiarDepar = (e) => {
    if (e.target.value) {
      const cit = departamentos.find(
        (depar) => depar.departamento === e.target.value
      ).ciudades
      setCiudes({
        ciudades: cit,
      })
    } else {
      setCiudes({
        ciudades: [],
      })
    }
  }

  return (
    <div className='developer-business-info'>
      <form onSubmit={formik.handleSubmit}>
        <div className='container block-from'>
          <div className='form-row'>
            <div className='form-group col-md-7'>
              <label className='tit-input'>
                Departamento<span className='text-danger'> *</span>
              </label>
              <select
                name='departamento'
                className={
                  formik.errors.departamento
                    ? 'form-select form-info-corredorError'
                    : 'form-select form-info-corredor'
                }
                onChange={(e) => {
                  formik.handleChange(e)
                  cambiarDepar(e)
                }}
                value={formik.values.departamento}
              >
                <option value=''>Seleccione</option>
                {departamentos.map((ar, keyar) => (
                  <option key={keyar} value={ar.departamento} id={ar.id}>
                    {ar.departamento}
                  </option>
                ))}
              </select>
            </div>
            {formik.errors.departamento ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.departamento}
              </div>
            ) : null}
          </div>
          <div className='form-row'>
            <div className='form-group col-md-7'>
              <label className='tit-input'>
                Ciudad<span className='text-danger'> *</span>
              </label>
              <select
                name='ciudad'
                className={
                  formik.errors.ciudad
                    ? 'form-select form-info-corredorError'
                    : 'form-select form-info-corredor'
                }
                onChange={formik.handleChange}
                value={formik.values.ciudad}
                disabled={formik.values.departamento ? false : true}
              >
                <option value=''>Seleccione</option>
                {ciudad.ciudades.map((city, inxcity) => (
                  <option key={inxcity} value={city.name}>
                    {' '}
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
            {formik.errors.ciudad ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.ciudad}
              </div>
            ) : null}
          </div>
          <div className='form-row'>
            <div className='form-group col-md-7'>
              <label className='tit-input'>
                Dirección<span className='text-danger'> *</span>
              </label>
              <input
                type='text'
                name='direccion'
                className={
                  formik.errors.direccion
                    ? 'form-control form-info-corredorError'
                    : 'form-control form-info-corredor'
                }
                value={formik.values.direccion}
                placeholder='Av Kr 15 #13-23'
                onChange={formik.handleChange}
              />
            </div>
            {formik.errors.direccion ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.direccion}
              </div>
            ) : null}
          </div>
          <div className='form-row'>
            <div className='form-group col-md-7'>
              <label className='tit-input'>
                Nombre de la persona de contacto en la empresa
                <span className='text-danger'> *</span>
              </label>
              <input
                type='text'
                name='nombreConEmpre'
                className={
                  (formik.touched.nombreConEmpre && formik.values.nombreConEmpre === '')
                    ? 'form-control form-info-corredorError'
                    : 'form-control form-info-corredor'
                }
                placeholder='John Doe'
                value={formik.values.nombreConEmpre}
                onChange={(e) => onChangeNombreConEmpre(e.target.value)}
              />
            </div>
            {(formik.touched.nombreConEmpre && formik.values.nombreConEmpre === '') ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.nombreConEmpre}
              </div>
            ) : null}
          </div>
          <div className='form-row form-rowCheck'>
            <div className='form-check col-md-7'>
              <input
                type='checkbox'
                name='Eslamismapersona'
                className='form-check-input inputCheckebox'
                onClick={(e) => onClickEslaMismaPersona(e)}
                checked={formik.values.Eslamismapersona}
              />
              <label className='form-check-label tit-input-checkbox'>
                Es la misma información personal
              </label>
              {formik.errors.Eslamismapersona ? (
                <div className='form-label Mensaje-ErrorCorredor'>
                  Este campo es obligatorio
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className='container sectionBtnCorredor'>
          <div className='row'>
            <div className='col'>
              <button
                className='btn btn-volver'
                onClick={() => {
                  setStep(3)
                }}
              >
                Volver
              </button>
            </div>
            <div className='col btn-holder'>
              <button
                type='submit'
                className={
                  permiteGuardar
                    ? 'btn btn-endNext'
                    : 'btn btn-endNextOtro disabled-link'
                }
              >
                Siguiente
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default infoEmpresarialTwo
