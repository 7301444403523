import React, { useState } from 'react'
import {Link} from  'react-router-dom'
import verContra from "../../assets/icons/visualizar.png";
import NoverContra from "../../assets/icons/novisualizar.png";

const CorreoContra = () => {

  const [estadoVisualizar, setEstadoVisualizar] = useState(false);

  return (
    <div>
        <label className="form-label subtitle--login">Correo electrónico<span className='text-danger'> *</span></label>
        <div className="input-group mb-3">
          <input type="email" name="email" className="form-control style--inputs--login icoInput1" placeholder="micorreo@correo.com" required />
        </div>


        <div className="container--texts--login">
          <label className="subtitle--login form-label">Contraseña<span className='text-danger'> *</span></label>
          <div className="form-label olvida-contra">
            <Link to="/auth/recovery-password" className="form-label links--olvida-contra">He olvidado mi contraseña</Link>
          </div>
        </div>
        <div className="input-group mb-3 pruebasinputpsw">
          <input name="password" type={estadoVisualizar==false ? "password" : "text"} className="form-control style--inputs--login inputVerContra" placeholder="*****************" />
          <label className="imgVisualizarPSW style--inputs--login"><img src={estadoVisualizar==false ? NoverContra : verContra } alt="Ver Constraseña" className="imgVerContra" onClick={() =>setEstadoVisualizar(!estadoVisualizar)} /></label>
        </div>
    </div>
  );
};
export default CorreoContra;