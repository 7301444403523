import React, { useContext, useState, useEffect, useCallback } from 'react';

import ProfileBannerBroker from './ProfileBannerBroker';
import RequestList from './../../pages/Search/RequestList';
import DetailsModal from '../../pages/Search/DetailsModal';
import getAllLands from '../../services/getAllLands';
import ProfileDeveloperFilter from './ProfileDeveloperFilter';


// imports to load lands in the lotsContext
import {useAuth} from '../../context/AuthContext';
import {Context} from '../../context/lotsContext';
import getLandsToOffer from '../../services/getLandsToOffer';
import { message } from 'antd';

import UserModelsEnum from '../../helpers/enums/UserModelsEnum';
import HttpResponseEmun from '../../helpers/enums/HttpResponseEmun';

// styles
import './index.css';
import SkeletonComponent from '../Home/HomeBroker/SkeletonLoader/SkeletonLoader';
import requestLandById from '../../services/requestLandById';
import LandTable from '../../pages/Search/LandTable/LandTable';

function ProfileBroker({brokerId}) {

        const [filterTableList, setFilterTableList] = useState({page: 0, showRows: 10, statusParam: 'ACTIVE' });
        const [filter, setFilter] = useState({page: 0, showRows: 10, associateTypeParam: brokerId, statusParam: 'ACTIVE' });
        const [currentRequestLots, setCurrentRequestLots] = useState([]);
        const [showDetails, setShowDetails] = useState({show: false, request: {}});
        const [searchParams, setSearchParams] = useState({});
        const [searchLoading, setSearchLoading] = useState(true);
        const [modalLoading, setModalLoading] = useState(false);

        const [lands, setLands] = useState({});
    
        const [selectedRequest, setSelectedRequest] = useState("");
        const [selectedLand, setSelectedLand] = useState({landId: "", registrationNumber: "", disabledList: []});
    
        const {user, userData} = useAuth();
        const { dispatch } = useContext(Context);

        const closeModal = () => {
            setShowDetails({show: false, request: {}})
        }

        const fetchDataLandsDeveloperCallback = useCallback(
          async () => {
            setSearchLoading(true);
            const res = await getAllLands(searchParams, {...filter, typeParam: UserModelsEnum.Broker, creatorParam: brokerId});
            setLands(res.data);
            setSearchLoading(false);
          },
          [filter, searchParams],
        );

        const requestLandByIdCallback = useCallback(
          async (requestId) => {
            try {
              const res = await requestLandById(selectedLand.landId, requestId);
              if(res.status === HttpResponseEmun.OK) {
                message.success({content: `¡El lote ${selectedLand.registrationNumber} fue solicitado con éxito!`, duration: 3});
                setSelectedLand(({...selectedLand, disabledList: [...selectedLand.disabledList, requestId]}));
              } else {
                message.error({content: `¡Error al solicitar el lote ${selectedLand.registrationNumber}!`, duration: 4});
              }
            } catch (error) {
              message.error({content: `¡Error al solicitar el lote ${selectedLand.registrationNumber}!`, duration: 4});
            }
          },
        [selectedLand]);

        useEffect(() => {
          fetchDataLandsDeveloperCallback(searchParams, filter);
        }, [searchParams, filter, userData]);

    return (
    <>
        <ProfileBannerBroker />

        <div className="profile-developer-table">

            <ProfileDeveloperFilter changeFilters={(newFilters) => setFilter({...filter, ...newFilters})} />

            {!searchLoading && (<div className="search-table-container">
              <LandTable 
                setShowDetails={setShowDetails} lands={lands} filter={filter}
                setFilter={setFilter} setSelectedLand={setSelectedLand}
                requestLandByIdCallback={requestLandByIdCallback}
                selectedLand={selectedLand} />
            </div>)}

            {searchLoading && (<div className="search-table-container"><SkeletonComponent /></div>)}

        </div>

        <DetailsModal modalLoading={modalLoading} showDetails={showDetails} 
    closeModal={closeModal} setSelectedRequest={setSelectedRequest} selectedRequest={selectedRequest} />
    </>)
}

export default ProfileBroker;
