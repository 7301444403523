import React, { useState } from 'react'
import { Divider, Pagination, Empty } from 'antd';
import RequestListItem from '../RequestListItem';

import sort from '../../../components/handleSort';
//import '../../../components/Home/HomeBroker/index.css';
import './index.css';

const theadDevelopers = [
  {item:'registrationNumber', label:'Código'},
  {item:'state', label:'Estado'},
  {item:'city', label:'Ciudad'},
  {item:'neighborhood', label:'Barrio'},
  {item:'areaRange', label:(<p className="d-inline request-lot-area">Área del Lote (m<sup>2</sup>)</p>)},
  {item:'developer!', label:''},
  {item:'!', label:''},
]



const RequestList = ({ currentRequestLots, filterTableList, setFilterTableList, setShowDetails, 
    selectedRequest, setSelectedRequest, filter, setFilter}) => {

    
    const [logo, setLogo] = useState({});

    const FilterSvg = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filter-left" viewBox="0 0 16 16">
        <path d="M2 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
    </svg>

    const handleSort = (item) =>{
        setFilter({...filter, ordering: sort(item)}) 
    }

    const requestLotClickHandler = (item) => {
        setShowDetails({show: true, request: item});
    }

    const offerButtonHandler = (e, id) => {
        e.stopPropagation();
        if(id === selectedRequest) return setSelectedRequest("");
        setSelectedRequest(id);
    }
    
    return (
            <>
                
            <div>   
                <table >
                <thead className="HeadListUser-admin" >
                    <tr >
                        {theadDevelopers.map((e, i)=>{
                        return(
                        <th key={i} scope="col" className={`col-listUser-adminPanel col${i+1}`} onClick={() => { !e.item.includes("!") && handleSort(e.item) }} style={{ cursor: 'pointer' }}>
                        <span style={{ marginRight: '0.5rem' }} >{e.label}</span>
                        {!e.item.includes("!") && FilterSvg}
                        </th>)
                    })}
                    </tr>
                </thead>
                <tbody>
                {currentRequestLots && currentRequestLots.type !== 'lands' && 
                <tr className='table-row-s'>
                    
                </tr>}

                {currentRequestLots.content && currentRequestLots.content.length === 0 &&
                    <tr>
                    <td colSpan={8}>
                    <Empty 
                        description={
                        <span>
                            No se encontraron datos
                        </span>}
                        image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    
                    </td>
                    </tr>}
                    {currentRequestLots.content && currentRequestLots.content.map((item, i) => {
                    
                    return <RequestListItem key={item._id} item={item} logo={logo} setLogo={setLogo} requestLotClickHandler={requestLotClickHandler} offerButtonHandler={offerButtonHandler} 
                    selectedRequest={selectedRequest} filterTableList={filterTableList} setFilterTableList={setFilterTableList} />})}
                    <tr >
                        <td colSpan="7">
                        <Divider style={{margin: '2px'}}/>
                        </td>
                    </tr>

                </tbody>
                <tfoot>
                    <tr>
                    <td colSpan="7" align='right'>
                        <Pagination
                            current={filter.page +1}
                            onChange={(current, pageSize)=> setFilter({...filter, page: current -1})}
                            defaultCurrent={1}
                            total={currentRequestLots.totalElements}
                            showTotal={(total) => `Total ${total}`}
                            responsive
                            disabled={currentRequestLots.totalElements >  currentRequestLots.size ? false : true}
                        />
                    </td>
                    </tr>
                </tfoot>
                </table>
            </div>

            </>
        )
    }

export default RequestList;