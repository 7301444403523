import React, {useEffect, useState, useContext} from 'react'
//import { DateTime  } from 'luxon'
import CloseCircle from '../icon/close-circle-outline.svg'
import { DatePicker } from 'antd'

import NotePopOver from '../NotePopOver';

import { Context } from '../../../../../../context/lotsContext'
//{name, label, type, placeholder, value, onChange, onBlur, error}
//types inputs:{text, number, email, file, image}
/**
 * 
 * @returns {JSX.Element}
 * @constructor
 * @param {string} name
 * hola
 */
export default function InputList({values, onChange, deleteInput, getValues, inputType}) {
  const [note, setNote] = useState({comment: '', date: '', index: ''})

 /*  useEffect(() => {
    if(getValues ){
      getValues(note)
      //setNote({comment: '', date: '', index: ''})
    }
  }, [note.comment, note.date, note.index]) */

  const {
    state: { isVisibleAssociate }
  } = useContext(Context)
  
  const onChangeValues = (e, i) => {
    
      getValues(e, i)
   if(onChange){
    onChange(e)
   }
  }

  const renderDateNote = current => {

    let showReminder = null;

    isVisibleAssociate.lot && isVisibleAssociate.lot.notes && isVisibleAssociate.lot.notes.forEach((note) => {
        if(current.format("YYYY-MM-DD") === note.date.substr(0, 10)){

          showReminder = note;
        }
    });

    if(showReminder !== null){
      return (
        <NotePopOver note={showReminder} dayMoment={current} />
      );
    }

    return (
      <div className="ant-picker-cell-inner">
        {current.date()}
      </div>
    );
  }

return (
  <>
      {values && !inputType && 
        values.map((item, index) => {
              return(
                <div key={index} className="input-type">
                  <label className="label-input">Notas</label>
                  <div className='input'>
                    <input autoFocus type='text' onChange={(e) => getValues({comment: e.target.value}, index)}/> 
                    {index > 0 && <img src={CloseCircle} style={{cursor: 'pointer', width: '20px'}} onClick={()=>deleteInput(index)}/>}
                  </div>
                  <div className='DatePicker'>
                    <span> <b>Asignar fecha de recordatorio</b></span>
                    <DatePicker dateRender={renderDateNote} className='datePicker-input' placeholder='DD/MM/AAAA' format={'MM/DD/YYYY'} onChange={(dateMoment, dateString) => getValues({date: new Date(dateString)}, index)}/>
                  </div>
                </div>
              )
    })}
</>
)

}