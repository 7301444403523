import Request from './Request';

export default async function getLands(filter, Id) {
   
    try {
                 const response = await Request.get(`/lands`, { ...filter, stateParam: filter.stateParam ? filter.stateParam : 'ON_SALE,PAUSED,BOUGHT', showDetailsParam: 0, creatorParam: Id })
                       return response
        
    } catch (error) {
        error && console.error(error)
        return error
    }
    
  }