import Request from './Request';

export default async function requestLandById (landId, landRequestId) {
    try{
        const response = await Request.post(`/land-requests/${landRequestId}/request/land-by-id/${landId}`);
        return response;
    } catch (error) {
        error && console.error(error)
        return error
    }
}