import React, {useState, useEffect, useMemo, useRef} from 'react';
import { Col, Row, Form, Select, Input, Slider } from 'antd';
import { useAuth } from '../../context/AuthContext'
import { states as stateLands } from '../../helpers/enums/LotsEnum';
import { states as stateRequestLand} from '../../helpers/enums/RequestLandsEnum'
import getCities from '../../services/getCities';
import './SelectTypeFilter.css';
import UserModelsEnum from '../../helpers/enums/UserModelsEnum';
import { getAreaMax, getAreaMin } from '../../services/getArea';
import formatter from '../../helpers/numberFormatter';
import { getPriceMax } from '../../services/getPrice';
import CustomSlider from '../CustomSlider/CustomSlider';

const { Option } = Select;

export default function SelectTypeFilter({onChange}) {

    const timer = useRef(null);

    const [isMounted, setIsMounted] = useState(true);
    const [checked, setChecked] = useState(false);
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [adress, setAdress] = useState('');
    const [area, setArea] = useState([]);
    const [serverArea, setServerArea] = useState([]);
    const [price, setPrice] = useState([]);
    const [serverPrice, setServerPrice] = useState([]);
    const [cities, setCities] = useState([]);
    const [params, setParams] = useState({});


    const { user } = useAuth();


    const states = useMemo(() => {
      return user && user.model === UserModelsEnum.Developer ? stateRequestLand : stateLands;
    }, [])

    const getLocationList = async (searchParamCity) => {
      if(isMounted){
        const resCities = await getCities(searchParamCity);
        const cities = resCities.data && resCities.data.content && [...new Set( resCities.data.content.map(e => e.city) )];
        setCities(cities);
      }
    }

    const getArea = async () => {
      if(isMounted){
        const resMin = await getAreaMin();
        const resMax = await getAreaMax();
        const min = resMin.data && resMin.data.content && resMin.data.content[0].startRange;
        const max = resMax.data && resMax.data.content && resMax.data.content[0].endRange;
        setServerArea([min, max]);
      }
    }

    const getPrice = async () => {
      if(isMounted && user && user.model === UserModelsEnum.Broker){
        const prices = await getPriceMax();
        const min = 0;
        const max = prices.data && prices.data[0] && prices.data[0].max;
        setServerPrice([min, max]);
      }
    }

    useEffect(() => {
      getLocationList();
      getArea();
      getPrice();
      return () => {
        setIsMounted(false)
      }
    }, []);
    

    useEffect(() => {
      if(onChange){
        onChange(params)
      }
    }, [params]);


    useEffect(() => {

      const stateKey = state !== '' && Object.keys(states).find(key => states[key].name === state);
      
      setParams({
        cityParam: city, stateParam: stateKey, 
        startAreaRangeParam: area[0], endAreaRangeParam: area[1]
      });

    }, [state, city, area]);

    useEffect(() => {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        if(user && user.model === UserModelsEnum.Broker) setParams({...params, searchParam: adress});
      }, 800);
    }, [adress, user]);

    useEffect(() => {
      if(user && user.model === UserModelsEnum.Broker){

        setParams({
          ...params,
          startPriceRangeParam: price[0],
          endPriceRangeParam: price[1]
        });

      }
    }, [price, user])
    
    return(
      <Form
        initialValues={{
          price: [1, Infinity]
        }}
      >
        <div className="container-collapse">
                  <div className="accordions-filter">
                    <div className="accordion-filter">
                      <input type="checkbox" onChange={(e)=> {setChecked(e.target.checked)}} id="first-filter" />
                      <label htmlFor="first-filter" className="acc-label-filter">
                        Filtros
                        <div className='bullet-point-filter'></div>
                      </label>
                      <div className="acc-content-filter">
                        <Row>
                          <Col className='col-f' style={{display: 'flex'}} xs={24} md={4}>
                            <label className='title-f' htmlFor='state'>ESTADO</label>
                            <Form.Item id="state" name="state">
                              <Select value={state} onChange={(e) => setState(e)} showSearch placeholder="Estado" allowClear>
                                {Object.values(states).map((state, i) => <Option key={i} value={state.name}>{state.name}</Option>)}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col className='col-f' style={{display: 'flex'}} xs={24} md={4}>
                            <label className='title-f' htmlFor='city'>CIUDAD</label>
                            <Form.Item id="city" name="city">
                              <Select value={city} onChange={(e) => setCity(e)} onSearch={getLocationList} showSearch placeholder="Ciudad" allowClear>
                                {cities && cities.map((city, i) => <Option key={i} value={city}>{city}</Option>)}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col className='col-f' style={{display: 'flex'}} xs={24} md={4}>
                            <label className='title-f' htmlFor='adress'>DIRECCIÓN</label>
                            <Form.Item id="adress" name="adress">
                              <Input value={adress} onChange={e => setAdress(e.currentTarget.value)} placeholder="Dirección"></Input>
                            </Form.Item>
                          </Col>

                          { serverArea.length > 0 && <Col className='col-f' style={{display: 'flex'}} xs={24} md={4}>
                            <label className='title-f' htmlFor='area'>ÁREA</label>
                            <Form.Item name="area" id="area">
                              <CustomSlider tipFormatter={formatter} min={serverArea[0]} max={serverArea[1]} defaultValue={[serverArea[0], serverArea[1]]} onAfterChange={e => setArea(e)} range />
                            </Form.Item>
                          </Col> }
                          
                          {user && user.model === UserModelsEnum.Broker && serverPrice.length > 0 && <Col className='col-f' style={{display: 'flex'}} xs={24} md={4}>
                            <label className='title-f' htmlFor='state'>PRECIO</label>
                            <Form.Item name="price" id="price">
                              <Slider step={500000} tipFormatter={formatter} min={serverPrice[0]} max={serverPrice[1]} onAfterChange={e => setPrice(e)} range />
                            </Form.Item>
                          </Col>}
                        
                        </Row>
                      </div>
                    </div>
                  </div>
                </div> 
              </Form>
    )
}