import React, { useState, useEffect, useContext, useCallback } from "react";
import './AdminPanelSetting.css'
import { MenuOutlined, SettingOutlined } from '@ant-design/icons';
import TableListDeveloper from './TableList/TableList'
import {
  AppstoreOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import Request from '../../services/Request';
import { ThemeContext } from "../../context/ThemeContext";
import HttpResponseEmun from "../../helpers/enums/HttpResponseEmun";

const AdminPanelSetting = () => {    
    const [toggleState, setToggleState] = useState({type: "users", label: "Administradores"});
    const [datas, setDatas] = useState({})
    const [filter, setFilter] = useState({page: 0, showRows: 10, statusParam: "ACTIVE"});
    const [loading, setLoading] = useState(false)
    const {themeColor} = useContext(ThemeContext)

    const refreshData = useCallback(
        () => setToggleState({...toggleState, update: toggleState.update ? false : true}),
        [toggleState],
    );

    const [ignoreLoading, setIgnoreLoading] = useState(false);
    
  useEffect(() => {
      if(ignoreLoading) return setIgnoreLoading(false);
      if(filter.sortLoading){ // Not show skeleton when sorting
        setFilter(prev => ({...prev, sortLoading: undefined}));
        setIgnoreLoading(true);
      }else {
        setLoading(true)
        setDatas([])
      }
      
  async function test() {
      try {
          if(toggleState.type === 'users'){
              const response = await Request.get('/users', { ...filter, typeParam: 'SUDO,ADMIN'})
              if (response.status === HttpResponseEmun.OK) {
                  setLoading(false)
                  setDatas({type: toggleState.type, ...response.data.data})
                  setLoading(false)
              }
          }
          if(toggleState.type === 'developers'){
              const response = await Request.get('/developers', { ...filter, stateParam: 'VERIFY'})
              if (response.status === HttpResponseEmun.OK) {
                  setLoading(false)
                  setDatas({type: toggleState.type, ...response.data.data})
                  setLoading(false)
              }
          }
          if(toggleState.type === 'brokers'){
              const response = await Request.get('/brokers', { ...filter})
              if (response.status === HttpResponseEmun.OK) {
                  setLoading(false)
                  setDatas({type: toggleState.type, ...response.data})
                  setLoading(false)
              }
          }
           if(toggleState.type === 'rol'){
              const response = await Request.get('/roles', {...filter});
              if (response.status === HttpResponseEmun.OK) {
                  setLoading(false)
                  setDatas({type: toggleState.type, ...response.data})
                  setLoading(false)
              }
          }
          if(toggleState.type === 'permissions'){
              const response = await Request.get('/permissions', { ...filter, ordering: filter.ordering ? filter.ordering : 'code,asc,'});
              if (response.status === HttpResponseEmun.OK) {
                  setLoading(false)
                  setDatas({type: toggleState.type, ...response.data})
                  setLoading(false)
              }
          }
          if(toggleState.type === 'system'){
              const response = await Request.get('/system-parameters')
              if (response.status === HttpResponseEmun.OK) {
                  setLoading(false)
                  setDatas({type: toggleState.type, ...response.data})
                  setLoading(false)
              }
          } 
          
      } catch (error) {
          error && console.error(error)
      }
  }
  test()
  }, [filter, toggleState]);

    return (
    <>
        <div className="container">

            <div className="container containerListAndSearchAdmin">
                <div className="blocTabs">

                    <button
                        className={toggleState.type === "users" ? "tabsListAdminPanel active-tabsListAdminPanel" : "tabsListAdminPanel"}
                       
                        onClick={() => {
                            setToggleState({type: "users", label: "Administradores"})
                            setFilter({page: 0, showRows: 10, statusParam: "ACTIVE"})
                        }}>

                        <div className={toggleState.type === "users" ? "boxBtnSelec" : "boxBtn"}
                                                     
                            >Administradores</div>
                    </button>
                    <button
                        className={toggleState.type === "developers" ? "tabsListAdminPanel active-tabsListAdminPanel" : "tabsListAdminPanel"}
                       
                        onClick={() => {
                            setToggleState({type: "developers", label: "Desarrolladores"})
                            setFilter({page: 0, showRows: 10, statusParam: "ACTIVE"})
                        }}
                    >
                        <div className={toggleState.type === "developers" ? "boxBtnSelec2" : "boxBtn"}
                     >Desarrolladores</div>
                    </button>
                    <button
                        className={toggleState.type === "brokers" ? "tabsListAdminPanel active-tabsListAdminPanel" : "tabsListAdminPanel"}
                      
                        onClick={() => {
                            setToggleState({type: "brokers", label: "Corredores"})
                            setFilter({page: 0, showRows: 10, statusParam: "ACTIVE"})
                        }}
                    >
                        <div className={toggleState.type === "brokers" ? "boxBtnSelec" : "boxBtn"}
                      >Corredores</div>
                    </button>
                    <button
                        className={toggleState.type === "rol" ? "tabsListAdminPanel active-tabsListAdminPanel" : "tabsListAdminPanel"}
                       
                        onClick={() =>{
                             setToggleState({type: "rol", label: "Roles"})
                                setFilter({page: 0, showRows: 10, stateParam: "ACTIVE"})
                            }}
                    >
                        <div className={toggleState.type === "rol" ? "boxBtnSelec2" : "boxBtn"}                              
                            >Roles</div>
                    </button>

                    <button
                        className={toggleState.type === "permissions" ? "tabsListAdminPanel active-tabsListAdminPanel" : "tabsListAdminPanel"}
                        onClick={() => {
                            setToggleState({type: "permissions", label: "Permisos"})
                            setFilter({page: 0, showRows: 10, statusParam: "ACTIVE"})
                        }}
                    >
                        <div className={toggleState.type === "permissions" ? "boxBtnSelec" : "boxBtn"}
                       
                        >Permisos</div>
                    </button>
                    <button
                        className={toggleState.type === "system" ? "tabsListAdminPanel active-tabsListAdminPanel" : "tabsListAdminPanel"}
                        onClick={() => {
                            setToggleState({type: "system", label: "Sistema"})
                            setFilter({page: 0, showRows: 10, statusParam: "ACTIVE"})
                        }}
                    >
                        <div className={toggleState.type === "system" ? "boxBtnSelec2" : "boxBtn"}
                      >Sistema</div>
                    </button>
                </div>

            </div>
            <div className='containerListResponsive'>
                <h4>{toggleState.label}</h4>
                <div style={{backgroundColor:"#272A30", color: "#fff", display: 'flex', width: '100%', justifyContent: 'end', minHeight: '40px'}}>
                    
                <Menu theme='dark' className='menu' style={{backgroundColor:"#272A30", color: "#fff", borderBottom: 0, minHeight: '40px', minWidth: '50px'}} mode="horizontal" >
                  
                  <Menu.SubMenu key="SubMenu" icon={<MenuOutlined />}>

                    <Menu.Item onClick={() => setToggleState({type: "users", label: "Administradores"})} key={'1'} icon={<AppstoreOutlined />}>
                      Administradores
                    </Menu.Item>
                    <Menu.Item onClick={() => setToggleState({type: "developers", label: "Desarrolladores"})} key={'2'}  icon={<AppstoreOutlined />}>
                      Desarrolladores
                    </Menu.Item>
                      <Menu.Item onClick={() => setToggleState({type: "brokers", label: "Corredores"})} key={'3'} icon={<AppstoreOutlined />}>
                        Corredores
                      </Menu.Item>
                      <Menu.Item onClick={() => {
                            setToggleState({type: "rol", label: "Roles"});
                            setFilter({page: 0, showRows: 10, stateParam: "ACTIVE"});
                           }
                        } key={'4'} icon={<AppstoreOutlined />}>
                        Roles
                      </Menu.Item>
                      <Menu.Item onClick={() => setToggleState({type: "permissions", label: "Permisos"})} key={'5'} icon={<AppstoreOutlined />}>
                        Permisos
                      </Menu.Item>
                      <Menu.Item onClick={() => setToggleState({type: "system", label: "Sistema"})} key={'6'} icon={<AppstoreOutlined />}>
                        Sistema
                      </Menu.Item>
                  </Menu.SubMenu>
                </Menu>
                </div>
            </div>
            <div className="container p-0 container-listUser">
                    <TableListDeveloper datas={datas} setDatas={setDatas} filter={filter} setFilter={setFilter} refreshData={refreshData} />
            </div>
        </div>
    </>
    )
}

export default AdminPanelSetting