import React, {useEffect, useState} from 'react';
import InputList from "./InputList/InputList";
import {PlusCircleOutlined} from '@ant-design/icons';
import './index.css';
import { fileLandOptions } from '../../helpers/fileLand';
import { message, Select } from 'antd';

/**
 * 
 * @param {*} iconColor string
 * @description color of the icon (red, blue, black, white, gray), default black
 * @param iconStyle object
 * @description stylos for icon
 * @param selectOptions array
 * @description array of options to be displayed in the select (types inputs) [text, number, email, file]
 * @param values function
 * @description function to get the values of the inputs
 * @param onChange function
 * @description function to be executed when the input changes
 * @param inputType string
 * @description type of the input (text, number, email, file)
 * @param containerStyle object
 * @description containerStyle={{}}
 * 
 * 
 * @returns 
 */
export default function CustomInputLand({iconColor, iconStyle, values, onChange, selectOptions, inputType, containerStyle, defaultValues}) {
  const [inputParams, setInputParams] = useState(defaultValues || [])
  const [params, setParams] = useState({type: 'file', name: ''})
  const [error, setError] = useState({type: false, name: false})
  const [fileTypeSelected, setFileTypeSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const handleParams = (paramsInput = null) => {
    /*if(params.type === '' && !inputType){
      setError({...error, type: true})
      setTimeout(() => {
        setError({...error, type: false})
      }, 3000);
      return;
    }else if(params.name === ''){
      setError({...error, name: true})
      setTimeout(() => {
        setError({...error, name: false})
      }, 3000);
      return;
    }else if(inputParams.find(item => item.name === params.name)){
      setError({...error, name: true})
      setTimeout(() => {
        setError({...error, name: false})
      }, 3000);
      return;
    }*/

    if(inputParams.find(item => item.name === (paramsInput || params).name)){
      return message.error({content: 'Este tipo de archivo ya existe!'});
    }


    setInputParams([...inputParams, paramsInput || params]);
    setParams({type: 'file', name: ''});
  }
  const deleteInput = (i) => {
    setInputParams(inputParams.filter((item, index) => index !== i));
    values(inputParams.filter((item, index) => index !== i));
  }

  const fileTypes = fileLandOptions;

  const onChangeFileType = (value) => {
    setFileTypeSelected(value);
    setParams({...params, name: value});
    handleParams({...params, name: value});
    setFileTypeSelected(null);
  };

  const getValues = (e, i) => {

    let objtValues = null
    if(e.target.type === 'file'){
      let file = e.target.files[0]
      file.nameStep = e.target.name;
      objtValues = {
        type: e.target.type,
        name: e.target.name,
        value: file,
        step: '6',
      }
    }else{
      objtValues = {
        type: e.target.type,
        name: e.target.name,
        value: e.target.value,
        step: '6',
      }
    }
    const newArray = inputParams
    newArray.splice(i, 1, objtValues)
    setInputParams(newArray)
    if(values){
      values(newArray)
    }
  }

  useEffect(() => {
    values(inputParams);
  }, []);

  return (
    <div className='custom-input mt-2' style={containerStyle}>

      <b>*Tipo de archivo</b>
      
      <div className='content'>
        {/*selectOptions && !inputType &&
          <div className={`content-select ${error.type ? 'error-select' : ''}`}>
            <select value={params.type} onChange={(e)=>setParams({...params, type: e.target.value})} style={{cursor: 'pointer'}}>
              <option >Tipo</option>
              {selectOptions.map((item, i) => {
                switch (item) {
                  case 'file':
                    return <option key={i} value="file">Archivo</option>
                  case 'email':
                    return <option key={i} value="email">Correo</option>
                  case 'number':
                    return <option key={i} value="number">Número</option>
                  case 'text':
                    return <option key={i} value="text">Texto</option>
                  default:
                    return null
                }
              })}
            </select>
            <i></i>
          </div>*/}
        <div className={`content-input `}>
        {params.type !== 'file' ? <input className={`${error.name ? 'error-input' : ''}`} value={params.name} onKeyPress={(e)=> {e.key === 'Enter' && handleParams()}} onChange={(e)=>setParams({...params, name: e.target.value.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())})} type="text" placeholder="Ingresar título del campo" />
        : (
          <Select
          showSearch
          placeholder="Seleccione el tipo de archivo"
          optionFilterProp="children"
          onChange={onChangeFileType}
          value={fileTypeSelected}
          open={open}
          onDropdownVisibleChange={(visible) => setOpen(visible)}
          className='landFileType'
          filterOption={(input, option) =>
            ((option && option.label) || '').toLowerCase().includes(input.toLowerCase())
          }
          options={fileTypes}
          />
        )}
        {/*<button onClick={handleParams}>{<PlusCircleOutlined color={iconColor} style={iconStyle}/> }</button>*/}
        </div>
      </div>
      <div className='d-flex align-items-center mt-3 otherButton' role="button" onClick={() => setOpen(true)}>
      <PlusCircleOutlined className="mr-3" />Agregar otro archivo
      </div>
      <InputList values={inputParams} onChange={onChange} deleteInput={deleteInput} getValues={getValues} inputType={inputType}/>
    </div>
  )
}
