import React, { useState,useContext, useEffect } from "react";
import { Modal, message, Row, Col, Divider, Tooltip,Spin  } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ButtonApp } from '../../../button/button';
import {RangeForm} from './RangeForm';
import {senDataRange,editDataRange} from './CreateRange.service';
import {Context} from '../../../../context/RangeContext';
import './style.css';
export const CreateRange = ({ visible, onClose,modeEdit })=>{
  const {
    parameters, 
    isValid,
    clear,
    messageError   
  } = useContext(Context)
  const [isLoading,setIsLoading] = useState(false);

 const handleSubmitData = async ()=>{
  if(isValid()){
    if(!modeEdit){
      setIsLoading(true);
      const response = await senDataRange(parameters);
      if(response){
        message.success('Operacion realizada con exito');
        clear();
        setIsLoading(false);
        onClose(true);
      }else{
        message.error('El rango no pudo ser creado');
        setIsLoading(false);
      }    
    }else{
      setIsLoading(true);
      if(parameters.endRange > parameters.startRange){
        const response = await editDataRange(parameters);
        if(response){
          message.success('Operacion realizada con exito');
          setIsLoading(false);
          clear();
          onClose(true);
        }else{
          setIsLoading(false);
          message.error('El rango no pudo ser editado');
        } 
      }else{
        setIsLoading(false);
        message.error('El rango de inicio no puede ser mayor que el final');
      }
      
    }
  }else{    
    message.error(messageError?messageError:'Debe rellenar el formulario');
    setIsLoading(false);
  }  
 }

  return (
    <>
    <Modal
      title="Crear rango de área"
      width={550}
      style={{ bottom: 0, maxHeight: '', overflow: 'hidden', padding: 0 }}
      visible={visible}
      destroyOnClose
      closeIcon={
        <Tooltip title="Cerrar">
          <CloseOutlined 
            onClick={()=>{
              clear()
              onClose(false)                            
            }} 
            style={{ fontZise: '40px', cursor: 'pointer' }}/>
        </Tooltip>
      }
      bodyStyle={{ height: 'calc(35vh - 55px)' }}
      footer={
        <>
          <div className='button-modal' style={{paddingTop:'100px'}}>          
            <span style={{margin: '0px 8.5%'}}>          
              <ButtonApp
                name='Cancelar'
                onClick={()=>{onClose(false)}}        
                content='Cancelar'
                danger
                cancel
                disabled={isLoading}
              />
            </span>                                        
            <span style={{margin: '0px 8.5%'}}>          
              <ButtonApp
                name='Guardar'
                onClick={handleSubmitData}        
                content={modeEdit?'Editar rango':'Crear rango'}
                primary 
                disabled={isLoading}             
              />
            </span>
          </div>
        </>
      }
      >
        <Spin spinning={isLoading} tip="Loading...">
          <RangeForm modeEdit={modeEdit}/>
        </Spin>
    </Modal >
    </>
  )
}