import React, { useState, useEffect,useContext } from "react";
import { Input, InputNumber, Tooltip } from "antd";
import './AdminPanelRequests.css'
import { MenuOutlined, SettingOutlined } from '@ant-design/icons';
import TableListRequests from './TableListRequests/TableListRequests'
import {
  AppstoreOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import Request from '../../services/Request';
import { ThemeContext } from "../../context/ThemeContext";
import HttpResponseEmun from "../../helpers/enums/HttpResponseEmun";

const AdminPanelRequests = () => {
    const {themeColor} = useContext(ThemeContext)
    const [toggleState, setToggleState] = useState({type: "VALIDATION", label: "Validación"});
    const [datas, setDatas] = useState({})
    const [filter, setFilter] = useState({page: 0, showRows: 10, statusParam: "ACTIVE", ordering: '' });
    const [loading, setLoading] = useState(false)

    useEffect(() => {
           setLoading(true)
      setDatas([])
  async function test() {
      try {
            const response = await Request.get('/developers', { ...filter, stateParam: toggleState.type})
            if (response.status === HttpResponseEmun.OK) {
                setLoading(false)
                setDatas({type: toggleState.type, ...response.data.data})
                setLoading(false)            
            }                    
      } catch (error) {
          error && console.error(error)
      }
      
  }
  test()
  }, [filter, toggleState])
    return (
    <>
        <div className="container">

            <div className="container containerListAndSearchAdmin">
                <div className="blocTabs">

                    <button
                        className={toggleState.type === "VALIDATION" ? "tabsListAdminPanel active-tabsListAdminPanel" : "tabsListAdminPanel"}                        
                        onClick={() => {
                            setToggleState({type: "VALIDATION", label: "Validación"})
                            setFilter({page: 0, showRows: 10, statusParam: "ACTIVE"})
                        }}
                    >
                        <div className={toggleState.type === "VALIDATION" ? "boxBtnSelec" : "boxBtn"} 
                            
                        >Validación</div>
                    </button>
                    <button
                        className={toggleState.type === "VERIFY" ? "tabsListAdminPanel active-tabsListAdminPanel" : "tabsListAdminPanel"}
                        onClick={() => {
                            setToggleState({type: "VERIFY", label: "Verificados"})
                            setFilter({page: 0, showRows: 10, statusParam: "ACTIVE"})
                        }}
                    >
                        <div className={toggleState.type === "VERIFY" ? "boxBtnSelec2" : "boxBtn"} 
                       >Verificados</div>
                    </button>
                    <button
                        className={toggleState.type === "NO_ACCEPT" ? "tabsListAdminPanel active-tabsListAdminPanel" : "tabsListAdminPanel"}
                        onClick={() => {
                            setToggleState({type: "NO_ACCEPT", label: "No aceptados"})
                            setFilter({page: 0, showRows: 10, statusParam: "ACTIVE"})
                        }}
                    >
                        <div className={toggleState.type === "NO_ACCEPT" ? "boxBtnSelec" : "boxBtn"} 
                       >No aceptados</div>
                    </button>
                </div>

            </div>
            <div className='containerListResponsive' >
                <h4 >{toggleState.label}</h4>
                <div >
                    
                <Menu theme='dark' style={{backgroundColor:"#272A30", color: "#fff", borderBottom: 0, minHeight: '40px', minWidth: '50px'}} className='menu'  mode="horizontal" >
                  
                  <Menu.SubMenu key="0" title="" icon={<MenuOutlined />}>
                    <Menu.Item onClick={() => setToggleState({type: "VALIDATION", label: "Validación"})} key={'1'} icon={<AppstoreOutlined />}>
                    Validación
                    </Menu.Item>
                    <Menu.Item onClick={() => setToggleState({type: "VERIFY", label: "Verificados"})} key={'2'}  icon={<AppstoreOutlined />}>
                    Verificados
                    </Menu.Item>
                      <Menu.Item onClick={() => setToggleState({type: "NO_ACCEPT", label: "No aceptados"})} key={'3'} icon={<AppstoreOutlined />}>
                      No aceptados
                      </Menu.Item>
                  </Menu.SubMenu>
                </Menu>
                </div>
            </div>
            <div className="container p-0 container-listUser">
                <TableListRequests datas={datas} setDatas={setDatas} filter={filter} setFilter={setFilter}/>
            </div>
           
        </div>
    </>
    )
}

export default AdminPanelRequests