import React,{ createContext, useState } from 'react';
import { notification } from 'antd';
import {getNotification,readNotification} from '../services/notifications';

const openNotification = (placement,title,description) => {
  notification.info({
    message: title,
    description:description,
    placement,
  });  
};

export const GlobalNotificationContext = createContext();
export const GlobalNotificationProvider = ({ children }) =>{
  
  const [notifications,setNotifications] = useState( [] )

  const desplegateNotification = (data)=>{
    openNotification('bottomRight',data.title,data.message);        
  }
  
  return(
    <GlobalNotificationContext.Provider value={{
      notifications,
      setNotifications,
      desplegateNotification,      
      }}>
          {children}
    </GlobalNotificationContext.Provider>
  )

}