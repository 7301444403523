
import React, {useState, useEffect } from 'react'
import { Drawer, Avatar, Popover, Select, Divider } from 'antd';
import { ButtonApp } from '../../button/button';
import Request from '../../../services/Request';
import { UserOutlined } from '@ant-design/icons';

const { Option } = Select;

const iconclose = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-x-lg" viewBox="0 0 16 16">
    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
</svg>

export default function DetailsDeveloper({ visibleEdit, onCloseEdit, data, type })  {
  //obtener resolucion de pantalla
    const [size, setSize] = useState('500px');
    useEffect(() => {
        window.addEventListener('resize', () => {
            if(window.innerWidth < 520) {
            setSize('100%');
            }else{
            setSize('500px');
            }
        }
        );
    }, [visibleEdit]);
const onClose = () => {
    onCloseEdit()
}
    return (
        <>
            <Drawer
                headerStyle={{ textAlign: 'left', width: '100%' }}
                width={size}
                closeIcon={iconclose}
                maskClosable={false}
                title={type === 'developers' ? 'Detalles del desarrollador' : 'Detalles del Corredor'}
                placement="right"
                onClose={onClose}
                visible={visibleEdit}
                bodyStyle={{overflowX: 'hidden'}}
            >

                <div className="container-fluid container-global">
                        <table className='table-details'>
                            <tbody>
                        {data && type === 'developers' &&
                            <>
                                <tr>
                                <th>
                                    <span>NIT</span>
                                </th>
                                <td>
                                    <span>{data.nit}</span>
                                </td>
                                </tr>
                                <tr>
                                <th>
                                    <span>RAZÓN SOCIAL</span>
                                </th>
                                <td>
                                    <span>{data.businessName}</span>
                                </td>
                                </tr>
                            <tr>
                                <th>
                                    <span>TIPO</span>
                                </th>
                                <td>
                                    <span>{<span>{data.otherType ? (data.otherType.length > 0 ? "Otro / " + data.otherType : null) : data.type}</span>}</span>
                                </td>
                                </tr>
                            <tr>
                                <th>
                                    <span>DEPARTAMENTO</span>
                                </th>
                                <td>
                                    <span>{data.department}</span>
                                </td>
                                </tr>
                            <tr>
                                <th>
                                    <span>CIUDAD</span>
                                </th>
                                <td>
                                    <span>{data.city}</span>
                                </td>
                                </tr>
                            <tr>
                                <th>
                                    <span>DIRECCIÓN</span>
                                </th>
                                <td>
                                    <span>{data.address}</span>
                                </td>
                                </tr>
                            <tr>
                                <th>
                                    <span>CONTACTO</span>
                                </th>
                                <td>
                                    <span>{data.contactPerson}</span>
                                </td>
                                </tr>
                            <tr>
                                <th>
                                    <span>CORREO ALTERNATIVO</span>
                                </th>
                                <td>
                                    <span>{data.alternativeEmail}</span>
                                </td>
                                </tr>
                            <tr>
                                <th>
                                    <span>PÁGINA WEB</span>
                                </th>
                                <td>
                                    <span><a target="_blank" href={data.webSite}>{data.webSite}</a></span>
                                </td>
                                </tr>
                            <tr>
                                <th>
                                    <span>USO DE INTERES</span>
                                </th>
                                <td>
                                    {data.interestUse && <ul>
                                    {data.interestUse.commercial && <li>Comercial</li>}
                                    {data.interestUse.dotacional && <li>Dotacional</li>}
                                    {data.interestUse.industrial && <li>Industrial</li>}
                                    {data.interestUse.noVis && <li>No Vis</li>}
                                    {data.interestUse.vis && <li>Vis</li>}
                                    {data.interestUse.others && <li>Otro / {data.interestUse.others}</li>}
                                    </ul>}
                                </td>
                                </tr>
                                {data.admin && 
                                (<>
                                <Divider/>
                                <span>
                                Datos del Administrador
                                </span>
                                <Divider/>
                                <tr>
                                <th>
                                    <span>NOMBRE</span>
                                </th>
                                <td>
                                    <span>{data.admin.names} {data.admin.lastNames}</span>
                                </td>
                                </tr>
                                <tr>
                                <th>
                                    <span>CORREO</span>
                                </th>
                                <td>
                                    <span>{data.admin.email}</span>
                                </td>
                                </tr>
                                <tr>
                                <th>
                                    <span>DIRECCCIÓN</span>
                                </th>
                                <td>
                                    <span>{data.admin.address}</span>
                                </td>
                                </tr>
                                <tr>
                                <th>
                                    <span>TELÉFONO</span>
                                </th>
                                <td>
                                    <span>{data.admin.phone}</span>
                                </td>
                                </tr>
                                </>)}
                                </>
                        }
                        {data.user && type === 'brokers' &&
                                <>
                                    <tr>
                                        <th>
                                            <span>NOMBRES</span>
                                        </th>
                                        <td>
                                            <span>{data.user.names} {data.user.lastNames}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <span>CORREO</span>
                                        </th>
                                        <td>
                                            <span>{data.user.email}</span>
                                        </td>
                                        </tr>
                                    <tr>
                                        <th>
                                            <span>TELÉFONO</span>
                                        </th>
                                        <td>
                                            <span>{data.user.phone}</span>
                                        </td>
                                        </tr>
                                    <tr>
                                        <th>
                                            <span>DIRECCIÓN</span>
                                        </th>
                                        <td>
                                            <span>{data.user.address}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <span>TIPO</span>
                                        </th>
                                        <td>
                                            <span>{data.user.type}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <span>FECHA DE REGISTRO</span>
                                        </th>
                                        <td>
                                            <span>{data.user.createdAt}</span>
                                        </td>
                                    </tr>
                                </>
                            }
                            </tbody>
                        </table>
                </div>
            </Drawer >
        </>
    )
}