import React, { useState, useContext } from 'react';
import { MinusOutlined, FileSearchOutlined, PoweroffOutlined } from '@ant-design/icons';
import { Divider, Pagination, Empty, Tooltip, message, Popconfirm } from 'antd';
import DetailsDeveloper from '../DetailsPanelRequests/DetailsPanelRequests';
import { Filter } from '../../AdminPanelFilter/AdminPanelFilter';
import { DateTime  } from 'luxon'
import Request from '../../../services/Request';
import sort from '../../handleSort'
import shortText from '../../../helpers/shortText';
import {RequestsAvailableContext} from '../../../context/RequestsAvailableContext';

const thead = {
NO_ACCEPT: [
  {item:'registrationDate', label:'Fecha'},
  {item:'nit', label:'Nit'},
  {item:'businessName', label:'Razón Social'},
  {item:'type', label:'Tipo'},
  {item:'department', label:'Departamento'},
  {item:'city', label:'Ciudad'},
  {item:'error', label:'Motivo'},
  {item:'', label:'Opciones'},
],
VERIFY: [
  {item:'registrationDate', label:'Fecha'},
  {item:'nit', label:'Nit'},
  {item:'businessName', label:'Razón Social'},
  {item:'type', label:'Tipo'},
  {item:'department', label:'Departamento'},
  {item:'city', label:'Ciudad'},
  {item:'', label:'Opciones'},
],
VALIDATION: [
  {item:'registrationDate', label:'Fecha'},
  {item:'nit', label:'Nit'},
  {item:'businessName', label:'Razón Social'},
  {item:'type', label:'Tipo'},
  {item:'department', label:'Departamento'},
  {item:'city', label:'Ciudad'},
  {item:'', label:'Opciones'},
]
}
const TableList = ({datas, setDatas, filter, setFilter}) => {
    const [showModal, setShowModal] = useState(false)
    const [user, setUser] = useState({})
    
    const { incrementRequestsAvailable, decrementRequestsAvailable } = useContext(RequestsAvailableContext);


    const FilterSvg = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filter-left" viewBox="0 0 16 16">
        <path d="M2 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
    </svg>

    const handleSort = (item) =>{
      setFilter({...filter, ordering: sort(item)}) 
    }

    const handleShowDrawerDetails = () => {
        setShowModal(true );
       
    }
    
    const handleCLoseDrawerDetails = () => {
        setShowModal(false);
    }
    const handleStatus = (developer) => {
        const {_id} = developer
          let status = developer.admin.status === 1 ? 'INACTIVE' : 'ACTIVE'
            Request.put(`/developers/${_id}/change-status/users/${status}`)
                .then(res => {
                    setDatas({...datas, content: datas.content.filter(developer => developer._id !== _id)});
                    if(status === "ACTIVE") incrementRequestsAvailable();
                    else decrementRequestsAvailable();
                    message.success({ content: 'Solicitud Exitosa!', duration: 3 });
                }).catch(err => {
                    console.error(err)
                    message.error({content: 'Ocurrio un proble!', duration: 4});
                }
                ) 
      } 
        
    
return (
        <>
            <Filter filterUser={filter} setFilteruser={setFilter} defaultChecked={filter.statusParam} />
            <DetailsDeveloper developer={user} setDeveloper={setUser} datas={datas} setDatas={setDatas} visibleDetails={showModal} onCloseDetails={handleCLoseDrawerDetails} />
         <div >   
            <table >
            <thead className="HeadListUser-admin">
                  <tr >
                    {datas.type && thead[datas.type].map((e, i)=>{
                      if(e.label === 'Motivo'){
                        return(
                          <th key={i} scope="col" className={`col-listUser-adminPanel col6`} onClick={() => { handleSort(e.item) }} style={{ cursor: 'pointer' }}>
                          <span style={{ marginRight: '0.5rem' }} >{e.label}</span>
                          {e.item !== '' && FilterSvg}
                          </th>)
                      }else{
                        return(
                          <th key={i} scope="col" className={`col-listUser-adminPanel col${i+1}`} onClick={() => { handleSort(e.item) }} style={{ cursor: 'pointer' }}>
                          <span style={{ marginRight: '0.5rem' }} >{e.label}</span>
                          {e.item !== '' && FilterSvg}
                          </th>)
                      }
                  })}
                  </tr>
              </thead>
              <tbody>
              <tr className='table-row-s'>
                
              </tr>
              {datas.content && datas.content.length === 0 &&
                <tr>
                  <td colSpan={8}>
                  <Empty 
                    description={
                      <span>
                        No se encontraron datos
                      </span>}
                     image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  
                  </td>
                </tr>}
                {datas.content && datas.content.map((developer, i) => {
                
                return (
              <React.Fragment key={i}>
                <tr className='table-row-body'>
                  <td className='col-listUser-adminPanel col1' scope="row">{DateTime.fromISO(developer.registration.registrationDate).toFormat('dd-MM-yyyy')}</td>
                  <td className='col-listUser-adminPanel col2' scope="row">{developer.nit}</td>
                  <td className='col-listUser-adminPanel col3' scope="row">{developer.businessName.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())}</td>
                  <td className='col-listUser-adminPanel col4' scope="row">{developer.type}</td>
                  <td className='col-listUser-adminPanel col5' scope="row">{developer.department}</td>
                  <td className='col-listUser-adminPanel col6' scope="row">{developer.city.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())}</td>
                  {datas.type === "NO_ACCEPT" &&
                    <td className='col-listUser-adminPanel col6' scope="row">{shortText(developer.registration.reason, 20)}</td>}
                  <td className='col-listUser-adminPanel col7' scope="row">
                    <span className='divider'><MinusOutlined style={{ fontSize: '1.5rem', color: 'gray', transform: 'rotate(90deg)' }} /></span>
                    <span style={{ cursor: 'pointer' }} >
                      <Tooltip placement="top" title='Detalles'>
                          <FileSearchOutlined  onClick={() => {
                            setUser(developer)
                            handleShowDrawerDetails()
                          }} style={{ fontSize: '1.5rem' }} />
                      </Tooltip>
                    </span>
                    <span style={{ margin: '0 5px' }} >
                      <Popconfirm
                        title={`¿Estas seguro que quieres ${filter && filter.statusParam === 'ACTIVE' ? 'Desactivar' : 'Activar'} este usuario?`}
                        onConfirm={()=>handleStatus(developer)}
                        okText="Si"
                        cancelText="No"
                        >
                        <Tooltip placement="top" title={filter && filter.statusParam === 'ACTIVE' ? 'Desactivar' : 'Activar'}>
                        <PoweroffOutlined style={{ cursor: 'pointer', fontSize: '1.5rem', color: filter && filter.statusParam === 'ACTIVE' ? '#FF9100' : '#00B74A'}} />
                        </Tooltip>
                        </Popconfirm>
                    </span>
                  </td>
                </tr>
                <tr >
                  <td colSpan="7">
                    <Divider style={{margin: '2px'}}/>
                  </td>
                </tr>
                
              </React.Fragment>
                  )})}

              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="7" align='right'>
                    <Pagination
                        pageSizeOptions={['10', '20', '50', '100']}
                        current={filter.page +1}
                        onChange={(current, pageSize)=> setFilter({...filter, page: current -1, showRows: pageSize})}
                        defaultCurrent={1}
                        total={datas.totalElements}
                        showTotal={(total) => `Total ${total}`}
                        responsive
                        disabled={datas.totalElements >  datas.showRows ? false : true}
                        showSizeChanger={false}
                      />
                  </td>
                </tr>
                <tr >
                    <td colSpan="7">
                      <Divider style={{marginBottom: '15px'}}/>
                    </td>
                </tr>
              </tfoot>
            </table>
        </div>

        </>
    )
}
export default TableList