import React, {useContext, useState, useEffect} from "react"
import { Col, Divider, Pagination, Row, Select, Slider,InputNumber,Button,Input  } from "antd";
import {PlusOutlined,MinusOutlined} from '@ant-design/icons';
import {Context} from '../../../../context/RangeContext';
import Request from "../../../../services/Request";
const { Option } = Select;
const selectorData =  ['ACTIVE', 'INACTIVE'];
const ranged = {min:0,max:10000}
export const RangeForm = ({modeEdit})=>{
  const {
    parameters,
    setRange,
    setParameters,
    validateParameters    
  } = useContext(Context)
  const [params,setParams] = useState({
      startRange:0,
      endRange:0,
      name:'',
      state:'ACTIVE'
  });
  const [editable,setEditable] = useState(true);

  const [startValue, setStartValue] = useState(0);
  const [endValue, setEndValue]     = useState(0);

  const [startSlideValue, setStartSlideValue] = useState(0);
  const [endSlideValue, setEndSlideValue]     = useState(0);

  const [nameValue, setNameValue] = useState('');
  const [rangeParam,setRangeParam] = useState({min:0,max:1000})


  let StartInput = ''
  let endInput = ''

  useEffect( () => {
    async function setMaxParameter(){
      const AREA_MAX = await Request.get('/system-parameters/AREA_MAX');      
      setRangeParam({min:0,max:AREA_MAX.data.value})
    }
    setMaxParameter()
  },[])

  useEffect( () => {
    setParameters({...parameters,endRange:endSlideValue});        
  },[endValue])

  useEffect( () => {     
    setParameters({...parameters,startRange:startSlideValue});  
    
  },[startValue])
  useEffect( () => {
    setParameters({...parameters,name:nameValue});        
  },[nameValue])

  useEffect( () => {    
    setParameters(params)          
  },[params])

  useEffect( () => {
    
    validateParameters()
  },[parameters])
  const format = (num)=> {    
    let formatNumber = new Intl.NumberFormat("ES-MX", {
      style: "currency",
      currency: "MXN"
    }).format(num)
    formatNumber = formatNumber.replace('.00','')
    formatNumber = formatNumber.replace(',','.');
    formatNumber = formatNumber.replace('$','');
    console.log(formatNumber)        
    return formatNumber;
  }
  const reformat = (stringNum)=>{
    let stringNumber = ''+stringNum;
    stringNumber = stringNumber.replace(',','');
    stringNumber = stringNumber.replace('.','');
    return stringNumber;
  }
  const handleStateChange = (e)=>{
    setParams({...params,state:e});           
  }
  const handleNameChange  = (e)=>{
    const value = e.target.value?e.target.value:'';
    setNameValue(value);    
  }
  const handleStartChange = (e)=>{ 
    const value = e===null?0:e;
    setStartSlideValue(parseInt(value));
    const valueText = format(parseInt(value,10))
    console.log(valueText);
    setStartValue( valueText);                   
  }

  const handleEndChange = (e)=>{
    const value = e===null?0:e;
    setEndSlideValue(parseInt(value,10));
    const valueText = format(parseInt(value,10))
    console.log(valueText);
    setEndValue( valueText);    
  }

  const handleStartInputChange = (e)=>{ 
    const targetValue = e.target.value?e.target.value:0;
    let toNumber = reformat(targetValue);
    let value = parseInt(toNumber,10);
    if(value > rangeParam.max){
      value = rangeParam.max;
    }else if(value < rangeParam.min){
      value = rangeParam.min
    }
    setStartSlideValue(value);
    const valueText = format(value)
    console.log(valueText);
    setStartValue(valueText);    
  }
  const handleEndInputChange = (e)=>{
    const targetValue = e.target.value?e.target.value:0;
    let toNumber = reformat(targetValue);
    let value = parseInt(toNumber,10);
    if(value > rangeParam.max){
      value = rangeParam.max;
    }else if(value < rangeParam.min){
      value = rangeParam.min
    }
    setEndSlideValue(value);
    const valueText = format(value)
    console.log(valueText);
    setEndValue( valueText);
  }

  const incrementHandlers = (onPlus,onMinus,typeRange) => {
    return (
      <div className="stack">
        <Button primary  className="button" onClick={ () => {
         onPlus(typeRange) 
        }}>
          <PlusOutlined />
        </Button>        
        
        <Button danger className="button" onClick={() => {
          onMinus(typeRange)
        }}>
          <MinusOutlined />
        </Button>        
        
      </div>
    )
  }

  const plus =  (typeRange)=>{
    let aux = typeRange==='START'?startSlideValue:endSlideValue;
    aux++
    if(aux > rangeParam.max){
      aux = rangeParam.max;
    }else if(aux < rangeParam.min){
      aux = rangeParam.min;
    }    
    const value =  format(parseInt(aux,10))
    if(typeRange === 'START'){      
      setStartSlideValue(aux);      
      setStartValue(value);      
    }else{
      setEndSlideValue(aux);
      setEndValue(value);
    }
  }
  const minus = (typeRange)=>{
    let aux = typeRange==='START'?startSlideValue:endSlideValue;
    aux--;
    if(aux > rangeParam.max){
      aux = rangeParam.max;
    }else if(aux < rangeParam.min){
      aux = rangeParam.min;
    }    
    const value =  format(parseInt(aux,10))
    if(typeRange === 'START'){      
      setStartSlideValue(aux);
      setStartValue( value);      
    }else{    
      setEndSlideValue(aux);
      setEndValue(value); 
    }
  }

  const rangedInput = (typeRange)=>{
    return (
      <Row>
        <Col span={6}>
            <label className='tit-input'>
              {typeRange==='START'?'Rango Inicio m²':'Rango Final m²' }
              <span className='text-danger'> *</span>                   
            </label>
        </Col>
        <Col span={10}>
          {typeRange==='START' && <Slider 
            defaultValue={rangeParam.min} 
            max={rangeParam.max}            
            onChange={handleStartChange}
            value={typeof startSlideValue === 'number' ? startSlideValue : 0}
            /> } 
          {typeRange==='END' && <Slider 
            defaultValue={rangeParam.min} 
            max={rangeParam.max}
            onChange={handleEndChange}
            value={typeof endSlideValue === 'number' ? endSlideValue : 0}
            /> }             
        </Col>
        <Col span={4}>
          {typeRange==='START' && <Input

            style={{
              margin: '0 16px',
              width:'100%'
            }}
            value={startValue}
            onChange={handleStartInputChange}                             
            controls={false}
          />          
            }
          {typeRange==='END' && <Input

            style={{
              margin: '0 16px',
              width:'100%'
            }}
            value={endValue}     
            onChange={handleEndInputChange}                   
            controls={false}
          />}          
        </Col>
        <Col span={2}>
          {incrementHandlers(plus,minus,typeRange)}
        </Col>
      </Row>
    )
  }

  const nameInput = ()=>{
    return (
    <Row>
      <Col span={6}>
        <label className='tit-input'>
          Nombre
          <span className='text-danger'> *</span>                                       
        </label>
      </Col>     
      <Col span={12}>
        <input
          type='text'
          name='name'
          className= 'form-control form-info-corredor'
          placeholder='Name'
          onChange={handleNameChange} 
          value={nameValue}                   
        />
      </Col>     
    </Row>)
  }

  const stateInput = () =>{
    return (
      <Row>
        <Col span={6}>
          <label className='tit-input'>
            Estado
            <span className='text-danger'> *</span>                                       
          </label>
        </Col>     
        <Col span={12}>
          <Select              
              defaultValue={selectorData[0]}
              style={{
                width: 120,
              }}
              onChange={handleStateChange}
            >
              {selectorData.map((state) => (
                <Option key={state}>{state==='ACTIVE'?'Activo':'Inactivo'}</Option>
              ))}
          </Select> 
        </Col>     
      </Row>)
  }
  useEffect(()=>{
    if(modeEdit && editable){
      
      setEditable(false);
      setParams({...parameters});
      setStartValue(parameters.startRange)
      setEndValue(parameters.endRange)
      setNameValue(parameters.name)     
    }
    setRange(params);
  },[params]
  )
  return (
    <div className='block-from'>      

      <div className='form-row'>
          <div className='form-group'>            
            {nameInput()}            
          </div>
          <div className='form-group'>            
            {rangedInput('START')}                      
          </div>
          <div className='form-group'>            
            {rangedInput('END')}                      
          </div>          
          <div className='form-group'>
            {stateInput()}            
          </div>
      </div>      
            
    </div>
  )
}