import React from 'react'
import { createContext, useState } from 'react'
import ModalRegister from '../components/ModalRegister/ModalRegister'
import ModalGuarda from '../components/ModalGuarda/ModalGuarda'

const ModalsContext = createContext()
const { Provider, Consumer } = ModalsContext

function ModalsProvider({ children }) {
  const [policiesModal, setPoliciesModal] = useState({
    isOpen: false,
    onConfirm: () => {},
    onCancel: () => {},
  })
  const [confirmationMessage, setConfirmationMessage] = useState({
    isOpen: false,
    title: '',
    message: '',
    onConfirm: () => {},
    onCancel: () => {},
  })

  const openPoliciesModalAction = ({
    onConfirm = () => {},
    onCancel = () => {},
  }) => {
    setPoliciesModal({
      isOpen: true,
      onConfirm,
      onCancel,
    })
  }

  const closePoliciesModalAction = () => {
    setPoliciesModal({
      isOpen: false,
      onConfirm: () => {},
      onCancel: () => {},
    })
  }

  const openConfirmationMessageAction = ({
    title = '',
    message = '',
    onConfirm = () => {},
    onCancel = () => {},
  }) => {
    setConfirmationMessage({
      isOpen: true,
      title,
      message,
      onConfirm,
      onCancel,
    })
  }

  const closeConfirmationMessageAction = () => {
    setConfirmationMessage({
      isOpen: false,
      title: '',
      message: '',
      onConfirm: () => {},
      onCancel: () => {},
    })
  }

  return (
    <Provider
      value={{
        policiesModal,
        openPoliciesModalAction,
        closePoliciesModalAction,
        confirmationMessage,
        openConfirmationMessageAction,
        closeConfirmationMessageAction,
      }}
    >
      {children}
      <ModalRegister />
      <ModalGuarda />
    </Provider>
  )
}

export { ModalsProvider, Consumer as ModalsConsumer, ModalsContext }
