import React, { useState, useRef } from 'react'
import { CloseOutlined } from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';
import SwitchButton from '../../components/Switch/SwitchButton'
import agregarIcon from '../../assets/icons/agregar.png'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useAuth } from '../../context/AuthContext'
import useModals from '../../hooks/useModals'
import { updateDeveloperPersonalInfo } from '../../services/developers'
import useToasts from '../../hooks/useToasts'
import FormNewUser from './FormNewUser'
import ChangePassword from '../ChangePassword'
import {CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined} from '@ant-design/icons'
import { verifyEmail } from '../../services/auth'
import { message } from 'antd'
import CreatedUserModal from '../CreatedUserModal/CreatedUserModal'
import EditUserModal from '../EditUserModal/EditUserModal';

const FormEditDePersonal = () => {
  const { user, userData, setUser } = useAuth()
  const [isToggled, setIsToggled] = useState((userData && userData.publicProfile !== false))
  const { openConfirmationMessage } = useModals()
  const { addToast } = useToasts()
  const [newUserToggle, setNewUserToggle] = useState(false)
  const [changePasswordToggle, setChangePasswordToggle] = useState(false)
  const [emailStatus, setEmailStatus] = useState(null)
  const [selectedUser, setSelectedUser] = useState({user:null, type:null});
  
  const closeModal = () => setSelectedUser({user:null, type:null});
  const openEditModal = () => setSelectedUser({user:selectedUser.user, type:"edit"});

  const refInputEmail = useRef(null)
  const handleEmail = async(e) =>{
    formik.handleChange(e)
    clearTimeout(refInputEmail.current)
    refInputEmail.current = setTimeout(async() => {
      setEmailStatus('loading')
           const res = await verifyEmail(e.target.value)
      setEmailStatus(res ? 'available' : 'not available')
      //clearTimeout(refInputEmail.current )
    }, 1000)
    //const res = await verifyEmail(email)
    
  }

  const VerifyEmailIcon = () => {
    switch (emailStatus) {
      case 'loading':
        return <LoadingOutlined style={{ fontSize: 15, color: "#39C0ED" }} spin className="input-icon"/>
      case 'available':
        return <CheckCircleOutlined style={{fontSize: 15, color: "#00B74A"}} className="input-icon"/>
      case 'not available':
        return <CloseCircleOutlined style={{fontSize: 15, color: "#F93154"}} className="input-icon"/>
      default:
        return null;
    }
  }

  const onSubmit = (formData) => {
    if(emailStatus === 'available' || emailStatus === null){
    const developerDataToUpdate = {
      alternativeEmail: formData.correoAlternativo,
      publicProfile: isToggled,
    }
    let userDataToUpdate = {}
    if(emailStatus !== null){
      userDataToUpdate = {
        names: formData.nombre,
        lastNames: formData.apellido,
        phone: formData.telefono,
        email: formData.email,
      }

    }else{
      userDataToUpdate = {
        names: formData.nombre,
        lastNames: formData.apellido,
        phone: formData.telefono,
      }
    }

    openConfirmationMessage({
      message: '¿Quieres guardar los cambios?',
      onConfirm: () => {
        updateDeveloperPersonalInfo(
          userData._id,
          developerDataToUpdate,
          user.id,
          userDataToUpdate
        )
          .then((response) => {
            if(response){
              message.success('Se han guardado los cambios');
              setUser({...user, ...userDataToUpdate})
            }else{
              throw '';
            }
          })
          .catch((e) => {
            message.error('No se pudieron guardar los cambios');
          })
      },
    })
  }else if(emailStatus === 'not available'){
    message.error('Este correo electrónico ya se encuentra registrado');
  }
  }

  const validationSchema = Yup.object().shape({
    nombre: Yup.string().required('Este campo es obligatorio'),
    apellido: Yup.string().required('Este campo es obligatorio'),
    email: Yup.string().required('Este campo es obligatorio'),
  })

  const formik = useFormik({
    initialValues: {
      nombre: (userData && userData.names) || '',
      apellido: (userData && userData.lastNames) || '',
      telefono: (userData && userData.phone) || '',
      correoAlternativo: (userData && userData.alternativeEmail) || '',
      email: (userData && userData.email) || '',
    },
    enableReinitialize: true,

    onSubmit,

    validationSchema,
  })

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className='row'>
          <div className='col-sm-6'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label className='tit-input titEditCorredor'>
                  Nombre<span className='text-danger'> *</span>
                </label>
                <input
                  type='text'
                  name='nombre'
                  className={
                    formik.errors.nombre
                      ? 'form-control form-info-corredorError'
                      : 'form-control form-info-corredor'
                  }
                  placeholder='Jonh'
                  onChange={formik.handleChange}
                  value={formik.values.nombre}
                />
              </div>
            </div>
            {formik.errors.nombre ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.nombre}
              </div>
            ) : null}
          </div>
          <div className='col-sm-6'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label className='tit-input titEditCorredor'>
                  Apellido<span className='text-danger'> *</span>
                </label>
                <input
                  type='text'
                  name='apellido'
                  className={
                    formik.errors.apellido
                      ? 'form-control form-info-corredorError'
                      : 'form-control form-info-corredor'
                  }
                  placeholder='Jonh'
                  onChange={formik.handleChange}
                  value={formik.values.apellido}
                />
              </div>
            </div>
            {formik.errors.apellido ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.apellido}
              </div>
            ) : null}
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-6'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label className='tit-input titEditCorredor'>Correo</label>
                <div className="input-wrapper">
                    <input
                      ref={refInputEmail}
                      type='text'
                      name='email'
                      style={{paddingRight: '35px'}}
                      className={
                        formik.errors.email
                          ? 'form-control form-info-corredorError'
                          : 'form-control form-info-corredor'
                      }
                      placeholder='Admin@DoDes.com'
                      onChange={handleEmail}
                      value={formik.values.email}
                    />
                    <VerifyEmailIcon/>
                  </div>
              </div>
            </div>
            {formik.errors.email ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.email}
              </div>
            ) : null}
          </div>
          <div className='col-sm-6'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label className='tit-input titEditCorredor'>
                  Teléfono de contacto<span className='text-danger'> *</span>
                </label>
                <input
                  type='number'
                  name='telefono'
                  className={
                    formik.errors.telefono
                      ? 'form-control form-info-corredorError'
                      : 'form-control form-info-corredor'
                  }
                  placeholder='3005479854'
                  onChange={formik.handleChange}
                  value={formik.values.telefono}
                />
              </div>
            </div>
            {formik.errors.telefono ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.telefono}
              </div>
            ) : null}
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-6'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label className='tit-input titEditCorredor'>
                  Correo alternativo<span className='text-danger'> *</span>
                </label>
                <input
                  type='text'
                  name='correoAlternativo'
                  className={
                    formik.errors.correoAlternativo
                      ? 'form-control form-info-corredorError'
                      : 'form-control form-info-corredor'
                  }
                  placeholder='Admin@DoDes.com'
                  onChange={formik.handleChange}
                  value={formik.values.correoAlternativo}
                />
              </div>
            </div>
            {formik.errors.correoAlternativo ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.correoAlternativo}
              </div>
            ) : null}
          </div>
          <div className='col-sm-6'></div>
        </div>

        <div className='row'>
          <div className='col'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label
                  className='tit-input titEditContrasena'
                  role='button'
                  onClick={() => {
                    setChangePasswordToggle((last) => !last)
                  }}
                >
                  Cambiar contraseña
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <ChangePassword
              show={changePasswordToggle}
              setShow={setChangePasswordToggle}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <hr className='LineaFormCo'></hr>
          </div>
        </div>

        {userData &&
          userData.users.map((user, i) => (
            <div key={i}>
              <div className='row'>
                <div className='col-sm-6'>
                  <div className='user-name' onClick={() => setSelectedUser({user, type:"details"})}>
                    {user.names} {user.lastNames}
                  </div>
                </div>
              </div>
            </div>
          ))}

        <FormNewUser
          newUserToggle={newUserToggle}
          close={() => setNewUserToggle(false)}
        />

        {!newUserToggle && (
          <div className='row'>
            <div className='col'>
              <div className='form-row'>
                <div className='form-group col-md-10'>
                  <label
                    className='tit-input titEditNuevoUser'
                    onClick={() => {
                      setNewUserToggle(true)
                    }}
                  >
                    <img
                      src={agregarIcon}
                      className='iconagregar'
                      alt='Registrar Nuevo Usuario'
                    />
                    Registrar nuevo Usuario
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className='row'>
          <div className='col'>
            <hr className='LineaFormCo'></hr>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label className='tit-input'>Perfil público</label>
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <div className='form-row'>
              <div className='form-group col-md-10 tit-PerfilP'>
                <SwitchButton
                  isToggled={isToggled}
                  onToggle={() => setIsToggled(!isToggled)}
                />{' '}
                Quiero que mi perfil sea público y que los corredores
                puedan ver mi información
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label className='tit-input'>Notificaciones de cambio</label>
                <p className='tit-msg'>
                  Quiero que me notifiquen cuando haya cambios en la plataforma
                  por medio del
                </p>
                <div className='control-group'>
                  <label className='control control-radio'>
                    Correo Email
                    <input type='radio' name='notiCam' defaultChecked />
                    <div className='control_indicator'></div>
                  </label>
                  <label className='control control-radio'>
                    Perfil Urban 360
                    <input type='radio' name='notiCam' />
                    <div className='control_indicator'></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <div className='col btn-holderSiguienteCenter'>
              <button type='submit' className='btn btn-endNext'>
                GUARDAR CAMBIOS
              </button>
            </div>
          </div>
        </div>
      </form>

      <div>
          <Modal
            width={700}
            visible={selectedUser.user}
            destroyOnClose
            closeIcon={
              <Tooltip title="Cerrar">
                <CloseOutlined onClick={closeModal} style={{ fontZise: '30px', color: '#39C0ED', width: '100%', height: '100%', display: 'block', paddingTop: '15px'  }}/>
              </Tooltip>
            }
            footer=''
            centered
            >

            {selectedUser.type === "details" && <CreatedUserModal user={selectedUser.user} closeModal={closeModal} openEditModal={openEditModal} />}
            {selectedUser.type === "edit" && <EditUserModal user={selectedUser.user} closeModal={closeModal} />}

          </Modal>
        </div>

      

    </div>
  )
}

export default FormEditDePersonal
