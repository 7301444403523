import React, {useState} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { message } from 'antd';
import SwitchButton from '../../components/Switch/SwitchButton'
import { updateUser } from '../../services/developers';
import { ButtonApp } from '../button/button';
import { useAuth } from '../../context/AuthContext';
import './EditUserModal.css';
import { useCallback } from 'react';
import { getAllPermissions } from '../../services/permission';
import updateUserPermissions from '../../services/assignPermissionsToUser';
import HttpResponseEmun from '../../helpers/enums/HttpResponseEmun';
import { useEffect } from 'react';

const initialStatePermissions = {
  P0001: false,
  P0002: false,
  P0003: false,
  P0004: false,
  P0005: false,
  P0006: false,
  P0007: false,
}

function EditUserModal({user, closeModal}) {

    const { refreshUser } = useAuth();
    const [permissions, setPermissions] = useState(initialStatePermissions);
    const [permissionIds, setPermissionIds] = useState([]);
    const [permissionData, setPermissionData] = useState([]);

    const validationSchema = Yup.object().shape({
        names: Yup.string().required('Este campo es obligatorio'),
        lastNames: Yup.string().required('Este campo es obligatorio'),
        email: Yup.string()
        .email('Correo no válido')
        .required('Correo tiene que ser obligatorio'),
        phone: Yup.string()
        .required('El teléfono tiene que ser obligatorio'),
        password: Yup.string()
          .matches(
            /^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            'Contraseña No válida'
          ),
        repeatPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
    });

    const getPermissions = useCallback(async () => {
      const permissionsData = await getAllPermissions();
      if(permissionsData.status === HttpResponseEmun.OK){
          setPermissionData(permissionsData.data.content);

          const permissionListData = permissionsData.data.content.filter((permission) => {
            return user.permissions.includes(permission._id)
          });

          let prevPermissions = initialStatePermissions;

          for (const permission of permissionListData) {
            prevPermissions = {...prevPermissions, [permission.code]: true}
          }

          setPermissions(prevPermissions);

      }
    }, [setPermissionData]);

    const onSubmit = async (formData) => {

        const updateFormData = {};

        for (const field in formData) {
            if(formData[field] && formData[field] !== "" && field !== 'email') {
                updateFormData[field] = formData[field];
            }
        }

        try {
            const res = await updateUser(user._id, updateFormData);
            await updateUserPermissions(user._id, permissionIds);
            if(res){
                message.success({content: `El usuario ${user.names} ${user.lastNames} ha sido actualizado con éxito`, duration: 3});
            } else {
                message.error({content: `¡Hubo un error al actualizar el usuario ${user.names} ${user.lastNames}!`, duration: 4});
            }
        } catch (error) {
            message.error({content: `¡Hubo un error al actualizar el usuario ${user.names} ${user.lastNames}!`, duration: 4});
        } finally {
            refreshUser();
            closeModal();
        }

    }
    
    const formik = useFormik({
        initialValues: {
            names: user.names,
            lastNames: user.lastNames,
            phone: user.phone,
            email: user.email,
            password: '',
            repeatPassword: '',
        },
        enableReinitialize: true,
        onSubmit,
        validationSchema,
    });

    const applyPermissionKey = useCallback((key) => {
      const permission = permissionData.find(item => item.code === key);
      const permissionId = permission._id;
      setPermissionIds((prev) => {
        if(prev.includes(permissionId)) return prev;
        return([...prev, permissionId])
      });
    }, [permissionData]);
  
    const removePermissionKey = useCallback((key) => {
      const permission = permissionData.find(item => item.code === key);
      if(!permission) return;
      const permissionId = permission._id;
      setPermissionIds((prev) => prev.filter(item => item !== permissionId));
    }, [permissionData]);
  
    useEffect(() => {
      for (const key in permissions) {
        const isPermissionApplied = permissions[key];
        if (isPermissionApplied) {
          applyPermissionKey(key); 
        } else {
          removePermissionKey(key);
        }
      }
    }, [permissions, applyPermissionKey]);
  
    useEffect(() => {
      getPermissions();
    }, []);

    return (
        <div className={`new-user-section`}>
          <h4>Editar usuario</h4>
          <form>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='form-row'>
                  <div className='form-group col-md-10'>
                    <label className='tit-input titEditCorredor'>
                      Nombre <span className='text-danger'> *</span>
                    </label>
                    <input
                      type='text'
                      name='names'
                      className={
                        formik.errors.names
                          ? 'form-control form-info-corredorError'
                          : 'form-control form-info-corredor'
                      }
                      placeholder='Jonh'
                      onChange={formik.handleChange}
                      value={formik.values.names}
                    />
                  </div>
                </div>
                {formik.errors.names ? (
                  <div className='form-label Mensaje-ErrorCorredor'>
                    {formik.errors.names}
                  </div>
                ) : null}
              </div>
              <div className='col-sm-6'>
                <div className='form-row'>
                  <div className='form-group col-md-10'>
                    <label className='tit-input titEditCorredor'>
                      Apellido <span className='text-danger'> *</span>
                    </label>
                    <input
                      type='text'
                      name='lastNames'
                      className={
                        formik.errors.lastNames
                          ? 'form-control form-info-corredorError'
                          : 'form-control form-info-corredor'
                      }
                      placeholder='Smith'
                      onChange={formik.handleChange}
                      value={formik.values.lastNames}
                    />
                  </div>
                </div>
                {formik.errors.lastNames ? (
                  <div className='form-label Mensaje-ErrorCorredor'>
                    {formik.errors.lastNames}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='form-row'>
                  <div className='form-group col-md-10'>
                    <label className='tit-input titEditCorredor'>
                      Correo <span className='text-danger'> *</span>
                    </label>
                    <input
                      type='email'
                      name='email'
                      className={
                        formik.errors.email
                          ? 'form-control form-info-corredorError'
                          : 'form-control form-info-corredor'
                      }
                      placeholder='example@example.com'
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      style={{opacity: .5}}
                      disabled={true}
                    />
                  </div>
                </div>
                {formik.errors.email ? (
                  <div className='form-label Mensaje-ErrorCorredor'>
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
              <div className='col-sm-6'>
                <div className='form-row'>
                  <div className='form-group col-md-10'>
                    <label className='tit-input titEditCorredor'>
                      Teléfono de contacto <span className='text-danger'> *</span>
                    </label>
                    <input
                      type='text'
                      name='phone'
                      className={
                        formik.errors.phone
                          ? 'form-control form-info-corredorError'
                          : 'form-control form-info-corredor'
                      }
                      placeholder='(+57)(xxx) xxx xx xx'
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                    />
                  </div>
                </div>
                {formik.errors.phone ? (
                  <div className='form-label Mensaje-ErrorCorredor'>
                    {formik.errors.phone}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='form-row'>
                  <div className='form-group col-md-10'>
                    <label className='tit-input titEditCorredor'>
                      Contraseña
                    </label>
                    <input
                      type='password'
                      name='password'
                      className={
                        formik.errors.password
                          ? 'form-control form-info-corredorError'
                          : 'form-control form-info-corredor'
                      }
                      placeholder='*********'
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                  </div>
                </div>
                {formik.errors.password ? (
                  <div className='form-label Mensaje-ErrorCorredor'>
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>
              <div className='col-sm-6'>
                <div className='form-row'>
                  <div className='form-group col-md-10'>
                    <label className='tit-input titEditCorredor'>
                      Repite la contraseña
                    </label>
                    <input
                      type='password'
                      name='repeatPassword'
                      className={
                        (formik.errors.repeatPassword && formik.touched.repeatPassword)
                          ? 'form-control form-info-corredorError'
                          : 'form-control form-info-corredor'
                      }
                      placeholder='**********'
                      onChange={formik.handleChange}
                      value={formik.values.repeatPassword}
                    />
                  </div>
                </div>
                {(formik.errors.repeatPassword && formik.touched.repeatPassword) ? (
                  <div className='form-label Mensaje-ErrorCorredor'>
                    {formik.errors.repeatPassword}
                  </div>
                ) : null}
              </div>
            </div>


            <h6 className='mt-4'>Permisos del usuario</h6>

            <div className="permissions" style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>

              <div className='row'>
                <div className='col'>
                  <div className='form-row'>
                    <div className='form-group'>
                      <SwitchButton
                        isToggled={permissions.P0001}
                        onToggle={() => setPermissions({...permissions, P0001: !permissions.P0001})}
                      />{' '}
                      Puede registrar nuevos lotes propios
                    </div>
                    <div className='form-group'>
                      <SwitchButton
                        isToggled={permissions.P0002}
                        onToggle={() => setPermissions({...permissions, P0002: !permissions.P0002})}
                      />{' '}
                      Puede agregar nuevos usuarios
                    </div>
                    <div className='form-group'>
                      <SwitchButton
                        isToggled={permissions.P0003}
                        onToggle={() => setPermissions({...permissions, P0003: !permissions.P0003})}
                      />{' '}
                      Puede crear nuevas solicitudes
                    </div>
                    <div className='form-group'>
                      <SwitchButton
                        isToggled={permissions.P0004}
                        onToggle={() => setPermissions({...permissions, P0004: !permissions.P0004})}
                      />{' '}
                      Puede entrar a buscar nuevos lotes
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col'>
                  <div className='form-row'>
                    <div className='form-group'>
                      <SwitchButton
                        isToggled={permissions.P0005}
                        onToggle={() => setPermissions({...permissions, P0005: !permissions.P0005})}
                      />{' '}
                      Puede contactarse con los aliados
                    </div>
                    <div className='form-group'>
                      <SwitchButton
                        isToggled={permissions.P0006}
                        onToggle={() => setPermissions({...permissions, P0006: !permissions.P0006})}
                      />{' '}
                      Puede Editar/Eliminar Lotes
                    </div>
                    <div className='form-group'>
                      <SwitchButton
                        isToggled={permissions.P0007}
                        onToggle={() => setPermissions({...permissions, P0007: !permissions.P0007})}
                      />{' '}
                      Puede modificar la información de la empresa
                    </div>
                  </div>
                </div>
              </div>

            </div>



            <div className="d-flex justify-content-center">
                <ButtonApp 
                disabled={!formik.isValid}
                type="submit"
                onClick={formik.handleSubmit}
                primary 
                content="Actualizar" />
            </div>
    
          </form>
        </div>
      );
}

export default EditUserModal
