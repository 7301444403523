import React from 'react';
import { message, Modal, Tooltip } from 'antd';
import { StopTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
import { changeAssociateStatus } from '../../services/allies';
import './BlockButton.css';

const { confirm } = Modal;


function BlockButton({userId, name, refresh, style}) {

    const blockUser = async () => {
        try {
            await changeAssociateStatus(userId);
            message.success(`El usuario ${name} ha sido bloqueado correctamente`);
            refresh();
        } catch (error) {
            message.error(`Hubo un error al bloquear el usuario ${name}`);
        }
    }

    const showConfirm = (title,description,onConfirm) => {
        confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            content: description,
            cancelText:'Cancelar',
            okText:'Continuar',
            onOk() {
            onConfirm();
            },
        
            onCancel() {
            console.log('Cancel');
            },
        });
    };

    const blockButtonHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();

        showConfirm(
            `¿Quieres bloquear a ${name}?`,
            `No podras responder este chat, ni veras la información, lotes o requerimientos`,
            blockUser
        );
    }

    return (
        <Tooltip title="Bloquear">
            <StopTwoTone style={style} onClick={blockButtonHandler} className='stop-icon' twoToneColor="#eb2f96" />                 
        </Tooltip>
    )
}

export default BlockButton;