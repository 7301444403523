import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { verifyEmail } from '../../services/auth'
import { useRegister } from '../../context/RegisterContext'
import useToasts from '../../hooks/useToasts'
import CircularProgress from '@material-ui/core/CircularProgress';
import verContra from '../../assets/icons/visualizar.png'
import NoverContra from '../../assets/icons/novisualizar.png'
import { Button, notification, Space } from 'antd';
import UserModelsEnum from '../../helpers/enums/UserModelsEnum'
import Loading from '../Loading/Loading'

const RegisterDesarrollador = () => {
  const { setUserToRegister, setUserType, setStep } = useRegister()
  const { addToast } = useToasts()
  const [emailDisabled , setEmailDisabled] = useState(false);
  const [showLoading , setShowLoading] = useState(false);
  const [passwordDisabled , setPasswordDisabled] = useState(true);  
  const [isCalled,setIsCalled] = useState(false);
  const [timerCalledInterval,setTimerCalledInterval] = useState();
  let timerCalled = ()=>{}  
  

  const openNotificationWithIcon = (type,description,title) => {
    notification[type]({
      message: title,
      description:description       
    });
  };
  const onSubmit = (formData) => {
    setShowLoading(true)
    verifyEmail(formData.email)
      .then(function (result) {
        if (result) {
          setUserToRegister((userToRegister) => ({
            ...userToRegister,
            email: formData.email,
            password: formData.password,
          }))
          setUserType(UserModelsEnum.Developer)
          setStep(1)
          setShowLoading(false)
        } else {
          addToast({
            type: 'error',
            title: 'Error',
            message: 'El correo electrónico ya existe',
          })
          setShowLoading(false)
        }
      })
      .catch(function (err) {
        addToast({
          type: 'error',
          title: 'Error',
          message: err.message,
        })
      })
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Correo no válido')
      .required('Correo tiene que ser obligatorio'),
    password: Yup.string()
      .required('La contraseña es requerida')
      .matches(
        /^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Contraseña No válida'
      ),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
      .required('Es necesario que confirme la contraseña'),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      repeatPassword: '',
    },

    onSubmit,

    validationSchema,
  })

  var correoError = ''
  var correoErrorTiene = ''
  if (formik.errors.email) {
    correoError = 'form-control style--inputs--login-Error icoInput1'
    correoErrorTiene = 'YES'
  } else {
    correoError = 'form-control style--inputs--login icoInput1'
    correoErrorTiene = ''
  }

  var contraError = ''
  var contraErrorTiene = ''
  var btnver = ''
  let Acc = {
    estado: 'Inicial',
  }
  if (formik.errors.password) {
    Acc.estado = 'no correcto'
    contraError =
      'form-control style--inputs--login-Error icoInput2 inputVerContra'
    btnver = 'imgVisualizarPSW style--inputs--login-Error'
  } else {
    contraError = 'form-control style--inputs--login icoInput2 inputVerContra'
    btnver = 'imgVisualizarPSW style--inputs--login'
    if (formik.values.password) {
      Acc.estado = 'Correcto'
    } else {
      Acc.estado = 'Inicial'
    }
  }
  if (correoErrorTiene == 'YES') {
    contraErrorTiene = ''
  } else {
    if (formik.values.password) {
      contraErrorTiene = 'YES'
    }
  }

  var repeatPError = ''
  var repeatPErrorTiene = ''
  var vercontra = ''
  if (formik.errors.repeatPassword) {
    repeatPError =
      'form-control style--inputs--login-Error icoInput2 inputVerContra'
    repeatPErrorTiene = 'YES'
    vercontra = 'imgVisualizarPSW style--inputs--login-Error'
  } else {
    repeatPError = 'form-control style--inputs--login icoInput2 inputVerContra'
    repeatPErrorTiene = ''
    vercontra = 'imgVisualizarPSW style--inputs--login'
  }

  const [estadoVisualizar, setEstadoVisualizar] = useState(false)
  const [estadoVisualizarRepiteContra, setEstadoVisualizarRepiteContra] =
    useState(false)

  const handleChange = (event)=>{
    if(timerCalledInterval){
           clearTimeout(timerCalledInterval);
    }
    formik.handleChange(event);
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value)){      
      setPasswordDisabled(true);      
    }else{

      timerCalled = (
        ()=>{
          setEmailDisabled(true);
          verifyEmail(event.target.value)
          .then(  
            function (result){
              
              if(result){
                setPasswordDisabled(false);
                openNotificationWithIcon('success','Este correo se encuentra disponible y puede ser utilizado','Correo disponible');
              }else{
                setPasswordDisabled(true);
                openNotificationWithIcon('error','Este correo no se encuentra disponible','Correo en uso');
              }
              setEmailDisabled(false); 
              clearTimeout(timerCalledInterval)
              setIsCalled(false);         
            })
          .catch(
            function (err) {              
              setPasswordDisabled(true);
              setEmailDisabled(false);
              openNotificationWithIcon('error',err.message,'Fallo al enviar la peticion');
              addToast({
                type: 'error',
                title: 'Error',
                message: err.message,
              })
              clearTimeout(timerCalledInterval)
              setIsCalled(false);
            }
          )
          clearTimeout(timerCalledInterval)
        }
      );

      if(isCalled){
               clearTimeout(timerCalledInterval);
        setTimerCalledInterval(setTimeout(timerCalled,1000))
        
      }else{
        setTimerCalledInterval(setTimeout(timerCalled,1000))        
        setIsCalled(true);                 
      }
    }
  }

  return (
    <div>      
      <Loading isVisible={showLoading}/>  
      <form onSubmit={formik.handleSubmit}>
        <div className='container--texts--login'>
          <label className='subtitle--login form-label Mensaje-Error2'>
            *Correo electronico 
          </label>
          {
            emailDisabled &&
            <CircularProgress
            size={24}
            sx={{              
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
            />
          }                  
        </div>
        <div className='input-group mb-2'>
          <input
            className={correoError}
            name='email'
            type='email'
            placeholder='micorreo@correo.com 2'
            onChange={handleChange}
            disabled={emailDisabled}
          />
        </div>

        {correoErrorTiene ? (
          <div className='form-label Mensaje-Error'>{formik.errors.email}</div>
        ) : null}

        <div className='container--texts--login'>
          <label className='subtitle--login form-label Mensaje-Error2'>*Contraseña</label>
        </div>
        <div className='input-group mb-2 pruebasinputpsw'>
          <input
            name='password'
            type={estadoVisualizar == false ? 'password' : 'text'}
            className={contraError}
            placeholder='********'
            onChange={formik.handleChange}
            disabled={passwordDisabled}
          />
          <label className={btnver}>
            <img
              src={estadoVisualizar == false ? NoverContra : verContra}
              alt='Ver Constraseña'
              className='imgVerContra'
              onClick={() => setEstadoVisualizar(!estadoVisualizar)}
            />
          </label>
        </div>

        {contraErrorTiene ? (
          <div className='form-label Mensaje-Error'>
            {formik.errors.password}
          </div>
        ) : null}

        <div
          className={
            Acc.estado == 'Inicial'
              ? 'form-label Mensaje-Error2'
              : Acc.estado == 'Correcto'
              ? 'form-label Mensaje-Acc'
              : 'form-label Mensaje-Error'
          }
        >
          Tu contraseña debe tener mínimo 8 carácteres
        </div>
        <div
          className={
            Acc.estado == 'Inicial'
              ? 'form-label Mensaje-Error2'
              : Acc.estado == 'Correcto'
              ? 'form-label Mensaje-Acc'
              : 'form-label Mensaje-Error'
          }
        >
          Tu contraseña debe tener mínimo 1 Mayúscula
        </div>
        <div
          className={
            Acc.estado == 'Inicial'
              ? 'form-label Mensaje-Error2'
              : Acc.estado == 'Correcto'
              ? 'form-label Mensaje-Acc'
              : 'form-label Mensaje-Error'
          }
        >
          Tu contraseña debe tener mínimo 1 carácter especial
        </div>

        <div className='container--texts--login'>
          <label className='subtitle--login form-label Mensaje-Error2'>
            *Repite contraseña
          </label>
        </div>
        <div className='input-group mb-2 pruebasinputpsw'>
          <input
            name='repeatPassword'
            type={estadoVisualizarRepiteContra == false ? 'password' : 'text'}
            className={repeatPError}
            placeholder='********'
            onChange={formik.handleChange}
            disabled={passwordDisabled}
          />
          <label className={vercontra}>
            <img
              src={
                estadoVisualizarRepiteContra == false ? NoverContra : verContra
              }
              alt='Ver Constraseña'
              className='imgVerContra'
              onClick={() =>
                setEstadoVisualizarRepiteContra(!estadoVisualizarRepiteContra)
              }
            />
          </label>
        </div>
        {repeatPErrorTiene ? (
          <div className='form-label Mensaje-Error'>
            {formik.errors.repeatPassword}
          </div>
        ) : null}

        <button
          className={
            formik.isValid && formik.values.email && !passwordDisabled
              ? 'button--register2-Active'
              : 'button--register2 disabled-link'
          }
          type='submit'
        >
          REGISTRARME
        </button>
      </form>
    </div>
  )
}

export default RegisterDesarrollador
