import Request from './Request';

export default async function getLandRequest(id) {
   
    try {
                 const response = await Request.get(`/land-requests/${id}`)
                       return response
        
    } catch (error) {
        error && console.error(error)
        return error
    }
    
  }