import React, { useState, useRef } from 'react'
import { Switch, message } from 'antd';
import {CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined} from '@ant-design/icons'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useAuth } from '../../context/AuthContext'
import useModals from '../../hooks/useModals'
import { updateBroker } from '../../services/brokers'
import useToasts from '../../hooks/useToasts'
import ChangePassword from '../ChangePassword'
import Request from '../../services/Request';
import './index.css'
import { verifyEmail } from '../../services/auth'

const FormEditCorredor = () => {
  const { user, userData, setUser } = useAuth()
  const { openConfirmationMessage } = useModals()
  const [emailStatus, setEmailStatus] = useState(null)
  const { addToast } = useToasts()
  const [changePasswordToggle, setChangePasswordToggle] = useState(false)

  const refInputEmail = useRef(null)
  const handleEmail = async(e) =>{
    formik.handleChange(e)
    clearTimeout(refInputEmail.current)
    refInputEmail.current = setTimeout(async() => {
      setEmailStatus('loading')
           const res = await verifyEmail(e.target.value)
      setEmailStatus(res ? 'available' : 'not available')
    }, 1000)
    
  }
  const VerifyEmailIcon = () => {
    switch (emailStatus) {
      case 'loading':
        return <LoadingOutlined style={{ fontSize: 15, color: "#39C0ED" }} spin className="input-icon"/>
      case 'available':
        return <CheckCircleOutlined style={{fontSize: 15, color: "#00B74A"}} className="input-icon"/>
      case 'not available':
        return <CloseCircleOutlined style={{fontSize: 15, color: "#F93154"}} className="input-icon"/>
      default:
        return null;
    }
  }
  const onSubmit = (formData) => {
    if(emailStatus === 'available' || emailStatus === null){
      
    let userDataToUpdate = {}
    if(emailStatus !== null){
      userDataToUpdate = {
        names: formData.nombre,
        lastNames: formData.apellido,
        phone: formData.telefono,
        address: formData.address,
        email: formData.email,
      }

    }else{
      userDataToUpdate = {
        names: formData.nombre,
        lastNames: formData.apellido,
        phone: formData.telefono,
        address: formData.address,
      }
    }

    openConfirmationMessage({
      message: '¿Quieres guardar los cambios?',
      onConfirm: () => {
        Request.put(`/users/${user.id}`, {...userDataToUpdate})
          .then((response) => {
            if(response){
              message.success('Se han guardado los cambios');
              setUser({...user, ...userDataToUpdate})
            }else{
              throw '';
            }
          })
          .catch((e) => {
            message.error('No se pudieron guardar los cambios');
            
          })
      },
    })
  }else if(emailStatus === 'not available'){
    message.error('Este correo electrónico ya se encuentra registrado');
  }

}

  const validationSchema = Yup.object().shape({
    names: Yup.string().required('Este campo es obligatorio'),
    lastNames: Yup.string().required('Este campo es obligatorio'),
    email: Yup.string().required('Este campo es obligatorio'),
  })

  const formik = useFormik({
    initialValues: {
      names: (userData && userData.names) || '',
      lastNames: (userData && userData.lastNames) || '',
      telefono: (userData && userData.phone) || '',
      address: (userData && userData.address) || '',
      email: (userData && userData.email) || '',
    },
    enableReinitialize: true,
    
    onSubmit,

    validationSchema,
  })

  return (
    <div className='form-edit-corredor'>
      <form onSubmit={formik.handleSubmit}>
        <div className='row'>
          <div className='col-sm-6'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label className='tit-input titEditCorredor'>
                  Nombre<span className='text-danger'> *</span>
                </label>
                <input
                  type='text'
                  name='names'
                  className={
                    formik.errors.names
                      ? 'form-control form-info-corredorError'
                      : 'form-control form-info-corredor'
                  }
                  placeholder='Jonh'
                  onChange={formik.handleChange}
                  value={formik.values.names}
                />
              </div>
            </div>
            {formik.errors.names ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.names}
              </div>
            ) : null}
          </div>
          <div className='col-sm-6'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label className='tit-input titEditCorredor'>
                  Apellido<span className='text-danger'> *</span>
                </label>
                <input
                  type='text'
                  name='lastNames'
                  className={
                    formik.errors.lastNames
                      ? 'form-control form-info-corredorError'
                      : 'form-control form-info-corredor'
                  }
                  placeholder='Jonh'
                  onChange={formik.handleChange}
                  value={formik.values.lastNames}
                />
              </div>
            </div>
            {formik.errors.lastNames ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.lastNames}
              </div>
            ) : null}
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-6'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label className='tit-input titEditCorredor'>
                  Correo<span className='text-danger'> *</span>
                  </label>
                  <div className="input-wrapper">
                    <input
                      ref={refInputEmail}
                      type='text'
                      name='email'
                      style={{paddingRight: '35px'}}
                      className={
                        formik.errors.email
                          ? 'form-control form-info-corredorError'
                          : 'form-control form-info-corredor'
                      }
                      placeholder='Admin@DoDes.com'
                      onChange={handleEmail}
                      value={formik.values.email}
                    />
                    <VerifyEmailIcon/>
                  </div>
              </div>
            </div>
            {formik.errors.email ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.email}
              </div>
            ) : null}
          </div>
          <div className='col-sm-6'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label className='tit-input titEditCorredor'>
                  Teléfono de contacto<span className='text-danger'> *</span>
                </label>
                <input
                  type='number'
                  name='phone'
                  className={
                    formik.errors.phone
                      ? 'form-control form-info-corredorError'
                      : 'form-control form-info-corredor'
                  }
                  placeholder='3005479854'
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                />
              </div>
            </div>
            {formik.errors.phone ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.phone}
              </div>
            ) : null}
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label
                  className='tit-input titEditContrasena'
                  role='button'
                  onClick={() => {
                    setChangePasswordToggle((last) => !last)
                  }}
                >
                  Cambiar contraseña
                </label>
              </div>
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label className='tit-input titEditCorredor'>
                  Dirección<span className='text-danger'> *</span>
                </label>
                <input
                  type='text'
                  name='address'
                  className={
                    formik.errors.address
                      ? 'form-control form-info-corredorError'
                      : 'form-control form-info-corredor'
                  }
                  placeholder='Calle falsa 123'
                  onChange={formik.handleChange}
                  value={formik.values.address}
                />
              </div>
            </div>
            {formik.errors.address ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.address}
              </div>
            ) : null}
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <ChangePassword
              show={changePasswordToggle}
              setShow={setChangePasswordToggle}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <hr className='LineaFormCo'></hr>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <div className='col btn-holderSiguienteCenter'>
              <button type='submit' className='btn btn-endNext'>
                GUARDAR CAMBIOS
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default FormEditCorredor
