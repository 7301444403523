import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useRegister } from '../../context/RegisterContext'
import useModals from '../../hooks/useModals'
import './InfoPersonal.css'
import InputMask from 'react-input-mask'
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const InfoPersonal = () => {
  const [open, setOpen] = useState(false);
  const { setStep, userToRegister,errorMessage, setUserToRegister, registerUser } =
    useRegister()
  const { openPoliciesModal } = useModals()
  const [identification, setIdentification] = useState('nit')
  const [mask, setMask] = useState('999.999.999')

  const onSubmit = (formData) => {
       setUserToRegister({
      ...userToRegister,
      names: formData.nombre,
      lastNames: formData.apellido,
      identification: formData.cedula,
      phone: formData.telefono,
    })
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const action = (
    <React.Fragment>     
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  
  useEffect(() => {
    if (
      userToRegister.names !== '' &&
      userToRegister.lastNames !== '' &&
      userToRegister.identification !== '' &&
      userToRegister.phone !== ''
    ) {
      openPoliciesModal({
        onConfirm: () => {
          registerUser().then(
            (resp) =>{   
              if(resp === false){
                setOpen(true);
              }           
            }
          )          
        },
      })
    }
  }, [userToRegister])

  const validationSchema = Yup.object().shape({
    nombre: Yup.string().required('Este campo es obligatorio'),
    apellido: Yup.string().required('Este campo es obligatorio'),
    cedula: Yup.string()
      .required('Este campo es obligatorio')
      .test(
        'cedula',
        identification==='nit'?'El NIT ingresado tiene un formato inválido':'La cedula ingresada tiene un formato inválido',
        (value) => {
          let regex
          if (identification === 'nit') {
            regex = /^[0-9]{3}.[0-9]{3}.[0-9]{3}$/
          }
          if (identification === 'id') {
            regex = /^[0-9\b]{8,30}$/
          }
          return regex.test(value)
        }
      ),
    telefono: Yup.string().required('Este campo es obligatorio'),
  })

  const formik = useFormik({
    initialValues: {
      nombre: userToRegister.names,
      apellido: userToRegister.lastNames,
      cedula: userToRegister.identification,
      telefono: userToRegister.phone,
    },

    onSubmit,

    validationSchema,
  })

  let permiteGuardar = false
  if (
    formik.values.nombre !== '' &&
    formik.values.apellido !== '' &&
    formik.values.cedula !== '' &&
    formik.values.telefono !== ''
  ) {
    permiteGuardar = true
  }

  return (
    <div className='broker-personal-info'>
      <form onSubmit={formik.handleSubmit}>
        <div className='container block-from'>
          
          <div className='form-row name-field'>
            <div className='form-group col-md-3'>
              <label className='tit-input' style={{whiteSpace: 'nowrap'}}>
                Tipo de identificación<span className='text-danger'> *</span>
              </label>
              <select
                className='form-select form-info-corredor'
                onChange={(e) => {
                  setIdentification(e.target.value)
                  if (e.target.value === 'nit') {
                    setMask('999.999.999')
                  }
                  if (e.target.value === 'id') {
                    setMask('99.999.999')
                  }
                  formik.setFieldValue('cedula', '')
                }}
                value={identification}
              >
                <option value='nit'>NIT</option>
                <option value='id'>Cédula</option>
              </select>
            </div>
              
            <div className='form-group col-md-3'>
              <label className='tit-input'>
                {identification === 'id'  && 'Cedula'} 
                {identification === 'nit' && 'NIT'} 
                <span className='text-danger'> *</span>
              </label>
              <InputMask
                mask={identification === 'id'?false:mask}
                name='cedula'
                className={
                  formik.errors.cedula
                    ? 'form-control form-info-corredorError'
                    : 'form-control form-info-corredor'
                }
                value={formik.values.cedula}
                placeholder={identification === 'id'?'99.999.999.999':'900.900.900'}
                onChange={(e) => {
                  formik.handleChange(e)
                }}
              />
            </div>

          </div>

          <div className='form-row name-field'>
            <div className='form-group col-md-3'>             
            </div>
            {formik.errors.cedula ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.cedula}
              </div>
            ) : null}
          </div>
          <div className='form-row name-field'>
            <div className='form-group col-md-3'>
              <label className='tit-input'>
                Nombre<span className='text-danger'> *</span>
              </label>
              <input
                type='text'
                name='nombre'
                className={
                  formik.errors.nombre
                    ? 'form-control form-info-corredorError'
                    : 'form-control form-info-corredor'
                }
                value={formik.values.nombre}
                placeholder='Jonh'
                onChange={formik.handleChange}
              />
            </div> 

            <div className='form-group col-md-3'>
              <label className='tit-input'>
                Apellido<span className='text-danger'> *</span>
              </label>
              <input
                type='text'
                name='apellido'
                className={
                  formik.errors.apellido
                    ? 'form-control form-info-corredorError'
                    : 'form-control form-info-corredor'
                }
                value={formik.values.apellido}
                placeholder='Doe'
                onChange={formik.handleChange}
              />
            </div>           
          </div>
                
          <div className='form-row name-field'>       
            {formik.errors.nombre ? (
              <div className='form-label Mensaje-ErrorCorredor col-md-3 mr-md-4'>
                {formik.errors.nombre}
              </div>
            ) : null}

            {formik.errors.apellido ? (
              <div className='form-label Mensaje-ErrorCorredor col-md-3'>
                {formik.errors.apellido}
              </div>
            ) : null}
          </div>        

          <div className='form-row'>
            <div className='form-group col-md-6'>
              <label className='tit-input'>
                Teléfono de contacto<span className='text-danger'> *</span>
              </label>
              <input
                type='number'
                name='telefono'
                className={
                  formik.errors.telefono
                    ? 'form-control form-info-corredorError'
                    : 'form-control form-info-corredor'
                }
                value={formik.values.telefono}
                placeholder='(+57)(xxx) xxx xx xx'
                onChange={formik.handleChange}
              />
            </div>
            {formik.errors.telefono ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.telefono}
              </div>
            ) : null}
          </div>
        </div>

        <div className='container sectionBtnCorredor'>
          <div className='row'>
            <div className='col'>
              <button
                className='btn btn-volver'
                onClick={() => {
                  setStep(1)
                }}
              >
                Volver
              </button>
            </div>
            <div className='col btn-holder'>
              <button
                type='submit'
                className={
                  permiteGuardar
                    ? 'btn btn-endNext'
                    : 'btn btn-endNextOtro disabled-link'
                }
              >
                Siguiente
              </button>
            </div>
          </div>
        </div>
      </form>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={errorMessage}
        action={action}
      />
    </div>
  )
}

export default InfoPersonal
