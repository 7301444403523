import React, { useState, useEffect, useContext} from 'react';
import { Modal, Input, message, Popconfirm, Button, Avatar, Divider } from 'antd';
import { ButtonApp } from '../../button/button';
import Request from '../../../services/Request';
import { FilePdfOutlined, UserOutlined } from '@ant-design/icons';
import HttpResponseEmun from '../../../helpers/enums/HttpResponseEmun';
import {RequestsAvailableContext} from '../../../context/RequestsAvailableContext';
const { TextArea } = Input; 


const DetailsPanelRequests = ({developer, setDeveloper, datas, setDatas, visibleDetails, onCloseDetails}) => {
  const [loading, setLoading] = useState(false)
  const [validate, setValidate] = useState({message: '', type: ''});

  const {decrementRequestsAvailable} = useContext(RequestsAvailableContext);

useEffect(() => {
  const changeStatus = async () => {
  message.loading({content: 'Solicitud en proceso!', duration: 2});
        try {
                const response = await Request.put(`/developers/${developer._id}/change-status`, {
                    status: validate.type,
                    error: validate.message
                })
                if (response.status === HttpResponseEmun.OK) {
                    setDatas({...datas, content: datas.content.filter(item => item._id !== developer._id)})
                    setLoading(false)
                    setValidate({message: '', type: ''})
                    onCloseDetails()
                    setDeveloper({})
                    message.success({ content: 'Solicitud Exitosa!', duration: 3 });
                    decrementRequestsAvailable();
              
            }else{
                message.error({ content: 'Ocurrio un error!', duration: 3 });
            }

            
            
        } catch (error) {
            message.error({ content: 'Ocurrio un error!', duration: 3 });
            error && console.error(error)
        }
  }
  if(validate.type !== ''){
   
      changeStatus()
    }
  }, [validate.type])

const confirm = () => {
  if((validate.message === '' && validate.type === 'NO ACCEPT') || (validate.message === '' && validate.type === ''  )){
    return message.error({content: 'Debe enviar un motivo!', duration: 4});
  }else{

    setValidate({...validate, type: 'NO ACCEPT'})
  }
}
const handleCancel = () => {
  setTimeout(() => {
        setValidate({message: '', type: ''})    
  }, 1000);
};
const windowsNew = (file) =>{ 
  window.open(
    `${process.env.REACT_APP_API_URL}/file/${developer._id}/${file}?authorization=${localStorage.getItem('authorization')}&model=Desarrollador`,
    "File",
    "width=800px,height=600px") 
}
  return (
    <>
      <Modal
        centered
        visible={visibleDetails}
        title="Detalles del Desarrollador"
        onCancel={()=>{
        setDeveloper({})
        onCloseDetails()
        }}
        width={600}
        bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
        footer={[
          <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          {developer.state === 'VALIDATION' &&
          <>
              <span style={{margin: '0 5px'}}>     
                <ButtonApp type='submit' content='Aprobar' primary onClick={()=>{
                    setValidate({...validate, type: 'VERIFY'})
                  }} />
              </span>
              <Popconfirm 
                title={
                  <>
                  <h6>Motivo</h6>
                  <TextArea rows={2} value={validate.message} onChange={(e)=>setValidate({...validate, message: e.target.value})}/>
                  </>
                }
                placement="top" 
                onConfirm={()=>confirm()}
                onVisibleChange={() => handleCancel()}
                okText="Enviar" 
                cancelText="Cancelar"
                >
                  <span style={{margin: '0 5px'}}>
                    <ButtonApp type='submit' content='Rechazar' primary cancel />
                  </span>
              </Popconfirm>
          </>
          }
          {developer.state !== 'VALIDATION' &&
          <span style={{margin: '0 5px'}}>
                    <ButtonApp content='Cerrar' primary cancel  onClick={onCloseDetails}/>
                  </span>
          }
          </div>   
        ]}
      >
           <div className='logo'>
              <Avatar icon={<UserOutlined />} src={`${process.env.REACT_APP_API_URL}/file/${developer._id}/${developer.logo}?authorization=${localStorage.getItem('authorization')}&model=Desarrollador`} style={{  verticalAlign: 'middle', border: '3px solid #f0f0f0' }} size={64}/>
              
          </div>
        <table className='table-details'>
          <tbody>
           <tr>
              <th>
                <span>NIT</span>
              </th>
              <td>
                <span>{developer.nit}</span>
              </td>
            </tr>
           <tr>
              <th>
                <span>RAZÓN SOCIAL</span>
              </th>
              <td>
                <span>{developer.businessName}</span>
              </td>
            </tr>
           <tr>
              <th>
                <span>TIPO</span>
              </th>
              <td>
                <span>{developer.otherType ? (developer.otherType.length > 0 ? "Otro / " + developer.otherType : null) : developer.type}</span>
              </td>
            </tr>
           <tr>
              <th>
                <span>DEPARTAMENTO</span>
              </th>
              <td>
                <span>{developer.department}</span>
              </td>
            </tr>
           <tr>
              <th>
                <span>CIUDAD</span>
              </th>
              <td>
                <span>{developer.city}</span>
              </td>
            </tr>
           <tr>
              <th>
                <span>DIRECCIÓN</span>
              </th>
              <td>
                <span>{developer.address}</span>
              </td>
            </tr>
           <tr>
              <th>
                <span>CONTACTO</span>
              </th>
              <td>
                <span>{developer.contactPerson}</span>
              </td>
            </tr>
           <tr>
              <th>
                <span>CORREO ALTERNATIVO</span>
              </th>
              <td>
                <span>{developer.alternativeEmail}</span>
              </td>
            </tr>
           <tr>
              <th>
                <span>PÁGINA WEB</span>
              </th>
              <td>
                <span><a Target="_blank" href={developer.webSite}>{developer.webSite}</a></span>
              </td>
            </tr>
           <tr>
              <th>
                <span>USO DE INTERES</span>
              </th>
              <td>
                {developer.interestUse && <ul>
                  {developer.interestUse.commercial && <li>Comercial</li>}
                  {developer.interestUse.dotacional && <li>Dotacional</li>}
                  {developer.interestUse.industrial && <li>Industrial</li>}
                  {developer.interestUse.noVis && <li>No Vis</li>}
                  {developer.interestUse.vis && <li>Vis</li>}
                  {developer.interestUse.others && <li>{developer.interestUse.others}</li>}
                </ul>}
              </td>
            </tr>
           <tr>
              <th>
                <span>DOCUMENTOS</span>
              </th>
              <td>
                <Button style={{height: '50px'}} type="dashed" size='large' onClick={()=>windowsNew(developer.representacionLegalDoc)}>
                  <FilePdfOutlined style={{ fontSize: '30px', color: '#08c' }} />
                  Certificado E.R.L. 
                </Button>
                <Button style={{height: '50px'}} type="dashed" size='large' onClick={()=>windowsNew(developer.rutDoc)}>
                  <FilePdfOutlined style={{ fontSize: '30px', color: '#08c' }}/>
                  RUT
                </Button>
              </td>
            </tr>
           {developer.state === 'NO ACCEPT' &&
           <tr>
              <th>
                <span>MOTIVO</span>
              </th>
              <td>
                <span style={{textAlign: 'justify', display: 'flex'}}>{developer.registration && developer.registration.reason}</span>
              </td>
            </tr>}
            {developer.admin && 
            (<>
            <Divider/>
            <span>
              Datos del Administrador
            </span>
            <Divider/>
            <tr>
              <th>
                <span>NOMBRE</span>
              </th>
              <td>
                <span>{developer.admin.names} {developer.admin.lastNames}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span>CORREO</span>
              </th>
              <td>
                <span>{developer.admin.email}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span>DIRECCCIÓN</span>
              </th>
              <td>
                <span>{developer.admin.address}</span>
              </td>
            </tr>
            <tr>
              <th>
                <span>TELÉFONO</span>
              </th>
              <td>
                <span>{developer.admin.phone}</span>
              </td>
            </tr>
            </>)}
          </tbody>
        </table>
       
      </Modal>
      
    </>
  );
}

export default DetailsPanelRequests;