import React from 'react'
import { useState, useEffect, createContext } from 'react'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import useLocalStorage from '../hooks/useLocalStorage'
import { registerDeveloper } from '../services/developers'
import { registerBroker } from '../services/brokers'
import { useAuth } from './AuthContext'
import UserModelsEnum from '../helpers/enums/UserModelsEnum'

export const RegisterContext = createContext();

const initialUserInformation = ({
  email: '',
  type: '',
  otherType: '',
  names: '',
  lastNames: '',
  phone: '',
  alternativeEmail: '',
  businessName: '',
  nit: '',
  webSite: '',
  department: '',
  city: '',
  address: '',
  contactPerson: '',
  sameInfoPerson: false,
  password: '',
  interestUse: {
    vis: false,
    noVis: false,
    industrial: false,
    dotacional: false,
    commercial: false,
    others: false,
  },
  isPublic: true,
});

export const useRegister = () => {
  const context = useContext(RegisterContext)
  if (!context) throw new Error('There is no Register provider')
  return context
}

export default function RegisterProvider({ children }) {
  const { setUser } = useAuth()
  const history = useHistory()
  const [errorMessage,setErrorMessage] = useState('');
  const [userToRegister, setUserToRegister] = useLocalStorage(
    'userRegisterData',
    initialUserInformation
  )
  const [step, setStep] = useLocalStorage('userRegisterStep', -1)
  const [userType, setUserType] = useLocalStorage('userRegisterType', '')

  const clearUserRegisterData = () => {
    setStep(0)
    setUserType('')
    setUserToRegister(initialUserInformation)
  }

  const registerUser = async () => {
    let response
      
    if (userType === UserModelsEnum.Developer) {
      response = await registerDeveloper(userToRegister);
      localStorage.removeItem('userRegisterData')
      localStorage.removeItem('userRegisterStep')
      localStorage.removeItem('userRegisterType')
      if(response){
        window.location.reload()      
      }
    } else if (userType ===  UserModelsEnum.Broker) {
      response = await registerBroker(userToRegister)
    }
    if (response.value) {
      localStorage.removeItem('userRegisterData')
      localStorage.removeItem('userRegisterStep')
      localStorage.removeItem('userRegisterType')
      setUser(response.data)
      return true
    }
        
    if(response['error']){
      setErrorMessage(response['error'][0]);
    }
    return false;
  }

  useEffect(() => {

    if(step > 0){
      history.push('/auth/register/user-data');
    } else if(step === 0){
      history.push('/auth/register');
      setStep(null);
    } else if (step === -1){
      history.push('/auth/login');
      setStep(null);
    }
    
  }, [step])

  const contextValue = {
    userToRegister,
    setUserToRegister,
    step,
    setStep,
    clearUserRegisterData,
    userType,
    setUserType,
    registerUser,
    errorMessage,
  }

  return (
    <RegisterContext.Provider value={contextValue}>
      {children}
    </RegisterContext.Provider>
  )
}
