const months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
const weekDays = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];


export default function formatDate (date) {

    date = new Date(date);

    return weekDays[date.getDay()] + ', '
     + date.getDate() + ' de ' + 
     months[date.getMonth()] + ' de ' + 
     date.getUTCFullYear();

}