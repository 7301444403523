
import React, { useState, useContext } from 'react'
import { Modal, message, Select, Row, Col, Divider, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Request from '../../../../services/Request';
import { ButtonApp } from '../../../button/button';
import {Context} from '../../../../context/lotsContext';
import './CreateRequest.css';
import getLandRequests from '../../../../services/getLandRequests';
import {useAuth} from '../../../../context/AuthContext'
import Spinner from '../../../../assets/spinners/spinner.svg'

//steps
import GeneralInformation from './Steps/GeneralInformation/GeneralInformation';
import Location from './Steps/Location/Location';
import HttpResponseEmun from '../../../../helpers/enums/HttpResponseEmun';


const iconclose = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-x-lg" viewBox="0 0 16 16">
    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
</svg>

const { Option } = Select;

const errorMessages = {
  'ERROR0040': 'Ya existe una solicitud vigente con las mismas características'
}

export default function CreateLots ({ visible, onClose, filter }){
    const [nextVerify, setNextVerify] = useState([])
    const [showSpinner, setShowSpinner] = useState(false);
    const {userData} = useAuth()
    const {
      state: { step, newRequestLot, currentLots, currentLotsRequests, AdditionalData },
      dispatch,
    } = useContext(Context)

const Errors = {
  department: 'Debes seleccionar un departamento',
  city: 'Debes seleccionar una ciudad',
  zone: 'Debes seleccionar una zona',
  chip: 'Debes ingresar un chip',
  registration: 'debes ingresar una matrícula',
  aliasLand: 'Debes ingresar un alias para el Lote',
  area: 'Debes ingresar una área para el Lote', 
  metersPrice: 'Debes ingresar un monto en precio por metro', 
  businessType: 'Debes seleccionar un tipo de negocio', 
  treatment: 'Debes seleccionar un tratamiento',
  map: 'Debes Seleccionar la ubicación haciendo click en el mapa',
  address: 'Debes ingresar una dirección',
}
    
const next = (value, action) => {
  if(value && !action){
    if(!nextVerify.includes(value)){
      setNextVerify([...nextVerify, value])
    }
  }else if(value && action === 'delete'){
    const array = nextVerify.filter(item => item !== value)
    setNextVerify(array)
  }

}
const handleStep = (e) => {
  if(e.target.name === 'next' && step < 2){
    dispatch({type: 'SET_STEP', payload: step + 1})
  }else if(e.target.name === 'back' && step > 1){
    dispatch({type: 'SET_STEP', payload: step - 1})
  }else if(e.target.name === 'ok'){
    handleSubmitData()
    /* onClose()
    dispatch({type: 'RESET'}) */
  }
}

const handleSubmitData = async (e) => {
  /* const AdditionalFiles = AdditionalData.filter((e)=> e.type === 'file').map((e)=> {
    return {name: e.name, file: e.value}
  })
  const formData = new FormData()
    for (let i = 0; i < AdditionalFiles.length; i++) {
      formData.append(`${AdditionalFiles[i].name}`, AdditionalFiles[i].file)
    }
    const response = await Request.post(`/file/${dataNewLots._id}?model=solicitud de lote`, formData)
          let dataSchema = {
        fields:[]
    }
   return */
  setShowSpinner(true)
  for (const property in newRequestLot) {
    if((newRequestLot[property] === '' || newRequestLot[property] === null) && property !== 'observations'){
      message.warning(Errors[property])
      setShowSpinner(false)
      return
    }
  }

      const res = await Request.post('/land-requests', newRequestLot)
      if(res.status === HttpResponseEmun.OK){
        const numberLots = res.data.registrationNumber
        const dataNewLots = res.data
        dispatch({type: 'SET_CURRENT_LOTS_REQUESTS', payload: {content: [...currentLotsRequests.content, res.data]}})

        const lotsRequestsId = res.data._id
        const otherInputTypesAdditional = AdditionalData.filter((e)=> e.type !== 'file')
        const AdditionalFiles = AdditionalData.filter((e)=> e.type === 'file').map((e)=> {
          return {name: e.name, file: e.value}
        })
        let dataSchema = {fields:[]}

        if(AdditionalFiles.length > 0){
          const formData = new FormData()
            for (let i = 0; i < AdditionalFiles.length; i++) {
              formData.append(`${AdditionalFiles[i].name}`, AdditionalFiles[i].file)
            }
            const response = await Request.post(`/file/${lotsRequestsId}?model=Solicitud de lote`, formData)
             if(response.data.length > 0){
              response.data.forEach((e, i)=>{
              dataSchema ={
                  fields: [
                    ...dataSchema.fields,
                    {
                      ...AdditionalData.find((el)=> el.name === e.fieldname ),
                      value: e.path
                    }
                  ]
                }
            })
          }else{
            dataSchema ={
              fields: [
                ...dataSchema.fields,
                {
                  ...AdditionalData.find((el)=> el.name === response.data.fieldname ),
                  value: response.data.path
                }
              ]
            }
          }
        }
            if(otherInputTypesAdditional.length > 0){
              dataSchema = {
                fields: [
                  ...dataSchema.fields,
                  ...otherInputTypesAdditional
                ]
              }
            }
            const resp = await Request.put(`/land-requests/${dataNewLots._id}`, dataSchema)
            onClose()
            dispatch({type: 'RESET'})
            setShowSpinner(false)
            message.success(`Solicitud N° ${numberLots} registrada exitosamente`)
            const response = await getLandRequests(filter, userData._id)
            if(response.status === HttpResponseEmun.OK){
              dispatch({type: 'SET_CURRENT_LOTS_REQUESTS', payload: response.data})
            }else{
              message.error('Error al obtener las solicitudes')
            }
      } else{
        const msg = (res.data && res.data.message) ? errorMessages[res.data.message.split(" ")[0]] : null;
        message.error(msg || 'Error al crear la solicitud');
        setShowSpinner(false);
      }
}
    return (
        <>
        <div 
        style={{
          width: '100%', 
          zIndex: 1000,
          position: 'fixed',
          display: 'inline-block',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1001,
          background: 'rgba(0, 0, 0, 0.542)',
          }}
        hidden={!showSpinner}>
          <img src={Spinner} />
      </div>
 <Modal
  headerStyle={{ textAlign: 'left' }}
  width={950}
  style={{ top: 0, maxHeight: '', overflow: 'hidden', padding: 0 }}
  visible={visible}
  destroyOnClose
  closeIcon={
    <Tooltip title="Cerrar" placement="bottom">
      <CloseOutlined 
        onClick={()=>{
          onClose()
          dispatch({type: 'RESET_STEP'})
          dispatch({type: 'RESET-LOT-DATA'})
          
        }} 
        style={{ fontSize: '25px', color: '#39C0ED', cursor: 'pointer', width: '100%', height: '100%', display: 'block', paddingTop: '15px'  }}/>
    </Tooltip>
  }
  bodyStyle={{ height: 'calc(100vh - 55px)' }}
  footer={
    <>
    <div className='button-modal'>
      {(step >= 1 && step <= 2) &&
      <span style={{margin: '0px 8.5%'}}>
      <ButtonApp
        name='back'
        onClick={handleStep}
        content='Volver'
        primary
        cancel
        disabled={step <= 1}
      />
      </span>
      }
      {step < 2 &&
      <span style={{margin: '0px 7.5%'}}>

        <ButtonApp
          name='next'
          onClick={handleStep}
          content='Siguiente'
          disabled={!nextVerify.includes(step)}
          primary
        />
      </span>
      }
      {step === 2 &&
      <span style={{margin: '0px 7.5%'}}>
      <ButtonApp
        name='ok'
        onClick={handleStep}
        disabled={!nextVerify.includes(step)}
        content='Crear solicitud'
        primary
      />
      </span>
      }
      
    </div>
    </>
  }
  >
<Row>
  <Col span={24}>
  <div className='step-selector-lots'>
          <div
            className={`info-select ${step === 1 && 'active'}`}
          >
            <span className='links'>
              1. Información general
            </span>
          </div>
          <div
            className={`info-select ${step === 2 && 'active'}`}
          >
            <span className='links'>
              2. Ubicación
            </span>
          </div>
    </div>
  </Col>
</Row>
 <Row>
  <Col span={24}>
        <span className='subtitle'>
          Para poder generar una solicitud, primero necesitamos saber la informacion general.
        </span>
  </Col>
  <Divider style={{margin: '5px 0'}}/>
</Row>
    <div className='steps'>
      {step === 1 && (<GeneralInformation next={next}/>)}
      {step === 2 && (<Location next={next}/>)} 
    </div>
</Modal >
        </>
    )
}