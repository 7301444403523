import React, {useContext, useState, useEffect} from "react"
import { Col, Divider, Pagination, Row, Select, Space } from "antd"
import '../styles.css'
import Maps from "./Map"
import {Context} from '../../../../../../context/lotsContext'
import useLocationSelector from "../../../../../../hooks/useLocationSelector"
import departamentos from "../../../../../../helpers/states";
const { Option } = Select

export default function Location ({next}){
  const {
    state: { dataEditLots, step},
    dispatch,
  } = useContext(Context)
  const [coords, setCoords] = useState();
  const [centerMap, setCenterMap] = useState([]);
  const {options, selectedOptions, onChangeHandler} = useLocationSelector(
  {setCoords, createType: 'SET_DATA_TO_UPDATE_LOT'});
    useEffect(() => {
  
      if(
        dataEditLots.chip !== '' && 
        dataEditLots.registration !== '' && 
        dataEditLots.location !== null && 
        dataEditLots.location !== ''){
          next(step)
    }else{
      next(step, 'delete')
    
    }
    }, [dataEditLots])

const onChange = async (e) => {
  if(e.target.name === 'department'){
    const departmentCoords = departamentos.find((dep)=> dep.departamento === e.target.value)
    setCenterMap({lat: departmentCoords.lat, lng: departmentCoords.lng})
  }else{
    dispatch({type: 'SET_LOT_DATA_EDIT', payload: {[e.target.name]: e.target.value}})
    dispatch({type: 'SET_DATA_TO_UPDATE_LOT', payload: {[e.target.name]: e.target.value}})
  }
}

  //verificar si un marcador esta dentro de un poligono
  /* const isInside = (marker, polygon) => {
    var x = marker.latitude, y = marker.longitude;
    var inside = false;
    for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        var xi = polygon[i].latitude, yi = polygon[i].longitude;
        var xj = polygon[j].latitude, yj = polygon[j].longitude;
        var intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
  } */
  
  useEffect(() => {
   setCoords([dataEditLots.location.point && dataEditLots.location.point.map((item) => [item.latitude, item.longitude])])
  }, [])
  

return(
  <div className='container-general-information'>
      <div className='container-fluid container-global'>
      <Row>
      <Col xs={{ span: 24}} md={{ span: 9}} className='location-responsive'>
      <div className='block-from'>
              <div className='form-row'>
                <div className='form-group'>
                    <label className='tit-input'>
                      Departamento
                      <span className='text-danger'> *</span>
                    </label>
                    <Select
                        className='form-control form-info-corredor'
                        bordered={false}
                        loading={false}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        placeholder='Seleccione un Departamento'
                        style={{padding: '0.375rem 0.75rem', height: '39px'}}
                        onChange={ (e)=> {
                          onChangeHandler(e, "department")
                          onChange({target:{name: 'department', value: e}})
                        }}
                        value={selectedOptions.department || dataEditLots.location.department}
                       >
                       { options.department.map((item, i)=>{
                        return(
                          <Option key={i} value={item.name}>{item.name}</Option>
                        )
                        })}
                    </Select>
                </div>
              </div>
              <div className='form-row'>
                <div className='form-group'>
                  <label className='tit-input'>
                    Ciudad
                    <span className='text-danger'> *</span>
                  </label>
                  <Select
                        className='form-control form-info-corredor'
                        bordered={false}
                        loading={false}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        placeholder='Seleccione una ciudad'
                        style={{padding: '0.375rem 0.75rem', height: '39px'}}
                        onChange={(e)=>onChangeHandler(e, 'city')}
                        value={selectedOptions.city || dataEditLots.location.city}
                       >
                      {options.city.map((item, i)=>{
                        return(
                          <Option key={i} value={item.name}>{item.name}</Option>
                        )
                        })}
                    </Select>
                </div>
              </div>
              {options.location && (options.location.length > 0 || dataEditLots.location.location) &&
              <div className='form-row'>
                <div className='form-group'>
                    <label className='tit-input'>
                      Localidad
                      <span className='text-danger'> *</span>
                    </label>
                    <Select
                        className='form-control form-info-corredor'
                        bordered={false}
                        loading={false}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        placeholder='Seleccione una localidad'
                        style={{padding: '0.375rem 0.75rem', height: '39px'}}
                        onChange={(value)=>onChangeHandler(value, 'location')}
                        value={selectedOptions.location || dataEditLots.location.location}
                       >
                       {options.location && options.location.length > 0 && options.location.map((item, i)=>{
                          return(
                            <Option key={i} value={item.name}>{item.name}</Option>
                          )
                        })}
                    </Select>
                </div>
              </div>}
              {options.commune && (options.commune.length > 0 || dataEditLots.location.commune) &&
              <div className='form-row'>
                <div className='form-group'>
                    <label className='tit-input'>
                      Comuna
                      <span className='text-danger'> *</span>
                    </label>
                    <Select
                        className='form-control form-info-corredor'
                        bordered={false}
                        loading={false}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        placeholder='Seleccione una Comuna'
                        style={{padding: '0.375rem 0.75rem', height: '39px'}}
                        onChange={(value)=>onChangeHandler(value, 'commune')}
                        value={selectedOptions.commune || dataEditLots.location.commune}
                       >
                       {options.commune && options.commune.length > 0 && options.commune.map((item, i)=>{
                          return(
                            <Option key={i} value={item.name}>{item.name}</Option>
                          )
                        })}
                    </Select>
                </div>
              </div>}
              {options.UPZ && (options.UPZ.length > 0 || dataEditLots.location.UPZ) &&
              <div className='form-row'>
                <div className='form-group'>
                    <label className='tit-input'>
                    UPZ
                      <span className='text-danger'> *</span>
                    </label>
                    <Select
                        className='form-control form-info-corredor'
                        bordered={false}
                        loading={false}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        placeholder='Seleccione un UPZ'
                        style={{padding: '0.375rem 0.75rem', height: '39px'}}
                        onChange={(value)=>onChangeHandler(value, 'UPZ')}
                        value={selectedOptions.UPZ || dataEditLots.location.UPZ}
                       >
                       {options.UPZ && options.UPZ.length > 0 && options.UPZ.map((item, i)=>{
                          return(
                            <Option key={i} value={item.name}>{item.name}</Option>
                          )
                        })}
                    </Select>
                </div>
              </div>}
              {options.neighborhood && (options.neighborhood.length > 0 || dataEditLots.location.neighborhood) && 
              <div className='form-row'>
                <div className='form-group'>
                    <label className='tit-input'>
                    Barrio
                      <span className='text-danger'> *</span>
                    </label>
                    <Select
                        className='form-control form-info-corredor'
                        bordered={false}
                        loading={false}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        placeholder='Seleccione un Barrio'
                        style={{padding: '0.375rem 0.75rem', height: '39px'}}
                        onChange={(value)=>onChangeHandler(value, 'neighborhood')}
                        value={selectedOptions.neighborhood || dataEditLots.location.neighborhood}
                       >
                       {options.neighborhood && options.neighborhood.length > 0 && options.neighborhood.map((item, i)=>{
                          return(
                            <Option key={i} value={item.name}>{item.name}</Option>
                          )
                        })}
                    </Select>
                </div>
              </div>}
              <div className='form-row'>
                <div className='form-group'>
                  <label className='tit-input'>
                    Dirección
                    <span className='text-danger'> *</span>
                  </label>
                  <input
                    type='text'
                    name='address'
                    className= 'form-control form-info-corredor'
                    placeholder='Av Kr #13-23'
                    onChange={onChange}
                    value={dataEditLots.address}
                  />
                </div>
              </div>
              <div className='form-row form-flex'>
                <div className='form-group'>
                  <label className='tit-input'>
                    Matrícula
                    <span className='text-danger'> *</span>
                  </label>
                  <input
                    type='text'
                    name='registration'
                    className='form-control form-info-corredor'
                    placeholder='1112457897412'
                    onChange={onChange}
                    value={dataEditLots.registration}
                  />
                </div>
                <div className='form-group m'>
                  <label className='tit-input'>
                    CHIP
                    <span className='text-danger'> *</span>
                  </label>
                  <input
                    type='text'
                    name='chip'
                    className='form-control form-info-corredor'
                    placeholder='AAAA 4444 BBBB'
                    onChange={onChange}
                    value={dataEditLots.chip}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={{ span: 24}} md={{ span: 14, offset: 1 }}>
            <div className="map">
            <Maps center={centerMap} LatLng={coords && coords} onChange={onChange} marker={[dataEditLots.map !== null && dataEditLots.map.latitude, dataEditLots.map !== null && dataEditLots.map.longitude]} isVisible={true}/>
            </div>
          </Col>
        </Row>
</div>
</div>
    )
}