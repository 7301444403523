
import React, { useReducer, createContext } from 'react'
import PropTypes from 'prop-types'

const initialState = {
  currentLots: {},
  currentAssociateLots: {},
  dataToUpdateLot: null,
  dataEditLots:{
    address: null,
    aliasLand: null,
    area: null,
    businessType: null,
    chip: null,
    location: null,
    map: null,
    metersPrice: null,
    observations: "",
    price: null,
    public: true,
    registration: "",
    registrationNumber: "",
    treatment: "",
    zone: "",
    interestUse: {
      vis: false,
      noVis: false,
      industrial: false,
      dotacional: false,
      commercial: false,
      others: ''
    },
  },
  dataToUpdateRequest: null,
  dataNewLots: {
    aliasLand: '', 
    area: '', 
    metersPrice: '', 
    businessType: '', 
    treatment: '', 
    zone: '', 
    observations: '', 
    public: true,
    location: null,
    map: null,
    address: '',
    registration: '',
    chip: '',
    interestUse: {
      vis: false,
      noVis: false,
      industrial: false,
      dotacional: false,
      commercial: false,
      others: ''
    },

  },
  step: 1,
  filesToUpload: {
    type:{
      FPCT: '', 
      FIT: '', 
      FE: '', 
      FCTL: ''
    }, 
    status: {
      FPCT: 0, 
      FIT: 0, 
      FE: 0, 
      FCTL: 0
    }
  },
  AdditionalData:[],
  isVisible:{
    toggleState: {
      type: "land-requests", 
      label: "Mis solicitudes",
    },
    option: false, 
    type: '', 
    lot: {},

  },
  isVisibleHB:{
    toggleState: {
      type: "offers", 
      label: "Mis ofertas",
    },
    option: false, 
    type: '', 
    lot: {},

  },
  isVisibleAssociate:{
    toggleState: {
      type: "offers", 
      label: "Mis ofertas",
    },
    option: false, 
    type: '', 
    lot: {},
  },
  currentLotsRequests: {},
  newRequestLot: {
    areaRange: null,
    public: true,
    interestUse: {
      vis: false,
      noVis: false,
      industrial: false,
      dotacional: false,
      commercial: false,
      others: ''
    },
    observations: "",
    location: null,
  },
  dataEditRequests: {
    areaRange: null,
    public: true,
    interestUse: {
      vis: false,
      noVis: false,
      industrial: false,
      dotacional: false,
      commercial: false,
      others: ''
    },
    observations: "",
    location: null,
  },
  locationParams: {
    location: '',
    commune: '',
    upz: '',
    neighborhood: '',
  },
  associateDrawerData: {
    show: false,
    landId: null
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CURRENT_LOTS':
      return { ...state, currentLots: {...state.currentLots, ...action.payload} }
    case 'RESET_CURRENT_LOTS':
      return { ...state, currentLots: initialState.currentLots }
    case 'SET_CURRENT_ASSOCIATE_LOTS':
      return { ...state, currentAssociateLots: {...state.currentAssociateLots, ...action.payload} }
    case 'RESET_CURRENT_ASSOCIATE_LOTS':
      return { ...state, currentAssociateLots: initialState.currentAssociateLots }
    case 'SET_CURRENT_LOTS_REQUESTS':
      return { ...state, currentLotsRequests: {...state.currentLotsRequests, ...action.payload} }
    case 'RESET_CURRENT_LOTS_REQUESTS':
      return { ...state, currentLotsRequests: initialState.currentLotsRequests }
    case 'SET_NEW_REQUEST_LOT':
      return { ...state, newRequestLot: { ...state.newRequestLot, ...action.payload }}
    case 'SET_LOT_DATA':
      return { ...state, dataNewLots: { ...state.dataNewLots, ...action.payload } }
    case 'SET_DATA_TO_UPDATE_LOT':
      return { ...state, dataToUpdateLot: { ...state.dataToUpdateLot, ...action.payload } }
    case 'RESET_DATA_TO_UPDATE_LOT':
      return { ...state, dataToUpdateLot: initialState.dataToUpdateLot }
    case 'SET_DATA_TO_UPDATE_REQUEST':
      return { ...state, dataToUpdateRequest: { ...state.dataToUpdateRequest, ...action.payload } }
    case 'RESET_DATA_TO_UPDATE_REQUEST':
      return { ...state, dataToUpdateRequest: initialState.dataToUpdateRequest }
    case 'SET_LOT_DATA_EDIT':
      return { ...state, dataEditLots: { ...state.dataEditLots, ...action.payload } }
    case 'SET_REQUEST_DATA_EDIT':
      return { ...state, dataEditRequests: { ...state.dataEditRequests, ...action.payload } }
    case 'RESET-LOT-DATA':
        return { ...state, dataNewLots: initialState.dataNewLots }
    case 'SET_STEP':
      return { ...state, step: action.payload }
    case 'RESET_STEP':
      return { ...state, step: initialState.step }
    case 'SET_FILES':
      return { ...state, filesToUpload: {...state.filesToUpload, ...action.payload} }
    case 'SET_ADDITIONAL_DATA':
      return { ...state, AdditionalData: action.payload }
    case 'SET_IS_VISIBLE':
      return { ...state, isVisible: action.payload }
    case 'SET_IS_VISIBLE_HB':
      return { ...state, isVisibleHB: action.payload }
    case 'SET_IS_VISIBLE_ASSOCIATE':
      return { ...state, isVisibleAssociate: action.payload }
    case 'SET_LOCATION_PARAMS':
      return { ...state, locationParams: {...state.locationParams, ...action.payload} }
    case 'RESET_LOCATION_PARAMS':
      return { ...state, locationParams: initialState.newRequestLot }
    case 'SET_ASSOCIATE_DRAWER':
      return {...state, associateDrawerData: {...state.associateDrawerData, ...action.payload}}
    case 'RESET_ASSOCIATE_DRAWER':
      return {...state, associateDrawerData: initialState.associateDrawerData}
    case 'RESET':
      return { ...state, newRequestLot: initialState.newRequestLot, dataNewLots: initialState.dataNewLots, isVisible: initialState.isVisible, currentLotsRequests: initialState.currentLotsRequests,  AdditionalData: initialState.AdditionalData, step: initialState.step, filesToUpload: initialState.filesToUpload}//, currentLots: initialState.currentLots }
    default:
      return state
  }
}

const Context = createContext(initialState)
const ContextProvider = ({ children }) => {
  ContextProvider.propTypes = {
    children: PropTypes.node,
  }
  const [state, dispatch] = useReducer(reducer, initialState)


  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
}

export { Context, ContextProvider }
