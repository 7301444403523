import React, {useState, useEffect, useCallback, useMemo, useRef} from 'react';
import { Divider, Pagination, Empty } from 'antd';
import sort from '../../../components/handleSort';
import LandItem from '../LandItem/LandItem';
import LandModal from '../LandModal/LandModal';

import {useAuth} from '../../../context/AuthContext';

import getLandRequestsOnSale from '../../../services/getLandRequestsOnSale';
import RequestListItem from '../RequestListItem';
import SkeletonLoader from '../../../components/Home/HomeBroker/SkeletonLoader/SkeletonLoader';


const theadDevelopers = [
    {item:'registrationNumber', label:'Código'},
    {item: '!', label:''},
    {item:'city', label:'Ciudad'},
    {item:'neighborhood', label:'Barrio'},
    {item:'areaRange', label:(<p className="d-inline request-lot-area">Área del Lote (m<sup>2</sup>)</p>)},
    {item:'!', label:''},
    {item:'', label:''},
];

const FilterSvg = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filter-left" viewBox="0 0 16 16">
    <path d="M2 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
</svg>;

function LandTable({filter, setFilter, lands, setShowDetails, setSelectedLand, selectedLand, requestLandByIdCallback}) {

    const [loading, setLoading] = useState(false);
    const [requestFilter, setRequestFilter] = useState({page: 0, showRows: 10, statusParam: 'ACTIVE' });
    const [requests, setRequests] = useState({});
    const [logo, setLogo] = useState({});
    const landIdPreviousChanges = useRef(null);

    const handleSort = (item) =>{
        setFilter({...filter, ordering: sort(item)}) 
    }

    const requestLotClickHandler = useCallback(
        (item) => {
            setShowDetails({show: true, request: item, requestLand: selectedLand});
        },
        [selectedLand],
    );

    const {userData} = useAuth();

    const getRequestLandCallback = useCallback(async (landId) => {
        setLoading(true);
        const res = await getLandRequestsOnSale(requestFilter, userData._id, landId);
        setRequests(res.data);
        setLoading(false);
    }, [requestFilter, userData]);

    useEffect(() => {
        if(selectedLand){
            if(selectedLand.landId === landIdPreviousChanges.current) return;
            if(selectedLand.landId !== "") getRequestLandCallback(selectedLand.landId);
            landIdPreviousChanges.current = selectedLand.landId;
        }
    }, [selectedLand, requestFilter]);


    const requestLand = useMemo(() => ({
        type: "request-land",
        selectedLand,
    }), [selectedLand]); 


    return (
        <> 
        <div>   
            <table >
            <thead className="HeadListUser-admin" >
                <tr >
                    {theadDevelopers.map((e, i)=>{
                    if(e.item === "!") return;
                    return(
                    <th key={i} scope="col" className={`col-listUser-adminPanel col${i+1}`} onClick={() => { e.item !== "" && handleSort(e.item) }} style={{ cursor: 'pointer' }}>
                    <span style={{ marginRight: '0.5rem' }} >{e.label}</span>
                    {e.item !== "" && FilterSvg}
                    </th>)
                })}
                </tr>
            </thead>
            <tbody>
            
            {/*{lands && lands.type !== 'lands' && 
            <tr className='table-row-s'>
                
            </tr>}*/}

            {lands.content && lands.content.length === 0 &&
                <tr>
                <td colSpan={8}>
                <Empty 
                    description={
                    <span>
                        No se encontraron datos
                    </span>}
                    image={Empty.PRESENTED_IMAGE_SIMPLE} />
                
                </td>
                </tr>}

                <tr className='table-row-s'>
                
                </tr>

                {lands.content && lands.content.map((item, i) => {
                
                {/* Here the Items tr */}

                return <React.Fragment key={i}>
                    <LandItem selectedLand={selectedLand} setSelectedLand={setSelectedLand}
                item={item} structure={theadDevelopers} />

                {loading && selectedLand && selectedLand.landId === item._id && <tr><td colSpan={8}><SkeletonLoader /></td></tr>}

                {!loading && selectedLand && selectedLand.landId === item._id && requests.content && requests.content.length === 0 && 
                (<tr>
                    <td colSpan={8}>
                    <Empty 
                        description={
                        <span>
                            No se encontraron datos
                        </span>}
                        image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    
                    </td>
                    </tr>)}

                {selectedLand && !loading && selectedLand.landId === item._id && requests.content && requests.content.map((request, i) => {
                    return <RequestListItem key={i} requestLand={requestLand} requestLotClickHandler={requestLotClickHandler} item={request} filterTableList={requestFilter}
                    setSelectedLand={setSelectedLand} setFilterTableList={setRequestFilter} logo={logo} setLogo={setLogo} requestLandByIdCallback={requestLandByIdCallback} />
                })}

                {selectedLand && selectedLand.landId === item._id && requests.content && <tr>
                    <td colSpan="7" align='right'>
                        <Pagination
                            current={requestFilter.page +1}
                            onChange={(current, pageSize)=> setRequestFilter({...filter, page: current -1})}
                            defaultCurrent={1}
                            total={requests.totalElements}
                            showTotal={(total) => `Total ${total}`}
                            responsive
                            disabled={requests.totalElements >  requests.size ? false : true}
                        />
                    </td>
                </tr>}

                </React.Fragment>
                })}
                
                <tr >
                    <td colSpan="7">
                    <Divider style={{margin: '2px'}}/>
                    </td>
                </tr>

            </tbody>
            <tfoot>
                <tr>
                <td colSpan="7" align='right'>
                    <Pagination
                        current={filter.page +1}
                        onChange={(current, pageSize)=> setFilter({...filter, page: current -1})}
                        defaultCurrent={1}
                        total={lands.totalElements}
                        showTotal={(total) => `Total ${total}`}
                        responsive
                        disabled={lands.totalElements >  lands.size ? false : true}
                    />
                </td>
                </tr>
            </tfoot>
            </table>
        </div>

        <LandModal showSpinner={false} />

        </>
    )
}

export default LandTable
