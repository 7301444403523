import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { updatePassword } from '../../services/users'
import useModals from '../../hooks/useModals'
import useToasts from '../../hooks/useToasts'

import verContra from '../../assets/icons/visualizar.png'
import NoverContra from '../../assets/icons/novisualizar.png'

import './index.css'

const ChangePassword = ({ show, setShow }) => {
  const { openConfirmationMessage } = useModals()
  const { addToast } = useToasts()

  const onSubmit = (formData) => {
    openConfirmationMessage({
      message: '¿Seguro que quieres cambiar la contraseña?',
      onConfirm: () => {
        updatePassword(formData.password)
          .then(function (result) {
            if (result) {
              addToast({
                type: 'success',
                title: 'Éxito',
                message: 'Contraseña actualizada',
              })
            } else {
              addToast({
                type: 'error',
                title: 'Error',
                message: 'Error al actualizar la contraseña',
              })
            }
            setShow(false)
          })
          .catch(function (err) {
            addToast({
              type: 'error',
              title: 'Error',
              message: err.message,
            })
          })
      },
    })
  }

  const validationSchema = Yup.object().shape({
    // lastPassword: Yup.string()
    //   .required('La contraseña es anterior requerida'),
    password: Yup.string()
      .required('La contraseña es requerida')
      .matches(
        /^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Contraseña No válida'
      ),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
      .required('Es necesario que confirme la contraseña'),
  })

  const formik = useFormik({
    initialValues: {
      // lastPassword: '',
      password: '',
      repeatPassword: '',
    },

    onSubmit,

    validationSchema,
  })

  var correoError = ''
  var correoErrorTiene = ''
  if (formik.errors.email) {
    correoError = 'form-control style--inputs--login-Error icoInput1'
    correoErrorTiene = 'YES'
  } else {
    correoError = 'form-control style--inputs--login icoInput1'
    correoErrorTiene = ''
  }

  var contraError = ''
  var contraErrorTiene = ''
  var btnver = ''
  let Acc = {
    estado: 'Inicial',
  }
  if (formik.errors.password) {
    Acc.estado = 'no correcto'
    contraError =
      'form-control style--inputs--login-Error icoInput2 inputVerContra'
    btnver = 'imgVisualizarPSW style--inputs--login-Error'
  } else {
    contraError = 'form-control style--inputs--login icoInput2 inputVerContra'
    btnver = 'imgVisualizarPSW style--inputs--login'
    if (formik.values.password) {
      Acc.estado = 'Correcto'
    } else {
      Acc.estado = 'Inicial'
    }
  }
  if (correoErrorTiene == 'YES') {
    contraErrorTiene = ''
  } else {
    if (formik.values.password) {
      contraErrorTiene = 'YES'
    }
  }

  var repeatPError = ''
  var repeatPErrorTiene = ''
  var vercontra = ''
  if (formik.errors.repeatPassword) {
    repeatPError =
      'form-control style--inputs--login-Error icoInput2 inputVerContra'
    repeatPErrorTiene = 'YES'
    vercontra = 'imgVisualizarPSW style--inputs--login-Error'
  } else {
    repeatPError = 'form-control style--inputs--login icoInput2 inputVerContra'
    repeatPErrorTiene = ''
    vercontra = 'imgVisualizarPSW style--inputs--login'
  }

  const [estadoVisualizar, setEstadoVisualizar] = useState(false)
  const [estadoVisualizarRepiteContra, setEstadoVisualizarRepiteContra] =
    useState(false)

  return (
    <div className={`change-password-form ${show ? 'show' : ''}`}>
      <form>
        {/* <div className='container--texts--login'>
          <label className='subtitle--login form-label'>
            Anterior Contraseña <span className='text-danger'> *</span>
          </label>
        </div>
        <div className='input-group mb-2'>
          <input
            className={correoError}
            name='lastPassword'
            type='password'
            placeholder='********'
            onChange={formik.handleChange}
          />
        </div>

        {correoErrorTiene ? (
          <div className='form-label Mensaje-Error'>{formik.errors.lastPassword}</div>
        ) : null} */}

        <div className='container--texts--login'>
          <label className='subtitle--login form-label'>
            Contraseña <span className='text-danger'> *</span>
          </label>
        </div>
        <div className='input-group mb-2 pruebasinputpsw'>
          <input
            name='password'
            type={estadoVisualizar == false ? 'password' : 'text'}
            className={contraError}
            placeholder='********'
            onChange={formik.handleChange}
          />
          <label className={btnver}>
            <img
              src={estadoVisualizar == false ? NoverContra : verContra}
              alt='Ver Constraseña'
              className='imgVerContra'
              onClick={() => setEstadoVisualizar(!estadoVisualizar)}
            />
          </label>
        </div>

        {contraErrorTiene ? (
          <div className='form-label Mensaje-Error'>
            {formik.errors.password}
          </div>
        ) : null}

        <div
          className={
            Acc.estado == 'Inicial'
              ? 'form-label Mensaje-Error2'
              : Acc.estado == 'Correcto'
              ? 'form-label Mensaje-Acc'
              : 'form-label Mensaje-Error'
          }
        >
          Tu contraseña debe tener mínimo 8 carácteres
        </div>
        <div
          className={
            Acc.estado == 'Inicial'
              ? 'form-label Mensaje-Error2'
              : Acc.estado == 'Correcto'
              ? 'form-label Mensaje-Acc'
              : 'form-label Mensaje-Error'
          }
        >
          Tu contraseña debe tener mínimo 1 Mayúscula
        </div>
        <div
          className={
            Acc.estado == 'Inicial'
              ? 'form-label Mensaje-Error2'
              : Acc.estado == 'Correcto'
              ? 'form-label Mensaje-Acc'
              : 'form-label Mensaje-Error'
          }
        >
          Tu contraseña debe tener mínimo 1 carácter especial
        </div>

        <div className='container--texts--login'>
          <label className='subtitle--login form-label'>
            Repite contraseña <span className='text-danger'> *</span>
          </label>
        </div>
        <div className='input-group mb-2 pruebasinputpsw'>
          <input
            name='repeatPassword'
            type={estadoVisualizarRepiteContra == false ? 'password' : 'text'}
            className={repeatPError}
            placeholder='********'
            onChange={formik.handleChange}
          />
          <label className={vercontra}>
            <img
              src={
                estadoVisualizarRepiteContra == false ? NoverContra : verContra
              }
              alt='Ver Constraseña'
              className='imgVerContra'
              onClick={() =>
                setEstadoVisualizarRepiteContra(!estadoVisualizarRepiteContra)
              }
            />
          </label>
        </div>
        {repeatPErrorTiene ? (
          <div className='form-label Mensaje-Error'>
            {formik.errors.repeatPassword}
          </div>
        ) : null}

        <button
          className={
            formik.isValid && formik.values.password
              ? 'button--register2-Active'
              : 'button--register2 disabled-link'
          }
          type='submit'
          onClick={formik.handleSubmit}
        >
          Cambiar contraseña
        </button>
      </form>
    </div>
  )
}

export default ChangePassword
