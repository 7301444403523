import React from 'react'

export default function Toast({ title, type, message, onClose }) {
    return (
        <div className={'toast show'} role='alert' aria-live='assertive' aria-atomic='true'>
            <div className={`toast-header  text-bg-${type}`}>
                <strong className='me-auto'>{title}</strong>
                <button type='button' className='btn-close' onClick={onClose}></button>
            </div>
            <div className='toast-body'>{message}</div>
        </div>
    );
}
