import React, { useState, useEffect,useContext } from "react";
import getLands from '../../../services/getLands'
import getLandRequests from '../../../services/getLandRequests'
import './index.css'
import { MenuOutlined, SettingOutlined } from '@ant-design/icons';
import TableList from './TableList/TableList.jsx'
import { useAuth } from '../../../context/AuthContext'
import {
  AppstoreOutlined,
} from '@ant-design/icons';
import {Context} from '../../../context/lotsContext';
import { Menu, message } from 'antd';
import { ThemeContext } from "../../../context/ThemeContext";
import HttpResponseEmun from "../../../helpers/enums/HttpResponseEmun";
import { developerLandStates } from "../../../helpers/enums/DeveloperLandStates";

const AdminPanelRequests = () => {
    const [filter, setFilter] = useState({page: 0, showRows: 10});
    const [loading, setLoading] = useState(false);
    const {userData} = useAuth()
    const {themeColor} = useContext(ThemeContext)
    const {
        state: { isVisible },
        dispatch,
      } = useContext(Context)


   useEffect(() => {
      setLoading(true)
      dispatch({ type: 'RESET_CURRENT_LOTS'})
      dispatch({ type: 'RESET_CURRENT_LOTS_REQUESTS'})
      const fetchDataLands = async () => {
      const response = await getLands({...filter, stateParam: developerLandStates.OWN}, userData._id)
      if (response.status === HttpResponseEmun.OK) {
            dispatch({ type: 'SET_CURRENT_LOTS', payload: response.data })
        }else{
            message.error('Error al cargar los lotes')
        }
      }
      const fetchDataRequests = async () => {
        const response = await getLandRequests(filter, userData._id)
        if (response.status === HttpResponseEmun.OK) {
            dispatch({ type: 'SET_CURRENT_LOTS_REQUESTS', payload: response.data })
        }else{
            message.error('Error al cargar las solicitudes')
        }
    }
    if(userData && isVisible.toggleState.type === 'lands'){
        fetchDataLands()
    }else if(userData && isVisible.toggleState.type === 'land-requests'){
        fetchDataRequests()
    }
       
    
  }, [filter, isVisible.toggleState.type, userData]) 
    return (
    <>
        <div className="container">

            <div className="container containerListAndSearchAdmin">
                <div className="blocTabs">

                    <button
                        className={isVisible.toggleState.type === "land-requests" ? "tabsListAdminPanel active-tabsListAdminPanel" : "tabsListAdminPanel"}
                        style={ isVisible.toggleState.type === "land-requests" ? 
                            {backgroundColor:themeColor['background-color'],color:themeColor['color']} : {}
                        }
                        onClick={() =>{
                            setFilter({page: 0, showRows: 10});
                             dispatch({ type: 'SET_IS_VISIBLE', payload: {...isVisible, toggleState: {type: "land-requests", label: "Mis Solicitudes"}}})}}
                    >
                        <div className={isVisible.toggleState.type === "land-requests" ? "boxBtnSelec" : "boxBtn"}                          
                        >Mis solicitudes</div>
                    </button>
                    <button
                        className={isVisible.toggleState.type === "lands" ? "tabsListAdminPanel active-tabsListAdminPanel" : "tabsListAdminPanel"}
                        style={ isVisible.toggleState.type === "lands" ? 
                            {backgroundColor:themeColor['background-color'],color:themeColor['color']} : {}
                        }
                        onClick={() => {
                            setFilter({page: 0, showRows: 10});
                            dispatch({ type: 'SET_IS_VISIBLE', payload: {...isVisible, toggleState: {type: "lands", label: "Mis lotes"}}})}}
                    >
                        <div className={isVisible.toggleState.type === "lands" ? "boxBtnSelec2" : "boxBtn"}                        
                        >Mis Lotes</div>
                    </button>
                </div>

            </div>
            <div className='containerListResponsive'>
                <h6 style={{color: 'white', minWidth: 'fit-content', margin: '5px 20px'}}>{isVisible.toggleState.label}</h6>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'end', minHeight: '40px'}}>
                    
                <Menu theme='dark' className='menu' style={{backgroundColor:"#272A30", color: "#fff", borderBottom: 0, minHeight: '40px', minWidth: '50px'}} mode="horizontal" >
                  
                  <Menu.SubMenu key="0" icon={<MenuOutlined />}>
                    <Menu.Item onClick={() => dispatch({ type: 'SET_IS_VISIBLE', payload: {...isVisible, toggleState: {type: "land-requests", label: "Mis Ofertas"}}})} key={'1'} icon={<AppstoreOutlined />}>
                    Mis solicitudes
                    </Menu.Item>
                    <Menu.Item onClick={() => dispatch({ type: 'SET_IS_VISIBLE', payload: {...isVisible, toggleState: {type: "lands", label: "Mis lotes"}}})} key={'2'}  icon={<AppstoreOutlined />}>
                    Mis Lotes
                    </Menu.Item>
                  </Menu.SubMenu>
                </Menu>
                </div>
            </div>
            <div className="container p-0 container-listUser">
                    <TableList filter={filter} setFilter={setFilter}/>
            </div>
           
        </div>
    </>
    )
}

export default AdminPanelRequests