import Request from './Request'
import HttpResponseEmun from '../helpers/enums/HttpResponseEmun'
import UserModelsEnum from '../helpers/enums/UserModelsEnum'

export const registerDeveloper = async (user) => {
  try {
    const payload = {
      admin: {
        names: user.names,
        lastNames: user.lastNames,
        email: user.email,
        password: user.password,
        address: user.address,
        phone: user.phone,
      },
      address: user.address,
      type: user.type,
      alternativeEmail: user.alternativeEmail,
      otherType: user.otherType,
      businessName: user.businessName,
      nit: user.nit,
      webSite: user.webSite,
      department: user.department,
      city: user.city,
      contactPerson: user.contactPerson,
      sameInfoPerson: user.sameInfoPerson,
      interestUse: {
        vis: user.interestUse.vis,
        noVis: user.interestUse.noVis,
        industrial: user.interestUse.industrial,
        dotacional: user.interestUse.dotacional,
        commercial: user.interestUse.commercial,
      },
    }
    if (user.interestUse.others) {
      payload.interestUse.others = user.interestUse.others
    }
    const response = await Request.post('/public/developers', payload)
    if (response.status === HttpResponseEmun.OK) {
      const { data } = response
      const { token, id } = data
      localStorage.setItem('authorization', token)
      localStorage.setItem(
        'user',
        JSON.stringify({ model: UserModelsEnum.Developer, id })
      )
      return { model: UserModelsEnum.Developer, id }
    } else {
         }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const verifyNIT = async (nit) => {
  try {
    const response = await Request.get(`/public/developers/by-nit/${nit}`)
    if (response.status === HttpResponseEmun.OK && response.data === null) {
      return true
    } else {
      return false
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const getAllDevelopers = async (filter) => {
  try {
    const response = await Request.get('/developers', {...filter})
    if (response.status === HttpResponseEmun.OK) {
      const { data } = response
      return data;
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const getDevelopers = async () => {
  try {
    const response = await Request.get('/public/developers')
    if (response.status === HttpResponseEmun.OK) {
      const { data } = response
      return data.data
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const getDeveloper = async (id) => {
  try {
    const response = await Request.get(`/users/${id}`)
    if (response.status === HttpResponseEmun.OK) {
      const { data: userData } = response
      const { data: developerData } = await Request.get(
        `/developers/${userData.associateType}`
      )
      const data = {
        ...userData,
        ...developerData.data,
      }
      return data
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const updateDeveloperPersonalInfo = async (
  developerID,
  developer,
  userID,
  userData
) => {
  try {
    await Request.put(`/developers/${developerID}`, developer)
    const response = await Request.put(`/users/${userID}`, userData)
    if (response.status === HttpResponseEmun.OK) {
      return true
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const updateDeveloperBusinessInfo = async (developerID, developer) => {
  try {
    const response = await Request.put(`/developers/${developerID}`, developer)
    if (response.status === HttpResponseEmun.OK) {
      return true
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const uploadRUT = async (id, rut) => {
  try {
    const response = await Request.post(`/developers/upload-RUT/${id}`, rut)
    if (response.status === HttpResponseEmun.OK) {
      return true
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const uploadDERL = async (id, derl) => {
  try {
    const response = await Request.post(`/developers/upload-DERL/${id}`, derl)
    if (response.status === HttpResponseEmun.OK) {
      return true
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const uploadLOGO = async (id, logo) => {
  try {
    const response = await Request.post(`/developers/upload-LOGO/${id}`, logo)
    if (response.status === HttpResponseEmun.OK) {
      return true
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const getLOGO = async (id, filename) => {
  try {
    const response = await Request.get(
      `/file/${id}/${filename}`,
      {
        model: UserModelsEnum.Developer,
      },
      { responseType: 'blob' }
    )
    if (response.status === HttpResponseEmun.OK) {
      const blob = response.data
      const url = window.URL.createObjectURL(blob)

      return url
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const getRUT = async (id, filename) => {
  try {
    const response = await Request.get(
      `/file/${id}/${filename}`,
      {
        model: UserModelsEnum.Developer,
      },
      { responseType: 'blob' }
    )
    if (response.status === HttpResponseEmun.OK) {
      const blob = response.data
      const url = window.URL.createObjectURL(blob)

      return url
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const getDERL = async (id, filename) => {
  try {
    const response = await Request.get(
      `/file/${id}/${filename}`,
      {
        model: UserModelsEnum.Developer,
      },
      { responseType: 'blob' }
    )
    if (response.status === HttpResponseEmun.OK) {
      const blob = response.data
      const url = window.URL.createObjectURL(blob)
      
      return url
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const newUser = async (user) => {
  try {
    const response = await Request.post('/users', {
      ...user,
      address: 'This does not exist on form',
      status: 1,
    })
    if (response.status === HttpResponseEmun.OK) {
      return response.data;
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const deleteUser = async (userId, developerId) => {
  try {
    const response = await Request.delete(`/developers/${developerId}/${userId}`);
    if (response.status === HttpResponseEmun.OK) {
      return true
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const updateUser = async (userId, user) => {
  try {
    const response = await Request.put(`/users/${userId}`, {
      ...user,
      address: 'This does not exist on form',
      status: 1,
    });
    if (response.status === HttpResponseEmun.OK) {
      return true
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const associateBroker = async (brokerData) => {
  try {
    const response = await Request.post('/developers/associate-broker', brokerData);
    if (response.status === HttpResponseEmun.OK) {
      return null;
    } else {
      return response;
    }
  } catch (error) {
    console.error(error);
  }
}

export const getAssociatedBrokers = async (filter) => {
  try {
    const response = await Request.post('/developers/get-associate-broker', filter);
    if (response.status === HttpResponseEmun.OK) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
  }
}