import React, { useState } from 'react'
import logo360 from '../../assets/logo.png'
import elipse from '../../assets/icons/ellipse1.png'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Loading from '../../components/Loading/Loading'
import PieDeInicio from '../../components/PieRegistro/PieRegistro'
import CorreoContra from '../../components/CorreoContra/CorreoContra'
import { useAuth } from '../../context/AuthContext'
import useToasts from '../../hooks/useToasts'

import './Login.css'

const Login = () => {
  const { login } = useAuth()
  const { addToast } = useToasts()
  const [inputsLogin, setinputsLogin] = useState({
    email: '',
    password: '',
  })
  const [showLoading, setShowLoading] = useState(false)

  const changeForm = (e) => {
    setinputsLogin({
      ...inputsLogin,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setShowLoading(true)
    try {
      if (!(await login(inputsLogin))) {
        addToast({
          type: 'error',
          title: 'Upss !!',
          message: 'Debe verificar las credenciales',
        })
      }
      setShowLoading(false)
    } catch (error) {
      setShowLoading(false)
      console.error(error)
    }
  }

  return (
    <div className='login'>
      <Loading isVisible={showLoading}/>
      <div className='container-fluid main-container'>
        <Row>
          <Col>
            <Link to='/auth/login'>
              <img
                src={logo360}
                className='mt-5 ms-4 mt-lg-5 ms-lg-5 ps-lg-5'
                alt='logo'
              />
            </Link>
            <div className='style--image--circle position-absolute top-0 end-0 text-end'>
              <img src={elipse} className='img-fluid' alt='logo' />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
            <div className='container'>
              <h1 className='style--title--login'>¡Bienvenido!</h1>

              <form onChange={changeForm} onSubmit={handleSubmit}>
                <CorreoContra />
                <br />
                <div className='input-group mb-3'>
                  <button
                    className={
                      inputsLogin.email != ''
                        ? inputsLogin.password.length >= 5
                          ? 'button--register-Active'
                          : 'button--register disabled-link'
                        : 'button--register disabled-link'
                    }
                    type='submit'
                  >
                    INICIAR SESIÓN
                  </button>
                </div>
              </form>
              <PieDeInicio
                direc='auth/register'
                mensajeLink='REGÍSTRATE'
                mensaje='¿Aún no tienes cuenta?'
                tipo='1'
                lineClass='linea'
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Login
