import React, { useState, useEffect, useContext } from "react";
import { UserOutlined, MoreOutlined } from "@ant-design/icons";
import {
  Layout,
  Avatar,
  Col,
  Row,
  Typography,
  AutoComplete,
  Divider,
  Space,
  Pagination,
} from "antd";

import "../../Style.css";
import { ChatContext } from "../../../../context/ChatContext";
import getPicture from "../../../../helpers/getPicture";
const { Header, Content, Footer, Sider } = Layout;
const { Text } = Typography;

export default function PreviewChat({ collapsed, urlImage, registrationNumber, name, chat,archived, toggleOpen }) {
  const {setCurrentLandRequest,setCurrentLandSelect,
    setCurrentMessages, setBrokerAssociate, setDeveloperAssociate,
    createAssociateChat} = useContext(ChatContext);
  const [logo, setLogo] = useState('')  

  useEffect(()=>{    
    if(urlImage){
      const pic = getPicture(urlImage.id,urlImage.filename,urlImage.model);   
      if(pic) {
        setLogo(pic)
      }
    }      
  }, [urlImage])
  
  const setCurrentChatFunction = () => {
    setCurrentMessages(null);
    if(chat.land || chat.landRequest){
      setBrokerAssociate(null);
      setDeveloperAssociate(null);
      setCurrentLandSelect(chat.land);
      setCurrentLandRequest(chat.landRequest);
    } else {
      setCurrentLandSelect(null);
      setCurrentLandRequest(null);
      setBrokerAssociate(chat.broker);
      setDeveloperAssociate({...chat.developer, associateType: chat.developer._id});
    }

  }

  return (
    <div className="previewItemChat" onClick={()=>{toggleOpen();setCurrentChatFunction()}}>
      <Row justify="start" align="middle">
        <Col span={collapsed ? 24 : 5} style={{ textAlign: 'center' }}>
          <Avatar 
          size={37} 
          icon={<UserOutlined />} 
          src={logo}
          />
        </Col>
        {!collapsed && (
          <Col span={19}>
            <Row justify="start">
              <Col span={24}>
                <Text style={{ color: "white", fontSize:'12px' }} strong>
                  {name}
                </Text>
              </Col>
              {archived && <Col span={24}>
                <Text style={{ color: "blue" }} keyboard>
                   Archivado
                </Text>
              </Col>}
              <Col span={24}>
                {registrationNumber ? (<Text style={{ color: "blue" }} keyboard>
                  Lote {registrationNumber}
                </Text>) : null}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
}
