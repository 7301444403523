import Request from './Request';

export default async function getLandsRequestsOnSale(filter, associateTypeId, landId) {
   
    try {
            const response = await Request.get(`/lands/${landId}/land-requests/by-associate/${associateTypeId}`, { ...filter, stateParam: 'OPEN'});

            return response
        
        
    } catch (error) {
        error && console.error(error)
        return error
    }
    
  }