import React, {useState, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import TableList from '../TableList/TableList';
import {ChatContext} from '../../../../context/ChatContext';
import getPicture from '../../../../helpers/getPicture';
import UserModelsEnum from '../../../../helpers/enums/UserModelsEnum';
import { UserOutlined, UpOutlined, MinusOutlined, CheckSquareTwoTone } from '@ant-design/icons';
import { Divider, Button, Avatar, Tooltip, Badge } from 'antd';
import './index.css';

const stateColorObject = {
    "OPEN": "#0dcaf0",
    "PAUSED":"#fcd150",
    "CANCELLED":"#d8505e",
    "CLOSED":"#8e969e",
    IN_ANALYSIS: "#46acfa", // info
    REFUSED: "#97999A", //danger
    IN_FEASIBILITY: "#97999A", //light blue a100
    IN_NEGOTIATION: "#21B608",  //light blue a700
    BOUGHT: "#21B608", // success
    RETIRED: "#9FA6B2", // warning
    ACCEPTED: "#20c997", // teal
    NOT_AVAILABLE: "#9FA6B2"
}


// Component for each Request Lot

export default function RequestListItem ({item, logo, setLogo, requestLotClickHandler, offerButtonHandler, 
    selectedRequest, filterTableList, setFilterTableList, requestLand, setSelectedLand, requestLandByIdCallback }) {

    const [tooltipVisible, setTooltipVisible] = useState(undefined);
    const hideTooltip = () => setTooltipVisible(false);
    const showTooltip = () => setTooltipVisible(undefined);
    const isMyOfferTab = selectedRequest === undefined && !requestLand;

    const {setCurrentLandRequest} = useContext(ChatContext);

    const [loading, setLoading] = useState(false);

    const contactSvg = <svg onMouseOver={hideTooltip} onMouseLeave={showTooltip} style={{marginRight: "2rem", marginLeft: "1rem"}} width="28" height="28" viewBox="0 0 28 28" fill="#00556D" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.33464 0.667969C1.86797 0.667969 0.681302 1.86797 0.681302 3.33464L0.667969 27.3346L6.0013 22.0013H24.668C26.1346 22.0013 27.3346 20.8013 27.3346 19.3346V3.33464C27.3346 1.86797 26.1346 0.667969 24.668 0.667969H3.33464ZM3.33464 3.33464H24.668V19.3346H4.89464L3.33464 20.8946V3.33464ZM16.668 14.0013H6.0013V16.668H16.668V14.0013ZM6.0013 10.0013H22.0013V12.668H6.0013V10.0013ZM22.0013 6.0013H6.0013V8.66797H22.0013V6.0013Z" fill="#00556D"/>
    </svg>;

    const contactSvgDisabled = <svg style={{marginRight: "2rem", marginLeft: "1rem"}} width="28" height="28" viewBox="0 0 28 28" fill="#d6d6d6" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.33464 0.667969C1.86797 0.667969 0.681302 1.86797 0.681302 3.33464L0.667969 27.3346L6.0013 22.0013H24.668C26.1346 22.0013 27.3346 20.8013 27.3346 19.3346V3.33464C27.3346 1.86797 26.1346 0.667969 24.668 0.667969H3.33464ZM3.33464 3.33464H24.668V19.3346H4.89464L3.33464 20.8946V3.33464ZM16.668 14.0013H6.0013V16.668H16.668V14.0013ZM6.0013 10.0013H22.0013V12.668H6.0013V10.0013ZM22.0013 6.0013H6.0013V8.66797H22.0013V6.0013Z" fill="#d6d6d6"/>
    </svg>;

    // DEVELOPER IS AN ARRAY WHEN THE DATA CAME FROM MY OFFER TAB
    if(!item.developer._id && isMyOfferTab) item.developer._id = item.developer[0]._id;
    if(!item.developer.logo && isMyOfferTab) item.developer.logo = item.developer[0].logo;
    if(!item.developer.businessName && isMyOfferTab) item.developer.businessName = item.developer[0].businessName;
    if(!item.developer.contactPerson && isMyOfferTab) item.developer.contactPerson = item.developer[0].contactPerson;

    const setProfilePicture = () => {
        const url = getPicture(item.developer._id, item.developer.logo, UserModelsEnum.Developer);
        setLogo({...logo, [item.developer._id]:url});
    }

    const requestLandByIdHandler = async (requestId) => {
        setLoading(true);
        await requestLandByIdCallback(requestId);
        setLoading(false);
        
    }

    const setChat = async (e) => {
        e.stopPropagation();
        setCurrentLandRequest(item);
    }

    useEffect(() => {
        if(item._id && logo && !logo[item.developer._id]) setProfilePicture();
    }, [logo, item.developer._id, item._id]);

    const code = item.registrationNumber ? item.registrationNumber : "";
    const neighborhood = item.location && item.location.neighborhood;
    const stateColor = item.state && stateColorObject[item.landOffered ? item.landOffered.state : item.state ];

    return (<React.Fragment key={item.registrationNumber}>
        <Tooltip visible={tooltipVisible} placement="left" title="Ver la solicitud">
        <tr className={`table-row-body-broker ${isMyOfferTab ? 'tr-width-fixed' : ''}`} style={{borderLeftColor: stateColor, outline: isMyOfferTab && "2px solid "+stateColor}} onClick={() => requestLotClickHandler(item)}>
        <td width={!isMyOfferTab ? "15%" : undefined} className='col-listUser-adminPanel col1' style={{color: stateColor}} scope="row">{code}</td>
        <td className='col-listUser-adminPanel col4' scope="row">{neighborhood}</td>
        {!isMyOfferTab && logo && !requestLand && <td className='col-listUser-adminPanel col6' scope="row">
            {item.associates ? <Tooltip title="Tienes lotes que cumplen la solicitud">
                <Badge size="small" count='!'>
                    <CheckSquareTwoTone 
                    onMouseOver={hideTooltip} 
                    onMouseLeave={showTooltip}
                    style={{fontSize: '25px', marginLeft: '1rem'}} 
                    twoToneColor="#eb2f96" />
                </Badge>
            </Tooltip> : null}
        </td>}
        <td style={requestLand && {textAlign: "end"}} className='col-listUser-adminPanel col7' scope="row">

            {/* Drowpdown offer with avatar */}

            {isMyOfferTab && logo && 
            (<>
                <span className='divider'><MinusOutlined style={{ fontSize: '1.5rem', color: 'gray', transform: 'rotate(90deg)' }} /></span>
                {item.state === "OPEN" ? <Link to="/app/chat" onClick={setChat}><Tooltip title="Conversar">{contactSvg}</Tooltip></Link> : contactSvgDisabled}
                <Avatar style={{border: "1px solid black", borderRadius: "50%"}} icon={<UserOutlined />} src={logo[item.developer._id]} size={30}/>
            </>)}

            {/* Button to Offer */}

            {!isMyOfferTab && !requestLand && <Button onMouseOver={hideTooltip} onMouseLeave={showTooltip} type="primary" 
            className="requestList-offerButton"
            style={{marginLeft: 'auto'}}
            onClick={(e) => {offerButtonHandler(e, item._id)}}>
            {selectedRequest === item._id ? "Ocultar" : "Ofrecer"}{<UpOutlined className={"offer-btn-icon" + " " + (selectedRequest === item._id ? "" : "icon-rotate-animation")} />}</Button>}


            {/* Button request */}

            {requestLand && 
            <>
                <Avatar style={{border: "1px solid black", borderRadius: "50%"}} icon={<UserOutlined />} src={logo[item.developer._id]} size={30}/>
                <Button className="requestLand_button" type="primary" 
                disabled={loading || item.isOffered || (requestLand.selectedLand.disabledList && requestLand.selectedLand.disabledList.includes(item._id))}
                onClick={(e) => {
                    e.stopPropagation();
                    requestLandByIdHandler(item._id);
                }}>Solicitar
                </Button>
            </>
            }

        </td>
        </tr>
        </Tooltip>
        <tr>
        <td colSpan="7">
            <Divider style={{margin: '4px', fontSize: '2rem'}}/>
        </td>
        </tr>
        {item._id === selectedRequest && (
            <tr><td colSpan="7"><TableList filter={filterTableList} setFilter={setFilterTableList} hideHead offerId={item._id} /></td></tr>
        )}
    </React.Fragment>)
}