const businessType = Object.freeze({
    INPUT: {type: 'INPUT', name: 'Aporte'},
    PARTIAL_SALE: {type: 'PARTIAL_SALE', name: 'Venta Parcial'},
    TOTAL_SALE: {type: 'TOTAL_SALE', name: 'Venta Total'},
  })
const treatment = Object.freeze({
    CONSOLIDATION: {type: 'CONSOLIDATION', name: 'Consolidación'},
    CONSERVATION: {type: 'CONSERVATION', name: 'Conservación'},
    DEVELOPING: {type: 'DEVELOPING', name: 'Desarrollo'}, 
  })
const zone = Object.freeze({
    COMMERCIAL: {type: 'COMMERCIAL', name: 'Comercial'},
    DOTATIONAL: {type: 'DOTATIONAL', name: 'Dotacional'}, 
    RESIDENTIAL: {type: 'RESIDENTIAL', name: 'Residencial'},
  });

const states = Object.freeze({
  ON_SALE: {type: "ON_SALE", name: "En venta"},
  PAUSED: {type: "PAUSED", name: "Pausada"},
  RETIRED: {type: "RETIRED", name: "Retirado"},
  BOUGHT: {type: "BOUGHT", name: "Comprado"},
  SOLD_OUT: {type: "SOLD_OUT", name: "Vendido"}
});

export {
    businessType,
    treatment,
    zone,
    states,
}