import HttpResponseEmun from '../helpers/enums/HttpResponseEmun';
import Request from './Request';

export default async function getFieldFile (id, filename, model) {

    // The filename already includes the element id, 
    // this will use the id that you pass if there is any on the filename.

    const url = filename.includes("/") ? `/file/${filename}` : `/file/${id}/${filename}`;

    try {
        const response = await Request.get(
          url,
          {
            model: model,
          },
          { responseType: 'blob' }
        )
        if (response.status === HttpResponseEmun.OK) {
          const blob = response.data
          const url = window.URL.createObjectURL(blob)
          return url
        }
      } catch (error) {
        console.error(error)
      }
}