import Request from './Request';

export default async function getLandsToOffer(filter, associateTypeId, landRequestId) {
   
    try {
        //const response = await Request.get(`/land-requests/${landRequestId}/offer-lands/by-associate/${associateTypeId}`, { ...filter, stateParam: 'ON_SALE', showDetailsParam: 0, creatorParam: brokerId })
        const response = await Request.get(`/land-requests/${landRequestId}/offer-lands/by-associate/${associateTypeId}`, { ...filter, stateParam: 'ON_SALE' });
  
        return response
        
    } catch (error) {
        error && console.error(error)
        return error
    }
    
  }