import React, { useState } from 'react'
import { Divider, Pagination, Empty } from 'antd';
import './index.css';
import RequestListItem from './RequestListItem';



const RequestListSmall = ({ currentRequestLots, filterTableList, setFilterTableList, setShowDetails, 
    selectedRequest, setSelectedRequest, filter, setFilter}) => {

    const [logo, setLogo] = useState({});

    const requestLotClickHandler = (item) => {
        setShowDetails({show: true, request: item});
    }

    const offerButtonHandler = (e, id) => {
        e.stopPropagation();
        if(id === selectedRequest) return setSelectedRequest("");
        setSelectedRequest(id);
    }
    
    return (
            <>
                
            <div className='w-100'>   
                <table >
                <tbody>
                {currentRequestLots && currentRequestLots.type !== 'lands' && 
                <tr className='table-row-s'>
                    
                </tr>}

                {currentRequestLots.content && currentRequestLots.content.length === 0 &&
                    <tr>
                    <td colSpan={8}>
                    <Empty 
                        description={
                        <span>
                            No se encontraron datos
                        </span>}
                        image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    
                    </td>
                    </tr>}
                    {currentRequestLots.content && currentRequestLots.content.map((item, i) => {
                    
                    return <RequestListItem key={item._id} item={item} logo={logo} setLogo={setLogo} requestLotClickHandler={requestLotClickHandler} offerButtonHandler={offerButtonHandler} 
                    selectedRequest={selectedRequest} filterTableList={filterTableList} setFilterTableList={setFilterTableList} />})}
                    <tr >
                        <td colSpan="7">
                        <Divider style={{margin: '2px'}}/>
                        </td>
                    </tr>

                </tbody>
                <tfoot>
                    <tr>
                    <td colSpan="7" align='right'>
                        <Pagination
                            current={filter.page +1}
                            onChange={(current, pageSize)=> setFilter({...filter, page: current -1})}
                            defaultCurrent={1}
                            total={currentRequestLots.totalElements}
                            showTotal={(total) => `Total ${total}`}
                            responsive
                            disabled={currentRequestLots.totalElements >  currentRequestLots.size ? false : true}
                        />
                    </td>
                    </tr>
                </tfoot>
                </table>
            </div>

            </>
        )
    }

export default RequestListSmall;