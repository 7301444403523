import { Skeleton } from 'antd';
import React from 'react';

function SkeletonAssociateBroker() {
  return (
    <div className="mt-4">
        {Array(9).fill([]).map((e, i) => {
        return (<div key={i} className="row mx-auto my-2">
        <div className="col-12">
            <Skeleton.Input style={{height: "4rem"}} size="large" block active />
        </div>
        </div>)
        })}
    </div>
  )
}

export default SkeletonAssociateBroker