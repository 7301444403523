import Request from './Request'
import UserModelsEnum from '../helpers/enums/UserModelsEnum'
import HttpResponseEmun from '../helpers/enums/HttpResponseEmun'
export const verifyEmail = async (email) => {
  try {
    const response = await Request.get('/public/users/by-email', { email })
    if (response.status === HttpResponseEmun.OK) {
      throw Error(`El correo electrónico ${email} ya está registrado`)
    }
    return true
  } catch (error) {
    console.error(error)
  }
  return false
}

export const login = async (email, password) => {
  try {
    const response = await Request.post('/public/users/signin', {
      email,
      password,
    })
   
    if (response.status === HttpResponseEmun.OK && response.data.id) {
      const { id, model, token, user } = response.data 
      localStorage.setItem('authorization', token)

      if(model === UserModelsEnum.Developer){
        localStorage.setItem('user', JSON.stringify({ model, id, developer:user.associateDeveloper[0]._id }))
      }else if(model === UserModelsEnum.Broker){
        localStorage.setItem('user', JSON.stringify({ model, id, broker:user.associateBroker[0]._id }))
      }else{
        localStorage.setItem('user', JSON.stringify({ model, id}))
      }
      if(user.theme){
        localStorage.setItem('theme',JSON.stringify(user.theme.theme))  
      }else{
        localStorage.setItem('theme',JSON.stringify(
          {
            'background-color':"#272a30",
            'color':"#fafafa ",
          }
        ))
       
      }
      return { model, id }
    }
  } catch (error) {
    console.error(error)
  }
  return false
}
