import { message } from 'antd';
import React, {useCallback, useContext, useState, useEffect} from 'react'
import { useAuth } from '../../../context/AuthContext';
import { Context } from '../../../context/lotsContext';
import HttpResponseEmun from '../../../helpers/enums/HttpResponseEmun';
import UserModelsEnum from '../../../helpers/enums/UserModelsEnum';
import DetailsModal from '../../../pages/Search/DetailsModal';
import getAllRequestLands from '../../../services/getAllRequestLands';
import getLandsToOffer from '../../../services/getLandsToOffer';
import SkeletonLoader from '../RequestListSmall/SkeletonLoader/SkeletonLoader';
import RequestListSmall from '../RequestListSmall';

function AssociatedLandRequests({developerId}) {

    const [filterTableList, setFilterTableList] = useState({page: 0, showRows: 10, statusParam: 'ACTIVE' });
    const [filter, setFilter] = useState({page: 0, showRows: 10, associateTypeParam: developerId, statusParam: 'ACTIVE' });
    const [currentRequestLots, setCurrentRequestLots] = useState([]);
    const [showDetails, setShowDetails] = useState({show: false, request: {}});
    const [searchParams, setSearchParams] = useState({});
    const [searchLoading, setSearchLoading] = useState(true);
    const [modalLoading, setModalLoading] = useState(false);

    const [selectedRequest, setSelectedRequest] = useState("");

    const {user, userData} = useAuth();
    const { dispatch } = useContext(Context);

    const closeModal = () => {
        setShowDetails({show: false, request: {}})
    }

    const getRequestCallback = useCallback(
        (searchParams, filter) => {
            if(user && user.model !== UserModelsEnum.Broker) return;
            setSearchLoading(true)
            getAllRequestLands(searchParams, filter).then(res => {
            
            setCurrentRequestLots(res);
            setSearchLoading(false);
            });
        },
        [searchParams, filter],
    );

    useEffect(() => {
        getRequestCallback(searchParams, filter);
      }, [searchParams, filter]);

    const fetchDataLandsCallback = useCallback(
        async (filterTableList, selectedRequest) => {
          dispatch({ type: 'SET_IS_VISIBLE_ASSOCIATE', payload: {option: false, 
            type: '', lot: {}, toggleState: {type: "lands", label: "Mis lotes"}}});
          dispatch({ type: 'RESET_CURRENT_ASSOCIATE_LOTS'});
          const response = await getLandsToOffer(filterTableList, userData._id, selectedRequest);
          if (response.status === HttpResponseEmun.OK) {
            dispatch({ type: 'SET_CURRENT_ASSOCIATE_LOTS', payload: {type: "lands", ...response.data} })
          }else{
            message.error('Error al cargar los lotes')
          }
          },
        [],
    );

    useEffect(() => {
        if(selectedRequest){
            fetchDataLandsCallback(filterTableList, selectedRequest);
        }
    }, [filterTableList, userData, user, selectedRequest]);

    return <div className="profile-developer-table m-0 p-0 w-100">

        {!searchLoading && (<div className="search-table-container">
            <RequestListSmall currentRequestLots={currentRequestLots} filterTableList={filterTableList} setFilterTableList={setFilterTableList}
            setShowDetails={setShowDetails} selectedRequest={selectedRequest} setSelectedRequest={setSelectedRequest}
            filter={filter} setFilter={setFilter} />
        </div>)}

        {searchLoading && <SkeletonLoader />}

        <DetailsModal modalLoading={modalLoading} showDetails={showDetails} 
        closeModal={closeModal} setSelectedRequest={setSelectedRequest}
        requestLandByIdCallback={() => {}} selectedRequest={selectedRequest} />

    </div>
}

export default AssociatedLandRequests