import React, { useContext, useMemo } from 'react';
import { Button, Avatar, Tooltip, Badge} from 'antd';
import { UserOutlined, MinusOutlined, UpOutlined, CheckSquareTwoTone } from '@ant-design/icons';
//import getPicture from '../../../helpers/getPicture';
import './index.css';
import { ChatContext } from '../../../context/ChatContext';
import getPicture from '../../../helpers/getPicture';
import UserModelsEnum from '../../../helpers/enums/UserModelsEnum';
import { useAuth } from '../../../context/AuthContext';

function RequestLandButton({item, selectedLand, setSelectedLand}) {

    const {setBrokerAssociate,setDeveloperAssociate, createAssociateChat} = useContext(ChatContext);
    const {userData} = useAuth();

    const onChatHandle = (e, land) =>{
      e.stopPropagation();
      setBrokerAssociate(land.associate[0]);
      setDeveloperAssociate(userData);
      createAssociateChat(); 
    }

    const requestLand = (e, landId, registrationNumber) => {
        e.stopPropagation();
        if(selectedLand && item._id === selectedLand.landId) return setSelectedLand({landId: "", registrationNumber: "", disabledList: []});
        else setSelectedLand({landId, registrationNumber, disabledList: []});
    }

    const photo = useMemo(() => {
        const userInfo = item.associate[0];
        const url = getPicture(userInfo._id, userInfo.photo, UserModelsEnum.User);
        return url;
    }, [item]);

    return (
        <>
            <span className='mr-2'>{item.associates ? <Tooltip title="Tienes solicitudes que cumplen con este lote">
                <Badge size="small" count='!'>
                    <CheckSquareTwoTone
                    style={{fontSize: '25px'}} 
                    twoToneColor="#eb2f96" />
                </Badge>
            </Tooltip> : null}</span>
            <span className='divider'><MinusOutlined style={{ fontSize: '1.5rem', color: 'gray', transform: 'rotate(90deg)' }} /></span>
            <span className='chat-icon'>
                <Tooltip title="Conversar">
                    <svg onClick={(e) => {        
                        onChatHandle(e, item)
                    }
                    } className="contactSvg" width="28" height="28" viewBox="0 0 28 28" fill="#64CBF7" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M3.33464 0.667969C1.86797 0.667969 0.681302 1.86797 0.681302 3.33464L0.667969 27.3346L6.0013 22.0013H24.668C26.1346 22.0013 27.3346 20.8013 27.3346 19.3346V3.33464C27.3346 1.86797 26.1346 0.667969 24.668 0.667969H3.33464ZM3.33464 3.33464H24.668V19.3346H4.89464L3.33464 20.8946V3.33464ZM16.668 14.0013H6.0013V16.668H16.668V14.0013ZM6.0013 10.0013H22.0013V12.668H6.0013V10.0013ZM22.0013 6.0013H6.0013V8.66797H22.0013V6.0013Z" fill="#64CBF7"/>
                    </svg>
                </Tooltip>
            </span>
            <Avatar className="requestLand-avatar" icon={<UserOutlined />} src={photo} size={30}/>
            <Button type="primary" 
            style={{marginRight: "1rem"}}
            className="requestLandButton"
            onClick={(e) => requestLand(e, item._id, item.registrationNumber)}>Solicitar lote
            {<UpOutlined style={{fontSize: "10px"}} className={"offer-btn-icon" + " " + (selectedLand && selectedLand.landId === item._id ? "" : "icon-rotate-animation")} />}</Button>
        </>
    )
}

export default RequestLandButton
