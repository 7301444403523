import React from 'react'
import { Redirect } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import UserModelsEnum from '../../helpers/enums/UserModelsEnum'
import HomeDeveloper from '../../components/Home/HomeDeveloper'
import HomeBroker from '../../components/Home/HomeBroker'
import './index.css'
export default function Home() {
  const { user } = useAuth()

  return (
    <div className='container-home'>
      {/* {user && (user.model === UserModelsEnum.SUDO || user && user.model === UserModelsEnum.ADMIN) &&
      <HomeDeveloper />
      } */}
      {user && user.model === UserModelsEnum.Broker &&
      <HomeBroker />
      }
     {user && user.model === UserModelsEnum.Developer &&
      <HomeDeveloper />
      }
    </div>
  )

}
