import React, {useContext, useRef} from 'react'
import { Input, InputNumber, Popover, Switch, Tooltip } from 'antd';
import { MinusOutlined, SearchOutlined } from '@ant-design/icons';
import { Select } from 'antd';
const { Option } = Select;
const children = [];

export const FilterHeader = ({ filterList,label,item,filterUser, setFilteruser, defaultChecked, noSwitch }) => {        
    
    const handleChange = (value) => {
        
        clearTimeout(refSearch.current)
        const parameter ={} 
        parameter[`${item}Param`] = value
        refSearch.current = setTimeout(async() => {
          setFilteruser({...filterUser,...parameter})
        }, 1000)
        
      };
    const refSearch = useRef(null)
   

    const filter = <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', width:250 }}>
        <span style={{ fontWeight: 700, fontSize: '12px' }}>{label}</span>
        <div>
            <Select    
                mode="tags"
                tokenSeparators={[',']}            
                style={{
                    width:200,
                }}
                onChange={handleChange}
                >
                {  filterList[item] &&
                 filterList[item].map( (field,index) => {
                    return <Option key={index} value={field.name}>{field.name}</Option>
                 })
                }
                                
            </Select>            
        </div>

        
    </div>
    return (

        <div className="filter-container">
            <div className="filter-right">
                {!noSwitch && <div style={{ marginRight: '1rem' }}>
                    <Tooltip placement="left" title='Buscar activos/inactivos'>
                        <Switch checkedChildren="Activo" unCheckedChildren="Inactivo" onChange={(e) => { setFilteruser({ ...filterUser, statusParam: filterUser.statusParam ? (e ? 'ACTIVE' : 'INACTIVE') : undefined, stateParam: filterUser.stateParam ? (e ? "ACTIVE" : "INACTIVE") : undefined }) }} defaultChecked checked={defaultChecked === 'INACTIVE' ? false : true} />
                    </Tooltip>
                </div>}
                <Popover content={filter} placement="bottomRight" trigger="click">
                    <div style={{ cursor: 'pointer' }}>
                        <span style={{ color: '#fff' }}>{label}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filter" viewBox="0 0 16 16">
                            <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </div>
                </Popover>
            </div>
        </div>

    )
}