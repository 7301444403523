import { Skeleton } from 'antd';
import React from 'react';

function FaqSkeleton() {
  return (
    <>
        {Array(9).fill([]).map((e, i) => {
        return (<div key={i} className="row mx-auto my-1">
        <div className="col-12">
            <Skeleton.Input style={{height: "3rem"}} size="large" block active />
        </div>
        </div>)
        })}
    </>
  )
}

export default FaqSkeleton