import React, { useState, useEffect } from 'react';
import { InputNumber, Slider } from 'antd'

import './index.css'

function CustomSlider({
    tipFormatter = null,
    className = '',
    min = 0,
    max = 0,
    onAfterChange,
    step = undefined,
    keyboard = true,
    range = true,
    onChange,
    value,
    defaultValue = undefined
}) {

    const [afterNumberChange, setAfterNumberChange] = useState(false);
    const [showInputs, setShowInputs] = useState(false);

    const onChangeInputMin = (newValue) => {
        if(newValue > max) newValue = max;
        onChange([newValue, value ? (value[1] || max) : max]);
        setAfterNumberChange(prev => !prev);
    };

    const onChangeInputMax = (newValue) => {
        if(newValue > max) newValue = max;
        if(newValue == null) newValue = max;
        onChange([value ? (value[0] || min) : min, newValue]);
        setAfterNumberChange(prev => !prev);
    };

    const formatter = (value, info) => value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    const parser = (value) => value.replace(/\$\s?|(\.)*/g, '');
    

    useEffect(() => {
      const debounceTimer = setTimeout(() => {
        if(value !== null && (value && value.length > 0)){
          onAfterChange(value)
        }
      }, 800);
      return () => clearTimeout(debounceTimer);
    }, [afterNumberChange]);

    return <div onMouseOver={() => setShowInputs(true)} onMouseLeave={() => setShowInputs(false)}>
        <Slider 
        tipFormatter={tipFormatter} 
        className={className} 
        min={min} 
        onAfterChange={onAfterChange} 
        max={max} 
        step={step} 
        keyboard={keyboard} 
        range={range}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue} />
        <div 
        style={{visibility: showInputs ? 'visible' : 'hidden', 
        opacity: showInputs ? 1 : 0,
        width: '100%',
        position: 'absolute', zIndex: 99}} 
        className='d-flex justify-content-between transitions'>
        <InputNumber
            min={min}
            max={max}
            formatter={formatter}
            parser={parser}
            value={value ? (value[0] || min) : min}
            onChange={onChangeInputMin}
        />
        <InputNumber
            min={min}
            max={max}
            formatter={formatter}
            parser={parser}
            value={value ? (value[1] || (value[1] === 0 ? 0 : max)) : max}
            onChange={onChangeInputMax}
        />
        </div>
    </div>
}

export default CustomSlider