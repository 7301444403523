import React from 'react';
import { Divider, Skeleton, Space } from 'antd';
import './index.css';

function SkeletonComponent() {
    return (
        <>
            <Space className="skeleton_headers" style={{display: "flex", justifyContent: "center", margin:"1rem"}}>
                <Skeleton.Input active />
                <Skeleton.Input active />
                <Skeleton.Input active />
                <Skeleton.Input active />
                <Skeleton.Input active />
                <Skeleton.Input active />
            </Space>

            {Array(4).fill([]).map((e, i) => {
                return (<div key={i} className="row my-2 w-90 mx-auto">
                <div className="col-lg-11 col-md-12 col-12">
                    <Skeleton.Input block active />
                </div>
                <div className="col-1 skeleton_actions">
                    <Skeleton.Button shape="round" active />
                </div>
                </div>)
            })}

            <Divider />

        </>
    )
}

export default SkeletonComponent;
