import React from 'react';
import LotsRequestDetails from '../lotsRequestDetails/lotsRequestDetails';
import Spinner from '../../../assets/spinners/spinner.svg';
import { CloseOutlined } from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';

function DetailsModal({modalLoading, showDetails, closeModal, setSelectedRequest, requestLandByIdCallback, selectedRequest}) {
    return (
        <div>
        <div 
          style={{
            width: '100%', 
            zIndex: 1000,
            position: 'fixed',
            display: 'inline-block',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1001,
            background: 'rgba(0, 0, 0, 0.542)',
            }}
            hidden={!modalLoading}>
            <img src={Spinner} />
          </div>
          <Modal
            width={700}
            visible={showDetails.show}
            destroyOnClose
            closeIcon={
              <Tooltip title="Cerrar">
                <CloseOutlined onClick={closeModal} style={{ fontZise: '30px', color: '#39C0ED', width: '100%', height: '100%', display: 'block', paddingTop: '15px'  }}/>
              </Tooltip>
            }
            footer=''
            centered
            >
                {showDetails.show && <LotsRequestDetails land={showDetails.land} lotsRequestData={showDetails.request}
                setSelectedRequest={setSelectedRequest} closeModal={closeModal} requestLand={showDetails.requestLand} requestLandByIdCallback={requestLandByIdCallback}
                selectedRequest={selectedRequest} />}
                
            </Modal>
        </div>
    )
}

export default DetailsModal
