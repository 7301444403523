import React, { createContext, useState, useEffect } from "react";
import getRequestsAvailable from '../services/getRequestsAvailable';
import { useAuth } from '../context/AuthContext';
import UserModelsEnum from '../helpers/enums/UserModelsEnum'

export const RequestsAvailableContext = createContext();

export const RequestsAvailableProvider = ({ children }) => {  

  const { user } = useAuth();  
  
  const [requestsAvailable, setRequestsAvailable] = useState(0);

  const incrementRequestsAvailable = () => {
    setRequestsAvailable(prev => prev + 1);
  }

  const decrementRequestsAvailable = () => {
    setRequestsAvailable(prev => prev - 1);
  }

  const checkRequestsAvailable = () => {
    
    getRequestsAvailable().then(requestsAvailableResponse => {
      setRequestsAvailable(requestsAvailableResponse.data.totalElements);
    }).catch(e => console.error(e));

  }

  useEffect(() => {
    if(user && user.model === UserModelsEnum.SUDO ||
       user && user.model === UserModelsEnum.ADMIN){
          checkRequestsAvailable();
       }
  }, [user]);
 

  return(
    <RequestsAvailableContext.Provider value={{requestsAvailable, incrementRequestsAvailable, decrementRequestsAvailable}}>
      {children}
    </RequestsAvailableContext.Provider>
  )
  
}