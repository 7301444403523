import Request from './Request';

export default async function getLand(id) {
   
    try {
                 const response = await Request.get(`/lands/${id}`)
                       return response
        
    } catch (error) {
        error && console.error(error)
        return error
    }
    
  }