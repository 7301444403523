import React from 'react'

import RegisterDesarrollador from '../Register/registerDesarrollador'
import PieRegistro from '../PieRegistro/PieRegistro'

const LadoIzquierdo = (props) => {
  return (
    <div className='container-fluid contenedor-1'>
      <p className='text-start tit-Principal'>
        Regístrate como <br /> Desarrollador
      </p>
      <RegisterDesarrollador />
      <PieRegistro
        direc='auth/login'
        mensajeLink='Inicia Sesión'
        mensaje='¿Ya tienes cuenta?'
        tipo='1'
        lineClass='linea'
      />
    </div>
  )
}
export default LadoIzquierdo
