import React,{useState,useContext, useEffect} from "react"
import {Layout,Menu,Empty } from "antd";
import SiderPanel from "./components/ChatSider/Sider";
import createNewChat from "../../services/Chat";
import ChatBox from "./components/ChatBox/chatBox";
import { ChatContext } from "../../context/ChatContext";
const { Header, Content, Footer } = Layout;

const mainStyle ={
  minHeight: 'calc(100vh - 100px)',
}

export default function Chat(){
  const {  
    currentLandSelect,
    currentChat  
  } = useContext(ChatContext)
  const [land,setLand] = useState(null);
  const chatContentStyle = { 
    margin: '0px 16px 0', 
    display:"flex",
    justifyContent:'center',
    flexDirection:'column',
    alignContent:'center', 
    overflowX:'hidden'   
  }

  useEffect(() => {    
    if(currentChat){
      setActiveChat(true);
      setLand(currentLandSelect)
    }else{
      setLand(null)
      setActiveChat(false);
    }
  },[currentChat])

  const [activeChat,setActiveChat] = useState(false);

  const empy = () => <Empty />;
  return (    
    
    <Layout style={mainStyle}  >
      <SiderPanel/>
      <Layout>
        
        <Content className="chat-box-container" style={chatContentStyle}>
         {!activeChat&& <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            {empy()}
          </div>}
          { activeChat && 
            <ChatBox currentLand={land} chat={currentChat}/>
          }
        </Content>
        
      </Layout>
    </Layout>
    
  )
}