import Request from '../services/Request';

export const getAllRoles = async () => {
    try {
        const res = await Request.get("/roles", {showRows: 500, stateParam: "ACTIVE", ordering: "code,asc"});
        return res;
    } catch (error) {
        if(error) console.error(error);
        return error;
    }
}