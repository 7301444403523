import React, {useCallback, useState} from 'react'
import CloseCircle from '../icon/close-circle-outline.svg'
import uploadFileIcon from '../../../assets/icons/uploadFileIcon.png'
import loadingFileIcon from '../../../assets/icons/loadingFileIcon.png'
import loadedFileIcon from '../../../assets/icons/loadedFileIcon.png'
import propTypes from 'prop-types'
import ViewFile from '../../ViewFile/ViewFile'
import getLandFile from '../../../services/getLandFile'
//{name, label, type, placeholder, value, onChange, onBlur, error}
//types inputs:{text, number, email, file, image}
/**
 * 
 * @returns {JSX.Element}
 * @constructor
 * @param {string} name
 * hola
 */
export default function InputList({values, onChange, deleteInput, getValues, inputType}) {
const [fileStatus, setFileStatus] = useState({})
  const onChangeValues = (e, i) => {
    if(e.target.type === 'file'){
      getValues(e, i)
      setFileStatus({...fileStatus, [e.target.name]: 'loading'})
      setTimeout(() => {
        setFileStatus({...fileStatus, [e.target.name]: 'loaded'})
      }, 2000);
    }
   if(onChange){
    onChange(e)
   }
  }

  const getFileCallback = useCallback((path) => {
    const getFileLink = async (path) => {
      return await getLandFile(path);
    }
    return () => getFileLink(path);
  }, []);

return (
  <>
      {values && !inputType && 
        values.map((item, index) => {
          switch (item.type) {
            case 'text':
              return(
                <div key={index} className="input-type">
                  <label className="label-input">{item.name}</label>
                  <div className='input'>
                    <input defaultValue={item.value} autoFocus type={item.type} name={item.name} onChange={onChangeValues} onBlur={(e)=>getValues(e, index)}/> 
                    <img src={CloseCircle} style={{cursor: 'pointer', width: '20px'}} onClick={()=>deleteInput(index)}/>
                  </div>
                </div>
              )
            case 'number':
              return(
              <div key={index} className="input-type">
                <label className="label-input">{item.name}</label>
                <div className='input'>
                  <input defaultValue={item.value} autoFocus type={item.type} name={item.name} onChange={onChangeValues} onBlur={(e)=>getValues(e, index)}/> 
                  <img src={CloseCircle} style={{cursor: 'pointer', width: '20px'}} onClick={()=>deleteInput(index)}/>
                </div>
              </div>
              )
            case 'email':
              return(
              <div key={index} className="input-type">
                <label className="label-input">{item.name}</label>
                <div className='input'>
                    <input defaultValue={item.value} autoFocus type={item.type} name={item.name} onChange={onChangeValues} onBlur={(e)=>getValues(e, index)}/> 
                    <img src={CloseCircle} style={{cursor: 'pointer', width: '20px'}} onClick={()=>deleteInput(index)}/>
                </div>
              </div>
              )
            case 'file':
              const fileUploaded = typeof item.value === 'string';
              //if(fileUploaded) setFileStatus({...fileStatus, [item.name]: 'loaded'});
              return(
                <div autoFocus key={index} className='form-row text-left mt-2'>
                      <label className='label-input-file'>
                      {item.name}
                      </label>
                      <div className='input'>
                        <label
                          className={`tit-Archivobtn ${
                            fileStatus[item.name] === 'loading' ? 'loading' : (fileStatus[item.name] === 'loaded' || fileUploaded) ? 'loaded' : 'upload'
                          }`}
                          htmlFor={item.name}
                        >
                          <img
                            src={
                              fileStatus[item.name] === 'loading'
                                ? loadingFileIcon
                                : (fileStatus[item.name] === 'loaded' || fileUploaded)
                                ? loadedFileIcon
                                : uploadFileIcon
                            }
                            className='imgDentroBtn'
                          />
                          {fileStatus[item.name] === 'loading'
                            ? 'Cargando archivo'
                            : fileStatus[item.name] === 'loaded'
                            ? 'Carga exitosa'
                            : (fileUploaded ? 'Remplazar archivo' : 'Subir archivo')}
                            <ViewFile getFile={getFileCallback(item.value)} file={fileUploaded ? null : item.value} show={fileStatus[item.name] !== 'loading'} />
                        </label>
                        <input
                          name={item.name}
                          id={item.name}
                          className= 'input-file'
                          type='file'
                          onChange={(e)=> onChangeValues(e, index)}
                        />
                        <img src={CloseCircle} style={{cursor: 'pointer', width: '20px'}} onClick={()=>deleteInput(index)}/>
                      </div>
                    </div>
              )
                    
            default:
              return(
                <div key={index} className="input-type">
                    <label className="label-input">{item.name}</label>
                    <input type={item.type} name={item.name} onChange={onChange}/> 
                </div>
              )
          }
    })}

    {inputType && values &&
      values.map((item, index) => {
        return(
        <div key={index} className="input-type">
          <label className="label-input">{item.name}</label>
          <input type={inputType} name={item.name} onChange={onChange}/> 
        </div>
        )
    })}
</>
)

}

/* InputList.propTypes = {
  params: propTypes.array.isRequired,
  setParams: propTypes.func.isRequired,
  onChange: propTypes.func.isRequired
} */