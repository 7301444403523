import React, { useState, useEffect,useContext } from "react";
import './AdminPanelConfigurations.css';
import { MenuOutlined } from '@ant-design/icons';
import {AppstoreOutlined} from '@ant-design/icons';
import { Menu } from 'antd';
import TableListConfigurations from "./TableListConfigurations/TableLisConfigurations";
import Request from '../../services/Request';
import HttpResponseEmun from "../../helpers/enums/HttpResponseEmun";

const AdminPanelConfigurations = () => {
    const [toggleState, setToggleState] = useState({type: "AREA", label: "Área"});  
    const [filter, setFilter] = useState({page: 0, showRows: 10, statusParam: "ACTIVE", ordering: '' });    
    const [datas, setDatas] = useState({});
    const [loading, setLoading] = useState(false);

    async function loadArea() {
        const response = await Request.get('/geographical-area', { ...filter})
        if (response.status === HttpResponseEmun.OK) {
            setLoading(true)                                                                              
            setDatas({type: toggleState.type, ...response.data})
            setLoading(false)                                                   
        }    
    }

    async function loadRange() {
        const response = await Request.get('/ranges', { ...filter })
        if (response.status === HttpResponseEmun.OK) {
            setLoading(true)                                                            
            setDatas({ type: toggleState.type,...response.data})
            setLoading(false)                                                   
        } 
    }

    async function loadData() {
        try {
            if(toggleState.type === 'AREA'){
                loadArea()                         
            }else{
                loadRange()
            }
        } catch (error) {
            error && console.error(error)
        }        
    }
    
    const refresh = (val)=>{        
        loadData()        
    }

    useEffect(() => {
        setLoading(true)                
        loadData()
    }, [filter, toggleState])    
    
    return (
    <>
        <div className="container">
            <div className="container containerListAndSearchAdmin">
                <div className="blocTabs">
                    <button
                        className={toggleState.type === "AREA" ? "tabsListAdminPanel active-tabsListAdminPanel" : "tabsListAdminPanel"}                        
                        onClick={() => {
                            setDatas({});
                            setToggleState({type: "AREA", label: "Area"})
                            setFilter({page: 0, showRows: 10, statusParam: "ACTIVE",stateParam:'ACTIVE'})
                        }}
                    >
                        <div className={toggleState.type === "AREA" ? "boxBtnSelec" : "boxBtn"}                             
                        >Área geográfica</div>
                    </button>

                    <button
                        className={toggleState.type === "RANGE" ? "tabsListAdminPanel active-tabsListAdminPanel" : "tabsListAdminPanel"}
                        onClick={() => {
                            setDatas({});
                            setToggleState({type: "RANGE", label: "Rango"})
                            setFilter({page: 0, showRows: 10, statusParam:'ACTIVE',stateParam:'ACTIVE'})
                        }}
                    >
                        <div className={toggleState.type === "RANGE" ? "boxBtnSelec2" : "boxBtn"} 
                       >Rango de área</div>
                    </button>                    
                </div>

            </div>
            <div className='containerListResponsive'>
                <h4 >{toggleState.label}</h4>
                <div >
                    
                <Menu theme='dark' className='menu'  style={{backgroundColor:"#272A30", color: "#fff", borderBottom: 0, minHeight: '40px', minWidth: '50px'}} mode="horizontal" >
                  
                  <Menu.SubMenu key="0" icon={<MenuOutlined />}>
                    <Menu.Item onClick={() => setToggleState({type: "AREA", label: "Area"})} key={'1'} icon={<AppstoreOutlined />}>
                    Área grográfica
                    </Menu.Item>
                    <Menu.Item onClick={() => setToggleState({type: "RANGE", label: "Rango"})} key={'2'}  icon={<AppstoreOutlined />}>
                    Rango de área
                    </Menu.Item>                     
                  </Menu.SubMenu>
                </Menu>
                </div>
            </div>
            <div className="container p-0 container-listUser">            
            
              <TableListConfigurations datas={datas} setDatas={setDatas} filter={filter} setFilter={setFilter} toggle={toggleState.type} refresh={refresh} />              
              
            </div>
           
        </div>
    </>
    )
}

export default AdminPanelConfigurations