import React, { useContext } from 'react';
import { Card, Tooltip, message, Popconfirm } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import { Context } from '../../../../../../context/lotsContext';
import formatDate from '../../../../../../helpers/formatDate';
import Request from '../../../../../../services/Request';
import HttpResponseEmun from '../../../../../../helpers/enums/HttpResponseEmun';

function NoteList() {

    const { state: { isVisibleHB, currentLots }, dispatch } = useContext(Context);

    const deleteNote = async (note) => {
        try {
            const res = await Request.delete(`/lands/${isVisibleHB.lot._id}/delete-notes/${note._id}`);
            if(res.status === HttpResponseEmun.OK){
            dispatch({
                type: 'SET_IS_VISIBLE_HB',
                payload:{
                toggleState: {
                    ...isVisibleHB.toggleState
                },
                option: true, 
                type: isVisibleHB.type, 
                lot: res.data,
                }
            });
            const updatedLots = currentLots.content.map(item => {
                if(item._id === res.data._id) return res.data;
                return item;
            });
            dispatch({ type: 'SET_CURRENT_LOTS', payload: { ...currentLots, content: updatedLots } });
            message.success({content: "Recordatorio eliminado", duration: 3});
            }
        } catch (error) {
            message.error({content: "Error al eliminar el recordatorio", duration: 4});
            console.error(error);
        }
    }

    return (
        <div className="mt-2">

        {isVisibleHB.lot.notes && isVisibleHB.lot.notes.length > 0 && isVisibleHB.lot.notes.map(note => {
            return <><Card className="my-2" title={formatDate(note.date)}  
            extra={<Popconfirm
                title={`¿Estas seguro que quieres eliminar esta recordatorio?`}
                onConfirm={async () => await deleteNote(note)}
                okText="Si"
                cancelText="No"
            >
                <Tooltip title="Eliminar">
                    <DeleteTwoTone className="p-2 border border-danger border-opacity-10 rounded" style={{cursor: "pointer"}} twoToneColor="#F93154" />
                </Tooltip>
            </Popconfirm>}
            style={{width: "98%"}}
            >
                <p>{note.comment}</p>
            </Card>
            </>
        })}

        </div>

    );
}

export default NoteList
