import React from 'react'
import RegisterCorredor from '../Register/registerCorredor'
import PieRegistro from '../PieRegistro/PieRegistro'

const LadoDerecho = (props) => {
  return (
    <div className='container-fluid contenedor-2'>
      <p className='text-start tit-Principal2'>
        Regístrate como <br /> Corredor
      </p>
      <RegisterCorredor />
      <PieRegistro
        direc='auth/login'
        mensajeLink='Inicia Sesión'
        mensaje='¿Ya tienes cuenta?'
        tipo='2'
        lineClass='linea2'
      />
    </div>
  )
}
export default LadoDerecho
