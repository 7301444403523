import React, { useRef } from 'react';
import { useState } from 'react';
import Spinner from '../../assets/spinners/spinner.svg';
import { Modal, Tooltip, Button, Input, Space } from 'antd';
import { ButtonApp } from '../button/button';
import { useAuth } from '../../context/AuthContext';
import useToasts from '../../hooks/useToasts';
import { CloseOutlined } from '@ant-design/icons';
import verContra from '../../assets/icons/visualizar.png'
import NoverContra from '../../assets/icons/novisualizar.png'

import './index.css';
import { associateBroker } from '../../services/developers';

const AssociateBroker = ({show, handleClose}) => {

  const [estadoVisualizar, setEstadoVisualizar] = useState(false);

  const { addToast } = useToasts()
  

  const {user, userData} = useAuth();
  const [showSpinner, setShowSpinner] = useState(false);
  const emailRef = useRef();
  const pwdRef = useRef();

  const handleSubmit = async () => {

    const brokerData = {
      email: emailRef.current.value,
      password: pwdRef.current.value,
    }

    const error = await associateBroker(brokerData);

    if(!error){
      handleClose();
      return addToast({
        type: 'success',
        title: 'Corredor asociado!!',
        message: `Se ha asociado el corredor correctamente`,
      });
    }

    if(error.data.message) addToast({
      type: 'error',
      title: '¡Verifica las credenciales!',
      message: error.data.message.split(" ").slice(1).join(" "),
    });

    else return addToast({
      type: 'error',
      title: 'Upss !!',
      message: '¡Ha ocurrido un error inesperado al asociar el corredor!',
    })

  }
   
  return (
    <div >
      <div 
      style={{
        width: '100%', 
        zIndex: 1000,
        position: 'fixed',
        display: 'inline-block',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1001,
        background: 'rgba(0, 0, 0, 0.542)',
        }}
      hidden={!showSpinner}>
          <img src={Spinner} />
      </div>
      <Modal
      width={400}
      visible={show}
      onCancel={handleClose}
      closeIcon={
        <Tooltip title="Cerrar">
          <CloseOutlined style={{ fontZise: '30px', color: '#39C0ED' }}/>
        </Tooltip>
      }
      footer={
        <div class="w-100 d-flex justify-content-center align-items-center">
          <span class="associateBtn"><ButtonApp onClick={handleSubmit} content='ASOCIAR CUENTA' primary/></span>
        </div>
      }
      >
      <h3 className='title'>¡Asociar Cuenta!</h3>
      <p className='subtitle__associate'>Ingresa los datos de tu cuenta de corredor para asociarla</p>

        <div>
          <label className='subtitle--login form-label'>*Correo</label>
        </div>
        <div className='input-group email-input'>
          <input
            ref={emailRef}
            name='email'
            type='text'
            placeholder='micorreo@correo.com'
            className='form-control'
            //onChange={formik.handleChange}
          />
          <label>
            <h5>@</h5>
          </label>
        </div>


        <div className='mt-3 d-flex justify-content-between'>
          <label className='subtitle--login form-label'>*Contraseña</label>
          <a href="">He olvidado mi contraseña</a>
        </div>
        <div className='input-group password-input'>
          <input
            ref={pwdRef}
            name='password'
            type={estadoVisualizar == false ? 'password' : 'text'}
            placeholder='********'
            className='form-control'
            //onChange={formik.handleChange}
          />
          <label>
            <img
              src={estadoVisualizar == false ? NoverContra : verContra}
              alt='Ver Constraseña'
              className='imgVerContra'
              onClick={() => setEstadoVisualizar(!estadoVisualizar)}
            />
          </label>
        </div>


      </Modal>
    </div>
  )
};

export default AssociateBroker;
