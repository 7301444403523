import React, { useState } from 'react'

import { Modal, Button } from 'react-bootstrap'
import useModals from '../../hooks/useModals'

import './ModalGuarda.css'

const ModalGuarda = () => {
  const { confirmationMessage, closeConfirmationMessage } = useModals()

  const handleClose = () => {
    closeConfirmationMessage()
    confirmationMessage.onCancel()
  }

  const handleConfirm = () => {
    closeConfirmationMessage()
    confirmationMessage.onConfirm()
  }

  return (
    <Modal
      show={confirmationMessage.isOpen}
      onHide={handleClose}
      className='confirm-message-modal'
    >
      <Modal.Header className='header'>
        <div role='button' className='close-button' onClick={handleClose}>
          ×
        </div>
      </Modal.Header>
      <Modal.Body className='body'>
        <p>{confirmationMessage.message}</p>
      </Modal.Body>
      <Modal.Footer className='footer'>
        <Button className='confirm-button' onClick={handleConfirm}>
          SI
        </Button>
        <Button className='cancel-button' onClick={handleClose}>
          NO
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalGuarda
