import React, { useState, useEffect, useMemo, useContext} from 'react';
import {Link} from 'react-router-dom';
import { Avatar, Col, Row, Button} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useAuth } from '../../../../context/AuthContext';
import { ChatContext} from '../../../../context/ChatContext';
import {Context} from '../../../../context/lotsContext';
import getProfilePicture from '../../../../services/getProfilePict';
import {Badge} from 'react-bootstrap';
import './LandRequestDetails.css';
import shortText from '../../../../helpers/shortText';
import interestUseEnum from '../../../../helpers/enums/interestUseEnum';

import ShowFile from '../../../../pages/Search/ShowFile/ShowFile';
import UserModelsEnum from '../../../../helpers/enums/UserModelsEnum';
import {states as RequestLandsEnum} from '../../../../helpers/enums/RequestLandsEnum';
import { ButtonApp } from '../../../button/button';
import NumberFormat from 'react-number-format';

const LandRequestDetails = ({setShowEditRequests}) => {
  const [logo, setLogo] = useState('');
  const {userData} = useAuth();
  const {setCurrentLandRequest} = useContext(ChatContext);

  const {state: {isVisible}, dispatch} = useContext(Context);

  const item = useMemo(() => ({...isVisible.landRequest, developer: userData}), [isVisible.landRequest, userData]);

  const getInterestBadgeNumber = useMemo(() => Object.values(item.interestUse || {}).filter(e => e === true).length
  , [item.interestUse]);

  const getBadgeWithInterest = (interest) => {

    if(!interestUseEnum[interest]) return null;

    return <Badge key={interest} pill className="bg-secondary">{interestUseEnum[interest].name}</Badge>
  }

  const setChat = async () => {
    setCurrentLandRequest(item);
  }



  useEffect(() => {
    const id = item.developer._id;
    const filename = item.developer.logo;
    const model = UserModelsEnum.Developer;

    getProfilePicture(id, filename, model).then((response) => {
      if(response) setLogo(response)});
  }, []);


  const handleEdit = () => {
    //dispatch({type: 'SET_NEW_REQUEST_LOT', payload: landRequest})
    dispatch({type:'SET_REQUEST_DATA_EDIT', payload: isVisible.landRequest});
    setShowEditRequests(true);
    dispatch({
      type: 'SET_IS_VISIBLE',
      payload:{
        toggleState: {
            ...isVisible.toggleState
        },
        option: false, 
        type: '',
        landRequest: isVisible.landRequest,
      }
    })
  }

  const handleDelete = () =>{
    dispatch({
      type: 'SET_IS_VISIBLE',
      payload:{
        toggleState: {...isVisible.toggleState},
        option: true, 
        type: 'request-delete',
        landRequest: isVisible.landRequest,
      }
    })
  }

   return (
    <div className='container-details'>
      <Row className='rows' style={{alignItems: 'end'}}>
        <span className='subtitle1'>Solicitud del Lote</span>
        <span className='subtitle2'>{/*lotsRequestData.registrationNumber*/}</span>
        <span style={{color: "#54B4D3"}} className='subtitle3'>{item.registrationNumber}</span>
      </Row>
      <Row className='rows'>
        <Avatar style={{border: "1px solid black", borderRadius: "50%"}} icon={<UserOutlined />} src={logo} size={90}/>
        <div className='type-and-name'>
          <span style={{color: "#136a99"}} className='subtitle1'>{item.developer.names.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())}{' '}{item.developer.lastNames.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())}</span>
          <span style={{color: "#136a99"}} className='subtitle3'>{item.developer.businessName}</span>
        </div>
      </Row>
      
      <div className="lot-request-detail-body">
        <Row className='rows' style={{marginBottom: "0"}}>
          <Col className='col' xs={12} md={12}>
            <h6>Área del lote (m<sup>2</sup>)</h6>
            <div className="d-flex flex-column">
                <span className='d-flex'><strong>Min: </strong><NumberFormat 
                      type='text' 
                      value={item.areaRange && item.areaRange.startRange} 
                      thousandSeparator={'.'} 
                      decimalSeparator={','} 
                      prefix={''}
                      disabled
                      style={{border: 'none', marginLeft: '10px', background: '#fff'}}
                    /></span>
                <span className='d-flex'><strong>Max: </strong><NumberFormat 
                      type='text' 
                      value={item.areaRange && item.areaRange.endRange} 
                      thousandSeparator={'.'} 
                      decimalSeparator={','} 
                      prefix={''}
                      disabled
                      style={{border: 'none', marginLeft: '10px', background: '#fff'}}
                    /></span>
            </div>
          </Col>
          <Col className='col' xs={12} md={12}>
            <h6>Zona</h6>
            <span>{item.location && item.location.location}</span>
          </Col>
          <Col className='col' xs={12} md={12}>
            <h6>Departamento</h6>
            <span>{item.location && item.location.department}</span>
          </Col>
          <Col className='col' xs={12} md={12}>
            <h6>Ciudad</h6>
            <span>{item.location && item.location.city}</span>
          </Col>
        </Row>

        <Row>
          <Col className={"col interest" + " " + (getInterestBadgeNumber > 3 ? "interest-large" : "")} xs={24} md={24}>
            <h6>Uso de Interés</h6>
            { item.interestUse && Object.keys(item.interestUse).map(interest => {
              if(item.interestUse[interest]) return getBadgeWithInterest(interest);
            })}
          </Col>
        </Row>
      </div>



      {item.observations && (
        <Row className='rows request-info-observations'>
          <Col className='col' xs={24}>
            <h6>Observaciones</h6>
            <span>{item.observations}</span>
          </Col>
        </Row>
      )}

    {item.fields && item.fields.length > 0 && <Row className="my-2">
       {(item.fields.map((field, i) => {
          return <Col key={i} className='col' xs={12} sm={8} md={8}>
            <h6>{field.name}</h6>
            {field.type !== 'file' && 
            <span title={field.value}>{field.value && shortText(field.value, 30)}</span>}
            {field.type === 'file' &&
            <ShowFile id={item._id} model="Solicitud de lote" filename={field.value} />}
          </Col>
      }))}
      </Row>}


      <Row className='rows mt-4'>
        <div className='button-group-lot' style={{whiteSpace: "nowrap"}}>
          <span className='button-lot-delete delete-btn'>
            <ButtonApp disabled={item.state === RequestLandsEnum.CLOSED.type || item.state === RequestLandsEnum.CANCELLED.type} content='ELIMINAR SOLICITUD' onClick={handleDelete} primary cancel/>
          </span>
          <span className='button-lot-edit'>
            <ButtonApp disabled={item.state === RequestLandsEnum.CLOSED.type || item.state === RequestLandsEnum.CANCELLED.type} content='EDITAR SOLICITUD' onClick={handleEdit} primary/>
            </span>
        </div>
      </Row>

      
    </div>
  )
}

export default LandRequestDetails;