import Request from '../services/Request';

export default async function updateRolPermissions (userId, permissions) {
    try {
        const res = await Request.put(`/users/${userId}/assign-permissions`, {permissions});
        return res;
    } catch (error) {
        if(error) console.error(error);
        return error;
    }
}