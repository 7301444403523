import React from 'react';
import { Skeleton} from 'antd';

function SkeletonComponent() {
    return (
        <>
            {Array(9).fill([]).map((e, i) => {
                return (<div key={i} className="row my-3 w-90 mx-auto">
                <div className="col-12">
                    <Skeleton.Input size="large" block active />
                </div>
                </div>)
            })}

        </>
    )
}

export default SkeletonComponent;
