import Request from '../services/Request';

export default async function getProfileUser (userId) {
    try {
        const res = await Request.get(`/users/${userId}/profile/user`);
        return res;
    } catch (error) {
        if(error) console.error(error);
        return error;
    }
}