import Request from './Request';

export default async function getLands(filter, searchParams) {
   
    try {
        const response = await Request.get(`/lands`, {...searchParams, ...filter, stateParam: 'ON_SALE', showDetailsParam: 1})

        return response
        
    } catch (error) {
        error && console.error(error)
        return error
    }
    
}