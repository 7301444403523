import React, {useEffect, useState} from 'react';
import InputList from "./InputList/InputList";
import NoteList from './NoteList/NoteList';
//import {PlusCircleOutlined} from '@ant-design/icons';
import PlusCircleOutlined from './icon';
import './index.css';

/**
 * 
 * @param {object} iconStyle object
 * @description stylos for icon
 * @param selectOptions array
 * @description array of options to be displayed in the select (types inputs) [text, number, email, file]
 * @param values function
 * @description function to get the values of the inputs
 * @param onChange function
 * @description function to be executed when the input changes
 * @param inputType string
 * @description type of the input (text, number, email, file)
 * @param containerStyle object
 * @description containerStyle={{}}
 * 
 * 
 * @returns 
 */
export default function CustomInput({iconColor, iconStyle, values, onChange, inputType, containerStyle}) {
  const [inputParams, setInputParams] = useState([{comment: '', date: ''}])
  const [params, setParams] = useState({comment: '', date: ''})

  const handleParams = () => {
    setInputParams([...inputParams, params])
    setParams({comment: '', date: ''})
  }
  const deleteInput = (i) => {
    setInputParams(inputParams.filter((item, index) => index !== i))
  }

  const getValues = (value, index) => {
     
    const note = inputParams[index]
    const newArray = inputParams
    newArray.splice(index, 1, {...note, ...value,})
    setInputParams(newArray)
    if(values){
      values(newArray)
    }
  }

  useEffect(() => {
    values(inputParams)
  }, [inputParams])
  
  return (
    <div className='custom-input' style={containerStyle}>
      <div className='container-batch-diary'>
        <InputList values={inputParams} onChange={onChange} deleteInput={deleteInput} getValues={getValues} inputType={inputType}/>
        
        <NoteList />

      </div>
      
        <div className='content-input'>
          <button onClick={handleParams}  className='button-n'>{<PlusCircleOutlined color='blue' style={iconStyle}/> }</button>
          <span style={{color: '#60caf7'}}>Agregar otra nota</span>
      </div>
    </div>
  )
}
