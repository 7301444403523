import React from 'react'
import { Link } from 'react-router-dom'

const PieDeInicio = (props) => {
  const toDirec = '/' + props.direc
  return (
    <div>
      <div className='form-label'>
        <hr className={props.lineClass} />
      </div>
      <div
        className={`form-label text-center questions--register--login footer-label${props.tipo}`}
      >
        {props.mensaje}
      </div>
      <div
        className='registrarseClass text-center'
        role='group'
        aria-label='Basic mixed styles example'
      >
        <Link to={toDirec} className={`links--login${props.tipo}`}>
          {props.mensajeLink}
        </Link>
      </div>
    </div>
  )
}
export default PieDeInicio
