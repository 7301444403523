import React, { useState, useRef } from 'react'

import { Modal, Button } from 'react-bootstrap'
import useModals from '../../hooks/useModals'
import Loading from '../Loading/Loading'

import './modalRegisterStyle.css'

const ModalRegister = () => {
  const [readed, setReaded] = useState(false)
  const { policiesModal, closePoliciesModal } = useModals()

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop - 1 <= e.target.clientHeight;
    if (bottom) {
      setReaded(true)
    }
  }

  const handleClose = () => {
    closePoliciesModal()
    policiesModal.onCancel()
    setReaded(false)
  }

  const handleRegister = () => {
    closePoliciesModal()
    policiesModal.onConfirm()
    setReaded(false)
  }

  return (
    <>
    
      <Modal
        show={policiesModal.isOpen}
        onHide={handleClose}
        className='ModalConGuarda'
      >
        <Modal.Header className='ModalheaderRegist'>
          <Modal.Title className='tit-politicas'>
            Políticas de privacidad
          </Modal.Title>
          <div
            aria-hidden='true'
            role='button'
            className='close btnCloseModal EquisCerraModal'
            onClick={handleClose}
          >
            ×
          </div>
        </Modal.Header>
        <Modal.Body
          className='Modalbody'
          onScroll={handleScroll}
        >
          <p>
            URBAN 360 SAS, con domicilio en Bogotá, es
            responsable de tratar de forma estrictamente confidencial sus datos
            personales, conscientes de esto hacemos un esfuerzo permanente para
            proteger esta información.
          </p>

          <p>
            Su información personal será utilizada para proveerle los servicios
            y productos que ha solicitado, informarle sobre cambios en los
            mismos y evaluar la calidad del servicio que le brindamos.
          </p>

          <p>
            Para prevenir el acceso no autorizado a sus datos personales y con
            el fin de asegurar que la información sea utilizada para los fines
            establecidos en este aviso de privacidad, hemos desarrollado
            bloqueos físicos y electrónicos, así como equipos de redes con la
            última tecnología de seguridad para asegurar la integridad de dicha
            información.
          </p>

          <p>
            Todos sus datos personales son tratados de acuerdo con la
            legislación aplicable y vigente en el país, por ello le informamos
            que usted tiene en todo momento el derecho de acceder, rectificar,
            cancelar u oponerse al tratamiento que le damos a sus datos
            personales, así como revocar el consentimiento otorgado para el
            tratamiento de estos; derecho que podrá hacer valer a través de
            URBAN 360 SAS en su teléfono celular: 321 9316457 o por medio del
            correo electrónico: contacto@urban360sas.com El ejercicio de los
            derechos de acceso, rectificación, cancelación, oposición,
            divulgación y limitación de uso; o la revocación de consentimiento,
            podrá efectuarse por vía telefónica o por correo electrónico a
            partir de enero de 2021.
          </p>
          <p>
            A través de estos canales usted podrá actualizar sus datos y
            especificar el medio por el cual desea recibir información, ya que
            en caso de no contar con esta especificación de su parte URBAN 360
            SAS establecerá el canal que considere pertinente para enviarle
            información.
          </p>
          <p>
            Este aviso de privacidad podrá ser modificado de tiempo en tiempo.
            Dichas modificaciones serán oportunamente informadas a través de
            nuestra página en internet www.URBAN360SAS.com, o cualquier otro
            medio de comunicación oral, impreso o electrónico que URBAN 360 SAS
            determine para tal efecto.
          </p>
        </Modal.Body>
        <Modal.Footer className='Modalfooter'>
          <Button
            variant={`btn btn-endNext ${
              !readed ? 'disabled' : ''
            } btnAceptar`}
            onClick={handleRegister}
            size='lg'
            disabled={!readed}
          >
            ACEPTO LAS POLÍTICAS
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalRegister
