import React,{ createContext,useCallback,useContext,useEffect,useState } from "react";
import createNewChat from '../services/Chat';
import Request from "../services/Request";
import { useHistory } from 'react-router-dom';
import { SocketContext } from "./socketIO";

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const {socket,listenChat,listenMessagesChat,leaveMessagesChat} = useContext(SocketContext);
  const endpoints = {
    chats:'/chats/'
  }
  const [chatList, setChatList] = useState([])
  const history = useHistory();
  
  //Mensajes
  const [currentLandRequest,setCurrentLandRequest] = useState(null)
  const [currentLandSelect,setCurrentLandSelect] = useState(null)
  const [brokerAssociate, setBrokerAssociate] = useState(null)
  const [developerAssociate, setDeveloperAssociate] = useState(null)
  const [currentChat, setCurrentChat] = useState(null);
  const [goChat,setGoChat] = useState(false);
  const [currentMessages,setCurrentMessages] = useState(null);
  const [sendMessages,setSendMessages] = useState(null);

  const [filterArchived,setFilterArchived] = useState(false);

  const [stopListenChat, setStopListenChat] = useState(null);

  useEffect(() => {          
    getAllChats();
  },[filterArchived]);

  useEffect(() => {
    if(developerAssociate && !currentLandRequest) createAssociateChat();
  },[developerAssociate]);

  useEffect(() => {
    //if(currentLandRequest && !developerAssociate) createChat();
    if(currentLandRequest) createChat();
  },[currentLandRequest]);

  useEffect(() => {
    if(socket){
      const callback = listenChat(getAllMessages); 
      setStopListenChat(prev => {
        if(prev) prev();
        return callback;
      });
    }    
    if(currentChat){
      getAllMessages()
      leaveMessagesChat()
      listenMessagesChat(currentChat.data._id)                             
    }
  },[currentChat]);

  useEffect(() => {
    if(currentChat){
      sendChatMessage();      
    }
  },[sendMessages]);

  const toggleArchived = ()=>{
    if(filterArchived){
      setFilterArchived(false);
    } else{
      setFilterArchived(true);
    }        
  }

   const createChat = async () => {
    if(currentLandRequest && currentLandSelect){
      const response  = await Request.post(`${endpoints.chats}`,{
        land:currentLandSelect._id,
        landRequest:currentLandRequest._id
      });
      if(response){        
        setCurrentChat(response);        
        getAllChats();               
        setGoChat(true);        
      }
    }     
   }

   const createAssociateChat = useCallback(async () => {
    if(brokerAssociate && developerAssociate){
      const response = await Request.post(`${endpoints.chats}`,{
        developerAssociateType:developerAssociate.associateType,
        brokerAssociateType:brokerAssociate.associateType
      });
      if(response){        
        response.data.broker = brokerAssociate;
        response.data.developer = developerAssociate;
        setCurrentChat(response);        
        getAllChats();               
        setGoChat(true);        
      }
    }     
   }, [brokerAssociate, developerAssociate, setCurrentChat, setGoChat]);

   const getAllChats = async () => {        
    const params = new URLSearchParams([['archivedParam', filterArchived?'ARCHIVED':'NOT_ARCHIVED']])
    const response  = await Request.get(`${endpoints.chats}`,params);
    setChatList(response.data.content);    
   }

   const getAllMessages = async () => {    
    const response = await Request.get(`/chats/${currentChat.data._id}/get-message/`)    
    setCurrentMessages(response.data);
   }

   const sendChatMessage = async () =>{
    const sendMess = await Request.post(`/chats/${currentChat.data._id}/send-message`,sendMessages);
    if(sendMess){
      getAllMessages()
    }
   }

   const archiveChat = async () => {
    const response = await Request.put(`/chats/${currentChat.data._id}/change-state-archived/ARCHIVED`)
    if(response){      
      console.log(response)
    }     
   }

  return(
    <ChatContext.Provider value={{
      sendMessages,
      setSendMessages,
      currentMessages,
      currentChat,
      setCurrentChat,
      currentLandSelect,
      setCurrentLandSelect,
      currentLandRequest,
      setCurrentLandRequest,
      setBrokerAssociate,
      setDeveloperAssociate,
      createAssociateChat,
      archiveChat,
      getAllChats,
      toggleArchived,
      filterArchived,      
      setFilterArchived,
      setCurrentMessages,
      goChat,setGoChat,chatList}}>
      {children}
    </ChatContext.Provider>
  )
  
}