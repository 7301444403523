import React, {useEffect, useState,useContext} from "react"
import { RetweetOutlined,DeleteOutlined,WarningOutlined } from '@ant-design/icons';
import departamentos from '../../../../../helpers/states'
import './Map.css'
import L from "leaflet";

import puntero from './icon/icon.png';
import { Button, Tooltip } from 'antd';
import { Layout } from 'antd';
import {Context} from '../../../../../context/AreaContext'
let greenIcon = L.icon({
  iconUrl: puntero,
  iconSize:     [20, 20], // size of the icon
   // point from which the popup should open relative to the iconAnchor
});

let Markers = [];
let poligonForm = [];
let isRemove = false;
const { Footer } = Layout;

export default function Maps ({isEdit, centerMap}){  
  const {
    parameters,
    departmentCenter,
    setCoords,
    setIsValidForm,
    validateParameters,
    isCloseModal
  } = useContext(Context)
  const [center,setCenter] = useState({lat:3.7765593098768635,lng:-72.64823459932259});
  const [polygonCoord,setPolygonCoord] = useState([]); 
  const [markerCoord ,setMarkerCoord] = useState([]);
  const [isValid,setIsValid] = useState(false);
  const [Map, setMap] = useState()
  const ZOOM_LEVEL = 5;    

  useEffect(()=>{    
    let map = L.map('map',{
      center:center,
      zoom:ZOOM_LEVEL,
      drawControl: true
    })
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '© OpenStreetMap'
    }).addTo(map);        
    addClickEvent(map);                 
    setMap(map);
    if(isEdit && parameters.point){
      parameters.point.map( (p) =>{
        const point = [p[0],p[1]];
        const arrPolygon = polygonCoord;
        arrPolygon.push(point);
        setPolygonCoord(arrPolygon);         
        if(polygonCoord.length >= 3){
          addPolygon(map)
          setIsValidForm(true)
        }
        addMarket(map,point); 
        onChangeParam();
      })
    }
  },[]);

  useEffect(() => {
    if(!isEdit){      
      departamentos.map( (dep)=>{
        if(dep.departamento === departmentCenter){
          let point = [
            dep.lat,
            dep.lng
          ]
          centrateMap(point)
        }
      })      
    }
  },[departmentCenter])

  useEffect(() => {
    if(centerMap && (centerMap.lat && centerMap.lng)){
      Map.flyTo([centerMap.lat, centerMap.lng], 8)
    }
  }, [centerMap])

  const addClickEvent = (map) =>{
    
    map.on('click', (e)=>{
      let point = [
        e['latlng'].lat,
        e['latlng'].lng
      ]      
      const arrPolygon = polygonCoord;
      arrPolygon.push(point);
      setPolygonCoord(arrPolygon);         
      if(polygonCoord.length >= 3){
        addPolygon(map)
      }
      addMarket(map,point); 
      onChangeParam();
    });
    setMap(map);
  }

  const centrateMap = (point) =>{
    if(Map){      
      Map.setView([point[0],point[1]],ZOOM_LEVEL+1)
    }
  }
  const addMarket = (map,point) =>{    
    const Marker = L.marker(point, {icon: greenIcon});
    Markers.push(Marker); 
    const auxMarkets = markerCoord;
    auxMarkets.push(point);
    setMarkerCoord(auxMarkets);
    Marker.addTo(map);
  }
  const addPolygon = (map) =>{             
    if(poligonForm && (polygonCoord.length > 3 || isRemove)){
      poligonForm.remove();
      poligonForm = null;          
    }
    let polygons = L.polygon(polygonCoord, { color: 'blue',interactive:true,}).addTo(map);
    poligonForm = polygons;  
    if(isEdit){
      map.fitBounds(polygons.getBounds());
    }    
    if(polygonCoord.length === 3){
      isRemove = true;
      validateParameters();
    }
  }
  const onChangeParam = ()=>{       
    setCoords({point:polygonCoord});
    /*if(polygonCoord.length < 3){
      setIsValid(false);
    }else{
      setIsValid(true);
    }*/
    validateParameters();
  }

  const deletePolygon = ()=>{
    deleteMarker();
    deleteShape();
    onChangeParam();
    validateParameters();
  }
  
  const deleteMarker = () =>{
    if(Map && Markers){
      Markers.map(marker=>{Map.removeLayer(marker)})      
      Markers = []; 
      setMarkerCoord([]);    
    }       
  } 

  const deleteShape = () =>{       
    if(poligonForm && polygonCoord.length > 0){  
      if(isRemove){
        poligonForm.remove();
        poligonForm = null;      
      }         
      for (let i = polygonCoord.length; i > 0; i--) {
        setPolygonCoord(polygonCoord.pop());
      }            
    }    
  }
  const deleteOneMarker = () =>{
    if(Map && Markers && markerCoord.length > 1){
      try{
        Map.removeLayer(Markers.pop()); 
        if(markerCoord.length > 0 ){
          const auxMark =  markerCoord;
          auxMark.pop();        
          setMarkerCoord(auxMark);    
        }  
      }catch(err){
        console.log('fallo')
      }       
    }else{
      deleteMarker();
    }
  }

  const deleteOneShape = () =>{  
    if(polygonCoord && polygonCoord.length > 0){      
      if(isRemove && poligonForm){
        poligonForm.remove();
        poligonForm = null;    
      }
      const auxShape = polygonCoord;
      auxShape.pop()
      setPolygonCoord(auxShape);
      
      let polygons = L.polygon(polygonCoord, { color: 'blue',interactive:true,}).addTo(Map) ;  
      poligonForm = polygons;
     
    }else{
      deleteShape()
    }    
  }
  const undo = ()=>{
    deleteOneMarker();
    deleteOneShape();
    onChangeParam(); 
    validateParameters();   
  }

  useEffect(() => { 
    if(isCloseModal){      
      deletePolygon();
    }  
  },[ 
    isCloseModal
  ])


  return (
      <>
        <div id='map'>          
        </div>  
        <Layout>
          <Footer className="toolbar">
            <div className="toolbar-left">
              {
                !isValid &&
                <span>                                
                  Debe crear al menos un poligono de 3 vertices.                
                </span>
              }
            </div>
            <div className="toolbar-rigth">
              <Tooltip title="Desechar todo">
                <Button type="danger" shape="circle" icon={<DeleteOutlined />} onClick={deletePolygon} />
              </Tooltip>
              <Tooltip title="Deshacer">
                <Button type="primary" shape="circle" icon={<RetweetOutlined  onClick={undo}/>} />
              </Tooltip>
            </div>
          </Footer>          
        </Layout>     
      </>
  )
}

