import React, {useState, useEffect} from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import { ButtonApp } from '../../button/button';
import { message, Modal, Transfer } from 'antd';
import Empty from '../../Empty/Empty';
import { getAllRoles } from '../../../services/getAllRoles';
import assignRolesToUser from '../../../services/assignRolesToUser';
import './index.css';
import HttpResponseEmun from '../../../helpers/enums/HttpResponseEmun';
import { GetUserAdmin as getUserAdmin } from '../../../services/userAdmin';


const mutedText = (text) => (<p className="text-muted m-0">{text}</p>);

const LocaleTransferEs = {itemUnit: "Rol", itemsUnit: "Roles", notFoundContent: <Empty />,
remove: "Eliminar", removeAll: "Eliminar Todos", selectAll: "Seleccionar Todo",
removeCurrent: "Eliminar", searchPlaceholder: "Buscar", selectInvert: "Invertir",
titles: [mutedText("Sin aplicar"), mutedText("Aplicados")], selectCurrent:"Seleccionar"};


export default function RolPermissionsModal({closeUserRolModal, showUserRolModal}) {

    const [isSending, setIsSending] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [rolesAppliedKeys, setRolesAppliedKeys] = useState([]);
    const [previousRolesAppliedKeys, setPreviousRolesAppliedKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);

    const user = showUserRolModal.user;

    const userName = `${user && user.names} ${user && user.lastNames}`;

    const getRoles = async () => {

        setIsLoading(true);


        const rolesData = await getAllRoles();
        if(rolesData.status === HttpResponseEmun.OK){
            setRoles(rolesData.data.content.map(item => ({...item, key: item._id})));
        } else {
            message.error({content: `¡Hubo un error al cargar los roles!`, duration: 4});
        }

        const userData = await getUserAdmin(user._id);
        if(userData.roles){
            setRolesAppliedKeys(userData.roles);
            setPreviousRolesAppliedKeys(userData.roles);
        } else {
            message.error({content: `¡Hubo un error al cargar los roles del usuario "${userName}"`, duration: 4});
        }



        setIsLoading(false);

    }
    
    useEffect(() => {
        if(showUserRolModal.visible) getRoles();
    }, [showUserRolModal.visible]);

    useEffect(() => {
        if(showUserRolModal.visible === false){
            setRolesAppliedKeys([]);
            setSelectedKeys([]);
        }
    }, [showUserRolModal.visible]);
    
    const filterOption = (inputValue, option) => {

        inputValue = inputValue.toLowerCase();

        if(option.code.toLowerCase().indexOf(inputValue) > -1) return true;
        if(option.name.toLowerCase().indexOf(inputValue) > -1) return true;
    }

    const handleChange = (newTargetKeys) => {
        setRolesAppliedKeys(newTargetKeys);
    };

    const handleSearch = async (dir, value) => {
        //console.log('search:', dir, value);
    };

    const updateUserRolHandler = async () => {

        const rolesApplied = rolesAppliedKeys.map(key => {
            return roles.find(rol => rol.key === key);
        });

        setIsSending(true);

        try {
            const {status} = await assignRolesToUser(user._id, rolesApplied.map(rol => rol._id));
            if(status === HttpResponseEmun.OK) {
                message.success({content: `¡Se han aplicado los roles al usuario "${userName}" correctamente!`, duration: 3});
            } else {
                message.error({content: `¡Error al aplicar los roles al usuario "${userName}"`, duration: 4});
            }
        } catch (error) {
            message.error({content: `¡Error al aplicar los roles al usuario "${userName}"`, duration: 4});
            console.error(error);
        } finally {
            setIsSending(false);
            closeUserRolModal();
        }

    }

    const selectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    }

    return (
    <Modal
        className="rolPermissionsModal"
        title={`Roles de "${user && userName}"`} 
        visible={showUserRolModal.visible} 
        onCancel={closeUserRolModal}
        footer={
        <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <span style={{margin: '0 3px'}}>
            <ButtonApp content="Cerrar" primary cancel onClick={closeUserRolModal}/>
            </span>
            <span style={{margin: '0 3px'}}>
            <ButtonApp disabled={isSending | previousRolesAppliedKeys.join("") === rolesAppliedKeys.join("")} 
            content="Actualizar" primary onClick={updateUserRolHandler} />
            </span>
        </div>
        }
        >

        {isLoading && (<div style={{height: "20rem"}} className="text-center pt-5">
            <LoadingOutlined style={{fontSize: "20px", color: "#0d6efd"}} />
            <small style={{display:"block"}} className="text-muted mt-2">Cargando roles</small>
        </div>)}

        {!isLoading && <Transfer className="transferRol"
        locale={LocaleTransferEs}
        dataSource={roles}
        showSearch
        filterOption={filterOption}
        targetKeys={rolesAppliedKeys}
        onChange={handleChange}
        onSearch={handleSearch}
        onSelectChange={selectChange}
        selectedKeys={selectedKeys}
        render={rol => `${rol.code} ${rol.name}`}
        />}

    </Modal>
    )
}
