import React, { useState } from 'react'

import constructora from '../../assets/icons/domain1.png'
import money from '../../assets/icons/money.png'
import retail from '../../assets/icons/retail.png'
import otro from '../../assets/icons/otro.png'

import constructoraBlue from '../../assets/icons/domain.png'
import moneyBlue from '../../assets/icons/money_blue.png'
import retailBlue from '../../assets/icons/retailblue.png'
import otroBlue from '../../assets/icons/otroblue.png'
import { useRegister } from '../../context/RegisterContext'

import './InfoPersonalDesarrollador.css'

const InfoPersonalDesarrollador = () => {
  const { setStep, userToRegister, setUserToRegister, clearUserRegisterData } = useRegister()
  const [toggleState, setToggleState] = useState(
    userToRegister.type || 'Constructora/ Promotora'
  )

  const [OtroState, setOtroState] = useState({
    camp: !!userToRegister.otherType,
    err: false,
    valorInput: userToRegister.otherType,
  })

  const NextForm = () => {
    const saveformTypeDesarrollador = {
      type: toggleState, // Generamos una id rápida
      otherType: OtroState.valorInput,
    }
    setUserToRegister({ ...userToRegister, ...saveformTypeDesarrollador })
    setStep(2)
  }

  const toggleTab = (index) => {
    setToggleState(index)
  }

  const handleChange = (event) => {
    if (event.target.value === '') {
      setOtroState({ camp: false, err: true, valorInput: '' })
    } else {
      setOtroState({ camp: true, err: false, valorInput: event.target.value })
    }
  }

  return (
    <div className='type-developer'>
      <div className='container'>
        <h1 className='select--corredor'>
          Selecciona qué tipo de desarrollador eres
        </h1>
        <div className='bloc-tabs'>
          <button
            className={
              toggleState === 'Constructora/ Promotora'
                ? 'tabs active-tabs'
                : 'tabs'
            }
            onClick={() => toggleTab('Constructora/ Promotora')}
          >
            <div>
              <span
                className={
                  toggleState === 'Constructora/ Promotora'
                    ? 'optionSelec'
                    : null
                }
              >
                {' '}
                <img
                  className='img-fluid'
                  src={
                    toggleState === 'Constructora/ Promotora'
                      ? constructoraBlue
                      : constructora
                  }
                  alt=''
                />{' '}
                <br />
                Constructora/ Promotora
              </span>
            </div>
          </button>

          <button
            className={
              toggleState === 'Fondo de Inversión' ? 'tabs active-tabs' : 'tabs'
            }
            onClick={() => toggleTab('Fondo de Inversión')}
          >
            <div>
              {' '}
              <img
                className='img-fluid'
                src={toggleState === 'Fondo de Inversión' ? moneyBlue : money}
                alt=''
              />
              <br />
              <span
                className={
                  toggleState === 'Fondo de Inversión' ? 'optionSelec' : null
                }
              >
                Fondo de Inversión
              </span>
            </div>
          </button>

          <button
            className={toggleState === 'Retail' ? 'tabs active-tabs' : 'tabs'}
            onClick={() => toggleTab('Retail')}
          >
            <div>
              <img
                className='img-fluid'
                src={toggleState === 'Retail' ? retailBlue : retail}
                alt=''
              />
              <br />
              <span className={toggleState === 'Retail' ? 'optionSelec' : null}>
                Retail
              </span>{' '}
            </div>
          </button>

          <button
            className={toggleState === 'Otro' ? 'tabs active-tabs' : 'tabs'}
            onClick={() => toggleTab('Otro')}
          >
            <div>
              <img
                className='img-fluid'
                src={toggleState === 'Otro' ? otroBlue : otro}
                alt=''
              />
              <br />
              <span className={toggleState === 'Otro' ? 'optionSelec' : null}>
                Otro
              </span>{' '}
            </div>
          </button>
        </div>

        <div className='content-tabs'>
          <div
            className={
              toggleState === 'Constructora/ Promotora'
                ? 'content active-content'
                : 'content'
            }
          >
            <h2 className='titActive'>Constructora/Promotora</h2>
            <p>
              Eres una Empresa que busca lotes que se ajusten a tus lineamientos
              para la construcción de proyectos Inmobiliarios, Logísticos,
              Industriales.
            </p>
          </div>

          <div
            className={
              toggleState === 'Fondo de Inversión'
                ? 'content active-content'
                : 'content'
            }
          >
            <h2 className='titActive'>Fondo de Inversión</h2>
            <p>
              Buscas lotes para invertir, que se ajusten a los intereses de tus
              inversionistas y que puedas desarrollar en conjunto con empresas
              dedicadas a la construcción.
            </p>
          </div>

          <div
            className={
              toggleState === 'Retail' ? 'content  active-content' : 'content'
            }
          >
            <h2 className='titActive'>Retail</h2>
            <p>
              Buscas lotes para desarrollar supermercados, tiendas
              departamentales, locales para el mejoramiento del hogar,
              farmacias, tiendas fast fashion, librerías, entre otros.
            </p>
          </div>

          <div
            className={
              toggleState === 'Otro'
                ? 'content other active-content'
                : 'content other'
            }
          >
            <h2 className='titActive'>Otro</h2>
            <p>*Especifica que tipo de desarrollador eres</p>
            <div className='form-group row'>
              <div className='col-sm-12'>
                <input
                  type='text'
                  className={
                    OtroState.err === false
                      ? 'form-control input-otro'
                      : 'form-control input-OtroError'
                  }
                  id='otro'
                  placeholder='Desarrollador'
                  onChange={handleChange}
                  value={OtroState.valorInput}
                />
              </div>
            </div>
            {OtroState.err === true ? (
              <div className='form-label Mensaje-ErrorInput'>
                Este campo es obligatorio
              </div>
            ) : null}
          </div>
        </div>

        <div className='container sectionBtn'>
          <div className='row'>
            <div className='col'>
              <button
                className='btn btn-volver'
                onClick={clearUserRegisterData}
              >
                Cancelar registro
              </button>
            </div>
            <div className='col'>
              <div className='btn-holder'>
                <button
                  className={
                    toggleState === 'Otro'
                      ? OtroState.camp === false
                        ? 'btn btn-endNextOtro'
                        : 'btn btn-endNext'
                      : 'btn btn-endNext'
                  }
                  onClick={NextForm}
                >
                  Siguiente
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoPersonalDesarrollador
