import React, { useState, useEffect } from 'react'
import { Select } from 'antd'
import attachmentFile from '../../assets/icons/attachmentFile.png'
import uploadFileIcon from '../../assets/icons/uploadFileIcon.png'
import loadingFileIcon from '../../assets/icons/loadingFileIcon.png'
import loadedFileIcon from '../../assets/icons/loadedFileIcon.png'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useAuth } from '../../context/AuthContext'
import useModals from '../../hooks/useModals'
import {
  updateDeveloperBusinessInfo,
  getRUT,
  getDERL,
} from '../../services/developers'
import useToasts from '../../hooks/useToasts'

import departamentos from '../../helpers/states'

const FormEditDeEmpresarial = (props) => {
  const { user, userData } = useAuth()
  const { openConfirmationMessage } = useModals()
  const { addToast } = useToasts()
  const [rutFile, setRutFile] = useState('')
  const [loadingRut, setLoadingRut] = useState(false)
  const [certificateFile, setCertificateFile] = useState('')
  const [loadingCertificate, setLoadingCertificate] = useState(false)
  const [rutUrl, setRutUrl] = useState('')
  const [certificateUrl, setCertificateUrl] = useState('')
  const [interestCities, setInterestCities] = useState([]);

  const [EstadoBotones, setEstadoBotones] = useState({
    active1: userData && userData.interestUse.vis,
    active2: userData && userData.interestUse.noVis,
    active3: userData && userData.interestUse.industrial,
    active4: userData && userData.interestUse.dotacional,
    active5: userData && userData.interestUse.commercial,
    active6: userData && !!userData.interestUse.others,
  })

  const handleClick = (event) => {
    if (event.target.id === '1') {
      setEstadoBotones({
        active1: !EstadoBotones.active1,
        active2: EstadoBotones.active2,
        active3: EstadoBotones.active3,
        active4: EstadoBotones.active4,
        active5: EstadoBotones.active5,
        active6: EstadoBotones.active6,
      })
    }

    if (event.target.id === '2') {
      setEstadoBotones({
        active1: EstadoBotones.active1,
        active2: !EstadoBotones.active2,
        active3: EstadoBotones.active3,
        active4: EstadoBotones.active4,
        active5: EstadoBotones.active5,
        active6: EstadoBotones.active6,
      })
    }
    if (event.target.id === '3') {
      setEstadoBotones({
        active1: EstadoBotones.active1,
        active2: EstadoBotones.active2,
        active3: !EstadoBotones.active3,
        active4: EstadoBotones.active4,
        active5: EstadoBotones.active5,
        active6: EstadoBotones.active6,
      })
    }
    if (event.target.id === '4') {
      setEstadoBotones({
        active1: EstadoBotones.active1,
        active2: EstadoBotones.active2,
        active3: EstadoBotones.active3,
        active4: !EstadoBotones.active4,
        active5: EstadoBotones.active5,
        active6: EstadoBotones.active6,
      })
    }
    if (event.target.id === '5') {
      setEstadoBotones({
        active1: EstadoBotones.active1,
        active2: EstadoBotones.active2,
        active3: EstadoBotones.active3,
        active4: EstadoBotones.active4,
        active5: !EstadoBotones.active5,
        active6: EstadoBotones.active6,
      })
    }
    if (event.target.id === '6') {
      setEstadoBotones({
        active1: EstadoBotones.active1,
        active2: EstadoBotones.active2,
        active3: EstadoBotones.active3,
        active4: EstadoBotones.active4,
        active5: EstadoBotones.active5,
        active6: !EstadoBotones.active6,
      })
    }
  }

  useEffect(() => {
    if (userData && userData.department !== '') {
      const cit = departamentos.find(
        (depar) => depar.departamento === userData.department
      ).ciudades
      setCiudes({
        ciudades: cit,
      })

      getRUT(userData._id, userData.rutDoc).then((res) => {
        if (res) {
          setRutUrl(res)
        }
      })

      getDERL(userData._id, userData.representacionLegalDoc).then((res) => {
        if (res) {
          setCertificateUrl(res)
        }
      })

      let cities = [];
      departamentos.forEach(departament => {
        cities.push(...departament.ciudades);
      });
      setInterestCities([...new Set( cities )]);
    }
  }, [])

  useEffect(() => {
    if (userData) {
      setRutFile(userData.rutDoc)
      setCertificateFile(userData.representacionLegalDoc)
    }
  }, [userData])

  const onSubmit = (formData) => {
    const data = {
      interestUse: {
        vis: EstadoBotones.active1,
        noVis: EstadoBotones.active2,
        industrial: EstadoBotones.active3,
        dotacional: EstadoBotones.active4,
        commercial: EstadoBotones.active5,
      },
      webSite: formData.webSite,
      address: formData.address,
      department: formData.department,
      city: formData.ciudad,
      interestCities: formData.interestCities
    }

    if (formData.otherInterest) {
      data.interestUse.others = formData.otherInterest
    }

    openConfirmationMessage({
      message: '¿Quieres guardar los cambios?',
      onConfirm: () => {
        updateDeveloperBusinessInfo(userData._id, data)
          .then((response) => {
            addToast({
              type: 'success',
              title: '¡Éxito!',
              message: 'Se han guardado los cambios',
            })
          })
          .catch((e) => {
            addToast({
              type: 'error',
              title: 'Error',
              message: 'No se pudieron guardar los cambios',
            })
          })
      },
    })
  }

  const onChangeRutFile = (e) => {}

  const onChangeCertificateFile = (e) => {}

  const validationSchema = Yup.object().shape({
    webSite: Yup.string().required('Este campo es obligatorio'),
    address: Yup.string().required('Este campo es obligatorio'),
    department: Yup.string().required('Este campo es obligatorio'),
    ciudad: Yup.string().required('Este campo es obligatorio'),
    interestCities: Yup.array()
  })

  const formik = useFormik({
    initialValues: {
      webSite: (userData && userData.webSite) || '',
      address: (userData && userData.address) || '',
      department: (userData && userData.department) || '',
      ciudad: (userData && userData.city) || '',
      interestCities: (userData && userData.interestCities) || [],
      otherInterest: (userData && userData.interestUse.others) || '',
    },
    enableReinitialize: true,

    onSubmit,

    validationSchema,
  })

  const [ciudad, setCiudes] = useState({
    ciudades: [],
  })

  const cambiarDepar = (e) => {
    if (e.target.value) {
      const cit = departamentos.find(
        (depar) => depar.departamento === e.target.value
      ).ciudades
      setCiudes({
        ciudades: cit,
      })
    } else {
      setCiudes({
        ciudades: [],
      })
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='row'>
        <div className='col-sm-6'>
          <div className='form-row'>
            <div className='form-group col-md-10'>
              <label className='tit-input titEditCorredor'>Razón social</label>
              <h1 className='tit-correo'>
                {userData && userData.businessName}
              </h1>
            </div>
          </div>
        </div>
        <div className='col-sm-6'>
          <div className='form-row'>
            <div className='form-group col-md-10'>
              <label className='tit-input titEditCorredor'>NIT</label>
              <h1 className='tit-correo'>{userData && userData.nit}</h1>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-sm-6'>
          <div className='form-row'>
            <div className='form-group col-md-10'>
              <label className='tit-input titEditCorredor'>
                Sitio Web<span className='text-danger'> *</span>
              </label>
              <input
                type='text'
                name='webSite'
                className={
                  formik.errors.webSite
                    ? 'form-control form-info-corredorError'
                    : 'form-control form-info-corredor'
                }
                placeholder='DoDesarrolladora.com'
                onChange={formik.handleChange}
                value={formik.values.webSite}
              />
            </div>
            {formik.errors.webSite ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.webSite}
              </div>
            ) : null}
          </div>
        </div>
        <div className='col-sm-6'>
          <div className='form-row'>
            <div className='form-group col-md-10'>
              <label className='tit-input titEditCorredor'>
                Departamento<span className='text-danger'> *</span>
              </label>
              <select
                name='department'
                className={
                  formik.errors.department
                    ? 'form-select form-info-corredorError'
                    : 'form-select form-info-corredor'
                }
                onChange={(e) => {
                  formik.handleChange(e)
                  cambiarDepar(e)
                }}
                value={formik.values.department}
              >
                <option value=''>Seleccione</option>
                {departamentos.map((ar, k) => (
                  <option key={k} value={ar.departamento} id={ar.id}>
                    {ar.departamento}
                  </option>
                ))}
              </select>
            </div>
            {formik.errors.department ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.department}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-sm-6'>
          <div className='form-row'>
            <div className='form-group col-md-10'>
              <label className='tit-input'>
                Dirección<span className='text-danger'> *</span>
              </label>
              <input
                type='text'
                name='address'
                className={
                  formik.errors.address
                    ? 'form-control form-info-corredorError'
                    : 'form-control form-info-corredor'
                }
                placeholder='Av Kr 15 #13-23'
                onChange={formik.handleChange}
                value={formik.values.address}
              />
            </div>
          </div>
          {formik.errors.address ? (
            <div className='form-label Mensaje-ErrorCorredor'>
              {formik.errors.address}
            </div>
          ) : null}
        </div>
        <div className='col-sm-6'>
          <div className='form-row'>
            <div className='form-group col-md-10'>
              <label className='tit-input'>
                Ciudad<span className='text-danger'> *</span>
              </label>
              <select
                name='ciudad'
                className={
                  formik.errors.ciudad
                    ? 'form-select form-info-corredorError'
                    : 'form-select form-info-corredor'
                }
                onChange={formik.handleChange}
                value={formik.values.ciudad}
                disabled={formik.values.department ? false : true}
              >
                <option value=''>Seleccione</option>
                {ciudad.ciudades.map((city, k) => (
                  <option key={k} value={city.name}>
                    {' '}
                    {city.name}
                  </option>
                ))}
              </select>
              {formik.errors.ciudad ? (
                <div className='form-label Mensaje-ErrorCorredor'>
                  {formik.errors.ciudad}
                </div>
              ) : null}
            </div>
          </div>
          {formik.errors.ciudad ? (
            <div className='form-label Mensaje-ErrorCorredor'>
              {formik.errors.ciudad}
            </div>
          ) : null}
        </div>
      </div>

      <div className='row'>
        <div className='col-sm-6'>
          <div className='form-row'>
            <div className='form-group col-md-10'>
              <label className='tit-input'>Ciudades de interés</label>
            </div>
          </div>
        </div>
        <div className='col-sm-6'></div>
      </div>

      <div className='row'>
        <div className='col-sm-11'>
          <div className='form-row'>
            <div className='form-group col-md-12'>
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder="Ciudades de interés"
              options={interestCities && interestCities.map((city) => (
                {label: city.name, value: city.name}
              ))}
              onChange={(value) => {
                if(value.length < 7){
                  formik.setFieldValue("interestCities", value);
                }
              }}
              value={formik.values.interestCities}
              size='large'
              name="interestCities"
              className='form-control form-info-corredor'
            />
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <div className='form-row'>
            <div className='form-group col-md-10'>
              <label className='tit-input'>
                Usos de interés<span className='text-danger'> *</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <div className='form-row'>
            <div className='form-group col-md-12'>
              <div className='form-group col-md-12'>
                <div className='form-group col-lg-8 col-xl-7 mx-auto usoInteresGrid'>
                  <div
                    className={
                      EstadoBotones.active1
                        ? 'btn btnSelecUsoInteresSelec'
                        : 'btn btnSelecUsoInteres'
                    }
                    onClick={handleClick}
                    id='1'
                  >
                    VIS
                  </div>
                  <div
                    className={
                      EstadoBotones.active2
                        ? 'btn btnSelecUsoInteresSelec'
                        : 'btn btnSelecUsoInteres'
                    }
                    onClick={handleClick}
                    id='2'
                  >
                    No Vis
                  </div>
                  <div
                    className={
                      EstadoBotones.active3
                        ? 'btn btnSelecUsoInteresSelec'
                        : 'btn btnSelecUsoInteres'
                    }
                    onClick={handleClick}
                    id='3'
                  >
                    Industrial
                  </div>
                  <div
                    className={
                      EstadoBotones.active4
                        ? 'btn btnSelecUsoInteresSelec'
                        : 'btn btnSelecUsoInteres'
                    }
                    onClick={handleClick}
                    id='4'
                  >
                    Dotacional
                  </div>
                  <div
                    className={
                      EstadoBotones.active5
                        ? 'btn btnSelecUsoInteresSelec'
                        : 'btn btnSelecUsoInteres'
                    }
                    onClick={handleClick}
                    id='5'
                  >
                    Comercial
                  </div>
                  {!EstadoBotones.active6 ? (
                    <div
                      className='btn btnSelecUsoInteres'
                      onClick={handleClick}
                      id='6'
                    >
                      Otro
                    </div>
                  ) : (
                    <div className='other-interest-container'>
                      <div className='btn btnSelecUsoInteresSelec other-interest'>
                        <input
                          type='text'
                          name='otherInterest'
                          className='form-input-other-interest'
                          placeholder='Otro uso'
                          autoFocus
                          onChange={formik.handleChange}
                          value={formik.values.otherInterest}
                        />
                        <span
                          className='close-other-interest'
                          onClick={handleClick}
                          id='6'
                        >
                          x
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <hr className='LineaFormCo'></hr>
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <div className='form-row col-md-10 h-100 d-flex flex-column'>
            <label className='tit-input rut-label d-flex align-items-center'>RUT</label>
            <div className='form-group h-100'>
              <a
                className={`tit-Archivobtn h-100 ${
                  loadingRut ? 'loading' : rutFile ? 'loaded' : 'upload'
                }`}
                href={rutUrl}
                target='_blank'
              >
                <img
                  src={
                    loadingRut
                      ? loadingFileIcon
                      : rutFile
                      ? attachmentFile
                      : uploadFileIcon
                  }
                  className='imgDentroBtn'
                />
                {loadingRut
                  ? 'Cargando Archivo'
                  : rutFile
                  ? userData && "RUT" /*userData.rutDoc*/
                  : 'Subir Archivo'}
              </a>
            </div>
          </div>
        </div>
        <div className='col'>
          <div className='form-row'>
            <div className='form-group col-md-10'>
              <label className='tit-input'>
                Certificado de Existencia y Representación Legal
              </label>
              <div className='form-group'>
                <a
                  className={`tit-Archivobtn ${
                    loadingCertificate
                      ? 'loading'
                      : certificateFile
                      ? 'loaded'
                      : 'upload'
                  }`}
                  href={certificateUrl}
                  target='_blank'
                >
                  <img
                    src={
                      loadingCertificate
                        ? loadingFileIcon
                        : certificateFile
                        ? attachmentFile
                        : uploadFileIcon
                    }
                    className='imgDentroBtn'
                  />
                  {loadingCertificate
                    ? 'Cargando Archivo'
                    : certificateFile
                    ? userData && "Certificado de Existencia y Representación Legal" /*userData.representacionLegalDoc*/
                    : 'Subir Archivo'}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <div className='form-row'>
            <div className='form-group text-center'>
              <label className='tit-input titEditSolicitaCambio'>
                Solicitar Cambio y Re-verificación de documentos
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <div className='col btn-holderSiguienteCenter'>
            <button type='submit' className='btn btn-endNext'>
              GUARDAR CAMBIOS
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default FormEditDeEmpresarial
