import React from 'react'
import { useState, useEffect, useContext } from 'react';
import CropEasy from './crop/CropEasy';
import { ScissorOutlined, UserOutlined, CloseOutlined } from '@ant-design/icons';
import { Modal, Button, Avatar, message, Tooltip, Spin } from 'antd';
import { ButtonApp } from '../button/button';
import { Context } from '../../context/lotsContext';
import UserModelsEnum from '../../helpers/enums/UserModelsEnum';
import {useAuth} from '../../context/AuthContext'
import Request from '../../services/Request'
import Spinner from '../../assets/spinners/spinner.svg'
import HttpResponseEmun from '../../helpers/enums/HttpResponseEmun';

const Profile = ({currentPhotoURL, showCrop, setShowCrop, userId, setLogo}) => {
  
  const [file, setFile] = useState(null);
  const [photoURL, setPhotoURL] = useState('');
  const [openCrop, setOpenCrop] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const {user, userData} = useAuth()
   const handleChange = (e) => {
    const getFile = e.target.files[0];
    if (getFile) {
      setFile(getFile);
      setPhotoURL(URL.createObjectURL(getFile));
      setOpenCrop(true);
    }
  };
  const loading = (e) =>{
    setShowSpinner(e)
  }
  const handleSubmit = async (e) => {
    loading(true)
    try {
      const formData = new FormData()
      formData.append('file', file)
      if (user.model === UserModelsEnum.Developer){
          const response = await Request.post(`/developers/upload-LOGO/${userData._id}`, formData)
          if(response.status === HttpResponseEmun.OK){
            setLogo(URL.createObjectURL(file))
            message.success('Enviado con exito')
            loading(false)
          }
      }else {
        const response = await Request.post(`/users/me/photo`, formData)
        if(response.status === HttpResponseEmun.OK){
          setLogo(URL.createObjectURL(file))
          message.success('Enviado con exito')
          loading(false)
        }

      }

    } catch (error) {
      message.error('Ocurrio un error')
      console.error(error);
      
    }
    loading(false)
    handleClose()

  };
  const handleClose = () =>{
    setShowCrop(false)
    setPhotoURL('')
    setFile(null)
  }

  return (
    <div >
      <div 
      style={{
        width: '100%', 
        zIndex: 1000,
        position: 'fixed',
        display: 'inline-block',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1001,
        background: 'rgba(0, 0, 0, 0.542)',
        }}
      hidden={!showSpinner}>
          <img src={Spinner} />
      </div>
      <Modal
      width={300}
      visible={showCrop}
      onCancel={handleClose}
      closeIcon={
        <Tooltip title="Cerrar">
          <CloseOutlined style={{ fontZise: '30px', color: '#39C0ED' }}/>
        </Tooltip>
      }
      footer={
        <div style={{width:'100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}} >
          <span style={{marginRight: '5px'}}><ButtonApp onClick={handleClose} content='Cancelar' primary cancel/></span>
          <span style={{marginLeft: '5px'}}><ButtonApp onClick={handleSubmit} content='Aceptar' primary/></span>
        </div>
      }
      >
      
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <span style={{marginBottom: '10px'}}>
        Ingresa una foto para tu perfil
        </span>
        <Tooltip title='Seleccionar imagen'>
          <label htmlFor="profilePhoto">
            <input
              accept="image/*"
              id="profilePhoto"
              type="file"
              style={{ display: 'none' }}
              onChange={handleChange}
            />
            <Avatar
              icon={<UserOutlined style={{fontSize: '70px'}}/>}
              src={photoURL ? photoURL : currentPhotoURL}
              style={{ width: 75, height: 75, cursor: 'pointer', margin: '5px auto' }}
            />
          </label>
          </Tooltip>
          {file && (
            <Tooltip title='Recortar imagen'>
            <Button
              aria-label="Crop"
              color="primary"
              onClick={() => setOpenCrop(true)}
              icon={<ScissorOutlined />}
            >
            </Button>
            </Tooltip>
          )}
        </div>
      </Modal>
      <CropEasy {...{ photoURL, openCrop, setOpenCrop, setPhotoURL, setFile, loading }} />
    </div>
  )
};

export default Profile;
