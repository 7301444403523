import React, { useState,useEffect,useRef } from "react";

import {Layout,Row,Col,Button,Input,Divider,Upload,message as toast  } from "antd";
import {SendOutlined,PaperClipOutlined,LoadingOutlined,UploadOutlined,CloseOutlined} from "@ant-design/icons"
import Request from "../../../../services/Request";
import FormData from "form-data";
import { ChatContext } from "../../../../context/ChatContext";
import { useContext } from "react";
const {  Footer } = Layout;
const { TextArea } = Input;
const propsUpload = {
  name: 'file',
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
    authorization: 'authorization-text',
    }
  }
const ChatInput = (props) =>{ 
  const {chat } = props
  const inputFile = useRef(null);
  const {setSendMessages} = useContext(ChatContext)
  const [message,setMessage] = useState('');
  const [canSend,setCanSend] = useState(false);
  const [loading,setLoading] = useState(false);
  const [files,setFiles]     = useState('');
  const [fileSelected,setFileSelected] = useState(null);
  const error = () => {
    toast.error('Fallo al enviar el archivo');
  };
  useEffect( () => {
    if(message){
      setCanSend(true);
    }else{
      setCanSend(false);
    }
  },[message])
  useEffect( () => {
    console.log(files);
    //if( files !== ''){
    //  const sendFile = async () => {
    //    const resp = await Request.post(`/file/${chat.data._id}?model=Chat`, files);
    //    console.log(resp)    
    //    if(resp.status === 400){
    //      setLoading(false);
    //      error();
    //    }
    //  }
    //  sendFile();
    //}
  },[files])

  const onChange = (e) =>{    
    const value = e.target.value?e.target.value:'';
    setMessage(value);    
  }
  const sendMessage = async () => {    
    if(canSend){
      setSendMessages(
        {
          "type": "TEXT",
          "message": message
        }
      )            
      setMessage('');      
    }else if(fileSelected){
      setLoading(true)
       const sendFile = async () => {
       const resp = await Request.post(`/file/${chat.data._id}?model=Chat`, files);
       console.log(resp)    
       if(resp.status === 400){
         setLoading(false);         
         error();
       }else{        
        setSendMessages(
          {
            "type": "FILE",
            "message": resp.data.path,
            "fileName":resp.data.name
          }
        )
        cancelFile();
       }
     }
     sendFile();
    }
  }

  const onChangeFile = (e) => {    
    console.log(e);
    if (!e.target.files[0]) {
      setFiles('')      
      return
    }
    const file = e.target.files[0]
    file.nameStep = e.target.name
    const formData = new FormData();    
    formData.append(e.target.name, e.target.files[0]);
    setFileSelected(file); 
    setFiles(formData);   
    //setTimeout(() => {
    //        
    //  setLoading(false)
    //}, 1000);    
  }

  const handleKeyDown = (e)=> {
    console.log(e);
    if (e.key === 'Enter') {
      sendMessage();
    }
  }
  const activeFileUpload = () => {
    console.log(inputFile);
    inputFile.current.click();
  }
  const cancelFile = () => {
    setFiles('');
    setFileSelected(null);
    setLoading(false);

  }
  return (        
      <Footer className="chat-footer" style={{ textAlign: 'center', alignContent:'center', paddingTop:'0' }}>
        <Divider/>
        <div className="row-text-content">
          <div className="col-text">
          
          {!fileSelected &&  
           <Button
           name='file'
           id='file'
           className= 'buton-file'
           type='text'           
           icon={<PaperClipOutlined style={{fontSize:'50px'}}  />}
           onClick={activeFileUpload}
            />
           }
          {!loading &&  
           <input
           ref={inputFile}
           name='file'
           id='file'
           className= 'input-file-chat'
           type='file'
           accept='application/pdf'
           onChange={onChangeFile}
            />
           }
          </div>
          <div className="col-text-input">
            {fileSelected &&  
            <div className="file-preview">
              <UploadOutlined  style={{fontSize:'50px'}} />
              {!loading &&<Button className="cancel-file" onClick={cancelFile} icon={<CloseOutlined disabled={loading} />}>
                {fileSelected.name}
              </Button>}              
            </div>}
            {!fileSelected && <TextArea onChange={onChange} value={message} className='chat-textarea' rows={2} onKeyDown={handleKeyDown} disabled={chat.data.archived} />}
            {loading && <LoadingOutlined />}
          </div>
          <div className="col-text">
            {!loading && <Button onClick={sendMessage} type="text" shape="circle" icon={<SendOutlined style={{fontSize:'50px'}} />} size={50}  disabled={chat.data.archived}/>}
          </div>
        </div>
       
      </Footer>    
  )
}
export default ChatInput;

/**
 * 
 *  <Row align="middle"  gutter={16}>
          <Col offset={5} span={{xs:2,md:1}}>
            <Button type="text" shape="circle" icon={<PaperClipOutlined style={{fontSize:'50px'}} />} size={100} />
          </Col>
          <Col span={{xs:20,md:22}}>
           <TextArea style={{width:'100%'}} rows={2} />
          </Col>
          <Col offset={5} span={{xs:2,md:1}}>
            <Button type="text" shape="circle" icon={<SendOutlined style={{fontSize:'50px'}} />} size={50} />
          </Col>
        </Row>
 * 
 */