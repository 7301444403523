import HttpResponseEmun from '../helpers/enums/HttpResponseEmun';
import Request from '../services/Request';


export const verifyCode = async (code) => {
    try {
        const res = await Request.get("/permissions/by-code/"+code);

        if(res.status === HttpResponseEmun.NotFound){
            return {message: `El código "${code}" está disponible `, unique: true};
        }
        if(res.status === HttpResponseEmun.OK){
            return {message: "Este código ya existe", unique: false};
        }
    } catch (error) {
        return {message: "Error al validar el código", unique: false};
    }
}

export const getAllPermissions = async () => {
    try {
        const res = await Request.get("/permissions", {showRows: 100, statusParam: "ACTIVE", ordering: "code,asc"});
        return res;
    } catch (error) {
        if(error) console.error(error);
        return error;
    }
}