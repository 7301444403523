import React, { useContext, useEffect, useState } from "react";
import {Layout,Row,Col,Avatar,Typography,Select,Dropdown,Menu,Button, Modal, Space,message    } from "antd";
import { UserOutlined,MoreOutlined,StopOutlined,MessageOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import { ChatContext } from "../../../../context/ChatContext";
import {Context as lotsContext} from '../../../../context/lotsContext';
import UserModelsEnum from "../../../../helpers/enums/UserModelsEnum";
import { Link } from 'react-router-dom'
import getProfilePicture from '../../../../services/getProfilePict';
import Request from "../../../../services/Request";
const { confirm } = Modal;

const getStatus = (key)=>{
  switch (key) {
    case 'OPEN':
      return 0
    case 'PAUSED':
      return 1
    case 'CANCELLED':
      return 2
    case 'CLOSED':
      return 3
    case 'IN_ANALYSIS':
      return 4
    case 'REFUSED':
      return 5
    case 'IN_FEASIBILITY':
      return 6
    case 'IN_NEGOTIATION':
      return 7
    case 'ON_SALE':
      return 8
    case 'RETIRED':
      return 9
    case 'SOLD_OUT':
      return 10
    case 'BOUGHT':
      return 11
    case 'NOT_AVAILABLE':
      return 12
  
    default:
      return 0;
  }
}
const getStatusDevelop = (key)=>{
  switch (key) {
    case 'IN_ANALYSIS':
      return 0
    case 'IN_FEASIBILITY':
      return 1
    case 'IN_NEGOTIATION':
      return 2
    case 'REFUSED':
      return 3
    case 'BOUGHT':
      return 4
    case 'RETIRED':
      return 5     
    case 'NOT_AVAILABLE':
      return 6
  
    default:
      return 0;
  }
}
const getStatusCorridor = (key)=>{
  switch (key) {
    case 'ON_SALE':
      return 0
    case 'BOUGHT':
      return 1
    case 'PAUSED':
      return 2
    case 'RETIRED':
      return 3
    case 'SOLD_OUT':
      return 4
  
    default:
      return 0;
  }
}

const status = [
  {label:'Abierta'         ,type:'OPEN'            , option:true},  
  {label:'Pausado'         ,type:'PAUSED'          ,option:true},
  {label:'Cancelada'       ,type:'CANCELLED'       ,option:true},
  {label:'Cerrado'         ,type:'CLOSED'          ,option:true},
  {label:'En análisis'     ,type:'IN_ANALYSIS'     , option:true},  
  {label:'Rechazado'       ,type:'REFUSED'         ,option:true},
  {label:'En factibilidad' ,type:'IN_FEASIBILITY'  , option:true},  
  {label:'En negociación'  ,type:'IN_NEGOTIATION'  , option:true}, 
  {label:'En venta'         ,type:'ON_SALE'        ,option:true},
  {label:'Retirado'        ,type:'RETIRED'         ,option:true},
  {label:'Vendido'         ,type:'SOLD_OUT'        ,option:true},
  {label:'Comprado'        ,type:'BOUGHT'          , option:true},
  {label:'No disponible'   ,type:'NOT_AVAILABLE'   , option:true},  
]
const statusDevelop= [   
  {label:'En análisis'     ,type:'IN_ANALYSIS'     , option:true , optionIndex:[3,1]},
  {label:'En factibilidad' ,type:'IN_FEASIBILITY'  , option:true , optionIndex:[3,2]}, 
  {label:'En negociación'  ,type:'IN_NEGOTIATION'  , option:true , optionIndex:[4,3]},
  {label:'Rechazado'       ,type:'REFUSED'         , option:false },
  {label:'Comprado'        ,type:'BOUGHT'          , option:false },
  {label:'Retirado'        ,type:'RETIRED'         , option:false },
  {label:'No disponible'   ,type:'NOT_AVAILABLE'   , option:false }, 
]
const statusCorridor = [   
  {label:'En venta'         ,type:'ON_SALE'        ,option:true, optionIndex:[2,4,3]},
  {label:'Comprado'        ,type:'BOUGHT'          ,option:false},
  {label:'Pausado'         ,type:'PAUSED'          ,option:true, optionIndex:[0,3]},
  {label:'Retirado'        ,type:'RETIRED'         ,option:false},
  {label:'Vendido'         ,type:'SOLD_OUT'        ,option:false},    
]

const { Option, OptGroup } = Select;

const ChatToolbar = (contactProps) =>{
  const {currentChat,archiveChat} = useContext(ChatContext)
  const {
    state: { currentLots, isVisibleHB, },
    dispatch,
  } = useContext(lotsContext);
  const [visitId,setVisitId] = useState('')
  const { Title,Text,  } = Typography;
  const [contactName,setContactName] = useState('');
  const [typeContact,setTypeContact] = useState('');
  const [nitContact,setNitContact] = useState('');
  const [statusActual,setStatusActual] = useState(status[0]);
  const [logo,setLogo] = useState('');
  const headerStyle ={
    marginBottom:"10px"
  }

  const titleConversationStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems:'center'
  }
  
  useEffect(() => {
    console.log(currentChat);    
    if(currentChat){
      let data = null;
      if(localStorage.getItem('user')){
        const model = JSON.parse(localStorage.getItem('user')).model;
        try {
          if(model === UserModelsEnum.Developer){ //desarrollador
            data = currentChat.data.broker;
            const indexStatus = currentChat.data.landRequest ? getStatusDevelop(currentChat.data.landRequest.landOffered.state) : null;
            setStatusActual(statusDevelop[indexStatus]);          
            setContactName(`${data.names} ${data.lastNames}`);
            setVisitId(data.associateType);
  
  
            const id = data._id
            const filename = data.photo
            const model = UserModelsEnum.User;            
            getProfilePicture(id, filename, model).then((response) => {
              if(response) {
                  setLogo(response)
                }
            });
  
  
          }else{ //corredor
            data = currentChat.data.developer;
            const indexStatus = currentChat.data.land ? getStatusCorridor(currentChat.data.land.state) : null;
            setStatusActual(statusCorridor[indexStatus]);
            setContactName(`${data.contactPerson}`);
            setVisitId(data._id); 
            
            const id = data._id
            const filename = data.logo
            const model = UserModelsEnum.Developer;            
            getProfilePicture(id, filename, model).then((response) => {
              if(response) {
                  setLogo(response)
                }
            });                       
          }
          if(currentChat.data.land) setTypeContact(`Lote ${currentChat.data.land.registrationNumber}`);
        } catch (err) {
          console.log('problem with load')
        }                        
      }            
      //setTypeContact(contactProps.contactProps.type);
      //setNitContact(contactProps.contactProps.nit);
    }
  },[contactProps])
  
  const changeStatus = async (index)=>{   
    const model = JSON.parse(localStorage.getItem('user')).model;
    const id = model === UserModelsEnum.Developer? currentChat.data.landRequest.landOffered._id:currentChat.data.land._id;
    const status_land =( model === UserModelsEnum.Developer)? statusDevelop[index].type: statusCorridor[index];  
    console.log(status_land)  
    const res = await Request.put(`/lands/${id}/change-state/${status_land.type}`)  
    console.log(res);
    if(res.status === 200){
      setStatusActual(status_land);
      message.success({
        content: <span>Estado del lote actualizado a <b>"{status_land.label}"</b></span>,
      });
    }else{
      message.error('Error al cambiar el estado')
    }   
  }
  const menuStatus = () => {    
    return (
      statusActual.option? <Menu>
        {orderMenu(statusActual.optionIndex)}                                         
      </Menu>:''
    )
  }

  const orderMenu = (options)=> {
    const model = JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).model;
    return (
    model === UserModelsEnum.Developer?
    options.map( (number,index) =>{
      return (
        <Menu.Item key={index}>
          <button 
          onClick={()=>{changeStatus(number)}} className={`select-status ${statusDevelop[number].type}`}>
            {statusDevelop[number].label}
          </button>
        </Menu.Item>
      )
    })
    :
    options.map( (number,index) =>{
      return (
        <Menu.Item key={index}>
          <button 
          onClick={()=>{changeStatus(number)}} className={`select-status ${statusCorridor[number].type}`}>
            {statusCorridor[number].label}
          </button>
        </Menu.Item>
      )
    })   
    )
  }

  const showConfirm = (title,description,onConfirm) => {
    confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      content: description,
      cancelText:'Cancelar',
      okText:'Continuar',
      onOk() {
        onConfirm();
      },
  
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  const menuOptions = () =>{
    return  (
      <Menu
        items={[   
          {
            key: '2',
            label: (
              <Link to={'/app/profile/'+visitId} className="link--header" >ir a su perfil</Link>              
            ),
            icon: <UserOutlined />,
            disabled: false,
          },         
          {
            key: '1',
            label: (
              <a target="_blank" rel="noopener noreferrer" onClick={
                () => {
                  showConfirm(
                    `¿Quieres bloquear a ${contactName}?`,
                    `No podras responder este chat, ni veras la información,lotes o requerimientos`,
                    () => {
                     console.log('Bloqueado') 
                    }
                  ) 
                }
              } >
                Bloquear
              </a>
            ),
            icon: <StopOutlined />,
            disabled: false,
          },
          {
            key: '3',
            label: (
              <a target="_blank" rel="noopener noreferrer" onClick={
                () => {
                  showConfirm(
                    `¿Quieres archivar el chat?`,
                    `Se guardará en chats archivados, no aparecerá en la lista principal`,
                    () => {
                      console.log('Chatear')
                      archiveChat()                       
                     }
                  ) 
                }
              } >
                Archivar chat
              </a>
            ),
            icon: <MessageOutlined />,
            disabled: false,            
          },
          
        ]}
      />
    );
  }
 
return (
    <div className="toolbar-chat">
      <Row className="site-layout-sub-header-background header-chat" style={headerStyle} gutter={{ xs: 16, sm: 16, md: 24, lg: 80 }}>
        <Col span={1}></Col>
        <Col className="avatar-col" span={{xs:1,sm:1}}>
          <Avatar 
            className="toolbar-miniature"
            icon={<UserOutlined />}  
            src={logo}          
          />
        </Col>
        <Col className="toolbar-develop-information" span={{xs:18,sm:21}}>
          <div className="title-conversation" style={titleConversationStyle}>
            <p className="title-conversation_main" >{contactName}</p>        
            {((typeContact && currentChat.data.land) ? <p className="title-conversation_sub">({typeContact})</p> : null)}      
          </div>                     
          <div className="title-conversation-nit" style={titleConversationStyle}>
            <p className="title-conversation_nit" >{nitContact}</p>
          </div>                     
        </Col>            
        <Col span={1}>      

        </Col>
      </Row> 
      <div className="example-spacer"></div>
      {(statusActual ? <Dropdown className="dropdown-buttons" overlay={menuStatus}>        
        <button  className={`select-status ${statusActual.type}`}>{statusActual.label}</button>
      </Dropdown> : null)}
      <Dropdown className="dropdown-buttons" overlay={menuOptions}>        
        <MoreOutlined />
      </Dropdown>

      
    </div>

  )
}
export default ChatToolbar;

/*

 {
            key: '2',
            label: (
              <Link to={'/app/profile/'+visitId} className="link--header" >ir a su perfil</Link>              
            ),
            icon: <UserOutlined />,
            disabled: false,
          },



  const menuLots = (lotId, status)=>{
    const arrayItems = [
      {
        key: 1, 
        label: 
        status === 'ON_SALE' ?
            (<div 
            onClick={(e)=> {
              e.stopPropagation();
              dispatch({
                type: 'SET_IS_VISIBLE_HB',
                payload:{
                  toggleState: {...isVisibleHB.toggleState},
                  option: true, 
                  type: 'confirmState', 
                  lot: {lotId: lotId, state: 'PAUSED'},
                }
              })
            }}  
            >
              <span className='status-PAUSED-b'>Pausado</span>
            </div>)
            : null
      },
      {
        key: 2, 
        label: 
        (status === 'PAUSED' || status === 'ON_SALE') ?
            (<div 
            onClick={(e)=> {
              e.stopPropagation();
              dispatch({
                type: 'SET_IS_VISIBLE_HB',
                payload:{
                  toggleState: {...isVisibleHB.toggleState},
                  option: true, 
                  type: 'confirmState', 
                  lot: {lotId: lotId, state: 'RETIRED'},
                }
              })
            }}  
            >
              <span className='status-RETIRED-b'>Retirado</span>
            </div>)
            : null
      },
      {
        key: 3, 
        label: 
        status === 'ON_SALE' ?
            (<div 
            onClick={(e)=> {
              e.stopPropagation();
              dispatch({
                type: 'SET_IS_VISIBLE_HB',
                payload:{
                  toggleState: {...isVisibleHB.toggleState},
                  option: true, 
                  type: 'confirmState', 
                  lot: {lotId: lotId, state: 'SOLD_OUT'},
                }
              })
            }}  
            >
              <span className='status-SOLD_OUT-b'>Vendido</span>
            </div>)
            : null
      },
      {
        key: 4, 
        label: 
        status === 'PAUSED' ?
            (<div 
            onClick={(e)=> {
              e.stopPropagation();
              dispatch({
                type: 'SET_IS_VISIBLE_HB',
                payload:{
                  toggleState: {...isVisibleHB.toggleState},
                  option: true, 
                  type: 'confirmState', 
                  lot: {lotId: lotId, state: 'ON_SALE'},
                }
              })
            }}  
            >
              <span className='status-ON_SALE-b'>En venta</span>
            </div>)
            : null
      },
    ]
  
    const menu = []
    arrayItems.forEach((item)=>{
      if(item.label !== null){
        menu.push(item)
      }
    })
    return (
      <Menu
        items={menu}
      />
    )
  }
 <Title style={{fontSize:"20px",marginTop:"40x"}} >Nombre de la conversacion</Title>       
*/