import React, {useContext} from 'react';
import {Divider, Tooltip, Avatar} from 'antd';
import { UserOutlined, MinusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import getPicture from '../../../helpers/getPicture';
import UserModelsEnum from '../../../helpers/enums/UserModelsEnum';
import { ProfileModalContext } from '../../../context/ProfileModalContext';

function BrokerItem({item}) {

    const { setProfileData } = useContext(ProfileModalContext);

    const url = item && item.user && getPicture(item.user._id, item.user.photo, UserModelsEnum.User);

    const showModal = () => {
        setProfileData({
            show: true,
            profileData: item
        });
    }

    if(!item) return null;

    return (
        <React.Fragment key={item.registrationNumber}>
            <tr onClick={showModal} className='table-row-body-broker'>
                <td className='col-listUser-adminPanel col1' scope="row">
                    <Avatar className="requestLand-avatar" icon={<UserOutlined />} src={url} size={30}/>
                </td>
                <td className='col-listUser-adminPanel col2' scope="row">
                    { item.user && item.user.names + " " + item.user && item.user.lastNames }
                </td>
                <td className='col-listUser-adminPanel col3' scope="row">
                    { item.user && item.user.email}
                </td>
                <td className='col-listUser-adminPanel col4' scope="row">
                    { item.type }
                </td>
                <td className='col-listUser-adminPanel col5' scope="row"></td>
                <td className='col-listUser-adminPanel col6' scope="row"></td>
                <td style={{textAlign: "end"}} className='col-listUser-adminPanel col7' scope="row">
                    <Tooltip title="Conversar">
                    <Link to="/app/chat">
                        <svg className="contactSvg" width="28" height="28" viewBox="0 0 28 28" fill="#64CBF7" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M3.33464 0.667969C1.86797 0.667969 0.681302 1.86797 0.681302 3.33464L0.667969 27.3346L6.0013 22.0013H24.668C26.1346 22.0013 27.3346 20.8013 27.3346 19.3346V3.33464C27.3346 1.86797 26.1346 0.667969 24.668 0.667969H3.33464ZM3.33464 3.33464H24.668V19.3346H4.89464L3.33464 20.8946V3.33464ZM16.668 14.0013H6.0013V16.668H16.668V14.0013ZM6.0013 10.0013H22.0013V12.668H6.0013V10.0013ZM22.0013 6.0013H6.0013V8.66797H22.0013V6.0013Z" fill="#64CBF7"/>
                        </svg>
                    </Link>
                    </Tooltip>
                </td>
            </tr>

            <tr>
                <td colSpan="7">
                    <Divider style={{margin: '4px', fontSize: '2rem'}}/>
                </td>
            </tr>
        </React.Fragment>
    )
}

export default BrokerItem
