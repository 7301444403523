import React, { useState } from 'react'
import logo360 from '../../assets/logo.png'
import elipse from '../../assets/icons/ellipse1.png'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import PieDeInicio from '../../components/PieRegistro/PieRegistro'
import { recoveryPassword } from '../../services/users'
import useToasts from '../../hooks/useToasts'

import './index.css'

const RecoveryPassword = () => {
  const { addToast } = useToasts()
  const [inputsLogin, setinputsLogin] = useState({
    email: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const changeForm = (e) => {
    setinputsLogin({
      ...inputsLogin,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (await recoveryPassword(inputsLogin.email)) {
        addToast({
          type: 'success',
          title: 'Hecho',
          message: 'Se ha enviado un correo para recuperar la contraseña',
        })
        setinputsLogin({
          email: '',
        })
      } else {
        addToast({
          type: 'error',
          title: 'Upss !!',
          message: 'El correo no existe',
        })
      }
    } catch (error) {
      console.error(error)
      addToast({
        type: 'error',
        title: 'Upss !!',
        message: 'El correo no existe',
      })
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className='recovery-password'>
      <div className='container-fluid main-container'>
        <Row>
          <Col>
            <Link to='/auth/login'>
              <img
                src={logo360}
                className='mt-5 ms-4 mt-lg-5 ms-lg-5 ps-lg-5'
                alt='logo'
              />
            </Link>
            <div className='position-absolute top-0 end-0 w-50 text-end'>
              <img src={elipse} className='img-fluid' alt='logo' />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
            <div className='container'>
              <h1 className='style--title--login'>Recuperar contraseña</h1>

              <form onChange={changeForm} onSubmit={handleSubmit}>
                <div>
                  <label className='form-label subtitle--login'>
                    Correo electrónico<span className='text-danger'> *</span>
                  </label>
                  <div className='input-group'>
                    <input
                      type='email'
                      name='email'
                      className='form-control style--inputs--login icoInput1'
                      placeholder='micorreo@correo.com'
                      value={inputsLogin.email}
                      required
                    />
                  </div>
                </div>
                <br />
                <div className='input-group'>
                  <button
                    className={
                      (inputsLogin.email != '' && !isLoading)
                          ? 'button--register-Active'
                          : 'button--register disabled-link'
                    }
                    type='submit'
                    disabled={isLoading}
                  >
                    Enviar
                  </button>
                </div>
              </form>
              <PieDeInicio
                direc='auth/login'
                mensajeLink='Regresar'
                tipo='1'
                lineClass='linea'
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default RecoveryPassword
