import React, {useState, useEffect, useCallback} from 'react';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { Form, Input, Select } from 'antd';

import './AssociatedBrokers.css';
import BrokersTable from './BrokersTable/BrokersTable';
import ProfileModal from '../../components/ProfileModal/ProfileModal';
import HttpResponseEmun from '../../helpers/enums/HttpResponseEmun';
import { useAuth } from '../../context/AuthContext';
import { getAssociatedBrokers } from '../../services/developers';

function AssociatedBrokers() {

    const [brokers, setBrokers] = useState(null);
    const [searchParam, setSearchParam] = useState('');
    const [filter, setFilter] = useState({page: 0, showRows: 10, statusParam: 'ACTIVE' });
    const [searchLoading, setSearchLoading] = useState(false);

    const {userData} = useAuth();

    const selectFilter = ((input, option) => {
        return (option.value).toLowerCase().includes(input.toLowerCase())
    });

    const getBrokersCallback = useCallback(async () => {
      if(userData){
        const response = await getAssociatedBrokers(filter);
        if (response.status === HttpResponseEmun.OK) {
            setBrokers(response.data);
        }
      }
      
    }, [filter, userData]);

    const applyFilterCallback = useCallback(() => {
      setFilter({...filter, searchParam});
      getBrokersCallback();
    }, [filter, searchParam]);

    useEffect(() => {
      const debounceTimer = setTimeout(() => {
        if(searchParam){
          applyFilterCallback();
        }
      }, 800);
      return () => clearTimeout(debounceTimer);
    }, [searchParam]);

    useEffect(() => {
      getBrokersCallback();
    }, []);

    

    return (
        <>
          <div className="container-fluid search-filters-container" style={{backgroundColor: "#272a30", color: "#fff"}}>
  
              <Form name="searchForm" layout="vertical" className="search-form">
                      <Form.Item name="search" label="Buscar" className="search-input">
                          <Input value={searchParam} onChange={(e) => setSearchParam(e.target.value)} name="search" type="search" autoFocus style={{backgroundColor: "transparent", color:"white"}} suffix={
                          <SearchOutlined className="site-form-item-icon search-input-icon" />} placeholder="Ingrese un criterio de búsqueda" />
                      </Form.Item>
  
                      <div className="search-filters">
                        <Form.Item name="city" label="Ciudad">
                          <Select disabled={true} showSearch filterOption={selectFilter} placeholder="Ciudad" allowClear>
                            {/*locationList.cities.map((city, i) => <Option key={i} value={city} />)*/}
                          </Select>
                        </Form.Item>
  
                        <Form.Item name="neighborhood" label="Barrio">
                          <Select disabled={true} showSearch filterOption={selectFilter} placeholder="Barrio" allowClear>
                            {/*locationList.neighborhoods.map((neighborhood, i) => <Option key={i} value={neighborhood} />)*/}
                          </Select>
                        </Form.Item>

                        <Form.Item name="area" label="Área">
                          <Select disabled={true} showSearch filterOption={selectFilter} placeholder="Area" allowClear>
                            {/*locationList.neighborhoods.map((neighborhood, i) => <Option key={i} value={neighborhood} />)*/}
                          </Select>
                        </Form.Item>
  
  
                        </div>
  
              </Form>
  
          </div>
  
          {searchLoading && (<div className="loadingContainer"><LoadingOutlined style={{fontSize: "20px", color: "#0dcaf0"}} /></div>)}
          
          <div className="search-table-container">
            { brokers && <BrokersTable brokers={brokers} filter={filter} setFilter={setFilter} /> }
          </div>

          <ProfileModal />
  
        </>
      )
}

export default AssociatedBrokers