import React, { useContext, useState, useEffect, useRef, useCallback } from 'react'
import { MinusOutlined, EyeOutlined, PlusCircleOutlined, UserOutlined, EyeInvisibleOutlined, CloseOutlined, BellOutlined } from '@ant-design/icons';
import { Divider, Pagination, Empty, Tooltip, message, Button, Modal, Row, Col, Menu, Dropdown, Avatar, Badge, Select, Slider } from 'antd';
import LotsDetails from '../lotsDetails/lotsDetails';
import CreateLots from '../CreateLots/CreateLots';
import EditLot from '../EditLot/EditLot';
import EditRequest from '../EditRequest/EditRequest';
import CreateRequest from '../CreateRequest/CreateRequest';
import iconChat from '../../../../assets/icons/iconChat.svg'
import NumberFormat from "react-number-format";
import Request from '../../../../services/Request';
import {Context} from '../../../../context/lotsContext';
import sort from '../../../handleSort'
import getLands from '../../../../services/getLands';
import { useAuth } from '../../../../context/AuthContext';
import { ButtonApp } from '../../../button/button';
import Spinner from '../../../../assets/spinners/spinner.svg'
import getLandRequests from '../../../../services/getLandRequests'
import getProfilePicture from '../../../../services/getProfilePict';
import './TableList.css'
import shortText from '../../../../helpers/shortText';
import FilterRS from '../../../FilterRS/FilterRS';
import SelectTypeFilter from '../../../SelectTypeFilter/SelectTypeFilter';
import { SignalCellularNoSimOutlined } from '@material-ui/icons';
import CloseLot from '../CloseLot/CloseLot';
import { ChatContext } from '../../../../context/ChatContext';
import getPicture from '../../../../helpers/getPicture';
import HttpResponseEmun from '../../../../helpers/enums/HttpResponseEmun';
import useCellWidths from '../../../../hooks/useCellWidths';
import LandRequestDetails from '../LandRequestDetails/LandRequestDetails';
import DeleteRequest from '../DeleteRequest/DeleteRequest';
import { SocketContext } from '../../../../context/socketIO';


const {Option} = Select


const thead = {
  lands:[
    {item:'registrationNumber', label:'Codigo'},
    {item:'state', label:'Estado'},
    {item:'city', label:'Ciudad'},
    {item:'address', label:'Dirección'},
    {item:'area', label:'Area(m2)'},
    {item:'metersPrice', label:'Precio del Lote ($COP)'},
    //{item:'', label:''},
  ],
  landRequests:[
    {item:'registrationNumber', label:'Codigo'},
    {item:'state', label:'Estado'},
    {item:'city', label:'Ciudad'},
    {item:'area', label:'Area(m2)'},
    {item:'metersPrice', label:'Precio del Lote ($COP)'},
    {item:'', label:''},
  ]
}
const getStatus = (key)=>{
  switch (key) {
    case 'OPEN':
      return 'Abierta'
    case 'PAUSED':
      return 'En pausa'
    case 'CANCELLED':
      return 'Cancelada'
    case 'CLOSED':
      return 'Cerrada'
    case 'IN_ANALYSIS':
      return 'En análisis'
    case 'REFUSED':
      return 'Rechazado'
    case 'IN_FEASIBILITY':
      return 'En factibilidad'
    case 'IN_NEGOTIATION':
      return 'En negociación'
    case 'ON_SALE':
      return 'En venta'
    case 'PAUSED':
      return 'Pausado'
    case 'RETIRED':
      return 'Retirado'
    case 'SOLD_OUT':
      return 'Vendido'
    case 'BOUGHT':
      return 'Comprado'
    case 'ACCEPTED':
      return 'En análisis'
    case 'NOT_AVAILABLE':
      return 'No disponible'
    case 'OWN':
      return 'Propio'
  
    default:
      return 'S/N';
  }
}

const reasonOptions = [
  'Información no corresponde al lote',
  'Información no es clara',
  'No se ha podido validar la información',
  'El lote tiene obstáculos jurídicos',
  'El lote tiene obstáculos Normativos',
  'El lote tiene obstáculos legales',
  'Se ha generado una alerta del propietario ',
  'No se puede continuar con el corredor',
  'El Corredor no responde a las solicitudes dentro de los tiempos esperados',
]

const TableList = ({ filter, setFilter}) => {
    const [showCreateLots, setShowCreateLots] = useState(false)
    const [showEditLots, setShowEditLots] = useState(false);
    const [showEditRequests, setShowEditRequests] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false);
    const [showSpinnertb, setShowSpinnertb] = useState(false);
    const [collapseItem, setCollapseItem] = useState(null);
    const [requestedLots, setRequestedLots] = useState({content:[]});
    const [filterTB, setFilterTB] = useState({filter:{page: 0, showRows: 5}, lotRequestId: ''});
    const [reason, setReason] = useState({reason: null});
    const [logo, setLogo] = useState({reason: null});
    const [unReadLands, setUnReadLands] = useState({});

    const {parentRef, cellWidths} = useCellWidths();
    
    const [state, setState] = useState(null);
    const {socket, setLandRequestAsViewed, isConnected, loadUnreadLandRequests, loadLandRequest} = useContext(SocketContext);
    const {user, userData} = useAuth()
    const {
        state: { currentLotsRequests, currentLots, isVisible },
        dispatch,
      } = useContext(Context);
      const {  
        setCurrentLandSelect,
        setCurrentLandRequest,
      } = useContext(ChatContext)
      
    const FilterSvg = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filter-left" viewBox="0 0 16 16">
        <path d="M2 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
    </svg>

  const loadLandRequestNotificationCount = (landRequests) => {

    landRequests.forEach(landRequest => {
        loadUnreadLandRequests(landRequest._id, (data) => {
            setUnReadLands((prev) => ({...prev, [data.landRequest]: data.notifications}))
        })
    });

    landRequests.forEach(landRequest => {
        loadLandRequest(user.id, landRequest._id)
    });

  }

  const markLandAsViewed = (landRequestId, landsId) => {
    if(socket) setLandRequestAsViewed(user.id, landRequestId, landsId)
  }

    
const getLot = async(lotRequestId)=>{
  setRequestedLots({content:[]})
  setShowSpinnertb(true)
  const res = await Request.get(`/land-requests/${lotRequestId || filterTB.lotRequestId}/lands-offered`, {...filterTB.filter, stateParam: 'IN_ANALYSIS,IN_FEASIBILITY,IN_NEGOTIATION,BOUGHT,RETIRED,NOT_AVAILABLE,REFUSED,ACCEPTED'})
 
  if(res.status === HttpResponseEmun.OK){
    setRequestedLots(res.data)
    setShowSpinnertb(false)
  }else{
    setShowSpinnertb(false)
  }
}

  useEffect (()=>{
    if(!currentLotsRequests.content || currentLotsRequests.content.length === 0) return;
    if(isConnected){
        loadLandRequestNotificationCount(currentLotsRequests.content);
    }
  },[currentLotsRequests, isConnected]);

useEffect(() => {
  if(filterTB.lotRequestId){
    getLot()

  }
}, [filterTB])

    
   
   const handleState = async(state, landRequestsId) => {
    setShowSpinner(true)
    const res = await Request.put(`/land-requests/${landRequestsId}/change-state/${state}`)
    if(res.status === HttpResponseEmun.OK){
      const response = await getLandRequests(filter, userData._id)
      if (response.status === HttpResponseEmun.OK) {
        dispatch({ type: 'SET_CURRENT_LOTS_REQUESTS', payload: response.data })
        setShowSpinner(false)
        }else{
          message.error('Ocurrió un error')
          setShowSpinner(false)
        }
    }
   }
   const handleStateOffer = async(state, landRequestsId, offerId, body) => {
    setShowSpinner(true)
    const res = await Request.put(`/land-requests/${landRequestsId}/offer-land/${offerId}/change-state/${state}`, body)
    if(res.status === HttpResponseEmun.OK){
      getLot(landRequestsId)
      const response = await getLandRequests(filter, userData._id)
        if (response.status === HttpResponseEmun.OK) {
                dispatch({ type: 'SET_CURRENT_LOTS_REQUESTS', payload: response.data })
            }else{
                message.error('Error al cargar las solicitudes')
            }
      setShowSpinner(false)
      closeIsVisible()
      message.success({
        content: <span>Estado del lote actualizado a <b>"{getStatus(state)}"</b></span>,
      });
      return true
      //message.success(`Estado del lote actualizado a "${getStatus(state)}"`)
    }else{
      setShowSpinner(false)
      message.error('Ocurrió un error')
      return false
    }
   }

   useEffect(() => {

    if(isVisible.type === 'accepted'){
      handleStateOffer('ACCEPTED', isVisible.lot.landRequestsId, isVisible.lot.offerId);
      dispatch({
        type: 'SET_IS_VISIBLE',
        payload:{
          toggleState: {...isVisible.toggleState},
          option: true, 
          type: 'details',
          lot: isVisible.lot,
        }
      })
    }

   }, [isVisible.type])

   const handleStateLots = async() => {
    setShowSpinner(true)
    const res = await Request.put(`/lands/${isVisible.lot.lotId}/change-state/${isVisible.lot.state}`)
    if(res.status === HttpResponseEmun.OK){
      const response = await getLands(filter, userData._id)
      if (response.status === HttpResponseEmun.OK) {
               dispatch({ type: 'SET_CURRENT_LOTS', payload: response.data })
        }else{
            message.error('Error al cargar los lotes')
        }
        message.success({
          content: <span>Estado del lote actualizado a <b>"{getStatus(isVisible.lot.state)}"</b></span>,
        });
        closeIsVisible()
        setShowSpinner(false)
      return true
      //message.success(`Estado del lote actualizado a "${getStatus(state)}"`)
    }else{
      setShowSpinner(false)
      message.error('Ocurrió un error')
      return false
    }
   }

const handleSort = (item) =>{
    setFilter({...filter, ordering: sort(item)}) 
  }


    const handleCLoseCreateLots = () => {
        setShowCreateLots(false);
    }
    
const closeIsVisible = () =>{
  setReason({reason: null})
    dispatch({
      type: 'SET_IS_VISIBLE',
      payload:{
        toggleState: {
            ...isVisible.toggleState
        },
        option: false, 
        type: '', 
        lot: {}
      }
    })
}

const handleCollapse = (id, i) => {
  setFilterTB({filter:{page: 0, showRows: 5}, lotRequestId: ''})
  if(collapseItem || collapseItem === 0){
    setCollapseItem(null)
  }
  if(collapseItem !== i){
    setCollapseItem(i)
    getLot(id)
  }
}

const handleIsPublic = async() => {
  setShowSpinner(true)
  const status = !isVisible.lot.public
  const res = await Request.put(`/lands/${isVisible.lot._id}`, {public: status})
  if(res.status === HttpResponseEmun.OK){
    const {data} = await getLands(filter, userData._id)
    if(isVisible.toggleState.type === 'land-requests'){
      dispatch({ type: 'SET_CURRENT_LOTS_REQUESTS', payload: {...currentLotsRequests, ...data} })
    }else if(isVisible.toggleState.type === 'lands'){
      dispatch({ type: 'SET_CURRENT_LOTS', payload: {...currentLots, ...data} })
    }
    setShowSpinner(false)
    message.success('Solicitud Exitosa!');
    closeIsVisible()
    
  }else{
    message.success('Ocurrio un error');
    setShowSpinner(false)
  }
}
const menuLots = (lotId, status)=>{
  const arrayItems = [
    {
      key: 1, 
      label: 
      status === 'ON_SALE' ?
          (<div 
          onClick={(e)=> {
            e.stopPropagation();
            dispatch({
              type: 'SET_IS_VISIBLE',
              payload:{
                toggleState: {...isVisible.toggleState},
                option: true, 
                type: 'confirmState', 
                lot: {lotId: lotId, state: 'PAUSED'},
              }
            })
          }}  
          style={{padding: '5px'}}>
            <span className='status-PAUSED'>Pausado</span>
          </div>)
          : null
    },
    {
      key: 2, 
      label: 
      (status === 'PAUSED' || status === 'ON_SALE') ?
          (<div 
          onClick={(e)=> {
            e.stopPropagation();
            dispatch({
              type: 'SET_IS_VISIBLE',
              payload:{
                toggleState: {...isVisible.toggleState},
                option: true, 
                type: 'confirmState', 
                lot: {lotId: lotId, state: 'RETIRED'},
              }
            })
          }}  
          style={{padding: '5px'}}>
            <span className='status-RETIRED'>Retirado</span>
          </div>)
          : null
    },
    {
      key: 3, 
      label: 
      status === 'ON_SALE' ?
          (<div 
          onClick={(e)=> {
            e.stopPropagation();
            dispatch({
              type: 'SET_IS_VISIBLE',
              payload:{
                toggleState: {...isVisible.toggleState},
                option: true, 
                type: 'confirmState', 
                lot: {lotId: lotId, state: 'SOLD_OUT'},
              }
            })
          }}  
          style={{padding: '5px'}}>
            <span className='status-SOLD_OUT'>Vendido</span>
          </div>)
          : null
    },
    {
      key: 4, 
      label: 
      status === 'PAUSED' ?
          (<div 
          onClick={(e)=> {
            e.stopPropagation();
            dispatch({
              type: 'SET_IS_VISIBLE',
              payload:{
                toggleState: {...isVisible.toggleState},
                option: true, 
                type: 'confirmState', 
                lot: {lotId: lotId, state: 'ON_SALE'},
              }
            })
          }}  
          style={{padding: '5px'}}>
            <span className='status-ON_SALE'>En venta</span>
          </div>)
          : null
    },
  ]

  const menu = []
  arrayItems.forEach((item)=>{
    if(item.label !== null){
      menu.push(item)
    }
  })
  return (
    <Menu
      items={menu}
    />
  )
  }
const menuOffer = (landRequestsId, offerId, status)=>{
return (
  <Menu
    items={[
      {
        key: 1, 
        label: 
        (status === 'ACCEPTED' || status === 'IN_ANALYSIS' || status === 'IN_FEASIBILITY' || status === 'IN_NEGOTIATION') &&
            <div 
            onClick={(e)=> {
              e.stopPropagation();
              dispatch({
                type: 'SET_IS_VISIBLE',
                payload:{
                  toggleState: {...isVisible.toggleState},
                  option: true, 
                  type: 'refused', 
                  lot: {landRequestsId: landRequestsId, offerId: offerId},
                }
              })
            }}  
            style={{padding: '5px'}}>
              <span className='status-REFUSED'>Rechazado</span>
            </div>
      },
      {
        key: 2, 
        label: 
        ( status === 'IN_ANALYSIS' || status === 'ACCEPTED' ) &&
            <div 
            onClick={(e)=> {
              e.stopPropagation();
              handleStateOffer('IN_FEASIBILITY', landRequestsId, offerId)
            }}  
            style={{padding: '5px'}}>
              <span className='status-IN_FEASIBILITY'>En factibilidad</span>
            </div>
      },
      {
        key: 3, 
        label: 
        status === 'IN_FEASIBILITY' &&
            <div 
            onClick={(e)=> {
              e.stopPropagation();
              handleStateOffer('IN_NEGOTIATION', landRequestsId, offerId)
            }}  
            style={{padding: '5px'}}>
              <span className='status-IN_NEGOTIATION'>En negociación</span>
            </div>
      }
    ]}
  />
)
}
const menu = (landRequestsId, status)=>{
return (
  <Menu
    items={[
      {
        key: 1, 
        label: 
            status === 'PAUSED' &&
           <div 
            onClick={(e)=> {
              e.stopPropagation();
              handleState('OPEN', landRequestsId)
            }}  
            style={{padding: '5px'}}>
              <span className='status-open'>Abierta</span>
            </div>
      },
      {
        key: 2, 
        label: 
            status === 'OPEN' &&
           <div 
            onClick={(e)=> {
              e.stopPropagation();
              handleState('PAUSED', landRequestsId)
            }}  
            style={{padding: '5px'}}>
              <span className='status-PAUSED'>Pausada</span>
            </div>
      },
      {
        key: 3, 
        label: 
          (status === 'OPEN' || status === 'PAUSED') &&
            <div 
            onClick={(e)=> {
              e.stopPropagation();
              handleState('CANCELLED', landRequestsId)
            }}  
            style={{padding: '5px'}}>
              <span className='status-cancelled'>Cancelada</span>
            </div>
      },
       {
        key: 4, 
        label: 
        status === 'OPEN' &&
            <div 
            onClick={(e)=> {
              e.stopPropagation();
              dispatch({
                type: 'SET_IS_VISIBLE',
                payload:{
                  toggleState: {...isVisible.toggleState},
                  option: true, 
                  type: 'closeLot', 
                  lot: {landRequestsId: landRequestsId, state: 'CLOSED'},
                }
              })
              //handleState('CLOSED', landRequestsId)
            }}  
            style={{padding: '5px'}}>
              <span className='status-closed'>Cerrada</span>
            </div>
      } 
    ]}
  />
)
}


const ConfirmState = () =>{
  
  return (
    <div style={{width: '100%', flexDirection: 'column', display: 'flex', alignItems: 'center', textAlign: 'center', marginTop: '20px'}}>
      <h5>¿Seguro que quieres cambiar el estado del lote?</h5>
      <div style={{marginTop: '50px', display:'flex', width: '100%', justifyContent: 'center'}}>
        <span style={{marginRight: '10px'}}>
          <ButtonApp onClick={handleStateLots} content='Aceptar' primary />
        </span>
        <span style={{marginLeft: '10px'}}>
          <ButtonApp onClick={()=>{
            dispatch({
              type: 'SET_IS_VISIBLE',
              payload:{
                toggleState: {...isVisible.toggleState},
                option: false, 
                type: '', 
                lot: {},
              }
            })
          }} content='Cancelar' primary cancel/>
        </span>
      </div>
    </div>
  )
}

const IsPublic = () =>{
  return(
  <>
    <div className='content-modal'>
        <h6>{isVisible.lot.public
        ? `¿Quieres volver este lote privado?`
        : `¿Quieres volver este lote publico?`
        }</h6>
        <span >
          {isVisible.lot.public 
          ? 'Nadie salvo los aliados a los que se lo envies podrán ver la información' 
          : 'Otras personas podrán verlo en tu perfil y en la búsqueda'
          }
        </span>
    </div>
    <div className='button-modal' style={{marginTop: '10px'}}>
      <span style={{margin: '10px 7.5%',}}>
          <ButtonApp
            onClick={handleIsPublic}
            content='SI'
            primary
          />
      </span>
      <span style={{margin: '10px 7.5%',}}>
          <ButtonApp
            onClick={closeIsVisible}
            content='NO'
            primary
            cancel
          />
      </span>
    </div>
  </>
  )
} 

const handleCloseEditLots = () => {
  setShowEditLots(false)
  dispatch({type:'RESET-LOT-DATA'})
}

const handleCloseEditRequests = () => {
  setShowEditRequests(false);
  dispatch({type:'RESET-REQUEST-DATA'});
}


const Refused = () => {
  return(
    <div style={{flexDirection: 'column', display: 'flex', alignItems: 'center'}}>
                <h5>Lote rechazado</h5>
                <div style={{width: '100%', marginTop: '15px'}}>
                  <label htmlFor='reason' style={{display: 'block'}}>Razón <span style={{color: 'red'}}>*</span></label>
                  <Select
                        id='reason'
                        className='select-reason'
                        bordered={false}
                        loading={false}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        placeholder='¿Por que rechazó el lote?'
                        style={{padding: '0.375rem 0.75rem', height: '39px', width: '100%'}}
                        onChange={(e)=> setReason({reason: e})}
                        value={reason.reason}
                      >
                      { reasonOptions.map((item, i)=>{
                        return(
                          <Option key={i} value={item}>{item}</Option>
                        )
                        })}
                    </Select>
                </div>
                  <span style={{marginTop: '50px'}}>
                  <ButtonApp disabled={reason.reason !== null ? false : true} onClick={()=>handleStateOffer('REFUSED', isVisible.lot.landRequestsId, isVisible.lot.offerId, reason)} content=' Enviar ' primary />
                  </span>
              </div>
  )
} 
const handleDelete = async() =>{
  setShowSpinner(true)
  const res = await Request.delete(`/lands/${isVisible.lot.data._id}`)
  if(res.status === HttpResponseEmun.OK){
    closeIsVisible()
    message.success(<span>Lote <b>"{isVisible.lot.data.registrationNumber}" eliminado</b></span>)
    const {data, status} = await getLands(filter, userData._id)
    if(status === HttpResponseEmun.OK){
      dispatch({ type: 'SET_CURRENT_LOTS', payload: data })
    }else{
      message.error('Ocurrio un error al obtener lotes')
    }
    setShowSpinner(false)
  }else{
    message.error('Ocurrio un problema')
    setShowSpinner(false)
  }
}

const Delete = () => {
  return(
    <div style={{width: '100%', flexDirection: 'column', display: 'flex', alignItems: 'center', textAlign: 'center'}}>
      <h5>¿Quieres eliminar este lote?</h5>
      <span>No podrás deshacer esta acción y deberás volverlo a registrar en caso de quererlo en la lista nuevamente</span>
      <div style={{marginTop: '50px', display:'flex', width: '100%', justifyContent: 'center'}}>
        <span style={{marginRight: '10px'}}>
          <ButtonApp onClick={handleDelete} content='SI' primary />
        </span>
        <span style={{marginLeft: '10px'}}>
          <ButtonApp onClick={()=>{
            dispatch({
              type: 'SET_IS_VISIBLE',
              payload:{
                toggleState: {...isVisible.toggleState},
                option: true, 
                type: 'details', 
                lot: isVisible.lot,
              }
            })
          }} content='NO' primary cancel/>
        </span>
      </div>
    </div>

  )
}
const onChatHandle = (landRequest,landOffert) =>{
  setCurrentLandSelect(landOffert.land); 
  setCurrentLandRequest(landRequest);   
}

const openViewRequest = (e, landRequest) => {
  e.stopPropagation();
  dispatch({
    type: 'SET_IS_VISIBLE',
    payload:{
      toggleState: {
        type: "land-requests", 
        label: "Mis solicitudes",
      },
      option: true, 
      type: 'request-details', 
      landRequest: landRequest,
    }
  })
}
    
return (
        <>
            <EditLot visible={showEditLots} onClose={handleCloseEditLots} filter={filter}/>
            <EditRequest visible={showEditRequests} onClose={handleCloseEditRequests} filter={filter}/>
            {isVisible.toggleState.type === 'lands' && <FilterRS hideState={true} onChange={(value)=> setFilter({...filter, ...value})}/>}
            {isVisible.toggleState.type === 'land-requests' && <SelectTypeFilter onChange={(value)=> setFilter({...filter, ...value})}/>}

          <div>   
            <table className="land-requests-table" >
            <thead className="HeadListUser-admin" >
                  <tr >
                    {thead[isVisible.toggleState.type === 'lands' ? 'lands' : 'landRequests'].map((e, i)=>{
                      if(e.item === 'metersPrice' && isVisible.toggleState.type === 'land-requests'){
                        return(
                          <th style={{textAlign: 'left', cursor: 'pointer'}} key={i} scope="col" className={`col-listUser-adminPanel col${i+1}`} onClick={() => { handleSort(e.item) }}>
                          <span style={{ marginRight: '0.5rem' }} >{e.label}</span>
                          {e.item !== '' && FilterSvg}
                          </th>)
                      }else if(e.item !== ''){
                        return(
                        <th key={i} scope="col" className={`col-listUser-adminPanel col${i+1}`} onClick={() => { handleSort(e.item) }} style={{ cursor: 'pointer' }}>
                        <span style={{ marginRight: '0.5rem' }} >{e.label}</span>
                        {e.item !== '' && FilterSvg}
                        </th>)
                          }else{
                        return(
                          <th key={i} scope="col" className={`col-listUser-adminPanel col7`} onClick={() => { handleSort(e.item) }} style={{ cursor: 'pointer' }}>
                          <span style={{ marginRight: '0.5rem' }} >{e.label}</span>
                          {e.item !== '' && FilterSvg}
                          </th>)
                      }
                  })}
                  </tr>
              </thead>
              <tbody className="fixed-td-width">
              {isVisible.toggleState.type === 'lands' &&
              <tr>
                <td colSpan="7">
                        <div className="titlefiltro">
                            <div className="col titleRight" style={{margin: 0}}>

                                <label onClick={()=>setShowCreateLots(true)} className="title-input titleEditNewUser">
                                <PlusCircleOutlined style={{ fontSize: '1.5rem', margin: '0 20px' }}/>
                                    Ingresar lote nuevo
                                </label>
                                <CreateLots filter={filter} visible={showCreateLots} onClose={handleCLoseCreateLots}/>
                            </div>
                        </div>
                    </td>
              
              </tr>}
              {isVisible.toggleState.type === 'land-requests' &&
              <tr>
                <td colSpan="7">
                        <div className="titlefiltro">
                            <div className="col titleRight" style={{margin: 0}}>

                                <label onClick={()=>setShowCreateLots(true)} className="title-input titleEditNewUser">
                                <PlusCircleOutlined style={{ fontSize: '1.5rem', margin: '0 20px' }}/>
                                    Crear nueva solicitud
                                </label>
                                <CreateRequest filter={filter} visible={showCreateLots} onClose={handleCLoseCreateLots}/>
                            </div>
                        </div>
                    </td>
              
              </tr>}
              {(isVisible.toggleState.type === 'land-requests' ? currentLotsRequests : currentLots).content && (isVisible.toggleState.type === 'land-requests' ? currentLotsRequests : currentLots).content.length === 0 &&
                <tr>
                  <td colSpan={8}>
                  <Empty 
                    description={
                      <span>
                        No se encontraron datos
                      </span>}
                     image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  
                  </td>
                </tr>}
                {isVisible.toggleState.type === 'lands' && currentLots.content && currentLots.content.map((item, i) => {
                
                return (
                  <React.Fragment key={i}>
                    <tr className='table-row-body-broker' onClick={(e)=>{
                      dispatch({
                        type: 'SET_IS_VISIBLE',
                        payload:{
                          toggleState: {
                            type: "lands", 
                            label: "Mis lotes",
                          },
                          option: true, 
                          type: 'details', 
                          lot: {data:item},
                        }
                      })
                    }}>
                      <td className='col-listUser-adminPanel col1' style={{color: item.state && item.state === 'OWN' ? '#b5a508' : '#757575', borderLeft: 'solid 5px #b5a508'}} scope="row">{item.registrationNumber && item.registrationNumber}</td>
                      <td className='col-listUser-adminPanel col2' scope="row">
                        {item.state === 'SOLD_OUT' 
                        ? <button onClick={(e)=> e.stopPropagation()} className={`select-status SOLD_OUT`}>Vendido</button>
                        : item.state === 'RETIRED'
                        ? <button onClick={(e)=> e.stopPropagation()} className={`select-status RETIRED`}>Retirado</button>
                        :
                        <Dropdown disabled overlayStyle={{zIndex: 999}} trigger='click' overlay={()=>menuLots(item._id, item.state)} placement="bottom">
                          <Tooltip title='Estado'>
                            <Button  onClick={(e)=> e.stopPropagation()} className={`select-status ${item.state}`}>{getStatus(item.state)}</Button>
                          </Tooltip>
                        </Dropdown> } 
                      </td>
                      <td className='col-listUser-adminPanel col3' scope="row">{item.location && item.location.city}</td>
                      <td className='col-listUser-adminPanel col4' scope="row">{item.address && shortText(item.address, 20)}</td>
                      <td className='col-listUser-adminPanel col5' scope="row"><NumberFormat value={item.area} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}  /> </td>
                      <td className='col-listUser-adminPanel col6' scope="row"><NumberFormat value={item.price} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} prefix={'$ '} /></td>
                      {/*<td className='col-listUser-adminPanel col7' scope="row">
                        <span className='divider'><MinusOutlined style={{ fontSize: '1.5rem', color: 'gray', transform: 'rotate(90deg)' }} /></span>
                        <span style={{ cursor: 'pointer' }} >
                          <Tooltip placement="top" title='Detalles'>
                          <Button 
                              type="link" 
                              icon={item.public && item.public === true ? <EyeOutlined style={{color: '#54B4D3', fontSize: '20px'}}/> : <EyeInvisibleOutlined 
                              style={{color: '#54B4D3', fontSize: '20px'}}/>}
                              className='button-row'
                              onClick={(e)=>{
                                e.stopPropagation();
                                dispatch({
                                  type: 'SET_IS_VISIBLE',
                                  payload:{
                                    toggleState: {
                                      type: "lands", 
                                      label: "Mis lotes",
                                    },
                                    option: true, 
                                    type: 'public', 
                                    lot: item,
                                  }
                                })
                              }}
                          />
                           
                          </Tooltip>
                        </span>
                        </td>*/}
                    </tr>
                    <tr>
                      <td colSpan="7">
                        <Divider style={{margin: '4px', fontSize: '2rem'}}/>
                      </td>
                    </tr>
                  </React.Fragment>
                  )})}
                {isVisible.toggleState.type === 'land-requests' && currentLotsRequests.content && currentLotsRequests.content.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <tr ref={parentRef} className={`table-row-body-broker ${(collapseItem !== i && collapseItem !== null ) && 'disableTr'}`} 
                     onClick={()=>{
                      handleCollapse(item._id, i)}}
                    >
                      <td className='col-listUser-adminPanel col1' style={item.state && item.state === 'ON_SALE' ? {color: '#21B608'} : {color: '#757575'}} scope="row">{item.registrationNumber && item.registrationNumber}</td>
                      <td className='col-listUser-adminPanel col2' scope="row">
                        {item.state === 'CANCELLED' 
                        ? <button onClick={(e)=> e.stopPropagation()} className={`select-status CANCELLED`}>Cancelada</button>
                        : item.state === 'CLOSED'
                        ? <button onClick={(e)=> e.stopPropagation()} className={`select-status CLOSED`}>Cerrada</button>
                        :
                        <Dropdown overlayStyle={{zIndex: 999}} trigger='click' overlay={()=>menu(item._id, item.state)} placement="bottom">
                          <Tooltip title='Estado'>
                            <Button  onClick={(e)=> e.stopPropagation()} className={`select-status ${item.state}`}>{getStatus(item.state)}</Button>
                          </Tooltip>
                        </Dropdown> } 
                      </td>
                      <td className='col-listUser-adminPanel col3' scope="row">{item.location && item.location.city}</td>
                      <td className='col-listUser-adminPanel col4' scope="row">{isVisible.toggleState.type === 'land-requests' ? (<><NumberFormat value={item.areaRange.startRange} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}  /> - <NumberFormat value={item.areaRange.endRange} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}  /></>) : <NumberFormat value={item.area} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}  />}</td>
                      <td className='col-listUser-adminPanel col5' scope="row"><NumberFormat value={item.price} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} prefix={'$ '} /></td>
                      <td style={{display: 'flex'}} className='col-listUser-adminPanel col7' scope="row">
                        <span className='divider'><MinusOutlined style={{ fontSize: '1.5rem', color: 'gray', transform: 'rotate(90deg)' }} /></span>
                        <span style={{ cursor: 'pointer' }} >
                          <Tooltip title="Ver solicitud"><Button 
                              type="link" 
                              icon={<EyeOutlined style={{color: '#54B4D3', fontSize: '20px'}}/>}
                              className='button-row'
                              onClick={(e) => openViewRequest(e, item)}
                          /></Tooltip>
                        </span>
                        <span style={{ cursor: 'pointer' }} >
                          <Button 
                              type="link" 
                              icon={<Badge count={unReadLands[item._id]}><BellOutlined style={{color: '#54B4D3', fontSize: '20px'}}/></Badge>}
                              className='button-row'
                          />
                        </span>
                        <span style={{ cursor: 'pointer' }} >
                          <div className={collapseItem === i ? 'bullet-point-tbd bullet-active' : 'bullet-point-tbd'}></div>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={6}>
                        <div className={collapseItem === i ? `collapseTr collapse-tr-open` : `collapseTr collapse-tr-close`}>
                          <table className="land-request-lands-table">
                            <tbody>
                            {requestedLots.content && requestedLots.content.map((el, i) => {
                              if(el.land && el.land.state === 'PAUSED') el.state = 'PAUSED';                           
                              return(
                                <React.Fragment key={i}>
                                  <tr className='tr-collapse table-row-body-broker'
                                        onClick={(e)=>{
                                          markLandAsViewed(item._id, el.land._id)
                                          console.log(el)
                                          dispatch({
                                            type: 'SET_IS_VISIBLE',
                                            payload:{
                                              toggleState: {
                                                type: "land-requests", 
                                                label: "Mis solicitudes",
                                              },
                                              option: true, 
                                              type: 'details', 
                                              lot: {data: el.land, landRequestsId: item._id, offerId: el._id, offerState: el.state, lotRequestState: item.state, landRequest: item },
                                            }
                                          })
                                        }} 
                                        >
                                      <td style={{width: cellWidths && cellWidths[0]}} className='col-listUser-adminPanel -td-1 col1' scope="row">{el.land.aliasLand && shortText(el.land.aliasLand, 10)}</td>
                                      <td style={{width: cellWidths && cellWidths[1]}} className='col-listUser-adminPanel -td-2 col2' scope="row">
                                      {el.state === 'REFUSED' && item.state !== 'CLOSED' && item.state !== 'CANCELLED' && item.state !== 'PAUSED'
                                        ? <button onClick={(e)=> e.stopPropagation()} className={`select-status REFUSED`}>Rechazado</button>
                                        : el.state === 'BOUGHT' && item.state !== 'CLOSED' && item.state !== 'CANCELLED' && item.state !== 'PAUSED'
                                        ? <button onClick={(e)=> e.stopPropagation()} className={`select-status BOUGHT`}>Comprado</button>
                                        : el.state === 'IN_NEGOTIATION' && item.state !== 'CLOSED' && item.state !== 'CANCELLED' && item.state !== 'PAUSED'
                                        ? <button onClick={(e)=> e.stopPropagation()} className={`select-status IN_NEGOTIATION`}>En negociacion</button>
                                        : el.state === 'NOT_AVAILABLE' && item.state !== 'CLOSED' && item.state !== 'CANCELLED' && item.state !== 'PAUSED'
                                        ? <button onClick={(e)=> e.stopPropagation()} className={`select-status NOT_AVAILABLE`}>No Disponible</button>
                                        :
                                        <Dropdown disabled={el.state === 'PAUSED'} overlayStyle={{zIndex: 999}} trigger='click' overlay={()=>menuOffer(item._id, el._id, el.state)} placement="bottom">
                                          <Tooltip title='Estado'>
                                            <Button style={{textAlign: 'center', display: 'flex', justifyContent: 'center', margin: 'auto'}} onClick={(e)=> e.stopPropagation()} className={`select-status ${el.state} ${item.state === 'CLOSED' || item.state === 'CANCELLED' || item.state === 'PAUSED' ? 'disabled' : ''}`}>{getStatus(el.state)}</Button>
                                          </Tooltip>
                                        </Dropdown>  }
                                      </td>
                                      <td style={{width: cellWidths && cellWidths[2]}} className='col-listUser-adminPanel -td-3 col3' scope="row">{el.land.location && el.land.location.city}</td>
                                      <td style={{width: cellWidths && cellWidths[3]}} className='col-listUser-adminPanel -td-4 col4' scope="row"><NumberFormat value={el.land.area} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}  /> </td>
                                      <td className='col-listUser-adminPanel -td-5 col5' scope="row"><NumberFormat value={el.land.price} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} prefix={'$ '} /></td>
                                      <td style={{textAlign: 'right', width: "100%"}} className='col-listUser-adminPanel -td-7 col7' scope="row">
                                        <span className='divider'><MinusOutlined style={{ fontSize: '1.5rem', color: 'gray', transform: 'rotate(90deg)' }} /></span>
                                        <span >
                                           <Tooltip placement="top" title='Chat'> 
                                          <Button                                               
                                              to='/app/chat'
                                              icon={<img src={iconChat} style={{width: '30px'}}/>}
                                              className='button-row'
                                               onClick={(e)=>{
                                                e.stopPropagation();
                                                onChatHandle(item,el);
                                               /*  dispatch({
                                                  type: 'SET_IS_VISIBLE',
                                                  payload:{
                                                    toggleState: {
                                                      type: "land-requests", 
                                                      label: "Mis solicitudes",
                                                    },
                                                    option: true, 
                                                    type: 'details', 
                                                    lot: el,
                                                  }
                                                }) */
                                              }} 
                                          />
                                          
                                          </Tooltip>
                                        </span>
                                        <span className='button-chat' style={{ cursor: 'pointer', marginLeft: '20px' }} >
                                          <Avatar icon={<UserOutlined />} style={{background: '#000'}} src={getPicture(el.land.user._id, el.land.user.photo, 'User')} size={48}/>
                                          
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                    <td colSpan="7">
                                      <Divider style={{margin: '4px', fontSize: '2rem'}}/>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              )})}
                              {showSpinnertb && 
                              <tr>
                                <td colSpan={7}>
                              <div 
                                style={{
                                  width: '100%', 
                                  zIndex: 1000,
                                  position: 'relative',
                                  display: 'inline-block',
                                  width: '100%',
                                  height: '20vh',
                                  top: 0,
                                  left: 0,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  zIndex: 1001,
                                  background: 'rgba(0, 0, 0, 0.542)',
                                  }}
                                //hidden={!showSpinner}
                                >
                                  <img src={Spinner} />
                              </div>
                                </td>
                              </tr>}
                            {requestedLots.content.length === 0 && !showSpinnertb &&
                              <tr>
                                <td colSpan={8}>
                                <Empty 
                                  description={
                                    <span>
                                      No se encontraron datos
                                    </span>}
                                  image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                
                                </td>
                              </tr>}
                            </tbody>
                            {requestedLots.totalElements >  requestedLots.size &&
                              <tfoot>
                                <tr>
                                  <td colSpan="7" align='right'>
                                    <Pagination
                                        pageSize={5}
                                        showSizeChanger={false}
                                        current={filterTB.filter.page +1}
                                        onChange={(current, pageSize)=> {
                                          setFilterTB({filter:{...filterTB.filter, page: current -1}, lotRequestId: item._id})
                                        }}
                                        defaultCurrent={1}
                                        total={requestedLots.totalElements}
                                        showTotal={(total) => `Total ${total}`}
                                        disabled={requestedLots.totalElements >  requestedLots.size ? false : true}
                                        responsive
                                      />
                                  </td>
                                </tr>
                              </tfoot>}
                          </table>

                        </div>
                      </td>
                    </tr>
                    
                    <tr>
                      <td colSpan="7">
                        <Divider style={{margin: '4px', fontSize: '2rem'}}/>
                      </td>
                    </tr>
                  </React.Fragment>
                  )})}

              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="7" align='right'>
                    <Pagination
                        showSizeChanger={false}
                        current={filter.page +1}
                        onChange={(current, pageSize)=> {
                          setCollapseItem(null)
                          setFilter({...filter, page: current -1})
                        }}
                        defaultCurrent={1}
                        total={(isVisible.toggleState.type === 'land-requests' ? currentLotsRequests : currentLots).totalElements}
                        showTotal={(total) => `Total ${total}`}
                        responsive
                        disabled={(isVisible.toggleState.type === 'land-requests' ? currentLotsRequests : currentLots).totalElements >  (isVisible.toggleState.type === 'land-requests' ? currentLotsRequests : currentLots).size ? false : true}
                      />
                  </td>
                </tr>
                <tr >
                    <td colSpan="7">
                      <Divider style={{marginBottom: '15px'}}/>
                    </td>
                </tr>
              </tfoot>
            </table>
            <div 
              style={{
                width: '100%', 
                zIndex: 1000,
                position: 'fixed',
                display: 'inline-block',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1001,
                background: 'rgba(0, 0, 0, 0.542)',
                }}
              hidden={!showSpinner}>
                <img src={Spinner} />
            </div>
            <Modal
              width={isVisible.type === 'public'
              ? 360
              : (isVisible.type === 'details' || isVisible.type === 'request-details')
              ? 750
              : 400
              }
              visible={isVisible.option}
              destroyOnClose
              closeIcon={
                <Tooltip title="Cerrar" placement="bottom">
                  <CloseOutlined onClick={closeIsVisible} style={{ fontSize: '25px', color: '#39C0ED', width: '100%', height: '100%', display: 'block', paddingTop: '15px'  }}/>
                </Tooltip>
              }
              footer=''
              centered
              >
                {isVisible.type === 'public' && <IsPublic/>}
                {isVisible.type === 'details' && <LotsDetails setShowEditLots={setShowEditLots} filter={filter} closeIsVisible={closeIsVisible} setShowSpinner={setShowSpinner} handleStateLots={handleStateOffer} lotsData={isVisible.lot && isVisible.lot.data && isVisible.lot.data.fields && isVisible.lot.data.fields.length > 0 ? {data: {...isVisible.lot.data, fields: [...isVisible.lot.data.fields]}, ...isVisible.lot} : isVisible.lot}/>}
                {isVisible.type === 'request-details' && <LandRequestDetails setShowEditRequests={setShowEditRequests} />}
                {isVisible.type === 'refused' && <Refused />}
                {isVisible.type === 'delete' && <Delete />}
                {isVisible.type === 'request-delete' && <DeleteRequest filter={filter} closeIsVisible={closeIsVisible} setShowSpinner={setShowSpinner} />}
                {isVisible.type === 'confirmState' && <ConfirmState />}
                {isVisible.type === 'closeLot' && <CloseLot props={isVisible.lot} setShowSpinner= {setShowSpinner} filter={filter}/>}
            </Modal>
        </div>

        </>
    )
}
export default TableList