import React,{useState, useEffect, useContext} from "react"
import { UserOutlined,MoreOutlined,MenuOutlined,ContainerOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import {Layout,Avatar,Col,Row,Drawer,Button,Typography,AutoComplete,Divider,Space,Pagination,Dropdown,Menu  } from "antd";
import Request from '../../../../services/Request';
import PreviewChat from '../ChatPreviewCard/PreviewChat';
import {useAuth} from '../../../../context/AuthContext'
import {ChatContext} from '../../../../context/ChatContext'
import UserModelsEnum from '../../../../helpers/enums/UserModelsEnum';
import getProfilePicture from '../../../../services/getProfilePict';
import "../../Style.css"
const { Header, Content, Footer, Sider } = Layout;
const {Text} = Typography

export default function SiderPanel(){
  const {chatList,getAllChats, toggleArchived} = useContext(ChatContext);
  const [isArchived,setIsArchive] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [autoComplete, setAcutoComplete] = useState(false);
  const [isToggle,setIsToggle] = useState(false);
  const [logo, setLogo] = useState('')
  const [chatListPreview, setChatListPreview] = useState([])
  const {user, userData} = useAuth()
  const [open, setOpen] = useState(false);
  const getNameCurrentUser = ()=>{
    if(user.model === UserModelsEnum.Developer){
      return userData.businessName;
    }
    return `${userData.names} ${userData.lastNames}`;
  }

  useEffect(() => {
    if(chatList){
      setChatListPreview(chatList);
    }
  },[chatList])
  
  useEffect(() => {
    if(user && userData && (userData.photo || userData.logo)){
    const id = user.model === UserModelsEnum.Developer ? userData._id : user.id
    const filename = userData.logo || userData.photo
    const model = user.model === UserModelsEnum.Developer ? UserModelsEnum.Developer : UserModelsEnum.User
      
    getProfilePicture(id, filename, model).then((response) => {
      if(response) {
          setLogo(response)
        }
      });
    }    
    getAllChats();
  }, [user, userData])
  
  const getNameBrokerOrDeveloper = (chat)=>{
    if(user && (user.model === UserModelsEnum.Developer)){
      return `${chat.broker.names} ${chat.broker.lastNames}`
    }
    return chat.developer.businessName
  }

  const getImageBrokerOrDeveloper = (chat) => {
    if(!user) return;
    const model = user.model !== UserModelsEnum.Developer ? UserModelsEnum.Developer : UserModelsEnum.User
    let id = '';
    let filename = ''
    if(user.model !== UserModelsEnum.Developer){
      id = chat.developer._id
      filename = chat.developer.logo;
    }else{
      id = chat.broker._id
      filename = chat.broker.photo;
    }
    return {id, filename, model};
  }

  const menuOptions = () =>{
    return  (
      <Menu
        items={[         
          {
            key: '2',
            label: (
              <a target="_blank" rel="noopener noreferrer" onClick={toggleArchived} >
                Archivados
              </a>
            ),
            icon: <ContainerOutlined/> ,
            disabled: false,
          },                
        ]}
      />
    );
  }

  const userHeader = () => {
    return (
      <Row justify="start" align="middle">
          <Col span={collapsed?24:5} style={{ textAlign: 'center' }}>
            <Avatar 
            size={37} 
            icon={<UserOutlined />}
            src={logo}
             />
          </Col>
          {!collapsed && 
          <Col span={15}>
            <Row justify="start">
              <Col span={24}><Text style={{color:'white', fontSize:'12px'}} strong>{ userData ? getNameCurrentUser() : '' }</Text></Col>
              <Col span={24}><Text style={{color:'blue'}} keyboard>{user ? user.model : ''}</Text></Col>
            </Row>            
          </Col>}
          {!collapsed && 
          <Col span={4}>
            <Dropdown className="dropdown-buttons" overlay={menuOptions}>                   
              <MoreOutlined style={{color:'white'}}/>
            </Dropdown>            
          </Col>}
        </Row>
    )
  }

  const allySearch = () => {
    return(
      <AutoComplete
        dropdownRender={menu => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <Space style={{ padding: '4px', justifyContent: 'center', width: '100%' }}>
            <Pagination defaultCurrent={1} total={20} size="small"/>
            </Space>
          </>
        )}
        className={'form-control form-info-corredor form-chat'} 
        name='searchAlly'
        bordered={false}
        loading={false}
        showSearch
        value={autoComplete}
        optionFilterProp="children"
        filterOption={(input, option) => {}}
        placeholder='Buscar aliado'
        style={{padding: '0.375rem 0.75rem', height: '39px', marginBottom: '20px'}}                        
        onChange={(e)=>{setAcutoComplete(e)}}
         >        
      </AutoComplete>
    )
  }

  const contentSide = (callback = () => {}) =>{
    return (
      <>
      {userHeader()}
        <div className="division"></div>
        {!collapsed || open && allySearch()}
        <div className="chat-card-container">{
          chatListPreview.map(chat => 
            <PreviewChat 
            key={chat._id}
            archived={chat.archived}
            collapsed={collapsed}
            registrationNumber={chat.land && chat.land.registrationNumber}
            name={getNameBrokerOrDeveloper(chat)}
            urlImage={getImageBrokerOrDeveloper(chat)}
            chat={chat}
            toggleOpen={callback}
            />
          )
        }</div>
      </>
    )
  }
  const toggleOpen = () =>{
    setOpen(prev => !prev);
  }
  const onClose  = ()=>{
    setOpen(false)
  }
  return (    
    <>
      <Button className="chat-open-drawer" type="primary" shape="circle" icon={<MenuOutlined />} size={20} onClick={toggleOpen} />
      
      <Sider className="chat-sidenav" style={{backgroundColor:"#272A30"}} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
      {contentSide()}
      </Sider>

      <Drawer 
       className="chat-sidenav-mobile"                                 
        visible={open}
        closable={false}
        placement={"left"}       
        onClose={onClose}
        >
        {contentSide(toggleOpen.bind(this))}
        <Button className="chat-close-drawer" type="primary" shape="circle" icon={<ArrowLeftOutlined />} size={20} onClick={toggleOpen} />
      </Drawer>
    </>
          
  )
}