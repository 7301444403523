import HttpResponseEmun from '../helpers/enums/HttpResponseEmun'
import Request from './Request'

export const GetUserAdmin = async (id) => {
  try {
    const response = await Request.get(`/users/${id}`)
    if (response.status === HttpResponseEmun.OK) {
      const { data: userData } = response
      const data = {
        ...userData,
      }
      return data
    }
  } catch (error) {
    console.error(error)
  }
  return false
}