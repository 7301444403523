import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import uploadPhotoIcon from '../../assets/icons/uploadPhotoIcon.png'
import HeaderRegister from '../../components/HeaderRegister/HeaderRegister'
import StepSelector from '../../components/Register/StepSelector'
import { useAuth } from '../../context/AuthContext'
import UserModelsEnum from '../../helpers/enums/UserModelsEnum'
import { updatePhoto } from '../../services/brokers'
import './Photo.css'

const Photo = () => {
  const { userData, refreshUser } = useAuth()
  const [profilePhoto, setProfilePhoto] = useState('')
  const [redirect, setRedirect] = useState(false)

  const onSubmit = () => {}

  const onChangeProfilePhoto = (e) => {
    try {
      if (!e.target.files[0]) {
        setProfilePhoto('')
        return
      }
      setProfilePhoto(e.target.files[0])
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      updatePhoto(formData)
    } catch (e) {
      console.error(e)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setRedirect(true)
    refreshUser()
  }

  return (
    <div className='verification'>
      {userData && userData.photo && <Redirect to='/app/home' />}
      {redirect && <Redirect to='/app/profile' />}
      <form onSubmit={onSubmit}>
        
        <StepSelector step={5} userType={ UserModelsEnum.Broker} />

        <div className='container'>
          <div className='row container-row'>
            <h1 className='select--corredor'>¡Cuenta Registrada con éxito!</h1>
            <h2 className='select--corredor comentarioCorredor'>
              Solo queda un par de pasos. Sube una foto tuya.
            </h2>
          </div>

          <div className='row container-row px-5'>
            <div className='col d-flex justify-content-center align-items-center'>
              <div className='photo-input-container'>
                <label className='labelInputFile' htmlFor='inputSubir'>
                  <div className='photo-input'>
                    {profilePhoto ? (
                      <img
                        src={URL.createObjectURL(profilePhoto)}
                        className='photo-input-img'
                        alt='profilePhoto'
                      />
                    ) : (
                      <>
                        <div className='photo-input-icon'>
                          <img src={uploadPhotoIcon} alt='descargarNube' />
                        </div>
                        <div>Sube una foto aquí</div>
                      </>
                    )}
                  </div>
                </label>
                <input
                  name='fotoCorredor'
                  id='inputSubir'
                  type='file'
                  accept='image/*'
                  onChange={onChangeProfilePhoto}
                />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col btn-holder'>
              <button
                type='submit'
                className={`btn btn-endNext ${!profilePhoto ? 'disabled' : ''}`}
                disabled={!profilePhoto}
                onClick={handleSubmit}
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Photo
