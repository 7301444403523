import React, {useContext} from 'react';
import {Context} from '../../../../context/lotsContext';
import {useAuth} from '../../../../context/AuthContext';
import Request from '../../../../services/Request';
import getLandRequests from '../../../../services/getLandRequests';

import { message } from 'antd';
import { ButtonApp } from '../../../button/button';
import HttpResponseEmun from '../../../../helpers/enums/HttpResponseEmun';

function DeleteRequest({filter, setShowSpinner, closeIsVisible}) {

    const {state: {isVisible}, dispatch} = useContext(Context);
    const {userData} = useAuth();

    const handleDelete = async() =>{
        setShowSpinner(true)
        const res = await Request.delete(`/land-requests/${isVisible.landRequest._id}`)
        if(res.status === HttpResponseEmun.OK){
          closeIsVisible()
          message.success(<span>Solicitud <b>"{isVisible.landRequest.registrationNumber}" eliminada!</b></span>)
          const {data, status} = await getLandRequests(filter, userData._id)
          if(status === HttpResponseEmun.OK){
            dispatch({ type: 'SET_CURRENT_LOTS_REQUESTS', payload: data });
          }else{
            message.error('Ocurrio un error al obtener las solicitudes')
          }
          setShowSpinner(false)
        }else{
          message.error('Ocurrio un problema')
          setShowSpinner(false)
        }
      }

    return(
        <div style={{width: '100%', flexDirection: 'column', display: 'flex', alignItems: 'center', textAlign: 'center'}}>
          <h5>¿Quieres eliminar esta solicitud?</h5>
          <span>No podrás deshacer esta acción y deberás volver a registrar la solicitud en caso de quererla en la lista nuevamente</span>
          <div style={{marginTop: '50px', display:'flex', width: '100%', justifyContent: 'center'}}>
            <span style={{marginRight: '10px'}}>
              <ButtonApp onClick={handleDelete} content='SI' primary />
            </span>
            <span style={{marginLeft: '10px'}}>
              <ButtonApp onClick={()=>{
                dispatch({
                  type: 'SET_IS_VISIBLE',
                  payload:{
                    toggleState: {...isVisible.toggleState},
                    option: true, 
                    type: 'request-details',
                    landRequest: isVisible.landRequest
                  }
                })
              }} content='NO' primary cancel/>
            </span>
          </div>
        </div>
    
      )
}

export default DeleteRequest
