import React, {useState, useEffect} from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import { ButtonApp } from '../../button/button';
import { message, Modal, Transfer } from 'antd';
import Empty from '../../Empty/Empty';
import PermissionCategoriesEnum from '../../../helpers/enums/PermissionCategoriesEnum';
import { getAllPermissions } from '../../../services/permission';
import { updateRolPermissions, getRolById } from '../../../services/rol';
import './index.css';
import HttpResponseEmun from '../../../helpers/enums/HttpResponseEmun';


const mutedText = (text) => (<p className="text-muted m-0">{text}</p>);

const LocaleTransferEs = {itemUnit: "Permiso", itemsUnit: "Permisos", notFoundContent: <Empty />,
remove: "Eliminar", removeAll: "Eliminar Todos", selectAll: "Seleccionar Todo",
removeCurrent: "Eliminar", searchPlaceholder: "Buscar", selectInvert: "Invertir",
titles: [mutedText("Sin aplicar"), mutedText("Aplicados")], selectCurrent:"Seleccionar"};


export default function RolPermissionsModal({closeRolPermissionsModal, showRolPermissionsModal}) {

    const [isSending, setIsSending] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [permissionsAppliedKeys, setPermissionsAppliedKeys] = useState([]);
    const [previousPermissionsAppliedKeys, setPreviousPermissionsAppliedKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);

    const rol = showRolPermissionsModal.rol;

    const getPermissions = async () => {

        setIsLoading(true);


        const permissionsData = await getAllPermissions();
        if(permissionsData.status === HttpResponseEmun.OK){
            setPermissions(permissionsData.data.content.map(item => ({...item, key: item._id})));
        } else {
            message.error({content: `¡Hubo un error al cargar los permisos!`, duration: 4});
        }
        
        const permissionsAppliedData = await getRolById(rol._id);
        if(permissionsAppliedData.status === HttpResponseEmun.OK){
            const permissionsAppliedKeyArray = permissionsAppliedData.data.permissions;
            setPermissionsAppliedKeys(permissionsAppliedKeyArray);
            setPreviousPermissionsAppliedKeys(permissionsAppliedKeyArray);
        } else {
            message.error({content: `¡Hubo un error al cargar los permisos del rol "${rol.code}"`, duration: 4});
        }

        setIsLoading(false);

    }
    
    useEffect(() => {
        if(showRolPermissionsModal.visible) getPermissions();
    }, [showRolPermissionsModal.visible]);

    useEffect(() => {
        if(showRolPermissionsModal.visible === false){
            setPermissionsAppliedKeys([]);
            setSelectedKeys([]);
        }
    }, [showRolPermissionsModal.visible]);
    
    const filterOption = (inputValue, option) => {

        inputValue = inputValue.toLowerCase();

        const categoryName = PermissionCategoriesEnum[option.category];

        if(option.code.toLowerCase().indexOf(inputValue) > -1) return true;
        if(option.name.toLowerCase().indexOf(inputValue) > -1) return true;
        if(categoryName.toLowerCase().indexOf(inputValue) > -1) return true;
    }

    const handleChange = (newTargetKeys) => {
        setPermissionsAppliedKeys(newTargetKeys);
    };

    const handleSearch = async (dir, value) => {
        //console.log('search:', dir, value);
    };

    const updateRolPermissionsHandler = async () => {

        const permissionsApplied = permissionsAppliedKeys.map(key => {
            return permissions.find(permission => permission.key === key);
        });

        setIsSending(true);

        try {
            const {status} = await updateRolPermissions(rol._id, permissionsApplied.map(permission => permission._id));
            if(status === HttpResponseEmun.OK) {
                message.success({content: `¡El rol "${rol.code}" ha sido actualizado correctamente!`, duration: 3});
            } else {
                message.error({content: `¡Error al aplicar los permisos al rol "${rol.code}"`, duration: 4});
            }
        } catch (error) {
            message.error({content: `¡Error al aplicar los permisos al rol "${rol.code}"`, duration: 4});
            console.error(error);
        } finally {
            setIsSending(false);
            closeRolPermissionsModal();
        }

    }

    const selectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    }

    return (
    <Modal
        className="rolPermissionsModal"
        title={`Permisos de "${rol && rol.code}"`} 
        visible={showRolPermissionsModal.visible} 
        onCancel={closeRolPermissionsModal}
        footer={
        <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <span style={{margin: '0 3px'}}>
            <ButtonApp content="Cerrar" primary cancel onClick={closeRolPermissionsModal}/>
            </span>
            <span style={{margin: '0 3px'}}>
            <ButtonApp disabled={isSending | previousPermissionsAppliedKeys.join("") === permissionsAppliedKeys.join("")} 
            content="Actualizar" primary onClick={updateRolPermissionsHandler} />
            </span>
        </div>
        }
        >

        {isLoading && (<div style={{height: "20rem"}} className="text-center pt-5">
            <LoadingOutlined style={{fontSize: "20px", color: "#0d6efd"}} />
            <small style={{display:"block"}} className="text-muted mt-2">Cargando permisos</small>
        </div>)}

        {!isLoading && <Transfer className="transferRol"
        locale={LocaleTransferEs}
        dataSource={permissions}
        showSearch
        filterOption={filterOption}
        targetKeys={permissionsAppliedKeys}
        onChange={handleChange}
        onSearch={handleSearch}
        onSelectChange={selectChange}
        selectedKeys={selectedKeys}
        render={permission => `${permission.code} ${permission.name}`}
        />}

    </Modal>
    )
}
