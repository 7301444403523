import Request from './Request';

export default async function getAllRequestLands(searchParams, filter) {
    try {
        const res = await Request.get("/land-requests", {...searchParams, ...filter, stateParam: "OPEN"});
        return res.data;        
    } catch (error) {
        error && console.error(error)
        return error
    }
}