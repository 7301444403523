import React, {useMemo, useState, useEffect} from 'react';
import {FileOutlined, FileJpgOutlined, FileImageOutlined, FileGifOutlined, FilePdfOutlined,
    FileWordOutlined, FileExcelOutlined, FileZipOutlined, FileTextOutlined, LoadingOutlined } from '@ant-design/icons';
import getFile from '../../../services/getFile';
import './index.css';

const fileIcon = (extension, fileUrl) => {

    if(!fileUrl){
        return <LoadingOutlined />
    }

    switch (extension) {
        case 'jpg':
            return <FileJpgOutlined />
        case 'jpeg':
        case 'svg':
        case 'png':
            return <FileImageOutlined />
        case 'gif':
            return <FileGifOutlined />
        case 'pdf':
        case 'epub':
            return <FilePdfOutlined />
        case 'docx':
        case 'doc':
            return <FileWordOutlined />
        case 'xlsx':
            return <FileExcelOutlined />
        case 'rar':
        case 'zip':
            return <FileZipOutlined />
        case 'txt':
            return <FileTextOutlined />
        default:
            return <FileOutlined />
    }
}

const getFileExtension = (fileName) => {
    const splitedFileName = fileName.split(".");
    return splitedFileName[splitedFileName.length - 1];
}

function ShowFile({id, filename, model}) {

    const [fileUrl, setFileUrl] = useState(null);

    const fileNameToDisplay = "Abrir Archivo";
    const extension = useMemo(() => getFileExtension(filename), []);

    const handleFileClick = (e) => {
        if(!fileUrl) e.preventDefault();
    }

    const getFileUrl = async () => {
        const url = await getFile(id, filename, model);
        setFileUrl(url);
    }

    useEffect(() => {
        getFileUrl();
    }, []);
    

    return (
        <a onClick={handleFileClick} href={fileUrl || "#"} target="_blank">
        <label className="show-file-label">
            {fileIcon(extension, fileUrl)}
            <p>{fileNameToDisplay}</p>
        </label>
        </a>
    )
}

export default ShowFile
