import Login from '../pages/Login/Login'
import Register from '../pages/Register/Register'
import RegisterUserData from '../pages/Register/RegisterUserData'
import RecoveryPassword from '../pages/RecoveryPassword'
import UnloggedUpdatePassword from '../pages/UnloggedUpdatePassword'
import Page404 from '../pages/404'

/**
 * ⚠ These are internal routes before auth!
 * They will be rendered inside the app, using the default `containers/Auth`.
 */
const routes = [
  {    
    path: '/login', // the url 
    component: Login, // view rendered
  },
  {
    path: '/register',
    component: Register,
  },
  {
    path: '/register/user-data',
    component: RegisterUserData,
  },
  {
    path: '/recovery-password',
    component: RecoveryPassword,
  },
  {
    path: '/recovery-password/:token',
    component: UnloggedUpdatePassword,
  },
  {
    path: '/404',
    component: Page404,
  },
]

export default routes
