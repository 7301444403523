import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import uploadFileIcon from '../../assets/icons/uploadFileIcon.png'
import loadingFileIcon from '../../assets/icons/loadingFileIcon.png'
import loadedFileIcon from '../../assets/icons/loadedFileIcon.png'
import uploadPhotoIcon from '../../assets/icons/uploadPhotoIcon.png'
import HeaderRegister from '../../components/HeaderRegister/HeaderRegister'
import StepSelector from '../../components/Register/StepSelector'
import { useAuth } from '../../context/AuthContext'
import { uploadRUT, uploadDERL, uploadLOGO } from '../../services/developers'
import DeveloperStatesEnum from '../../helpers/enums/DeveloperStatesEnum'
import './Verification.css'
import UserModelsEnum from '../../helpers/enums/UserModelsEnum'

const Verificacion = () => {
  const { userData, setUserData, refreshUser } = useAuth()
  const [profilePhoto, setProfilePhoto] = useState('')
  const [rutFile, setRutFile] = useState('')
  const [loadingRut, setLoadingRut] = useState(false)
  const [certificateFile, setCertificateFile] = useState('')
  const [loadingCertificate, setLoadingCertificate] = useState(false)

  const onSubmit = () => {}

  const onChangeProfilePhoto = (e) => {
    try {
      if (!e.target.files[0]) {
        setProfilePhoto('')
        return
      }
      setProfilePhoto(e.target.files[0])
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      uploadLOGO(userData._id, formData)
    } catch (e) {
      console.error(e)
    }
  }

  const onChangeRutFile = (e) => {
    setLoadingRut(true)
    if (!e.target.files[0]) {
      setRutFile('')
      setLoadingRut(false)
      return
    }
    setRutFile(e.target.files[0])
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    uploadRUT(userData._id, formData)
      .then((response) => {})
      .catch((e) => {
        console.error(e)
      })
      .finally(() => {
        setLoadingRut(false)
      })
  }

  const onChangeCertificateFile = (e) => {
    setLoadingCertificate(true)
    if (!e.target.files[0]) {
      setCertificateFile('')
      setLoadingCertificate(false)
      return
    }
    setCertificateFile(e.target.files[0])
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    uploadDERL(userData._id, formData)
      .then((response) => {})
      .catch((e) => {
        console.error(e)
      })
      .finally(() => {
        setLoadingCertificate(false)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setUserData((lastUserData) => ({
      ...lastUserData,
      state: DeveloperStatesEnum.VERIFICATION,
    }))
    refreshUser()
  }

  return (
    <div className='verification'>
      {userData && userData.state !== DeveloperStatesEnum.Pending && (
        <Redirect to='/app/profile' />
      )}
      <form onSubmit={onSubmit}>
        <HeaderRegister />
        <StepSelector step={5} userType={ UserModelsEnum.Developer} />

        <div className='container'>
          <div className='row container-row'>
            <h1 className='select--corredor'>¡Cuenta Registrada con éxito!</h1>
            <h2 className='select--corredor comentarioCorredor'>
              Solo queda un par de pasos. Sube una foto o Logo de tu empresa y
              los siguientes documentos.
            </h2>
          </div>

          <div className='row container-row px-5'>
            <div className='col-lg-6 d-flex justify-content-center align-items-center'>
              <div className='photo-input-container'>
                <label className='labelInputFile' htmlFor='inputSubir'>
                  <div className='photo-input'>
                    {profilePhoto ? (
                      <img
                        src={URL.createObjectURL(profilePhoto)}
                        className='photo-input-img'
                        alt='profilePhoto'
                      />
                    ) : (
                      <>
                        <div className='photo-input-icon'>
                          <img src={uploadPhotoIcon} alt='descargarNube' />
                        </div>
                        <div>Sube una foto aquí</div>
                      </>
                    )}
                  </div>
                </label>
                <input
                  name='fotoCorredor'
                  id='inputSubir'
                  type='file'
                  accept='image/*'
                  onChange={onChangeProfilePhoto}
                />
              </div>
            </div>

            <div className='col-lg-6 d-flex flex-column justify-content-center py-2 px-lg-5'>
              <label className='textMsg'>
                Para poder verificar tu cuenta tienes{' '}
                <strong>5 días hábiles</strong> para cargar en tu perfil los
                siguientes documentos que validen la existencia de la empresa,
                con una <strong>vigencia menor a 30 días</strong>
              </label>
              <div className='form-row text-left mt-2'>
                <label className='tit-input'>
                  RUT<span className='text-danger'> *</span>
                </label>
                <div className='form-group'>
                  <label
                    className={`tit-Archivobtn ${
                      loadingRut ? 'loading' : rutFile ? 'loaded' : 'upload'
                    }`}
                    htmlFor='inputSubirRUT'
                  >
                    <img
                      alt=''
                      src={
                        loadingRut
                          ? loadingFileIcon
                          : rutFile
                          ? loadedFileIcon
                          : uploadFileIcon
                      }
                      className='imgDentroBtn'
                    />
                    {loadingRut
                      ? 'Cargando Archivo'
                      : rutFile
                      ? 'Carga Exitosa'
                      : 'Subir Archivo'}
                  </label>
                  <input
                    name='rutArchivo'
                    id='inputSubirRUT'
                    type='file'
                    accept='application/pdf'
                    onChange={onChangeRutFile}
                  />
                </div>
              </div>

              <div className='form-row text-left mt-2'>
                <label className='tit-input'>
                  Certificado de Existencia y Representación Legal
                  <span className='text-danger'> *</span>
                </label>
                <div className='form-group'>
                  <label
                    className={`tit-Archivobtn ${
                      loadingCertificate
                        ? 'loading'
                        : certificateFile
                        ? 'loaded'
                        : 'upload'
                    }`}
                    htmlFor='inputSubirCer'
                  >
                    <img
                      alt=''
                      src={
                        loadingCertificate
                          ? loadingFileIcon
                          : certificateFile
                          ? loadedFileIcon
                          : uploadFileIcon
                      }
                      className='imgDentroBtn'
                    />
                    {loadingCertificate
                      ? 'Cargando Archivo'
                      : certificateFile
                      ? 'Carga Exitosa'
                      : 'Subir Archivo'}
                  </label>
                  <input
                    name='certArchivo'
                    id='inputSubirCer'
                    type='file'
                    accept='application/pdf'
                    onChange={onChangeCertificateFile}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col btn-holder'>
              <button
                type='submit'
                className={`btn btn-endNext ${
                  !(
                    profilePhoto &&
                    rutFile &&
                    !loadingRut &&
                    certificateFile &&
                    !loadingCertificate
                  )
                    ? 'disabled'
                    : ''
                }`}
                disabled={
                  !(
                    profilePhoto &&
                    rutFile &&
                    !loadingRut &&
                    certificateFile &&
                    !loadingCertificate
                  )
                }
                onClick={handleSubmit}
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Verificacion
