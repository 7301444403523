import { ScissorOutlined, CloseOutlined } from '@ant-design/icons';
import {
  Box,
  Button,
  Modal,
  Slider,
} from 'antd';
import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { ButtonApp } from '../../button/button';
import getCroppedImg from './utils/cropImage';

const CropEasy = ({ photoURL, openCrop, setOpenCrop, setPhotoURL, setFile, loading}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async () => {
    loading(true);
    try {

      const { file, url } = await getCroppedImg(
        photoURL,
        croppedAreaPixels,
        rotation
      );
      var newFile = new File([file], file.name, {lastModified: Date.now(), type: file.type});
      setPhotoURL(url);
      setFile(newFile);
      setOpenCrop(false);
    } catch (error) {
      console.error(error);
    }
   loading(false)
  };
  return (
    <>
      <Modal
        title="Editar Imagen"
        visible={openCrop}
        onCancel={() => setOpenCrop(false)}
        bodyStyle={{position: 'relative', minHeight: 'calc(100vh - 370px)'}}
        footer={
        <div>
          <div style={{ width: '100%', marginBottom: '15px' }}>
          <div>
            <span>Zoom: {zoomPercent(zoom)}</span>
            <Slider
              tipFormatter={zoomPercent}
              min={1}
              max={3}
              step={0.1}
              value={zoom}
              onChange={(zoom) => setZoom(zoom)}
            />
          </div>
          <div>
            <span>Rotation: {rotation + '°'}</span>
            <Slider
              min={0}
              max={360}
              value={rotation}
              onChange={(rotation) => setRotation(rotation)}
            />
          </div>
        </div>
          <div style={{ display: 'flex', gap: 2, flexWrap: 'wrap', justifyContent: 'center' }} >
            <span style={{marginRight: '5px'}}><ButtonApp onClick={() => setOpenCrop(false)} content='Cancelar' primary cancel/></span>
            <span style={{marginLeft: '5px'}}><ButtonApp  onClick={cropImage} content='Aceptar' primary/></span>
          </div>
      </div>
        }

      >
      <div >
        <Cropper
          //style={{width: '300px', height: '300px', position: 'relative'}}
          image={photoURL}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={1}
          cropShape="round"
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onCropComplete={cropComplete}
          restrictPosition={true}
        />
      
        
      </div>
      </Modal>
    </>
  );
};

export default CropEasy;

const zoomPercent = (value) => {
  return `${Math.round(value * 100)}%`;
};
