import HttpResponseEmun from '../helpers/enums/HttpResponseEmun'
import Request from './Request'

export const putTheme = async (id,theme) => {
  try {
    const response = await Request.put(`/users/${id}`, { theme })
    if (response.status === HttpResponseEmun.OK) {
      throw Error(`El tema a sido cambiado`)
    }
    return true
  } catch (error) {
    console.error(error)
  }
  return false
}
