let arraySort = []

const handleSort = (item) => {

    if(item !== ''){
        if(arraySort.length === 0){
            //arraySort = [...arraySort, {item: item, mode: 'asc'}]
            arraySort = [{item: item, mode: 'asc'}]
        }
        arraySort.map((el, i)=> {
            if(!(arraySort.find(element => element.item === item))){
                //arraySort = [...arraySort, {item: item, mode: 'asc'}]
                arraySort = [{item: item, mode: 'asc'}]
                           }
            if(el.item === item){
                const Sort = arraySort
                Sort.splice(i, 1, {item: item, mode: el.mode === 'asc' ? 'desc' : 'asc'})
                arraySort = Sort
                           }
        })
        let orderingContent = ''
        arraySort.forEach((el)=>{
            orderingContent = `${orderingContent}${el.item},${el.mode},`
        })
               return orderingContent
    }
}

export default handleSort