import { Formik } from 'formik'
import * as Yup from 'yup';
import React, {useState, useEffect, useCallback, useRef} from 'react';
import { Drawer, message, Select } from 'antd';
import { CheckCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import { ButtonApp } from '../../button/button';
import Request from '../../../services/Request';
import './modal.css';
import { verifyCode } from '../../../services/permission';
import PermissionCategoriesEnum from '../../../helpers/enums/PermissionCategoriesEnum';
import HttpResponseEmun from '../../../helpers/enums/HttpResponseEmun';


const defaultValue = Object.keys(PermissionCategoriesEnum)[0];

const { Option } = Select;

const iconclose = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-x-lg" viewBox="0 0 16 16">
    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
</svg>

export const PermissionDrawer = ({ refreshData, visible, onClose, permission }) => {

    const verifyCodeRefTimer = useRef(null);

    const [codeChecked, setCodeChecked] = useState();
    const [isUnique, setIsUnique] = useState(false);
    const [isChecking, setIsChecking] = useState(false);
    const [errorCode, setErrorCode] = useState("");
    const [mayusError, setMayusError] = useState(false);
    const codeInputRef = useRef();

    const [sliderWidth, setSliderWidth] = useState("450px");
    //const initialValues = { ...rol };
    //const defaultState = permission === null ? "ACTIVE" : (permission.state === "0" ? "INACTIVE" : "ACTIVE");
    const initialValues = permission === null ? ({code: undefined, name: undefined, description: "", category: defaultValue}) : permission //, state: defaultState});

    const title = permission === null ? "Crear permiso" : "Editar permiso";
    const mainButtonText = permission === null ? "Guardar" : "Actualizar";

    const validationSchema = Yup.object().shape({
        code: Yup.string()
            .required('Este campo es requerido')
            .min(3, 'Debe tener al menos 3 caracteres')
            .matches(/^[A-Z0-9-]+$/, 'Sólo se permiten mayúsculas, guiones y números')
            .matches(/^\S*$/, 'El código no puede contener espacios'),
        name: Yup.string()
            .min(3, 'Debe tener al menos 3 caracteres')
            .required('Este campo es requerido'),
        description: Yup.string(),
        category: Yup.string()
            .required('Este campo es requerido'),
    });

    const verifyCodeCallback = useCallback(
        async (code) => {

            setErrorCode("");

            // Dont check if the code is the code to update

            if(permission !== null && code === permission.code) {
                setIsUnique(false);
                return null;
            } 

            if(code !== codeChecked) setCodeChecked("");

            // Dont check if there is another code error

            if(!/^[A-Z0-9-]+$/.test(code) || !/^\S*$/.test(code) || code.length <= 2) {
                setIsUnique(false);
                return null;
            }

            setIsChecking(true);
            const res = await verifyCode(code);
            setIsChecking(false);
            if(res.unique){
                setCodeChecked(code);
                setIsUnique(res.message);
            } else {
                setCodeChecked(code);
                setIsUnique(false);
                setErrorCode(res.message);
            }
        },
        [permission],
    )

    const codeValidator = async (e) => {

        const code = e.target.value;

        if(code === "") setIsChecking(false);

        if(isChecking) return null;

        clearTimeout(verifyCodeRefTimer.current);

        verifyCodeRefTimer.current = setTimeout(() => {
            verifyCodeCallback(code);
        }, 800);

    }


    const onSubmit = (e) => {

        if(permission === null){
            Request.post(`/permissions`,{...e})
            .then(res => { 
                if(res.status === HttpResponseEmun.OK){
                    refreshData();
                    onClose();
                    message.success('¡El permiso "'+ e.code +'" se ha creado con éxito!');
                } else {
                    message.error('Ha ocurrido un error');
                }
            })
            .catch(err => { message.error('Ha ocurrido un error'); })
        } else {
            Request.put(`/permissions/${e._id}`,{...e})
            .then(res => { 
                if(res.status === HttpResponseEmun.OK){
                    refreshData();
                    onClose();
                    message.success('¡El permiso "'+ e.code +'" ha sido actualizado con éxito!');
                } else {
                    message.error('Ha ocurrido un error');
                }
            })
            .catch(err => { message.error('Ha ocurrido un error'); })
        }
    }

    const auto_grow = (e) => {
        e.target.style.height = "5px";
        e.target.style.height = (e.target.scrollHeight)+"px";
    }

    const codeChangeHandler = (formik, e) => {

        if(e.target.value !== "" && (!/^[A-Z0-9-]+$/.test(e.target.value) || !/^\S*$/.test(e.target.value))) {
            e.target.value = e.target.value.substring(0, e.target.value.length - 1);
            formik.handleChange(e);

            if(!mayusError) {
                message.error({content: "Sólo se permiten mayúsculas, guiones y números", duration: 4});
                setMayusError(true);
            }
            return null;
        }

        formik.handleChange(e);
        codeValidator(e);

    }

    useEffect(() => {
        if(codeInputRef.current) codeInputRef.current.focus();
    }, [codeInputRef.current])

    useEffect(() => {
        if(window.innerWidth < 535) setSliderWidth('100%');
    }, [window.innerWidth]);

    useEffect(() => {
        return () => {
            if(!visible) {
                setIsUnique(false);
                setCodeChecked("");
                setErrorCode("");
            }
        }
    }, [visible]);

    /*useEffect(() => {
        if(isChecking === false && codeChecked !== "" && errorCode === ""){
            if(codeChecked !== codeCurrentValue) {
                verifyCodeCallback(codeCurrentValue)
            }
        }
    }, [codeChecked, codeCurrentValue, isChecking]);*/

    useEffect(() => {
        let mayusInterval;
        if(mayusError){
            mayusInterval = setTimeout(() => setMayusError(false), 4000);
        }
        return () => {
            clearInterval(mayusInterval);
        }

    }, [mayusError]);

    return (
        <>
            <Drawer
                headerStyle={{ textAlign: 'left' }}
                width={sliderWidth}
                closeIcon={iconclose}
                maskClosable={false}
                title={title}
                placement="right"
                onClose={onClose}
                visible={visible}
                destroyOnClose
            >

                <div className="container-fluid container-global">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {(formik) => {
                            return (
                                <form className='form-shadows' onSubmit={formik.handleSubmit}>
                                    <div className="container contai-SemiFluid">
                                        <div className='container CreateUserContainer'>
                                            <div className="row">
                                                <div className="col rol-modal-body">
                                                    <div className="row row-form-margin">
                                                        <div className="col-sm-12 col-md-6">
                                                            <label className="title-input">Código <span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                ref={codeInputRef}
                                                                autoComplete="off"
                                                                type="text"
                                                                name="code"
                                                                className={formik.errors.code && formik.values.code !== undefined ? "form-control form-admin-newUser-Error" : "form-control form-admin-newUser"}
                                                                placeholder="Código"
                                                                onChange={(e) => codeChangeHandler(formik, e)}
                                                                value={formik.values.code}
                                                            />
                                                            {(formik.errors.code && !isChecking && formik.values.code !== undefined) ? (<div className="form-label Mensaje-newUser-Error">{formik.errors.code}</div>) : null}
                                                            {(!isChecking && !isUnique && !formik.errors.code && errorCode !== "") && (<div className="form-label Mensaje-newUser-Error">{errorCode}</div>)}
                                                            {isChecking && (<div className="form-label">Revisando código <LoadingOutlined style={{color: "#1a86c4"}} /></div>)}
                                                            {(isUnique && !formik.errors.code && !isChecking) && (<div className="form-label green-colored">{isUnique}<CheckCircleTwoTone twoToneColor="#52c41a" /></div>)}
                                                        </div>
                                                        <div className="col-sm-12 col-md-6">
                                                            <label className="title-input">Nombre <span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                className={formik.errors.name && formik.values.name !== undefined ? "form-control form-admin-newUser-Error" : "form-control form-admin-newUser"}
                                                                placeholder="Nombre del permiso"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.name}
                                                            />
                                                            {formik.errors.name && formik.values.name !== undefined ? (<div className="form-label Mensaje-newUser-Error">{formik.errors.name}</div>) : null}
                                                        </div>
                                                    </div>
                                                    <div className="row row-form-margin">
                                                        <div className="col-sm-12 col-md-6">
                                                            <label className="title-input">Descripción</label>
                                                            <textarea
                                                                name="description"
                                                                className="form-control"
                                                                placeholder="Descripción del permiso"
                                                                onChange={e => {
                                                                    formik.handleChange(e)
                                                                }}
                                                                value={formik.values.description}
                                                                onInput={auto_grow}
                                                            />
                                                        </div>
                                                        <div className="col-sm-12 col-md-6">
                                                            <label className="title-input">Categoría <span style={{ color: 'red' }}>*</span></label>
                                                            <Select
                                                            defaultValue={permission === null ? defaultValue : permission.category}
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            onChange={e => { formik.handleChange({ target: { name: "category", value: e } }) }}
                                                            >
                                                                {Object.keys(PermissionCategoriesEnum).map((key, i) => {
                                                                    return <Option value={key}>{PermissionCategoriesEnum[key]}</Option>
                                                                })}
                                                            </Select>
                                                            {formik.errors.category && formik.values.category !== undefined ? (<div className="form-label Mensaje-newUser-Error">{formik.errors.category}</div>) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="container">
                                                <div className="row" style={{ marginTop: '2rem' }}>
                                                    <div className="col-6" style={{textAlign:'right'}}>
                                                        <ButtonApp onClick={(e) => {onClose();e.preventDefault()}} primary cancel content='Cancelar'/>
                                                    </div>
                                                    <div className="col-6">
                                                        <ButtonApp type='submit' primary content={mainButtonText} onClick={formik.handleSubmit} disabled={(formik.dirty === true ? Object.values(formik.errors).length === 0 ? false : true : true) || (errorCode !== "") || isChecking} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            )
                        }}
                    </Formik>
                </div>
            </Drawer >
        </>
    )
}