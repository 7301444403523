import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useRegister } from '../../context/RegisterContext'
import { verifyNIT } from '../../services/developers'
import InputMask from 'react-input-mask';

import './InfoEmpresarial.css'

const InfoEmpresarial = () => {
  const { setStep, userToRegister, setUserToRegister } = useRegister()

  const [EstadoBotones, setEstadoBotones] = useState({
    active1: userToRegister.interestUse.vis,
    active2: userToRegister.interestUse.noVis,
    active3: userToRegister.interestUse.industrial,
    active4: userToRegister.interestUse.dotacional,
    active5: userToRegister.interestUse.commercial,
    active6: !!userToRegister.interestUse.others,
  })

  const handleClick = (event) => {
    if (event.target.id === '1') {
      setEstadoBotones({
        active1: !EstadoBotones.active1,
        active2: EstadoBotones.active2,
        active3: EstadoBotones.active3,
        active4: EstadoBotones.active4,
        active5: EstadoBotones.active5,
        active6: EstadoBotones.active6,
      })
    }

    if (event.target.id === '2') {
      setEstadoBotones({
        active1: EstadoBotones.active1,
        active2: !EstadoBotones.active2,
        active3: EstadoBotones.active3,
        active4: EstadoBotones.active4,
        active5: EstadoBotones.active5,
        active6: EstadoBotones.active6,
      })
    }
    if (event.target.id === '3') {
      setEstadoBotones({
        active1: EstadoBotones.active1,
        active2: EstadoBotones.active2,
        active3: !EstadoBotones.active3,
        active4: EstadoBotones.active4,
        active5: EstadoBotones.active5,
        active6: EstadoBotones.active6,
      })
    }
    if (event.target.id === '4') {
      setEstadoBotones({
        active1: EstadoBotones.active1,
        active2: EstadoBotones.active2,
        active3: EstadoBotones.active3,
        active4: !EstadoBotones.active4,
        active5: EstadoBotones.active5,
        active6: EstadoBotones.active6,
      })
    }
    if (event.target.id === '5') {
      setEstadoBotones({
        active1: EstadoBotones.active1,
        active2: EstadoBotones.active2,
        active3: EstadoBotones.active3,
        active4: EstadoBotones.active4,
        active5: !EstadoBotones.active5,
        active6: EstadoBotones.active6,
      })
    }
    if (event.target.id === '6') {
      setEstadoBotones({
        active1: EstadoBotones.active1,
        active2: EstadoBotones.active2,
        active3: EstadoBotones.active3,
        active4: EstadoBotones.active4,
        active5: EstadoBotones.active5,
        active6: !EstadoBotones.active6,
      })
    }
  }

  const onSubmit = (formData) => {
    setUserToRegister({
      ...userToRegister,
      businessName: formData.razonSocial,
      nit: formData.nit,
      webSite: formData.sitioWeb,
      interestUse: {
        vis: EstadoBotones.active1,
        noVis: EstadoBotones.active2,
        industrial: EstadoBotones.active3,
        dotacional: EstadoBotones.active4,
        commercial: EstadoBotones.active5,
        others: EstadoBotones.active6 ? formData.otherInterest.trim() : '',
      },
    })
    setStep(4)
  }

  const validationSchema = Yup.object().shape({
    razonSocial: Yup.string().required('Este campo es obligatorio'),
    nit: Yup.string()
      .required('Este campo es obligatorio')
      .test('nit', 'El NIT ingresado ya ha sido registrado con anterioridad', (value) => {
        return verifyNIT(value)
      }).test('nit', 'El NIT ingresado tienen un formato inválido', (value) => {
        const regex = /^[0-9]{3}.[0-9]{3}.[0-9]{3}$/
        return regex.test(value)
      }),
    sitioWeb: Yup.string().required('Este campo es obligatorio'),
    otherInterest: Yup.string(),
  })

  const formik = useFormik({
    initialValues: {
      razonSocial: userToRegister.businessName,
      nit: userToRegister.nit,
      sitioWeb: userToRegister.webSite,
      otherInterest: userToRegister.interestUse.others,
    },

    onSubmit,

    validationSchema,
  })

  let permiteGuardar = false

  let errorCheck = false

  if (
    formik.values.razonSocial !== '' &&
    formik.values.nit !== '' &&
    formik.values.sitioWeb !== ''
  ) {
    if (
      EstadoBotones.active1 === false &&
      EstadoBotones.active2 === false &&
      EstadoBotones.active3 === false &&
      EstadoBotones.active4 === false &&
      EstadoBotones.active5 === false &&
      EstadoBotones.active6 === false
    ) {
      errorCheck = true
    } else {
      permiteGuardar = true
    }
    //permiteGuardar = true;
  }

  return (
    <div className='developer-business-info'>
      <form onSubmit={formik.handleSubmit}>
        <div className='container block-from'>
          <div className='form-row'>
            <div className='form-group col-md-7'>
              <label className='tit-input'>
                Razón Social<span className='text-danger'> *</span>
              </label>
              <input
                type='text'
                name='razonSocial'
                className={
                  formik.errors.razonSocial
                    ? 'form-control form-info-corredorError'
                    : 'form-control form-info-corredor'
                }
                placeholder='Nombre de mi empresa'
                onChange={formik.handleChange}
                value={formik.values.razonSocial}
              />
            </div>
            {formik.errors.razonSocial ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.razonSocial}
              </div>
            ) : null}
          </div>
          <div className='form-row'>
            <div className='form-group col-md-7'>
              <label className='tit-input'>
                NIT<span className='text-danger'> *</span>
              </label>
              <InputMask 
               mask='999.999.999' 
                name='nit'
                className={
                  formik.errors.nit
                    ? 'form-control form-info-corredorError'
                    : 'form-control form-info-corredor'
                }
                placeholder='900.900.900'
                onChange={formik.handleChange}
                value={formik.values.nit}
              />
            </div>
            {formik.errors.nit ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.nit}
              </div>
            ) : null}
          </div>
          <div className='form-row'>
            <div className='form-group col-md-7'>
              <label className='tit-input'>
                Sitio Web<span className='text-danger'> *</span>
              </label>
              <input
                type='text'
                name='sitioWeb'
                className={
                  formik.errors.sitioWeb
                    ? 'form-control form-info-corredorError'
                    : 'form-control form-info-corredor'
                }
                placeholder='misitioweb.com'
                onChange={formik.handleChange}
                value={formik.values.sitioWeb}
              />
            </div>
            {formik.errors.sitioWeb ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.sitioWeb}
              </div>
            ) : null}
          </div>
          <div className='form-row'>
            <div className='form-group col-md-7'>
              <label className='tit-input'>
                Usos de interés<span className='text-danger'> *</span>
              </label>
            </div>
            {errorCheck ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                Este campo es obligatorio
              </div>
            ) : null}
            <div className='form-group col-md-7'>
              <div
                className={
                  EstadoBotones.active1
                    ? 'btn btnSelecUsoInteresSelec'
                    : 'btn btnSelecUsoInteres'
                }
                onClick={handleClick}
                id='1'
              >
                VIS
              </div>
              <div
                className={
                  EstadoBotones.active2
                    ? 'btn btnSelecUsoInteresSelec'
                    : 'btn btnSelecUsoInteres'
                }
                onClick={handleClick}
                id='2'
              >
                No Vis
              </div>
              <div
                className={
                  EstadoBotones.active3
                    ? 'btn btnSelecUsoInteresSelec'
                    : 'btn btnSelecUsoInteres'
                }
                onClick={handleClick}
                id='3'
              >
                Industrial
              </div>
              <div
                className={
                  EstadoBotones.active4
                    ? 'btn btnSelecUsoInteresSelec'
                    : 'btn btnSelecUsoInteres'
                }
                onClick={handleClick}
                id='4'
              >
                Dotacional
              </div>
              <div
                className={
                  EstadoBotones.active5
                    ? 'btn btnSelecUsoInteresSelec'
                    : 'btn btnSelecUsoInteres'
                }
                onClick={handleClick}
                id='5'
              >
                Comercial
              </div>
              {!EstadoBotones.active6 ? (
                <div
                  className='btn btnSelecUsoInteres'
                  onClick={handleClick}
                  id='6'
                >
                  Otro
                </div>
              ) : (
                <div className='other-interest-container'>
                  <div className='btn btnSelecUsoInteresSelec other-interest'>
                    <input
                      type='text'
                      name='otherInterest'
                      className='form-input-other-interest'
                      placeholder='Otro uso'
                      autoFocus
                      onChange={formik.handleChange}
                      value={formik.values.otherInterest}
                    />
                    <span
                      className='close-other-interest'
                      onClick={handleClick}
                      id='6'
                    >
                      x
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='container sectionBtnCorredor'>
          <div className='row'>
            <div className='col'>
              <button
                className='btn btn-volver'
                onClick={() => {
                  setStep(2)
                }}
              >
                Volver
              </button>
            </div>
            <div className='col btn-holder'>
              <button
                type='submit'
                className={
                  formik.isValid
                    ? 'btn btn-endNext'
                    : 'btn btn-endNextOtro disabled-link'
                }
              >
                Siguiente
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default InfoEmpresarial
