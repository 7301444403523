import React from "react";
import Spinner from '../../assets/spinners/spinner.svg'

export default function Loading({isVisible}) {
  
  return(
    <div 
      style={{
      width: '100%', 
      zIndex: 1100,
      position: 'fixed',
      display: 'inline-block',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0, 0, 0, 0.542)',
      }}
      hidden={!isVisible}
    >
        <img src={Spinner} />
    </div>
  )
}