import HttpResponseEmun from '../helpers/enums/HttpResponseEmun';
import Request from './Request'

export const getNotification = async (page=0,notifiedParam='NO') => {
  try {
    let response;
    if(notifiedParam){
      response = await Request.get(`/notifications?showRows=${5}&page=${page}&notifiedParam=${notifiedParam}`)      
    }else{
      response = await Request.get(`/notifications?showRows=${5}&page=${page}`)
    }
    if (response.status === HttpResponseEmun.OK) {
      return {
        page:response['data']['page'],
        notifications:response['data']['content'],
        totalPages:response['data']['totalPages'],
        totalElements:response['data']['totalElements'],
      }
    }else{
      return {
        page:0,
        notifications:[],
        totalPages:0,
        totalElements:0,
      }
    }
    
  } catch (error) {
    console.error(error)
  }
  return false
}
export const readNotification = async (id) =>{
  try {
    const response = await Request.get(`/notifications/${id}`);
    return true;        
  } catch (error) {
    console.error(error)
  }
  return false
}