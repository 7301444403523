import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useRegister } from '../../context/RegisterContext'

import './InfoPersonal1.css'

const infoPersonal1 = () => {
  const { setStep, userToRegister, setUserToRegister } = useRegister()

  const onSubmit = (formData) => {
    setUserToRegister({
      ...userToRegister,
      names: formData.nombre,
      lastNames: formData.apellido,
      phone: formData.telefono,
      alternativeEmail: formData.correo,
    })
    setStep(3)
  }

  const validationSchema = Yup.object().shape({
    nombre: Yup.string().required('Este campo es obligatorio'),
    apellido: Yup.string().required('Este campo es obligatorio'),
    telefono: Yup.string().required('Este campo es obligatorio'),
    correo: Yup.string().required('Este campo es obligatorio'),
  })

  const formik = useFormik({
    initialValues: {
      nombre: userToRegister.names,
      apellido: userToRegister.lastNames,
      telefono: userToRegister.phone,
      correo: userToRegister.alternativeEmail,
    },

    onSubmit,

    validationSchema,
  })

  let permiteGuardar = false
  if (
    formik.values.nombre !== '' &&
    formik.values.apellido !== '' &&
    formik.values.telefono !== '' &&
    formik.values.correo !== ''
  ) {
    permiteGuardar = true
  }

  return (
    <div className='developer-personal-info'>
      <div className='container-fluid container-global'>
        <form onSubmit={formik.handleSubmit}>
          <div className='container block-from'>
            <div className='form-row'>
              <div className='form-group col-md-7'>
                <label className='tit-input'>
                  Nombre<span className='text-danger'> *</span>
                </label>
                <input
                  type='text'
                  name='nombre'
                  className={
                    formik.errors.nombre
                      ? 'form-control form-info-corredorError'
                      : 'form-control form-info-corredor'
                  }
                  placeholder='Jonh'
                  onChange={formik.handleChange}
                  value={formik.values.nombre}
                />
              </div>
              {formik.errors.nombre ? (
                <div className='form-label Mensaje-ErrorCorredor'>
                  {formik.errors.nombre}
                </div>
              ) : null}
            </div>
            <div className='form-row'>
              <div className='form-group col-md-7'>
                <label className='tit-input'>
                  Apellido<span className='text-danger'> *</span>
                </label>
                <input
                  type='text'
                  name='apellido'
                  className={
                    formik.errors.apellido
                      ? 'form-control form-info-corredorError'
                      : 'form-control form-info-corredor'
                  }
                  placeholder='Doe'
                  onChange={formik.handleChange}
                  value={formik.values.apellido}
                />
              </div>
              {formik.errors.apellido ? (
                <div className='form-label Mensaje-ErrorCorredor'>
                  {formik.errors.apellido}
                </div>
              ) : null}
            </div>
            <div className='form-row'>
              <div className='form-group col-md-7'>
                <label className='tit-input'>
                  Teléfono de contacto<span className='text-danger'> *</span>
                </label>
                <input
                  type='number'
                  name='telefono'
                  className={
                    formik.errors.telefono
                      ? 'form-control form-info-corredorError'
                      : 'form-control form-info-corredor'
                  }
                  placeholder='(+57)(xxx) xxx xx xx'
                  onChange={formik.handleChange}
                  value={formik.values.telefono}
                />
              </div>
              {formik.errors.telefono ? (
                <div className='form-label Mensaje-ErrorCorredor'>
                  {formik.errors.telefono}
                </div>
              ) : null}
            </div>
            <div className='form-row'>
              <div className='form-group col-md-7'>
                <label className='tit-input'>
                  Correo alternativo<span className='text-danger'> *</span>
                </label>
                <label className='tit-input-correoal'>
                  (Lo usaremos para confirmar tu identidad en caso de que
                  pierdas el correo original)
                </label>
                <input
                  type='email'
                  name='correo'
                  className={
                    formik.errors.correo
                      ? 'form-control form-info-corredorError'
                      : 'form-control form-info-corredor'
                  }
                  placeholder='micorreo@alternativo.com'
                  onChange={formik.handleChange}
                  value={formik.values.correo}
                />
              </div>
              {formik.errors.correo ? (
                <div className='form-label Mensaje-ErrorCorredor'>
                  {formik.errors.correo}
                </div>
              ) : null}
            </div>
          </div>

          <div className='container sectionBtnCorredor'>
            <div className='row'>
              <div className='col'>
                <button
                  className='btn btn-volver'
                  onClick={() => {
                    setStep(1)
                  }}
                >
                  Volver
                </button>
              </div>
              <div className='col btn-holder'>
                <button
                  type='submit'
                  className={
                    permiteGuardar
                      ? 'btn btn-endNext'
                      : 'btn btn-endNextOtro disabled-link'
                  }
                >
                  Siguiente
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default infoPersonal1
