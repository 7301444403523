import HttpResponseEmun from '../helpers/enums/HttpResponseEmun'
import Request from './Request'
export default function getProfilePicture(id, filename, model) {

const fetchData = async() => {
    try {
        const response = await Request.get(
          `/file/${id}/${filename}`,
          {
            model: model,
          },
          { responseType: 'blob' }
        )
        if (response.status === HttpResponseEmun.OK) {
          const blob = response.data
          const url = window.URL.createObjectURL(blob)
          return url
        }
      } catch (error) {
        console.error(error)
      }
    }

    return fetchData()
}