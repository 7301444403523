import React from 'react';
import { message, Modal, Tooltip } from 'antd';
import { UnlockTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
import { associateStates, changeAssociateStatus } from '../../services/allies';
import './UnblockButton.css';

const { confirm } = Modal;


function UnblockButton({userId, name, refresh, style}) {

    const blockUser = async () => {
        try {
            await changeAssociateStatus(userId, associateStates.activeState);
            message.success(`El usuario ${name} ha sido bloqueado correctamente`);
            refresh();
        } catch (error) {
            message.error(`Hubo un error al bloquear el usuario ${name}`);
        }
    }

    const showConfirm = (title,description,onConfirm) => {
        confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            content: description,
            cancelText:'Cancelar',
            okText:'Continuar',
            onOk() {
            onConfirm();
            },
        
            onCancel() {
            console.log('Cancel');
            },
        });
    };

    const blockButtonHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();

        showConfirm(
            `¿Quieres desbloquear a ${name}?`,
            `¿Estás seguro de que desea realizar esta acción?`,
            blockUser
        );
    }

    return (
        <Tooltip title="Desbloquear">
            <UnlockTwoTone style={style} onClick={blockButtonHandler} className='stop-icon' twoToneColor="#198754" />                 
        </Tooltip>
    )
}

export default UnblockButton;