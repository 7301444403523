import React, {useContext, useState, useEffect, useRef} from 'react'
import {InfoCircleOutlined} from '@ant-design/icons'
import {Context} from '../../../../../../context/lotsContext'
import NumberFormat from "react-number-format";
import {businessType, treatment, zone } from '../../../../../../helpers/enums/LotsEnum'
import { Col, Row, Select, Switch, Tooltip } from 'antd';
const { Option } = Select;

const selectBusinessType = Object.keys(businessType).map(function (key) {return businessType[key]});
const selectTreatment = Object.keys(treatment).map(function (key) {return treatment[key]});
const selectZone = Object.keys(zone).map(function (key) {return zone[key]});

const GeneralInformation = ({next}) => {
  const {
    state: { dataEditLots, step, dataToUpdateLot},
    dispatch,
  } = useContext(Context)
  const [lotData, setLotData] = useState({...dataEditLots})
  

  const onChange = (e) => {
    if(e.target.name === 'interestUse'){
      setLotData({...lotData, interestUse: {...lotData.interestUse, [e.target.fieldName]: e.target.value}})
      dispatch({type: 'SET_DATA_TO_UPDATE_LOT', payload: {interestUse: {...lotData.interestUse, [e.target.fieldName]: e.target.value}}})
    }else{
         setLotData({...lotData, [e.target.name]: e.target.value})
         dispatch({type: 'SET_DATA_TO_UPDATE_LOT', payload: {[e.target.name]: e.target.value}})
    }
  }
  
useEffect(() => {
  let verifiInterestUse = false
  for (const key in lotData.interestUse) {
    if (lotData.interestUse[key] !== false && lotData.interestUse[key] !== '') {
      verifiInterestUse = true
    }
  }
  if(
    lotData.aliasLand !== '' && 
    lotData.area !== '' && 
    lotData.metersPrice !== '' && 
    lotData.businessType !== '' && 
    lotData.treatment !== '' && 
    lotData.zone !== '' &&
    verifiInterestUse){
      dispatch({type: 'SET_LOT_DATA_EDIT', payload: lotData})
      next(step)
}else{
  next(step, 'delete')

}
}, [lotData])

const calc = () => {
   let a = lotData.metersPrice
  let b = lotData.area
   let res = a * b
   return res || '0'
}
const auto_grow = (e) => {
  e.target.style.height = "5px";
  e.target.style.height = (e.target.scrollHeight)+"px";
}

  return (
    <div className='container-general-information'>
      <div className='container-fluid container-global'>
          
    <Row>
      <Col xs={{ span: 24}} md={{ span: 10}}>
              <div className='form-row'>
                <div className='form-group'>
                    <label className='tit-input'>
                      Nombre (Alias) {' '} 
                    </label>
                    <Tooltip title='Nombre personalizado del lote'>
                      <InfoCircleOutlined style={{fontSize: '15px', marginLeft: '5px'}}/>
                    </Tooltip>
                      <span className='text-danger'> *</span>
                  <input
                    type='text'
                    name='aliasLand'
                    className= 'form-control form-info-corredor'
                    onChange={onChange}
                    value={lotData.aliasLand}
                    placeholder='Escribe un nombre personalizado'
                  />
                </div>
              </div>
              <div className='form-row'>
                <div className='form-group'>
                  <label className='tit-input'>
                  Área del lote (en metros cuadrados) m²
                  <span className='text-danger'> *</span>
                  </label>
                  <NumberFormat 
                    type='text' 
                    name='area'
                    className='form-control form-info-corredor'
                    placeholder='50.000'
                    value={lotData.area} 
                    thousandSeparator={'.'} 
                    decimalSeparator={','} 
                    prefix={''}
                    onValueChange={(e, i)=> onChange({target:{name: 'area', value: e.floatValue}})}
                  />
                </div>
              </div>
              
              <div className='form-row'>
                <div className='form-group'>
                  <label className='tit-input'>
                  Precio del lote por metro cuadrado ($COP) 
                  <span className='text-danger'> *</span>
                  </label>
                  <NumberFormat 
                    type='text'
                    name='metersPrice'
                    className= 'form-control form-info-corredor'
                    placeholder='$ 50.000'
                    value={lotData.metersPrice}
                    thousandSeparator={'.'} 
                    decimalSeparator={','} 
                    prefix={'$ '} 
                    onValueChange={(e, i)=> onChange({target:{name: 'metersPrice', value: e.floatValue}})}
                  />
                </div>
              </div>
              <div className='form-row'>
                <div className='form-group'>
                  <label className='tit-input'>
                    Precio total del lote ($COP)
                    <span className='text-danger'> *</span>
                  </label>
                  <NumberFormat
                    disabled 
                    type='text'
                    className= 'form-control form-info-corredor'
                    placeholder='$ 0'
                    value={calc()}
                    thousandSeparator={'.'} 
                    decimalSeparator={','} 
                    prefix={'$ '} 
                  />
                </div>
              </div>
          </Col>
          <Col xs={{ span: 24}} md={{ span: 10, offset: 4 }}>
              <div className='form-row'>
                <div className='form-group'>
                    <label className='tit-input'>
                      Uso del Lote (Tratamiento) 
                      <span className='text-danger'> *</span>
                    </label>
                    <Select className='form-control form-info-corredor'
                      name='treatment'
                      bordered={false}
                      onChange={(e)=>onChange({target:{name: 'treatment', value: e,}})}
                      placeholder='Seleccione una opción'
                      style={{padding: '0.375rem 0.75rem', height: '39px'}}
                      defaultValue={lotData.treatment}
                     >
                     { selectTreatment.map((item, i) => {
                     return(
                      <Option key={i} value={item.type}>{item.name}</Option>
                      )})}
                    </Select>
                </div>
              </div>
              <div className='form-row'>
                <div className='form-group'>
                  <label className='tit-input'>
                    Uso del Lote(Zona)
                    <span className='text-danger'> *</span>
                  </label>
                  <Select className='form-control form-info-corredor'
                      name='zone'
                      bordered={false}
                      placeholder='Seleccione una opción'
                      style={{padding: '0.375rem 0.75rem', height: '39px'}}
                      onChange={(e)=>onChange({target:{name: 'zone', value: e,}})}
                      defaultValue={lotData.zone}
                     >
                      { selectZone.map((item, i) => {
                        return(
                      <Option key={i} value={item.type}>{item.name}</Option>
                      )})} 
                    </Select>
                </div>
              </div>
              <div className='form-row'>
                <div className='form-group'>
                  <label className='tit-input'>
                    Tipo de negocio
                    <span className='text-danger'> *</span>
                  </label>
                  <Select className='form-control form-info-corredor'
                      name='businessType'
                      bordered={false}
                      placeholder='Seleccione una opción'
                      style={{padding: '0.375rem 0.75rem', height: '39px'}}
                      onChange={(e)=>onChange({target:{name: 'businessType', value: e,}})}
                      defaultValue={lotData.businessType}
                     >
                      { selectBusinessType.map((item, i) => {
                      return(
                        <Option key={i} value={item.type}>{item.name}</Option>
                        )})} 
                  </Select>
                </div>
              </div>
          </Col>
        </Row>
        <Row>
          <Col xs={{span: 12}} md={{span: 12}}>
                  <div className='form-row' >
                    <div className='form-group'>
                      <label className='tit-input'>
                      Usos de interés 
                      <span className='text-danger'> *</span>
                      </label>
                        <div className="container-checkbox">
                            <div className="cat">
                              <label>
                                  <input defaultChecked={lotData.interestUse.vis} className='input-checkbox' onChange={(e)=>onChange({target:{fieldName: 'vis', name: 'interestUse', value: e.target.checked}})} type="checkbox" /><span>VIS</span>
                              </label>
                            </div>

                            <div className="cat">
                              <label>
                                  <input defaultChecked={lotData.interestUse.noVis} className='input-checkbox' onChange={(e)=>onChange({target:{fieldName: 'noVis', name: 'interestUse', value: e.target.checked}})} type="checkbox"/><span>No Vis</span>
                              </label>
                            </div>

                            <div className="cat">
                              <label>
                                  <input defaultChecked={lotData.interestUse.industrial} className='input-checkbox' onChange={(e)=>onChange({target:{fieldName: 'industrial', name: 'interestUse', value: e.target.checked}})} type="checkbox" /><span>Industrial</span>
                              </label>
                            </div>

                            <div className="cat">
                              <label>
                                  <input defaultChecked={lotData.interestUse.dotacional} className='input-checkbox' onChange={(e)=>onChange({target:{fieldName: 'dotacional', name: 'interestUse', value: e.target.checked}})} type="checkbox"/><span>Dotacional</span>
                              </label>
                            </div>

                            <div className="cat">
                              <label>
                                  <input defaultChecked={lotData.interestUse.commercial} className='input-checkbox' onChange={(e)=>onChange({target:{fieldName: 'commercial', name: 'interestUse', value: e.target.checked}})} type="checkbox"/><span>Comercial</span>
                              </label>
                            </div>

                            <div className="cat">
                                  <label>
                                      <input className={(lotData.interestUse.others !== null && lotData.interestUse.others !== '') ? `input-text-active` : 'input-text'} defaultValue={lotData.interestUse.others} onChange={(e)=>onChange({target:{fieldName: 'others', name: 'interestUse', value: e.target.value}})} placeholder='Otro' type="text" />
                                  </label>
                            </div>

                        </div>
                        {/* <!-- end container --> */}
                    </div>
                  </div>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24}} md={{ span: 24}}>
          <div className='form-row'>
                <div className='form-group'>
                  <label className='tit-input'>
                    Observaciones
                  </label>
                  <textarea
                    style={{resize: 'none', overflow: 'hidden'}}
                    rows="2" 
                    cols="30"
                    name='observations'
                    className='form-control form-info-corredor'
                    placeholder='Escriba aqui alguna observación que pueda ser útil para el desarrollador'
                    onChange={onChange}
                    value={lotData.observations}
                    onInput={auto_grow}
                  />
                </div>
              </div>
              
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default GeneralInformation
