import React, { createContext, useEffect, useState } from 'react'
import Request from '../services/Request'
const parametersRange ={
    _id:'',
    isValid:false,
    startRange:0,
    endRange:0,
    name:'',
    state:'ACTIVE'
}

const Context = createContext()
const RangeProvider = ({ children }) => {  
  const [parameters,setParameters] = useState(parametersRange); 
  const [messageError,setMessagError] = useState(''); 
  const [valid,setValid] = useState(false);


  const setRange = (params)=>{    
    setValues(params);           
  }  
 
  const setValues = (newValue)=>{    
    const auxtParam = parameters;
    for (let key in auxtParam){
      if(newValue[key]){
        auxtParam[key] = newValue[key];
      }
    }    
    setParameters({...auxtParam});    
    validateParameters();
  }

  const validateParameters = ()=>{    
    setValid(true);
    if(parameters.name.length <= 0){
      setMessagError('El nombre no puede estar vacio')
      setValid(false);
    }
    if(parameters.startRange < 0){
      setMessagError('El rango inicial debe iniciar desde 0 en adelante')
      setValid(false);
    }
    if(parameters.endRange <= 0){
      setMessagError('El rango final no debe ser 0 ')
      setValid(false);
    }
    if(parameters.state.length <= 0){
      setMessagError('Debe seleccionar un estado ')
      setValid(false);
    } 
    if(parameters.startRange >= parameters.endRange){
      setMessagError('El rango inicial siempre debe ser menor que el rango final ')
      setValid(false);
    }          
  }

  const isValid = ()=>{
    return valid;
  }
  const clear = ()=>{
    setParameters(parametersRange);
  }

  return <Context.Provider value={{ messageError,parameters, setRange,setParameters,validateParameters,isValid,clear }}>{children}</Context.Provider>
}

export { Context, RangeProvider }
