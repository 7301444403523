import HttpResponseEmun from '../helpers/enums/HttpResponseEmun';
import Request from '../services/Request';


export const verifyCode = async (code) => {
    try {
        const res = await Request.get("/roles/by-code/"+code);

        if(res.status === HttpResponseEmun.NotFound){
            return {message: `El código "${code}" está disponible `, unique: true};
        }
        if(res.status === HttpResponseEmun.OK){
            return {message: "Este código ya existe", unique: false};
        }
    } catch (error) {
        return {message: "Error al validar el código", unique: false};
    }
}

export const updateRolPermissions = async (rolId, permissions) => {
    try {
        const res = await Request.put(`/roles/${rolId}/assign-permissions`, {permissions});
        return res;
    } catch (error) {
        if(error) console.error(error);
        return error;
    }
}

export const getRolById = async (rolId) => {
    try {
        const res = await Request.get(`/roles/${rolId}`);
        return res;
    } catch (error) {
        if(error) console.error(error);
        return error;
    }
}