import React, { useState, useEffect, createContext, useContext, useMemo } from 'react';
import { useAuth } from './AuthContext';
import { getAllPermissions } from '../services/permission';
import HttpResponseEmun from '../helpers/enums/HttpResponseEmun';


// Testing enum
const permissionsEnum = {"EED":false, "EEE":false};

export const PermissionsContext = createContext();

export const usePermissions = () => {
  const context = useContext(PermissionsContext);
  if (!context) throw new Error('There is no Permission provider');

  const [contextValue, setContextValues] = useState({
    permissions: permissionsEnum,
    isLoadingPermissions: false
  });


  useEffect(() => {

    // Passing the same values as context, but this way
    // The values will be detected by VS code TypeScript

    setContextValues({
      permissions: context.permissions, 
      isLoadingPermissions: context.isLoadingPermissions
    });

  }, [context]);

  return contextValue;
}

export function PermissionsProvider({ children }) {

  const [isLoadingPermissions, setIsLoadingPermissions] = useState(true);
  const [permissions, setPermissions] = useState(permissionsEnum);
  const [permissionsData, setPermissionsData] = useState([]);

  const { userData, loadingUser } = useAuth();


  const getPermissions = async () => {
    const response = await getAllPermissions();
    if(response.status === HttpResponseEmun.OK){
        setPermissionsData(response.data.content.map(item => ({...item, key: item._id})));
    }
  }

  useEffect(() => {
    if(userData && userData.permissions) {
      getPermissions();
    }
  }, [userData]);

  useEffect(() => {


      if(userData === null){
        setPermissions(permissionsEnum);
        setIsLoadingPermissions(loadingUser);
        return;
      }

      if(userData.permissions && permissionsData.length > 0 && isLoadingPermissions === true){

        const permissionsKeyList = userData.permissions.map(permissionId => {
          return permissionsData.find(permissionData => permissionData._id === permissionId).code;
        });

        let newPermissionsData = permissionsEnum;

        for (const permissionKey of permissionsKeyList) {
          newPermissionsData[permissionKey] = true;
        }

        setPermissions(newPermissionsData);

        setIsLoadingPermissions(false);
      }
  }, [userData, loadingUser, permissionsData]);

  const contextValue = useMemo(() => ({
    permissions,
    isLoadingPermissions,
  }), [permissions, isLoadingPermissions]);

  return (
    <PermissionsContext.Provider value={contextValue}>{children}</PermissionsContext.Provider>
  )
}
