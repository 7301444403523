import React from 'react'
import { useEffect, useState } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import routes from '../routes/auth'
import RegisterProvider from '../context/RegisterContext'

import Page404 from '../pages/404'

function Auth() {
  const { user } = useAuth()
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    if (user) {
      setRedirect(true)
    }
  }, [user])

  return (
    <div>
      {redirect && <Redirect to='/app' />}
      <RegisterProvider>
        <div>
          <Switch>
            {routes.map((route, i) => {
              return route.component ? (
                <Route
                  key={i}
                  exact={true}
                  path={`/auth${route.path}`}
                  render={(props) => <route.component {...props} />}
                />
              ) : null
            })}
            <Redirect exact path='/auth' to='/auth/login' />
            <Route component={Page404} />
          </Switch>
        </div>
      </RegisterProvider>
    </div>
  )
}

export default Auth
