import Request from './Request';

export default async function getFormatLocation(searchParamCity) {
   
    try {
        const response = await Request.get("/geographical-area?ordering=city,asc&showRows=999", {searchParam: searchParamCity});
        return response;       
        
    } catch (error) {
        error && console.error(error)
        return error
    }
    
  }