import React, { useEffect, useRef, useState } from 'react'
import SwitchButton from '../../components/Switch/SwitchButton'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useAuth } from '../../context/AuthContext'
import useModals from '../../hooks/useModals'
import { updateBroker } from '../../services/brokers'
import useToasts from '../../hooks/useToasts'
import ChangePassword from '../ChangePassword'

import './FormEditCorredor.css';
import departamentos from '../../helpers/states'

const FormEditCorredor = () => {
  const { user, userData, refreshUser} = useAuth()
  const [isToggled, setIsToggled] = useState(userData && userData.isPublic !== false)
  const { openConfirmationMessage } = useModals()
  const { addToast } = useToasts()
  const [changePasswordToggle, setChangePasswordToggle] = useState(false)

  const ref = useRef();

  const [cityArray, setCityArray] = useState({
    cities: [],
  })

  const onSubmit = (formData) => {
    const userDataToUpdate = {
      names: formData.nombre,
      lastNames: formData.apellido,
      phone: formData.telefono,
      website: formData.website,
      description: formData.description,
      city: formData.city,
      isPublic: isToggled,
      email: formData.email !== userData.email ? formData.email : undefined,
    //  identification: formData.identificacion,
    }

    openConfirmationMessage({
      message: '¿Quieres guardar los cambios?',
      onConfirm: () => {
        updateBroker(user.id, userDataToUpdate)
          .then((response) => {
            addToast({
              type: 'success',
              title: '¡Éxito!',
              message: 'Se han guardado los cambios',
            })
            refreshUser()
          })
          .catch((e) => {
            addToast({
              type: 'error',
              title: 'Error',
              message: 'No se pudieron guardar los cambios',
            })
          })
      },
    })
  }

  const validationSchema = Yup.object().shape({
    nombre: Yup.string().required('Este campo es obligatorio'),
    apellido: Yup.string().required('Este campo es obligatorio'),
    email: Yup.string().required('Este campo es obligatorio'),
    city: Yup.string().required('Este campo es obligatorio'),
  })

  const formik = useFormik({
    initialValues: {
      nombre: (userData && userData.names) || '',
      apellido: (userData && userData.lastNames) || '',
      telefono: (userData && userData.phone) || '',
      email: (userData && userData.email) || '',
      website: (userData && userData.website) || '',
      description: (userData && userData.description) || '',
      city: (userData && userData.city) || '',
      //   identification: (userData && userData.identification) || '',
    },
    enableReinitialize: true,

    onSubmit,

    validationSchema,
  })

  const auto_grow = (e) => {
    e.target.style.height = "3.30em";
    e.target.style.height = (e.target.scrollHeight)+"px";
  }

  useEffect(() => {
    if(ref.current){
      auto_grow({target: ref.current});
    }
  }, []);

  useEffect(() => {

    const cities = [];
      departamentos.forEach(depar => {
        cities.push(...depar.ciudades);
      });
      setCityArray({
        cities: cities,
    });

  }, []);

  return (
    <div className='form-edit-corredor'>
      <form onSubmit={formik.handleSubmit}>
        <div className='row'>
          <div className='col-sm-6'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label className='tit-input titEditCorredor'>
                  Nombre<span className='text-danger'> *</span>
                </label>
                <input
                  type='text'
                  name='nombre'
                  className={
                    formik.errors.nombre
                      ? 'form-control form-info-corredorError'
                      : 'form-control form-info-corredor'
                  }
                  placeholder='Jonh'
                  onChange={formik.handleChange}
                  value={formik.values.nombre}
                />
              </div>
            </div>
            {formik.errors.nombre ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.nombre}
              </div>
            ) : null}
          </div>
          <div className='col-sm-6'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label className='tit-input titEditCorredor'>
                  Apellido<span className='text-danger'> *</span>
                </label>
                <input
                  type='text'
                  name='apellido'
                  className={
                    formik.errors.apellido
                      ? 'form-control form-info-corredorError'
                      : 'form-control form-info-corredor'
                  }
                  placeholder='Jonh'
                  onChange={formik.handleChange}
                  value={formik.values.apellido}
                />
              </div>
            </div>
            {formik.errors.apellido ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.apellido}
              </div>
            ) : null}
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-6'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label className='tit-input titEditCorredor'>Correo</label>
                <input
                  type='text'
                  name='email'
                  className={
                    formik.errors.email
                      ? 'form-control form-info-corredorError'
                      : 'form-control form-info-corredor'
                  }
                  placeholder='Admin@DoDes.com'
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              </div>
            </div>
            {formik.errors.email ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.email}
              </div>
            ) : null}
          </div>


          <div className='col-sm-6'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label className='tit-input titEditCorredor'>
                  Teléfono de contacto<span className='text-danger'> *</span>
                </label>
                <input
                  type='number'
                  name='telefono'
                  className={
                    formik.errors.telefono
                      ? 'form-control form-info-corredorError'
                      : 'form-control form-info-corredor'
                  }
                  placeholder='3005479854'
                  onChange={formik.handleChange}
                  value={formik.values.telefono}
                />
              </div>
            </div>
            {formik.errors.telefono ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.telefono}
              </div>
            ) : null}
          </div>


          <div className='col-sm-6'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label className='tit-input titEditCorredor'>
                  Sitio Web
                </label>
                <input
                  type='text'
                  name='website'
                  className={
                    formik.errors.website
                      ? 'form-control form-info-corredorError'
                      : 'form-control form-info-corredor'
                  }
                  placeholder='misitioweb.com'
                  onChange={formik.handleChange}
                  value={formik.values.website}
                />
              </div>
            </div>
            {formik.errors.website ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.website}
              </div>
            ) : null}
          </div>


          <div className='col-sm-6'>
          <div className='form-row'>
            <div className='form-group col-md-10'>
              <label className='tit-input'>
                Ciudad<span className='text-danger'> *</span>
              </label>
              <select
                name='city'
                className={
                  formik.errors.city
                    ? 'form-select form-info-corredorError'
                    : 'form-select form-info-corredor'
                }
                onChange={formik.handleChange}
                value={formik.values.city}
              >
                <option value=''>Seleccione</option>
                {cityArray.cities.map((city, k) => (
                  <option key={k} value={city.name}>
                    {' '}
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {formik.errors.city ? (
            <div className='form-label Mensaje-ErrorCorredor'>
              {formik.errors.city}
            </div>
          ) : null}
        </div>


        <div className='col-sm-6'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label className='tit-input titEditCorredor'>
                  Descripción y experiencia
                </label>
                <textarea
                  type='text'
                  name='description'
                  className={
                    formik.errors.description
                      ? 'form-control form-info-corredorError'
                      : 'form-control form-info-corredor'
                  }
                  placeholder='Descripción y experiencia'
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  onInput={auto_grow}
                  ref={ref}
                />
              </div>
            </div>
            {formik.errors.description ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.description}
              </div>
            ) : null}
          </div>




        </div>

        {/* <div className='row'>
          <div className='col-sm-6'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label className='tit-input titEditCorredor'>
                  NIT o Cedula<span className='text-danger'> *</span>
                </label>
                <input
                  type='text'
                  name='identification'
                  className={
                    formik.errors.correoAlternativo
                      ? 'form-control form-info-corredorError'
                      : 'form-control form-info-corredor'
                  }
                  placeholder='NIT o Cedula'
                  onChange={formik.handleChange}
                  value={formik.values.correoAlternativo}
                />
              </div>
            </div>
            {formik.errors.correoAlternativo ? (
              <div className='form-label Mensaje-ErrorCorredor'>
                {formik.errors.correoAlternativo}
              </div>
            ) : null}
          </div>
          <div className='col-sm-6'></div>
        </div> */}

        <div className='row'>
          <div className='col'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label
                  className='tit-input titEditContrasena'
                  role='button'
                  onClick={() => {
                    setChangePasswordToggle((last) => !last)
                  }}
                >
                  Cambiar contraseña
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <ChangePassword
              show={changePasswordToggle}
              setShow={setChangePasswordToggle}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <hr className='LineaFormCo'></hr>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label className='tit-input'>Perfil público</label>
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <div className='form-row'>
              <div className='form-group col-md-10 tit-PerfilP'>
                <SwitchButton
                  isToggled={isToggled}
                  onToggle={() => setIsToggled(!isToggled)}
                />{' '}
                Quiero que mi perfil sea público y que los desarrolladores
                puedan ver mi información
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <div className='form-row'>
              <div className='form-group col-md-10'>
                <label className='tit-input'>Notificaciones de cambio</label>
                <p className='tit-msg'>
                  Quiero que me notifiquen cuando haya cambios en la plataforma
                  por medio del
                </p>
                <div className='control-group'>
                  <label className='control control-radio'>
                    Correo Email
                    <input type='radio' name='notiCam' defaultChecked />
                    <div className='control_indicator'></div>
                  </label>
                  <label className='control control-radio'>
                    Perfil Urban 360
                    <input type='radio' name='notiCam' />
                    <div className='control_indicator'></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <div className='col btn-holderSiguienteCenter'>
              <button type='submit' className='btn btn-endNext'>
                GUARDAR CAMBIOS
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default FormEditCorredor
