import React, {useState, useMemo} from 'react'
import '../CustomInputLand/index.css'
import ShowFile from '../../pages/Search/ShowFile/ShowFile'
import { Col, Row } from 'antd'
import shortText from '../../helpers/shortText'

import './LandFileTypeView.css'

const mandatoryFiles = [
  {
    filename: 'Permiso del propietario para comercializar el Terreno',
    property: 'permisoPropietarioDoc',
  },
  {
    filename: 'Información topográfica',
    property: 'informacionTopografiaDoc',
  },
  {
    filename: 'Escrituras',
    property: 'escriturasDoc',
  },
  {
    filename: 'Certificado de Tradición y Libertad',
    property: 'certificadoTradicionDoc',
  },
];

export default function LandFileTypeView({land}) {

  const [checked, setChecked] = useState(false);

  const fields = useMemo(() => {

    const mandatoryFields = mandatoryFiles.map(file => {
      return ({
        name: file.filename,
        type: 'file',
        value: land[file.property]
      })
    });

    return [...mandatoryFields, ...land.fields];

  }, [land]);

  return <>

  <Row className='rows' style={{marginBottom: '0px'}}>
          <div className="container-collapse">
            <div className="accordions">
              <div className="accordion">
                <input type="checkbox" onChange={(e)=> {setChecked(e.target.checked)}} id="second" />
                <label htmlFor="second" className="acc-label py-0">
                  Ver archivos
                  <div className='bullet-point'></div>
                </label>
                <div className="acc-content2">
                {fields && fields.length > 0 && <Row className="my-2">
                  {(fields.map((field, i) => {
                      return <Col key={i} className='col m-0 align-items-end' style={{minHeight: '6rem'}} xs={12} sm={8} md={8}>
                        <h6 title={field.name}>{shortText(field.name, 25)}</h6>
                        {field.type !== 'file' && 
                        <span title={field.value}>{field.value && shortText(field.value, 25)}</span>}
                        {field.type === 'file' &&
                        <ShowFile id={land._id} model="Lote" filename={field.value} />}
                      </Col>
                  }))}
                  </Row>}
                </div>
              </div>
            </div>
          </div>
      </Row>

    </>

}