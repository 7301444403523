import React, {useEffect, useState,useRef, useContext} from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import Request from '../../services/Request';
import './Notifications.css';
import iconUser  from  '../../assets/icons/ellipse2.png'
import {getNotification,readNotification} from '../../services/notifications';
import List from '@material-ui/core/List'; 
import ListItem from '@material-ui/core/ListItem'; 
import { DateTime } from 'luxon';
import { Context } from '../../context/lotsContext';
import { useAuth } from '../../context/AuthContext';
import UserModelsEnum from '../../helpers/enums/UserModelsEnum';

import Loading from '../../components/Loading/Loading'
import DetailsModal from '../../pages/Search/DetailsModal';
import { ChatContext } from '../../context/ChatContext';
import getLand from '../../services/getLand';
import getLandRequest from '../../services/getLandRequest';
import getChatById from '../../services/getChatById';

const NotificationTypes = { 
    DEVELOPER_CREATED : "DEVELOPER_CREATED",
    DEVELOPER_UPDATED : "DEVELOPER_UPDATED", 
    DEVELOPER_ACCEPT : "DEVELOPER_ACCEPT",
    DEVELOPER_REJECT : "DEVELOPER_REJECT",
    DEVELOPER_DISABLED : "DEVELOPER_DISABLED",
    DEVELOPER_ENABLED : "DEVELOPER_ENABLED",

    BROKER_CREATED : "BROKER_CREATED",
    BROKER_UPDATED : "BROKER_UPDATED",
    BROKER_DISABLED : "BROKER_DISABLED",
    BROKER_ENABLED : "BROKER_ENABLED",

    LAND_CREATED : "LAND_CREATED",
    LAND_UPDATED : "LAND_UPDATED",
    LAND_MATCH_REQUEST : "LAND_MATCH_REQUEST", // SOLICITUD
    LAND_CHANGE_STATUS : "LAND_CHANGE_STATUS",
    LAND_REQUEST_CHANGE_STATE_OFFER : "LAND_REQUEST_CHANGE_STATE_OFFER",

    LAND_REQUEST_CREATED : "LAND_REQUEST_CREATED",
    LAND_REQUEST_UPDATED : "LAND_REQUEST_UPDATED",
    LAND_REQUEST_MATCH_LAND : "LAND_REQUEST_MATCH_LAND", // LOTES
    LAND_REQUEST_CHANGE_STATUS : "LAND_REQUEST_CHANGE_STATUS", // offertId
    
    USER_CHANGE_PASSWORD : "USER_CHANGE_PASSWORD",
    USER_UPDATE_PROFILE : "USER_UPDATE_PROFILE",

    LAND_OFFERED_REQUEST : "LAND_OFFERED_REQUEST",


    LAND_NOTES_REMINDER: "LAND_NOTES_REMINDER",

    NEW_MENSSAGE : "NEW_MENSSAGE"
}

const Notifications = ({isOpen,isClose,toggleParam, notiToUpdate}) => {    
    const [showLoading, setShowLoading] = useState(false);
    const [notiListRead,setNotiListRead]     = useState([])
    const [pageRead,setPageRead]             = useState(0);    
    const [totalPagesRead,setTotalPagesRead] = useState(0);
    const [showDetails, setShowDetails] = useState({show: false, request: {}});

    const {setCurrentLandRequest, setCurrentLandSelect, setBrokerAssociate, setDeveloperAssociate, createAssociateChat } = useContext(ChatContext);
    
    const closeModal = () => {
        setShowDetails({show: false, request: {}})
    }
    
    const [notiList,setNotiList] = useState([]);
    const [page,setPage] = useState(0);    
    const [totalPages,setTotalPages] = useState(0);    
    const listInnerRef = useRef();
    const history = useHistory();

    const {user} = useAuth();
    const {dispatch} = useContext(Context);

    //const { createAssociateChat, setBrokerAssociate, setDeveloperAssociate } = useContext(ChatContext);

    useEffect(() => {
        isClose(true);
        setNotiList([]);
        setNotiListRead([]);
        setPageRead(0);
        setTotalPagesRead(0);
        setPage(0);
        setTotalPages(0);
        isClose(false);
    }, [notiToUpdate]);

    useEffect(() => {
        if(isOpen && notiList.length === 0){
            handlePagination(0,null);
        }
    },[toggleParam])

    const handlePagination = (nextPage,notifiedParam='NO') =>{
        if(notifiedParam){
            getNotification(nextPage,notifiedParam).then(
                    (data)=>{
                        handleNotificationItem(data,nextPage,notifiedParam);
                    }
                )
        }else {            
            getNotification(nextPage,null).then(
                    (data)=>{
                        handleNotificationItem(data,nextPage,null);
                    }
                )
        }
    }

    const handleNotificationItem = (data,notifiedParam) => {
        if(notifiedParam === 'NO'){
            handleNotificationUnRead(data);
        }else if(notifiedParam === 'YES'){
            handleNotificationRead(data)
        }else{
            handleNotification(data)
        }
               
    }

    const handleNotificationUnRead = (data)=>{
        
        if(data['notifications'].length > 0) {
            if(notiList.length <= 0){   
                        
                setNotiList(data['notifications']);
                setTotalPages(data['totalPages']);
                setPage(data['page']); 
                if(notiList.length < 5){
                    handlePagination(0,'YES');
                }                       
            }else{
                const aux = notiList;
                setNotiList(aux.concat(data['notifications']));            
                setTotalPages(data['totalPages']);
                setPage(data['page']);
            }
        }else {
            handlePagination(pageRead,'YES');
        }
        
    }
    const handleNotificationRead = (data)=>{
        if(data['notifications'].length > 0){
                       if(notiList.length <= 0){ 
                          
                setNotiListRead(data['notifications']);
                setTotalPagesRead(data['totalPages']);
                setPageRead(data['page']);

                setNotiList(data['notifications']);
                setTotalPages(data['totalPages']);
                setPage(data['page']);                        
            }else{
                          
                const aux = notiList;
                setNotiListRead(aux.concat(data['notifications']))
                setTotalPagesRead(data['totalPages']);
                setPageRead(data['page'])
                
                setNotiList(notiListRead);
            
            }
        }
    }

    const handleNotification = (data)=>{
        if(notiList.length <= 0){   
                    
            setNotiList(data['notifications']);
            setTotalPages(data['totalPages']);
            setPage(data['page']);                                 
        }else{
            const aux = notiList;
            setNotiList(aux.concat(data['notifications']));            
            setTotalPages(data['totalPages']);
            setPage(data['page']);
        }
    }
    const onScroll = () => {
                      if (listInnerRef.current) {
          const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
          if (scrollTop + clientHeight === scrollHeight) {
            if(page < totalPages){
                const newPage = page + 1;
                handlePagination(newPage,null);
            }
          }
        }
    };

    const handleDate = (date) => {
        const notificationDate = new Date(date);
        const today = new Date();

        if(today.getDay() === notificationDate.getDate()){
            const hora = `Hoy ${DateTime.fromJSDate(notificationDate).toFormat(`h:mm a`)}`;
            return (<p>{hora}</p>)
        }else{
            const fecha = `${DateTime.fromJSDate(notificationDate).toFormat(`dd/MM/yyyy`)}`;
            return (<p>{fecha}</p>)
        }

    }
    
    const handleClickNotification = async (notification) => {
        const type          = notification['type'];
        const isNotified    = notification['notified'];
        const id            = notification['_id'];
        const parameters    = notification['parameters'];
        
        if(!isNotified){
            readNotification(id);

            setNotiList(prev => {
                return prev.map(noti => {
                    if(noti._id === id) return {...noti, notified: true}
                    else return noti;
                })
            })
        }


        switch (type) { //Interacciones Aca
            case NotificationTypes.LAND_MATCH_REQUEST:{
                if(!parameters['lands']){
                    landsMatchHandle(parameters['landRequestId'], parameters['id']);
                } else {
                    landsMatchHandle(parameters['id'], parameters['lands'][0]);
                }
                break;
            }
            case NotificationTypes.LAND_CREATED:
            case NotificationTypes.LAND_UPDATED:
            case NotificationTypes.LAND_CHANGE_STATUS:{ 
                if(user.model === UserModelsEnum.Broker) landsHandle(parameters['id']);
                else if(user.model === UserModelsEnum.Developer && parameters['landRequestId']){
                    landRequestsMatchHandle(parameters['id'], parameters['landRequestId'], true);
                }
                else if(user.model === UserModelsEnum.Developer) {
                    landsHandleAsDeveloper(parameters['id'], true);
                }
                break;
            }
            case NotificationTypes.LAND_NOTES_REMINDER:{
                landsHandle(parameters['id']);
                break;
            }
            case NotificationTypes.LAND_OFFERED_REQUEST:
            case NotificationTypes.LAND_REQUEST_MATCH_LAND:{
                landRequestsMatchHandle(parameters['id'], parameters['landRequestId']);    
                //landsHandle(parameters['id'], "land-requests", "Mis solicitudes");
                break;
            }
            case NotificationTypes.LAND_REQUEST_CREATED:
            case NotificationTypes.LAND_REQUEST_UPDATED:
            case NotificationTypes.LAND_REQUEST_CHANGE_STATUS:
                landRequestsHandle(parameters['id']);

            case NotificationTypes.LAND_REQUEST_CHANGE_STATE_OFFER:
                const landId = parameters['landId'];
                const landRequestId = parameters['landRequestId'];
                landChangeStatusHandler(landId, landRequestId);
            case NotificationTypes.NEW_MENSSAGE:
                const chatId = parameters['chatId'];
                openChat(chatId);
            default:
                setTimeout(() => setShowLoading(false), 500);
                break;
        }

        setShowLoading(true);
        isClose(false);
    }   

    const openChat = async (chatId) => {

        try {
            const chat = (await getChatById(chatId)).data;
            if(chat.land && chat.landRequest){
                setCurrentLandSelect(chat.land);
                setCurrentLandRequest(chat.landRequest);
                return null;
            }
            if(chat.broker && chat.developer){
                setBrokerAssociate(chat.broker);
                setDeveloperAssociate({...chat.developer, associateType: chat.developer._id});
                createAssociateChat();
            }
        } catch (err) {
            console.error(err)
        }

    }

    const landChangeStatusHandler = async (landId, landRequestId) => {
        try {
            const land = (await getLand(landId)).data;
            const landRequest = (await getLandRequest(landRequestId)).data;
            setCurrentLandSelect(land);
            setCurrentLandRequest(landRequest);
        } catch (error) {
            console.error(error);
        }
    }

    const landsHandleAsDeveloper = async (landId, showStatus = false) =>{
        try{
            const land = (await Request.get(`/lands/${landId}`))['data'];
            land.user = {};
            const landData = {data: land, landRequestsId: '', offerId: '', offerState: 'No disponible', lotRequestState: land.state, showStatus };

            dispatch(
                {
                    type: 'SET_IS_VISIBLE',
                    payload:{
                        toggleState:{
                            type: "land-requests",
                            label: "Mis solicitudes"
                        },
                        option:true,
                        type:'details',
                        lot: landData
                    }
                }
            )
            /*if(response['data']){
                history.push('/app/home');
            }*/
        }catch(err){
            console.error(err);
        } finally {
            setShowLoading(false);
        }
    }

    const landsHandle = async (landId, type = "lands", label = "Mis lotes") =>{
        try{
            const response = await Request.get(`/lands/${landId}`);
            dispatch(
                {
                    type: user.model === UserModelsEnum.Broker ? 'SET_IS_VISIBLE_HB' : 'SET_IS_VISIBLE',
                    payload:{
                        toggleState:{
                            type: type,
                            label: label
                        },
                        option:true,
                        type:'details',
                        lot: user.model === UserModelsEnum.Broker ? response['data'] : response
                    }
                }
            )
            if(response['data']){
                history.push('/app/home');
            }
        }catch(err){
            console.error('Error');
        } finally {
            setShowLoading(false);
        }
    }

    const landRequestsMatchHandle = async (landId, landRequestId, showStatus = false) =>{
        try{
            //const responseLand = (await Request.get(`/lands/${landId}`))['data'];
            const responseRequest = await Request.get(`/land-requests/${landRequestId}`);
            const response = await Request.get(`/land-requests/${landRequestId}/lands-offered`, {stateParam: 'IN_ANALYSIS,IN_FEASIBILITY,IN_NEGOTIATION,BOUGHT,RETIRED,NOT_AVAILABLE,REFUSED,ACCEPTED'});
            const land = response.data.content.filter(el => el.land._id === landId)[0];
            if(!land) return landsHandleAsDeveloper(landId);
            const landData = {data: land.land, landRequestsId: responseRequest.data._id, offerId: land._id, offerState: land.state, lotRequestState: responseRequest.data.state, showStatus };

            dispatch(
                {
                    type: 'SET_IS_VISIBLE',
                    payload:{
                        toggleState:{
                            type: "land-requests",
                            label: "Mis solicitudes"
                        },
                        option:true,
                        type:'details',
                        lot: landData
                    }
                }
            )
            if(response['data']){
                history.push('/app/home');
            }
        }catch(err){
            console.error(err);
        } finally {
            setShowLoading(false);
        }
    }

    const landRequestsHandle = async (landRequestId, type = "land-requests", label = "Mis solicitudes") =>{
        try{
            const response = await Request.get(`/land-requests/${landRequestId}`);
            dispatch(
                {
                    type: 'SET_IS_VISIBLE',
                    payload:{
                        toggleState:{
                            type: type,
                            label: label
                        },
                        option:true,
                        type:'request-details',
                        landRequest: response.data
                    }
                }
            )
            if(response['data']){
                history.push('/app/home');
            }
        }catch(err){
            console.error('Error');
        } finally {
            setShowLoading(false);
        }
    }

    const landsMatchHandle = async (landRequestId, landId) =>{
        try{
            const response = await Request.get(`/land-requests/${landRequestId}`);
            const responseLand = await Request.get(`/lands/${landId}`);
            const developer = await Request.get(`/developers/${response.data.associateType}`);
            setShowDetails({show: true, land: responseLand.data, request: {...response.data, developer: developer.data.data}});
            /*if(response['data']){
                history.push('/app/home');
            }*/
        }catch(err){
            console.error('Error');
        } finally {
            setShowLoading(false);
        }
    }




    return (
        <>
        <Loading isVisible={showLoading}/>
        <DetailsModal modalLoading={false} showDetails={showDetails} 
        closeModal={closeModal} />
        <Modal show={isOpen} onHide={isClose} dialogClassName="NotificacionesModalPadre" backdropClassName="fondoNotifi" contentClassName="contNoti" >
            <Modal.Header className="modalNotificacionesTit headerModalNoti">
                <Modal.Title className="titNoti stilostextoTiNoti">
                    <span className="notification--title d-flex justify-content-between align-items-center">Notificaciones <CloseOutlined className='d-block text-white d-md-none' onClick={() => isClose(false)} /></span>
                </Modal.Title>
            </Modal.Header>
            
                        
            <Modal.Body className="Notificaciones">
                <List className="list--notification container contai-SemiFluid"
                    onScroll={onScroll}
                    ref={listInnerRef}
                >
                    {notiList.map((noti,ixNoti)=>
                        <ListItem button={true} className="row mt-2 item" key={ixNoti} 
                        onClick={() => { handleClickNotification(noti)    }}>
                                <div className="col-2 centrado" >
                                    <img src ={ iconUser } alt="icon" className='img-userNoti'/>
                                </div>
                                <div className="col-10 information">
                                    {
                                        <div className="time">                                    
                                            {handleDate(noti["createdAt"])}                                
                                        </div>
                                    }
                                    {
                                        (!noti['notified'] && <p className="tit-noti">{noti.title}</p>) 
                                    }
                                    {
                                        (noti['notified'] && <p className="tit-noti-read read">{noti.title}</p>) 
                                    }
                                    <p className="tit-subtitu">
                                        {noti.message}
                                    </p>                                    
                                </div>                                                                                       
                                                                                                                       
                        </ListItem>
                    )}
                </List>
            </Modal.Body>
                
        </Modal>
        </>
    )
}

export default Notifications
