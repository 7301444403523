/**
 * 
 * @param {*} text string
 * @description text to cut
 * @param {*} nc number
 * @description maximum number of characters
 * @returns 
 */
const shortText = (text, nc) => {
    if(text.length > nc){
        const string = text.slice(0, nc).concat('...');
        return string
    }else{
        return text
    }
     
    
}

export default shortText