import React from 'react';
import agregarIcon from '../../../assets/icons/agregar.png';

function CreateButtonRow({title, onClick}) {
    return (<tr >
        <td colSpan="7">
            <div className="titlefiltro">
                <div style={{margin: "0"}} className="col titleRight">

                    <label style={{margin: "1rem"}} onClick={onClick} className="title-input titleEditNewUser">
                        <img src={agregarIcon} className="iconADD" alt="" />
                        {title}
                    </label>
                </div>
            </div>
        </td>
      </tr>);
}

export default CreateButtonRow
