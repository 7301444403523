import HttpResponseEmun from '../helpers/enums/HttpResponseEmun'
import Request from './Request'
export default function getLandFile(path) {

const fetchData = async() => {
    try {
        const response = await Request.get(
          `/file/${path}`,
          {
            model: 'Lote',
          },
          { responseType: 'blob' }
        )
        if (response.status === HttpResponseEmun.OK) {
          const blob = response.data
          const url = window.URL.createObjectURL(blob)
          return url
        }
      } catch (error) {
        console.error(error)
      }
    }

    return fetchData()
}