import Profile from '../pages/Profile'
import ProfileVisit from '../pages/Profile-visite'
import Chat from '../pages/Chat'
import Home from '../pages/Home'
import Verification from '../pages/Verification/Verification'
import Photo from '../pages/Photo/Photo'
import Page404 from '../pages/404'
import AdminPanel from '../pages/AdminPanel/AdminPanel'
import Search from '../pages/Search/Search'
import AssociatedBrokers from '../pages/AssociatedBrokers/AssociatedBrokers'
import FaqPage from '../pages/FaqPage'

/**
 * ⚠ These are internal routes!
 * They will be rendered inside the app, using the default `containers/Layout`.
 *
 */
const routes = [
  {
    path: '/home',
    component: Home,
  },
  {
    path: '/admin',
    component: AdminPanel,
  },
  {
    path: '/admin/requests',
    component: AdminPanel,
  },
  {
    path: '/admin/setting',
    component: AdminPanel,
  },
  {
    path: '/search',
    component: Search,
  },
  {
    path: '/brokers',
    component: AssociatedBrokers,
  },
  {
    path: '/profile',
    component: Profile,
  },
  {
    path: '/profile/:id',
    component: ProfileVisit,
  },
  {
    path: '/chat',
    component: Chat,
  },
  {
    path: '/verification',
    component: Verification,
  },
  {
    path: '/photo',
    component: Photo,
  },
  {
    path: '/404',
    component: Page404,
  },
  {
    path: '/faq',
    component: FaqPage,
  },
]

export default routes
