import HttpResponseEmun from '../../../../helpers/enums/HttpResponseEmun';
import Request from '../../../../services/Request';
const ENDPOINTS = {
  range:"/ranges"
}


export const senDataRange = async (params) =>{
  const {
    name, 
    startRange,
    endRange,
    state,   
  } = params;
  try {
    let response;
    response = await Request.post(
      `${ENDPOINTS.range}`,
      {
        name, 
        startRange,
        endRange,
        state,
      }
    )
    if (response.status === HttpResponseEmun.OK) {
      return response;
    }else{
      return null;
    }

  } catch (error) {
    console.log(error);
    return error;
  }
}
export const editDataRange = async (params) =>{
  const {
    _id,
    name, 
    startRange,
    endRange,
    state,   
  } = params;  

  try {
    let response;
    response = await Request.put(
      `${ENDPOINTS.range}/${_id}`,
      {
        name, 
        startRange,
        endRange,
        state:  (state === 1 || state===0) ? (state === 1?'ACTIVE':'INACTIVE'):state,
      }
    )
    if (response.status === HttpResponseEmun.OK) {
      return response;
    }else{
      return null;
    }

  } catch (error) {
    console.log(error);
    return error;
  }
}

export const DeleteRange = async (id) =>{  
  try {
    let response;    
    response = await Request.delete(`${ENDPOINTS.range}/${id}`)      
    if (response.status === HttpResponseEmun.OK) {
      return response;
    }else{
      return null;
    }
    
  } catch (error) {
    console.error(error)
  }
  return false
}