import React, {useState, useEffect, useRef, useCallback} from 'react';

function useCellWidths(props) {
    
    const [cellWidths, setCellWidths] = useState(null);
    const parentRef = useRef();
    const childrenAmount = (props && props.childrenAmount) || 4;

    const calculateCellWidths = useCallback(() => {
    if(parentRef.current && parentRef.current.children.length > 0){
        const cellWidthsArray = [];

        for (let i = 0; i < childrenAmount; i++){
        cellWidthsArray.push(parentRef.current.children[i].clientWidth + "px");
        }

        setCellWidths(cellWidthsArray);
    }
    }, [parentRef.current, setCellWidths]);

    useEffect(() => {
    window.addEventListener("resize", calculateCellWidths);
    return () => {
        window.removeEventListener("resize", calculateCellWidths);
    }
    }, []);

    useEffect(() => {
    if(parentRef.current) calculateCellWidths();
    }, [parentRef.current, calculateCellWidths]);

    return {parentRef, cellWidths};

}

export default useCellWidths;