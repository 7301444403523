import React from 'react'
import './Register.css'
import { Row, Col } from 'react-bootstrap'

import LadoIzquierdo from '../../components/LadoIzquierdo/LadoIzquierdo'
import LadoDerecho from '../../components/LadoDerecho/LadoDerecho'

const Register = (props) => {
  return (
    <div className='register'>
      <div className='container-fluid'>
        <Row>
          <Col className='ladoIzquierdo' lg={6}>
            <LadoIzquierdo />
          </Col>
          <div className='CirculoMedio'>ó</div>
          <Col className='ladoDerecho' lg={6}>
            <LadoDerecho />
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default Register
