import React, {useState, useEffect} from 'react';
import ProfileDeveloper from '../../components/ProfileDeveloper';
import FormEditDesarrollador from '../../components/FormEditDesarrollador/FormEditDesarrollador'
import FormEditCorredor from '../../components/FormEditCorredor/FormEditCorredor'
import FormEditSUDOADMIN from '../../components/FormEditSUDO&ADMIN';

import UserModelsEnum from '../../helpers/enums/UserModelsEnum'
import DeveloperStatesEnum from '../../helpers/enums/DeveloperStatesEnum'
import { Modal } from 'antd'
import { WarningOutlined } from '@material-ui/icons'
import { ButtonApp } from '../../components/button/button';
import {
  useParams
} from "react-router-dom";
import Request from '../../services/Request'
import ProfileBroker from '../../components/ProfileBroker';
import useToasts from '../../hooks/useToasts';

export default function ProfileVisit() {
  const params = useParams();
  const { user, userData } = useState(null);
  const [visible, setVisible] = useState(false);
  const [userType, setUserType] = useState('');
  const { addToast } = useToasts()

  useEffect(()=>{
    if(userData && userData.state === DeveloperStatesEnum.Validation){
      setVisible(true)
    }else{
      setVisible(false)
    }
    
  }, [userData])
  const handleVisible = () =>{
    setVisible(false)
  }
  const getProfile = async () =>{
    const resp = await Request.get(`/users/${params.id}/profile/user`);
    if(resp.status === 200){
      setUserType(resp.data.type);
    }else{
      return addToast({
        type: 'error',
        title: 'Error al obtener usuario',
        message: `Se ha producido un error al obtener la información de este usuario`,
      });
    }
  }
  useEffect(()=>{    
    getProfile();
  }, [])

   const warning = () => {
    Modal.warning({
      title: 'Apreciado desarrollador.',
      content: 'Usted se encuentra en el proceso de validación URBAN-360, esto puede tomar aproximadamente de 3 a 4 días. Tan pronto se surta el proceso se notificará su correo electrónico',
    });
  };
  return (
    <div className='profile'>

      {userType === UserModelsEnum.Developer ? <ProfileDeveloper developerId={params.id} /> : null}
      {userType === UserModelsEnum.Broker ? <ProfileBroker brokerId={params.id} /> : null}

      {user && (user.model === UserModelsEnum.SUDO || user.model === UserModelsEnum.ADMIN) && <FormEditSUDOADMIN />}
      {user && user.model === UserModelsEnum.Developer && <FormEditDesarrollador />}
      {user && user.model === UserModelsEnum.Broker && <FormEditCorredor />}

      {userData && userData.state === DeveloperStatesEnum.Validation && 
      <Modal
      closable={false}
      visible={visible} 
      footer={
        <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ButtonApp content='Ok' primary onClick={handleVisible} />
        </div>
      } 
      warning> 
      <div style={{display: 'flex'}}>
        <WarningOutlined style={{color: '#FFA900', marginRight: '5px'}}/>
        <h5>Apreciado desarrollador</h5>
      </div>
      <p>
        'Usted se encuentra en el proceso de validación URBAN-360, 
        esto puede tomar aproximadamente de 3 a 4 días. 
        Tan pronto se surta el proceso se notificará su correo electrónico' 
      </p>
      </Modal>}
    </div>
  )
}
