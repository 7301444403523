import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import getLands from '../../../services/getLands';
import getLandsOffered from '../../../services/getLandsOffered';
import './index.css'
import { MenuOutlined, SettingOutlined } from '@ant-design/icons';
import TableList from './TableList/TableList.jsx';
import { useAuth } from '../../../context/AuthContext';
import {
  AppstoreOutlined,
} from '@ant-design/icons';
import {Context} from '../../../context/lotsContext';
import { Menu, message } from 'antd';
import { ThemeContext } from "../../../context/ThemeContext";
import HttpResponseEmun from "../../../helpers/enums/HttpResponseEmun";
const AdminPanelRequests = () => {
    const [filter, setFilter] = useState({page: 0, showRows: 10, statusParam: 'ACTIVE' });
    const [loading, setLoading] = useState(false)
    const {user, userData, loadingUser} = useAuth()
    const {themeColor} = useContext(ThemeContext)
    const {
        state: { isVisibleHB },
        dispatch,
      } = useContext(Context);

    const fetchDataLandsCallback = useCallback(
        async () => {
            const response = isVisibleHB.toggleState.type === 'lands' ? await getLands(filter, userData._id) : await getLandsOffered(filter, userData._id);
            if (response.status === HttpResponseEmun.OK) {
                dispatch({ type: 'SET_CURRENT_LOTS', payload: {type: isVisibleHB.toggleState.type, ...response.data} });
            }else{
                message.error('Error al cargar los lotes')
            }
            setLoading(false);
        },
    [filter, userData, isVisibleHB.toggleState.type]);

    useEffect(() => {
      setLoading(true)
      dispatch({ type: 'RESET_CURRENT_LOTS'});

      if(userData && !loadingUser && isVisibleHB.toggleState.type === 'lands'){
        fetchDataLandsCallback();
      }

      if(userData && !loadingUser && isVisibleHB.toggleState.type === 'offers'){
        fetchDataLandsCallback();
      }
       
  }, [isVisibleHB.toggleState.type, userData, loadingUser, filter]);

  const offerTabHandler = () => {
      if(isVisibleHB.toggleState.type !== "offers") setFilter({page: 0, showRows: 10, statusParam: 'ACTIVE' });
      dispatch({ type: 'SET_IS_VISIBLE_HB', payload: {...isVisibleHB, toggleState: {type: "offers", label: "Mis Ofertas"}}});
  };

  const landsTabHandler = () => {
    if(isVisibleHB.toggleState.type !== "lands") setFilter({page: 0, showRows: 10, statusParam: 'ACTIVE' });
    dispatch({ type: 'SET_IS_VISIBLE_HB', payload: {...isVisibleHB, toggleState: {type: "lands", label: "Mis Lotes"}}});
  };

    return (
    <>
        <div className="container">

            <div className="container containerListAndSearchAdmin">
                <div className="blocTabs">

                    <button
                        className={isVisibleHB.toggleState.type === "offers" ? "tabsListAdminPanel active-tabsListAdminPanel" : "tabsListAdminPanel"}
                        onClick={offerTabHandler}
                    >
                        <div className={isVisibleHB.toggleState.type === "offers" ? "boxBtnSelec-b" : "boxBtn"}                          
                        >Mis Ofertas</div>
                    </button>
                    <button
                        className={isVisibleHB.toggleState.type === "lands" ? "tabsListAdminPanel active-tabsListAdminPanel" : "tabsListAdminPanel"}
                        onClick={landsTabHandler}
                    >
                        <div className={isVisibleHB.toggleState.type === "lands" ? "boxBtnSelec-b2" : "boxBtn"}                        
                        >Mis Lotes</div>
                    </button>
                </div>

            </div>
            <div className='containerListResponsive'>
                <h6 style={{color: 'white', minWidth: 'fit-content', margin: '5px 20px'}}>{isVisibleHB.toggleState.label}</h6>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'end', minHeight: '40px'}}>
                    
                <Menu theme='dark' className='menu' style={{backgroundColor:"#272A30", color: "#fff", borderBottom: 0, minHeight: '40px', minWidth: '50px'}} mode="horizontal" >
                  
                  <Menu.SubMenu key="0" icon={<MenuOutlined />}>
                    <Menu.Item onClick={offerTabHandler} key={'1'} icon={<AppstoreOutlined />}>
                    Mis Ofertas
                    </Menu.Item>
                    <Menu.Item onClick={landsTabHandler} key={'2'}  icon={<AppstoreOutlined />}>
                    Mis Lotes
                    </Menu.Item>
                  </Menu.SubMenu>
                </Menu>
                </div>
            </div>
            <div className="container p-0 container-listUser">
                    <TableList filter={filter} setFilter={setFilter}/>
            </div>
           
        </div>
    </>
    )
}

export default AdminPanelRequests