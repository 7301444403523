import Axios from 'axios'
import HttpResponseEmun from '../helpers/enums/HttpResponseEmun'

export default {
  get: async (url, params = {}, options = {}) => {
    const headers =
      localStorage.getItem('authorization') &&
      localStorage.getItem('authorization') !== 'null'
        ? { authorization: localStorage.getItem('authorization') }
        : {}
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}${url}`, {
      headers,
      params,
      validateStatus: function (status) {
        return status < HttpResponseEmun.InternalServerError // Resolve only if the status code is less than 500
      },
      ...options,
    })
    return response
  },
  post: async (url, payload, options = {}) => {
    const headers =
      localStorage.getItem('authorization') &&
      localStorage.getItem('authorization') !== 'null'
        ? { authorization: localStorage.getItem('authorization') }
        : {}
    const response = await Axios.post(
      `${process.env.REACT_APP_API_URL}${url}`,
      payload,
      {
        headers,
        validateStatus: function (status) {
          return status < HttpResponseEmun.InternalServerError // Resolve only if the status code is less than 500
        },
        ...options,
      }
    )
    return response
  },
  put: async (url, payload, options = {}) => {
    const headers =
      localStorage.getItem('authorization') &&
      localStorage.getItem('authorization') !== 'null'
        ? { authorization: localStorage.getItem('authorization') }
        : {}
    const response = await Axios.put(
      `${process.env.REACT_APP_API_URL}${url}`,
      payload,
      {
        headers,
        validateStatus: function (status) {
          return status < HttpResponseEmun.InternalServerError // Resolve only if the status code is less than 500
        },
      }
    )
    return response
  },
  delete: async (url, params = {}, options = {}) => {
    const headers =
      localStorage.getItem('authorization') &&
      localStorage.getItem('authorization') !== 'null'
        ? { authorization: localStorage.getItem('authorization') }
        : {}
    const response = await Axios.delete(
      `${process.env.REACT_APP_API_URL}${url}`,
      {
        headers,
        params,
        validateStatus: function (status) {
          return status < HttpResponseEmun.InternalServerError // Resolve only if the status code is less than 500
        },
        ...options,
      }
    )
    return response
  },
}
