import React, { useState, useEffect, createContext, useContext } from 'react'
import { login as loginService } from '../services/auth'
import { getDeveloper } from '../services/developers'
import { getBrokerUser, getBrokerData } from '../services/brokers'
import { GetUserAdmin } from '../services/userAdmin'
import UserModelsEnum from '../helpers/enums/UserModelsEnum'
import { ThemeContext } from './ThemeContext'
import { SocketContext } from './socketIO';
import io from 'socket.io-client';
export const AuthContext = createContext()

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) throw new Error('There is no Auth provider')
  return context
}

export default function AuthProvider({ children }) {
  const [user, setUser] = useState(null)
  const [userData, setUserData] = useState(null)
  const [loadingUser, setLoadingUser] = useState(true)
  const {setThemeColor} = useContext(ThemeContext)
  const {connect,disconnect,setSocket} = useContext(SocketContext);
  

  const login = async ({ email, password }) => {
    const response = await loginService(email, password)
    if (response) {            
      setUser(response)
      setThemeColor(JSON.parse(localStorage.getItem('theme')))
      setSocket( (io(
        process.env.REACT_APP_SOCKET_URL,
        {
          extraHeaders: {
            "authorization": localStorage.getItem('authorization')?
            localStorage.getItem('authorization'):'',
          },    
        }
      )));
      return true
    }
    return false
  }

  const logout = () => {
    disconnect();
    setUser(null)
    setUserData(null)
    localStorage.removeItem('authorization')
    localStorage.removeItem('user')
    localStorage.removeItem('theme')
  }

  const refreshUser = async () => {
    setUser(JSON.parse(localStorage.getItem('user')))
  }  

  useEffect(() => {
    const user = localStorage.getItem('user')
    if (user) {
      setUser(JSON.parse(user))
    }
  }, [])

  useEffect(() => {
    const accessToken = localStorage.getItem('authorization')
    const userBasicInfo = localStorage.getItem('user')
    if (accessToken && userBasicInfo) {
      const data = JSON.parse(userBasicInfo)
      if (data.model === UserModelsEnum.ADMIN) {
        GetUserAdmin(data.id).then((userAdmin) => {
          if (userAdmin) {
            setUserData({ ...userAdmin })
            setLoadingUser(false)
          } else {
            logout()
          }
        })}else if (data.model === UserModelsEnum.SUDO) {
        GetUserAdmin(data.id).then((userAdmin) => {
          if (userAdmin) {
            setUserData({ ...userAdmin })
            setLoadingUser(false)
          } else {
            logout()
          }
        })
      } else if (data.model === UserModelsEnum.Developer) {
        getDeveloper(data.id).then((developer) => {
          if (developer) {
            setUserData({ ...developer })
            setLoadingUser(false)
          } else {
            logout()
          }
        })
      } else {
        getBrokerUser(data.id)
          .then((broker) => {
            if (broker) {
              setUserData((lastUserData) => {
                return { ...lastUserData, ...broker }
              })
              return getBrokerData(broker.associateType)
            } else {
              logout()
            }
          })
          .then((brokerData) => {
            if (brokerData) {
              setUserData((lastUserData) => {
                return { ...lastUserData, ...brokerData }
              })
              setLoadingUser(false)
            } else {
              logout()
            }
          })
      }
    } else {
      setLoadingUser(false)
      logout()
    }
  }, [user])

  const contextValue = {
    login,
    user,
    userData,
    setUser,
    setUserData,
    logout,
    loadingUser,
    refreshUser,
  }

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}
