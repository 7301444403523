import React, { useState } from 'react';
import Spinner from '../../assets/spinners/spinner.svg';
import { Modal, Tooltip } from 'antd';
import { ButtonApp } from '../button/button';
import useToasts from '../../hooks/useToasts';
import { CloseOutlined } from '@ant-design/icons';
import sendSupportMessage from '../../services/supports';
import HttpResponseEmun from '../../helpers/enums/HttpResponseEmun';

import './index.css';


const initialMsgValue = {subject: '', message: ''};


const SupportModal = ({show, handleClose}) => {

  const { addToast } = useToasts()

  const [showSpinner, setShowSpinner] = useState(false);
  const [msg, setMsg] = useState(initialMsgValue);

  const handleSubmit = async () => {

    if(!msg.subject) return addToast({
      type: 'warning',
      title: 'Asunto requerido',
      message: ' Por favor, asegúrate de completar el campo del asunto antes de enviar el mensaje.',
    });

    if(!msg.message) return addToast({
      type: 'warning',
      title: 'Mensaje requerido',
      message: 'Por favor, asegúrate de escribir un mensaje antes de enviar.'
    });

    setShowSpinner(true);

    const res = await sendSupportMessage(msg);
    
    setShowSpinner(false);

    if(res.status === HttpResponseEmun.OK){
      handleClose();
      setMsg(initialMsgValue);
      return addToast({
        type: 'success',
        title: 'Mensaje enviado!!',
        message: `Gracias por contactarnos, nos pondremos en contacto contigo pronto.`,
      });
    }

    else return addToast({
      type: 'error',
      title: 'Error al enviar el mensaje',
      message: (res && res.data && res.data.message) || '¡Ha ocurrido un error inesperado al enviar el mensaje!',
    });

  }

  const handleChange = (e) => {
    setMsg({...msg, [e.target.name]: e.target.value});
  }

  const auto_grow = (e) => {
    e.target.style.height = "3.30em";
    e.target.style.height = (e.target.scrollHeight)+"px";
  }
   
  return (
    <div >
      <div 
      style={{
        width: '100%', 
        zIndex: 1000,
        position: 'fixed',
        display: 'inline-block',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1001,
        background: 'rgba(0, 0, 0, 0.542)',
        }}
      hidden={!showSpinner}>
          <img src={Spinner} />
      </div>
      <Modal
      width={450}
      visible={show}
      onCancel={handleClose}
      closeIcon={
        <Tooltip title="Cerrar">
          <CloseOutlined style={{ fontZise: '30px', color: '#39C0ED' }}/>
        </Tooltip>
      }
      footer={
        <div className="w-100 d-flex justify-content-center align-items-center font h6">
          <span className="associateBtn"><ButtonApp onClick={handleSubmit} content='Enviar correo' primary/></span>
        </div>
      }
      >
      <h3 className='title h4 text-center my-3'>Correo a Soporte</h3>

        <div>
          <label className='subtitle--login form-label'>*Asunto</label>
        </div>
        <div className='input-group subject-input'>
          <input
            value={msg.subject}
            name='subject'
            type='text'
            placeholder='¿En qué podemos ayudarte?'
            className='form-control'
            onChange={handleChange}
          />
        </div>


        <div className='mt-3'>
          <label className='subtitle--login form-label'>*Mensaje</label>
        </div>
        <div className='input-group message-input'>
          <textarea
            value={msg.message}
            name='message'
            placeholder='Por favor danos más información'
            className='form-control'
            onInput={auto_grow}
            onChange={handleChange}
            style={{resize: 'none'}}
            ></textarea>
        </div>


      </Modal>
    </div>
  )
};

export default SupportModal;
