import React, { createContext, useState } from 'react'

const parametersArea ={
    _id:'',
    isValid:false,
    departmentParam:"", 
    cityParam:"",
    neighborhoodParam:"",
    locationParam: "",
    communeParam: "",
    UPZParam:"",
    point: []
}

const Context = createContext()
const AreatProvider = ({ children }) => {
  const [parameters,setParameters] = useState(parametersArea);
  const [departmentCenter,setDepartmentCenter] = useState('')
  const [sendActive,setSendActive] = useState(false)
  const [isValidForm,setIsValidForm] = useState(false)
  const [isCloseModal,setIsCloseModal] = useState(false)
  const [neighborhoods, setNeighborhoods] = useState([]);

  const setDepartment = (depart) => {
    setDepartmentCenter(depart);
  }
  const clearOnlyFields = ()=>{
    const points = parameters.points;
    setSendActive(false);
    setParameters({
      _id:'',
    isValid:false,
    departmentParam:"", 
    cityParam:"",
    neighborhoodParam:"",
    locationParam: "",
    communeParam: "",
    UPZParam:"", 
    point:points   
    })
  }
  const clearData = ()=>{
    setParameters({
      _id:'',
    isValid:false,
    departmentParam:"", 
    cityParam:"",
    neighborhoodParam:"",
    locationParam: "",
    communeParam: "",
    UPZParam:"",
    point: []
    });    
    setSendActive(false);
    setDepartmentCenter('');
    
  }
  const setArea = (params)=>{    
    setValues(params)           
  }  
  const setCoords = (coords)=>{
    setValues(coords)   
  }  
  const setValues = (newValue)=>{    
    const auxtParam = parameters;    
    for (let key in newValue){      
      auxtParam[key] = newValue[key];               
    }        
    setParameters(auxtParam);
    validateParameters();
  }
  const minimunField = () =>{
    return ( 
      ((parameters.locationParam && (parameters.locationParam).length > 0) ||
      (parameters.communeParam && (parameters.communeParam).length > 0)) &&
      (parameters.UPZParam && (parameters.UPZParam).length > 0) &&
      (parameters.neighborhoodParam && (parameters.neighborhoodParam).length > 0)
          
    )
  }
  const validateParameters = ()=>{
    const auxtParam = parameters;
    if(
      (parameters.departmentParam && parameters.departmentParam.length > 0) &&
      (parameters.cityParam && parameters.cityParam.length > 0) &&      
      (parameters.point && parameters.point.length >= 3) &&
      minimunField() //&& !neighborhoods.includes(parameters.neighborhoodParam)
    ){
      auxtParam.isValid = true;        
      setSendActive(true);
    }else{
      auxtParam.isValid = false;
      setSendActive(false);
    }
    setParameters(auxtParam);    
  }

  const setListNeighborhoods = (list) => {
    setNeighborhoods(list);
  }

  return <Context.Provider value={{ isCloseModal,setIsCloseModal,parameters,sendActive, setArea,setIsValidForm,setCoords,clearOnlyFields,departmentCenter,setDepartment,clearData,validateParameters, setListNeighborhoods }}>{children}</Context.Provider>
}

export { Context, AreatProvider }
