import React, {useContext, useRef} from 'react'
import { Input, InputNumber, Popover, Switch, Tooltip } from 'antd';
import { MinusOutlined, SearchOutlined } from '@ant-design/icons';

export const Filter = ({ filterUser, setFilteruser, defaultChecked, noSwitch }) => {

    
    const refSearch = useRef(null)
  const handleParams = async(e) =>{
      clearTimeout(refSearch.current)
      refSearch.current = setTimeout(async() => {
        setFilteruser({...filterUser,searchParam:e.target.value})
      }, 1000)
    
  }

    const filter = <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
        <span style={{ fontWeight: 700, fontSize: '12px' }}>Buscar</span>
        <div>
            <Tooltip placement="left" title={'Buscar'}>
                <SearchOutlined  style={{position: 'absolute', padding: '7px', pointerEvents: 'none'}}/>
                <input ref={refSearch} placeholder="Buscar" onChange={handleParams} style={{ width: '200px', paddingLeft: '24px' }} />
            </Tooltip>
        </div>

        
    </div>
    return (

        <div className="filter-container">
            <div className="filter-right">
                {!noSwitch && <div style={{ marginRight: '1rem' }}>
                    <Tooltip placement="left" title='Buscar activos/inactivos'>
                        <Switch checkedChildren="Activo" unCheckedChildren="Inactivo" onChange={(e) => { setFilteruser({ ...filterUser, statusParam: filterUser.statusParam ? (e ? 'ACTIVE' : 'INACTIVE') : undefined, stateParam: filterUser.stateParam ? (e ? "ACTIVE" : "INACTIVE") : undefined }) }} defaultChecked checked={defaultChecked === 'INACTIVE' ? false : true} />
                    </Tooltip>
                </div>}
                <Popover content={filter} placement="bottomRight" trigger="click">
                    <div style={{ cursor: 'pointer' }}>
                        <span style={{ color: '#fff' }}>Filtros</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filter" viewBox="0 0 16 16">
                            <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </div>
                </Popover>
            </div>
        </div>

    )
}