import React, { useEffect, useState, useContext } from 'react'
import { OverlayTrigger } from 'react-bootstrap'
import { useAuth } from '../../context/AuthContext'
import UserModelsEnum from '../../helpers/enums/UserModelsEnum'
import DeveloperStatesEnum from '../../helpers/enums/DeveloperStatesEnum'
import editPencil from '../../assets/icons/editPencil.png'
import verifiedIcon from './icon/verificado.png'
import validatingIcon from './icon/validating.png'
import noacceptIcon from './icon/noaccept.png'
import { uploadLOGO, getLOGO } from '../../services/developers'
import { updatePhoto, getPhoto } from '../../services/brokers'
import { Avatar, Rate, Tooltip} from 'antd'
import {  UserOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import './index.css'
import { ThemeContext } from '../../context/ThemeContext'
import Profile from '../EditProfilePicture/Profile'
import AssociateBroker from '../AssociateBroker/AssociateBroker'
import getProfilePicture from '../../services/getProfilePict'

export default function ProfileBanner() {
  const [logo, setLogo] = useState('')
  const [showCrop, setShowCrop] = useState(false)
  const [showAssociateBroker, setShowAssociateBroker] = useState(false);
  const { user, userData } = useAuth()

  const {themeColor} = useContext(ThemeContext);

  const onClick = () => {
    setShowCrop(true)
  }

  useEffect(() => {
    if(user && userData && (userData.photo || userData.logo)){
    const id = user.model === UserModelsEnum.Developer ? userData._id : user.id
    const filename = userData.logo || userData.photo
    const model = user.model === UserModelsEnum.Developer ? UserModelsEnum.Developer : UserModelsEnum.User

    console.log(id, filename, model)

    getProfilePicture(id, filename, model).then((response) => {
      if(response) {

        console.log(response)

        setLogo(response)
        }}
        )
    /* if (user && userData) {
      if (user.model === UserModelsEnum.Developer) {
        getLOGO(userData._id, userData.logo).then((logo) => {
          if (logo) {
            setLogo(logo)
          }
        })
      }
      if (user.model === UserModelsEnum.Broker) {
        getPhoto(user.id, userData.photo).then((photo) => {
          if (photo) {
            setLogo(photo)
          }
        })
      }
    } */
  }
  }, [user, userData])

  return (
    <div className='profile-banner' style={{backgroundColor:themeColor['background-color'],color:themeColor["color"]} }>
      <div className='profile-banner-img'>
        <div className='image-container'>
        <Avatar icon={<UserOutlined />} src={logo} style={{  verticalAlign: 'middle', border: '3px solid #f0f0f0' }} size={140}/>
        </div>

        <Tooltip title='Subir otra foto'>
          <div className='photo-input-container'>
              <div onClick={onClick} className='photo-input-icon'>
                <img src={editPencil} alt='descargarNube' />
              </div>
          </div>
        </Tooltip>
        
      </div>

      <div className='profile-banner-info'>
        <div className='profile-banner-info-personal'>
          {user && user.model === UserModelsEnum.Broker && (
            <Rate disabled defaultValue={0} style={{color:'#2a97d1'}} />
          )}
          <div className='profile-head'>
          <h1 className='profile-name'>
            {userData && userData.names + ' ' + userData.lastNames}
          </h1>

            {/* <Tooltip title='Cuenta no aceptada'>
            <img src={noacceptIcon} style={{width: '50px'}}/>
            </Tooltip > */}
          </div>
          
          <div className='d-flex profile-user-info'>
           {user && user.model !== UserModelsEnum.ADMIN || user && user.model !== UserModelsEnum.SUDO &&
            <h2 className='profile-business-name' style={{backgroundColor:themeColor['background-color'],color:themeColor["color"]}}>

              {user && user.model === UserModelsEnum.Developer
                ? userData && userData.businessName
                : `CC:${userData && userData.identification}`}
            </h2>}
            {user && user.model === UserModelsEnum.Developer && (
            <p style={{marginRight: '1rem'}} className='my-0'>Desarrolladora</p>
            )}
            {user && user.model === UserModelsEnum.Developer && (
              <div
                className={`profile-banner-info-verified ${
                  userData && userData.state === DeveloperStatesEnum.Validation
                    ? 'validation'
                    : userData &&
                      userData.state === DeveloperStatesEnum.Verified
                    ? 'verified'
                    : 'no-accept'
                }`}
              >
                {userData && userData.state === DeveloperStatesEnum.Validation
                  ? 'Pendiente de validación'
                  : userData && userData.state === DeveloperStatesEnum.Verified
                  ? 'Verificada'
                  : 'No verificada'}
              </div>
            )}
          </div>
        </div>
        <div className='profile-banner-info-type mt-3'>
          <h2 className={user && (user.model === UserModelsEnum.SUDO || user.model === UserModelsEnum.ADMIN) ? 'profile-type-sudo-admin' : 'profile-type'}>
            {user && user.model === UserModelsEnum.Developer
              ? 'Constructora'
              : user && user.model === UserModelsEnum.SUDO
              ? 'Super Administrador'
              : user && user.model === UserModelsEnum.ADMIN
              ? 'Administrador'
              :'Comisionista'}
          </h2>
          {user && user.model === UserModelsEnum.Developer && (
            <button onClick={() => setShowAssociateBroker(true)} className='profile-banner-asociate-account'>
              Asociar cuenta de corredor
            </button>
          )}
        </div>
      </div>
      <AssociateBroker show={showAssociateBroker} handleClose={() => setShowAssociateBroker(false)}></AssociateBroker>
      <Profile currentPhotoURL={logo} setShowCrop={setShowCrop} showCrop={showCrop} setLogo={setLogo}/>
    </div>
  )
}
