import React, { useEffect, useState, useContext } from 'react';
import { Divider, message, Table, Typography } from 'antd';
import Request from '../../../../services/Request';
import { ButtonApp } from '../../../button/button';
import {Context} from '../../../../context/lotsContext';
import { useAuth } from '../../../../context/AuthContext';
import getLandRequests from '../../../../services/getLandRequests'
import HttpResponseEmun from '../../../../helpers/enums/HttpResponseEmun';

const { Title } = Typography;
const columns = [
  {
    title: 'Codigo',
    dataIndex: 'registrationNumber',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Alias',
    dataIndex: 'aliasLand',
    render: (text) => <a>{text}</a>,
  },
];


export default function CloseLot({props, setShowSpinner, filter}) {
  const [selectionType, setSelectionType] = useState('checkbox');
  const [lots, setLots] = useState({content:[]});
  const [filterCL, setFilterCL] = useState({page: 0, showRows: 10});
  const [dataToSend, setDataToSend] = useState([]);
  const {state:{isVisible}, dispatch} = useContext(Context)
  const {userData} = useAuth()

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    setDataToSend(selectedRows.map((item)=> item.offerId))
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled User',
    // Column configuration not to be checked
    name: record.name,
  }),
};

  useEffect(() => {
    const getLot = async()=>{
        setLots({content:[]})
        setShowSpinner(true)
        const res = await Request.get(`/land-requests/${props.landRequestsId}/lands-offered`, {...filterCL, stateParam: 'IN_NEGOTIATION'})
        if(res.status === HttpResponseEmun.OK){
            setLots({...res.data, content: res.data.content.map((item, i) => {
                return {key: i, offerId: item._id, lotId: item.land._id, aliasLand: item.land.aliasLand, registrationNumber: item.land.registrationNumber}
            })})
            setShowSpinner(false)
        }else{
            setShowSpinner(false)
        }
        
      }
      if(props.landRequestsId){

          getLot()
      }
  }, [props.landRequestsId])

  const onClick = async() =>{
    if(dataToSend.length > 0){
      setShowSpinner(true)
          const res = await Request.post(`/land-requests/${props.landRequestsId}/close-request`, {lands: dataToSend})
          if(res.status === HttpResponseEmun.OK){
              const response = await getLandRequests(filter, userData._id)
              if (response.status === HttpResponseEmun.OK) {
                dispatch({ type: 'SET_CURRENT_LOTS_REQUESTS', payload: response.data })
                }else{
                  message.error('Ocurrió un error al obtener las solicitudes')
                }
            message.success('Solicitud cerrada con éxito')
            onClose()
            setShowSpinner(false)
        }else{
            message.error('Ocurrio un error al intentar cerrar la solicitud')
            setShowSpinner(false)
        }
    }else{
      message.warning('Debes seleccionar 1 oferta como mínimo')
    }
  }
  const onClose = () => {
    dispatch({
      type: 'SET_IS_VISIBLE',
      payload:{
        toggleState: {
            ...isVisible.toggleState
        },
        option: false, 
        type: '', 
        lot: {}
      }
    })
  }
  
  return (
    <div>
      
      <Title level={3}> Selección de ofertas</Title>
      <Title level={5}>Para cerrar la solicitud debe seleccionar como minimo una oferta...</Title>

      <Divider />

      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        scroll={{
          y: 200,
        }}
        columns={columns}
        dataSource={lots.content}
        pagination={{
          showSizeChanger: false,
          current: filterCL.page +1,
          onChange:(current, pageSize)=> setFilterCL({...filterCL, page: current -1}),
          defaultCurrent: 1,
          total: lots.totalElements,
          showTotal:(total) => `Total ${total}`,
          responsive: true,
          disabled: lots.totalElements > lots.size ? false : true,

        }}
      />
    <div style={{display: 'flex', justifyContent: 'center', marginTop: '15px'}}>
      <span style={{marginRight: '5px'}}>
        <ButtonApp onClick={onClick} primary content='Cerrar solicitud'/>
      </span>
      <span style={{marginLeft: '5px'}}>
        <ButtonApp onClick={onClose} primary cancel content='Cancelar'/>
      </span>
      
      
    </div>
      
    </div>
  );
};
