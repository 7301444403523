import React, {useContext, useState, useEffect} from "react"
import { Col, Divider, Pagination, Row, Select,AutoComplete, Space } from "antd"
import '../styles.css'
import Maps from "./Map"
import departamentos from '../../../../../helpers/states'
import {Context} from '../../../../../context/AreaContext'
import {getFormFields,getParameterValue} from './createArea.service';
const { Option } = Select

export default function LocationEdit (paramsEditables){
  const {
    setDepartment,  
    parameters,
    setArea,
    setIsValidForm,
    validateParameters,
    clearOnlyFields,
    isCloseModal,
  } = useContext(Context)
  const [params, setParams] = useState(paramsEditables?parameters:{
    _id:'',
    isValid:false,
    departmentParam:"", 
    cityParam:"",
    neighborhoodParam:"",
    locationParam: "",
    communeParam: "",
    UPZParam:"",
    point: []
  })  

  const [firstLoad,setFirstLoad] = useState(true);
  const [id,setId] = useState('');
  const [citys, setCitys] = useState([])  
  const [isUPZ,setIsUPZ] = useState(false)
  const [isLocation,setIsLocation] = useState(false);
  const [isClearValues,setIsClearValues] = useState(false);

  const [isCiudad, setIsCiudad] = useState(false);
  const [isCommune,setIsCommune] = useState(false);
  const [isNeighborhood,setIsNeighborhood] = useState(false);
  const [isParamsValid,setIsParamsValid] = useState(false);
  const [forceParameters,setForceParameters] = useState(false);

  const [listOfCommune,setListOfCommune] = useState([]);
  const [listOfNeighborhood,setListOfNeighborhood] = useState([]);
  const [listOfLocation,setListOfLocation] = useState([]);
  const [listOfUPZ,setListOfUPZ] = useState([]);
  
  const [valueOfDepartment,setValueOfDepartment] = useState('');
  const [valueOfCity,setValueOfCity] = useState('');
  const [valueOfCommune,setValueOfCommune] = useState('');
  const [valueOfNeighborhood,seValuetOfNeighborhood] = useState('');
  const [valueOfLocation,setValueOfLocation] = useState('');
  const [valueOfUPZ,setValueOfUPZ] = useState('');

const initValueEditable = () =>{
  setId(parameters._id);
  setValueOfCity(parameters.cityParam)
  setValueOfCommune(parameters.communeParam)
  setValueOfLocation(parameters.locationParam)
  setValueOfUPZ(parameters.UPZParam)
  seValuetOfNeighborhood(parameters.neighborhoodParam)
  setValueOfDepartment(parameters.departmentParam);
  let citys = []
  departamentos.map(item => {if(item.departamento === params.departmentParam){citys = item.ciudades}})
  setCitys(citys)
  setIsCiudad(true)  
}

const verifyForm = ()=>{
  if(
    params.departmentParam.length > 0 && 
    params.cityParam.length > 0){
      let validDinamicFields = true;
      if(forceParameters){
        if(isUPZ){
          if(params.UPZParam.length <= 0){validDinamicFields=false;}        
        }
  
        if(isLocation){
          if(params.locationParam.length <= 0){validDinamicFields=false;}
        }
  
        if(isCommune){
          if(params.communeParam.length <= 0){validDinamicFields=false;}
        }
  
        if(isNeighborhood){
          if(params.neighborhoodParam.length <= 0){validDinamicFields=false;}
        }
      }      
      if(validDinamicFields){
        setIsParamsValid(true);        
        setIsValidForm(true)        
      }else{
        setIsValidForm(false)
        setIsParamsValid(false);         
      }

  }else{
      setIsParamsValid(false);         
  }
  validateParameters()
}

const clearField = ()=>{     
  setIsCommune(false);
  setIsNeighborhood(false);
  setIsParamsValid(false);
  setIsLocation(false);
  setIsUPZ(false);
  setForceParameters(false);
  setIsCiudad(false);
  setValueOfCity('')
  setValueOfCommune('')
  seValuetOfNeighborhood('')
  setValueOfLocation('')
  setValueOfUPZ('')  
  clearOnlyFields();  
  setIsClearValues(true);

  setArea({departmentParam: params.departmentParam, 
    cityParam:'',
    neighborhoodParam:'',    
    UPZParam:'',    
    locationParam:'',    
    communeParam:'',
    point: [],
    lat: null,
    lng: null,
    isValid:false})    
  
}

const validateField = (key)=>{
  return (params[key].length > 0)? 'form-control form-info-corredor':'form-control form-info-corredor form-admin-newUser-Error';
}

const onChange = async (e) => {  
  setValue(e.target.name,e.target.value);    
  setParams({...params, [e.target.name]: e.target.value})
  verifyForm();        
}


const setValue = (nameField,value)=>{
  switch (nameField) {
    case 'departmentParam':{ 
      setValueOfDepartment(value);
      setParams({...params,[nameField]:value})
      break;
    }
    case 'UPZParam':{ 
      setValueOfUPZ(value);
      setParams({...params,[nameField]:value})
      break;
    }
    case "locationParam":{       
      setValueOfLocation( value) 
      setParams({...params,[nameField]:value})
      break;
    }
    case "neighborhoodParam":{
      seValuetOfNeighborhood(value)
      setParams({...params,[nameField]:value})
      break;
    }
    case 'communeParam':{
      setValueOfCommune(value);
      setParams({...params,[nameField]:value})
      break;
    } 
    case 'cityParam':{
      setValueOfCity(value);
      setParams({...params,[nameField]:value})
      break;
    } 
    default:
      break;
  }  
  verifyForm();
}

const loadValidators = () => {
  setIsCommune(true);
  setIsLocation(true);
  setIsUPZ(true);
  setIsNeighborhood(true);

  if(paramsEditables.paramsEditables.communeParam){

  } else {

  }
}


useEffect(() => { //fill Department    
  if(params.departmentParam !== '' && !firstLoad){ 
    clearField();
    setDepartment(params.departmentParam);
    setCitys([]) 
    let citys = []
    departamentos.map(item => {if(item.departamento === params.departmentParam){citys = item.ciudades}})
    setCitys(citys)
    setIsCiudad(true)
  }else{        
    initValueEditable();    
    setFirstLoad(false);
  }
}, [params.departmentParam]) 

useEffect(() => { //fill city  
  if(valueOfCity !== '' || paramsEditables){
    departamentos.map( op =>{
      if(op.departamento === params.departmentParam){
        op.ciudades.map( cit =>{
          if(cit === params.cityParam){
            handlerVerificationNextFields();            
          }
        })
      }
    })
    
    loadValidators();
  }

}, [valueOfCity]);
 
useEffect(() => { //fill barrio        
  verifyForm();
  if(isParamsValid){    
    setArea(params);
  }
}, [
  params.neighborhoodParam,
  params.locationParam,
  params.communeParam,
  params.UPZParam,
]);


useEffect(() => { 
  if(isCloseModal){
    clearField();
    setArea({departmentParam: '', 
      cityParam:'',
      neighborhoodParam:'',    
      UPZParam:'',    
      locationParam:'',    
      communeParam:'',
      point: [],
      lat: null,
      lng: null,
      isValid:false})   
  }  
},[ 
  isCloseModal
])

const handlerVerificationNextFields = () =>{
  getFormFields(params).then( 
    (resp)=>{
      if(resp.fields === 'ALL'){
        setIsNeighborhood(true);
        setIsLocation(true);
        setIsCommune(true);
        setIsUPZ(true);
      }else{        
        setForceParameters(true);
        for(let key in resp.fields){          
          switch (resp.fields[key]) {
            case 'UPZ':{ 
              setListOfUPZ(getParameterValue('UPZ'));
              setIsUPZ(true); break;
            }
            case "location":{
              setListOfLocation(getParameterValue('location'));
              setIsLocation(true); break;
            }
            case "neighborhood":{
              setListOfNeighborhood(getParameterValue('neighborhood'));
              setIsNeighborhood(true); break;
            }
            case 'commune':{
              setListOfCommune(getParameterValue('commune'));
              setIsCommune(true); break;
            } 
            default:
              break;
          }
        }
        
      }
    }
   )
}
return(
  <div className='container-general-information'>
      <div className='container-fluid container-global'>
      <Row>
      <Col xs={{ span: 24}} md={{ span: 9}} className='location-responsive'>
      <div className='block-from'>
              <div className='form-row'>
                <div className='form-group'>
                     <label className='tit-input'>
                      Departamento
                      <span className='text-danger'> *</span>
                    </label>
                    <Select                                           
                        dropdownRender={menu => (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Space style={{ padding: '4px', justifyContent: 'center', width: '100%' }}>
                            <Pagination defaultCurrent={1} total={20} size="small"/>
                            </Space>
                          </>
                        )}
                        className={ validateField('departmentParam') }
                        bordered={false}
                        loading={false}
                        showSearch
                        optionFilterProp="children"
                        value={valueOfDepartment}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        placeholder='Seleccione un Departamento'
                        style={{padding: '0.375rem 0.75rem', height: '39px'}}                        
                        onChange={(e)=>onChange({target:{name: 'departmentParam', value: e,}})}
                       >
                       { departamentos.map((item, i)=>{
                        return(
                          <Option key={i} value={item.departamento}>{item.departamento}</Option>
                        )
                        })}
                    </Select>
                </div>
              </div>

              {isCiudad && <div className='form-row'>
                <div className='form-group'>
                  <label className='tit-input'>
                    Ciudad
                    <span className='text-danger'> *</span>
                  </label>
                  <Select
                        dropdownRender={menu => (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Space style={{ padding: '4px', justifyContent: 'center', width: '100%' }}>
                            <Pagination defaultCurrent={1} total={20} size="small"/>
                            </Space>
                          </>
                        )}
                        className={ validateField('cityParam') }
                        bordered={false}
                        loading={false}
                        showSearch                        
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        placeholder='Seleccione una ciudad'
                        style={{padding: '0.375rem 0.75rem', height: '39px'}}
                        disabled={params.departmentParam !== '' ? false : true}
                        value={valueOfCity}
                        onChange={(e)=>onChange({target:{name: 'cityParam', value: e,}})}
                       >
                      {citys.map((item, i)=>{
                        return(
                          <Option key={i} value={item.name}>{item.name}</Option>
                        )
                        })}
                  </Select>
                </div>
              </div>}

              {(isLocation && params.communeParam === '') && <div className='form-row'>
                <div className='form-group'>
                  <label className='tit-input'>
                    Localidad 
                    {forceParameters?<span className='text-danger'> *</span>:''}                   
                  </label>

                  <AutoComplete
                        dropdownRender={menu => (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Space style={{ padding: '4px', justifyContent: 'center', width: '100%' }}>
                            <Pagination defaultCurrent={1} total={20} size="small"/>
                            </Space>
                          </>
                        )}
                        className={forceParameters?`${validateField('locationParam') } ${'form-control form-info-corredor'} `:'form-control form-info-corredor'} 
                        name='locationParam'
                        bordered={false}
                        loading={false}
                        showSearch
                        value={valueOfLocation}
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          if(option.children.toLowerCase().includes(input.toLowerCase())){
                            return true;
                          }else{                            
                            return false
                          }
                        }}
                        placeholder='Seleccione una localidad'
                        style={{padding: '0.375rem 0.75rem', height: '39px'}}                        
                        onChange={(e)=>onChange({target:{name: 'locationParam', value: e,}})}
                        disabled={params.communeParam === '' ? false : true}
                       >
                      {listOfLocation.map((item, i)=>{
                        return(
                          <Option key={i} value={item}>{item}</Option>
                        )
                        })}
                  </AutoComplete>
                 
                </div>
              </div>}

              {(isCommune && params.locationParam === '') && <div className='form-row'>
                <div className='form-group'>
                  <label className='tit-input'>
                    Comuna 
                    {forceParameters?<span className='text-danger'> *</span>:''}                   
                  </label>
                  <AutoComplete
                        dropdownRender={menu => (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Space style={{ padding: '4px', justifyContent: 'center', width: '100%' }}>
                            <Pagination defaultCurrent={1} total={20} size="small"/>
                            </Space>
                          </>
                        )}
                        className={forceParameters?`${validateField('communeParam') } ${'form-control form-info-corredor'} `:'form-control form-info-corredor'} 
                        name='communeParam'
                        bordered={false}
                        loading={false}
                        showSearch
                        value={valueOfCommune}
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          if(option.children.toLowerCase().includes(input.toLowerCase())){
                            return true;
                          }else{                            
                            return false
                          }
                        }}
                        placeholder='Seleccione una comuna'
                        style={{padding: '0.375rem 0.75rem', height: '39px'}}                        
                        onChange={(e)=>onChange({target:{name: 'communeParam', value: e,}})}
                        disabled={params.locationParam === '' ? false : true}
                       >
                      {listOfCommune.map((item, i)=>{
                        return(
                          <Option key={i} value={item}>{item}</Option>
                        )
                        })}
                  </AutoComplete>                 
                </div>
              </div>}

              {isUPZ && <div className='form-row'>
                <div className='form-group'>
                  <label className='tit-input'>
                    UPZ    
                    {forceParameters?<span className='text-danger'> *</span>:''}                
                  </label>
                  <AutoComplete
                        dropdownRender={menu => (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Space style={{ padding: '4px', justifyContent: 'center', width: '100%' }}>
                            <Pagination defaultCurrent={1} total={20} size="small"/>
                            </Space>
                          </>
                        )}
                        className={forceParameters?`${validateField('UPZParam') } ${'form-control form-info-corredor'} `:'form-control form-info-corredor'} 
                        name='UPZParam'
                        bordered={false}
                        loading={false}
                        showSearch
                        value={valueOfUPZ}
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          if(option.children.toLowerCase().includes(input.toLowerCase())){
                            return true;
                          }else{                            
                            return false
                          }
                        }}
                        placeholder='Seleccione un UPZ'
                        style={{padding: '0.375rem 0.75rem', height: '39px'}}                        
                        onChange={(e)=>onChange({target:{name: 'UPZParam', value: e,}})}
                       >
                      {listOfUPZ.map((item, i)=>{
                        return(
                          <Option key={i} value={item}>{item}</Option>
                        )
                        })}
                  </AutoComplete>                  
                </div>
              </div>}

              {isNeighborhood && <div className='form-row'>
                <div className='form-group'>
                  <label className='tit-input'>
                    Barrio
                    {forceParameters?<span className='text-danger'> *</span>:''}
                  </label>
                  <AutoComplete
                        dropdownRender={menu => (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Space style={{ padding: '4px', justifyContent: 'center', width: '100%' }}>
                            <Pagination defaultCurrent={1} total={20} size="small"/>
                            </Space>
                          </>
                        )}
                        className={forceParameters?`${validateField('neighborhoodParam') } ${'form-control form-info-corredor'} `:'form-control form-info-corredor'} 
                        name='neighborhoodParam'
                        bordered={false}
                        loading={false}
                        showSearch
                        value={valueOfNeighborhood}
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          if(option.children.toLowerCase().includes(input.toLowerCase())){
                            return true;
                          }else{                            
                            return false
                          }
                        }}
                        placeholder='Seleccione un barrio'
                        style={{padding: '0.375rem 0.75rem', height: '39px'}}                        
                        onChange={(e)=>onChange({target:{name: 'neighborhoodParam', value: e,}})}
                       >
                      {listOfNeighborhood.map((item, i)=>{
                        return(
                          <Option key={i} value={item}>{item}</Option>
                        )
                        })}
                  </AutoComplete>
                 
                </div>
              </div>}
            
          </div>
          </Col>
          <Col xs={{ span: 24}} md={{ span: 14, offset: 1 }}>
            <div className="map">
              <Maps isEdit={true}/>
            </div>
          </Col>       
        </Row>
  </div>
</div>
    )
}

/**
 * 
 * <Col xs={{ span: 24}} md={{ span: 9}} className='location-responsive'>
      <div className='block-from'>
              <div className='form-row'>
                <div className='form-group'>
                    <label className='tit-input'>
                      Departamento
                      <span className='text-danger'> *</span>
                    </label>
                    <Select                                           
                        dropdownRender={menu => (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Space style={{ padding: '4px', justifyContent: 'center', width: '100%' }}>
                            <Pagination defaultCurrent={1} total={20} size="small"/>
                            </Space>
                          </>
                        )}
                        className={'form-control form-info-corredor' }
                        bordered={false}
                        loading={false}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        placeholder='Seleccione un Departamento'
                        style={{padding: '0.375rem 0.75rem', height: '39px'}}
                        value={params.departmentParam}
                        onChange={(e)=>onChange({target:{name: 'departmentParam', value: e,}})}
                       >
                       { departamentos.map((item, i)=>{
                        return(
                          <Option key={i} value={item.departamento}>{item.departamento}</Option>
                        )
                        })}
                    </Select>
                </div>
              </div>
              <div className='form-row'>
                <div className='form-group'>
                  <label className='tit-input'>
                    Ciudad
                    <span className='text-danger'> *</span>
                  </label>
                  <Select
                        dropdownRender={menu => (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Space style={{ padding: '4px', justifyContent: 'center', width: '100%' }}>
                            <Pagination defaultCurrent={1} total={20} size="small"/>
                            </Space>
                          </>
                        )}
                        className={ 'form-control form-info-corredor'}
                        bordered={false}
                        loading={false}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        placeholder='Seleccione una ciudad'
                        style={{padding: '0.375rem 0.75rem', height: '39px'}}
                        disabled={params.departmentParam !== '' ? false : true}
                        onChange={(e)=>onChange({target:{name: 'cityParam', value: e,}})}
                        value={params.cityParam}
                       >
                      {citys.map((item, i)=>{
                        return(
                          <Option key={i} value={item}>{item}</Option>
                        )
                        })}
                    </Select>
                </div>
              </div>

              {isLocation && <div className='form-row'>
                <div className='form-group'>
                  <label className='tit-input'>
                    Localidad                    
                  </label>
                  <input
                    type='text'
                    name='locationParam'
                    className= 'form-control form-info-corredor'
                    placeholder='Av Kr #13-23'
                    onChange={onChange}
                    value={params.locationParam}                    
                  />
                </div>
              </div>}

              {isCommune && <div className='form-row'>
                <div className='form-group'>
                  <label className='tit-input'>
                    Comuna                    
                  </label>
                  <input
                    type='text'
                    name='communeParam'
                    className= 'form-control form-info-corredor'
                    placeholder='Av Kr #13-23'
                    onChange={onChange}  
                    value={params.communeParam}                   
                  />
                </div>
              </div>}

              {isUPZ && <div className='form-row'>
                <div className='form-group'>
                  <label className='tit-input'>
                    UPZ                    
                  </label>
                  <input
                    type='text'
                    name='UPZParam'
                    className= 'form-control form-info-corredor'
                    placeholder='Av Kr #13-23'
                    onChange={onChange} 
                    value={params.UPZParam}                     
                  />
                </div>
              </div>}

              {isNeighborhood && <div className='form-row'>
                <div className='form-group'>
                  <label className='tit-input'>
                    Barrio
                    <span className='text-danger'> *</span>
                  </label>
                  <input
                    type='text'
                    name='neighborhoodParam'
                    className={`${'form-control form-info-corredor'} `} 
                    placeholder='Av Kr #13-23'
                    onChange={onChange}     
                    value={params.neighborhoodParam}                 
                  />
                </div>
              </div>}
            
      </div>
      </Col>
 * 
 * 
 */