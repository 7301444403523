import React, { useState, useEffect, useContext, useRef} from 'react';
import { Avatar, Col, Rate, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useAuth } from '../../../../context/AuthContext';
import UserModelsEnum from '../../../../helpers/enums/UserModelsEnum';
import getProfilePicture from '../../../../services/getProfilePict';
import NumberFormat from "react-number-format";
import './lotsDetails.css'
import { ButtonApp } from '../../../button/button';
import {Context} from '../../../../context/lotsContext';
import Maps from '../CreateLots/Steps/Location/Map';



const LotsDetails = ({lotsData, setShowEditLots}) => {
  const [logo, setLogo] = useState('');
  const [checked, setChecked] = useState(false);
  const bottomRef = useRef();
  const {
    state: { isVisibleAssociate },
    dispatch,
  } = useContext(Context)
  const {user, userData} = useAuth()
  useEffect(() => {
    if(user && userData && (userData.photo || userData.logo)){
    const id = user.model === UserModelsEnum.Developer ? userData._id : user.id
    const filename = userData.logo || userData.photo
    const model = user.model === UserModelsEnum.Developer ? UserModelsEnum.Developer : UserModelsEnum.User

    getProfilePicture(id, filename, model).then((response) => {
      if(response) {

        setLogo(response)
        }}
        )
  }
  }, [user, userData])

  const handleDelete = () =>{
    dispatch({
      type: 'SET_IS_VISIBLE_ASSOCIATE',
      payload:{
        toggleState: {...isVisibleAssociate.toggleState},
        option: true, 
        type: 'delete', 
        lot: lotsData,
      }
    })
  }

  const handleEdit = () =>{
    dispatch({type:'SET_LOT_DATA_EDIT', payload: lotsData})
    setShowEditLots(true)
    dispatch({
      type: 'SET_IS_VISIBLE_ASSOCIATE',
      payload:{
        toggleState: {
            ...isVisibleAssociate.toggleState
        },
        option: false, 
        type: '', 
        lot: lotsData,
      }
    })
  }

  const hablebatchDiary = () => {
    dispatch({
      type: 'SET_IS_VISIBLE_ASSOCIATE',
      payload:{
        toggleState: {
            ...isVisibleAssociate.toggleState
        },
        option: true, 
        type: 'batchDiary', 
        lot: lotsData,
      }
    })
  }

  useEffect(() => {
    if(checked && bottomRef.current){
      setTimeout(() => bottomRef.current.scrollIntoView({behavior: 'smooth'}), 500);
    }
  }, [checked])

    return (
    <div className='container-details'>
      <Row className='rows-lot' style={{alignItems: 'end'}}>
        <span className='subtitle1'>Ficha del Lote</span>
        <span className='subtitle2'>{lotsData.registrationNumber && lotsData.registrationNumber}</span>
        <span className='subtitle3'><a onClick={hablebatchDiary}>Diario del lote</a></span>
      </Row>
      <Row className='rows-lot'>
      <Avatar icon={<UserOutlined />} src={logo} size={90}/>
      <div className='rate-and-name'>
        <Rate disabled defaultValue={0} style={{color:'#2a97d1'}}/>
        <span className='subtitle1'>{userData.names.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())}{' '}{userData.lastNames.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())}</span>
      </div>
      </Row>
      <Row className='rows-lot row2'>
        <Col className='col' xs={{ span: 24}} md={{ span: 8}}>
          <h6>Área del lote (m²)</h6>
          <NumberFormat 
                    type='text' 
                    value={lotsData.area} 
                    thousandSeparator={'.'} 
                    decimalSeparator={','} 
                    prefix={''}
                    disabled
                    style={{border: 'none', marginLeft: '10px', background: '#fff'}}
                  />
        </Col>
        <Col className='col' xs={{ span: 24}} md={{ span: 7, offset: 1 }}>
          <h6>Uso del lote (Tratamiento)</h6>
          <span>{lotsData.treatment && lotsData.treatment}</span>
        </Col>
        <Col className='col' xs={{ span: 24}} md={{ span: 7, offset: 1 }}>
          <h6>Departamento</h6>
          <span>{lotsData.location.department && lotsData.location.department}</span>
        </Col>
        <Col className='col' xs={{ span: 24}} md={{ span: 8}}>
          <h6>Precio del lote por m² ($COP)</h6>
          <NumberFormat 
                    type='text' 
                    value={lotsData.metersPrice && lotsData.metersPrice} 
                    thousandSeparator={'.'} 
                    decimalSeparator={','} 
                    prefix={'$'}
                    disabled
                    style={{border: 'none', marginLeft: '10px', background: '#fff'}}
                  />
        </Col>
        <Col className='col' xs={{ span: 24}} md={{ span: 7, offset: 1 }}>
          <h6>Uso del lote (zona)</h6>
          <span>{lotsData.zone && lotsData.zone}</span>
        </Col>
        <Col className='col' xs={{ span: 24}} md={{ span: 7, offset: 1 }}>
          <h6>Ciudad</h6>
          <span>{lotsData.location.city && lotsData.location.city}</span>
        </Col>
        <Col className='col' xs={{ span: 24}} md={{ span: 8}}>
          <h6>Precio total del lote ($COP)</h6>
          <NumberFormat 
                    type='text' 
                    value={lotsData.metersPrice * lotsData.area} 
                    thousandSeparator={'.'} 
                    decimalSeparator={','} 
                    prefix={'$'}
                    disabled
                    style={{border: 'none', marginLeft: '10px', background: '#fff'}}
                  />
        </Col>
        <Col className='col' xs={{ span: 24}} md={{ span: 7, offset: 1 }}>
          <h6>Tipo de negocio</h6>
          <span>{lotsData.businessType && lotsData.businessType}</span>
        </Col>
        <Col className='col' xs={{ span: 24}} md={{ span: 7, offset: 1 }}>
          <h6>Zona</h6>
          <span>{lotsData.location && lotsData.location.location}</span>
        </Col>
      </Row>
      <Row className='rows-lot'>
        <Col className='col' xs={24}>
          <h6>Observaciones</h6>
          <span>{lotsData.observations && lotsData.observations}</span>
        </Col>
      </Row>
      <Row className='rows-lot'>
          {/* <span onClick={() => setOpen(!open)} className='collapse-title'>Ver ubicacion en mapa</span> */}
          <div className="container-collapse">
            <div className="accordions">
              <div className="accordion">
                <input type="checkbox" onChange={(e)=> {setChecked(e.target.checked)}} id="first" />
                <label htmlFor="first" className="acc-label">
                  Ver ubicación en mapa
                  <div className='bullet-point'></div>
                </label>
                <div className="acc-content">
                  <Maps marker={[lotsData.map.latitude, lotsData.map.longitude]} zoom={13} isVisible={checked}/>
                </div>
              </div>
            </div>
          </div>
      </Row>
      <Row ref={bottomRef} className='rows-lot'>
        <div className='button-group-lot'>
          <span className='button-lot-delete'>
          <ButtonApp disabled={lotsData.state === 'SOLD_OUT' || lotsData.state === 'RETIRED'|| lotsData.state === 'BOUGHT'} content='ELIMINAR LOTE' onClick={handleDelete} primary cancel/>
          </span>
          <span className='button-lot-edit'>
          <ButtonApp disabled={lotsData.state === 'SOLD_OUT' || lotsData.state === 'RETIRED'|| lotsData.state === 'BOUGHT'} content='EDITAR LOTE' onClick={handleEdit} primary/>
          </span>
        </div>
      </Row>
    </div>
  )
}

export default LotsDetails;