import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import AuthProvider from './context/AuthContext'
import { ModalsProvider } from './context/ModalsContext'
import { ToastsProvider } from './context/ToastsContext'
import Layout from './containers/Layout'
import Auth from './containers/Auth'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import { PermissionsProvider } from './context/PermissionsContext';
import { ThemeProvider } from './context/ThemeContext'
import { ContextProvider } from './context/lotsContext'
import { AreatProvider } from './context/AreaContext';
import { ChatProvider } from './context/ChatContext';
import { RangeProvider } from './context/RangeContext';
import {SocketProvider} from './context/socketIO';
import {GlobalNotificationProvider} from './context/GlobalNotification';
import { RequestsAvailableProvider } from './context/RequestsAvailableContext';
import { ProfileModalProvider } from './context/ProfileModalContext';
function App() {
  return (
    <div>
      <GlobalNotificationProvider>
        <SocketProvider>
        <ThemeProvider>
          <AuthProvider>
            <PermissionsProvider>
              <ChatProvider>
                <AreatProvider>
                  <RangeProvider>
                    <ContextProvider>
                      <ModalsProvider>
                        <ToastsProvider>
                          <RequestsAvailableProvider>   
                            <ProfileModalProvider>       
                              <Router basename={process.env.REACT_APP_CONTEXT || '/'}>                  
                                  <Switch>
                                    <Route path='/auth' component={Auth} />
                                    <Route path='/app' component={Layout} />
                                    <Redirect exact path='/' to='/auth' />
                                  </Switch>                  
                              </Router>
                            </ProfileModalProvider>  
                          </RequestsAvailableProvider>            
                        </ToastsProvider>
                      </ModalsProvider>
                    </ContextProvider>
                  </RangeProvider>
                </AreatProvider>
              </ChatProvider>
            </PermissionsProvider>
          </AuthProvider>
         </ThemeProvider>
        </SocketProvider>
      </GlobalNotificationProvider>
    </div>
  )
}

export default App
