import React, { createContext, useState } from "react";

export const ProfileModalContext = createContext();

export const ProfileModalProvider = ({ children }) => {    
  
  const [profileData, setProfileData] = useState({
    show: false,
    profileData: null
  });
 

  return(
    <ProfileModalContext.Provider value={
    {
        profileData: profileData.profileData, 
        show: profileData.show, 
        setProfileData
    }
        }>
      {children}
    </ProfileModalContext.Provider>
  )
  
}