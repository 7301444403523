import React from 'react';
import { Tabs } from 'antd'

import FormEditDePersonal from '../../components/FormEditDePersonal/FormEditDePersonal'
import FormEditDeEmpresarial from '../../components/FormEditDeEmpresarial/FormEditDeEmpresarial'

import './FormEditDesarrollador.css'

export default function FormEditDesarrollador(props) {

  return (
    <div className='form-edit-desarrollador'>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Información Personal" key="1">
          <FormEditDePersonal DatosDesarrollador={props.DatosDesarrollador} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Información Empresarial" key="2">
          <FormEditDeEmpresarial DatosDesarrollador={props.DatosDesarrollador} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}
