import React, { useEffect,useState,useRef } from "react";

import {Layout,Menu,Empty,Row,Col,Button, Radio,Input,Divider, Alert  } from "antd";
import {SendOutlined,PaperClipOutlined} from "@ant-design/icons"
import BubbleChat from "../ChatBubble/bubbleChat";
import ChatInput from "../ChatInput/ChatInput";
import ChatToolbar from "../ChatToolbar/chatToolbar";
import { ChatContext } from "../../../../context/ChatContext";
import { useContext } from "react";
import UserModelsEnum from '../../../../helpers/enums/UserModelsEnum'
const { Header, Content, Footer } = Layout;
const { TextArea } = Input;

let messages = []
const ChatBox = ({currentLand,chat}) =>{
  const bottomRef = useRef();
  const {currentMessages} = useContext(ChatContext);
  const [contactInfo,setContactInfo] = useState({})
  const [message,setMessage] = useState([]);
  const chatBoxStyles = {
    justifyContent:'center', 
    height: '45vh', 
    
    overflowX:'hidden',
    width:'95%',    
  }

  const isChatDisabled = (chat.data && chat.data.landRequest && chat.data.landRequest.landOffered && chat.data.landRequest.landOffered.state === "REFUSED");
  const rejectedMessage = (chat.data && chat.data.landRequest && chat.data.landRequest.landOffered && chat.data.landRequest.landOffered.reason)
  
  useEffect(() => {  
    console.log(chat);   
    console.log(currentLand);
    if(currentMessages){
      messages = [];
      const model = JSON.parse(localStorage.getItem('user')).model;
      if(model === UserModelsEnum.Developer){
        currentMessages.content.map(
          (item) =>{                    
            if(JSON.parse(localStorage.getItem('user')).developer === item.user ){
              messages.push(
                {chat:chat.data._id,status:'READ',me:true,time:item.createdAt,message:item.message,type:item.type , fileName:item.fileName}
              )
            }else{
              messages.push(
                {chat:chat.data._id,status:'READ',me:false,time:item.createdAt,message:item.message,user:item.fullName,type:item.type,fileName:item.fileName}
              )
            }
          }
        )
      }else{
        currentMessages.content.map(
          (item) =>{                    
            if(JSON.parse(localStorage.getItem('user')).broker === item.user ){
              messages.push(
                {chat:chat.data._id,status:'READ',me:true,time:item.createdAt,message:item.message,type:item.type,fileName:item.fileName}
              )
            }else{
              messages.push(
                {chat:chat.data._id,status:'READ',me:false,time:item.createdAt,message:item.message,user:item.fullName,type:item.type,fileName:item.fileName}
              )
            }
          }
        )
      }
      setMessage(messages.reverse());      
    }
    if(bottomRef){
      setTimeout(
        () => {
          downScroll()          
        },
        100
      )
    }      
  },[currentMessages])

  useEffect(() => {
    if(currentLand){
      setContactInfo(currentLand.developer);
    }
    
  },[currentLand])
  const downScroll = ()=>{    
    try{
      const topOfDiv =   bottomRef.current.offsetTop;
      const bottomOfDiv =   bottomRef.current.scrollHeight;
      bottomRef.current.scrollTo({ top: (topOfDiv+bottomOfDiv), behavior: 'smooth' });
    }catch(err){
      console.log('err')
    }
  }
  return (
    <>    
      <ChatToolbar contactProps={contactInfo}/>

      <Content id="chat-scroll" style={chatBoxStyles} ref={bottomRef} >
        <Row gutter={16} align='middle'>
          {message.map(
            (item,index)=>{
              if(item.me){
                return(                  
                    <Col key={index} className="chat-me" span={24}>
                      <BubbleChat key={index} item={item}/>
                    </Col>                  
                )
              }else{
                return(                                      
                    <Col key={index} className="chat-other" span={24}> 
                      <BubbleChat key={index} item={item}/>
                    </Col>                  
                )
              }
            }
          )}
        </Row>
        {isChatDisabled ? <Alert
            message="El lote ha sido rechazado"
            description={`Razón: ${rejectedMessage}`}
            type="warning"
            className="mt-3 mb-3"
            showIcon
          /> : null}
      </Content >
      
      {!isChatDisabled && <ChatInput chat={chat}/>}
    </>
  )
}
export default ChatBox;