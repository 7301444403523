import DeveloperStatesEnum from '../helpers/enums/DeveloperStatesEnum';
import HttpResponseEmun from '../helpers/enums/HttpResponseEmun';
import Request from './Request';

export default async function getRequestsAvailable () {
    try {
        const response = await Request.get('/developers', 
        { page: 0, showRows: 1, statusParam: "ACTIVE", 
        stateParam: DeveloperStatesEnum.Validation});

        const {status} = response;

        if(status === HttpResponseEmun.OK){
            return response.data;
        }
    } catch (error) {
        console.error(error);
        if(error) return error;
    }
  }