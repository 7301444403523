import React, {useState, useRef} from 'react';
import { Divider, Pagination, Empty } from 'antd';
import sort from '../../../components/handleSort';

import {useAuth} from '../../../context/AuthContext';

import BrokerItem from '../BrokerItem/BrokerItem';

const theadDevelopers = [
    {item: '', label:''},
    {item:'broker', label:'Corredor'},
    {item:'email', label:'Email'},
    {item:'type', label:'Tipo'},
    {item:'!', label:''},
    {item:'', label:''},
];

const FilterSvg = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filter-left" viewBox="0 0 16 16">
    <path d="M2 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
</svg>;

function BrokersTable({filter, setFilter, brokers}) {

    const handleSort = (item) =>{
        setFilter({...filter, ordering: sort(item)}) 
    }

    const {userData} = useAuth();

    return (
        <> 
        <div>   
            <table >
            <thead className="HeadListUser-admin" >
                <tr >
                    {theadDevelopers.map((e, i)=>{
                    if(e.item === "!") return;
                    return(
                    <th key={i} scope="col" className={`col-listUser-adminPanel col${i+1}`} onClick={() => { e.item !== "" && handleSort(e.item) }} style={{ cursor: 'pointer' }}>
                    <span style={{ marginRight: '0.5rem' }} >{e.label}</span>
                    {e.item !== "" && FilterSvg}
                    </th>)
                })}
                </tr>
            </thead>
            <tbody>
            
            {/*{brokers && brokers.type !== 'brokers' && 
            <tr className='table-row-s'>
                
            </tr>}*/}

            {brokers && brokers.length === 0 &&
                <tr>
                <td colSpan={8}>
                <Empty 
                    description={
                    <span>
                        No se encontraron datos
                    </span>}
                    image={Empty.PRESENTED_IMAGE_SIMPLE} />
                
                </td>
                </tr>}

                <tr className='table-row-s'>
                
                </tr>

                {brokers && brokers.map((broker, i) => {
                
                {/* Here the Items tr */}

                return <React.Fragment key={broker._id}>
                    <BrokerItem item={broker} structure={theadDevelopers} />

                </React.Fragment>
                })}
                
                <tr >
                    <td colSpan="7">
                    <Divider style={{margin: '2px'}}/>
                    </td>
                </tr>

            </tbody>
            <tfoot>
                <tr>
                <td colSpan="7" align='right'>
                    <Pagination
                        current={filter.page +1}
                        onChange={(current, pageSize)=> setFilter({...filter, page: current -1})}
                        defaultCurrent={1}
                        total={brokers.totalElements}
                        showTotal={(total) => `Total ${total}`}
                        responsive
                        disabled={brokers.totalElements >  brokers.size ? false : true}
                    />
                </td>
                </tr>
            </tfoot>
            </table>
        </div>

        </>
    )
}

export default BrokersTable
