import React from 'react'
import { useEffect, useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import LayoutHeader from '../components/LayoutHeader'
import UserModelsEnum from '../helpers/enums/UserModelsEnum'
import DeveloperStatesEnum from '../helpers/enums/DeveloperStatesEnum'
import routes from '../routes/app'

import Page404 from '../pages/404'

function Layout() {
  const { user, userData } = useAuth()
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    if (!user) {
      setRedirect(true)
    }
  }, [user])
  
  return (
    <div>
      {redirect && <Redirect to='/auth' />}
      {user && userData && user.model === UserModelsEnum.SUDO && (
        <Redirect to='/app/admin' />
      )}
      {user && userData && user.model === UserModelsEnum.ADMIN && (
        <Redirect to='/app/admin/requests' />
      )}
      {user &&
        userData &&
        user.model === UserModelsEnum.Developer &&
        userData.state === DeveloperStatesEnum.Pending && (
          <Redirect to='/app/verification' />
        )}
      {user &&
        userData &&
        user.model === UserModelsEnum.Developer &&
        userData.state === DeveloperStatesEnum.Validation && (
          <Redirect to='/app/profile' />
        )}
      {user &&
        userData &&
        user.model === UserModelsEnum.Broker &&
        !userData.photo && <Redirect to='/app/photo' />}
      <div>
        {userData && (userData.state !== DeveloperStatesEnum.Pending || userData.photo) && (
          <LayoutHeader />
        )}
        <Switch>
          {routes.map((route, i) => {
            return route.component ? (
              <Route
                key={i}
                exact={true}
                path={`/app${route.path}`}
                render={(props) => <route.component {...props} />}
              />
            ) : null
          })}
          <Redirect exact from='/app' to='/app/home' />
          <Route component={Page404} />
        </Switch>
      </div>
    </div>
  )
}

export default Layout
