import React, { useState, useEffect, useMemo, useContext} from 'react';
import {Link} from 'react-router-dom';
import { Avatar, Col, Row, Button, message} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useAuth } from '../../../context/AuthContext';
import { ChatContext} from '../../../context/ChatContext';
import getProfilePicture from '../../../services/getProfilePict';
import {Badge} from 'react-bootstrap';
import './lotsRequestDetails.css';
import shortText from '../../../helpers/shortText';
import interestUseEnum from '../../../helpers/enums/interestUseEnum';

import ShowFile from '../ShowFile/ShowFile';
import UserModelsEnum from '../../../helpers/enums/UserModelsEnum';
import HttpResponseEmun from '../../../helpers/enums/HttpResponseEmun';
import Request from '../../../services/Request';
import NumberFormat from 'react-number-format';

/*const fields = [
  {name: "Correo", value: "John_Doe@example.com", type: "email"},
  {name: "Descuento", value: "Hasta el 29 de Diciembre", type: "text"},
  {name: "Número telefónico", value: "24-124-0824-1233", type: "number"},
  {name: "Correo Adicional", value: "John_Doe2313@example.com", type: "email"},
  {name: "Número Adicional", value: "13-123-0823-123", type: "number"},
  {name: "Estado", value: "Todo bien, aquí ha alguien le dio por poner mucho texto y algo que se supone se debe controlar de alguna manera, por supuesto.", type: "text"},
  {name: "Fotos para la solicitud", value: "fdfa1680-3bc4-41c5-9d01-1b4386a181d1.pdf", type: "file"},
  {name: "Documento PDF", value: "fdfa1680-3bc4-41c5-9d01-1b4386a181d1.pdf", type: "file"},
];*/

//const fields = [];

const lotsRequestDetails = ({lotsRequestData: item, setSelectedRequest, closeModal, requestLand, requestLandByIdCallback, land, selectedRequest}) => {
  const [loading, setLoading] = useState(false)
  const [logo, setLogo] = useState('');
  const {user, userData} = useAuth();
  const {setCurrentLandRequest, setDeveloperAssociate, setBrokerAssociate, createAssociateChat} = useContext(ChatContext);
  const name = user;

  const isTabMyOffer = setSelectedRequest === undefined; // TAB MY OFFERS
  const isSelected = selectedRequest === item._id;

  const landToOfferDisabled = land && item.landOffered.map(land => land.land._id).includes(land._id);
  

  const contactSvg = <svg style={{marginRight: "1rem"}} width="28" height="28" viewBox="0 0 28 28" fill="#60CAF7" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.33464 0.667969C1.86797 0.667969 0.681302 1.86797 0.681302 3.33464L0.667969 27.3346L6.0013 22.0013H24.668C26.1346 22.0013 27.3346 20.8013 27.3346 19.3346V3.33464C27.3346 1.86797 26.1346 0.667969 24.668 0.667969H3.33464ZM3.33464 3.33464H24.668V19.3346H4.89464L3.33464 20.8946V3.33464ZM16.668 14.0013H6.0013V16.668H16.668V14.0013ZM6.0013 10.0013H22.0013V12.668H6.0013V10.0013ZM22.0013 6.0013H6.0013V8.66797H22.0013V6.0013Z" fill="#60CAF7"/>
  </svg>;

  const getInterestBadgeNumber = useMemo(() => Object.values(item.interestUse).filter(e => e === true).length
  , [item.interestUse]);

  const getBadgeWithInterest = (interest) => {

    if(!interestUseEnum[interest]) return null;

    return <Badge key={interest} pill className="bg-secondary">{interestUseEnum[interest].name}</Badge>
  }

  const offerHandler = (id) => {
    closeModal();
    !isTabMyOffer && setSelectedRequest(id);
  }

  const offerLandHandler = async (land) => {
    try {
      const res = await Request.post(`/land-requests/${item._id}/offer-land/${land._id}`);
      if(res.status === HttpResponseEmun.OK){
        message.success({ content: `¡El lote No. ${land.registrationNumber} fue enviado con éxito!`, duration: 3 });
      }else{
        message.error({content: `¡Error al enviar el lote No. ${land.registrationNumber}!`, durantion: 4});
      }
    } catch (error) {
      console.error(error);
      message.error({content: `¡Hubo un error inesperado al enviar el lote No. ${land.registrationNumber}!`, durantion: 4});
    } finally {
      closeModal();
    }
  }

  const requestLandByIdHandler = async (requestId) => {
    setLoading(true);
    await requestLandByIdCallback(requestId);
    setLoading(false);
    closeModal();
  }

  const setChat = async () => {
    if(!isTabMyOffer) {
      setBrokerAssociate(userData);
      setDeveloperAssociate({...item.developer, associateType: item.associateType});
      createAssociateChat();
      return;
    }
    setCurrentLandRequest(item);
  }



  useEffect(() => {
    const id = item.developer._id;
    const filename = item.developer.logo;
    const model = UserModelsEnum.Developer;

    getProfilePicture(id, filename, model).then((response) => {
      if(response) setLogo(response)});
  }, []);

   return (
    <div className='container-details'>
      <Row className='rows' style={{alignItems: 'end'}}>
        <span className='subtitle1'>Solicitud del Lote</span>
        <span className='subtitle2'>{/*lotsRequestData.registrationNumber*/}</span>
        <span style={{color: "#54B4D3"}} className='subtitle3'>{item.registrationNumber}</span>
      </Row>
      <Row className='rows'>
        <Avatar style={{border: "1px solid black", borderRadius: "50%"}} icon={<UserOutlined />} src={logo} size={90}/>
        <div className='type-and-name'>
          <div>
            <span className='subtitle1 pr-5'>{item.developer.contactPerson}</span>
          </div>
          <span style={{color: "#136a99"}} className='subtitle3'>{item.developer.businessName}</span>
        </div>
      </Row>
      
      <div className="lot-request-detail-body mb-2">
        <Row className='rows' style={{marginBottom: "0"}}>
          <Col className='col' xs={12} md={12}>
            <h6>Área del lote (m<sup>2</sup>)</h6>
            <div className="d-flex flex-column">
                <span className='d-flex'><strong>Min: </strong><NumberFormat 
                      type='text' 
                      value={item.areaRange && item.areaRange.startRange} 
                      thousandSeparator={'.'} 
                      decimalSeparator={','} 
                      prefix={''}
                      disabled
                      style={{border: 'none', marginLeft: '10px', background: '#fff'}}
                    /></span>
                <span className='d-flex'><strong>Max: </strong><NumberFormat 
                      type='text' 
                      value={item.areaRange && item.areaRange.endRange} 
                      thousandSeparator={'.'} 
                      decimalSeparator={','} 
                      prefix={''}
                      disabled
                      style={{border: 'none', marginLeft: '10px', background: '#fff'}}
                    /></span>
            </div>
          </Col>
          <Col className='col' xs={12} md={12}>
            <h6>Zona</h6>
            <span>{item.location.location}</span>
          </Col>
          <Col className='col' xs={12} md={12}>
            <h6>Departamento</h6>
            <span>{item.location.department}</span>
          </Col>
          <Col className='col' xs={12} md={12}>
            <h6>Ciudad</h6>
            <span>{item.location.city}</span>
          </Col>
        </Row>

        <Row>
          <Col style={{margin: '0px'}} className={"col interest" + " " + (getInterestBadgeNumber > 3 ? "interest-large" : "")} xs={24} md={24}>
            <h6>Uso de Interés</h6>
            { Object.keys(item.interestUse).map(interest => {
              if(item.interestUse[interest]) return getBadgeWithInterest(interest);
            })}
          </Col>
          <Link style={{width: '100%'}} to="/app/chat" onClick={setChat}>
              {contactSvg}
              <span style={{color: "#60CAF7", textDecoration:"underline"}} className='subtitle1 pl-5'>Contactar</span>
          </Link>
        </Row>
      </div>



      {item.observations && (
        <Row className='rows request-info-observations mb-3'>
          <Col className='col' xs={24}>
            <h6>Observaciones</h6>
            <span>{item.observations}</span>
          </Col>
        </Row>
      )}

    {item.fields && item.fields.length > 0 && <Row className="my-2">
       {(item.fields.map((field, i) => {
          return field.value && <Col key={i} className='col' xs={12} sm={8} md={8}>
            <h6>{field.name}</h6>
            {field.type !== 'file' && 
            <span title={field.value}>{shortText(field.value, 30)}</span>}
            {field.type === 'file' &&
            <ShowFile id={item._id} model="Solicitud de lote" filename={field.value} />}
          </Col>
      }))}
      </Row>}

      {!requestLand && !land && <Row className="d-flex justify-content-center offerBtn">
        <Button disabled={isTabMyOffer || isSelected} onClick={() => offerHandler(item._id)} type="primary" className="offer-button" style={{color: !(isTabMyOffer || isSelected) ? "black" : undefined, backgroundColor: !(isTabMyOffer || isSelected) ? "#60caf7" : undefined, border: "none", borderRadius: ".4rem"}}>OFRECER LOTE</Button>
      </Row>}

      {requestLand && <Row className="d-flex justify-content-center offerBtn">
        <Button disabled={loading || item.isOffered} onClick={() => requestLandByIdHandler(item._id)} type="primary" className="offer-button" style={{color: !isTabMyOffer ? "black" : undefined, border: "none", borderRadius: ".4rem"}}>SOLICITAR LOTE</Button>
      </Row>}

      {!requestLand && land && <Row className="d-flex justify-content-center offerBtn">
        <Button disabled={landToOfferDisabled} onClick={() => offerLandHandler(land)} type="primary" className="offer-button" style={{color: !landToOfferDisabled ? "black" : undefined, backgroundColor: !landToOfferDisabled ? "#60caf7" : undefined, border: "none", borderRadius: ".4rem"}}>OFRECER LOTE ({land.registrationNumber})</Button>
      </Row>}

      
    </div>
  )
}

export default lotsRequestDetails;