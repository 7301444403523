// FILTER, RANGE AND SEARCH
import { Col, Row, Select, Slider, Tag } from 'antd';
import React, {useEffect, useRef, useState} from 'react'
import NumberFormat from 'react-number-format';
import Request from '../../services/Request';
import './FilterRS.css'

const {Option} = Select
export default function FilterRS({onChange, hideState = false}) {
  const [checked, setChecked] = useState(false);
  const [paramMinMax, setParamMinMax] = useState(null);
  const [params, setParams] = useState({});
  useEffect(() => {
    if(onChange){
      onChange(params)
    }
  }, [params])
  

    useEffect(() => {
    const getMinMax = async() => {
      const res = await Request.get('/lands/by-price/min-and-max')
      setParamMinMax(res.data[0] ? res.data[0] : { min: 0, max:0 })
    }
    getMinMax()
    }, [])

    const refSearch = useRef(null)
  const handleParams = async(e, i) =>{
    let name = e.target.name
    if(name === 'search'){
      clearTimeout(refSearch.current)
      refSearch.current = setTimeout(async() => {
        setParams({...params, searchParam: e.target.value})
      }, 1000)
    }
    
  }

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const getColor = (key) => {
      switch (key) {
        case 'BOUGHT':
          return ' #14A44D';
        case 'PAUSED':
          return '#E4A11B';
        case 'ON_SALE':
          return '#14A44D';
        case 'SOLD_OUT':
          return '#54B4D3';
        case 'RETIRED':
          return '#9FA6B2';
      
        default:
          return ;
      }
    }
  
    return (
      <Tag
        color={getColor(value)}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
          color: '#000',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {label}
      </Tag>
    );
  };
    return(
        <div className="container-collapse">
                  <div className="accordions-filter">
                    <div className="accordion-filter">
                      <input type="checkbox" onChange={(e)=> {setChecked(e.target.checked)}} id="first-filter" />
                        <div className='container-header-filter'>
                          <Row className='header-filter'>
                            {!hideState ? <Col className='col-filter-select' xs={24} md={18}>
                              <Select 
                              tagRender={tagRender}
                              size='small'
                              showArrow 
                              defaultValue={['ON_SALE', 'PAUSED', 'BOUGHT']}  
                              mode= 'multiple' 
                              style= {{
                                width: '300px',
                              }}
                              onChange={ (value) => {
                                let stateLots = ''
                                value.forEach((el)=>{
                                  stateLots = `${stateLots}${stateLots !== '' ? ',' : ''}${el}`
                                })
                                setParams({...params, stateParam: stateLots})
                              }}
                              placeholder= 'Filtrar por...'
                              maxTagCount= 'responsive'
                              allowClear
                              >
                                <Option value='ON_SALE'>En venta</Option>
                                <Option value='PAUSED'>Pausada</Option>
                                <Option value='RETIRED'>Retirado</Option>
                                <Option value='BOUGHT'>Comprado</Option>
                                <Option value='SOLD_OUT'>Vendido</Option>
                              </Select>
                            </Col> : <div style={{width: '275px'}}></div>}
                            <Col className='col-filter-label' xs={24} md={6}>
                              <label htmlFor="first-filter" className="acc-label-filter">
                                Filtros
                                <div className='bullet-point-filter'></div>
                              </label>
                            </Col>
                          </Row>
                        </div>
                        <div className="acc-content-filter">
                          <Row className='acc-content-filter-row'>
                            <Col style={{display: 'flex', alignItems: 'end', margin: 0}} className='col-f' xs={24} md={14}>
                              <div className='acc-content-search' style={{display: 'flex', flexDirection: 'column'}}>
                                <label htmlFor='search' style={{marginBottom: '5px'}}>Buscar</label>
                                <input ref={refSearch} name='search' style={{width: '200px', color: '#000'}} id='search' onChange={handleParams} placeholder='Buscar lotes por...'/>
                              </div>
                            </Col>
                            <Col className='col-f' xs={24} md={8}>
                              <label htmlFor='price'>Precio</label>
                              {paramMinMax !== null && 
                              <Slider
                                tooltip={{
                                  open: true,
                                  formatter: null,
                                }}
                                id='price' 
                                style={{width: '86%', marginTop: '15px'}}
                                range defaultValue={[paramMinMax.min, paramMinMax.max]} 
                                marks={{
                                  0: {
                                    style: {
                                      color: '#fff',
                                    },
                                    label: <NumberFormat style={{border: 0, background: 'transparent'}} disabled value={params.startPriceRangeParam || paramMinMax.min} thousandSeparator={'.'} decimalSeparator={','} prefix={'$ '} />,
                                  },
                                  [paramMinMax.max]: {
                                    style: {
                                      color: '#fff',
                                    },
                                    label: <NumberFormat style={{border: 0, background: 'transparent'}} disabled value={params.endPriceRangeParam || paramMinMax.max} thousandSeparator={'.'} decimalSeparator={','} prefix={'$ '} />,
                                  },
                                }}
                                max={paramMinMax.max}
                                min={paramMinMax.min}
                                onAfterChange={(e)=>setParams({...params, startPriceRangeParam: e[0], endPriceRangeParam: e[1]})}
                                />}
                            </Col>
                            
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
    )
}