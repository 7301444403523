import React, {useState} from 'react';
import { Avatar, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { ButtonApp } from '../button/button';
import { deleteUser } from '../../services/developers'
import './CreatedUserModal.css';
import {useAuth} from '../../context/AuthContext';

const Delete = ({user, closeModal, closeDeleteModal}) => {

    const { refreshUser, userData } = useAuth();

    const deleteUserHandle = async () => {
        try {
            const res = await deleteUser(user._id, userData._id);
            if(res){
                message.success({content: `¡El usuario ${user.names} ${user.lastNames} ha sido elimando con éxito!`, duration: 3});
                refreshUser();
                closeModal();
            } else {
                message.error({content: `¡Hubo un error al eliminar el usuario ${user.names} ${user.lastNames}!`, durantion: 3});
            }
        } catch (error) {
            message.error({content: `¡Hubo un error al eliminar el usuario ${user.names} ${user.lastNames}!`, durantion: 3})
        } finally{
            closeDeleteModal();
        }
    }

    return(
      <div style={{width: '100%', flexDirection: 'column', display: 'flex', alignItems: 'center', textAlign: 'center'}}>
        <h5>¿Quieres eliminar este usuario?</h5>
        <span>No podrás deshacer esta acción y deberás volverlo a registrar el usuario para activarlo nuevamente.</span>
        <div style={{marginTop: '50px', display:'flex', width: '100%', justifyContent: 'center'}}>
          <span style={{marginRight: '10px'}}>
            <ButtonApp onClick={deleteUserHandle} content='SI' primary />
          </span>
          <span style={{marginLeft: '10px'}}>
            <ButtonApp onClick={closeDeleteModal} content='NO' primary cancel/>
          </span>
        </div>
      </div>
    )
  }

function CreatedUserModal({user, closeModal, openEditModal}) {

    const [showDeleteHandle, setShowDeleteHandle] = useState(false);

    const closeDeleteModal = () => setShowDeleteHandle(false);

    if(showDeleteHandle) return <Delete user={user} closeDeleteModal={closeDeleteModal} closeModal={closeModal}  />

    return (<div>
      <div className='row'>
        <Avatar className="col-2" style={{border: "1px solid black", borderRadius: "50%"}} icon={<UserOutlined />} size={90}/>
        <div className='type-and-name col-10'>
          <div>
            <span className='subtitle1 pr-5'>{user.names + ' ' + user.lastNames}</span>
          </div>
          <span style={{color: "#136a99"}} className='subtitle3'>{user.type}</span>
        </div>
      </div>
      
      <div className="user-detail-body mt-3">
        <div className='row' style={{marginBottom: "0"}}>
          <div className='col-6'>
            <h6>Correo</h6>
            <span>{user.email}</span>
          </div>
           <div className='col-6'>
            <h6>Teléfono</h6>
            <span>{user.phone}</span>
          </div>
        </div>
      </div>

      <div className="mt-5 d-flex justify-content-center">
        <span style={{marginRight: '5px'}}>
            <ButtonApp onClick={() => setShowDeleteHandle(true)} primary cancel content='Eliminar'/>
        </span>
        <span style={{marginLeft: '5px'}}>
            <ButtonApp onClick={openEditModal} primary content='Editar'/>
        </span>
      </div>

    </div>
    )
}

export default CreatedUserModal
