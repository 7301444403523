import {useState} from 'react';
import HttpResponseEmun from '../../../../../helpers/enums/HttpResponseEmun';
import Request from '../../../../../services/Request';
const ENDPOINTS = {
  verify:"/geographical-area"
}
  let listOfCommune = []
  let listOfNeighborhood = []
  let listOfLocation = []
  let listOfUPZ = []
export const getFormFields = async (params) => {
  

  const {departmentParam,cityParam} = params;
  try {
    let response;    
    response = await Request.get(`${ENDPOINTS.verify}?departamentParam=${departmentParam}&cityParam=${cityParam}`)      
    if (response.status === HttpResponseEmun.OK) {
      if(response.data.content.length === 0){
        return {fields:['ALL']}
      }
      const listOfParametersKey = getField(response.data.content[0]);
      fillParameters(listOfParametersKey.fields,response.data.content);
      return listOfParametersKey;
    }else{
      return null;
    }
    
  } catch (error) {
    console.error(error)
  }
  return false
}

export const senDataArea = async (params) =>{
  const {
    departmentParam, 
    cityParam,
    neighborhoodParam,
    locationParam,
    communeParam,
    UPZParam,
    point,
    lat,
    lng
  } = params;
  try {
    let response;
    response = await Request.post(
      `${ENDPOINTS.verify}`,
      {
        department:departmentParam,
        city:cityParam,
        neighborhood:neighborhoodParam,
        location:locationParam,
        commune:communeParam,
        UPZ:UPZParam,
        lat,
        lng,
        point:point.map(p=>({latitude:p[0],longitude:p[1]}))
      }
    )
    if (response.status === HttpResponseEmun.OK) {
      return response;
    }else{
      return null;
    }

  } catch (error) {
    console.log(error);
    return error;
  }
}
export const ediDataArea = async (params) =>{
  const {
    _id,
    departmentParam, 
    cityParam,
    neighborhoodParam,
    locationParam,
    communeParam,
    UPZParam,
    lat,
    lng,
    point
  } = params;
  try {
    let response;
    response = await Request.put(
      `${ENDPOINTS.verify}/${_id}`,
      {
        department:departmentParam,
        city:cityParam,
        neighborhood:neighborhoodParam,
        location:locationParam,
        commune:communeParam,
        UPZ:UPZParam,
        lat,
        lng,
        point:point.map(p=>({latitude:p[0],longitude:p[1]}))
      }
    )
    if (response.status === HttpResponseEmun.OK) {
      return response;
    }else{
      return null;
    }

  } catch (error) {
    console.log(error);
    return error;
  }
}

export const DeleteArea = async (id) =>{  
  try {
    let response;    
    response = await Request.delete(`${ENDPOINTS.verify}/${id}`)      
    if (response.status === HttpResponseEmun.OK) {
      return response;
    }else{
      return null;
    }
    
  } catch (error) {
    console.error(error)
  }
  return false
}
const getField = (element)=>{
  const fields ={fields:[]}
  for(let key in element){
    if( 
      key !== '_id' && 
      key !== 'department' && 
      key !== 'city' && 
      key !== 'createdAt' && 
      key !== 'updatedAt' && 
      key !== 'point'){
      fields.fields.push(key);
    }
  }  
  return fields;
}

export const getParameterValue =(parameter)=>{
  if(parameter === 'UPZ'){
    return listOfUPZ;
  }
  if(parameter === 'location'){
    return listOfLocation;
  }
  if(parameter === 'neighborhood'){
    return listOfNeighborhood;
  }
  if(parameter === 'commune'){
    return listOfCommune;
  }
  return [];
}

const fillParameters = (listOfParameters,content)=>{
  listOfParameters.map( (parameter)=>{
    if(parameter === 'UPZ'){
      listOfUPZ = (getListOfValueForParameter(content,parameter));
    }
    if(parameter === 'location'){
      listOfLocation = (getListOfValueForParameter(content,parameter));
    }
    if(parameter === 'neighborhood'){      
      listOfNeighborhood = (getListOfValueForParameter(content,parameter));
    }
    if(parameter === 'commune'){      
      listOfCommune = (getListOfValueForParameter(content,parameter));
    }
  })
}
const getListOfValueForParameter = (content,parameter)=>{
  const listParameter = []

 
  content.map( (element)=>{
    for(let key in element){
      if(key === parameter){
        listParameter.push(element[key]);
      }
    }
  })
  return  listParameter.filter((item,index)=>{
    return listParameter.indexOf(item) === index;
  })

}