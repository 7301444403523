import React from 'react'
import logo from '../../assets/logo.png'
import './HeaderRegister.css'

const HeaderRegister = (props) => {
  return (
    <div className='header-register'>
      <nav className='navbar navbar-expand-lg header-registro'>
        <img src={logo} className='img--logo' alt='Urban 360' />
      </nav>
    </div>
  )
}

export default HeaderRegister
