import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import './Search.css';
//import {ThemeContext} from '../../context/ThemeContext';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { Form, Input, Select, Slider } from 'antd';
import RequestList from './RequestList';
import DetailsModal from './DetailsModal'
import getAllRequestLands from '../../services/getAllRequestLands';
import Request from '../../services/Request';
import getAllLands from '../../services/getAllLands';

// imports to load lands in the lotsContext
import {useAuth} from '../../context/AuthContext';
import {Context} from '../../context/lotsContext';
import getLandsToOffer from '../../services/getLandsToOffer';
import { message } from 'antd';

import UserModelsEnum from '../../helpers/enums/UserModelsEnum';
import LandTable from './LandTable/LandTable';
import requestLandById from '../../services/requestLandById';
import HttpResponseEmun from '../../helpers/enums/HttpResponseEmun';
import formatter from '../../helpers/numberFormatter';
import CustomSlider from '../../components/CustomSlider/CustomSlider';

const getInputsValues = (formRef, broker, developer) => {
  
  const searchParam = formRef.current.getFieldValue("search");
  const cityParam = formRef.current.getFieldValue("city");
  const neighborhoodParam = formRef.current.getFieldValue("neighborhood");
  //const startAreaRangeParam = formRef.current.getFieldValue("minArea");
  //const endAreaRangeParam = formRef.current.getFieldValue("maxArea");
  const area = formRef.current.getFieldValue("area");
  const startAreaRangeParam = area ? area[0] : undefined;
  const endAreaRangeParam = area ? area[1] : undefined;

  if(developer) {
    const price = formRef.current.getFieldValue("price");
    const startPriceRangeParam = price ? price[0] : undefined;
    const endPriceRangeParam = price ? price[1] : undefined;
    return ({searchParam, cityParam, neighborhoodParam, startAreaRangeParam,
      endAreaRangeParam, startPriceRangeParam, endPriceRangeParam});
  }
  if(broker) return ({searchParam, cityParam, neighborhoodParam, startAreaRangeParam,
    endAreaRangeParam});
  
}

function Search() {
    //const {themeColor} = useContext(ThemeContext);
    const [filterTableList, setFilterTableList] = useState({page: 0, showRows: 10, statusParam: 'ACTIVE' });
    const [filter, setFilter] = useState({page: 0, showRows: 10, statusParam: 'ACTIVE' });
    const [devFilter, setDevFilter] = useState({page: 0, showRows: 10, statusParam: 'ACTIVE' });
    const [currentRequestLots, setCurrentRequestLots] = useState([]);
    const [modalLoading, setModalLoading] = useState(false);
    const [showDetails, setShowDetails] = useState({show: false, request: {}});
    const [searchParams, setSearchParams] = useState({});
    const [searchLoading, setSearchLoading] = useState(false);
    const [locationList, setLocationList] = useState({cities: [], neighborhoods: []});
    const [areaValues, setAreaValues] = useState({min: null, max: null});
    const [pricesValues, setPricesValues] = useState({min: 0, max: null});

    const [selectedLand, setSelectedLand] = useState({landId: "", registrationNumber: "", disabledList: []});

    const [lands, setLands] = useState({});

    const [selectedRequest, setSelectedRequest] = useState("");

    const formRef = useRef();

    const {Option} = Select;

    const {user, userData} = useAuth();
    const { dispatch } = useContext(Context);

    const brokerSearch = userData && user && user.model === UserModelsEnum.Broker;
    const developerSearch = userData && user && user.model === UserModelsEnum.Developer;

    const handleSearch = () => {
      setSearchParams(getInputsValues(formRef, brokerSearch, developerSearch));
    }

    /*
    const backgroundStyles = themeColor["background-color"]?
    {
      backgroundColor:themeColor["background-color"],
      color:themeColor["color"]
    }:
    {
      backgroundColor:"#272a30",
      color:"#fff"
    }*/

    const areaValuesCallback = useCallback(async () => {
      const resMin = await Request.get("/ranges?stateParam=ACTIVE&ordering=startRange,asc");
      const resMax = await Request.get("/ranges?stateParam=ACTIVE&ordering=endRange,desc");
      const min = resMin.data.content[0].startRange;
      const max = resMax.data.content[0].endRange;
      setAreaValues({min, max})
    }, []);

    const priceValuesCallback = async () => {
      const prices = await Request.get("/lands/by-price/min-and-max");
      setPricesValues({...pricesValues, max: prices.data[0].max || 1000000000});
    }

    useEffect(() => {
      if(developerSearch){
        priceValuesCallback();
      }
    }, [developerSearch])

    useEffect(() => {
      areaValuesCallback();
    }, [])

    const closeModal = () => {
      setShowDetails({show: false, request: {}})
    }

    const getRequestCallback = useCallback(
      (searchParams, filter) => {
        if(user && user.model !== UserModelsEnum.Broker) return;
        setSearchLoading(true)
        getAllRequestLands(searchParams, filter).then(res => {
         
          setCurrentRequestLots(res);
          setSearchLoading(false);
        });
      },
      [searchParams, filter],
    );

    const getLocationList = useCallback(
      async (searchParamCity, searchParamNeighborhood) => {
        // If a city is selected it only will show the neighborhood on that city
        const city = formRef.current.getFieldValue("city");
        const cityParamForNeighborhoods = city ? "&cityParam="+city : "";
        
        const resNeighborhoods = await Request.get("/geographical-area?ordering=neighborhood,asc&showRows=999"+cityParamForNeighborhoods, {searchParam: searchParamNeighborhood});
        const resCities = await Request.get("/geographical-area?ordering=city,asc&showRows=999", {searchParam: searchParamCity});
        const neighborhoods = [...new Set( resNeighborhoods.data.content.map(e => e.neighborhood) )];
        const cities = [...new Set( resCities.data.content.map(e => e.city) )];
        setLocationList(prev => ({...prev, neighborhoods}));
        setLocationList(prev => ({...prev, cities}));
      },
      [],
    );

    const requestLandByIdCallback = useCallback(
      async (requestId) => {
        try {
          const res = await requestLandById(selectedLand.landId, requestId);
          if(res.status === HttpResponseEmun.OK) {
            message.success({content: `¡El lote ${selectedLand.registrationNumber} fue solicitado con éxito!`, duration: 3});
            setSelectedLand(({...selectedLand, disabledList: [...selectedLand.disabledList, requestId]}));
          } else {
            message.error({content: `¡Error al solicitar el lote ${selectedLand.registrationNumber}!`, duration: 4});
          }
        } catch (error) {
          message.error({content: `¡Error al solicitar el lote ${selectedLand.registrationNumber}!`, duration: 4});
        }
      },
      [selectedLand]);

    const selectFilter = ((input, option) => {
      return (option.value).toLowerCase().includes(input.toLowerCase())
    });

    const handleSearchFilter = (e, type) => {
      if(type === "city") getLocationList(e);
      if(type === "neighborhood") getLocationList(undefined, e);
    }

    useEffect(() => {
      getRequestCallback(searchParams, filter);
    }, [searchParams, filter]);

    useEffect(() => {
      getLocationList();
    }, []);

    const fetchDataLandsCallback = useCallback(
      async (filterTableList, selectedRequest) => {
        dispatch({ type: 'SET_IS_VISIBLE_HB', payload: {option: false, 
          type: '', lot: {}, toggleState: {type: "lands", label: "Mis lotes"}}});
        dispatch({ type: 'RESET_CURRENT_LOTS'});
        const response = await getLandsToOffer(filterTableList, userData._id, selectedRequest);
        if (response.status === HttpResponseEmun.OK) {
          dispatch({ type: 'SET_CURRENT_LOTS', payload: {type: "lands", ...response.data} })
        }else{
          message.error('Error al cargar los lotes')
        }
        },
      [],
    );

    const fetchDataLandsDeveloperCallback = useCallback(
      async () => {
        setSearchLoading(true);
        const res = await getAllLands(searchParams, {...devFilter, typeParam: UserModelsEnum.Broker});
        setLands(res.data);
        setSearchLoading(false);
      },
      [devFilter, searchParams],
    );

    useEffect(() => {
      if(developerSearch){
        fetchDataLandsDeveloperCallback(searchParams, devFilter);
      }
    }, [searchParams, devFilter, userData]);

    useEffect(() => {
      if(brokerSearch && selectedRequest){
          fetchDataLandsCallback(filterTableList, selectedRequest);
      }
    }, [filterTableList, userData, user, selectedRequest]);

    return (
      <>
        <div className="container-fluid search-filters-container" style={{backgroundColor: "#272a30", color: "#fff"}}>

            <Form style={developerSearch ? {maxWidth: "900px"} : {}} ref={formRef} name="searchForm" layout="vertical" className="search-form">
                    <Form.Item name="search" label="Buscar" className="search-input">
                        <Input onPressEnter={handleSearch} name="search" type="search" autoFocus style={{backgroundColor: "transparent", color:"white"}} suffix={
                        <SearchOutlined onClick={handleSearch} className="site-form-item-icon search-input-icon" />} placeholder="Ingrese un criterio de búsqueda" />
                    </Form.Item>

                    <div className="search-filters">
                      <Form.Item name="city" label="Ciudad">
                        <Select showSearch filterOption={selectFilter} onSearch={(e) => handleSearchFilter(e, "city")} onChange={() => {handleSearch();getLocationList()}} placeholder="Ciudad" allowClear>
                          {locationList.cities.map((city, i) => <Option key={i} value={city} />)}
                        </Select>
                      </Form.Item>

                      <Form.Item name="neighborhood" label="Barrio">
                        <Select showSearch filterOption={selectFilter} onSearch={(e) => handleSearchFilter(e, "neighborhood")} onChange={() => {handleSearch();getLocationList()}} placeholder="Barrio" allowClear>
                          {locationList.neighborhoods.map((neighborhood, i) => <Option key={i} value={neighborhood} />)}
                        </Select>
                      </Form.Item>

                      <Form.Item className="area_Slider" name="area" style={brokerSearch && {width: "20rem"}} label="Rango del área">
                        {(areaValues.max > 0) && <CustomSlider tipFormatter={formatter} className="slider-area" min={areaValues.min} onAfterChange={handleSearch} max={areaValues.max} step={5} keyboard={true} range defaultValue={[areaValues.min, areaValues.max]} />}
                      </Form.Item>

                      {developerSearch && pricesValues && <Form.Item className="price_Slider" name="price" label="Precio">
                        {(pricesValues.max > 0) && <Slider tipFormatter={formatter} onAfterChange={handleSearch} min={0} max={pricesValues.max} step={500000} range keyboard={true} defaultValue={[0, pricesValues.max]} />}
                      </Form.Item>}

                      </div>

            </Form>

        </div>

        {searchLoading && (<div className="loadingContainer"><LoadingOutlined style={{fontSize: "20px", color: "#0dcaf0"}} /></div>)}
        
        {!searchLoading && brokerSearch && (<div className="search-table-container">
          <RequestList currentRequestLots={currentRequestLots} filterTableList={filterTableList} setFilterTableList={setFilterTableList}
          setShowDetails={setShowDetails} selectedRequest={selectedRequest} setSelectedRequest={setSelectedRequest}
          filter={filter} setFilter={setFilter} />
        </div>)}

        {developerSearch && !searchLoading && lands &&
        <div className="search-table-container"><LandTable 
        setShowDetails={setShowDetails} lands={lands} filter={devFilter}
         setFilter={setDevFilter} setSelectedLand={setSelectedLand}
         requestLandByIdCallback={requestLandByIdCallback}
         selectedLand={selectedLand} /></div>}

        <DetailsModal modalLoading={modalLoading} showDetails={showDetails} 
        closeModal={closeModal} setSelectedRequest={setSelectedRequest}
        requestLandByIdCallback={requestLandByIdCallback} selectedRequest={selectedRequest} />

      </>
    )
}

export default Search
