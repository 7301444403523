
import React, { useState, useContext } from 'react'
import { Modal, message, Row, Col, Divider, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Request from '../../../../services/Request';
import { ButtonApp } from '../../../button/button';
import {Context} from '../../../../context/lotsContext';
import './EditLot.css';
import getLands from '../../../../services/getLands';
import {useAuth} from '../../../../context/AuthContext'
import Spinner from '../../../../assets/spinners/spinner.svg'

//steps
import GeneralInformation from './Steps/GeneralInformation/GeneralInformation';
import Location from './Steps/Location/Location';
import LegalInformation from './Steps/LegalInformation/LegalInformation';
import HttpResponseEmun from '../../../../helpers/enums/HttpResponseEmun';


export default function CreateLots ({ visible, onClose, filter }){
    const [nextVerify, setNextVerify] = useState([])
    const [showSpinner, setShowSpinner] = useState(false);
    const {userData} = useAuth()
    const {
      state: { step, filesToUpload, dataEditLots, dataToUpdateLot, AdditionalData },
      dispatch,
    } = useContext(Context)

const Errors = {
  department: 'Debes seleccionar un departamento',
  city: 'Debes seleccionar una ciudad',
  zone: 'Debes seleccionar una zona',
  chip: 'Debes ingresar un chip',
  registration: 'debes ingresar una matrícula',
  aliasLand: 'Debes ingresar un alias para el Lote',
  area: 'Debes ingresar una área para el Lote', 
  metersPrice: 'Debes ingresar un monto en precio por metro', 
  businessType: 'Debes seleccionar un tipo de negocio', 
  treatment: 'Debes seleccionar un tratamiento',
  map: 'Debes Seleccionar la ubicación haciendo click en el mapa',
  address: 'Debes ingresar una dirección',
}
    
const next = (value, action) => {
  if(value && !action){
    if(!nextVerify.includes(value)){
      setNextVerify([...nextVerify, value])
    }
  }else if(value && action === 'delete'){
    const array = nextVerify.filter(item => item !== value)
    setNextVerify(array)
  }

}
const handleStep = (e) => {
  if(e.target.name === 'next' && step < 3){
    dispatch({type: 'SET_STEP', payload: step + 1})
  }else if(e.target.name === 'back' && step > 1){
    dispatch({type: 'SET_STEP', payload: step - 1})
  }else if(e.target.name === 'ok'){
    onClose()
    dispatch({type: 'RESET'})
  }
}
const getParam = (key) =>{
  switch (key) {
    case 'APORTE':
      return 'INPUT'
    case 'VENTA PARCIAL':
      return 'PARTIAL_SALE'
    case 'VENTA TOTAL':
      return 'TOTAL_SALE'
    case 'CONSOLIDACION':
      return 'CONSOLIDATION'
    case 'CONSERVACION':
      return 'CONSERVATION'
    case 'DESARROLLO':
      return 'DEVELOPING'
    case 'COMERCIAL':
      return 'COMMERCIAL'
    case 'DOTACIONAL':
      return 'DOTATIONAL'
    case 'RESIDENCIAL':
      return 'RESIDENTIAL'
  
    default:
      return key
  }
}

const handleSubmitData = async (e) => {
  setShowSpinner(true)
  for (const property in dataEditLots) {
    if((dataEditLots[property] === '' || dataEditLots[property] === null) && property !== 'observations'){
      message.warning(Errors[property])
      setShowSpinner(false)
      return
    }
  }

 
      const data = await Request.put(`/lands/${dataEditLots._id}`, dataToUpdateLot)
      if(data.status === HttpResponseEmun.OK){
        message.success('Lote actualizado correctamente')
        handleStep(e)
        dispatch({type: 'SET_LOT_DATA_EDIT', payload: data.data})
        dispatch({type: 'RESET_DATA_TO_UPDATE_LOT'})
        //dispatch({type: 'SET_CURRENT_LOTS', payload: {content: [...currentLots.content, data.data]}})
        const res = await getLands(filter, userData._id)
        if(res.status === HttpResponseEmun.OK){
          dispatch({type: 'SET_CURRENT_LOTS', payload: res.data})
        }else{
          message.error('Error al obtener los lotes')
        }
        setShowSpinner(false)
      }else{
        message.error('Error al actualizar el lote')
        setShowSpinner(false)
      }
}
const handleSubmitFiles = async() => {

  try {
    setShowSpinner(true)
    let filesUpload = Object.keys(filesToUpload.type).map(function (key) {return {name: key, file: filesToUpload.type[key]}});
    const AdditionalFiles = AdditionalData.filter((e)=> typeof e.value !== "string")
    const otherInputTypesAdditional = AdditionalData.filter((e)=> typeof e.value === "string")
    filesUpload = [
      ...filesUpload, 
      ...AdditionalFiles.map((e)=> {
        return {name: e.name, file: e.value}
      })
    ]
       dispatch({type: 'SET_FILES', payload: {...filesToUpload, status:{FPCT: 1, FIT: 1, FE: 1, FCTL: 1}}})
    const formData = new FormData()
    let hasFileToSend = false;
    for (let i = 0; i < filesUpload.length; i++) {
      if(!filesUpload[i].file) continue;
      hasFileToSend = true;
      formData.append(`${filesUpload[i].name}`, filesUpload[i].file)
        dispatch({type: 'SET_FILES', payload: {...filesToUpload, status:{...filesToUpload.status, [filesUpload[i].name]: 2}}})
    }
    let dataSchema = {
      fields:[]
    }

    if(hasFileToSend){
      const response = await Request.post(`/file/${dataEditLots._id}?model=Lote`, formData)

      if(!response.data.forEach) response.data = [response.data];

      if(response.status === HttpResponseEmun.OK){
        response.data.forEach((e, i)=>{
          if( e.fieldname === 'FPCT'){
            dataSchema = {
              ...dataSchema,
              permisoPropietarioDoc: e.path
            }
          }else if (e.fieldname === 'FIT'){
            dataSchema = {
              ...dataSchema,
              informacionTopografiaDoc: e.path
            }
          }else if (e.fieldname === 'FE'){
            dataSchema = {
              ...dataSchema,
              escriturasDoc: e.path
            }
          }else if (e.fieldname === 'FCTL'){
            dataSchema = {
              ...dataSchema,
              certificadoTradicionDoc: e.path
            }
          }else{
            dataSchema = {
              ...dataSchema,
              fields: [
                ...dataSchema.fields,
                {
                  ...AdditionalData.find((el)=> el.name === decodeURIComponent(escape(e.fieldname)) ),
                  value: e.path
                }
              ]
            }
          }
        })
      }
    }

    if(otherInputTypesAdditional.length > 0){
      dataSchema = {
        ...dataSchema,
        fields: [
          ...dataSchema.fields,
          ...otherInputTypesAdditional
        ]
      }
    }
    
    const res = await Request.put(`/lands/${dataEditLots._id}`, dataSchema)
    if(res.status === HttpResponseEmun.OK){
      message.success('Archivos enviados con éxito')
      const {status, data} = await getLands(filter, userData._id)
      if(status === HttpResponseEmun.OK){
        dispatch({type: 'SET_CURRENT_LOTS', payload: data})
      }else{
        message.error('Error al obtener los lotes')
      }
          onClose()
          dispatch({type: 'RESET'})
          dispatch({type: 'SET_IS_VISIBLE', payload: {
            toggleState: {
              type: "lands", 
              label: "Mis lotes",
            },
            option: false, 
            type: '', 
            lot: {},
        
          }})
    setShowSpinner(false)
    }else{
      message.error('Error al enviar los archivos')
      setShowSpinner(false)
    }
  } catch (error) {
    message.error('Error al guardar los archivos')
    setShowSpinner(false)
  }
}


    return (
        <>
        <div 
        style={{
          width: '100%', 
          zIndex: 1000,
          position: 'fixed',
          display: 'inline-block',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1001,
          background: 'rgba(0, 0, 0, 0.542)',
          }}
        hidden={!showSpinner}>
          <img src={Spinner} />
      </div>
 <Modal
  headerStyle={{ textAlign: 'left' }}
  width={950}
  style={{ top: 0, maxHeight: '', overflow: 'hidden', padding: 0 }}
  visible={visible}
  destroyOnClose
  closeIcon={
    <Tooltip title="Cerrar" placement="bottom">
      <CloseOutlined 
        onClick={()=>{
          onClose()
          dispatch({type: 'RESET_STEP'})
          dispatch({type: 'RESET_DATA_TO_UPDATE_LOT'})        
        }} 
        style={{ fontSize: '25px', color: '#39C0ED', cursor: 'pointer', width: '100%', height: '100%', display: 'block', paddingTop: '15px' }}/>
    </Tooltip>
  }
  bodyStyle={{ height: 'calc(100vh - 55px)' }}
  footer={
    <>
    <div className='button-modal'>
      {(step >= 1 && step <= 2) &&
      <span style={{margin: '0px 8.5%'}}>
      <ButtonApp
        name='back'
        onClick={handleStep}
        content='Volver'
        primary
        cancel
        disabled={step <= 1}
      />
      </span>
      }
      {(!dataToUpdateLot && step < 3 || step === 1) && 
      <span style={{margin: '0px 7.5%'}}>

        <ButtonApp
          name='next'
          onClick={handleStep}
          content='Siguiente'
          disabled={!nextVerify.includes(step)}
          primary
        />
      </span>
      }
      {step === 2 && dataToUpdateLot &&
      <span style={{margin: '0px 7.5%'}}>
      <ButtonApp
        name='next'
        onClick={handleSubmitData}
        //disabled={!nextVerify.includes(step)}
        content='Guardar Cambios'
        primary
      />
      </span>
      }
      {step >= 3 &&
      <ButtonApp
        name='finish'
        onClick={()=>{
          handleSubmitFiles()
        }}
        content='Enviar archivos'
        primary
      />
      }
    </div>
    </>
  }
  >
<Row>
  <Col span={24}>
  <div className='step-selector-lots'>
          <div
            className={`info-select ${step === 1 && 'active'}`}
          >
            <span className='links'>
              1. Información general
            </span>
          </div>
          <div
            className={`info-select ${step === 2 && 'active'}`}
          >
            <span className='links'>
              2. Ubicación
            </span>
          </div>
          <div className={`info-select ${ step === 3 && 'active'}`}>
            <span className='links'>
              3. Información Legal
            </span>
          </div>
    </div>
  </Col>
</Row>
{step !== 3 &&
 <Row>
  <Col span={24}>
        <span className='subtitle'>
          Para ingresar un lote primero ingresa la informacion general del mismo.
        </span>
  </Col>
  <Divider style={{margin: '14px 0'}}/>
</Row>}
    <div className='steps'>
      {step === 1 && (<GeneralInformation next={next}/>)}
      {step === 2 && (<Location next={next}/>)} 
      {step === 3 && (<LegalInformation next={next}/>)}
    </div>
</Modal >
</>
    )
}