import React, { useState, useEffect,useContext } from 'react';
import { message, Modal, Empty } from 'antd';
import { MinusOutlined, EditTwoTone, UserSwitchOutlined } from '@ant-design/icons';
import { Pagination, Popconfirm, Tooltip } from 'antd';
import { ButtonApp } from '../../button/button';
import Request from '../../../services/Request';
import {EditUser} from '../EditDrawer/Edit';
import { Filter } from '../../AdminPanelFilter/AdminPanelFilter';
import sort from '../../handleSort'
import HttpResponseEmun from '../../../helpers/enums/HttpResponseEmun';
import shortText from '../../../helpers/shortText';
const theadDevelopers = [
    {item:'name', label:'Nombre'},
    {item:'email', label:'Email'},
    {item:'type', label:'Tipo'},
    {item:'', label:'Opciones'},
  ]
  const FilterSvg = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filter-left" viewBox="0 0 16 16">
        <path d="M2 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
    </svg>
const UserDetails = ({developer, visibleUsers, screenSize, onCloseUsers}) => {
    const [showEdit, setShowEdit] = useState(false)
    const [users, setUsers] = useState([])
    const [dataUserEdit, setDataUserEdit] = useState({})
    const [params, setParams] = useState({})
    const [filter, setFilter] = useState({searchParam: "", statusParam: "ACTIVE", page: 0, showRows: 10})

    useEffect(() => {
      const getUsers = async () => {
        try {
          const response = await Request.get(`/developers/users/${developer._id}`, {...filter})
          if (response.status === HttpResponseEmun.OK) {
                                  setParams({totalElements: response.data.data.totalElements, page: response.data.data.page})
            setUsers(response.data.data.content)
          }
        } catch (error) {
          error && console.error(error)
        }
      }
      if(developer._id && visibleUsers === true){
        getUsers()
      }
    }, [filter, developer])

    
const handleSort = (item) => {
  setFilter({...filter, ordering: sort(item)}) 
 }
 const handleShowEdit = () => {
  setShowEdit(true);
  
}
 const onCloseEdit = () => {
  setShowEdit(false);
}

  return (
    <>
      <Modal
        visible={visibleUsers}
        title="Usuarios"
        onCancel={onCloseUsers}
        width={1000}
        footer={[
          <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

            <ButtonApp content='Cerrar' primary cancel onClick={onCloseUsers} />
          </div>
        ]}
      >
        <EditUser visibleEdit={showEdit} onCloseEdit={onCloseEdit} user={dataUserEdit} />
        <Filter filterUser={filter} setFilteruser={setFilter} defaultChecked={filter.statusParam}/>
        <div >   
            <table >
            <thead className="HeadListUser-admin" >
                  <tr >
                    {theadDevelopers.map((e, i)=>{
                    return(
                    <th key={i} scope="col" className={`col-listUser-adminPanel col${e.item === '' ? 7 : i+1}`} onClick={() => { handleSort(e.item) }} style={{ cursor: 'pointer' }}>
                    <span style={{ marginRight: '0.5rem' }} >{e.label}</span>
                    {FilterSvg}
                    </th>)
                    
                  })}
                  </tr>
              </thead>
              <tbody>
                <tr className='table-row-s'/>
                {users && users.length === 0 && 
                  <tr>
                    <td colSpan={theadDevelopers.length}>
                    <Empty 
                      description={
                        <span>
                          No se encontraron Usuarios
                        </span>}
                      image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    
                    </td>
                  </tr>
                  }
                  {users && users.length > 0 && 
                  users.map((user, i) => {                    
                    return (
                        
                      <tr key={i} className='table-row-body'>
                        <td className='col-listUser-adminPanel col1' scope="row">{screenSize < 450 ? shortText(user.names, 12) : `${user.names} ${user.lastNames}`}</td>
                        <td className='col-listUser-adminPanel col2' scope="row">{screenSize < 450 ? shortText(user.email, 8) : user.email}</td>
                        <td className='col-listUser-adminPanel col3' scope="row">{user.type}</td>
                        <td className='col-listUser-adminPanel col7' scope="row" style={screenSize < 450 ? {flexDirection: 'column', display: 'flex'} : {}}>
                          <span className='divider'><MinusOutlined style={{ fontSize: '1.5rem', color: 'gray', transform: 'rotate(90deg)' }} /></span>
                          
                          <span style={{ cursor: 'pointer', margin: '0 5px' }} >
                              <Tooltip placement="top" title='Editar'>
                                  <EditTwoTone onClick={() => {
                                      handleShowEdit()
                                      setDataUserEdit(user)
                                      }} style={{ fontSize: '1.5rem' }} />
                              </Tooltip>
                          </span>
                          <span style={{margin: '0 5px'}}>
                              <Popconfirm
                              title={`¿Estas seguro que quieres ${user.status === 1 ? 'desactivar' : 'activar'} este usuario?`}
                              onConfirm={() => {
                                const userStatus = user.status === 1 ? 0 : 1
                              Request.put(`/users/${user._id}`, {status: userStatus})
                              .then(res => {
                              if (res.status === HttpResponseEmun.OK) {
                              message.success('Solicitud exitosa')
                              let newListUser = users.filter(e => {
                              return e._id !== user._id
                              })
                              setUsers(newListUser)
                              }
                              })
                              .catch(err => { console.error(err) })
                              }}
                              okText="Si"
                              cancelText="No"
                              >
                            <Tooltip placement='top' title={user.status === 1 ? 'Desactivar' : 'Activar'}>
                              <UserSwitchOutlined style={{ cursor: 'pointer', fontSize: '1.5rem', color: user.status === 1 ? '#FF9100' : '#00B74A' }} />
                            </Tooltip>
                              </Popconfirm>
                              </span>
                        </td>
                      </tr>
                      )
                    })}

              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="7" align='right'>
                    <Pagination
                        current={params.page +1}
                        onChange={(current, pageSize)=> setFilter({...filter, page: current -1, showRows: pageSize})}
                        defaultCurrent={1}
                        total={params.totalElements}
                        showTotal={(total) => `Total ${total}`}
                        responsive
                        disabled={params.totalElements >  params.size ? false : true}
                        showSizeChanger={false}
                      />
                  </td>
                </tr>
              </tfoot>
              
            </table>
        </div>

      </Modal>
    </>
  );
};

export default UserDetails;