import React, { useContext, useState, useEffect, useCallback } from 'react';

import ProfileBannerDeveloper from './ProfileBannerDeveloper';
import RequestList from './../../pages/Search/RequestList';
import DetailsModal from '../../pages/Search/DetailsModal';
import getAllRequestLands from '../../services/getAllRequestLands';
import ProfileDeveloperFilter from './ProfileDeveloperFilter';


// imports to load lands in the lotsContext
import {useAuth} from '../../context/AuthContext';
import {Context} from '../../context/lotsContext';
import getLandsToOffer from '../../services/getLandsToOffer';
import { message } from 'antd';

import UserModelsEnum from '../../helpers/enums/UserModelsEnum';
import HttpResponseEmun from '../../helpers/enums/HttpResponseEmun';

// styles
import './index.css';
import SkeletonComponent from '../Home/HomeBroker/SkeletonLoader/SkeletonLoader';

function ProfileDeveloper({developerId}) {

        const [filterTableList, setFilterTableList] = useState({page: 0, showRows: 10, statusParam: 'ACTIVE' });
        const [filter, setFilter] = useState({page: 0, showRows: 10, associateTypeParam: developerId, statusParam: 'ACTIVE' });
        const [currentRequestLots, setCurrentRequestLots] = useState([]);
        const [showDetails, setShowDetails] = useState({show: false, request: {}});
        const [searchParams, setSearchParams] = useState({});
        const [searchLoading, setSearchLoading] = useState(true);
        const [modalLoading, setModalLoading] = useState(false);
    
        const [selectedRequest, setSelectedRequest] = useState("");
    
        const {user, userData} = useAuth();
        const { dispatch } = useContext(Context);

        const closeModal = () => {
            setShowDetails({show: false, request: {}})
        }
    
        const getRequestCallback = useCallback(
          (searchParams, filter) => {
            if(user && user.model !== UserModelsEnum.Broker) return;
            setSearchLoading(true)
            getAllRequestLands(searchParams, filter).then(res => {
             
              setCurrentRequestLots(res);
              setSearchLoading(false);
            });
          },
          [searchParams, filter],
        );
    
        useEffect(() => {
          getRequestCallback(searchParams, filter);
        }, [searchParams, filter]);
    
        const fetchDataLandsCallback = useCallback(
          async (filterTableList, selectedRequest) => {
            dispatch({ type: 'SET_IS_VISIBLE_HB', payload: {option: false, 
              type: '', lot: {}, toggleState: {type: "lands", label: "Mis lotes"}}});
            dispatch({ type: 'RESET_CURRENT_LOTS'});
            const response = await getLandsToOffer(filterTableList, userData._id, selectedRequest);
            if (response.status === HttpResponseEmun.OK) {
              dispatch({ type: 'SET_CURRENT_LOTS', payload: {type: "lands", ...response.data} })
            }else{
              message.error('Error al cargar los lotes')
            }
            },
          [],
        );
    
    
        useEffect(() => {
          if(selectedRequest){
              fetchDataLandsCallback(filterTableList, selectedRequest);
          }
        }, [filterTableList, userData, user, selectedRequest]);

    return (
    <>
        <ProfileBannerDeveloper />


        <div className="profile-developer-table">

            <ProfileDeveloperFilter changeFilters={(newFilters) => setFilter({...filter, ...newFilters})} />

            {!searchLoading && (<div className="search-table-container">
                <RequestList currentRequestLots={currentRequestLots} filterTableList={filterTableList} setFilterTableList={setFilterTableList}
                setShowDetails={setShowDetails} selectedRequest={selectedRequest} setSelectedRequest={setSelectedRequest}
                filter={filter} setFilter={setFilter} />
            </div>)}

            {searchLoading && (<div className="search-table-container"><SkeletonComponent /></div>)}

        </div>

        <DetailsModal modalLoading={modalLoading} showDetails={showDetails} 
        closeModal={closeModal} setSelectedRequest={setSelectedRequest} selectedRequest={selectedRequest} />
    </>)
}

export default ProfileDeveloper
