import React,{ createContext,useState,useEffect } from "react";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {  
  const [themeDefault, setThemeDefault] = useState({
    'background-color':"#272a30",
    'color':"#fafafa ",
  })
  const [themeColor, setThemeColor] = useState(  
    localStorage.getItem('theme') ?
    {
      'background-color':JSON.parse(localStorage.getItem("theme"))['background-color'],
      'color':JSON.parse(localStorage.getItem("theme"))['color'],
    } :  
    {
      'background-color':"#272a30",
      'color':"#fafafa ",
    }
  )
 

  return(
    <ThemeContext.Provider value={{themeColor,setThemeColor,themeDefault, setThemeDefault}}>
      {children}
    </ThemeContext.Provider>
  )
  
}