import React, { useState } from 'react'
import './RegisterCorredor.css'

import constructora from '../../assets/icons/domain1.png'
import constructoraBlue from '../../assets/icons/domain.png'
import money from '../../assets/icons/money.png'
import moneyBlue from '../../assets/icons/money_blue.png'
import person from '../../assets/icons/person.png'
import personBlue from '../../assets/icons/personblue.png'
import otro from '../../assets/icons/otro.png'
import otroBlue from '../../assets/icons/otroblue.png'
import { useRegister } from '../../context/RegisterContext'

const TypeCorredor = () => {
  const { setStep, userToRegister, setUserToRegister, clearUserRegisterData } =
    useRegister()

  const [toggleState, setToggleState] = useState(
    userToRegister.type || 'Comisionista'
  )
  const [OtroState, setOtroState] = useState({
    camp: !!userToRegister.otherType,
    err: false,
    valorInput: userToRegister.otherType,
  })

  const toggleTab = (index) => {
    setToggleState(index)
  }
  const handleChange = (event) => {
    if (event.target.value === '') {
      setOtroState({ camp: false, err: true, valorInput: '' })
    } else {
      setOtroState({ camp: true, err: false, valorInput: event.target.value })
    }
  }

  const NextForm = () => {
    const saveformTypeDesarrollador = {
      type: toggleState, // Generamos una id rápida
      otherType: OtroState.valorInput,
    }
    setUserToRegister({ ...userToRegister, ...saveformTypeDesarrollador })
    setStep(2)
  }

  return (
    <div className='type-broker'>
      <div className='container'>
        <h1 className='select--corredor'>
          Selecciona qué tipo de corredor eres
        </h1>
        <div className='bloc-tabs'>
          <button
            className={
              toggleState === 'Comisionista' ? 'tabs active-tabs' : 'tabs'
            }
            onClick={() => toggleTab('Comisionista')}
          >
            <div>
              <span
                className={
                  toggleState === 'Comisionista' ? 'optionSelec' : null
                }
              >
                {' '}
                <img
                  className='img-fluid'
                  src={toggleState === 'Comisionista' ? moneyBlue : money}
                  alt=''
                />{' '}
                <br />
                Comisionista
              </span>
            </div>
          </button>
          <button
            className={
              toggleState === 'Empresa inmobiliaria'
                ? 'tabs active-tabs'
                : 'tabs'
            }
            onClick={() => toggleTab('Empresa inmobiliaria')}
          >
            <div>
              {' '}
              <img
                className='img-fluid'
                src={
                  toggleState === 'Empresa inmobiliaria'
                    ? constructoraBlue
                    : constructora
                }
                alt=''
              />
              <br />
              <span
                className={
                  toggleState === 'Empresa inmobiliaria' ? 'optionSelec' : null
                }
                alt=''
              >
                Empresa inmobiliaria
              </span>
            </div>
          </button>
          <button
            className={
              toggleState === 'Propietario' ? 'tabs active-tabs' : 'tabs'
            }
            onClick={() => toggleTab('Propietario')}
          >
            <div>
              <img
                className='img-fluid'
                src={toggleState === 'Propietario' ? personBlue : person}
                alt=''
              />
              <br />
              <span
                className={toggleState === 'Propietario' ? 'optionSelec' : null}
              >
                Propietario
              </span>{' '}
            </div>
          </button>

          <button
            className={toggleState === 'Otro' ? 'tabs active-tabs' : 'tabs'}
            onClick={() => toggleTab('Otro')}
          >
            <div>
              <img
                className='img-fluid'
                src={toggleState === 'Otro' ? otroBlue : otro}
                alt=''
              />
              <br />
              <span className={toggleState === 'Otro' ? 'optionSelec' : null}>
                Otro
              </span>{' '}
            </div>
          </button>
        </div>

        <div className='content-tabs'>
          <div
            className={
              toggleState === 'Comisionista'
                ? 'content active-content'
                : 'content'
            }
          >
            <h2 className='titActive'>Comisionista</h2>

            <p>
              Eres un profesional independiente que se encarga de intermediar la
              negociación de dos clientes (vendedor y comprador) en una
              transacción de bienes raíces. Donde no solamente te encargas de
              presentar a las partes y enseñar los inmuebles, si no que vas
              mucho mas allá siendo un asesor integral en todos los aspectos del
              negocio y alcanzando siempre que las partes lleven a feliz término
              la transacción de una forma tranquila, segura y basados en todos
              los requerimientos de ley.
            </p>
          </div>

          <div
            className={
              toggleState === 'Empresa inmobiliaria'
                ? 'content active-content'
                : 'content'
            }
          >
            <h2 className='titActive'>Empresa Inmobiliaria</h2>

            <p>
              Eres una compañía dedicada a la compra y venta de propiedades,
              tales como, lotes, casas, apartamentos, oficinas, locales, entre
              otros. Generalmente un comprador y un vendedor se ponen en
              contacto con ellos para entrar en las negociaciones
              correspondientes.
            </p>
          </div>

          <div
            className={
              toggleState === 'Propietario'
                ? 'content  active-content'
                : 'content'
            }
          >
            <h2 className='titActive'>Propietario</h2>

            <p>
              Eres propietario y/o poseedor de un lote y quieres encontrar no
              solo el mejor negocio si tambien al mejor comprador.
            </p>
          </div>

          <div
            className={
              toggleState === 'Otro'
                ? 'content other active-content'
                : 'content other'
            }
          >
            <h2 className='titActive'>Otro</h2>

            <p>*Especifica que tipo de corredor eres</p>
            <div className='form-group row'>
              <div className='col-sm-12'>
                <input
                  name='OtroType'
                  type='text'
                  className={
                    OtroState.err === false
                      ? 'form-control input-otro'
                      : 'form-control input-OtroError'
                  }
                  id='otro'
                  placeholder='Tipo de corredor'
                  onChange={handleChange}
                  useref='Otrotype'
                  value={OtroState.valorInput}
                />
              </div>
            </div>
            {OtroState.err === true ? (
              <div className='form-label Mensaje-ErrorInput'>
                Este campo es obligatorio
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className='container sectionBtn'>
        <div className='row'>
          <div className='col'>
            <button className='btn btn-volver' onClick={clearUserRegisterData}>
              Cancelar registro
            </button>
          </div>
          <div className='col'>
            <div className='btn-holder'>
              <button
                className={
                  toggleState === 'Otro'
                    ? OtroState.camp === false
                      ? 'btn btn-endNextOtro'
                      : 'btn btn-endNext'
                    : 'btn btn-endNext'
                }
                onClick={NextForm}
              >
                Siguiente
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TypeCorredor
