import React, {useState, useEffect, useRef} from 'react';
import { Col, Row, Form, Select, Input, Slider } from 'antd';

import Request from '../../../services/Request';
import { useAuth } from '../../../context/AuthContext'
import getCities from '../../../services/getCities';
import UserModelsEnum from '../../../helpers/enums/UserModelsEnum';
import { getAreaMax, getAreaMin } from '../../../services/getArea';
import formatter from '../../../helpers/numberFormatter';

import { DownOutlined } from '@ant-design/icons';
import './index.css';
import CustomSlider from '../../CustomSlider/CustomSlider';

const {Option} = Select;

function ProfileDeveloperFilter({changeFilters}) {

    const [showFilters, setShowFilters] = useState(false);

    const toggleShowFilters = () => {
        setShowFilters(!showFilters);
    }

    const timer = useRef(null);

    const [isMounted, setIsMounted] = useState(true);
    const [city, setCity] = useState('');
    const [neighborhood, setNeighborhood] = useState('');
    const [search, setSearch] = useState('');
    const [area, setArea] = useState([]);
    const [serverArea, setServerArea] = useState([]);
    const [cities, setCities] = useState([]);
    const [neighborhoods, setNeighborhoods] = useState([]);
    const [params, setParams] = useState({});


    const { user } = useAuth();


    const getLocationListCity = async (searchParamCity) => {
      if(isMounted){

        const resCities = await getCities(searchParamCity);
        const cities = resCities.data && [...new Set( resCities.data.content.map(e => e.city) )];
        setCities(cities);
        setNeighborhoods(neighborhoods);
      }
    }

    const getLocationListNeighborhood = async (searchParamNeighborhood) => {
        if(isMounted){
  
          // If a city is selected it only will show the neighborhood on that city
          const cityParamForNeighborhoods = city ? "&cityParam="+city : "";
  
          const resNeighborhoods = await Request.get("/geographical-area?ordering=neighborhood,asc&showRows=999"+cityParamForNeighborhoods, {searchParam: searchParamNeighborhood});
          const neighborhoods = [...new Set( resNeighborhoods.data.content.map(e => e.neighborhood) )];
          setNeighborhoods(neighborhoods);
        }
    }

    const getArea = async () => {
      if(isMounted){
        const resMin = await getAreaMin();
        const resMax = await getAreaMax();
        const min = resMin.data && resMin.data.content[0].startRange;
        const max = resMax.data && resMax.data.content[0].endRange;
        setServerArea([min, max]);
      }
    }

    useEffect(() => {
      getLocationListCity();
      getLocationListNeighborhood();
      getArea();
      return () => {
        setIsMounted(false)
      }
    }, []);
    

    useEffect(() => {
        changeFilters(params);
    }, [params]);

    useEffect(() => {
        getLocationListNeighborhood();
    }, [city])

    useEffect(() => {

      setParams({
        cityParam: city, startAreaRangeParam: area[0],
        neighborhoodParam: neighborhood,
        endAreaRangeParam: area[1]
      });

    }, [city, area, neighborhood]);

    useEffect(() => {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        if(user && user.model === UserModelsEnum.Broker) setParams({...params, searchParam: search});
      }, 800);
    }, [search, user]);


    return (

    <Form initialValues={{
      }}
    >
        <Row className="profile-developer-filter">
            <Col xs={20}>
                <h6>Mis solicitudes</h6>
            </Col>
            <Col xs={4}>
                <div onClick={toggleShowFilters} role="button" className="toggle-filter">
                    <p>Filtros</p>
                    <DownOutlined className={`down-icon ${showFilters ? 'rotate-90deg' : ''}`} />
                </div>
            </Col>

            <Row className={`profile-developer-filter-row ${showFilters ? 'showFilters' : ''}`} style={{width: "100%", marginTop: "1rem"}}>
            <Col className='col-f' style={{display: 'flex'}} xs={24} md={4}>
                <label className='title-f' htmlFor='search'>BÚSQUEDA</label>
                <Form.Item id="search" name="search">
                    <Input value={search} onChange={e => setSearch(e.currentTarget.value)} placeholder="Búsqueda"></Input>
                </Form.Item>
                </Col>

                <Col className='col-f' style={{display: 'flex'}} xs={24} md={4}>
                <label className='title-f' htmlFor='city'>CIUDAD</label>
                <Form.Item id="city" name="city">
                    <Select value={city} onChange={(e) => setCity(e)} onSearch={getLocationListCity} showSearch placeholder="Ciudad" allowClear>
                    {cities.map((city, i) => <Option key={i} value={city}>{city}</Option>)}
                    </Select>
                </Form.Item>
                </Col>

                <Col className='col-f' style={{display: 'flex'}} xs={24} md={4}>
                <label className='title-f' htmlFor='neighborhood'>BARRIO</label>
                <Form.Item id="neighborhood" name="neighborhood">
                    <Select value={neighborhood} onChange={(e) => setNeighborhood(e)} onSearch={getLocationListNeighborhood} showSearch placeholder="Barrio" allowClear>
                    {neighborhoods.map((neighborhood, i) => <Option key={i} value={neighborhood}>{neighborhood}</Option>)}
                    </Select>
                </Form.Item>
                </Col>
                

                { serverArea.length > 0 && <Col className='col-f' style={{display: 'flex'}} xs={24} md={4}>
                <label className='title-f' htmlFor='area'>ÁREA</label>
                <Form.Item name="area" id="area">
                    <CustomSlider tipFormatter={formatter} min={serverArea[0]} max={serverArea[1]} defaultValue={[serverArea[0], serverArea[1]]} onAfterChange={e => setArea(e)} range />
                </Form.Item>
                </Col> }
            
            </Row>

        </Row>
    </Form>)
}

export default ProfileDeveloperFilter
