import React, {useContext, useState, useEffect} from "react"
import { Col, Divider, Pagination, Row, Select, Space } from "antd"
import '../styles.css'
import Maps from "./Map"
import {Context} from '../../../../../../context/lotsContext'
import useLocationSelector from "../../../../../../hooks/useLocationSelector"
import departamentos from "../../../../../../helpers/states";
const { Option } = Select

export default function Location ({next}){
  const {
    state: { newRequestLot, step},
  } = useContext(Context)
  const [coords, setCoords] = useState([]);
  const [centerMap, setCenterMap] = useState([]);

  useEffect(() => {
  
    if(newRequestLot.location !== null){
      next(step)
    }else{
      next(step, 'delete')
    
    }
    }, [newRequestLot]) 

 /*  //verificar si un marcador esta dentro de un poligono
  const isInside = (marker, polygon) => {
    var x = marker.latitude, y = marker.longitude;
    var inside = false;
    for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        var xi = polygon[i].latitude, yi = polygon[i].longitude;
        var xj = polygon[j].latitude, yj = polygon[j].longitude;
        var intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
} */
const onChange = async (e) => {
  
  if(e.target.name === 'department'){
    const departmentCoords = departamentos.find((dep)=> dep.departamento === e.target.value)
    setCenterMap({lat: departmentCoords.lat, lng: departmentCoords.lng})
  }
}


const auto_grow = (e) => {
  e.target.style.height = "5px";
  e.target.style.height = (e.target.scrollHeight)+"px";
}

const {options, selectedOptions, onChangeHandler,
  observations, setObservations} = useLocationSelector(
    {setCoords, createType: 'SET_NEW_REQUEST_LOT'}
  );




return(
  <div className='container-general-information'>
      <div className='container-fluid container-global'>
      <Row>
      <Col xs={{ span: 24}} md={{ span: 9}} className='location-responsive'>
      <div className='block-from'>
              <div className='form-row'>
                <div className='form-group'>
                    <label className='tit-input'>
                      Departamento
                      <span className='text-danger'> *</span>
                    </label>
                    <Select
                        value={selectedOptions.department}
                        className='form-control form-info-corredor'
                        bordered={false}
                        loading={false}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        placeholder='Seleccione un Departamento'
                        style={{padding: '0.375rem 0.75rem', height: '39px'}}
                        onChange={ (e)=> {
                          onChangeHandler(e, "department")
                          onChange({target:{name: 'department', value: e}})
                        }}
                       >
                       { options.department.map((item, i)=>{
                        return(
                          <Option key={i} value={item.name}>{item.name}</Option>
                        )
                        })}
                    </Select>
                </div>
              </div>
              <div className='form-row'>
                <div className='form-group'>
                  <label className='tit-input'>
                    Ciudad
                    <span className='text-danger'> *</span>
                  </label>
                  <Select
                        value={selectedOptions.city}
                        className='form-control form-info-corredor'
                        bordered={false}
                        loading={false}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        placeholder='Seleccione una ciudad'
                        style={{padding: '0.375rem 0.75rem', height: '39px'}}
                        disabled={selectedOptions.department === '' ? true : false}
                        onChange={(e)=>onChangeHandler(e, 'city')}
                       >
                      {options.city.map((item, i)=>{
                        return(
                          <Option key={i} value={item.name}>{item.name}</Option>
                        )
                        })}
                    </Select>
                </div>
              </div>
              {options.location && options.location.length > 0 &&
              <div className='form-row'>
                <div className='form-group'>
                    <label className='tit-input'>
                      Localidad
                    </label>
                    <Select
                        className='form-control form-info-corredor'
                        bordered={false}
                        loading={false}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        placeholder='Seleccione una localidad'
                        style={{padding: '0.375rem 0.75rem', height: '39px'}}
                        onChange={(value)=>onChangeHandler(value, 'location')}
                        value={selectedOptions.location}
                       >
                       {options.location && options.location.length > 0 && options.location.map((item, i)=>{
                          return(
                            <Option key={i} value={item.name}>{item.name}</Option>
                          )
                        })}
                    </Select>
                </div>
              </div>}
              {options.commune && options.commune.length > 0 &&
              <div className='form-row'>
                <div className='form-group'>
                    <label className='tit-input'>
                      Comuna
                    </label>
                    <Select
                        className='form-control form-info-corredor'
                        bordered={false}
                        loading={false}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        placeholder='Seleccione una Comuna'
                        style={{padding: '0.375rem 0.75rem', height: '39px'}}
                        onChange={(value)=>onChangeHandler(value, 'commune')}
                        value={selectedOptions.commune}
                       >
                       {options.commune && options.commune.length > 0 && options.commune.map((item, i)=>{
                          return(
                            <Option key={i} value={item.name}>{item.name}</Option>
                          )
                        })}
                    </Select>
                </div>
              </div>}
              {options.UPZ && options.UPZ.length > 0 &&
              <div className='form-row'>
                <div className='form-group'>
                    <label className='tit-input'>
                    UPZ
                    </label>
                    <Select
                        className='form-control form-info-corredor'
                        bordered={false}
                        loading={false}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        placeholder='Seleccione un UPZ'
                        style={{padding: '0.375rem 0.75rem', height: '39px'}}
                        onChange={(value)=>onChangeHandler(value, 'UPZ')}
                        value={selectedOptions.UPZ}
                       >
                       {options.UPZ && options.UPZ.length > 0 && options.UPZ.map((item, i)=>{
                          return(
                            <Option key={i} value={item.name}>{item.name}</Option>
                          )
                        })}
                    </Select>
                </div>
              </div>}
              
              {options.neighborhood && options.neighborhood.length > 0 && 
              <div className='form-row'>
                <div className='form-group'>
                    <label className='tit-input'>
                    Barrio
                    </label>
                    <Select
                        className='form-control form-info-corredor'
                        bordered={false}
                        loading={false}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        placeholder='Seleccione un Barrio'
                        style={{padding: '0.375rem 0.75rem', height: '39px'}}
                        //disabled={params.cityParam !== '' ? false : true}
                        onChange={(value)=>onChangeHandler(value, 'neighborhood')}
                        value={selectedOptions.neighborhood}
                       >
                       {options.neighborhood && options.neighborhood.length > 0 && options.neighborhood.map((item, i)=>{
                          return(
                            <Option key={i} value={item.name}>{item.name}</Option>
                          )
                        })}
                    </Select>
                </div>
              </div>
              }
              <div className='form-row'>
                <div className='form-group'>
                  <label className='tit-input'>
                    Observaciones
                  </label>
                  <textarea
                    style={{resize: 'none', overflow: 'hidden'}}
                    rows="2" 
                    cols="10"
                    name='observations'
                    className='form-control form-info-corredor'
                    placeholder='Cerca a vías principales, buena ubicación'
                    onChange={(e) => setObservations(e.target.value)}
                    value={observations}
                    onInput={auto_grow}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={{ span: 24}} md={{ span: 14, offset: 1 }}>
            <div className="map">
            <Maps LatLng={coords} onChange={()=>{}} center={centerMap}/>
            </div>
          </Col>
        </Row>
</div>
</div>
    )
}