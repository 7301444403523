import React from 'react';
import { useContext } from 'react';
import { Context } from '../../../context/lotsContext';
import Spinner from '../../../assets/spinners/spinner.svg';
import {Tooltip, Modal} from 'antd';
import {CloseOutlined} from '@ant-design/icons';

import LotsDetails from '../../../components/Home/HomeDeveloper/lotsDetails/lotsDetails';


const centerStyles = {
    width: '100%', 
    zIndex: 1000,
    position: 'fixed',
    display: 'inline-block',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1001,
    background: 'rgba(0, 0, 0, 0.542)',
    
}

function LandModal({showSpinner}) {

    const {state: {isVisible}, dispatch} = useContext(Context);

    const closeIsVisible = () =>{
        dispatch({
          type: 'SET_IS_VISIBLE',
          payload:{
            toggleState: {
                ...isVisible.toggleState
            },
            option: false, 
            type: isVisible.type, 
            lot: {}
          }
        })
    }
    

    return (
        <>
        <div style={centerStyles}
              hidden={!showSpinner}>
                <img src={Spinner} />
            </div>
            <Modal
              width={isVisible && isVisible.type === 'details' ? 750 : 400}
              visible={isVisible && isVisible.option}
              destroyOnClose
              closeIcon={
                <Tooltip title="Cerrar">
                  <CloseOutlined onClick={()=> closeIsVisible()} style={{ fontZise: '30px', color: '#39C0ED'  }}/>
                </Tooltip>
              }
              footer=''
              centered
              >
                {isVisible && isVisible.type === 'details' && <LotsDetails lotsData={{data:isVisible.lot}} closeIsVisible={closeIsVisible}/>}
            </Modal>
        </>
    )
}

export default LandModal
