import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Drawer, Input, Collapse, Avatar, Tooltip, Pagination, Divider, Badge } from 'antd';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';

import './AssociateBrokerDrawer.css';
import { useAuth } from '../../context/AuthContext';
import UserModelsEnum from '../../helpers/enums/UserModelsEnum';
import getPicture from '../../helpers/getPicture';
import { ProfileModalContext } from '../../context/ProfileModalContext';
import ProfileModal from '../ProfileModal/ProfileModal';
import { getAllBrokers } from '../../services/brokers';
import SkeletonAssociateBroker from './SkeletonAssociateBroker/SkeletonAssociateBroker';
import { ChatContext } from '../../context/ChatContext';
import BlockButton from '../BlockButton/BlockButton';
import { associateStates } from '../../services/allies';
import UnblockButton from '../UnblockButton/UnblockButton';

function AssociateBrokerDrawer({show, closeDrawer}) {

    const [loading, setLoading] = useState(true);

    const [drawerWidth, setDrawerWidth] = useState("640px");
    
    const [searchParam, setSearchParam] = useState(null);
    const [brokers, setBrokers] = useState([]);

    const [filter, setFilter] = useState({page: 0, showRows: 10, statusParam: 'ACTIVE' });

    const {userData} = useAuth();
    const { setProfileData } = useContext(ProfileModalContext);
    const {setDeveloperAssociate, setBrokerAssociate, createAssociateChat} = useContext(ChatContext);

    const viewProfile = (e, broker) => {
        e.stopPropagation();
        if(broker.associatedUserState === associateStates.inactive || broker.associatedUserState === associateStates.inactiveState) return;
        setProfileData({
            show: true,
            profileData: broker
        });
    }

    const getBrokersCallback = useCallback(async () => {
        setLoading(true);
        const response = await getAllBrokers(filter);
        setBrokers(response);
        setLoading(false);
    }, [filter, userData]);

    const refreshData = useCallback(() => {
        applyFilterCallback(searchParam);
    }, [searchParam]);

    const applyFilterCallback = useCallback(async (searchParam) => {
        setLoading(true);
        const response = await getAllBrokers({...filter, searchParam});
        setBrokers(response);
        setLoading(false);
    }, [filter]);

    const openChat = (e, broker) => {

        e.stopPropagation();

        setBrokerAssociate(broker.user);
        setDeveloperAssociate(userData);

        createAssociateChat()
        
        closeDrawer();
    }

    useEffect(() => {
        if(show){
            getBrokersCallback();
        }
    }, [show, filter]);

    useEffect(() => {
      const debounceTimer = setTimeout(() => {
        if(searchParam !== null){
          applyFilterCallback(searchParam);
        }
      }, 800);
      return () => clearTimeout(debounceTimer);
    }, [searchParam]);

    useEffect(() => {
        if(window.innerWidth < 535) setDrawerWidth('100%');
    }, [window.innerWidth]);

    return (
        <>
          <Drawer 
          width={drawerWidth} 
          title="Corredores Asociados" 
          placement="right" 
          onClose={closeDrawer} 
          visible={show}
          headerStyle={{color: '#fff'}}
          className="brokerDrawer">
            <Input onChange={e => setSearchParam(e.target.value)} value={searchParam} name="search" type="search" autoFocus style={{backgroundColor: "transparent"}} suffix={
            <SearchOutlined className="site-form-item-icon search-input-icon" />} placeholder="Buscar aliado" />

            {loading ? 
                <SkeletonAssociateBroker />
            : null}

            <table className="land-simple-table mt-4">
                <tbody>
                    {brokers && brokers.content && brokers.content.map((broker) => {
                        const isActive = (broker.associatedUserState === associateStates.active || broker.associatedUserState === associateStates.activeState);
                        const brokerName = `${broker.user.names} ${broker.user.lastNames}`;
                        if(!broker.user || !broker.user.isPublic) return null;
                        return (<React.Fragment key={broker._id}><tr onClick={(e) => viewProfile(e, broker)} className='land-row border-table'>
                        <td>
                        {
                            isActive ? (<Tooltip title="Ver perfil">
                            <Avatar 
                                style={{marginRight: '1rem'}} 
                                className="requestLand-avatar" 
                                icon={<UserOutlined />} 
                                src={getPicture(broker.user._id, broker.user.photo, UserModelsEnum.User)}
                                size={35}
                            />
                            </Tooltip>) : (<Avatar 
                                style={{marginRight: '1rem'}} 
                                className="requestLand-avatar" 
                                icon={<UserOutlined />} 
                                src={getPicture(broker.user._id, broker.user.photo, UserModelsEnum.User)}
                                size={35}
                            />)
                        }
                        
                        </td>
                        <td>{brokerName}</td>
                        <td className='d-md-table-cell d-none' >{broker.user && broker.user.email}</td>
                        <td style={{textAlign: "end"}} className='col-listUser-adminPanel col7' scope="row">
                            {isActive && <><Tooltip title="Conversar">
                            <span 
                                onClick={(e) => openChat(e, broker)}>
                                <svg className="contactSvg me-md-0 me-2" width="28" height="28" viewBox="0 0 28 28" fill="#64CBF7" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3.33464 0.667969C1.86797 0.667969 0.681302 1.86797 0.681302 3.33464L0.667969 27.3346L6.0013 22.0013H24.668C26.1346 22.0013 27.3346 20.8013 27.3346 19.3346V3.33464C27.3346 1.86797 26.1346 0.667969 24.668 0.667969H3.33464ZM3.33464 3.33464H24.668V19.3346H4.89464L3.33464 20.8946V3.33464ZM16.668 14.0013H6.0013V16.668H16.668V14.0013ZM6.0013 10.0013H22.0013V12.668H6.0013V10.0013ZM22.0013 6.0013H6.0013V8.66797H22.0013V6.0013Z" fill="#64CBF7"/>
                                </svg>
                            </span>
                            </Tooltip>
                            <BlockButton userId={broker.user._id} name={brokerName} refresh={refreshData} /></>}
                            {!isActive && <>
                                <UnblockButton userId={broker.user._id} name={brokerName} refresh={refreshData} />
                                <Badge status='error' text='Bloqueado'></Badge>
                            </>}
                        </td>
                        </tr>
                        
                        </React.Fragment>);
                    })}
                    <tr >
                        <td colSpan="7">
                            <Divider style={{margin: '2px'}}/>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="7" align='right'>
                            <Pagination
                                current={filter.page +1}
                                onChange={(current, pageSize)=> setFilter({...filter, page: current -1})}
                                defaultCurrent={1}
                                total={brokers.totalElements}
                                showTotal={(total) => `Total ${total}`}
                                responsive
                                disabled={brokers.totalElements >  brokers.size ? false : true}
                            />
                        </td>
                    </tr>
                </tfoot>
            </table>

          </Drawer>
          <ProfileModal />
        </>
      );
}

export default AssociateBrokerDrawer