const departamentos = [
    {  
    "id":0,
    "departamento":"Amazonas",
    "lat":-1.54622768,
    "lng":-71.502128578,
    "ciudades":[
        {"name": "Leticia", "lat": -3.530058784, "lng": -70.04513691},
        {"name": "Puerto Nariño", "lat": -3.631323222, "lng": -70.471990393}
    ]
},
{  
    "id":1,
    "departamento":"Antioquia",
    "lat":6.702032125,
    "lng":-75.504557037,
    "ciudades":[ 
        {"name": "Abejorral", "lat":5.803728154, "lng":-75.438473525},
        {"name": "Abriaquí", "lat":6.627569378, "lng":-76.085977561},
        {"name": "Alejandría", "lat":6.365534125, "lng":-75.090597022},
        {"name": "Amagá", "lat":6.032921994, "lng":-75.708003098},
        {"name": "Amalfi", "lat": 6.977788843, "lng": -74.981239298},
        {"name": "Andes", "lat": 5.604993248, "lng": -75.941283908},
        {"name": "Angelópolis", "lat": 6.121430303, "lng": -75.715949663},
        {"name": "Angostura", "lat": 6.861498764, "lng": -75.358315997},
        {"name": "Anorí", "lat": 7.193110778, "lng": -75.108489539},
        {"name": "Anzá", "lat": 6.293546613, "lng": -75.917369269},
        {"name": "Apartadó", "lat": 7.86047815, "lng": -76.582362643},
        {"name": "Arboletes", "lat": 8.611395262, "lng":  -76.414783173},
        {"name": "Argelia", "lat": 5.708114524, "lng": -75.094697954},
        {"name": "Armenia", "lat": 6.163683758, "lng": -75.809955281},
        {"name": "Barbosa", "lat": 6.443417935, "lng": -75.329240854},
        {"name": "Bello", "lat": 6.358841086, "lng": -75.589735878},
        {"name": "Belmira", "lat": 6.665853953, "lng": -75.68621659},
        {"name": "Betania", "lat": 5.727081285, "lng": -75.986891074},
        {"name": "Betulia", "lat": 6.185783612, "lng": -75.956492463},
        {"name": "Briceño", "lat": 7.111788462, "lng": -75.562234738},
        {"name": "Buriticá", "lat": 6.80906489, "lng": -75.912065947},
        {"name": "Cáceres", "lat": 7.665155105, "lng": -75.223697441},
        {"name": "Caicedo", "lat": 6.423463639, "lng": -75.99351498},
        {"name": "Caldas", "lat": 6.054446841, "lng": -75.626426743},
        {"name": "Campamento", "lat": 7.036017478, "lng": -75.287661778},
        {"name": "Cañasgordas", "lat": 6.814950023, "lng": -76.02689632},
        {"name": "Caracolí", "lat": 6.33797388, "lng": -74.733548173},
        {"name": "Caramanta", "lat": 5.555275296, "lng": -75.630820964},
        {"name": "Carepa", "lat": 7.757967437, "lng": -76.643746573},
        {"name": "Carolina del Príncipe", "lat": null, "lng": null},
        {"name": "Caucasia", "lat": 7.867941792, "lng": -75.035018239},
        {"name": "Chigorodó", "lat": 7.631665644, "lng": -76.639642127},
        {"name": "Cisneros", "lat": 6.550065431, "lng": -75.08254116},
        {"name": "Ciudad Bolívar", "lat": null, "lng": null},
        {"name": "Cocorná", "lat": 6.005593074, "lng": -75.160154033},
        {"name": "Concepción", "lat": 6.376106187, "lng": -75.217859014},
        {"name": "Concordia", "lat": 6.057468678, "lng": -75.900146783},
        {"name": "Copacabana", "lat": 6.351882893, "lng": -75.50075633},
        {"name": "Dabeiba", "lat": 6.992243782, "lng": -76.314263284},
        {"name": "Donmatías", "lat": 6.492403905, "lng": -75.422276872},
        {"name": "Ebéjico", "lat": 6.326444922, "lng": -75.783311748},
        {"name": "El Bagre", "lat": 7.704633351, "lng": -74.664277263},
        {"name": "El Carmen de Viboral", "lat": 5.978078841, "lng": -75.263536102},
        {"name": "El Peñol", "lat": 6.232396368, "lng": -75.223509055},
        {"name": "El Retiro", "lat": 6.053444254, "lng": -75.515738045},
        {"name": "El Santuario", "lat": 6.124209495, "lng": -75.252122776},
        {"name": "Entrerríos", "lat": 6.594656004, "lng": -75.559130833},
        {"name": "Envigado", "lat": 6.154394922, "lng": -75.546867788},
        {"name": "Fredonia", "lat": 5.887702934, "lng": -75.679262419},
        {"name": "Frontino", "lat": 6.691364773, "lng": -76.33199656},
        {"name": "Giraldo", "lat": 6.663095863, "lng": -75.948046628},
        {"name": "Girardota", "lat": 6.379745895, "lng": -75.447957442},
        {"name": "Gómez Plata", "lat": 6.709562839, "lng": -75.192702912},
        {"name": "Granada", "lat": 6.123380721, "lng": -75.127636463},
        {"name": "Guadalupe", "lat": 6.861576325, "lng": -75.225913027},
        {"name": "Guarne", "lat": 6.266625703, "lng": -75.437308008},
        {"name": "Guatapé", "lat": 6.236329826, "lng": -75.156512723},
        {"name": "Heliconia", "lat": 6.214511788, "lng": -75.749710019},
        {"name": "Hispania", "lat": 5.797039002, "lng": -75.906692028},
        {"name": "Itagüí", "lat": 6.179279438, "lng": -75.614203699},
        {"name": "Ituango", "lat": 7.341549718, "lng": -75.678422773},
        {"name": "Jardín", "lat": 5.574444339, "lng": -75.818425748},
        {"name": "Jericó", "lat": 5.769820536, "lng": -75.764882556},
        {"name": "La Ceja", "lat": 5.992524843, "lng": -75.430869347},
        {"name": "La Estrella", "lat": 6.137595883, "lng": -75.648954991},
        {"name": "La Pintada", "lat": 5.746583958, "lng": -75.60220206},
        {"name": "La Unión", "lat": 5.940434685, "lng": -75.354290227},
        {"name": "Liborina", "lat": 6.723990935, "lng": -75.783000458},
        {"name": "Maceo", "lat": 6.534573585, "lng": -74.714108541},
        {"name": "Marinilla", "lat": 6.18520515, "lng": -75.304879329},
        {"name": "Medellín", "lat": 6.257590259, "lng": -75.611031065},
        {"name": "Montebello", "lat": 5.916853903, "lng": -75.519726926},
        {"name": "Murindó", "lat": 6.828773855, "lng": -76.708613491},
        {"name": "Mutatá", "lat": 7.329557848, "lng": -76.463065695},
        {"name": "Nariño", "lat": 5.581275312, "lng": -75.194570185},
        {"name": "Nechí", "lat": 7.983477394, "lng": -74.65844186},
        {"name": "Necoclí", "lat": 8.493728551, "lng": -76.676995884},
        {"name": "Olaya", "lat": 6.611418083, "lng": -75.775670969},
        {"name": "Peque", "lat": 7.044180058, "lng": -75.889688434},
        {"name": "Pueblorrico", "lat": 5.80970186, "lng": -75.860766666},
        {"name": "Puerto Berrío", "lat": 6.481922267, "lng": -74.528742101},
        {"name": "Puerto Nare", "lat": 6.126539139, "lng": -74.703177217},
        {"name": "Puerto Triunfo", "lat": 5.954830151, "lng": -74.686191798},
        {"name": "Remedios", "lat": 6.997267375, "lng": -74.558656518},
        {"name": "Rionegro", "lat": 6.150785807, "lng": -75.410760524},
        {"name": "Sabanalarga", "lat": 6.901000506, "lng": -75.792227535},
        {"name": "Sabaneta", "lat": 6.138305183, "lng": -75.608805993},
        {"name": "Salgar", "lat": 5.970502334, "lng": -75.981372511},
        {"name": "San Andrés de Cuerquia", "lat": 6.918427233, "lng": -75.6657758},
        {"name": "San Carlos", "lat": 6.193789659, "lng": -74.90732769},
        {"name": "San Francisco", "lat": 5.840093731, "lng": -74.98176334},
        {"name": "San Jerónimo", "lat": 6.438300409, "lng": -75.703107272},
        {"name": "San José de la Montaña", "lat": 6.823258913, "lng": -75.677708262},
        {"name": "San Juan de Urabá", "lat": 8.707141583, "lng": -76.532482343},
        {"name": "San Luis", "lat": 6.024205956, "lng": -75.007667403},
        {"name": "San Pedro de Urabá", "lat": 8.37915695, "lng": -76.316642334},
        {"name": "San Pedro de los Milagros", "lat": 6.458666308, "lng": -75.562483898},
        {"name": "San Rafael", "lat": 6.31074453, "lng": -74.998586516},
        {"name": "San Roque", "lat": 6.466165211, "lng": -74.945073196},
        {"name": "San Vicente", "lat": 6.310387522, "lng": -75.327401628},
        {"name": "Santa Bárbara", "lat": 5.878984956, "lng": -75.583303711},
        {"name": "Santa Fe de Antioquia", "lat": null, "lng": null},
        {"name": "Santa Rosa de Osos", "lat": 6.675827825, "lng": -75.438143332},
        {"name": "Santo Domingo", "lat": 6.481044132, "lng": -75.142600968},
        {"name": "Segovia", "lat": 7.26692339, "lng": -74.621639263},
        {"name": "Sonsón", "lat": 5.826388779, "lng": -74.836376225},
        {"name": "Sopetrán", "lat": 6.520164552, "lng": -75.745809033},
        {"name": "Támesis", "lat": 5.673758571, "lng": -75.709799743},
        {"name": "Tarazá", "lat": 7.488875998, "lng": -75.401894377},
        {"name": "Tarso", "lat": 5.871064557, "lng": -75.828711443},
        {"name": "Titiribí", "lat": 6.058349971, "lng": -75.799660047},
        {"name": "Toledo", "lat": 7.016397069, "lng": -75.71363382},
        {"name": "Turbo", "lat": 8.173851745, "lng": -76.574037701},
        {"name": "Uramita", "lat": 6.913916924, "lng": -76.133185136},
        {"name": "Urrao", "lat": 6.33534134, "lng": -76.259064819},
        {"name": "Valdivia", "lat": 7.265404213, "lng": -75.400945015},
        {"name": "Valparaíso", "lat": 5.651937802, "lng": -75.630834123},
        {"name": "Vegachí", "lat": 6.836535122, "lng": -74.754030203},
        {"name": "Venecia", "lat": 5.956576355, "lng": -75.777224226},
        {"name": "Vigía del Fuerte", "lat": 6.453351334, "lng": -76.695048017},
        {"name": "Yalí", "lat": 6.716870679, "lng": -74.75565002},
        {"name": "Yarumal", "lat": 6.9881309, "lng": -75.454399574},
        {"name": "Yolombó", "lat": 6.64442786, "lng": -75.029902658},
        {"name": "Yondó", "lat": 6.925159318, "lng": -74.158240414},
        {"name": "Zaragoza", "lat": 7.474004006, "lng": -74.858483258}
    ] 
},
{  
    "id":2,
    "departamento":"Arauca",
    "lat":6.569013,
    "lng":-71.4580065,
    "ciudades":[
        {"name": "Arauca","lat": 6.796280825,"lng": -70.509211529},
        {"name": "Arauquita","lat": 6.799338394,"lng": -71.298274293},
        {"name": "Cravo Norte","lat": 6.39654349,"lng": -70.073811966},
        {"name": "Fortul","lat": 6.750907234,"lng": -71.791242408},
        {"name": "Puerto Rondón","lat": 6.411761829,"lng": -70.966789642},
        {"name": "Saravena","lat": 6.906941714,"lng": -71.850707567},
        {"name": "Tame","lat": 6.390650479,"lng": -71.791786468}
    ]
},
{  
    "id":3,
    "departamento":"Atl\u00e1ntico",
    "lat":10.682076,
    "lng":-75.2720755,
    "ciudades":[ 
        {"name": "Baranoa","lat": 10.793293019,"lng": -74.922194906},
        {"name": "Barranquilla","lat": 10.98152051,"lng": -74.827715439},
        {"name": "Campo de la Cruz","lat": 10.38405079,"lng": -74.884660393},
        {"name": "Candelaria","lat": 10.489020471,"lng": -74.877566268},
        {"name": "Galapa","lat": 10.897719907,"lng": -74.887087876},
        {"name": "Juan de Acosta","lat": 10.823065032,"lng": -75.078419039},
        {"name": "Luruaco","lat": 10.635031161,"lng": -75.15402741},
        {"name": "Malambo","lat": 10.848288642,"lng": -74.806175549},
        {"name": "Manatí","lat": 10.454145546,"lng": -74.984773669},
        {"name": "Palmar de Varela","lat": 10.694812078,"lng": -74.770150903},
        {"name": "Piojó","lat": 10.740789594,"lng": -75.143660335},
        {"name": "Polonuevo","lat": 10.767401414,"lng": -74.854148436},
        {"name": "Ponedera","lat": 10.594651021,"lng": -74.798473158},
        {"name": "Puerto Colombia","lat": 11.004422597,"lng": -74.907405804},
        {"name": "Repelón","lat": 10.507570513,"lng": -75.121880527},
        {"name": "Sabanagrande","lat": 10.798940151,"lng": -74.768796603},
        {"name": "Sabanalarga","lat": 10.622507773,"lng": -74.955105416},
        {"name": "Santa Lucía","lat": 10.35364192,"lng": -74.965411787},
        {"name": "Santo Tomás","lat": 10.755626561,"lng": -74.78726468},
        {"name": "Soledad","lat": 10.906906595,"lng": -74.778424903},
        {"name": "Suán","lat": 10.310850347,"lng": -74.907133084},
        {"name": "Tubará","lat": 10.907219384,"lng": -74.98423293},
        {"name": "Usiacurí","lat": 10.740522657,"lng": -74.982674126}
    ]
},
{  
    "id":4,
    "departamento":"Bol\u00edvar",
    "lat":8.90242,
    "lng":-75.8497249,
    "ciudades":[
        {"name": "Achí","lat": 8.622943262,"lng": -74.478059448},
        {"name": "Altos del Rosario","lat": 8.757468863,"lng": -74.195158106},
        {"name": "Arenal","lat": 8.362996158,"lng": -74.103019049},
        {"name": "Arjona","lat": 10.164221225,"lng": -75.371217884},
        {"name": "Arroyohondo","lat": 10.241718047,"lng": -75.026904215},
        {"name": "Barranco de Loba","lat": 8.837077654,"lng": -74.279665305},
        {"name": "Brazuelo de Papayal","lat": null,"lng": null},
        {"name": "Calamar","lat": 10.155170977,"lng": -74.999959492},
        {"name": "Cantagallo","lat": 7.239229146,"lng": -74.100140429},
        {"name": "Cartagena de Indias","lat": 10.463433617,"lng": -75.45889915},
        {"name": "Cicuco","lat": 9.235268598,"lng": -74.688409615},
        {"name": "Clemencia","lat": 10.556636955,"lng": -75.314466528},
        {"name": "Córdoba","lat": 9.540047515,"lng": -74.894456346},
        {"name": "El Carmen de Bolívar","lat": 9.699819876,"lng": -75.157935726},
        {"name": "El Guamo","lat": 10.034836723,"lng": -74.928110717},
        {"name": "El Peñón","lat": 8.864818786,"lng": -73.912301472},
        {"name": "Hatillo de Loba","lat": 8.981717133,"lng": -74.090719016},
        {"name": "Magangué","lat": 9.053135895,"lng": -74.705393181},
        {"name": "Mahates","lat": 10.178213908,"lng": -75.17978161},
        {"name": "Margarita","lat": 9.054601889,"lng": -74.225847059},
        {"name": "María la Baja","lat": 9.976017168,"lng": -75.343802649},
        {"name": "Mompós","lat": null,"lng": null},
        {"name": "Montecristo","lat": 8.01710024,"lng": -74.463429112},
        {"name": "Morales","lat": 8.253700061,"lng": -73.971345899},
        {"name": "Norosí","lat": 8.50613476,"lng": -74.115889115},
        {"name": "Pinillos","lat": 8.886315511,"lng": -74.408884758},
        {"name": "Regidor","lat": 8.672628558,"lng": -73.914506788},
        {"name": "Río Viejo","lat": 8.559982465,"lng": -73.902986677},
        {"name": "San Cristóbal","lat": 10.369720871,"lng": -75.073338352},
        {"name": "San Estanislao","lat": 10.364681919,"lng": -75.205234868},
        {"name": "San Fernando","lat": 9.081483143,"lng": -74.371866466},
        {"name": "San Jacinto del Cauca","lat": 8.212463202,"lng": -74.75082},
        {"name": "San Jacinto","lat": 9.835362011,"lng": -75.106092009},
        {"name": "San Juan Nepomuceno","lat": 9.967957823,"lng": -75.065719587},
        {"name": "San Martín de Loba","lat": 8.816088444,"lng": -74.005999668},
        {"name": "San Pablo","lat": 7.507922612,"lng": -74.103569774},
        {"name": "Santa Catalina","lat": 10.649812776,"lng": -75.26548463},
        {"name": "Santa Rosa","lat": 10.469207257,"lng": -75.361160527},
        {"name": "Santa Rosa del Sur","lat": 7.772519173,"lng": -74.262728426},
        {"name": "Simití","lat": 7.851315534,"lng": -73.983716091},
        {"name": "Soplaviento","lat": 10.332090314,"lng": -75.116992257},
        {"name": "Talaigua Nuevo","lat": 9.269516705,"lng": -74.586952326},
        {"name": "Tiquisio","lat": 8.492345176,"lng": -74.296761387},
        {"name": "Turbaco","lat": 10.353107564,"lng": -75.379715411},
        {"name": "Turbaná","lat": 10.252769289,"lng": -75.432702985},
        {"name": "Villanueva","lat": 10.444913198,"lng": -75.267362754},
        {"name": "Zambrano","lat": 9.745147021,"lng": -74.880670367}
    ]
},
{  
    "id":5,
    "departamento":"Boyac\u00e1",
    "lat":5.8570233,
    "lng":-74.4307211,
    "ciudades":[
        {"name":"Almeida","lat":4.954825081,"lng":-73.388134245},
        {"name":"Aquitania","lat":5.437416373,"lng":-72.871494711},
        {"name":"Arcabuco","lat":5.73526424,"lng":-73.427931091},
        {"name":"Belén","lat":6.005059265,"lng":-72.893698873},
        {"name":"Berbeo","lat":5.230936356,"lng":-73.127319437},
        {"name":"Betéitiva","lat":5.920858933,"lng":-72.848578763},
        {"name":"Boavita","lat":6.337515626,"lng":-72.62021239},
        {"name":"Boyacá","lat":5.439856216,"lng":-73.381369938},
        {"name":"Briceño","lat":5.673229265,"lng":-73.926607047},
        {"name":"Buenavista","lat":5.489399419,"lng":-73.950662546},
        {"name":"Busbanzá","lat":5.843483731,"lng":-72.87574623},
        {"name":"Caldas","lat":5.575990669,"lng":-73.880575077},
        {"name":"Campohermoso","lat":5.006862536,"lng":-73.144670302},
        {"name":"Cerinza","lat":5.962013698,"lng":-72.964472884},
        {"name":"Chinavita","lat":5.202971639,"lng":-73.340480736},
        {"name":"Chiquinquirá","lat":5.622488024,"lng":-73.804245312},
        {"name":"Chíquiza","lat":null,"lng":null},
        {"name":"Chiscas","lat":6.68911806,"lng":-72.417956748},
        {"name":"Chita","lat":6.091468485,"lng":-72.439031738},
        {"name":"Chitaraque","lat":5.984394625,"lng":-73.425823751},
        {"name":"Chivatá","lat":5.556830465,"lng":-73.260617153},
        {"name":"Chivor","lat":null,"lng":null},
        {"name":"Ciénega","lat":5.393059633,"lng":-73.282057716},
        {"name":"Cómbita","lat":5.667822366,"lng":-73.321370491},
        {"name":"Coper","lat":5.452461072,"lng":-74.059965781},
        {"name":"Corrales","lat":5.82454582,"lng":-72.845448448},
        {"name":"Covarachía","lat":6.519521476,"lng":-72.740204209},
        {"name":"Cubará","lat":6.886954878,"lng":-72.181562849},
        {"name":"Cucaita","lat":5.527104109,"lng":-73.445986459},
        {"name":"Cuítiva","lat":5.58775449,"lng":-72.939536863},
        {"name":"Duitama","lat":5.890602515,"lng":-73.067094843},
        {"name":"El Cocuy","lat":6.353363177,"lng":-72.419237519},
        {"name":"El Espino","lat":6.508240556,"lng":-72.480496247},
        {"name":"Firavitoba","lat":5.67367659,"lng":-73.019833516},
        {"name":"Floresta","lat":5.85899211,"lng":-72.924998265},
        {"name":"Gachantivá","lat":5.744105975,"lng":-73.542655196},
        {"name":"Gámeza","lat":5.799050256,"lng":-72.729647651},
        {"name":"Garagoa","lat":5.089466651,"lng":-73.314302722},
        {"name":"Guacamayas","lat":6.445438546,"lng":-72.516353323},
        {"name":"Guateque","lat":5.01450831,"lng":-73.488123516},
        {"name":"Guayatá","lat":4.931424341,"lng":-73.496633724},
        {"name":"Güicán","lat":null,"lng":null},
        {"name":"Iza","lat":5.617366016,"lng":-72.969865044},
        {"name":"Jenesano","lat":5.38916272,"lng":-73.400870207},
        {"name":"Jericó","lat":6.155633662,"lng":-72.585230926},
        {"name":"La Capilla","lat":5.113167102,"lng":-73.459237077},
        {"name":"La Uvita","lat":6.265126191,"lng":-72.555510848},
        {"name":"La Victoria","lat":5.507008121,"lng":-74.241627269},
        {"name":"Labranzagrande","lat":5.541378132,"lng":-72.590384601},
        {"name":"Macanal","lat":4.980176495,"lng":-73.294136946},
        {"name":"Maripí","lat":5.569810633,"lng":-74.020031272},
        {"name":"Miraflores","lat":5.150953297,"lng":-73.178967217},
        {"name":"Mongua","lat":5.710937981,"lng":-72.634028752},
        {"name":"Monguí","lat":5.697352228,"lng":-72.832986642},
        {"name":"Moniquirá","lat":5.863370485,"lng":-73.558525378},
        {"name":"Motavita","lat":5.606192449,"lng":-73.377342665},
        {"name":"Muzo","lat":5.539429272,"lng":-74.111771391},
        {"name":"Nobsa","lat":5.778759851,"lng":-72.932683237},
        {"name":"Nuevo Colón","lat":5.354701485,"lng":-73.448734241},
        {"name":"Oicatá","lat":5.610787844,"lng":-73.279919053},
        {"name":"Otanche","lat":5.753787787,"lng":-74.197194226},
        {"name":"Pachavita","lat":5.148323877,"lng":-73.399512192},
        {"name":"Páez","lat":5.095211035,"lng":-73.004358626},
        {"name":"Paipa","lat":5.762027786,"lng":-73.137774423},
        {"name":"Pajarito","lat":5.382328949,"lng":-72.696726742},
        {"name":"Panqueba","lat":6.443299758,"lng":-72.461824312},
        {"name":"Pauna","lat":5.688801559,"lng":-74.005041215},
        {"name":"Paya","lat":5.621242742,"lng":-72.369290598},
        {"name":"Paz del Río","lat":6.030008561,"lng":-72.759880177},
        {"name":"Pesca","lat":5.498483324,"lng":-73.07837993},
        {"name":"Pisba","lat":5.768131812,"lng":-72.439932321},
        {"name":"Puerto Boyacá","lat":5.953466654,"lng":-74.452858102},
        {"name":"Quípama","lat":5.595729831,"lng":-74.268570671},
        {"name":"Ramiriquí","lat":5.319934092,"lng":-73.310778996},
        {"name":"Ráquira","lat":5.496268621,"lng":-73.628944376},
        {"name":"Rondón","lat":5.379947879,"lng":-73.196509947},
        {"name":"Saboyá","lat":5.699926051,"lng":-73.747994656},
        {"name":"Sáchica","lat":5.573464882,"lng":-73.543102003},
        {"name":"Samacá","lat":5.47053997,"lng":-73.521175604},
        {"name":"San Eduardo","lat":5.234614686,"lng":-73.049140669},
        {"name":"San José de Pare","lat":5.994154088,"lng":-73.543865224},
        {"name":"San Luis de Gaceno","lat":4.798203543,"lng":-73.133970184},
        {"name":"San Mateo","lat":6.395388812,"lng":-72.551848623},
        {"name":"San Miguel de Sema","lat":5.532326922,"lng":-73.747158978},
        {"name":"San Pablo de Borbur","lat":5.677345066,"lng":-74.114037486},
        {"name":"Santa María","lat":4.823002556,"lng":-73253775062},
        {"name":"Santa Rosa de Viterbo","lat":5.892950301,"lng":-72.991727804},
        {"name":"Santa Sofía","lat":5.731602716,"lng":-73.603662869},
        {"name":"Santana","lat":6.057614971,"lng":-73.489748282},
        {"name":"Sativanorte","lat":6.13211793,"lng":-72.730324154},
        {"name":"Sativasur","lat":6.083405978,"lng":-72.724108145},
        {"name":"Siachoque","lat":5.498747701,"lng":-73.216817778},
        {"name":"Soatá","lat":6.323088045,"lng":-72.696040893},
        {"name":"Socotá","lat":5.906513772,"lng":-72.536463605},
        {"name":"Socha","lat":5.960498836,"lng":-72.682397841},
        {"name":"Sogamoso","lat":5.646672053,"lng":-72.870956503},
        {"name":"Somondoco","lat":4.970974064,"lng":-73.428787852},
        {"name":"Sora","lat":5.580573191,"lng":-73.443718375},
        {"name":"Soracá","lat":5.494890461,"lng":-73.318999965},
        {"name":"Sotaquirá","lat":5.763010553,"lng":-73.24352232},
        {"name":"Susacón","lat":6.215200578,"lng":-72.715756341},
        {"name":"Sutamarchán","lat":5.640030759,"lng":-73.625987727},
        {"name":"Sutatenza","lat":5.030711335,"lng":-73.412244005},
        {"name":"Tasco","lat":5.882294313,"lng":-72.726268173},
        {"name":"Tenza","lat":5.071413139,"lng":-73.424796988},
        {"name":"Tibaná","lat":5.301116649,"lng":-73.393252095},
        {"name":"Tibasosa","lat":5.747329389,"lng":-73.011147244},
        {"name":"Tinjacá","lat":5.577566497,"lng":-73.676145721},
        {"name":"Tipacoque","lat":6.42035349,"lng":-72.694635834},
        {"name":"Toca","lat":5.580254287,"lng":-73.160696902},
        {"name":"Togüí","lat":5.921830062,"lng":-73.489218055},
        {"name":"Topagá","lat":5.765350303,"lng":-72.836729576},
        {"name":"Tota","lat":5.501297215,"lng":-72.996332969},
        {"name":"Tunja","lat":5.518472615,"lng":-73.378018556},
        {"name":"Tununguá","lat":5.720301516,"lng":-73.943309265},
        {"name":"Turmequé","lat":5.305697418,"lng":-73.509087138},
        {"name":"Tuta","lat":5.678126998,"lng":-73.173430534},
        {"name":"Tutazá","lat":6.078051903,"lng":-72.857078319},
        {"name":"Umbita","lat":5.217241368,"lng":-73.463384768},
        {"name":"Ventaquemada","lat":5.383385481,"lng":-73.520979424},
        {"name":"Villa de Leyva","lat":5.665762424,"lng":-73.514948003},
        {"name":"Viracachá","lat":5.440558216,"lng":-73.266274556},
        {"name":"Zetaquira","lat":5.285635983,"lng":-73.167630912}
    ]
},
{  
    "id":6,
    "departamento":"Caldas",
    "lat":5.2904847,
    "lng":-75.5547977,
    "ciudades":[
        {"name":"Aguadas","lat":5.616204006,"lng":-75.455447104},
        {"name":"Anserma","lat":5.20029922,"lng":-75.752890779},
        {"name":"Aranzazu","lat":5.265539969,"lng":-75.473926194},
        {"name":"Belalcázar","lat":4.99467728,"lng":-75.812259892},
        {"name":"Chinchiná","lat":4.974340569,"lng":-75.659546861},
        {"name":"Filadelfia","lat":5.285743777,"lng":-75.589787839},
        {"name":"La Dorada","lat":5.410515691,"lng":-74.72114262},
        {"name":"La Merced","lat":5.393185716,"lng":-75.557910707},
        {"name":"Manizales","lat":5.08343301,"lng":-75.50727881},
        {"name":"Manzanares","lat":5.23295391,"lng":-75.152634472},
        {"name":"Marmato","lat":5.492632029,"lng":-75.60209955},
        {"name":"Marquetalia","lat":5.309142491,"lng":-75.041178923},
        {"name":"Marulanda","lat":5.235585244,"lng":-75.283166479},
        {"name":"Neira","lat":5.183358589,"lng":-75.506929774},
        {"name":"Norcasia","lat":5.638062349,"lng":-74.854927072},
        {"name":"Pácora","lat":5.500035035,"lng":-75.482450565},
        {"name":"Palestina","lat":5.050342543,"lng":-75.661762659},
        {"name":"Pensilvania","lat":5.407187836,"lng":-75.166839962},
        {"name":"Riosucio","lat":5.442275418,"lng":-75.75220499},
        {"name":"Risaralda","lat":5.097563816,"lng":-75.733351662},
        {"name":"Salamina","lat":5.370332425,"lng":-75.409621766},
        {"name":"Samaná","lat":5.519692873,"lng":-74.99718577},
        {"name":"San José","lat":5.083424513,"lng":-75.810178904},
        {"name":"Supía","lat":5.45295802,"lng":-75.640357045},
        {"name":"Victoria","lat":5.447542859,"lng":-74.836225017},
        {"name":"Villamaría","lat":4.940211591,"lng":-75.435440137},
        {"name":"Viterbo","lat":5.047822054,"lng":-75.879297}
    ]
},
{  
    "id":7,
    "departamento":"Caquet\u00e1",
    "lat":1.1321602,
    "lng":-74.9379926,
    "ciudades":[  
        {"name":"Albania","lat":1.22786549,"lng":-75.882326508},
        {"name":"Belén de los Andaquíes","lat":1.536174657,"lng":-75.909475036},
        {"name":"Cartagena del Chairá","lat":0.647693081,"lng":-74.272354991},
        {"name":"Curillo","lat":1.115379292,"lng":-75.973284517},
        {"name":"El Doncello","lat":1.713734697,"lng":-75.268781092},
        {"name":"El Paujil","lat":1.476630016,"lng":-75.202318246},
        {"name":"Florencia","lat":1.749138704,"lng":-75.558239064},
        {"name":"La Montañita","lat":1.302860442,"lng":-75.235727319},
        {"name":"Milán","lat":1.146692554,"lng":-75.386647638},
        {"name":"Morelia","lat":1.38299611,"lng":-75.673811234},
        {"name":"Puerto Rico","lat":2.018940891,"lng":-75.073086267},
        {"name":"San José del Fragua","lat":1.304702314,"lng":-76.110005942},
        {"name":"San Vicente del Caguán","lat":1.308465061,"lng":-74.197469706},
        {"name":"Solano","lat":0.096131862,"lng":-72.320364019},
        {"name":"Solita","lat":0.943939454,"lng":-75.646685441},
        {"name":"Valparaíso","lat":1.07534915,"lng":-75.737790038}
    ]   
},
{  
    "id":8,
    "departamento":"Casanare",
    "lat":5.2941088,
    "lng":-72.0173432,
    "ciudades":[ 
        {"name":"Aguazul","lat":5.126121886,"lng":-72.548210167},
        {"name":"Chámeza","lat":5.228453404,"lng":-72.864968036},
        {"name":"Hato Corozal","lat":6.03948032,"lng":-71.524457518},
        {"name":"La Salina","lat":6.178282373,"lng":-72.346933772},
        {"name":"Maní","lat":4.675769488,"lng":-72.212802727},
        {"name":"Monterrey","lat":4.841308642,"lng":-72.847343398},
        {"name":"Nunchía","lat":5.529181235,"lng":-72.092519371},
        {"name":"Orocue","lat":4.915139133,"lng":-71.598739657},
        {"name":"Paz de Ariporo","lat":5.779915281,"lng":-70.86947037},
        {"name":"Pore","lat":5.663194953,"lng":-71.925808058},
        {"name":"Recetor","lat":5.263068507,"lng":-72.770818307},
        {"name":"Sabanalarga","lat":4.81119011,"lng":-72.99580655},
        {"name":"Sácama","lat":6.049906435,"lng":-72.210761632},
        {"name":"San Luis de Palenque","lat":5.274955665,"lng":-71.817492757},
        {"name":"Támara","lat":5.878927424,"lng":-72.200678221},
        {"name":"Tauramena","lat":4.697463691,"lng":-72.629224357},
        {"name":"Trinidad","lat":5.354527091,"lng":-71.254795186},
        {"name":"Villanueva","lat":4.528307719,"lng":-72.82314281},
        {"name":"Yopal","lat":5.242744761,"lng":-72.25802857}
    ]
},
{  
    "id":9,
    "departamento":"Cauca",
    "lat":2.1461317,
    "lng":-77.3975047,
    "ciudades":[ 
        {"name":"Almaguer","lat":1.912440521,"lng":-76.834945194},
        {"name":"Argelia","lat":2.329337283,"lng":-77.26314906},
        {"name":"Balboa","lat":2.084732945,"lng":-77.208813302},
        {"name":"Bolívar","lat":1.853951938,"lng":-76.963374538},
        {"name":"Buenos Aires","lat":2.988923885,"lng":-76.621589509},
        {"name":"Cajibío","lat":2.643209848,"lng":-76.701389144},
        {"name":"Caldono","lat":2.803845691,"lng":-76.427193766},
        {"name":"Caloto","lat":3.062875557,"lng":-76.321279771},
        {"name":"Corinto","lat":3.136753606,"lng":-76.209645692},
        {"name":"El Tambo","lat":2.534488312,"lng":-77.005112065},
        {"name":"Florencia","lat":1.69453474,"lng":-77.087357234},
        {"name":"Guachené","lat":3.144389993,"lng":-76.392348107},
        {"name":"Guapí","lat":2.41326696,"lng":-77.690475305},
        {"name":"Inzá","lat":2.499058783,"lng":-76.137625312},
        {"name":"Jambaló","lat":2.84981013,"lng":-76.31124919},
        {"name":"La Sierra","lat":2.187473572,"lng":-76.78228032},
        {"name":"La Vega","lat":2.053933426,"lng":-76.758806204},
        {"name":"López de Micay","lat":2.95819877,"lng":-77.242121575},
        {"name":"Mercaderes","lat":1.793825164,"lng":-77.180603812},
        {"name":"Miranda","lat":3.228235212,"lng":-76.216708274},
        {"name":"Morales","lat":2.773663981,"lng":-76.746051522},
        {"name":"Padilla","lat":3.194730618,"lng":-76.336241803},
        {"name":"Páez","lat":2.733887702,"lng":-75.993682638},
        {"name":"Patía","lat":2.157983906,"lng":-77.046576199},
        {"name":"Piamonte","lat":1.054128816,"lng":-76.444327103},
        {"name":"Piendamó","lat":2.702420026,"lng":-76.537704551},
        {"name":"Popayán","lat":2.471704362,"lng":-76.591944191},
        {"name":"Puerto Tejada","lat":3.266724205,"lng":-76.41846353},
        {"name":"Puracé","lat":2.262153618,"lng":-76.448269377},
        {"name":"Rosas","lat":2.260421954,"lng":-76.74582033},
        {"name":"San Sebastián","lat":1.856261815,"lng":-76.716524236},
        {"name":"Santa Rosa","lat":1.52002179,"lng":-76.538762806},
        {"name":"Santander de Quilichao","lat":2.984467338,"lng":-76.498731453},
        {"name":"Silvia","lat":2.651291894,"lng":-76.350573563},
        {"name":"Sotará","lat":2.231820541,"lng":-76.611466326},
        {"name":"Suárez","lat":2.936865997,"lng":-76.745824296},
        {"name":"Sucre","lat":2.057735184,"lng":-76.909939647},
        {"name":"Timbío","lat":2.369625312,"lng":-76.710519323},
        {"name":"Timbiquí","lat":2.699475127,"lng":-77.491315765},
        {"name":"Toribío","lat":2.975771999,"lng":-76.203315664},
        {"name":"Totoró","lat":2.475213085,"lng":-76.39675937},
        {"name":"Villa Rica","lat":3.180627317,"lng":-76.464455581}
    ]
},
{  
    "id":10,
    "departamento":"Cesar",
    "lat":9.272741,
    "lng":74.6371692,
    "ciudades":[
        {"name":"Aguachica","lat":8.248735219,"lng":-73.6227405},
        {"name":"Agustín Codazzi","lat":9.949215908,"lng":-73.223582441},
        {"name":"Astrea","lat":9.510727925,"lng":-73.942146411},
        {"name":"Becerril","lat":9.727732939,"lng":-73.252505222},
        {"name":"Bosconia","lat":9.946391309,"lng":-73.866288153},
        {"name":"Chimichagua","lat":9.27797765,"lng":-73.846979027},
        {"name":"Chiriguaná","lat":9.426648354,"lng":-73.547978944},
        {"name":"Curumaní","lat":9.238771332,"lng":-73.488108612},
        {"name":"El Copey","lat":10.199953563,"lng":-73.912820636},
        {"name":"El Paso","lat":9.683474681,"lng":-73.738337782},
        {"name":"Gamarra","lat":8.337726622,"lng":-73.706491986},
        {"name":"González","lat":8.405516522,"lng":-73.375373085},
        {"name":"La Gloria (Cesar)","lat":8.588002247,"lng":-73.616023836},
        {"name":"La Jagua de Ibirico","lat":9.52099808,"lng":-73.322493379},
        {"name":"La Paz","lat":10.244405624,"lng":-73.078245692},
        {"name":"Manaure Balcón del Cesar","lat":10.38027852,"lng":-73.007062754},
        {"name":"Pailitas","lat":8.938916143,"lng":-73.572472125},
        {"name":"Pelaya","lat":8.764116734,"lng":-73.632955464},
        {"name":"Pueblo Bello","lat":10.417368738,"lng":-73.614506323},
        {"name":"Río de Oro","lat":8.201070245,"lng":-73.487501265},
        {"name":"San Alberto","lat":7.769815987,"lng":-73.472188879},
        {"name":"San Diego","lat":10.169527121,"lng":-73.287982702},
        {"name":"San Martín","lat":7.937788454,"lng":-73.544363121},
        {"name":"Tamalameque","lat":8.902075012,"lng":-73.763396197},
        {"name":"Valledupar","lat":10.218902768,"lng":-73.457847638}
    ]
},
{  
    "id":11,
    "departamento":"Choc\u00f3",
    "lat":6.3343515,
    "lng":-78.0656026,
    "ciudades":[
        {"name":"Acandí","lat":null,"lng":null},
        {"name":"Alto Baudó","lat":null,"lng":null},
        {"name":"Bagadó","lat":null,"lng":null},
        {"name":"Bahía Solano","lat":null,"lng":null},
        {"name":"Bajo Baudó","lat":null,"lng":null},
        {"name":"Bojayá","lat":null,"lng":null},
        {"name":"Cantón de San Pablo","lat":null,"lng":null},
        {"name":"Cértegui","lat":null,"lng":null},
        {"name":"Condoto","lat":null,"lng":null},
        {"name":"El Atrato","lat":null,"lng":null},
        {"name":"El Carmen de Atrato","lat":null,"lng":null},
        {"name":"El Carmen del Darién","lat":null,"lng":null},
        {"name":"Istmina","lat":null,"lng":null},
        {"name":"Juradó","lat":null,"lng":null},
        {"name":"Litoral de San Juan","lat":null,"lng":null},
        {"name":"Lloró","lat":null,"lng":null},
        {"name":"Medio Atrato","lat":null,"lng":null},
        {"name":"Medio Baudó","lat":null,"lng":null},
        {"name":"Medio San Juan","lat":null,"lng":null},
        {"name":"Nóvita","lat":null,"lng":null},
        {"name":"Nuquí","lat":null,"lng":null},
        {"name":"Quibdó","lat":5.6924,"lng":-76.6611},
        {"name":"Río Iró","lat":null,"lng":null},
        {"name":"Río Quito","lat":null,"lng":null},
        {"name":"Riosucio","lat":null,"lng":null},
        {"name":"San José del Palmar","lat":null,"lng":null},
        {"name":"Sipí","lat":null,"lng":null},
        {"name":"Tadó","lat":null,"lng":null},
        {"name":"Unión Panamericana","lat":null,"lng":null},
        {"name":"Unguía","lat":null,"lng":null}
    ]
},
{  
    "id":12,
    "departamento":"Cundinamarca",
    "lat":4.771120716,
    "lng":-74.431110916,
    "ciudades":[
        {"name":"Agua de Dios","lat":4.372744676,"lng":-74.671110509},
        {"name":"Albán","lat":4.891121638,"lng":-74.442784317},
        {"name":"Anapoima","lat":4.559962881,"lng":-74.525695298},
        {"name":"Anolaima","lat":4.789313614,"lng":-74.461942563},
        {"name":"Apulo","lat":null,"lng":null},
        {"name":"Arbeláez","lat":4.232786985,"lng":-74.395762911},
        {"name":"Beltrán","lat":4.791721105,"lng":-74.714913986},
        {"name":"Bituima","lat":4.847821091,"lng":-74.528627661},
        {"name":"Bogotá","lat": 4.316107698,"lng":-74.181072702},
        {"name":"Bojacá","lat":4.705576252,"lng":-74.335407699},
        {"name":"Cabrera","lat":3.91861924,"lng":-74.44615606},
        {"name":"Cachipay","lat":4.723063341,"lng":-74.459195283},
        {"name":"Cajicá","lat":4.930402779,"lng":-74.029851152},
        {"name":"Caparrapí","lat":5.381501399,"lng":-74.521235947},
        {"name":"Cáqueza","lat":4.402259457,"lng":-73.94482435},
        {"name":"Carmen de Carupa","lat":5.338856556,"lng":-73.926148172},
        {"name":"Chaguaní","lat":4.95425175,"lng":-74.630901385},
        {"name":"Chía","lat":4.857823671,"lng":-74.038095385},
        {"name":"Chipaque","lat":4.463746629,"lng":-74.04605246},
        {"name":"Choachí","lat":null,"lng":null},
        {"name":"Chocontá","lat":null,"lng":null},
        {"name":"Cogua","lat":null,"lng":null},
        {"name":"Cota","lat":null,"lng":null},
        {"name":"Cucunubá","lat":null,"lng":null},
        {"name":"El Colegio","lat":null,"lng":null},
        {"name":"El Peñón","lat":null,"lng":null},
        {"name":"El Rosal","lat":null,"lng":null},
        {"name":"Facatativá","lat":null,"lng":null},
        {"name":"Fómeque","lat":null,"lng":null},
        {"name":"Fosca","lat":null,"lng":null},
        {"name":"Funza","lat":null,"lng":null},
        {"name":"Fúquene","lat":null,"lng":null},
        {"name":"Fusagasugá","lat":null,"lng":null},
        {"name":"Gachalá","lat":null,"lng":null},
        {"name":"Gachancipá","lat":null,"lng":null},
        {"name":"Gachetá","lat":null,"lng":null},
        {"name":"Gama","lat":null,"lng":null},
        {"name":"Girardot","lat":null,"lng":null},
        {"name":"Granada","lat":null,"lng":null},
        {"name":"Guachetá","lat":null,"lng":null},
        {"name":"Guaduas","lat":null,"lng":null},
        {"name":"Guasca","lat":null,"lng":null},
        {"name":"Guataquí","lat":null,"lng":null},
        {"name":"Guatavita","lat":null,"lng":null},
        {"name":"Guayabal de Síquima","lat":null,"lng":null},
        {"name":"Guayabetal","lat":null,"lng":null},
        {"name":"Gutiérrez","lat":null,"lng":null},
        {"name":"Jerusalén","lat":null,"lng":null},
        {"name":"Junín","lat":null,"lng":null},
        {"name":"La Calera","lat":null,"lng":null},
        {"name":"La Mesa","lat":null,"lng":null},
        {"name":"La Palma","lat":null,"lng":null},
        {"name":"La Peña","lat":null,"lng":null},
        {"name":"La Vega","lat":null,"lng":null},
        {"name":"Lenguazaque","lat":null,"lng":null},
        {"name":"Machetá","lat":null,"lng":null},
        {"name":"Madrid","lat":null,"lng":null},
        {"name":"Manta","lat":null,"lng":null},
        {"name":"Medina","lat":null,"lng":null},
        {"name":"Mosquera","lat":null,"lng":null},
        {"name":"Nariño","lat":null,"lng":null},
        {"name":"Nemocón","lat":null,"lng":null},
        {"name":"Nilo","lat":null,"lng":null},
        {"name":"Nimaima","lat":null,"lng":null},
        {"name":"Nocaima","lat":null,"lng":null},
        {"name":"Pacho","lat":null,"lng":null},
        {"name":"Paime","lat":null,"lng":null},
        {"name":"Pandi","lat":null,"lng":null},
        {"name":"Paratebueno","lat":null,"lng":null},
        {"name":"Pasca","lat":null,"lng":null},
        {"name":"Puerto Salgar","lat":null,"lng":null},
        {"name":"Pulí","lat":null,"lng":null},
        {"name":"Quebradanegra","lat":null,"lng":null},
        {"name":"Quetame","lat":null,"lng":null},
        {"name":"Quipile","lat":null,"lng":null},
        {"name":"Ricaurte","lat":null,"lng":null},
        {"name":"San Antonio del Tequendama","lat":null,"lng":null},
        {"name":"San Bernardo","lat":null,"lng":null},
        {"name":"San Cayetano","lat":null,"lng":null},
        {"name":"San Francisco","lat":null,"lng":null},
        {"name":"San Juan de Rioseco","lat":null,"lng":null},
        {"name":"Sasaima","lat":null,"lng":null},
        {"name":"Sesquilé","lat":null,"lng":null},
        {"name":"Sibaté","lat":null,"lng":null},
        {"name":"Silvania","lat":null,"lng":null},
        {"name":"Simijaca","lat":null,"lng":null},
        {"name":"Soacha","lat":null,"lng":null},
        {"name":"Sopó","lat":null,"lng":null},
        {"name":"Subachoque","lat":null,"lng":null},
        {"name":"Suesca","lat":null,"lng":null},
        {"name":"Supatá","lat":null,"lng":null},
        {"name":"Susa","lat":null,"lng":null},
        {"name":"Sutatausa","lat":null,"lng":null},
        {"name":"Tabio","lat":null,"lng":null},
        {"name":"Tausa","lat":null,"lng":null},
        {"name":"Tena","lat":null,"lng":null},
        {"name":"Tenjo","lat":null,"lng":null},
        {"name":"Tibacuy","lat":null,"lng":null},
        {"name":"Tibirita","lat":null,"lng":null},
        {"name":"Tocaima","lat":null,"lng":null},
        {"name":"Tocancipá","lat":null,"lng":null},
        {"name":"Topaipí","lat":null,"lng":null},
        {"name":"Ubalá","lat":null,"lng":null},
        {"name":"Ubaque","lat":null,"lng":null},
        {"name":"Ubaté","lat":null,"lng":null},
        {"name":"Une","lat":null,"lng":null},
        {"name":"Útica","lat":null,"lng":null},
        {"name":"Venecia","lat":null,"lng":null},
        {"name":"Vergara","lat":null,"lng":null},
        {"name":"Vianí","lat":null,"lng":null},
        {"name":"Villagómez","lat":null,"lng":null},
        {"name":"Villapinzón","lat":null,"lng":null},
        {"name":"Villeta","lat":null,"lng":null},
        {"name":"Viotá","lat":null,"lng":null},
        {"name":"Yacopí","lat":null,"lng":null},
        {"name":"Zipacón","lat":null,"lng":null},
        {"name":"Zipaquirá","lat":null,"lng":null}
    ]
},
{  
    "id":13,
    "departamento":"C\u00f3rdoba",
    "lat":8.3998519,
    "lng":-76.2087392,
    "ciudades":[
        {"name":"Ayapel","lat":8.265768688,"lng":-75.048684357},
        {"name":"Buenavista","lat":8.191397953,"lng":-75.433825319},
        {"name":"Canalete","lat":8.7240958,"lng":-76.237790807},
        {"name":"Cereté","lat":8.895667051,"lng":-75.878423912},
        {"name":"Chimá","lat":9.11024941,"lng":-75.652373511},
        {"name":"Chinú","lat":9.053287104,"lng":-75.373873626},
        {"name":"Ciénaga de Oro","lat":8.841811894,"lng":-75.607798292},
        {"name":"Cotorra","lat":9.071460219,"lng":-75.754592755},
        {"name":"La Apartada","lat":8.052218422,"lng":-75.28816022},
        {"name":"Lorica","lat":9.165034998,"lng":-75.909781236},
        {"name":"Los Córdobas","lat":8.823307063,"lng":-76.343055201},
        {"name":"Momil","lat":9.265461621,"lng":-75.650908762},
        {"name":"Montelíbano","lat":7.866432635,"lng":-75.875339255},
        {"name":"Montería","lat":8.584698016,"lng":-75.950550788},
        {"name":"Moñitos","lat":9.190515343,"lng":-76.119317094},
        {"name":"Planeta Rica","lat":8.181108488,"lng":-75.68923803},
        {"name":"Pueblo Nuevo","lat":null,"lng":null},
        {"name":"Puerto Escondido","lat":null,"lng":null},
        {"name":"Puerto Libertador","lat":null,"lng":null},
        {"name":"Purísima","lat":null,"lng":null},
        {"name":"Sahagún","lat":null,"lng":null},
        {"name":"San Andrés de Sotavento","lat":null,"lng":null},
        {"name":"San Antero","lat":null,"lng":null},
        {"name":"San Bernardo del Viento","lat":null,"lng":null},
        {"name":"San Carlos","lat":null,"lng":null},
        {"name":"San José de Uré","lat":null,"lng":null},
        {"name":"San Pelayo","lat":null,"lng":null},
        {"name":"Tierralta","lat":null,"lng":null},
        {"name":"Tuchín","lat":null,"lng":null},
        {"name":"Valencia","lat":null,"lng":null}
    ]
},
{  
    "id":14,
    "departamento":"Guain\u00eda",
    "lat":2.6042234,
    "lng":-71.1886218,
    "ciudades":[
        {"name":"Inírida","lat":3.8684,"lng":-67.9239}
    ]
},
{  
    "id":15,
    "departamento":"Guaviare",
    "lat":1.7936722,
    "lng":-72.9500695,
    "ciudades":[  
        {"name":"Calamar","lat":null,"lng":null},
        {"name":"El Retorno","lat":null,"lng":null},
        {"name":"Miraflores","lat":null,"lng":null},
        {"name":"San José del Guaviare","lat":2.5691,"lng":-72.6422}
    ]
},
{  
    "id":16,
    "departamento":"Huila",
    "lat":2.6735718,
    "lng":-76.0793888,
    "ciudades":[
        {"name":"Acevedo","lat":null,"lng":null},
        {"name":"Agrado","lat":null,"lng":null},
        {"name":"Aipe","lat":null,"lng":null},
        {"name":"Algeciras","lat":null,"lng":null},
        {"name":"Altamira","lat":null,"lng":null},
        {"name":"Baraya","lat":null,"lng":null},
        {"name":"Campoalegre","lat":null,"lng":null},
        {"name":"Colombia","lat":null,"lng":null},
        {"name":"El Pital","lat":null,"lng":null},
        {"name":"Elías","lat":null,"lng":null},
        {"name":"Garzón","lat":null,"lng":null},
        {"name":"Gigante","lat":null,"lng":null},
        {"name":"Guadalupe","lat":null,"lng":null},
        {"name":"Hobo","lat":null,"lng":null},
        {"name":"Íquira","lat":null,"lng":null},
        {"name":"Isnos","lat":null,"lng":null},
        {"name":"La Argentina","lat":null,"lng":null},
        {"name":"La Plata","lat":null,"lng":null},
        {"name":"Nátaga","lat":null,"lng":null},
        {"name":"Neiva","lat":2.9273,"lng":-75.2819},
        {"name":"Oporapa","lat":null,"lng":null},
        {"name":"Paicol","lat":null,"lng":null},
        {"name":"Palermo","lat":null,"lng":null},
        {"name":"Palestina","lat":null,"lng":null},
        {"name":"Pitalito","lat":null,"lng":null},
        {"name":"Rivera","lat":null,"lng":null},
        {"name":"Saladoblanco","lat":null,"lng":null},
        {"name":"San Agustín","lat":null,"lng":null},
        {"name":"Santa María","lat":null,"lng":null},
        {"name":"Suaza","lat":null,"lng":null},
        {"name":"Tarqui","lat":null,"lng":null},
        {"name":"Tello","lat":null,"lng":null},
        {"name":"Teruel","lat":null,"lng":null},
        {"name":"Tesalia","lat":null,"lng":null},
        {"name":"Timaná","lat":null,"lng":null},
        {"name":"Villavieja","lat":null,"lng":null},
        {"name":"Yaguará","lat":null,"lng":null}
    ]
},
{  
    "id":17,
    "departamento":"La Guajira",
    "lat":11.42874,
    "lng":-72.9507656,
    "ciudades":[  
        {"name":"Albania","lat":null,"lng":null},
        {"name":"Barrancas","lat":null,"lng":null},
        {"name":"Dibulla","lat":null,"lng":null},
        {"name":"Distracción","lat":null,"lng":null},
        {"name":"El Molino","lat":null,"lng":null},
        {"name":"Fonseca","lat":null,"lng":null},
        {"name":"Hatonuevo","lat":null,"lng":null},
        {"name":"La Jagua del Pilar","lat":null,"lng":null},
        {"name":"Maicao","lat":null,"lng":null},
        {"name":"Manaure","lat":null,"lng":null},
        {"name":"Riohacha","lat":11.5449,"lng": -72.9069},
        {"name":"San Juan del Cesar","lat":null,"lng":null},
        {"name":"Uribia","lat":null,"lng":null},
        {"name":"Urumita","lat":null,"lng":null},
        {"name":"Villanueva","lat":null,"lng":null}
    ]
},
{  
    "id":18,
    "departamento":"Magdalena",
    "lat":10.1314881,
    "lng":-75.3691989,
    "ciudades":[
        {"name":"Algarrobo","lat":null,"lng":null},
        {"name":"Aracataca","lat":null,"lng":null},
        {"name":"Ariguaní","lat":null,"lng":null},
        {"name":"Cerro de San Antonio","lat":null,"lng":null},
        {"name":"Chibolo","lat":null,"lng":null},
        {"name":"Chibolo","lat":null,"lng":null},
        {"name":"Ciénaga","lat":null,"lng":null},
        {"name":"Concordia","lat":null,"lng":null},
        {"name":"El Banco","lat":null,"lng":null},
        {"name":"El Piñón","lat":null,"lng":null},
        {"name":"El Retén","lat":null,"lng":null},
        {"name":"Fundación","lat":null,"lng":null},
        {"name":"Guamal","lat":null,"lng":null},
        {"name":"Nueva Granada","lat":null,"lng":null},
        {"name":"Pedraza","lat":null,"lng":null},
        {"name":"Pijiño del Carmen","lat":null,"lng":null},
        {"name":"Pivijay","lat":null,"lng":null},
        {"name":"Plato","lat":null,"lng":null},
        {"name":"Pueblo Viejo","lat":null,"lng":null},
        {"name":"Remolino","lat":null,"lng":null},
        {"name":"Sabanas de San ángel","lat":null,"lng":null},
        {"name":"Salamina","lat":null,"lng":null},
        {"name":"San Sebastián de Buenavista","lat":null,"lng":null},
        {"name":"San Zenón","lat":null,"lng":null},
        {"name":"Santa Ana","lat":null,"lng":null},
        {"name":"Santa Bárbara de Pinto","lat":null,"lng":null},
        {"name":"Santa Marta","lat":11.2404,"lng":-74.2110},
        {"name":"Sitionuevo","lat":null,"lng":null},
        {"name":"Tenerife","lat":null,"lng":null},
        {"name":"Zapayán","lat":null,"lng":null},
        {"name":"Zona Bananera","lat":null,"lng":null}
    ]
},
{  
    "id":19,
    "departamento":"Meta",
    "lat":3.2672965,
    "lng":-75.2942488,
    "ciudades":[  
        {"name":"Acacías","lat":null,"lng":null},
        {"name":"Barranca de Upía","lat":null,"lng":null},
        {"name":"Cabuyaro","lat":null,"lng":null},
        {"name":"Castilla la Nueva","lat":null,"lng":null},
        {"name":"Cubarral","lat":null,"lng":null},
        {"name":"Cumaral","lat":null,"lng":null},
        {"name":"El Calvario","lat":null,"lng":null},
        {"name":"El Castillo","lat":null,"lng":null},
        {"name":"El Dorado","lat":null,"lng":null},
        {"name":"Fuente de Oro","lat":null,"lng":null},
        {"name":"Granada","lat":null,"lng":null},
        {"name":"Guamal","lat":null,"lng":null},
        {"name":"La Macarena","lat":null,"lng":null},
        {"name":"La Uribe","lat":null,"lng":null},
        {"name":"Lejanías","lat":null,"lng":null},
        {"name":"Mapiripán","lat":null,"lng":null},
        {"name":"Mesetas","lat":null,"lng":null},
        {"name":"Puerto Concordia","lat":null,"lng":null},
        {"name":"Puerto Gaitán","lat":null,"lng":null},
        {"name":"Puerto Lleras","lat":null,"lng":null},
        {"name":"Puerto López","lat":null,"lng":null},
        {"name":"Puerto Rico","lat":null,"lng":null},
        {"name":"Restrepo","lat":null,"lng":null},
        {"name":"San Carlos de Guaroa","lat":null,"lng":null},
        {"name":"San Juan de Arama","lat":null,"lng":null},
        {"name":"San Juanito","lat":null,"lng":null},
        {"name":"San Martín","lat":null,"lng":null},
        {"name":"Villavicencio","lat":4.1420,"lng":-73.6266},
        {"name":"Vista Hermosa","lat":null,"lng":null}
    ]
},
{  
    "id":20,
    "departamento":"Nari\u00f1o",
    "lat":1.5278403,
    "lng":-78.4840582,
    "ciudades":[  
        {"name":"Aldana","lat":null,"lng":null},
        {"name":"Ancuyá","lat":null,"lng":null},
        {"name":"Arboleda","lat":null,"lng":null},
        {"name":"Barbacoas","lat":null,"lng":null},
        {"name":"Belén","lat":null,"lng":null},
        {"name":"Buesaco","lat":null,"lng":null},
        {"name":"Chachagüí","lat":null,"lng":null},
        {"name":"Colón","lat":null,"lng":null},
        {"name":"Consacá","lat":null,"lng":null},
        {"name":"Contadero","lat":null,"lng":null},
        {"name":"Córdoba","lat":null,"lng":null},
        {"name":"Cuaspud","lat":null,"lng":null},
        {"name":"Cumbal","lat":null,"lng":null},
        {"name":"Cumbitara","lat":null,"lng":null},
        {"name":"El Charco","lat":null,"lng":null},
        {"name":"El Peñol","lat":null,"lng":null},
        {"name":"El Rosario","lat":null,"lng":null},
        {"name":"El Tablón","lat":null,"lng":null},
        {"name":"El Tambo","lat":null,"lng":null},
        {"name":"Francisco Pizarro","lat":null,"lng":null},
        {"name":"Funes","lat":null,"lng":null},
        {"name":"Guachucal","lat":null,"lng":null},
        {"name":"Guaitarilla","lat":null,"lng":null},
        {"name":"Gualmatán","lat":null,"lng":null},
        {"name":"Iles","lat":null,"lng":null},
        {"name":"Imués","lat":null,"lng":null},
        {"name":"Ipiales","lat":null,"lng":null},
        {"name":"La Cruz","lat":null,"lng":null},
        {"name":"La Florida","lat":null,"lng":null},
        {"name":"La Llanada","lat":null,"lng":null},
        {"name":"La Tola","lat":null,"lng":null},
        {"name":"La Unión","lat":null,"lng":null},
        {"name":"Leiva","lat":null,"lng":null},
        {"name":"Linares","lat":null,"lng":null},
        {"name":"Los Andes","lat":null,"lng":null},
        {"name":"Magüí Payán","lat":null,"lng":null},
        {"name":"Mallama","lat":null,"lng":null},
        {"name":"Mosquera","lat":null,"lng":null},
        {"name":"Nariño","lat":null,"lng":null},
        {"name":"Olaya Herrera","lat":null,"lng":null},
        {"name":"Ospina","lat":null,"lng":null},
        {"name":"Pasto","lat":1.2136,"lng":-77.2811},
        {"name":"Policarpa","lat":null,"lng":null},
        {"name":"Potosí","lat":null,"lng":null},
        {"name":"Providencia","lat":null,"lng":null},
        {"name":"Puerres","lat":null,"lng":null},
        {"name":"Pupiales","lat":null,"lng":null},
        {"name":"Ricaurte","lat":null,"lng":null},
        {"name":"Roberto Payán","lat":null,"lng":null},
        {"name":"Samaniego","lat":null,"lng":null},
        {"name":"San Bernardo","lat":null,"lng":null},
        {"name":"San José de Albán","lat":null,"lng":null},
        {"name":"San Lorenzo","lat":null,"lng":null},
        {"name":"San Pablo","lat":null,"lng":null},
        {"name":"San Pedro de Cartago","lat":null,"lng":null},
        {"name":"Sandoná","lat":null,"lng":null},
        {"name":"Santa Bárbara","lat":null,"lng":null},
        {"name":"Santacruz","lat":null,"lng":null},
        {"name":"Sapuyes","lat":null,"lng":null},
        {"name":"Taminango","lat":null,"lng":null},
        {"name":"Tangua","lat":null,"lng":null},
        {"name":"Tumaco","lat":null,"lng":null},
        {"name":"Túquerres","lat":null,"lng":null},
        {"name":"Yacuanquer","lat":null,"lng":null}
    ]
},
{  
    "id":21,
    "departamento":"Norte de Santander",
    "lat":8.0829755,
    "lng":-73.9568591,
    "ciudades":[ 
        {"name":"Ábrego","lat":null,"lng":null},
        {"name":"Arboledas","lat":null,"lng":null},
        {"name":"Bochalema","lat":null,"lng":null},
        {"name":"Bucarasica","lat":null,"lng":null},
        {"name":"Cáchira","lat":null,"lng":null},
        {"name":"Cácota","lat":null,"lng":null},
        {"name":"Chinácota","lat":null,"lng":null},
        {"name":"Chitagá","lat":null,"lng":null},
        {"name":"Convención","lat":null,"lng":null},
        {"name":"Cúcuta","lat":7.8939,"lng": -72.5079},
        {"name":"Cucutilla","lat":null,"lng":null},
        {"name":"Durania","lat":null,"lng":null},
        {"name":"El Carmen","lat":null,"lng":null},
        {"name":"El Tarra","lat":null,"lng":null},
        {"name":"El Zulia","lat":null,"lng":null},
        {"name":"Gramalote","lat":null,"lng":null},
        {"name":"Hacarí","lat":null,"lng":null},
        {"name":"Herrán","lat":null,"lng":null},
        {"name":"La Esperanza","lat":null,"lng":null},
        {"name":"La Playa de Belén","lat":null,"lng":null},
        {"name":"Labateca","lat":null,"lng":null},
        {"name":"Los Patios","lat":null,"lng":null},
        {"name":"Lourdes","lat":null,"lng":null},
        {"name":"Mutiscua","lat":null,"lng":null},
        {"name":"Ocaña","lat":null,"lng":null},
        {"name":"Pamplona","lat":null,"lng":null},
        {"name":"Pamplonita","lat":null,"lng":null},
        {"name":"Puerto Santander","lat":null,"lng":null},
        {"name":"Ragonvalia","lat":null,"lng":null},
        {"name":"Salazar de Las Palmas","lat":null,"lng":null},
        {"name":"San Calixto","lat":null,"lng":null},
        {"name":"San Cayetano","lat":null,"lng":null},
        {"name":"Santiago","lat":null,"lng":null},
        {"name":"Santo Domingo de Silos","lat":null,"lng":null},
        {"name":"Sardinata","lat":null,"lng":null},
        {"name":"Teorama","lat":null,"lng":null},
        {"name":"Tibú","lat":null,"lng":null},
        {"name":"Toledo","lat":null,"lng":null},
        {"name":"Villa Caro","lat":null,"lng":null},
        {"name":"Villa del Rosario","lat":null,"lng":null}
    ]
},
{  
    "id":22,
    "departamento":"Putumayo",
    "lat":0.471946,
    "lng":-76.0941109,
    "ciudades":[  
        {"name":"Colón","lat":null,"lng":null},
        {"name":"Mocoa","lat":1.1471,"lng":-76.6483},
        {"name":"Orito","lat":null,"lng":null},
        {"name":"Puerto Asís","lat":null,"lng":null},
        {"name":"Puerto Caicedo","lat":null,"lng":null},
        {"name":"Puerto Guzmán","lat":null,"lng":null},
        {"name":"Puerto Leguízamo","lat":null,"lng":null},
        {"name":"San Francisco","lat":null,"lng":null},
        {"name":"San Miguel","lat":null,"lng":null},
        {"name":"Santiago","lat":null,"lng":null},
        {"name":"Sibundoy","lat":null,"lng":null},
        {"name":"Valle del Guamuez","lat":null,"lng":null},
        {"name":"Villagarzón","lat":null,"lng":null}
    ]
},
{  
    "id":23,
    "departamento":"Quind\u00edo",
    "lat":4.3983315,
    "lng":-75.9205652,
    "ciudades":[
        {"name":"Armenia","lat":4.5339,"lng":-75.6811},
        {"name":"Buenavista","lat":null,"lng":null},
        {"name":"Calarcá","lat":null,"lng":null},
        {"name":"Circasia","lat":null,"lng":null},
        {"name":"Córdoba","lat":null,"lng":null},
        {"name":"Filandia","lat":null,"lng":null},
        {"name":"Génova","lat":null,"lng":null},
        {"name":"La Tebaida","lat":null,"lng":null},
        {"name":"Montenegro","lat":null,"lng":null},
        {"name":"Pijao","lat":null,"lng":null},
        {"name":"Quimbaya","lat":null,"lng":null},
        {"name":"Salento","lat":null,"lng":null}
    ]
},
{  
    "id":24,
    "departamento":"Risaralda",
    "lat":5.0974157,
    "lng":-76.3863069,
    "ciudades":[ 
        {"name":"Apía","lat":null,"lng":null},
        {"name":"Balboa","lat":null,"lng":null},
        {"name":"Belén de Umbría","lat":null,"lng":null},
        {"name":"Dosquebradas","lat":null,"lng":null},
        {"name":"Guática","lat":null,"lng":null},
        {"name":"La Celia","lat":null,"lng":null},
        {"name":"La Virginia","lat":null,"lng":null},
        {"name":"Marsella","lat":null,"lng":null},
        {"name":"Mistrató","lat":null,"lng":null},
        {"name":"Pereira","lat":4.8143,"lng":-75.6944},
        {"name":"Pueblo Rico","lat":null,"lng":null},
        {"name":"Quinchía","lat":null,"lng":null},
        {"name":"Santa Rosa de Cabal","lat":null,"lng":null},
        {"name":"Santuario","lat":null,"lng":null}
    ]
},
{  
    "id":25,
    "departamento":"San Andr\u00e9s y Providencia",
    "lat":12.9379376,
    "lng":-81.8234152,
    "ciudades":[ 
        {"name":"Providencia y Santa Catalina Islas","lat":null,"lng":null},
        {"name":"San Andrés","lat":12.5830,"lng":-81.7000}
    ]
},
{  
    "id":26,
    "departamento":"Santander",
    "lat":6.9292823,
    "lng":-74.6268584,
    "ciudades":[  
        {"name":"Aguada","lat":null,"lng":null},
        {"name":"Albania","lat":null,"lng":null},
        {"name":"Aratoca","lat":null,"lng":null},
        {"name":"Barbosa","lat":null,"lng":null},
        {"name":"Barichara","lat":null,"lng":null},
        {"name":"Barrancabermeja","lat":null,"lng":null},
        {"name":"Betulia","lat":null,"lng":null},
        {"name":"Bolívar","lat":null,"lng":null},
        {"name":"Bucaramanga","lat":7.1195,"lng":-73.1227},
        {"name":"Cabrera","lat":null,"lng":null},
        {"name":"California","lat":null,"lng":null},
        {"name":"Capitanejo","lat":null,"lng":null},
        {"name":"Carcasí","lat":null,"lng":null},
        {"name":"Cepitá","lat":null,"lng":null},
        {"name":"Cerrito","lat":null,"lng":null},
        {"name":"Charalá","lat":null,"lng":null},
        {"name":"Charta","lat":null,"lng":null},
        {"name":"Chima","lat":null,"lng":null},
        {"name":"Chipatá","lat":null,"lng":null},
        {"name":"Cimitarra","lat":null,"lng":null},
        {"name":"Concepción","lat":null,"lng":null},
        {"name":"Confines","lat":null,"lng":null},
        {"name":"Contratación","lat":null,"lng":null},
        {"name":"Coromoro","lat":null,"lng":null},
        {"name":"Curití","lat":null,"lng":null},
        {"name":"El Carmen de Chucurí","lat":null,"lng":null},
        {"name":"El Guacamayo","lat":null,"lng":null},
        {"name":"El Peñón","lat":null,"lng":null},
        {"name":"El Playón","lat":null,"lng":null},
        {"name":"El Socorro","lat":null,"lng":null},
        {"name":"Encino","lat":null,"lng":null},
        {"name":"Enciso","lat":null,"lng":null},
        {"name":"Florián","lat":null,"lng":null},
        {"name":"Floridablanca","lat":null,"lng":null},
        {"name":"Galán","lat":null,"lng":null},
        {"name":"Gámbita","lat":null,"lng":null},
        {"name":"Girón","lat":null,"lng":null},
        {"name":"Guaca","lat":null,"lng":null},
        {"name":"Guadalupe","lat":null,"lng":null},
        {"name":"Guapotá","lat":null,"lng":null},
        {"name":"Guavatá","lat":null,"lng":null},
        {"name":"Güepsa","lat":null,"lng":null},
        {"name":"Hato","lat":null,"lng":null},
        {"name":"Jesús María","lat":null,"lng":null},
        {"name":"Jordán","lat":null,"lng":null},
        {"name":"La Belleza","lat":null,"lng":null},
        {"name":"La Paz","lat":null,"lng":null},
        {"name":"Landázuri","lat":null,"lng":null},
        {"name":"Lebrija","lat":null,"lng":null},
        {"name":"Los Santos","lat":null,"lng":null},
        {"name":"Macaravita","lat":null,"lng":null},
        {"name":"Málaga","lat":null,"lng":null},
        {"name":"Matanza","lat":null,"lng":null}
        ,{"name":"Mogotes","lat":null,"lng":null},
        {"name":"Molagavita","lat":null,"lng":null},
        {"name":"Ocamonte","lat":null,"lng":null},
        {"name":"Oiba","lat":null,"lng":null},
        {"name":"Onzaga","lat":null,"lng":null},
        {"name":"Palmar","lat":null,"lng":null},
        {"name":"Palmas del Socorro","lat":null,"lng":null},
        {"name":"Páramo","lat":null,"lng":null},
        {"name":"Piedecuesta","lat":null,"lng":null},
        {"name":"Pinchote","lat":null,"lng":null},
        {"name":"Puente Nacional","lat":null,"lng":null},
        {"name":"Puerto Parra","lat":null,"lng":null},
        {"name":"Puerto Wilches","lat":null,"lng":null},
        {"name":"Rionegro","lat":null,"lng":null},
        {"name":"Sabana de Torres","lat":null,"lng":null},
        {"name":"San Andrés","lat":null,"lng":null},
        {"name":"San Benito","lat":null,"lng":null},
        {"name":"San Gil","lat":null,"lng":null},
        {"name":"San Joaquín","lat":null,"lng":null},
        {"name":"San José de Miranda","lat":null,"lng":null},
        {"name":"San Miguel","lat":null,"lng":null},
        {"name":"San Vicente de Chucurí","lat":null,"lng":null},
        {"name":"Santa Bárbara","lat":null,"lng":null},
        {"name":"Santa Helena del Opón","lat":null,"lng":null},
        {"name":"Simacota","lat":null,"lng":null},
        {"name":"Suaita","lat":null,"lng":null},
        {"name":"Sucre","lat":null,"lng":null},
        {"name":"Suratá","lat":null,"lng":null},
        {"name":"Tona","lat":null,"lng":null},
        {"name":"Valle de San José","lat":null,"lng":null},
        {"name":"Vélez","lat":null,"lng":null},
        {"name":"Vetas","lat":null,"lng":null},
        {"name":"Villanueva","lat":null,"lng":null},
        {"name":"Zapatoca","lat":null,"lng":null}
    ]
},
{  
    "id":27,
    "departamento":"Sucre",
    "lat":9.2127876,
    "lng":-75.6828289,
    "ciudades":[  
        {"name":"Buenavista","lat":null,"lng":null},
        {"name":"Caimito","lat":null,"lng":null},
        {"name":"Chalán","lat":null,"lng":null},
        {"name":"Colosó","lat":null,"lng":null},
        {"name":"Corozal","lat":null,"lng":null},
        {"name":"Coveñas","lat":null,"lng":null},
        {"name":"El Roble","lat":null,"lng":null},
        {"name":"Galeras","lat":null,"lng":null},
        {"name":"Guaranda","lat":null,"lng":null},
        {"name":"La Unión","lat":null,"lng":null},
        {"name":"Los Palmitos","lat":null,"lng":null},
        {"name":"Majagual","lat":null,"lng":null},
        {"name":"Morroa","lat":null,"lng":null},
        {"name":"Ovejas","lat":null,"lng":null},
        {"name":"Sampués","lat":null,"lng":null},
        {"name":"San Antonio de Palmito","lat":null,"lng":null},
        {"name":"San Benito Abad","lat":null,"lng":null},
        {"name":"San Juan de Betulia","lat":null,"lng":null},
        {"name":"San Marcos","lat":null,"lng":null},
        {"name":"San Onofre","lat":null,"lng":null},
        {"name":"San Pedro","lat":null,"lng":null},
        {"name":"Sincé","lat":null,"lng":null},
        {"name":"Sincelejo","lat":9.3044,"lng":-75.3970},
        {"name":"Sucre","lat":null,"lng":null},
        {"name":"Tolú","lat":null,"lng":null},
        {"name":"Tolú Viejo","lat":null,"lng":null}
    ]
},
{  
    "id":28,
    "departamento":"Tolima",
    "lat":4.0960773,
    "lng":-76.415664,
    "ciudades":[  
        {"name":"Alpujarra","lat":null,"lng":null},
        {"name":"Alvarado","lat":null,"lng":null},
        {"name":"Ambalema","lat":null,"lng":null},
        {"name":"Anzoátegui","lat":null,"lng":null},
        {"name":"Armero","lat":null,"lng":null},
        {"name":"Ataco","lat":null,"lng":null},
        {"name":"Cajamarca","lat":null,"lng":null},
        {"name":"Carmen de Apicalá","lat":null,"lng":null},
        {"name":"Casabianca","lat":null,"lng":null},
        {"name":"Chaparral","lat":null,"lng":null},
        {"name":"Coello","lat":null,"lng":null},
        {"name":"Coyaima","lat":null,"lng":null},
        {"name":"Cunday","lat":null,"lng":null},
        {"name":"Dolores","lat":null,"lng":null},
        {"name":"El Espinal","lat":null,"lng":null},
        {"name":"Falán","lat":null,"lng":null},
        {"name":"Flandes","lat":null,"lng":null},
        {"name":"Fresno","lat":null,"lng":null},
        {"name":"Guamo","lat":null,"lng":null},
        {"name":"Herveo","lat":null,"lng":null},
        {"name":"Honda","lat":null,"lng":null},
        {"name":"Ibagué","lat":4.4389,"lng":-75.2322},
        {"name":"Icononzo","lat":null,"lng":null},
        {"name":"Lérida","lat":null,"lng":null},
        {"name":"Líbano","lat":null,"lng":null},
        {"name":"Mariquita","lat":null,"lng":null},
        {"name":"Melgar","lat":null,"lng":null},
        {"name":"Murillo","lat":null,"lng":null},
        {"name":"Natagaima","lat":null,"lng":null},
        {"name":"Ortega","lat":null,"lng":null},
        {"name":"Palocabildo","lat":null,"lng":null},
        {"name":"Piedras","lat":null,"lng":null},
        {"name":"Planadas","lat":null,"lng":null},
        {"name":"Prado","lat":null,"lng":null},
        {"name":"Purificación","lat":null,"lng":null},
        {"name":"Rioblanco","lat":null,"lng":null},
        {"name":"Roncesvalles","lat":null,"lng":null},
        {"name":"Rovira","lat":null,"lng":null},
        {"name":"Saldaña","lat":null,"lng":null},
        {"name":"San Antonio","lat":null,"lng":null},
        {"name":"San Luis","lat":null,"lng":null},
        {"name":"Santa Isabel","lat":null,"lng":null},
        {"name":"Suárez","lat":null,"lng":null},
        {"name":"Valle de San Juan","lat":null,"lng":null},
        {"name":"Venadillo","lat":null,"lng":null},
        {"name":"Villahermosa","lat":null,"lng":null},
        {"name":"Villarrica","lat":null,"lng":null}
    ]
},
{  
    "id":29,
    "departamento":"Valle del Cauca",
    "lat":4.0389639,
    "lng":-77.7771017,
    "ciudades":[
        {"name":"Alcalá","lat":null,"lng":null},
        {"name":"Andalucía","lat":null,"lng":null},
        {"name":"Ansermanuevo","lat":null,"lng":null},
        {"name":"Argelia","lat":null,"lng":null},
        {"name":"Bolívar","lat":null,"lng":null},
        {"name":"Buenaventura","lat":null,"lng":null},
        {"name":"Buga","lat":null,"lng":null},
        {"name":"Bugalagrande","lat":null,"lng":null},
        {"name":"Caicedonia","lat":null,"lng":null},
        {"name":"Cali","lat":3.451646,"lng":-76.532051},
        {"name":"Calima","lat":null,"lng":null},
        {"name":"Candelaria","lat":null,"lng":null},
        {"name":"Cartago","lat":null,"lng":null},
        {"name":"Dagua","lat":null,"lng":null},
        {"name":"El Águila","lat":null,"lng":null},
        {"name":"El Cairo","lat":null,"lng":null},
        {"name":"El Cerrito","lat":null,"lng":null},
        {"name":"El Dovio","lat":null,"lng":null},
        {"name":"Florida","lat":null,"lng":null},
        {"name":"Ginebra","lat":null,"lng":null},
        {"name":"Guacarí","lat":null,"lng":null},
        {"name":"Jamundí","lat":null,"lng":null},
        {"name":"La Cumbre","lat":null,"lng":null},
        {"name":"La Unión","lat":null,"lng":null},
        {"name":"La Victoria","lat":null,"lng":null},
        {"name":"Obando","lat":null,"lng":null},
        {"name":"Palmira","lat":null,"lng":null},
        {"name":"Pradera","lat":null,"lng":null},
        {"name":"Restrepo","lat":null,"lng":null},
        {"name":"Riofrío","lat":null,"lng":null},
        {"name":"Roldanillo","lat":null,"lng":null},
        {"name":"San Pedro","lat":null,"lng":null},
        {"name":"Sevilla","lat":null,"lng":null},
        {"name":"Toro","lat":null,"lng":null},
        {"name":"Trujillo","lat":null,"lng":null},
        {"name":"Tuluá","lat":null,"lng":null},
        {"name":"Ulloa","lat":null,"lng":null},
        {"name":"Versalles","lat":null,"lng":null},
        {"name":"Vijes","lat":null,"lng":null},
        {"name":"Yotoco","lat":null,"lng":null},
        {"name":"Yumbo","lat":null,"lng":null},
        {"name":"Zarzal","lat":null,"lng":null}
    ]
},
{  
    "id":30,
    "departamento":"Vaup\u00e9s",
    "lat":0.4272638,
    "lng":-71.7004466,
    "ciudades":[ 
        {"name":"Carurú","lat":null,"lng":null},
        {"name":"Mitú","lat":1.1987,"lng":-70.1734},
        {"name":"Taraira","lat":null,"lng":null}
    ]
},
{  
    "id":31,
    "departamento":"Vichada",
    "lat":4.5317289,
    "lng":-70.3649554,
    "ciudades":[ 
        {"name":"Cumaribo","lat":null,"lng":null},
        {"name":"La Primavera","lat":null,"lng":null},
        {"name":"Puerto Carre\u00f1o","lat":6.1890,"lng":-67.4859},
        {"name":"Santa Rosal\u00eda","lat":null,"lng":null}
    ]
}
]

export default departamentos;