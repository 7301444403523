import React,{useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Dialog  from "@material-ui/core/Dialog";
import DialogActions  from "@material-ui/core/DialogActions";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { CompactPicker  } from 'react-color';
import { ThemeContext } from '../../context/ThemeContext';
import { putTheme } from '../../services/theme';
import {AuthContext} from '../../context/AuthContext';
import CloseRounded from '@material-ui/icons/CloseRounded';

const ThemingDialog = (props) => {  
  const { handleClose, openDialog } = props;
  const {user} = useContext(AuthContext)
  const {themeColor,setThemeColor} = useContext(ThemeContext);

  const handleChangeComplete = (color) => {
    setThemeColor({ 'background-color': color.hex,'color':getColorText(color) });
    localStorage.setItem("theme",JSON.stringify({ 'background-color': color.hex,'color':getColorText(color) }));    
    putTheme(user.id,{theme:{ 'background-color': color.hex,'color':getColorText(color) } }).then(
      resp =>{
       
      },      
    )
    .catch(
      (err) => {
        console.error(err);
      }
    )
  };

  const handleChange = (color,event) =>{    
    setThemeColor({ 'background-color': color.hex,'color':getColorText(color) });    
  }
 
  const getColorText = (color) =>{
    return (
      color.rgb.r > 170 && color.rgb.g > 170 ||
      color.rgb.g > 170 && color.rgb.b > 170 ||
      color.rgb.b > 170 && color.rgb.r > 170)?
    "#333333" :
    "#fafafa"
  }


  ThemingDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    openDialog: PropTypes.bool.isRequired,
  };

  return (
    <Dialog onClose={handleClose} open={openDialog} xs={{width:200}}>     
      <DialogTitle style={{paddingBottom:0}}>
        Seleccionar color del tema
        <Button onClick={handleClose} autoFocus>
            <CloseRounded/>
          </Button>
      </DialogTitle> 
      <DialogContent style={{
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
        alignItems:"center"
      }}>
      
      <CompactPicker
        color={ themeColor['background-color']}
        onChange={handleChange} 
        onChangeComplete={handleChangeComplete}        
      />            

      </DialogContent>
    </Dialog>
  );
}


export default ThemingDialog;



