import HttpResponseEmun from '../helpers/enums/HttpResponseEmun'
import UserModelsEnum from '../helpers/enums/UserModelsEnum'
import Request from './Request'

export const registerBroker = async (broker) => {
  const brokerData = {
    type: broker.type,
    otherType: broker.otherType,
    identification:broker.identification,
    user: {
      names: broker.names,
      lastNames: broker.lastNames,
      email: broker.email,
      password: broker.password,
      address: 'This does not exist on form',
      phone: broker.phone,
      isPublic: true,
      status: 1,
    },
  }

  try {
    const response = await Request.post('/public/brokers', brokerData)
    if (response.status === HttpResponseEmun.OK) {
      const { data } = response
      const { token, id } = data
      localStorage.setItem('authorization', token)
      localStorage.setItem('user', JSON.stringify({ model: UserModelsEnum.Broker, id }))
      return {value:true,data:{ model: UserModelsEnum.Broker, id }}
    } else {      
      return {value:false,error:response.data.message}      
    }
  } catch (error) {
    return { error,value:false}
  }
  
}

export const getBrokers = async () => {
  try {
    const response = await Request.get('/public/brokers')
    if (response.status === HttpResponseEmun.OK) {
      const { data } = response
      return data.data
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const getAllBrokers = async (filter) => {
  try {
    const response = await Request.get('/brokers', {...filter})
    if (response.status === HttpResponseEmun.OK) {
      const { data } = response
      return data;
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const getBrokerUser = async (id) => {
  try {
    const response = await Request.get(`/users/${id}`)
    if (response.status === HttpResponseEmun.OK) {
      const { data } = response
      return data
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const getBrokerData = async (id) => {
  try {
    const response = await Request.get(`/brokers/${id}`)
    if (response.status === HttpResponseEmun.OK) {
      const { data } = response
      return data
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const updateBroker = async (id, broker) => {
  try {
    const response = await Request.put(`/users/${id}`, broker)
    if (response.status === HttpResponseEmun.OK) {
      return true
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const updateBrokerData = async (id, broker) => {
  try {
    const response = await Request.put(`/brokers/${id}`, broker)
    if (response.status === HttpResponseEmun.OK) {
      return true
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const getPhoto = async (id, filename) => {
  try {
    const response = await Request.get(
      `/file/${id}/${filename}`,
      {
        model: UserModelsEnum.User,
      },
      { responseType: 'blob' }
    )
    if (response.status === HttpResponseEmun.OK) {
      const blob = response.data
      const url = window.URL.createObjectURL(blob)

      return url
    }
  } catch (error) {
    console.error(error)
  }
  return false
}

export const updatePhoto = async (logo) => {
  try {
    const response = await Request.post(`/users/me/photo`, logo)
    if (response.status === HttpResponseEmun.OK) {
      return true
    }
  } catch (error) {
    console.error(error)
  }
  return false
}
