import Request from './Request';

export default async function getLands(filter, id) {
   
    try {
            const response = await Request.get(`/land-requests`, { ...filter, stateParam: filter.stateParam || 'OPEN,PAUSED,CANCELLED,CLOSED', associateTypeParam: id})
   
            return response
        
        
    } catch (error) {
        error && console.error(error)
        return error
    }
    
  }