import React, { useState, useContext, useEffect } from 'react'
import { EditTwoTone, MinusOutlined, UserSwitchOutlined, DeleteOutlined } from '@ant-design/icons';
import { Divider, Pagination, Empty, Tooltip, message, Popconfirm, Dropdown, Menu, Input } from 'antd';
import { Filter } from '../../AdminPanelFilter/AdminPanelFilter';
import { FilterHeader } from '../../AdminHeaderFilter/AdminHeaderFilter';
import CreateButtonRow from '../../AdminPanelSetting/CreateButtonRow/CreateButtonRow';
import sort from '../../handleSort';

import { Context as contextArea } from '../../../context/AreaContext';
import { Context as contextRange } from '../../../context/RangeContext';

import {CreateArea} from './CreateArea/CreateArea';
import {CreateRange} from './CreateRange/CreateRange';

import {DeleteRange} from './CreateRange/CreateRange.service';
import {DeleteArea} from './CreateArea/Location/createArea.service';
import Request from '../../../services/Request';
const { Search } = Input;
const SubMenu = Menu.SubMenu;

const thead = {
  RANGE: [
    {item:'name', label:'Nombre'},
    {item:'startRange', label:'Rango inicial'},
    {item:'endRange', label:'Rango final'},
    {item:'state', label:'Estado'}, 
    {item:'', label:''},       
  ],
  AREA: [
    {item:'department',   open:false,label:'Departamento'},
    {item:'city',         open:false,label:'Ciudad'},
    {item:'location',     open:false,label:'Localidad'},
    {item:'UPZ',          open:false,label:'UPZ'},
    {item:'neighborhood', open:false,label:'Barrio'},    
    {item:'',             open:false,label:''},    
  ]
}

const filtro  = {"deparment":[],"city":[],"location":[],"commune":[],"UPZ":[],"neighborhood":[]}
const TableListConfigurations = ({datas, setDatas, filter, setFilter ,toggle,refresh}) => {
    const {setArea} = useContext(contextArea);
    const {setRange} = useContext(contextRange);
    const [isEditModeArea, setIsEditModeArea] = useState(false);
    const [isEditModeRange, setIsEditModeRange] = useState(false);
    const [newArea, setNewArea] = useState(false);
    const [newRange, setNewRange] = useState(false);
    const [showCreateArea,setShowCreateArea] = useState(false)
    const [showCreateRange,setShowCreateRange] = useState(false)
    const [filterSelect,setFilterSelect] = useState(filtro);    

    const [open, setOpen] = useState(false);

    useEffect(() => {
      const getFilterList = async () => {
        const uniqueValues = await Request.get(`/geographical-area/values/unique`);
        setFilterSelect(uniqueValues.data);
      }
      getFilterList();
    },[])
    const handleOpenChange = (flag) => {
      console.log(flag);
      
    };
    const returnDropdownFilter = (item) => {
      console.log(item);
      return <Menu
      onOpenChange={handleOpenChange}
      items={[
       {        
         key: '1',
         label: (
           <input type='text' placeholder='search' />
         ),
       },
      ]}
      />     
     }

    const FilterSvg = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filter-left" viewBox="0 0 16 16">
        <path d="M2 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
    </svg>

    const handleSort = (item) =>{
      setFilter({...filter, ordering: sort(item)}) 
    }
    //Map
    const handleShowCreateArea = () => {      
      setShowCreateArea(true);
    }
    const handleCloseCreateArea = (val)=>{
      refresh(val);
      setIsEditModeArea(false);
      setShowCreateArea(false);
    }

    //Range
    const handleShowCreateRange = () => {
      setShowCreateRange(true);
    }
    const handleCloseCreateRange = (val)=>{
      refresh(val);
      setIsEditModeRange(false);
      setShowCreateRange(false);
    }        
    const format = (num)=> {
      let formatNumber = new Intl.NumberFormat("ES-MX", {
        style: "currency",
        currency: "MXN"
      }).format(num)
      formatNumber = formatNumber + '';
      if(formatNumber.includes('.00')){
        formatNumber = formatNumber.replace('.00','')
      }
      formatNumber = formatNumber.replace(',','.');
      return formatNumber.replace('$','');
    }
   

    const getBodyByType = (developer) =>{      
      if(toggle === "AREA"){
        return (
          <>
            <td className='col-listUser-adminPanel col2' scope="row">{developer.department}</td>
            <td className='col-listUser-adminPanel col3' scope="row">{developer.city}</td>
            <td className='col-listUser-adminPanel col4' scope="row">{developer.location}</td>
            <td className='col-listUser-adminPanel col5' scope="row">{developer.UPZ}</td>
            <td className='col-listUser-adminPanel col6' scope="row">{developer.neighborhood}</td>                                
            <td className='col-listUser-adminPanel col7' scope="row">
                <span>
                <MinusOutlined style={{ fontSize: '1.5rem', color: 'gray', transform: 'rotate(90deg)' }} />
                </span>
                <span style={{ cursor: 'pointer', margin: '0 5px' }} >
                    <Tooltip placement="top" title='Editar'>
                        <EditTwoTone onClick={() => {
                            setIsEditModeArea(true);
                            const editParams = {
                              _id:developer._id,
                              departmentParam:developer.department,
                              cityParam:developer.city,
                              point:developer.point.map( ( p => ([p.latitude,p.longitude]) ) ),
                              locationParam   :developer.location ? developer.location: '',
                              communeParam     :developer.commune ? developer.commune: '',
                              UPZParam         :developer.UPZ ? developer.UPZ: '',
                              neighborhoodParam:developer.neighborhood,
                            }                            
                            setArea(editParams);
                            handleShowCreateArea();
                            }} style={{ fontSize: '1.5rem' }} />
                    </Tooltip>
                </span>
                {filter && filter.statusParam === 'ACTIVE' && 
                <span style={{margin: '0 5px'}}>
                    <Popconfirm
                    title={`¿Estas seguro que quieres eliminar esta Área?`}
                    onConfirm={async ()=> {
                      const resp = await DeleteArea(developer._id);
                      if(resp){
                        message.success('El área se elimino correctamente');
                      }else{
                        message.error('Hubo un error al eliminar el área');
                      }
                      refresh(true);
                      
                    }}
                    okText="Si"
                    cancelText="No"
                    >
                    <Tooltip placement="top" title='Eliminar'>
                    <DeleteOutlined style={{ cursor: 'pointer', fontSize: '1.5rem', color: '#F93154' }} />
                    </Tooltip>
                    </Popconfirm>
                </span> }
                {filter && filter.statusParam === 'INACTIVE' &&
                  <span style={{margin: '0 5px'}}>
                    <Tooltip placement="top" title='Activar'>
                      <UserSwitchOutlined onClick={()=>{                        
                      }} />
                    </Tooltip>
                </span> }
            </td>
          </>
        )        
      }else{
        return (
          <>
            <td className='col-listUser-adminPanel col2' scope="row">{developer.name}</td>
            <td className='col-listUser-adminPanel col3' scope="row">{format(developer.startRange)}</td>
            <td className='col-listUser-adminPanel col4' scope="row">{format(developer.endRange)}</td>
            <td className='col-listUser-adminPanel col5' scope="row">{developer.state===1?'Activo':'Inactivo'}</td>            
            <td className='col-listUser-adminPanel col7' scope="row">
                <span>
                <MinusOutlined style={{ fontSize: '1.5rem', color: 'gray', transform: 'rotate(90deg)' }} />
                </span>
                <span style={{ cursor: 'pointer', margin: '0 5px' }} >
                    <Tooltip placement="top" title='Editar'>
                        <EditTwoTone onClick={() => {
                          setIsEditModeRange(true);
                          setRange({...developer});
                          handleShowCreateRange();
                            }} style={{ fontSize: '1.5rem' }} />
                    </Tooltip>
                </span>
                 
               {filter && filter.stateParam === 'ACTIVE' && <span style={{margin: '0 5px'}}>
                    <Popconfirm
                    title={`¿Estas seguro que quieres eliminar este Rango de área?`}
                    onConfirm={()=> {
                      const resp = DeleteRange(developer._id);
                      if(resp){
                        message.success('El rango área se elimino correctamente');
                      }else{
                        message.error('Hubo un error al eliminar el rango area');
                      }
                      refresh(false);
                      refresh(true);
                    }}
                    okText="Si"
                    cancelText="No"
                    >
                    <Tooltip placement="top" title='Eliminar'>
                    <DeleteOutlined style={{ cursor: 'pointer', fontSize: '1.5rem', color: '#F93154' }} />
                    </Tooltip>
                    </Popconfirm>
                </span> }
                
            </td>
          </>
        )
      }
    }
            
return (
        <>
        
             <Filter filterUser={filter} setFilteruser={setFilter} defaultChecked={filter.statusParam} noSwitch={toggle==='AREA'}/>
         <div >   
            <table >
            {datas.type === 'AREA' &&  <CreateButtonRow title="Crear área geográfica" onClick={()=>{handleShowCreateArea()}} />} 
            {datas.type === 'RANGE' &&  <CreateButtonRow title="Crear rango de área"  onClick={()=>{handleShowCreateRange()}} />} 
            <CreateArea  filter={filter} visible={showCreateArea}  setNewLots={setNewArea}  onClose={handleCloseCreateArea} modeEdit={isEditModeArea}/>
            <CreateRange filter={filter} visible={showCreateRange} setNewLots={setNewRange} onClose={handleCloseCreateRange} modeEdit={isEditModeRange}/>
            <thead className="HeadListUser-admin">
              <tr >
                    {datas.type && thead[datas.type].map((e, i)=>{
                    return(
                    <th key={i} scope="col" className={`col-listUser-adminPanel col${i+1}`} onClick={() => { handleSort(e.item) }} style={{ cursor: 'pointer' }}>    
                    {toggle==='AREA'&& e.item !== '' && <FilterHeader filterList={filterSelect} label={e.label} item={e.item} filterUser={filter} setFilteruser={setFilter} defaultChecked={filter.statusParam} noSwitch={true}/>}
                    {toggle!=='AREA'&& e.item !== '' && <span style={{ marginRight: '0.5rem' }} onClick={(e) => e.preventDefault()} >{e.label}</span>}
                    {toggle!=='AREA'&& e.item !== '' && FilterSvg}
                    </th>)                    
                  })}
              </tr>
            </thead>
            <tbody>
              <tr className='table-row-s'>
              
              </tr>
              {datas.content && datas.content.length === 0 &&
                <tr>
                  <td colSpan={8}>
                  <Empty 
                    description={
                      <span>
                        No se encontraron datos
                      </span>}
                     image={Empty.PRESENTED_IMAGE_SIMPLE} />                  
                  </td>
                </tr>}
                {datas.content && datas.content.map((developer, i) => {
                
                return (
                   <>
                      <tr key={i} className='table-row-body'>                        
                      {getBodyByType(developer)}
                      </tr>
                    <tr >
                      <td colSpan="7">
                        <Divider style={{margin: '2px'}}/>                        
                      </td>                      
                    </tr>                
                  </>
                  )
                })}

              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="7" align='right'>
                    <Pagination
                        current={datas.page +1}
                        onChange={(current, pageSize)=> setFilter({...filter, page: current -1, showRows: pageSize})}
                        defaultCurrent={1}
                        total={datas.totalElements}
                        showTotal={(total) => `Total ${total}`}
                        responsive
                        disabled={datas.totalElements >  datas.size ? false : true}
                        showSizeChanger={false}
                      />
                  </td>
                </tr>
              </tfoot>
            </table>
        </div>

        </>
    )
}
export default TableListConfigurations