import Request from './Request';

export default async function getFaqs(filter) {
   
    try {
        const response = await Request.get(`/faqs`, {...filter, ordering: 'createdAt,asc'});
        return response;
    } catch (error) {
        error && console.error(error);
        return error;
    }
    
  }