import { EyeOutlined } from '@ant-design/icons';
import React from 'react';
import './index.css';

function ViewFile({show, file, getFile}) {

  const handleShowFile = async (e) => {
    e.preventDefault();
    if(file){
        const url = URL.createObjectURL(file);
        window.open(url, '_blank');
    }
    if(getFile && !file){
        try {
            const url = await getFile();
            window.open(url, '_blank');
        } catch (err) {
            console.error(err)
        }
    }
  }

  return (<>
    {show ? <EyeOutlined onClick={handleShowFile} className='view-file' /> : null}
  </>)
}

export default ViewFile