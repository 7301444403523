import React, {useContext} from 'react';
import { ThemeContext } from '../../../context/ThemeContext';
import { Skeleton } from 'antd';
import { Row, Col } from 'react-bootstrap';

function SkeletonComponent() {

    const {themeColor} = useContext(ThemeContext);

    return (
        <Row className='profile-developer-banner' style={{backgroundColor:themeColor['background-color'],color:themeColor["color"]} }>
        <Col sm={6}>
            <Row style={{display: "flex", flexWrap: "nowrap"}}>
                <Col style={{marginRight: "5rem"}} className="img-col-container" sm={6}>
                    <Skeleton.Avatar active={true} size={140} shape="circle" />
                </Col>
                <Col sm={6}>

                    <Skeleton active={true} />

                </Col>
            </Row>
        </Col>
        <Col sm={6}>

            <Skeleton active={true} />

            <br />
            
        </Col>
    </Row>
    )
}

export default SkeletonComponent;
