import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ThemeContext } from '../../../context/ThemeContext';
import { Row, Col, Badge } from 'react-bootstrap';
import interestUseEnum from '../../../helpers/enums/interestUseEnum';
import './index.css';
import UserModelsEnum from '../../../helpers/enums/UserModelsEnum';
import shortText from '../../../helpers/shortText';
import getProfilePicture from '../../../services/getProfilePict';
import getProfileUser from '../../../services/getProfileUser';
import { Avatar, Button, Rate } from 'antd';
import {  UserOutlined } from '@ant-design/icons';

import ProfileBannerSkeleton from '../ProfileBannerSkeleton';
import { ChatContext } from '../../../context/ChatContext';
import { useAuth } from '../../../context/AuthContext';


const getDeveloperState = (state) => {

    const stateText = {
        'PENDING': 'Pendiente',
        'VALIDATION': 'En validación',
        'VERIFY': 'Verificada',
        'NO ACCEPT': 'No aceptada',
    };

    const stateBackground = {
        'PENDING': 'warning',
        'VALIDATION': 'info',
        'VERIFY': 'success',
        'NO ACCEPT': 'danger',
    };

    return {text: stateText[state], bg: stateBackground[state]}
}

const getBadgeWithInterest = (interest) => {

    if(!interestUseEnum[interest]) return null;

    return <Badge key={interest} text="dark" bg="light">{interestUseEnum[interest].name}</Badge>

  }


export default function ProfileBanner() {

  const [logo, setLogo] = useState('');

  const [broker, setBroker] = useState(null);

  const { userData } = useAuth();
  const { createAssociateChat, setBrokerAssociate, setDeveloperAssociate} = useContext(ChatContext);

  const {themeColor} = useContext(ThemeContext);

  const contactSvg = <svg style={{marginRight: "1rem"}} width="28" height="28" viewBox="0 0 28 28" fill="#60CAF7" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.33464 0.667969C1.86797 0.667969 0.681302 1.86797 0.681302 3.33464L0.667969 27.3346L6.0013 22.0013H24.668C26.1346 22.0013 27.3346 20.8013 27.3346 19.3346V3.33464C27.3346 1.86797 26.1346 0.667969 24.668 0.667969H3.33464ZM3.33464 3.33464H24.668V19.3346H4.89464L3.33464 20.8946V3.33464ZM16.668 14.0013H6.0013V16.668H16.668V14.0013ZM6.0013 10.0013H22.0013V12.668H6.0013V10.0013ZM22.0013 6.0013H6.0013V8.66797H22.0013V6.0013Z" fill="#60CAF7"/>
  </svg>;

  const params = useParams();

  const getBrokerDataById = async (id) => {
    const res = await getProfileUser(id);
    setBroker(res.data);
  }

  const setChat = () => {
    setBrokerAssociate(broker);
    setDeveloperAssociate(userData);
    createAssociateChat();
  }

  useEffect(() => {
      getBrokerDataById(params.id);
  }, [params.id]);

  useEffect(() => {

    if(broker){
        getProfilePicture(broker._id, broker.photo, UserModelsEnum.User).then((response) => {
            if(response) {
                setLogo(response);
            }
        });
    }

  }, [broker]);

  if(broker === null) return <ProfileBannerSkeleton />;


  return (
    <Row className='profile-developer-banner' style={{backgroundColor:themeColor['background-color'],color:themeColor["color"]} }>
    <Col sm={6}>
        <Row>
            <Col className="img-col-container" sm={6}>
                {/*<img className="rounded-circle img-fluid" src={logo || defaultLogo} alt={developer.names + " " + developer.lastNames} />*/}
                <Avatar icon={<UserOutlined />} src={logo} style={{  verticalAlign: 'middle', border: '3px solid #f0f0f0' }} size={140}/>
            </Col>
            <Col sm={12} md={6}>

                <h6 className="nowrap">{ shortText(broker.names + " " + broker.lastNames, 35) }</h6>

                <div className='brokerInfo_tel'>
                    <small><a href={`tel:${broker.phone}`} className="nowrap text-white">{ 'Tel: ' + broker.phone }</a></small>
                    <Rate style={{color: '#60CAF7'}} disabled defaultValue={0} />
                </div>

                {broker.website && (<>

                <br /><br />
                
                <strong>Sitio Web</strong>

                <br />

                <a className='text-white'>{broker.website}</a>
                </>)}

                <br />

                <a className='contactBtn' style={{width: '100%'}} onClick={setChat}>
                    {contactSvg}
                    <span style={{color: "#60CAF7", textDecoration:"underline"}} className='subtitle1 pl-5'>Contactar</span>
                </a>

            </Col>
        </Row>
    </Col>
    <Col className='broker_additional_info' sm={12} md={6}>
    
    {broker.description && <><strong className="w-100">Descripción e intereses</strong>
    <p style={{minHeight: '8rem'}}>{broker.description}</p>
    </>}
        
    <Button disabled={true} type="primary" className=''>Calificar Corredor</Button>

    </Col>
    </Row>
    )

}
