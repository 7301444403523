import React from 'react'
import './button.css'
export const ButtonApp = (props) => {
    if(props.primary){
        if(props.cancel){
            return(
                <button id={props.id} name={props.name} onDoubleClick={props.onDoubleClick} type={props.type} disabled={props.disabled} onClick={props.onClick} className={props.disabled ? 'disabled-btn btn-app-primary-cancel' : 'btn-app-primary-cancel'}>
                    {props.content || "Cancelar"}
                </button>
            )
        }
        return(
            <button id={props.id} name={props.name} onDoubleClick={props.onDoubleClick} type={props.type} disabled={props.disabled} onClick={props.onClick} className={props.disabled ? 'btn-app-primary disabled-btn' : 'btn-app-primary'}>
                {props.content || "Ok"}
            </button>
        )
    }
    if(props.cancel){
        return (
            <button id={props.id} name={props.name} onDoubleClick={props.onDoubleClick} type={props.type} disabled={props.disabled} onClick={props.onClick} className={props.disabled ? 'disabled-btn btn-app-default-cancel' : 'btn-app-default-cancel'}>
                {props.content || 'Cancelar'}
            </button>
        )
    }
    return (<button id={props.id} name={props.name} onDoubleClick={props.onDoubleClick} type={props.type} disabled={true} onClick={props.onClick} className={props.disabled ? 'btn-app-default disabled-btn' : 'btn-app-default'} >
        {props.content || 'Ok'}
    </button>)
}
