import React, { useState,useContext, useEffect } from "react";
import { Modal, message, Row, Col, Divider, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ButtonApp } from '../../../button/button';
import Location from "./Location/Location";
import LocationEdit from "./Location/LocationEdit";
import {Context} from '../../../../context/AreaContext'
import {senDataArea,ediDataArea} from './Location/createArea.service';

export const CreateArea = ({ visible, onClose,modeEdit })=>{
  
  const {
    parameters,
    sendActive,
    clearData,
    setArea,  
    setIsCloseModal,   
  } = useContext(Context);
  const [enableButton,setEnableButton] = useState(false)
  useEffect(() => {    
    setIsCloseModal(false);
     if(sendActive){
      setEnableButton(true);
     }else{
      setEnableButton(false)
     }
   },[sendActive]);
  
 const handleSubmitData = async ()=>{
  if(parameters.isValid){
    if(modeEdit){
      const response = await ediDataArea(parameters);
      if(response){
        message.success('Operacion realizada con exito');        
        setArea(
          {
            departmentParam: '', 
            cityParam:'',
            neighborhoodParam:'',    
            UPZParam:'',    
            locationParam:'',    
            communeParam:'', 
            point:[]
          }
        )
        clearData();
        onClose(true);
      }else{
        message.error('El área no pudo ser editada');
      }
    }else {
      const paramsOnlyWithValue = {}
      for(let key in parameters){
        if(parameters[key] && ((parameters[key].length && parameters[key].length > 0) || !parameters[key].length)){
          paramsOnlyWithValue[key] = parameters[key]
        }
      }
      const response = await senDataArea(paramsOnlyWithValue);
      if(response){
        message.success('Operacion realizada con exito');        
        setArea(
          {
            departmentParam: '', 
            cityParam:'',
            neighborhoodParam:'',    
            UPZParam:'',    
            locationParam:'',    
            communeParam:'', 
            point:[]
          }
          )
          clearData();
        onClose(true);
      }else{
        message.error('El área no pudo ser creado');
      }
    }     

  }else{    
    message.warning('Hay parametros invalidos en el formulario');
  }  
 }

 
  return (
    <>
    <Modal
      title="Crear área geográfica"
      width={950}
      style={{ top: 0, maxHeight: '', overflow: 'hidden', padding: 0 }}
      visible={visible}
      destroyOnClose
      closeIcon={
        <Tooltip title="Cerrar">
          <CloseOutlined 
            onClick={()=>{
              onClose(false)                            
            }} 
            style={{ fontZise: '20px', cursor: 'pointer' }}/>
        </Tooltip>
      }
      bodyStyle={{ height: 'calc(80vh - 55px)' }}
      footer={
        <>
          <div className='button-modal'>          
          <span style={{margin: '0px 8.5%'}}>          
            <ButtonApp
              name='Cancelar'
              onClick={()=>{
                setIsCloseModal(true);
                onClose(false)}}        
              content='Cancelar'
              danger
              cancel
            />
          </span>                                        
          <span style={{margin: '0px 8.5%'}}>          
            <ButtonApp
              name='Guardar'
              onClick={handleSubmitData}        
              content={modeEdit?'Editar área':'Crear área'}
              disabled={!enableButton}
              primary
            />
          </span>                                        
          </div>
        </>
      }
      >
        {modeEdit &&  <LocationEdit paramsEditables={parameters} /> }
        {!modeEdit && <Location /> }
      
    </Modal >
    </>
  )
}