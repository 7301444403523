import React from 'react';
import { Skeleton} from 'antd';

function SkeletonComponent() {
    return (
        <>
            {Array(9).fill([]).map((e, i) => {
                return (<div key={i} className="row my-3 w-90 mx-auto w-md-80">
                <div className="col-12 col-md-10 col-xl-11">
                    <Skeleton.Input size="large" block active />
                </div>
                <div className="col-0 d-none d-md-flex justify-content-center col-md-2 col-xl-1">
                    <Skeleton.Button size="large" shape="round" active />
                </div>
                </div>)
            })}

        </>
    )
}

export default SkeletonComponent;
