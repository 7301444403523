import React, {useState, useEffect, useCallback, useContext} from 'react';
import getFormatLocation from '../services/getFormatLocation';
import {Context} from '../context/lotsContext';

function useLocationSelector({setCoords, createType, defaultValues, defaultObservation}) {

    const {state: { dataEditLots }, dispatch} = useContext(Context);
    const [options, setOptions] = useState({
        department: [],
        city: [],
        location: [],
        commune: [],
        UPZ: [],
        neighborhood: []
    });
    
    const initialValue = {
        department: '',
        city: '',
        location: '',
        commune: '',
        UPZ: '',
        neighborhood: ''
    }

    const [selectedOptions, setSelectedOptions] = useState(defaultValues || initialValue);

    const [observations, setObservations] = useState(defaultObservation || '');

    const locationCompleteHandler = (neighborhood) => {
        if(createType === 'SET_NEW_REQUEST_LOT') return null;
        setCoords([neighborhood.points.map(item => [item.latitude, item.longitude])]);

        if(createType === 'SET_DATA_TO_UPDATE_LOT'){
            dispatch({type: createType, payload: {map: null}});
            dispatch({type: "SET_LOT_DATA_EDIT", payload: {map: null}});
        }

        if(createType !== 'SET_LOT_DATA'){
            dispatch({type: createType, payload: {location: { _id: neighborhood._id}, observations: observations}});
        } else if(neighborhood._id !== null){
            dispatch({type: createType, payload: {location: { _id: neighborhood._id}}});
        }        
      
    }

    const onChangeHandler = (e, name, edit) => {
        if(!edit){
            setCoords([])
            if(name === 'department') dispatch({type: 'SET_LOT_DATA_EDIT', payload: {map: null, location: {...dataEditLots.location, department: null, city: null, location: null, commune: null, UPZ: null, neighborhood: null}}});
            if(name === 'city') dispatch({type: 'SET_LOT_DATA_EDIT', payload: {map: null, location: {...dataEditLots.location, location: null, commune: null, UPZ: null, neighborhood: null}}});
            if(name === 'location') dispatch({type: 'SET_LOT_DATA_EDIT', payload: {map: null, location: {...dataEditLots.location, commune: null, UPZ: null, neighborhood: null}}});
            if(name === 'commune') dispatch({type: 'SET_LOT_DATA_EDIT', payload: {map: null, location: {...dataEditLots.location, UPZ: null, neighborhood: null}}});
            if(name === 'UPZ') dispatch({type: 'SET_LOT_DATA_EDIT', payload: {map: null, location: {...dataEditLots.location, neighborhood: null}}});
            
        }
        // When some data has changed, all the values below the actual
        // are set to their corresponding initialValue to mantain the sequence.
      
        const keys = Object.keys(initialValue);
        const thisNameIndex = keys.indexOf(name);
        const values = keys.map((key, i) => {
          if(i < thisNameIndex) return selectedOptions[key];
          if(i === thisNameIndex) return e;
          return '';
        });
      
        const valuesOptions = keys.map((key, i) => {
          if(i-1 < thisNameIndex) return options[key];
          return [];
        });
      
        const newValues = {};
        const newValuesOptions = {};
      
        keys.forEach((key, i) => {
          newValues[key] = values[i];
          newValuesOptions[key] = valuesOptions[i];
        });
        setSelectedOptions(newValues);
        setOptions(newValuesOptions);
      
      
        if(name === 'neighborhood' && e !== ''){
          const neighborhood = options.neighborhood.find(el => el.name === e);
          locationCompleteHandler(neighborhood);
        }
      
    }
    
    //You can pass the first param with the key you want to filter (for example: department)
    //The second argument is the value of that type (for example: Cundinamarca)
    //It will return the next array within that type of data (in this case a city array)
    const filterBy = (type, itemSelected) => {
    return options[type].find(item => item.name === itemSelected).data;
    }
  
    const getFormatLocationCallback = useCallback(async () => {
        const res = await getFormatLocation();
        setOptions({...options, department: res.data});
        if(defaultValues){
            for (const option in options) {
                setSelectedOptions({...selectedOptions, [option]:defaultValues[option]})
            }
            setSelectedOptions(defaultValues);

        }
    }, [setSelectedOptions, selectedOptions, defaultValues, getAllNeighboorhoods, setCoords]);
  
    useEffect(() => {
       
        getFormatLocationCallback();
    }, []);  
    useEffect(() => {
        const currentLocation = dataEditLots.location
        if(options.department.length > 0 && options.city.length === 0){
            let time = 1
       for (const key in currentLocation) {
        setTimeout(() => {
            if(options[key] !== undefined && key !== 'neighborhood'){
                onChangeHandler(currentLocation[key], key, true)
              }
        }, time);
            time++
          }   
        }
    }, [options.department]);  

    
    const getSubType = (type) => {
        const value = options[type].find(e => e.name === selectedOptions[type]);
        const subType = value !== undefined && value !==  'neighborhood' && value.data[0].type;
        return subType
    }

    const populateLowerValue = (type, subType, forced) => {
        if((selectedOptions[type] !== '' && selectedOptions[type] !== null && subType[0] !== undefined) || forced){
            const subTypeOptionValues = filterBy(type, selectedOptions[type]);
            if(subTypeOptionValues[0].name === '') return setOptions({ ...options, [subTypeOptionValues[0].data[0].type]: subTypeOptionValues[0].data});
            setOptions({...options, [subType]: subTypeOptionValues });
        }
    }
  
    /* This useEffect listen for every selectedOptions changed
    then, fill the data to the lower value for example, its a department
    was selected it fill the cities with all the cities of the department  */
  
    useEffect(() => {
        Object.keys(selectedOptions).forEach(type => {
        if(type === 'neighborhood') return; // No one its below neighborhood
        if(selectedOptions[type] === '' || selectedOptions[type] === null) return;

        const subType = getSubType(type);
        populateLowerValue(type, subType);
      
        });
    }, [selectedOptions]);
  
    useEffect(() => {
        if(createType !== 'SET_LOT_DATA' && createType !== 'SET_DATA_TO_UPDATE_LOT') dispatch({type: createType, payload: {observations}});
    }, [observations]);
    
    useEffect(() => {
        if(createType === 'SET_NEW_REQUEST_LOT') return;
        if(selectedOptions.neighborhood === '' && createType !== 'SET_DATA_TO_UPDATE_LOT') {
        setCoords([]);
        dispatch({type: createType, payload: {location: null}});
        }
    }, [selectedOptions.neighborhood]);


    // SET_NEW_REQUEST_LOT

    const getAllNeighboorhoods = (posibleOptionsData) => {

        return (posibleOptionsData.map(optionData => {
            if(optionData.data.length === 0) return optionData;
            return getAllNeighboorhoods(optionData.data);
        })).flat();

    }


    useEffect(() => {

        if(selectedOptions.city !== '' && selectedOptions.city !== null && createType === 'SET_NEW_REQUEST_LOT'){

            const currentSelectedOptionKeyList = Object.keys(selectedOptions).filter(key => selectedOptions[key] !== '' && selectedOptions[key] !== null);
            const currentSelectedOptionKey = currentSelectedOptionKeyList[currentSelectedOptionKeyList.length - 1];

            const currentSelectedOption = selectedOptions[currentSelectedOptionKey];
            const currentOption = options[currentSelectedOptionKey];

            const posibleOptions = currentOption.filter(area => area.name === currentSelectedOption);
            
            const allNeighborhoods = getAllNeighboorhoods(posibleOptions);

            const coords = allNeighborhoods.map( neighborhood => {
                return neighborhood.points.map(item => [item.latitude, item.longitude])
            });

            if(coords.length > 0) setCoords( [coords] );
        }

    }, [options, selectedOptions]);


    useEffect(() => {
        if(selectedOptions.city === '' || selectedOptions.city === null) {
            //dispatch({type: createType, payload: {location: null}});
            if(!dataEditLots.location || !dataEditLots.location._id) dispatch({type: createType, payload: {location: undefined}});
        }

        if(createType === 'SET_NEW_REQUEST_LOT' && selectedOptions.city !== '' && selectedOptions.city !== null){

            const locationEntries = Object.entries(selectedOptions).filter(([key, value]) => {
                return value !== '';
            });

            const location = Object.fromEntries(locationEntries);

            if(observations){
                dispatch({type: createType, payload: {location: location, observations: observations}});
            } else {
                dispatch({type: createType, payload: {location: location}});
            }
            

        }
    }, [selectedOptions, observations])


    return {options, selectedOptions, onChangeHandler, observations, setObservations}
}

export default useLocationSelector
