import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ThemeContext } from '../../../context/ThemeContext';
import { Row, Col, Badge } from 'react-bootstrap';
import interestUseEnum from '../../../helpers/enums/interestUseEnum';
import './index.css';
import UserModelsEnum from '../../../helpers/enums/UserModelsEnum';
import shortText from '../../../helpers/shortText';
import getProfilePicture from '../../../services/getProfilePict';
import getProfileUser from '../../../services/getProfileUser';
import { Avatar } from 'antd';
import {  UserOutlined } from '@ant-design/icons';

import ProfileBannerSkeleton from '../ProfileBannerSkeleton';


const getDeveloperState = (state) => {

    const stateText = {
        'PENDING': 'Pendiente',
        'VALIDATION': 'En validación',
        'VERIFY': 'Verificada',
        'NO ACCEPT': 'No aceptada',
    };

    const stateBackground = {
        'PENDING': 'warning',
        'VALIDATION': 'info',
        'VERIFY': 'success',
        'NO ACCEPT': 'danger',
    };

    return {text: stateText[state], bg: stateBackground[state]}
}

const getBadgeWithInterest = (interest) => {

    if(!interestUseEnum[interest]) return null;

    return <Badge key={interest} text="dark" bg="light">{interestUseEnum[interest].name}</Badge>

  }


export default function ProfileBanner() {

  const [logo, setLogo] = useState('');

  const [developer, setDeveloper] = useState(null);

  const {themeColor} = useContext(ThemeContext);

  const developerState = useMemo(() => {
      if(developer){
        return getDeveloperState(developer.associate.state);
      }
  }, [developer]);

  const params = useParams();

  const getDeveloperDataById = async (id) => {
    const res = await getProfileUser(id);
    console.log(res.data)
    setDeveloper(res.data);
    //const res = await getDeveloperUserById(id);
    //setDeveloper(res);
  }

  useEffect(() => {
      getDeveloperDataById(params.id);
  }, [params.id]);

  useEffect(() => {

    if(developer){
        getProfilePicture(developer.associate._id, developer.associate.logo, UserModelsEnum.Developer).then((response) => {
            if(response) {
                setLogo(response);
            }
        });
    }

  }, [developer]);

  if(developer === null) return <ProfileBannerSkeleton />;


  if(!developer.isDeveloper){
    return (
        <Row className='profile-developer-banner' style={{backgroundColor:themeColor['background-color'],color:themeColor["color"]} }>
        <Col sm={6}>
            <Row>
                <Col className="img-col-container" sm={6}>
                    {/*<img className="rounded-circle img-fluid" src={logo || defaultLogo} alt={developer.names + " " + developer.lastNames} />*/}
                    <Avatar icon={<UserOutlined />} src={logo} style={{  verticalAlign: 'middle', border: '3px solid #f0f0f0' }} size={140}/>
                </Col>
                <Col sm={6}>

                    <h6 className="nowrap">{ shortText(developer.names + " " + developer.lastNames, 35) }</h6>

                    <strong>Constructora</strong>

                    <p className="nowrap">{ shortText(developer.associate.type, 35) }</p>

                    {/*<Badge bg={developerState.bg}>{developerState.text}</Badge>*/}

                    <br /><br />

                    {/*<strong>Sitio Web</strong>

                        <p>{ developer.associate.webSite }</p>*/}

                </Col>
            </Row>
        </Col>
        <Col sm={6}>

        { developer.cities.length > 0 && (<>
        
        <strong className="w-100">Ciudades de interés</strong>

            <div className="badge-container">                

                { developer.cities.map(city => {
                    return <Badge key={city._id} text="dark" bg="light">{city._id}</Badge>
                }) }

            </div>

            <hr />

            {/*<strong className="w-100">Uso interés</strong>

            <div className="badge-container">

                { Object.keys(developer.associate.interestUse).map(interest => {
                    if(developer.associate.interestUse[interest]) return getBadgeWithInterest(interest);
                })}

            </div>*/}

            <br /></>)}
            
        </Col>
    </Row>
    )
    
  }

  return (
    <Row className='profile-developer-banner' style={{backgroundColor:themeColor['background-color'],color:themeColor["color"]} }>
        <Col sm={6}>
            <Row>
                <Col className="img-col-container" sm={6}>
                    {/*<img className="rounded-circle img-fluid" src={logo || defaultLogo} alt={developer.names + " " + developer.lastNames} />*/}
                    <Avatar icon={<UserOutlined />} src={logo} style={{  verticalAlign: 'middle', border: '3px solid #f0f0f0' }} size={140}/>
                </Col>
                <Col sm={6}>

                    <h6 className="nowrap">{ shortText(developer.names + " " + developer.lastNames, 35) }</h6>

                    <strong>Constructora</strong>

                    <p className="nowrap">{ shortText(developer.associate.type, 35) }</p>

                    <Badge bg={developerState.bg}>{developerState.text}</Badge>

                    <br /><br />

                    <strong>Sitio Web</strong>

                    <p>{ developer.associate.webSite }</p>

                </Col>
            </Row>
        </Col>
        <Col sm={6}>

            <strong className="w-100">Ciudades de interés</strong>

            <div className="badge-container">

                { developer.associate.interestCities.slice(0, 6).map(city => {
                    return <Badge key={city} text="dark" bg="light">{city}</Badge>
                }) }

            </div>

            <hr />

            <strong className="w-100">Uso interés</strong>

            <div className="badge-container">

                { Object.keys(developer.associate.interestUse).map(interest => {
                    if(developer.associate.interestUse[interest]) return getBadgeWithInterest(interest);
                })}

            </div>

            <br />
            
        </Col>
    </Row>
  )
}
