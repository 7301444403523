import React, {useContext, useState, useEffect, useRef} from 'react'
import {InfoCircleOutlined} from '@ant-design/icons'
import {Context} from '../../../../../../context/lotsContext'
import CustomInput from '../../../../../CustomInput'
import NumberFormat from "react-number-format";
import { Col, Divider, Pagination, Row, Select, Space, Switch, Tooltip } from 'antd';
import Request from '../../../../../../services/Request';
import HttpResponseEmun from '../../../../../../helpers/enums/HttpResponseEmun';
const { Option } = Select;

const GeneralInformation = ({next}) => {
  const {
    state: { newRequestLot, step, AdditionalData },
    dispatch,
  } = useContext(Context)
  const [lotData, setLotData] = useState({...newRequestLot})
  const [ranges, setRanges] = useState([])
  const [other, setOther] = useState(false)
 
  const onChange = (e) => {
    if(e.target.name === 'areaRange'){
      setLotData({...lotData, [e.target.name]: {_id: e.target.value}})
    }else if(e.target.name === 'interestUse'){
      setLotData({...lotData, [e.target.name]: {...lotData.interestUse, [e.target.fieldName]: e.target.value}})
    }else{
      setLotData({...lotData, [e.target.name]: e.target.value})
    }
  }
useEffect(() => {
  const getRanges = async()=>{
    const res = await Request.get('/ranges', {stateParam: 'ACTIVE', ordering: 'endRange,asc,startRange,asc'})
    if (res.status === HttpResponseEmun.OK) {
           setRanges(res.data.content)
    }
  }
getRanges()
}, [])

const verifyInterest = () => {
  let res = false;
  for (const property in lotData.interestUse) {
    if(lotData.interestUse[property] === true || (typeof lotData.interestUse[property] === 'string' && lotData.interestUse[property] !== ''))
    res = true
   
  }
  return res
}

useEffect(() => {
  if(
    lotData.areaRange !== null &&
    verifyInterest()){
      dispatch({type: 'SET_NEW_REQUEST_LOT', payload: lotData})
      next(step)
}else{
  next(step, 'delete')

}
}, [lotData])
const getValuesDinamicInputs = (e) => {
  dispatch({type: 'SET_ADDITIONAL_DATA', payload: e})
 }

  return (
    <div className='container-general-information'>
      <div className='container-fluid container-global'>
          
        <Row>
          <Col xs={{ span: 24}} md={{ span: 12, offset: 6}}>
                  <div className='form-row'>
                    <div className='form-group'>
                      <label className='tit-input'>
                        Área del lote (en metros cuadrados) m² 
                      <span className='text-danger'> *</span>
                      </label>
                      <Select
                        className='form-control form-info-corredor'
                        bordered={false}
                        loading={false}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        placeholder='Seleccione un rango de area'
                        style={{padding: '0.375rem 0.75rem', height: '39px'}}
                        onChange={(e)=>onChange({target:{name: 'areaRange', value: e,}})}
                       >
                       { ranges.map((item, i)=>{
                        return(
                          <Option key={i} value={item._id}>{`${item.startRange} - ${item.endRange} m²`}</Option>
                        )
                        })}
                    </Select>
                    </div>
                  </div>
                  <div className='form-row' >
                    <div className='form-group'>
                      <label className='tit-input'>
                      Usos de interés 
                      <span className='text-danger'> *</span>
                      </label>
                        <div className="container-checkbox">
                            <div className="cat">
                              <label>
                                  <input value={lotData.interestUse.vis} className='input-checkbox' onChange={(e)=>onChange({target:{fieldName: 'vis', name: 'interestUse', value: e.target.checked}})} type="checkbox" /><span>VIS</span>
                              </label>
                            </div>

                            <div className="cat">
                              <label>
                                  <input value={lotData.interestUse.noVis} className='input-checkbox' onChange={(e)=>onChange({target:{fieldName: 'noVis', name: 'interestUse', value: e.target.checked}})} type="checkbox"/><span>No Vis</span>
                              </label>
                            </div>

                            <div className="cat">
                              <label>
                                  <input value={lotData.interestUse.industrial} className='input-checkbox' onChange={(e)=>onChange({target:{fieldName: 'industrial', name: 'interestUse', value: e.target.checked}})} type="checkbox" /><span>Industrial</span>
                              </label>
                            </div>

                            <div className="cat">
                              <label>
                                  <input value={lotData.interestUse.dotacional} className='input-checkbox' onChange={(e)=>onChange({target:{fieldName: 'dotacional', name: 'interestUse', value: e.target.checked}})} type="checkbox"/><span>Dotacional</span>
                              </label>
                            </div>

                            <div className="cat">
                              <label>
                                  <input value={lotData.interestUse.commercial} className='input-checkbox' onChange={(e)=>onChange({target:{fieldName: 'commercial', name: 'interestUse', value: e.target.checked}})} type="checkbox"/><span>Comercial</span>
                              </label>
                            </div>

                            <div className="cat">
                                  <label>
                                      <input className={(lotData.interestUse.others !== null && lotData.interestUse.others !== '') ? `input-text-active` : 'input-text'} value={lotData.interestUse.others} onChange={(e)=>onChange({target:{fieldName: 'others', name: 'interestUse', value: e.target.value}})} placeholder='Otro' type="text" />
                                  </label>
                            </div>

                        </div>
                        {/* <!-- end container --> */}
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className='form-group' >
                      <label className='tit-input' style={{display:'block'}}>
                        Solicitud pública
                      </label>
                      <Switch defaultChecked={lotData.public} onChange={(e)=>onChange({target:{name: 'public', value: e,}})} />
                      <span > Quiero que esta solicitud sea pública y todos puedan verla</span>
                    </div>
                  </div>
                  
              </Col>
            {/*<Col xs={{ span: 24}} md={{ span: 11, offset: 1 }}>
              <div className= 'container-legal-information'>
                <CustomInput iconColor='gray'containerStyle={{margin: '33px 0 0 0'}} values={getValuesDinamicInputs} selectOptions={['file', 'email', 'number', 'text',  ]}/>
              </div>
            </Col>*/}
        </Row>

        <Row>
          <Col xs={{ span: 24}} md={{ span: 24}}>
          
              
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default GeneralInformation
