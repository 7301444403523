import Request from './Request';

export default async function getFormatLocation() {
   
    try {
        const response = await Request.get(`/geographical-area/format/of-location`);
        return response;       
        
    } catch (error) {
        error && console.error(error)
        return error
    }
    
  }