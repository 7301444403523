import React, {useEffect, useState} from "react"
import './Map.css'
import L from 'leaflet'
import puntero from './icon/puntero.png'
import { message } from "antd"

function isMarkerInsidePolygon(latlng, poly) {
  var inside = false;
  var x = latlng.lat, y = latlng.lng;
  for (var ii=0;ii<poly.getLatLngs().length;ii++){
      var polyPoints = poly.getLatLngs()[ii];
      for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
          var xi = polyPoints[i].lat, yi = polyPoints[i].lng;
          var xj = polyPoints[j].lat, yj = polyPoints[j].lng;

          var intersect = ((yi > y) != (yj > y))
              && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
          if (intersect) inside = !inside;
      }
  }
  return inside;
};
let polygon = null
export default function Maps ({LatLng, onChange, marker, isVisible, center}){
  const [coords, setCoords] = useState([])
  const [Map, setMap] = useState()

  useEffect(() => {
if(LatLng){
    let getCoords = LatLng.map((item) => {
    let polygonCoords = item.map(item => {
      const coords =[]
    for (const property in item) {
      coords.push(item[property]);
    }
      return coords
    } )
    return(polygonCoords)
    } )
    setCoords(getCoords)
    }
  }, [LatLng])

  useEffect(() => {
    if(center && (center.lat && center.lng)){
      Map.flyTo([center.lat, center.lng], 8)
    }
  }, [center])
  
useEffect(() => {
  polygon && polygon.remove()
  polygon = null
  if(Map && coords.length > 0){
    ///////
    polygon = L.polygon(coords, {color: 'red'}).addTo(Map);
    Map.fitBounds(polygon.getBounds());

   }
}, [coords])

useEffect(() => {
  
  let map = L.map('map-d', {
    center: marker || [4.60971, -74.08175],
    zoom: 5
  });
  L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 19,
    attribution: '© OpenStreetMap'
  }).addTo(map);
 
  setMap(map)
}, [])

useEffect(() => {
  let greenIcon = L.icon({
    iconUrl: puntero,
    iconSize:     [30, 45], // size of the icon
    iconAnchor:   [15, 45], // point of the icon which will correspond to marker's location
    popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
});
   if(isVisible && Map) {
  setTimeout(() => {
    L.marker(marker, {icon: greenIcon}).addTo(Map)
    .bindPopup(`Coordenadas ${marker}`)
    .openPopup();
    const closeButton = document.getElementsByClassName('leaflet-popup-close-button');
    if(closeButton.length > 0){
      L.DomEvent.on(closeButton[0], 'click', e => e.preventDefault());
    }
    }, 1000);
  }
}, [isVisible])


    return (
      <>
        <div id='map-d'></div>
      
      </>
      )
}




/* 

// Fiddle thanks to 
// Callbacks
function onMapClick(e) {
  var contained = polygon.contains(e.latlng);
  var message = contained ? "This is inside the polygon!" : "This is not inside the polygon.";
  popup
    .setLatLng(e.latlng)
    .setContent(message)
    .openOn(mymap);
}

function onMarkerClick(e) {
  var contained = polygon.contains(e.latlng);
  var message = contained ? "This marker is inside the polygon!" : "This marker is not inside the polygon.";
  popup
    .setLatLng(e.latlng)
    .setContent(message)
    .openOn(mymap);
}
// Setup
var mymap = L.map('mapid').setView([51.505, -0.09], 13);
L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/streets-v9/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiY29uZG9ydGhlZ3JlYXQiLCJhIjoiY2l6MXYwaDQyMDRneDMzcWZ4djRibWdiYiJ9.rbvqKXa9H0axkE3EAPSzgQ', {
  attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
  maxZoom: 18,
  id: 'mapid',
  accessToken: 'pk.eyJ1IjoiY29uZG9ydGhlZ3JlYXQiLCJhIjoiY2l6MXYwaDQyMDRneDMzcWZ4djRibWdiYiJ9.rbvqKXa9H0axkE3EAPSzgQ'
}).addTo(mymap);
var polygon = L.polygon([
  [51.51, -0.08],
  [51.503, -0.06],
  [51.51, -0.047]
]).addTo(mymap);
var popup = L.popup();
mymap.on('click', onMapClick);
L.marker([51.515, -0.07]).addTo(mymap).on('click', onMarkerClick);
L.marker([51.506, -0.06]).addTo(mymap).on('click', onMarkerClick);
L.marker([51.505, -0.074]).addTo(mymap).on('click', onMarkerClick); */