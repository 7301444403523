import React from 'react';
import PlusCircleOutlinedBlack from './plus-circle-outline-black.svg';
import PlusCircleOutlinedWhite from './plus-circle-outline-white.svg';
import PlusCircleOutlinedBlue from './plus-circle-outline-blue.svg';
import PlusCircleOutlinedRed from './plus-circle-outline-red.svg';
import PlusCircleOutlinedGray from './plus-circle-outline-gray.svg';

export default function PlusCircleOutlined({color, style}) {
  switch (color) {
    case 'black':
      return <img className='iconplus' alt='' style={style} src={PlusCircleOutlinedBlack}/>
    case 'white':
      return <img className='iconplus' alt='' style={style} src={PlusCircleOutlinedWhite}/>
    case 'blue':
      return <img className='iconplus' alt='' style={style} src={PlusCircleOutlinedBlue}/>
    case 'red':
      return <img className='iconplus' alt='' style={style} src={PlusCircleOutlinedRed}/>
    case 'gray':
      return <img className='iconplus' alt='' style={style} src={PlusCircleOutlinedGray}/>
    default:
      return <img className='iconplus' alt='' style={style} src={PlusCircleOutlinedBlack}/>
  }
}