import Request from '../services/Request';

export default async function updateRolPermissions (userId, roles) {
    try {
        const res = await Request.put(`/users/${userId}/assign-roles`, {roles});
        return res;
    } catch (error) {
        if(error) console.error(error);
        return error;
    }
}