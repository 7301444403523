import React from 'react'
import { useContext } from 'react'
import { ModalsContext } from '../context/ModalsContext'

export default function useModals() {
  const {
    policiesModal,
    openPoliciesModalAction,
    closePoliciesModalAction,
    confirmationMessage,
    openConfirmationMessageAction,
    closeConfirmationMessageAction,
  } = useContext(ModalsContext)

  const openConfirmationMessage = ({
    title = 'Titulo',
    message = 'Mensaje de confirmación',
    onConfirm = () => {},
    onCancel = () => {},
  }) => {
    openConfirmationMessageAction({ title, message, onConfirm, onCancel })
  }

  const closeConfirmationMessage = () => {
    closeConfirmationMessageAction()
  }

  const openPoliciesModal = ({
    onConfirm = () => {},
    onCancel = () => {},
  }) => {
    openPoliciesModalAction({ onConfirm, onCancel })
  }

  const closePoliciesModal = () => {
    closePoliciesModalAction()
  }

  return {
    policiesModal,
    confirmationMessage,
    openConfirmationMessage,
    closeConfirmationMessage,
    openPoliciesModal,
    closePoliciesModal,
  }
}
