import React, { useState, useEffect } from "react";
import {
  useLocation
} from "react-router-dom";

import AdminPanelSetting from "../../components/AdminPanelSetting/AdminPanelSetting";
import AdminPanelRequests from "../../components/AdminPanelRequests/AdminPanelRequests";
import AdminPanelConfigurations from "../../components/AdminPanelConfigurations/AdminPanelConfigurations";

import UserModelsEnum from "../../helpers/enums/UserModelsEnum";
import { useAuth } from "../../context/AuthContext";

import {useHistory} from  'react-router-dom'

import './adminPanel.css'

const AdminPanel = () => {
  const [verifyLocation, setVerifyLocation] = useState()
  const [verifyUser, setVerifyUser] = useState(false)
  const { user } = useAuth()
  const history = useHistory()
  let location = useLocation();
  useEffect(() => {
    setVerifyLocation(location.pathname)

    if(user && user.model === UserModelsEnum.ADMIN || user && user.model === UserModelsEnum.SUDO){
      setVerifyUser(true)
    }else{
      setVerifyUser(false)
      history.push('/app/profile')
    }
  }, [location]);
  
  return (
    <div className=" container-global">        
       {verifyLocation === '/app/admin' && verifyUser && <div className="container contPrincipalAdmin">
          <AdminPanelSetting />
        </div>}
       {verifyLocation === '/app/admin/requests' && verifyUser && <div className="container contPrincipalAdmin">
          <AdminPanelRequests />
        </div>}
       {verifyLocation === '/app/admin/setting' && verifyUser && <div className="container contPrincipalAdmin">
          <AdminPanelConfigurations />
        </div>}
    </div>
  );
};
export default AdminPanel;