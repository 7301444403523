import React, { useState, useEffect, useContext} from 'react';
import { Modal, Tooltip, Avatar, Col, Rate, Row } from 'antd';
import { UserOutlined, CloseOutlined } from '@ant-design/icons';
import getProfilePicture from '../../services/getProfilePict';
import './ProfileModal.css'
import {ProfileModalContext} from '../../context/ProfileModalContext';
import UserModelsEnum from '../../helpers/enums/UserModelsEnum';



const ProfileModal = () => {

  const [logo, setLogo] = useState('');

  const { profileData, show, setProfileData } = useContext(ProfileModalContext);

  useEffect(() => {
    
    if(profileData && profileData._id){
        const id = (profileData.user && profileData.user._id) || profileData._id;
        const filename = profileData.logo || profileData.user && profileData.user.logo || profileData.user && profileData.user.photo;
        const model = profileData.user ? UserModelsEnum.User : UserModelsEnum.Developer;

        getProfilePicture(id, filename, model).then((response) => {
        if(response) setLogo(response);
        });

    };
  }, [profileData]);

  const closeModal = () => {
    setProfileData({
        show: false,
        profileData: {}
    });
  }

  if(!profileData) return null;

  return (
    <>
    <Modal
        width={750}
        visible={show}
        destroyOnClose
        closeIcon={
        <Tooltip title="Cerrar" placement="bottom">
            <CloseOutlined onClick={closeModal} style={{ fontSize: '25px', color: '#39C0ED', width: '100%', height: '100%', display: 'block', paddingTop: '15px'  }}/>
        </Tooltip>
        }
        footer=''
        centered
        >

        <div className='container-details'>
            {/*<Row className='rows-lot' style={{alignItems: 'end'}}>
                <span className='subtitle1'>Perfil</span>
                <span className='subtitle2'>{profileData._id}</span>
            </Row>*/}
            <Row className='rows-lot mb-4'>
            <Avatar icon={<UserOutlined />} src={logo} size={90}/>
            <div className='rate-and-name'>
                <Rate disabled defaultValue={0} style={{color:'#2a97d1'}}/>
                <span className='subtitle1'>{profileData.contactPerson ? profileData.contactPerson : (
                    profileData.user && (profileData.user.names + " " + profileData.user.lastNames)
                )}</span>
            </div>
            </Row>
            <Row className='rows-lot row2'>
                <Col className='col' xs={{ span: 24}} md={{ span: 7, offset: 1 }}>
                <h6>Email</h6>
                <span>{profileData.email || profileData.alternativeEmail || (profileData.user && profileData.user.email) }</span>
                </Col>
                <Col className='col' xs={{ span: 24}} md={{ span: 7, offset: 1 }}>
                <h6>Tipo</h6>
                <span>{profileData.type}</span>
                </Col>
                { profileData.department && <Col className='col' xs={{ span: 24}} md={{ span: 7, offset: 1 }}>
                <h6>Departamento</h6>
                <span>{profileData.department}</span>
                </Col> }
                { profileData.user && profileData.user.phone && <Col className='col' xs={{ span: 24}} md={{ span: 7, offset: 1 }}>
                <h6>Teléfono</h6>
                <span>{profileData.user.phone}</span>
                </Col> }

                { profileData.city && <Col className='col' xs={{ span: 24}} md={{ span: 7, offset: 1 }}>
                <h6>Ciudad</h6>
                <span>{profileData.city}</span>
                </Col> }

                { profileData.address && <Col className='col' xs={{ span: 24}} md={{ span: 7, offset: 1 }}>
                <h6>Dirección</h6>
                <span>{profileData.address}</span>
                </Col> }

            </Row>
        </div>

    </Modal>
    </>
  )
}

export default ProfileModal;