import Request from './Request';

export const associateStates = {
    active: 'ACTIVO',
    inactive: 'INACTIVO',
    activeState: 'ACTIVE',
    inactiveState: 'INACTIVE'
}

export default async function getAllies(filter, id) {
   
    try {
        const response = await Request.get(`/allies`, { ...filter, associateType: id });
        return response;
        
    } catch (error) {
        error && console.error(error)
        return error
    }
    
}

export async function changeAssociateStatus(userId, endState = associateStates.inactiveState) {

    try {
        const response = await Request.put(`/users/${userId}/change-associates-status/${endState}`);
        return response;
    } catch (error) {
        error && console.error(error)
        return error
    }
    
}

export async function lockByAssociate(associateId) {
   
    try {
        const response = await Request.put(`/allies/locked/by-associate/${associateId}`);
        return response;
    } catch (error) {
        error && console.error(error)
        return error
    }
    
}