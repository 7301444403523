import React, { useState } from 'react'
import logo360 from '../../assets/logo.png'
import elipse from '../../assets/icons/ellipse1.png'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { unloggedUpdatePassword } from '../../services/users'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import verContra from '../../assets/icons/visualizar.png'
import NoverContra from '../../assets/icons/novisualizar.png'

import useToasts from '../../hooks/useToasts'

import './index.css'

const UnloggedUpdatePassword = () => {
  const { addToast } = useToasts()
  const { token } = useParams()
  const history = useHistory()

  const onSubmit = async (formData) => {
    try {
      if (await unloggedUpdatePassword(formData.password, token)) {
        addToast({
          type: 'success',
          title: 'Hecho',
          message: 'Su contraseña ha sido actualizada',
        })
        history.push('/auth/login')
      } else {
        addToast({
          type: 'error',
          title: 'Upss !!',
          message: 'Ocurrió un error',
        })
      }
    } catch (error) {
      console.error(error)
      addToast({
        type: 'error',
        title: 'Upss !!',
        message: 'Ocurrió un error',
      })
    }
  }

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('La contraseña es requerida')
      .matches(
        /^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Contraseña No válida'
      ),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
      .required('Es necesario que confirme la contraseña'),
  })

  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: '',
    },

    onSubmit,

    validationSchema,
  })

  var correoError = ''
  var correoErrorTiene = ''
  if (formik.errors.email) {
    correoError = 'form-control style--inputs--login-Error icoInput1'
    correoErrorTiene = 'YES'
  } else {
    correoError = 'form-control style--inputs--login icoInput1'
    correoErrorTiene = ''
  }

  var contraError = ''
  var contraErrorTiene = ''
  var btnver = ''
  let Acc = {
    estado: 'Inicial',
  }
  if (formik.errors.password) {
    Acc.estado = 'no correcto'
    contraError =
      'form-control style--inputs--login-Error icoInput2 inputVerContra'
    btnver = 'imgVisualizarPSW style--inputs--login-Error'
  } else {
    contraError = 'form-control style--inputs--login icoInput2 inputVerContra'
    btnver = 'imgVisualizarPSW style--inputs--login'
    if (formik.values.password) {
      Acc.estado = 'Correcto'
    } else {
      Acc.estado = 'Inicial'
    }
  }
  if (correoErrorTiene == 'YES') {
    contraErrorTiene = ''
  } else {
    if (formik.values.password) {
      contraErrorTiene = 'YES'
    }
  }

  var repeatPError = ''
  var repeatPErrorTiene = ''
  var vercontra = ''
  if (formik.errors.repeatPassword) {
    repeatPError =
      'form-control style--inputs--login-Error icoInput2 inputVerContra'
    repeatPErrorTiene = 'YES'
    vercontra = 'imgVisualizarPSW style--inputs--login-Error'
  } else {
    repeatPError = 'form-control style--inputs--login icoInput2 inputVerContra'
    repeatPErrorTiene = ''
    vercontra = 'imgVisualizarPSW style--inputs--login'
  }

  const [estadoVisualizar, setEstadoVisualizar] = useState(false)
  const [estadoVisualizarRepiteContra, setEstadoVisualizarRepiteContra] =
    useState(false)

  return (
    <div className='unlogged-update-password'>
      <div className='container-fluid main-container'>
        <Row>
          <Col>
            <Link to='/auth/login'>
              <img
                src={logo360}
                className='mt-5 ms-4 mt-lg-5 ms-lg-5 ps-lg-5'
                alt='logo'
              />
            </Link>
            <div className='style--image--circle position-absolute top-0 end-0 text-end'>
              <img src={elipse} className='img-fluid' alt='logo' />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
            <div className='container'>
              <h1 className='style--title--login'>¡Bienvenido!</h1>
              <form>
                <div>
                  <div className='container--texts--login'>
                    <label className='subtitle--login form-label'>
                      *Contraseña
                    </label>
                  </div>
                  <div className='input-group mb-2 pruebasinputpsw'>
                    <input
                      name='password'
                      type={estadoVisualizar == false ? 'password' : 'text'}
                      className={contraError}
                      placeholder='********'
                      onChange={formik.handleChange}
                    />
                    <label className={btnver}>
                      <img
                        src={
                          estadoVisualizar == false ? NoverContra : verContra
                        }
                        alt='Ver Constraseña'
                        className='imgVerContra'
                        onClick={() => setEstadoVisualizar(!estadoVisualizar)}
                      />
                    </label>
                  </div>

                  {contraErrorTiene ? (
                    <div className='form-label Mensaje-Error'>
                      {formik.errors.password}
                    </div>
                  ) : null}

                  <div
                    className={
                      Acc.estado == 'Inicial'
                        ? 'form-label Mensaje-Error2'
                        : Acc.estado == 'Correcto'
                        ? 'form-label Mensaje-Acc'
                        : 'form-label Mensaje-Error'
                    }
                  >
                    Tu contraseña debe tener mínimo 8 carácteres
                  </div>
                  <div
                    className={
                      Acc.estado == 'Inicial'
                        ? 'form-label Mensaje-Error2'
                        : Acc.estado == 'Correcto'
                        ? 'form-label Mensaje-Acc'
                        : 'form-label Mensaje-Error'
                    }
                  >
                    Tu contraseña debe tener mínimo 1 Mayúscula
                  </div>
                  <div
                    className={
                      Acc.estado == 'Inicial'
                        ? 'form-label Mensaje-Error2'
                        : Acc.estado == 'Correcto'
                        ? 'form-label Mensaje-Acc'
                        : 'form-label Mensaje-Error'
                    }
                  >
                    Tu contraseña debe tener mínimo 1 carácter especial
                  </div>

                  <div className='container--texts--login'>
                    <label className='subtitle--login form-label'>
                      *Repite contraseña
                    </label>
                  </div>
                  <div className='input-group mb-2 pruebasinputpsw'>
                    <input
                      name='repeatPassword'
                      type={
                        estadoVisualizarRepiteContra == false
                          ? 'password'
                          : 'text'
                      }
                      className={repeatPError}
                      placeholder='********'
                      onChange={formik.handleChange}
                    />
                    <label className={vercontra}>
                      <img
                        src={
                          estadoVisualizarRepiteContra == false
                            ? NoverContra
                            : verContra
                        }
                        alt='Ver Constraseña'
                        className='imgVerContra'
                        onClick={() =>
                          setEstadoVisualizarRepiteContra(
                            !estadoVisualizarRepiteContra
                          )
                        }
                      />
                    </label>
                  </div>
                  {repeatPErrorTiene ? (
                    <div className='form-label Mensaje-Error'>
                      {formik.errors.repeatPassword}
                    </div>
                  ) : null}
                </div>
                <br />
                <div className='input-group mb-3'>
                  <button
                    className={
                      formik.isValid && formik.values.password
                        ? 'button--register-Active'
                        : 'button--register disabled-link'
                    }
                    type='submit'
                    onClick={formik.handleSubmit}
                  >
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default UnloggedUpdatePassword
