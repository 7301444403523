import React, { useContext, useState,useEffect  } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import BrightnessMediumIcon from '@material-ui/icons/BrightnessMedium';
import Chip from '@material-ui/core/Chip';
import Badge from '@material-ui/core/Badge'
import UserModelsEnum from '../../helpers/enums/UserModelsEnum'
import DeveloperStatesEnum from '../../helpers/enums/DeveloperStatesEnum'
import logo from '../../assets/logo.png'
import logoBlack from '../../assets/logo-black.png'
import { 
  ArrowUpOutlined, 
  SettingOutlined,
  ControlOutlined, 
  ContainerOutlined, 
  UserOutlined, 
  TeamOutlined,
  SearchOutlined, 
  MessageOutlined, 
  BellOutlined,
  WhatsAppOutlined,
  MailOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import './index.css'
import { Divider } from 'antd'
import ThemingDialog from '../ThemingDialog/ThemingDialog';
import { ThemeContext } from '../../context/ThemeContext';
import { SocketContext } from '../../context/socketIO';
import Notifications from '../Notifications/Notifications';
import IconButton from '@material-ui/core/IconButton';
import { Context } from '../../context/lotsContext';
import { RequestsAvailableContext } from '../../context/RequestsAvailableContext'
import AssociateDrawer from '../AssociateDrawer/AssociateDrawer';
import AssociateBrokerDrawer from '../AssociateBrokerDrawer/AssociateBrokerDrawer';
import SupportModal from '../SupportModal/SupportModal';
import Faq from '../FAQ';
import { ChatContext } from '../../context/ChatContext';

export default function LayoutHeader() {
  const { user, userData, logout } = useAuth();    
  const [menuOpen, setMenuOpen] = useState(false);  
  const [openDialog, setOpenDialog] = React.useState(false);  
  const [verifyLocation,setVerifyLocation]= useState('');
  const [openNotification,setOpenNotification]= useState(false);  
  const {themeColor} = useContext(ThemeContext)    
  const {socket,notificationCount,newNotification,connect,isConnected,viewUserStatus} = useContext(SocketContext);
  const [currentStyles,setCurrentStyle] = useState(false)
  const [totalUnreadNotifications,setTotalUnreadNotifications] = useState(0);
  const [notiToUpdate, setNotiToUpdate] = useState(0);
  const [showAssociateDrawer, setShowAssociateDrawer] = useState(false);
  const [showAssociateBrokerDrawer, setShowAssociateBrokerDrawer] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [showFaq, setShowFaq] = useState(false);

  const history = useHistory();

  const {
    state: {associateDrawerData},
    dispatch
  } = useContext(Context);

  const {requestsAvailable} = useContext(RequestsAvailableContext);

  const {currentChat, goChat, setGoChat} = useContext(ChatContext)
  
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  let location = useLocation();

  useEffect (()=>{       
    setVerifyLocation(location.pathname);
  },[location]) 

  useEffect(() => {

    const routeView = location.pathname;
    const userId = user && user.id;
    const chatId = currentChat && currentChat.data && currentChat.data._id;
    const chatRoute = '/app/chat';

    const message = ({routeView, userId, chatId: routeView === chatRoute ? (chatId || null) : null});

    if(socket) {
      viewUserStatus(message.userId, message.routeView, message.chatId);
    }

  }, [location, user, currentChat, isConnected, socket])

  useEffect (()=>{     
      if(localStorage.getItem('user')){
        if(!socket){
          if(connect()){
            newNotification(()=>{})
          }
        }else{
          notificationCount( (value) => {
            setTotalUnreadNotifications(prev => {
              if(value > prev) setNotiToUpdate(prev => prev + 1);
              return value
            });     
               
          } )
        }      
      }        

      
  },[socket]) 

  
  const handleChangeDefaultTheme = () =>{ 
    setCurrentStyle(false)
  }          
  const handleChangeCustomTheme = () =>{ 
    setCurrentStyle(true)
  }          
  const handleNotification = () =>{    
    setOpenNotification(true)
  }

  const closeAssociateDrawer = () => {
    dispatch({type: 'RESET_ASSOCIATE_DRAWER'});
  }

  const openAssociateModal = () => {
    dispatch({type: 'SET_ASSOCIATE_DRAWER', payload: {landId: null, show: true}});
  }

  const handleLogout = () => {
    logout()
    dispatch({ type: 'RESET'})
  }

  useEffect(() => {
    if(goChat){
      history.push('/app/chat')
      setGoChat(false);
    }
  }, [goChat])
  
  return (
    <>
    <AssociateDrawer show={associateDrawerData && associateDrawerData.show} closeDrawer={closeAssociateDrawer} />
    <AssociateBrokerDrawer show={showAssociateBrokerDrawer} closeDrawer={() => setShowAssociateBrokerDrawer(false)} />
    <SupportModal show={showSupportModal} handleClose={() => setShowSupportModal(false)} />
    <div className='layout-header' style={
      currentStyles?
      {
        backgroundColor:themeColor["background-color"],
        color:themeColor["color"]
      }:
      {
        backgroundColor:"#272a30",
        color:"#fff"
      }      
    }>
      <div className='d-flex w-100 justify-content-center align-items-center px-lg-5 py-4'>
          { user && user.model === UserModelsEnum.SUDO 
            ?
            <Link to="/app/admin/setting" className="link--navBar">
              <img src ={ currentStyles.color =="#fff"?logoBlack:logo} className='ms-3 ps-3 ms-sm-5 ps-sm-5 logo' alt="Urban 360"/>
            </Link>
            : user && user.model === UserModelsEnum.ADMIN
            ?
            <Link to="/app/admin/requests" className="link--navBar">
              <img src ={ currentStyles.color =="#fff"?logoBlack:logo} className='ms-3 ps-3 ms-sm-5 ps-sm-5 logo' alt="Urban 360"/>
            </Link>
            :
            <Link to={user && userData && user.model === UserModelsEnum.Developer && userData.state === DeveloperStatesEnum.Validation ? "/app/profile" : "/app/home"} className="link--navBar">
              <img src ={ currentStyles.color =="#fff"?logoBlack:logo} className='ms-3 ps-3 ms-sm-5 ps-sm-5 logo' alt="Urban 360"/>
            </Link>
          }
                
            
        <div className='ms-auto'>
          {user && user.model === UserModelsEnum.Broker ? (
            userData && userData.status === 1 ? (
              <>
                <Tooltip title="Buscar">
                <Link to='/app/search' className="link--header" onClick={handleChangeDefaultTheme} >
                <SearchOutlined style={{fontSize: '22px', color:currentStyles?themeColor['color']:'#fff'}}/>
                </Link>
              </Tooltip>
              <Tooltip title="Aliados">
                <TeamOutlined onClick={openAssociateModal}
                style={{fontSize: '22px', color:currentStyles?themeColor['color']:'#fff', cursor: 'pointer'}}/>
              </Tooltip>
              <Tooltip title="Perfil">
                <Link to='/app/profile' className="link--header" onClick={handleChangeCustomTheme} >
                <UserOutlined style={{fontSize: '22px',   color:currentStyles?themeColor['color']:'#fff'}}/>
                </Link>
              </Tooltip>
              <Tooltip title="Mensajes">
                <Link to='/app/chat' className="link--header" onClick={handleChangeDefaultTheme}>
                <MessageOutlined style={{fontSize: '22px', color:currentStyles?themeColor['color']:'#fff'}}/>
                </Link>
              </Tooltip>
              <Tooltip title="Notificaciones">
                  <IconButton  className="notification--header" onClick={handleNotification}>
                    <Badge badgeContent={totalUnreadNotifications} color='secondary'>
                        <BellOutlined style={{fontSize: '22px', color:currentStyles?themeColor['color']:'#fff'}}/>
                    </Badge>
                  </IconButton >
              </Tooltip>
            </>
            ) : null
          ) : null}
          {user && user.model === UserModelsEnum.Developer ? (
            userData && userData.state === DeveloperStatesEnum.Verified ? (
              <>
                <Tooltip title="Buscar">
                <Link to='/app/search' className="link--header" onClick={handleChangeDefaultTheme}>
                <SearchOutlined style={{fontSize: '22px', color:currentStyles?themeColor['color']:'#fff'}}/>
                </Link>
              </Tooltip>
              <Tooltip title="Corredores asociados">
                <TeamOutlined onClick={() => setShowAssociateBrokerDrawer(true)}
                style={{fontSize: '22px', color:currentStyles?themeColor['color']:'#fff', cursor: 'pointer'}}/>
              </Tooltip>
              <Tooltip title="Perfil">
                <Link to='/app/profile' className="link--header" onClick={handleChangeCustomTheme} >
                <UserOutlined style={{fontSize: '22px', color:currentStyles?themeColor['color']:'#fff'}}/>
                </Link>
              </Tooltip> 
              <Tooltip title="Mensajes">
                <Link to='/app/chat' className="link--header" onClick={handleChangeDefaultTheme} >
                <MessageOutlined style={{fontSize: '22px', color:currentStyles?themeColor['color']:'#fff'}}/>
                </Link>
              </Tooltip>
              <Tooltip title="Notificaciones">
                  <IconButton  className="notification--header" onClick={handleNotification}>
                    <Badge badgeContent={totalUnreadNotifications} color='secondary'>
                        <BellOutlined style={{fontSize: '22px', color:currentStyles?themeColor['color']:'#fff'}}/>
                    </Badge>
                  </IconButton >
              </Tooltip>
              </>
            ) : null
          ) : null}
          
          {user && user.model === UserModelsEnum.SUDO || user && user.model === UserModelsEnum.ADMIN ? (
              <>
              <Tooltip title="Solicitudes">
                <Link to='/app/admin/requests' className="link--header" onClick={handleChangeDefaultTheme}>
                  <Badge color="error" badgeContent={requestsAvailable} invisible={!requestsAvailable}>
                    <ContainerOutlined style={{fontSize: '22px', color:currentStyles?themeColor['color']:'#fff'}}/>
                  </Badge>
                </Link>
              </Tooltip>
             

              <Tooltip title="Administracion">
                <Link to='/app/admin' className="link--header" onClick={handleChangeDefaultTheme}>
                <ControlOutlined style={{fontSize: '22px', color:currentStyles?themeColor['color']:'#fff'}}/>
                </Link>
              </Tooltip>

              <Tooltip title="Configuración">
                <Link to='/app/admin/setting' className="link--header" onClick={handleChangeDefaultTheme}>
                <SettingOutlined style={{fontSize: '22px', color:currentStyles?themeColor['color']:'#fff'}}/>
                </Link>
              </Tooltip>

              <Divider style={{background: themeColor['color'], height: '30px'}} type="vertical" />
              <Tooltip title="Perfil">
                <Link to='/app/profile' className="link--header" onClick={handleChangeCustomTheme}>
                <UserOutlined style={{fontSize: '22px', color:currentStyles?themeColor['color']:'#fff'}}/>
                </Link>
              </Tooltip>
              <Tooltip title="Notificaciones">
                  <IconButton  className="notification--header" onClick={handleNotification}>
                    <Badge badgeContent={totalUnreadNotifications} color='secondary'>
                        <BellOutlined style={{fontSize: '22px', color:currentStyles?themeColor['color']:'#fff'}}/>
                    </Badge>
                  </IconButton >
              </Tooltip>

              </>
            ) : null}
          
        </div>

        {showFaq ? <Faq show={showFaq} onClose={() => setShowFaq(false)} /> : null}

        <div
          className='profile-button'
          role='button'
          onClick={() => {
            if(!showFaq) setMenuOpen(!menuOpen)
          }}
        >
          {userData &&
            userData.names[0].toUpperCase() +
              userData.lastNames[0].toUpperCase()}

          <div className={`profile-menu ${!menuOpen ? 'hidden' : ''}`}>
            <div className='profile-menu-info'>
              <div className='circle'>
                {userData &&
                  userData.names[0].toUpperCase() +
                    userData.lastNames[0].toUpperCase()}
              </div>
              <div className='name'>
                {userData && userData.names + ' ' + userData.lastNames}
              </div>
             
            </div>
            <div className='role'>
                <Chip color="primary" label={user.model} />
              </div>
            {user && user.model === UserModelsEnum.SUDO || user && user.model === UserModelsEnum.ADMIN ? 
            <>
            <div className='btn-responsive'>
              <Link to='/app/admin/requests' className="link--header">
                  <Badge color="error" badgeContent={requestsAvailable} invisible={!requestsAvailable}>
                    <ContainerOutlined className='icon-header-responsive'/>
                  </Badge>
                  <span>Solicitudes</span>
              </Link>
            </div>
           
            <div className='btn-responsive'>
              <Link to='/app/admin' className="link--header">
                  <ControlOutlined className='icon-header-responsive'/>
                  <span>Administracion</span>
              </Link>
            </div>
            <div className='btn-responsive'>
              <Link to='/app/admin/setting' className="link--header">
                  <SettingOutlined className='icon-header-responsive'/>
                  <span>Configuración</span>
              </Link>
            </div>
            </>: null}

            {(user && (user.model !== UserModelsEnum.ADMIN && user.model !== UserModelsEnum.SUDO)) && (
              <div className='btn-responsive'>
                <Link to='/app/search' className="link--header" onClick={handleChangeDefaultTheme}>
                  <SearchOutlined className='icon-header-responsive'/>
                  <span>Buscar</span>
                </Link>
              </div>)}

              {(user && (user.model === UserModelsEnum.Developer)) && (
              <div className='btn-responsive' onClick={() => setShowAssociateBrokerDrawer(true)}>
                <span className="link--header" onClick={handleChangeDefaultTheme}>
                  <TeamOutlined className='icon-header-responsive'/>
                  <span>Corredores asociados</span>
                </span>
              </div>)}

              {(user && (user.model === UserModelsEnum.Broker)) && (
              <div className='btn-responsive' onClick={openAssociateModal}>
                <span className="link--header" onClick={handleChangeDefaultTheme}>
                  <TeamOutlined className='icon-header-responsive'/>
                  <span>Aliados</span>
                </span>
              </div>)}

            <div className='btn-responsive'  >
              <Link to='/app/profile' className="link--header" onClick={handleChangeCustomTheme}>
              <UserOutlined className='icon-header-responsive'/>
                    <span>Perfil</span>
              </Link>
            </div>

            {(user && (user.model !== UserModelsEnum.ADMIN && user.model !== UserModelsEnum.SUDO)) && (
              <div className='btn-responsive'>
              <Link to='/app/chat' className="link--header" onClick={handleChangeDefaultTheme}>
              <MessageOutlined className='icon-header-responsive'/>
                    <span>Mensajes</span>
                </Link>
            </div>)}

            <div className='btn-responsive'>
              <span className="link--header" onClick={handleNotification}>
              <BellOutlined className='icon-header-responsive'/>
                    <span>Notificaciones</span>
              </span>
            </div>
            
            {
                              
                <div className='profile-menu-section'>
                <Button className='profile-menu-item' onClick={handleClickOpen}>
                <BrightnessMediumIcon className='icon-header-responsive'/>
                  <span>Tema</span>
                </Button>
                </div>
              
            }

            {
              <div className='profile-menu-section'>
              <Button className='profile-menu-item' onClick={() => setShowFaq(true)}>
              <QuestionCircleOutlined style={{fontSize: '20px'}} className='icon-header-responsive'/>
                <span>FAQ</span>
                </Button>
              </div>
            }

            {
                              
                <div className='profile-menu-section'>
                <a target="_blank" href={`https://api.whatsapp.com/send?phone=${window.encodeURIComponent(process.env.REACT_APP_WHATSAPP)}&text=Deseo%20tener%20soporte%20o%20asesoría%20en%20URBAN-360&type=phone_number&app_absent=0`} className='profile-menu-item'>
                <WhatsAppOutlined style={{fontSize: '20px'}} className='icon-header-responsive'/>
                  <span>Whatsapp</span>
                </a>
                </div>
              
            }

            {(user && (user.model === UserModelsEnum.Developer || user.model === UserModelsEnum.Broker)) && (
              <div className='profile-menu-section'>
              <Button className='profile-menu-item' onClick={() => setShowSupportModal(true)}>
              <MailOutlined style={{fontSize: '20px'}} className='icon-header-responsive'/>
                <span>Correo de soporte</span>
                </Button>
              </div>
            )}
            

            <div className='profile-menu-section'>
              <Button className='profile-menu-item' onClick={handleLogout}>
              <ArrowUpOutlined style={{fontSize: '20px'}} className='icon-header-responsive' rotate={235}/>
                <span>Cerrar sesión</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ThemingDialog
        openDialog={openDialog}
        handleClose={handleClose}
      />
      <Notifications notiToUpdate={notiToUpdate} isOpen={openNotification} isClose={setOpenNotification} toggleParam={openNotification} />
    </div>
    </>
  )
}
