import React, { useEffect, useState, useRef,useContext } from 'react'
import { SolutionOutlined, EditTwoTone, MinusOutlined, UserSwitchOutlined, FileSearchOutlined, UserOutlined, DeleteOutlined, PoweroffOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { Divider, message, Pagination, Popconfirm, Tooltip, Empty, Modal, Input } from 'antd';
import DeleteIcon from '../../../assets/icons/delete.png'
import { CreateUser } from '../CrearDrawer/Create';
import Details from '../DetailsDrawer/Details';
import Request from '../../../services/Request';
import { Filter } from '../../AdminPanelFilter/AdminPanelFilter';
import { EditUser } from '../EditDrawer/Edit';
import UserDetails from '../UserDetails/UserDetails';
import {RolDrawer} from '../rolDrawer/create';
import {PermissionDrawer} from '../permissionDrawer/create';
import { ButtonApp } from '../../button/button';
import { ThemeContext } from '../../../context/ThemeContext';
import sort from '../../handleSort';
import Skeleton from '../Skeleton/Skeleton';
import RolPermissionsModal from '../RolPermissionsModal/RolPermissionsModal';
import UserRolModal from '../UserRolModal/UserRolModal';
import UserPermissionsModal from '../UserPermissionsModal/UserPermissionsModal';

import PermissionsCategoriesEnum from '../../../helpers/enums/PermissionCategoriesEnum';

//Small components
import CreateButtonRow from '../CreateButtonRow/CreateButtonRow';
import shortText from '../../../helpers/shortText';

const tHeaders = {
users: [
  {item:'names', label:'Nombre'},
  {item:'email', label:'Correo'},
  {item:'address', label:'Dirección'},
  {item:'phone', label:'Teléfono'},
  {item:'type', label:'Tipo'},
  {item:'', label:''},
],
developers: [
  {item:'nit', label:'NIT'},
  {item:'businessName', label:'Razón Social'},
  {item:'type', label:'Tipo'},
  {item:'address', label:'Dirección'},
  {item:'department', label:'Departamento'},
  {item:'city', label:'Ciudad'},
  {item:'', label:''},
],
brokers: [
  {item:'names', label:'Nombre'},
  {item:'email', label:'Correo'},
  {item:'phone', label:'Teléfono'},
  {item:'address', label:'Dirección'},
  {item:'type', label:'Tipo'},
  {item:'', label:''},
],
rol: [
  {item:'registrationNumber', label:'Código'},
  {item:'name', label:'Nombre'},
  {item:'description', label:'Descripción'},
  {item:'', label:''},
],
permissions: [
  {item:'code', label:'Código'},
  {item:'name', label:'Nombre'},
  {item:'description', label:'Descripción'},
  {item:'category', label:'Categoría'},
  {item:'', label:''},
],
system: [
  {item:'parameter', label:'Parametro'},
  {item:'description', label:'Descripción'},
  {item:'value', label:'Valor'},
  {item:'', label:''},
]}


const TableList = ({datas, setDatas, filter, setFilter, refreshData}) => {
  const {themeColor} = useContext(ThemeContext)
    const [newUser, setNewUser] = useState({ })
    const [details, setDetails] = useState({ })
    
    const [showRolPermissionsModal, setShowRolPermissionsModal] = useState({rol: null, visible: false});
    const [showUserPermissionsModal, setShowUserPermissionsModal] = useState({user: null, visible: false});
    const [showUserRolModal, setShowUserRolModal] = useState({user: null, visible: false});

    const [screenSize, setScreenSize] = useState(document.documentElement.clientWidth)
    const [showModalSistem, setShowModalSistem] = useState({data: {description: '', parameter: '', value: ''}, visible: false})
    const [showDrawer, setShowDrawer] = useState({
        drawerCreate: false,
        drawerEdit: false,
        drawerDetails: false,
        drawerUsers: false,
    });
    const [showRolDrawer, setShowRolDrawer] = useState({
      visible: false,
      rolToEdit: null,
    });

    const [showPermissionDrawer, setShowPermissionDrawer] = useState({
      visible: false,
      permissionToEdit: null,
    });

    const [currentsort, setCurrentSort] = useState('top');

    const [actionLoading, setActionLoading] = useState({delete: ""});


    const FilterSvg = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filter-left" viewBox="0 0 16 16">
        <path d="M2 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
    </svg>
    const handleSort = (item) =>{
      setFilter({...filter, ordering: sort(item), sortLoading: true});
    }

    const closeRolPermissionsModal = () => {
      setShowRolPermissionsModal({rol: null, visible: false});
    }

    const closeUserRolModal = () => {
      setShowUserRolModal({user: null, visible: false});
    }

    const closeUserPermissionsModal = () => {
      setShowUserPermissionsModal({user: null, visible: false});
    }

    
    const handleShowDrawerEdit = (e) => {
        e === 'edit' && setShowDrawer({ ...showDrawer, drawerEdit: true });
        e === 'details' && setShowDrawer({ ...showDrawer, drawerDetails: true });
        e === 'create' && setShowDrawer({ ...showDrawer, drawerCreate: true });
        e === 'users' && setShowDrawer({ ...showDrawer, drawerUsers: true });
    }
    const handleCLoseDrawerCreate = () => {
        setShowDrawer({ ...showDrawer, drawerCreate: false });
    }
    const handleCLoseDrawerEdit = () => {
        setShowDrawer({ ...showDrawer, drawerEdit: false });
    }
    const handleCLoseDrawerDetails = () => {
      setDetails({})
        setShowDrawer({ ...showDrawer, drawerDetails: false });
    }
    const handleCLoseDrawerUsers = () => {
      setDetails({})
        setShowDrawer({ ...showDrawer, drawerUsers: false });
    }
    const handleCLoseModalSystem = () => {
      setShowModalSistem({data: {parameter: '', value: '', description: ''}, visible: false})
    }

    const handleCloseRolDrawer = () => {
      setShowRolDrawer({visible: false, rolToEdit: null});
    }

    const handleClosePermissionDrawer = () => {
      setShowPermissionDrawer({visible: false, permissionToEdit: null});
    }

  const handleStatus = (data, type) => {
    if(type === 'users'){
      let status = data.status === 1 ? 0 : 1
        Request.put(`/users/${data._id}`, {status: status})
            .then(res => {
                setDatas({...datas, content: datas.content.filter(user => user._id !== data._id)})
                message.success({ content: 'Solicitud Exitosa!', duration: 3 });
            }).catch(err => {
                console.error(err)
                message.error({content: 'Ocurrio un proble!', duration: 4});
            }
            ) 
          }
    if(type === 'developers'){
      let status = data.admin.status === 1 ? 'INACTIVE' : 'ACTIVE'
        Request.put(`/developers/${data._id}/change-status/users/${status}`)
            .then(res => {
                setDatas({...datas, content: datas.content.filter(developer => developer._id !== data._id)})
                message.success({ content: 'Solicitud Exitosa!', duration: 3 });
            }).catch(err => {
                console.error(err)
                message.error({content: 'Ocurrio un proble!', duration: 4});
            }
            ) 
          }
    if(type === 'brokers'){
      let status = data.status === 1 ? 0 : 1
        Request.put(`/users/${data._id}`, {status: status})
            .then(res => {
                setDatas({...datas, content: datas.content.filter(broker => broker.user._id !== data._id)})
                message.success({ content: 'Solicitud Exitosa!', duration: 3 });
            }).catch(err => {
                console.error(err)
                message.error({content: 'Ocurrio un proble!', duration: 4});
            }
            ) 
          }

      if(type === "rol"){
        setActionLoading({delete: data._id});
        const state = data.state === "0" ? "ACTIVE" : "INACTIVE";
        const action = state === "ACTIVE" ? "habilitado" : "inhabilitado";
        const actionForErrorMsg = state === "ACTIVE" ? "habilitar" : "inhabilitar";

        Request.put(`/roles/${data._id}`, {state}).then(res => {
          setDatas({...datas, content: datas.content.filter(rol => rol._id !== data._id)});
          message.success({content:`El rol ${data.code} ha sido ${action}`, duration: 3});
          setActionLoading({delete: ""});
        }).catch((err) => {
          console.error(err);
          message.error({content: `Error inesperado al ${actionForErrorMsg} el rol ${data.code}`, duration: 4});
          setActionLoading({delete: ""});
        })
      }



      if(type === "permissions"){
        setActionLoading({delete: data._id});
        const action = "eliminado";
        const actionForErrorMsg = "eliminar";

        Request.delete(`/permissions/${data._id}`).then(res => {
          setDatas({...datas, content: datas.content.filter(rol => rol._id !== data._id)});
          message.success({content:`El permiso ${data.code} ha sido ${action}`, duration: 3});
          setActionLoading({delete: ""});
        }).catch((err) => {
          console.error(err);
          message.error({content: `Error inesperado al ${actionForErrorMsg} el permiso ${data.code}`, duration: 4});
          setActionLoading({delete: ""});
        })
      }
    } 

    
const handleUpdateParameter = () => {
  const {parameter, value, _id } = showModalSistem.data
  if(value === ''){
    message.warning('Introduce un Valor')
  }else{
    Request.put(`/system-parameters/${parameter}`, {value: value})
    .then(res => {
      setDatas({...datas, content: datas.content.map(param => param._id === _id ? {...res.data} : param)})
      handleCLoseModalSystem()
      message.success({ content: 'Solicitud Exitosa!', duration: 3 });
    }).catch(err => {
      console.error(err)
      message.error({content: 'Ocurrio un proble!', duration: 4});
    }
    ) 
  }
}

window.addEventListener('resize', ()=> setScreenSize(document.documentElement.clientWidth));

return (
        <>
            {datas.type !== 'system' && <Filter noSwitch={datas.type === 'permissions'} filterUser={filter} setFilteruser={setFilter} defaultChecked={filter.statusParam || filter.stateParam} />}
            <UserDetails developer={details} visibleUsers={showDrawer.drawerUsers} onCloseUsers={handleCLoseDrawerUsers} screenSize={screenSize}/>
            <CreateUser filter={filter} datas={datas} setDatas={setDatas} visible={showDrawer.drawerCreate} setNewUser={setNewUser} onClose={handleCLoseDrawerCreate} />
            <Details filter={filter} setDatas={setDatas} datas={datas} data={details} type={datas.type} visibleEdit={showDrawer.drawerDetails} onCloseEdit={handleCLoseDrawerDetails} />
            <EditUser user={details} datas={datas} setDatas={setDatas} visibleEdit={showDrawer.drawerEdit} onCloseEdit={handleCLoseDrawerEdit} />
            <RolDrawer refreshData={refreshData} visible={showRolDrawer.visible} rol={showRolDrawer.rolToEdit} onClose={handleCloseRolDrawer} />
            <PermissionDrawer refreshData={refreshData} visible={showPermissionDrawer.visible} permission={showPermissionDrawer.permissionToEdit} onClose={handleClosePermissionDrawer} />
            {/* //////////////////////////// */}

            {Object.keys(datas).length === 0 && <Skeleton />}

         {Object.keys(datas).length !== 0 && <div >   
            <table >
            <thead className="HeadListUser-admin" >
              <tr >
                {datas.type && tHeaders[datas.type].map((e, i)=>{
                    if(e.item !== ''){
                return(
                 <th key={i} scope="col" className={`col-listUser-adminPanel col${i+1}`} onClick={() => { handleSort(e.item) }} style={{ cursor: 'pointer' }}>
                <span style={{ marginRight: '0.5rem' }} >{e.label}</span>
                {FilterSvg}
                </th>)
                }else{
                    return(
                        <th key={i} scope="col" className={`col-listUser-adminPanel col7`} > 
                
                        </th>
                    )
                }

                })}
                
              </tr>
              </thead>
              <tbody>
              {(datas.type !== 'users' && datas.type !== 'rol' && datas.type !== 'permissions') && 
                <tr className='table-row-s'>
                
              </tr>}

            {/* Creations buttons */}

            {datas.type === 'users' && 
              <CreateButtonRow title="Crear administrador" onClick={()=>handleShowDrawerEdit('create')} />
            } 

            {datas.type === 'rol' && 
              <CreateButtonRow title="Crear rol" onClick={()=>setShowRolDrawer({visible: true, rolToEdit: null})} />
            } 

            {datas.type === 'permissions' && 
              <CreateButtonRow title="Crear permiso" onClick={()=>setShowPermissionDrawer({visible: true, permissionToEdit: null})} />
            }

              {datas.content && datas.content.length === 0 &&
              <tr>
                <td colSpan={8}>
                <Empty 
                  description={
                    <span>
                      No se encontraron datos
                    </span>}
                    image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  
                </td>
              </tr>}


              {datas.content && datas.type === 'users' && datas.content.length > 0 && datas.content.map((data, i) => {
                return (
                  <>
              <tr key={i} className='table-row-body'>
                <td className='col-listUser-adminPanel col1' scope="row">{data.names} {data.lastNames}</td>
                <td className='col-listUser-adminPanel col2' scope="row">{screenSize < 450 ? shortText(data.email, 15) : data.email}</td>
                <td className='col-listUser-adminPanel col3' scope="row">{shortText(data.address, 20)}</td>
                <td className='col-listUser-adminPanel col4' scope="row">{data.phone}</td>
                <td className='col-listUser-adminPanel col5' scope="row">{data.type}</td>
                <td className='col-listUser-adminPanel col7' scope="row" style={screenSize < 450 ? {flexDirection: 'column', display: 'flex'} : {textAlign: "right"}}>
                    <span hidden={screenSize < 450}>
                    <MinusOutlined style={{ fontSize: '1.5rem', color: 'gray', transform: 'rotate(90deg)' }} />
                    </span>
                    <span style={{ cursor: 'pointer', margin: '0 5px' }} >
                        <Tooltip placement="top" title='Editar'>
                            <EditTwoTone onClick={() => {
                                handleShowDrawerEdit('edit')
                                setDetails(data)
                                }} style={{ fontSize: '1.5rem' }} />
                        </Tooltip>
                    </span>

                    <span style={{margin: '0 5px'}}>
                      <Tooltip placement="top" title='Roles'>
                        <SolutionOutlined onClick={()=> setShowUserRolModal({user: data, visible: true})} style={{ cursor: 'pointer', fontSize: '1.5rem', color: '#212529' }} />
                      </Tooltip>
                    </span>

                    <span style={{margin: '0 5px'}}>
                      <Tooltip placement="top" title='Permisos'>
                        <SafetyCertificateOutlined onClick={()=> setShowUserPermissionsModal({user: data, visible: true})} style={{ cursor: 'pointer', fontSize: '1.5rem', color: '#00B74A' }} />
                      </Tooltip>
                    </span>

                    {filter && filter.statusParam === 'ACTIVE' && 
                    <span style={{margin: '0 5px'}}>
                        <Popconfirm
                        title={`¿Estas seguro que quieres eliminar este usuario?`}
                        onConfirm={()=> handleStatus(data, datas.type)}
                        okText="Si"
                        cancelText="No"
                        >
                        <Tooltip placement="top" title='Eliminar'>
                        <DeleteOutlined style={{ cursor: 'pointer', fontSize: '1.5rem', color: '#F93154' }} />
                        </Tooltip>
                        </Popconfirm>
                    </span> }
                    {filter && filter.statusParam === 'INACTIVE' &&
                      <span style={{margin: '0 5px'}}>
                        <Tooltip placement="top" title='Activar'>
                          <UserSwitchOutlined onClick={()=> handleStatus(data, datas.type)} style={{ cursor: 'pointer', fontSize: '1.5rem', color: '#00B74A' }} />
                        </Tooltip>
                    </span> }

                </td>
              </tr>
              <tr >
                  <td colSpan="7">
                    <Divider style={{margin: '2px'}}/>
                  </td>
              </tr>
                </>
                )})}
                
                
                {datas.content && datas.type === 'developers' && datas.content.length > 0 && datas.content.map((data, i) => {
                
                return (
                    <>
                <tr key={i} className='table-row-body'>
                  <td className='col-listUser-adminPanel col1' scope="row">{screenSize < 450 ? shortText(data.nit, 8) : data.nit}</td>
                  <td className='col-listUser-adminPanel col2' scope="row">{screenSize < 450 ? shortText(data.businessName.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase()), 8) : data.businessName.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())}</td>
                  <td className='col-listUser-adminPanel col3' scope="row">{data.type}</td>
                  <td className='col-listUser-adminPanel col4' scope="row">{shortText(data.address, 20).toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())}</td>
                  <td className='col-listUser-adminPanel col5' scope="row">{data.department}</td>
                  <td className='col-listUser-adminPanel col6' scope="row">{data.city.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())}</td>
                  <td className='col-listUser-adminPanel col7' scope="row" style={screenSize < 450 ? {flexDirection: 'column', display: 'flex'} : {}}>
                    <span hidden={screenSize < 450} className='divider'><MinusOutlined style={{ fontSize: '1.5rem', color: 'gray', transform: 'rotate(90deg)' }} /></span>
                    <span style={{ cursor: 'pointer', margin: '0 5px' }} >
                      <Tooltip placement="top" title='Detalles'>
                          <FileSearchOutlined  onClick={() => {
                            handleShowDrawerEdit('details')
                            setDetails(data)
                          }} style={{ fontSize: '1.5rem' }} />
                      </Tooltip>
                    </span>
                    <span style={{ margin: '0 5px' }} >
                      <Popconfirm
                        title={`¿Estas seguro que quieres ${filter && filter.statusParam === 'ACTIVE' ? 'Desactivar' : 'Activar'} este usuario?`}
                        onConfirm={()=> handleStatus(data, datas.type)}
                        okText="Si"
                        cancelText="No"
                        >
                        <Tooltip placement="top" title={filter && filter.statusParam === 'ACTIVE' ? 'Desactivar' : 'Activar'}>
                        <PoweroffOutlined style={{ cursor: 'pointer', fontSize: '1.5rem', color: filter && filter.statusParam === 'ACTIVE' ? '#FF9100' : '#00B74A'}} />
                        </Tooltip>
                        </Popconfirm>
                    </span>
                    <span style={{ margin: '0 5px' }} >
                      <Tooltip placement='top' title='Usuarios'>
                        <UserOutlined onClick={()=>{
                          handleShowDrawerEdit('users')
                          setDetails(data)
                                                   }} style={{ cursor: 'pointer', fontSize: '1.5rem', color: '#237DF0'}} />
                      </Tooltip>
                      </span>
                
                  </td>
                </tr>
                <tr >
                    <td colSpan="7">
                      <Divider style={{margin: '2px'}}/>
                    </td>
                </tr>
                
                  </>
                  )})}
                  
                  {datas.content &&  datas.type === 'brokers' && datas.content.length > 0 && datas.content.map((data, i) => {
                            /* ///BROKERS */
                            return (
                                <>
                            <tr key={i} className='table-row-body'>
                              <td className='col-listUser-adminPanel col1' scope="row">{data.user.names} {data.lastNames}</td>
                              <td className='col-listUser-adminPanel col2' scope="row">{screenSize < 450 ? shortText(data.user.email, 15) : data.user.email}</td>
                              <td className='col-listUser-adminPanel col3' scope="row">{ data.user.phone}</td>
                              <td className='col-listUser-adminPanel col4' scope="row">{shortText(data.user.address, 20)}</td>
                              <td className='col-listUser-adminPanel col5' scope="row">{data.user.type}</td>
                              <td className='col-listUser-adminPanel col7' scope="row" style={screenSize < 450 ? {flexDirection: 'column', display: 'flex'} : {}}>
                                <span hidden={screenSize < 450} className='divider'><MinusOutlined style={{ fontSize: '1.5rem', color: 'gray', transform: 'rotate(90deg)' }} /></span>
                                <span style={{ cursor: 'pointer' }} >
                                  <Tooltip placement="top" title='Detalles'>
                                      <FileSearchOutlined  onClick={() => {
                                        handleShowDrawerEdit('details')
                                        setDetails(data)
                                      }} style={{ fontSize: '1.5rem' }} />
                                  </Tooltip>
                                </span>
                                {filter && filter.statusParam === 'ACTIVE' && 
                                <span style={{margin: '0 5px'}}>
                                    <Popconfirm
                                    title={`¿Estas seguro que quieres eliminar este usuario?`}
                                    onConfirm={()=> handleStatus(data.user, datas.type)}
                                    okText="Si"
                                    cancelText="No"
                                    >
                                    <Tooltip placement="top" title='Eliminar'>
                                    <DeleteOutlined style={{ cursor: 'pointer', fontSize: '1.5rem', color: '#F93154' }} />
                                    </Tooltip>
                                    </Popconfirm>
                                </span> }
                                {filter && filter.statusParam === 'INACTIVE' &&
                                  <span style={{margin: '0 5px'}}>
                                    <Tooltip placement="top" title='Activar'>
                                      <UserSwitchOutlined onClick={()=> handleStatus(data.user, datas.type)} style={{ cursor: 'pointer', fontSize: '1.5rem', color: '#00B74A' }} />
                                    </Tooltip>
                                </span> }
                                
                              </td>
                            </tr>
                            <tr >
                                <td colSpan="7">
                                  <Divider style={{margin: '2px'}}/>
                                </td>
                            </tr>
                              </>
                                )})}
                  {datas.type === 'rol' && datas.content.length > 0 && datas.content.map((data, i) => {
                            /* ///ROL */
                            return (
                                <>
                            <tr key={i} className='table-row-body'>
                              <td className='col-listUser-adminPanel col1' scope="row">{data.code}</td>
                              <td className='col-listUser-adminPanel col2' scope="row">{screenSize < 450 ? shortText(data.name, 15) : data.name}</td>
                              <td className='col-listUser-adminPanel col3' scope="row">{shortText(data.description, 20)}</td>
                              <td  style={screenSize < 450 ? {flexDirection: 'column', display: 'flex'} : {textAlign: "right", display: "flex", justifyContent: "flex-end", margin: "0"}} className='col-listUser-adminPanel col7' scope="row">
                              <span hidden={screenSize < 450}>
                                <MinusOutlined style={{ fontSize: '1.5rem', color: 'gray', transform: 'rotate(90deg)' }} />
                                </span>
                                <span style={{ cursor: 'pointer', margin: '0 5px' }} >
                                    <Tooltip placement="top" title='Editar'>
                                        <EditTwoTone onClick={() => {
                                            setShowRolDrawer({visible: true, rolToEdit: data});
                                            }} style={{ fontSize: '1.5rem' }} />
                                    </Tooltip>
                                </span>

                                <span style={{margin: '0 5px'}}>
                                    <Tooltip placement="top" title='Permisos'>
                                      <SafetyCertificateOutlined onClick={()=> setShowRolPermissionsModal({rol: data, visible: true})} style={{ cursor: 'pointer', fontSize: '1.5rem', color: '#00B74A' }} />
                                    </Tooltip>
                                </span>
                                
                                {filter && filter.stateParam === 'ACTIVE' && 
                                <span style={{margin: '0 5px'}}>
                                    <Popconfirm
                                    title={`¿Estas seguro que quieres eliminar este rol?`}
                                    onConfirm={()=> actionLoading.delete !== data._id && handleStatus(data, datas.type)}
                                    okText="Si"
                                    cancelText="No"
                                    >
                                    <Tooltip placement="top" title='Eliminar'>
                                    <DeleteOutlined style={{ cursor: 'pointer', fontSize: '1.5rem', color: actionLoading.delete !== data._id ? '#F93154' : "rgba(0,0,0,.5)" }} />
                                    </Tooltip>
                                    </Popconfirm>
                                </span> }

                                {filter && filter.stateParam === 'INACTIVE' &&
                                  <span style={{margin: '0 5px'}}>
                                    <Tooltip placement="top" title='Activar'>
                                      <PoweroffOutlined onClick={()=> handleStatus(data, datas.type)} style={{ cursor: 'pointer', fontSize: '1.5rem', color: '#00B74A' }} />
                                    </Tooltip>
                                </span> }
                              </td>
                            </tr>
                            <tr >
                                <td colSpan="7">
                                  <Divider style={{margin: '2px'}}/>
                                </td>
                            <Details data={data} visibleEdit={showDrawer.drawerEdit} onCloseEdit={handleCLoseDrawerEdit} />
                            </tr>
                              </>
                                )})}
                  {datas.type === 'permissions' && datas.content.length > 0 && datas.content.map((data, i) => {
                            return (
                              <>
                          <tr key={i} className='table-row-body'>
                            <td className='col-listUser-adminPanel col1' scope="row">{data.code}</td>
                            <td className='col-listUser-adminPanel col2' scope="row">{screenSize < 450 ? shortText(data.name, 15) : data.name}</td>
                            <td className='col-listUser-adminPanel col3' scope="row">{shortText(data.description, 25)}</td>
                            <td className='col-listUser-adminPanel col4' scope="row">{PermissionsCategoriesEnum[data.category]}</td>
                            <td style={screenSize < 450 ? {flexDirection: 'column', display: 'flex'} : {textAlign: "right", display: "flex", justifyContent: "flex-end", margin: "0"}} className='col-listUser-adminPanel col7' scope="row">
                            <span hidden={screenSize < 450}>
                              <MinusOutlined style={{ fontSize: '1.5rem', color: 'gray', transform: 'rotate(90deg)' }} />
                              </span>
                              <span style={{ cursor: 'pointer', margin: '0 5px' }} >
                                  <Tooltip placement="top" title='Editar'>
                                      <EditTwoTone onClick={() => {
                                          setShowPermissionDrawer({visible: true, permissionToEdit: data})
                                          //handleShowDrawerEdit('edit-rol')
                                          //setDetails(data)
                                          }} style={{ fontSize: '1.5rem' }} />
                                  </Tooltip>
                              </span>
                              <span style={{margin: '0 5px'}}>
                                  <Popconfirm
                                  title={`¿Estas seguro que quieres eliminar este permiso?`}
                                  onConfirm={()=> actionLoading.delete !== data._id && handleStatus(data, datas.type)}
                                  okText="Si"
                                  cancelText="No"
                                  >
                                  <Tooltip placement="top" title='Eliminar'>
                                  <DeleteOutlined style={{ cursor: 'pointer', fontSize: '1.5rem', color: actionLoading.delete !== data._id ? '#F93154' : "rgba(0,0,0,.5)" }} />
                                  </Tooltip>
                                  </Popconfirm>
                              </span>
                            </td>
                          </tr>
                          <tr >
                              <td colSpan="7">
                                <Divider style={{margin: '2px'}}/>
                              </td>
                          <Details data={data} visibleEdit={showDrawer.drawerEdit} onCloseEdit={handleCLoseDrawerEdit} />
                          </tr>
                            </>
                              )})}
                  {datas.type === 'system' && datas.content.length > 0 && datas.content.map((data, i) => {
                            return (
                          <React.Fragment key={i}>
                            <tr className='table-row-body'>
                              <td className='col-listUser-adminPanel col1' scope="row">{screenSize < 450 ? shortText(data.parameter, 12) : data.parameter}</td>
                              <td className='col-listUser-adminPanel col2' scope="row">{screenSize < 450 ? shortText(data.description, 15) : shortText(data.description, 30)}</td>
                              <td className='col-listUser-adminPanel col3' scope="row">{data.value.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())}</td>
                              <td className='col-listUser-adminPanel col7' scope="row">
                                <span className='divider'><MinusOutlined style={{ fontSize: '1.5rem', color: 'gray', transform: 'rotate(90deg)' }} /></span>
                                <span style={{ cursor: 'pointer', margin: '0 5px' }} >
                                  <Tooltip placement="top" title='Editar'>
                                      <EditTwoTone onClick={() => {
                                          setShowModalSistem({data: data, visible: true})
                                          }} style={{ fontSize: '1.5rem' }} />
                                  </Tooltip>
                              </span>
                              </td>
                            </tr>
                            <tr >
                                <td colSpan="7">
                                  <Divider style={{margin: '2px'}}/>
                                </td>
                            </tr>
                              </React.Fragment>
                                )})}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="7" align='right'>
                    <Pagination
                        current={datas.page +1}
                        onChange={(current, pageSize)=> setFilter({...filter, page: current -1, showRows: pageSize})}
                        defaultCurrent={1}
                        total={datas.totalElements}
                        showTotal={(total) => `Total ${total}`}
                        responsive
                        disabled={datas.totalElements >  datas.size ? false : true}
                        showSizeChanger={false}
                      />
                  </td>
                </tr>
                <tr >
                    <td colSpan="7">
                      <Divider style={{marginBottom: '15px'}}/>
                    </td>
                </tr>
              </tfoot>
            </table>
        </div>}



            <RolPermissionsModal showRolPermissionsModal={showRolPermissionsModal} 
            closeRolPermissionsModal={closeRolPermissionsModal} />

            <UserRolModal showUserRolModal={showUserRolModal} 
            closeUserRolModal={closeUserRolModal} />

            <UserPermissionsModal showUserPermissionsModal={showUserPermissionsModal} 
            closeUserPermissionsModal={closeUserPermissionsModal} />

            {/* //////////////////////////// */}
            <Modal 
              title={`Modificar ${showModalSistem.data.parameter}`} 
              visible={showModalSistem.visible} 
              onCancel={handleCLoseModalSystem}
              footer={
                <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <span style={{margin: '0 3px'}}>
                    <ButtonApp primary onClick={handleUpdateParameter}/>
                  </span>
                  <span style={{margin: '0 3px'}}>
                    <ButtonApp primary cancel onClick={handleCLoseModalSystem}/>
                  </span>
                </div>
              }
              >
              <span><b>Descripción:</b> {showModalSistem.data.description} </span>
              <Input value={showModalSistem.data.value} placeholder="Valor del parametro" allowClear onChange={(e)=> setShowModalSistem({...showModalSistem, data: {...showModalSistem.data, value: e.target.value}})} />
            </Modal>
        </>
    )
}
export default TableList