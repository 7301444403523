import { Formik } from 'formik'
import * as Yup from 'yup';
import React, {useState, useEffect} from 'react'
import { Drawer, message, Popover, Select } from 'antd';
import { ButtonApp } from '../../button/button';
import Request from '../../../services/Request';
import Spinner from '../../../assets/spinners/spinner.svg'
import HttpResponseEmun from '../../../helpers/enums/HttpResponseEmun';
import UserModelsEnum from '../../../helpers/enums/UserModelsEnum';


const { Option } = Select;

const iconclose = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-x-lg" viewBox="0 0 16 16">
    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
</svg>

export const EditUser = ({ visibleEdit, onCloseEdit, user, filter, setDatas, datas}) => {
    const [showSpinner, setShowSpinner] = useState(false);
    const [sliderWidth, setSliderWidth] = useState("550px");
    const initialValues = { ...user, repeatPassword: user.password }

    const validationSchema = Yup.object().shape({
        names: Yup.string()
            .min(3, 'Debe tener al menos 3 caracteres')
            .required('Este campo es requerido'),
        lastNames: Yup.string()
            .min(3, 'Debe tener al menos 3 caracteres')
            .required('Este campo es requerido'),
        email: Yup.string()
            .email('Debes escribir un correo valido')
            .required('Este campo es requerido'),
        password: Yup.string()
            .matches(/^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Contraseña No válida"),
        repeatPassword: Yup.string()
            .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden"),
        address: Yup.string()
            .required('Este campo es requerido'),
        phone: Yup.number()
            .required('Este campo es requerido'),
        type: Yup.string()
            .required('Este campo es requerido'),
    })


    const onSubmit = (e) => {
        setShowSpinner(true)
        if(e.email === initialValues.email) delete e.email
        Request.put(`/users/${e._id}`,{...e})
        .then(res => { 
            if(res.status === HttpResponseEmun.OK){
                onCloseEdit()
                Request.get('/users', { ...filter, typeParam: 'SUDO,ADMIN'})
                    .then(response => {
                        if (response.status === HttpResponseEmun.OK) {
                            setDatas({...datas, ...response.data.data})
                            setShowSpinner(false)
                        }
                    })
                    .catch(err => { 
                        setShowSpinner(false)
                        message.error('Error al cargar los usuarios'); 
                    })
                message.success('Se ha editado el usuario con exito');
            }
         })
         .catch(err => { 
            setShowSpinner(false)
            message.error('Ha ocurrido un error'); })
    }

    const contentPopoverPassword = (
        <div>
            <p style={{ color: '#fff' }}>La contraseña debe tener mínimo 8 carácteres</p>
            <p style={{ color: '#fff' }}>La contraseña debe tener mínimo 1 Mayúscula</p>
            <p style={{ color: '#fff' }}>La contraseña debe tener mínimo 1 carácter especial</p>
        </div>
    );

    useEffect(() => {
        if(window.innerWidth < 535) setSliderWidth('100%');
    }, [window.innerWidth]);

    return (
        <>
            <Drawer
                headerStyle={{ textAlign: 'left' }}
                width={sliderWidth}
                closeIcon={iconclose}
                maskClosable={false}
                title="Editar usuario"
                placement="right"
                onClose={onCloseEdit}
                visible={visibleEdit}
                destroyOnClose
            >

                <div className="container-fluid container-global">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {(formik) => {
                            return (
                                <form className='form-shadows' onSubmit={formik.handleSubmit}>
                                    <div className="container contai-SemiFluid">
                                        <div className='container CreateUserContainer'>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="row row-form-margin">
                                                        <div className="col-sm-12 col-md-6">
                                                            <label className="title-input">Nombre <span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="text"
                                                                name="names"
                                                                className={formik.errors.names && formik.values.names !== undefined ? "form-control form-admin-newUser-Error" : "form-control form-admin-newUser"}
                                                                placeholder="Nombres"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.names}
                                                            />
                                                            {formik.errors.names && formik.values.names !== undefined ? (<div className="form-label Mensaje-newUser-Error">{formik.errors.names}</div>) : null}
                                                        </div>
                                                        <div className="col-sm-12 col-md-6">
                                                            <label className="title-input">Apellido <span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="text"
                                                                name="lastNames"
                                                                className={formik.errors.lastNames && formik.values.lastNames !== undefined ? "form-control form-admin-newUser-Error" : "form-control form-admin-newUser"}
                                                                placeholder="Apellido"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.lastNames}
                                                            />
                                                            {formik.errors.lastNames && formik.values.lastNames !== undefined ? (<div className="form-label Mensaje-newUser-Error">{formik.errors.lastNames}</div>) : null}
                                                        </div>
                                                    </div>
                                                    <div className="row row-form-margin">
                                                        <div className="col-sm-12 col-md-6">
                                                            <label className="title-input">Correo <span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                disabled
                                                                type="text"
                                                                name="email"
                                                                className={formik.errors.email && formik.values.email !== undefined ? "form-control form-admin-newUser-Error" : "form-control form-admin-newUser"}
                                                                placeholder="Correo"
                                                                onChange={e => {
                                                                    formik.handleChange(e)
                                                                }}
                                                                value={formik.values.email || 'ejemplo@ejemplo.com'}
                                                            />
                                                            {formik.errors.email && formik.values.email !== undefined ? (<div className="form-label Mensaje-newUser-Error">{formik.errors.email}</div>) : null}
                                                        </div>
                                                        <div className="col-sm-12 col-md-6">
                                                            <label className="title-input">Dirección <span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="text"
                                                                name="address"
                                                                className={formik.errors.address && formik.values.address !== undefined ? "form-control form-admin-newUser-Error" : "form-control form-admin-newUser"}
                                                                placeholder="Dirección"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.address}
                                                            />
                                                            {formik.errors.address && formik.values.address !== undefined ? (<div className="form-label Mensaje-newUser-Error">{formik.errors.address}</div>) : null}
                                                        </div>
                                                    </div>
                                                    <div className="row row-form-margin">
                                                        <div className="col-sm-12 col-md-6">
                                                            <label className="title-input">Contraseña <span style={{ color: 'red' }}>*</span></label>
                                                            <Popover
                                                                placement='left'
                                                                color='#FF9741'
                                                                content={contentPopoverPassword} >
                                                                <input
                                                                    type="password"
                                                                    name="password"
                                                                    className={formik.errors.password && formik.values.password !== undefined ? "form-control form-admin-newUser-Error" : "form-control form-admin-newUser"}
                                                                    placeholder="Contraseña"
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.password}
                                                                />
                                                            </Popover>
                                                            {formik.errors.password && formik.values.password !== undefined ? (<div className="form-label Mensaje-newUser-Error">{formik.errors.password}</div>) : null}
                                                        </div>
                                                        <div className="col-sm-12 col-md-6">
                                                            <label className="title-input">Repetir contraseña <span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="password"
                                                                name="repeatPassword"
                                                                className={formik.errors.repeatPassword && formik.values.repeatPassword !== undefined ? "form-control form-admin-newUser-Error" : "form-control form-admin-newUser"}
                                                                placeholder="Repetir contraseña"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.repeatPassword}
                                                            />
                                                            {formik.errors.repeatPassword && formik.values.repeatPassword !== undefined ? (<div className="form-label Mensaje-newUser-Error">{formik.errors.repeatPassword}</div>) : null}
                                                        </div>
                                                    </div>
                                                    <div className="row row-form-margin">
                                                        <div className="col-sm-12 col-md-6">
                                                            <label className="title-input">Teléfono <span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="text"
                                                                name="phone"
                                                                className={formik.errors.phone && formik.values.phone !== undefined ? "form-control form-admin-newUser-Error" : "form-control form-admin-newUser"}
                                                                placeholder="Teléfono "
                                                                onChange={formik.handleChange}
                                                                value={formik.values.phone}
                                                            />
                                                            {formik.errors.phone && formik.values.phone !== undefined ? (<div className="form-label Mensaje-newUser-Error">{formik.errors.phone}</div>) : null}
                                                        </div>
                                                        {user.type !== UserModelsEnum.Developer && 
                                                        <div className="col-sm-12 col-md-6">
                                                            <label className="title-input">Tipo <span style={{ color: 'red' }}>*</span></label>
                                                            <div>

                                                                <Select
                                                                    defaultValue={user.type}
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                    onChange={e => { formik.handleChange({ target: { name: "type", value: e } }) }}
                                                                >
                                                                    <Option value="ADMIN">Admin</Option>
                                                                    <Option value="SUDO">SuperAdmin</Option>
                                                                </Select>
                                                            </div>
                                                            {formik.values.type !== undefined && formik.errors.type ? (<div className="form-label Mensaje-newUser-Error">{formik.errors.type}</div>) : null}
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="container">
                                                <div className="row" style={{ marginTop: '2rem' }}>
                                                    <div className="col-6" style={{textAlign:'right'}}>
                                                        <ButtonApp onClick={onCloseEdit} primary cancel content='Salir'/>
                                                    </div>
                                                    <div className="col-6">
                                                        <ButtonApp type='submit' primary content='Guardar' onClick={formik.handleSubmit} disabled={formik.dirty === true ? Object.values(formik.errors).length === 0 ? false : true : true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            )
                        }}
                    </Formik>
                </div>
            </Drawer >
            <div 
            style={{
                width: '100%', 
                zIndex: 1000,
                position: 'fixed',
                display: 'inline-block',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1001,
                background: 'rgba(0, 0, 0, 0.542)',
                }}
            hidden={!showSpinner}>
                <img src={Spinner} />
            </div>
        </>
    )
}