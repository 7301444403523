import React, { useState, useEffect, useContext, useRef} from 'react';
import { Avatar, message, Button, Col, Modal, Rate, Row, Select, Tooltip } from 'antd';
import {Link} from 'react-router-dom';
import Collapse from 'react-bootstrap/Collapse';
import Fade from 'react-bootstrap/Fade';
import { UserOutlined, CloseOutlined } from '@ant-design/icons';
import { useAuth } from '../../../../context/AuthContext';
import UserModelsEnum from '../../../../helpers/enums/UserModelsEnum';
import getProfilePicture from '../../../../services/getProfilePict';
import NumberFormat from "react-number-format";
import './lotsDetails.css'
import Maps from '../CreateLots/Steps/Location/Map';
import { ButtonApp } from '../../../button/button';
import iconChat from '../../../../assets/icons/iconChat.svg'
import {Context} from '../../../../context/lotsContext';
import Request from '../../../../services/Request';
import getLands from '../../../../services/getLands';
import { ChatContext } from '../../../../context/ChatContext';
import { useMemo } from 'react';
import LandFileTypeView from '../../../LandFileTypeView/LandFileTypeView';
import { getStatus } from '../../HomeBroker/TableList/TableList';

const {Option} = Select
const LotsDetails = ({lotsData, handleStateLots, closeIsVisible, setShowEditLots}) => {
  const [logo, setLogo] = useState('');
  const [checked, setChecked] = useState(false);
  const [userName, setUserName] = useState('');
  const bottomRef = useRef();
  const {
    state: { isVisible, currentLots },
    dispatch,
  } = useContext(Context);
  
  const {user, userData: tempUserData} = useAuth();
  const {setCurrentLandRequest, setCurrentLandSelect, 
    createAssociateChat, setBrokerAssociate, setDeveloperAssociate} = useContext(ChatContext);

  const userData = isVisible.toggleState.type === 'land-requests' ? (isVisible.lot && isVisible.lot.data && isVisible.lot.data.user) : tempUserData;
  
  const userInfo = useMemo(() => {
    if(!lotsData.data.associate && !lotsData.data.user) return userData;
    return lotsData.data.user ? lotsData.data.user : lotsData.data.associate[0];
  });


  useEffect(() => {
    if(user && userData && (userData.photo || userData.logo)){
    const currUser = userInfo || userData;
    const id = currUser._id;
    const filename = currUser.logo || currUser.photo;
    const model = currUser.type === UserModelsEnum.Broker ? UserModelsEnum.User : UserModelsEnum.Developer;

    getProfilePicture(id, filename, model).then((response) => {
      if(response) {

        setLogo(response)
        }}
        )
  }
  }, [user, userData, userInfo])
  
  const handleEdit = () =>{
    dispatch({type:'SET_LOT_DATA_EDIT', payload: lotsData.data})
    setShowEditLots(true)
    dispatch({
      type: 'SET_IS_VISIBLE',
      payload:{
        toggleState: {
            ...isVisible.toggleState
        },
        option: false, 
        type: '', 
        lot: isVisible.lot,
      }
    })
  }
  const handleRefused = () =>{
    dispatch({
      type: 'SET_IS_VISIBLE',
      payload:{
        toggleState: {...isVisible.toggleState},
        option: true, 
        type: 'refused', 
        lot: lotsData,
      }
    })
  }

  const handleAccepted = () => {
    dispatch({
      type: 'SET_IS_VISIBLE',
      payload:{
        toggleState: {...isVisible.toggleState},
        option: false, 
        type: 'accepted',
        lot: lotsData,
      }
    })
  }

  const handleDelete = () =>{
    dispatch({
      type: 'SET_IS_VISIBLE',
      payload:{
        toggleState: {...isVisible.toggleState},
        option: true, 
        type: 'delete', 
        lot: lotsData,
      }
    })
  }

  useEffect(() => {
    if(checked && bottomRef.current){
      setTimeout(() => bottomRef.current.scrollIntoView({behavior: 'smooth'}), 500);
    }
  }, [checked]);

  const setChat = async () => {

    if(!lotsData.landRequest && userInfo){
      setBrokerAssociate(userInfo);
      setDeveloperAssociate(userData);
      createAssociateChat();
    } else {
      setCurrentLandSelect(lotsData.data);
      setCurrentLandRequest(lotsData.landRequest);
    }

    closeIsVisible();

  }

  useEffect(() => {
    if(userInfo.type === UserModelsEnum.Broker){
      setUserName(`${userInfo.names.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())} ${userInfo.lastNames.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())}`);
    } else {
      if(userData.names) setUserName(`${userData.names.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())} ${userData.lastNames.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())}`);
    }
  }, [userInfo]);

  const disabledActions = 
    (lotsData.offerState !== 'IN_ANALYSIS')
    || lotsData.lotRequestState === 'CANCELLED' 
    || lotsData.lotRequestState === 'CLOSED' 
    || lotsData.lotRequestState === 'PAUSED';
  
  return (
    <div className='container-details'>
      <Row className='rows' style={{alignItems: 'end', marginBottom: '10px'}}>
        <span className='subtitle1'>Ficha del Lote</span>
        <span className='subtitle2'>{lotsData.data.registrationNumber}</span>
      </Row>
      <Row className='rows' style={{marginBottom: '20px'}}>
        <Col xs={24} sm={14} >
          <div style={{display:'flex'}}>
            <Avatar icon={<UserOutlined />} src={logo} size={90}/>
            <div className='rate-and-name'>
              <Rate disabled defaultValue={0} style={{color:'#2a97d1'}}/>
              {userData && <span className='subtitle1'>{userName}</span>}
            </div>
          </div>
        </Col>
        <Col xs={24} sm={10}>
        <div className='container-contact'>
          <Button 
            type="link" 
            icon={<img src={iconChat} style={{width: '30px'}}/>}
            className='button-row'                                   
          />
          <a onClick={setChat} className='contact'>Contactar</a> 
        </div>
        </Col>
      </Row>
      <Row className='rows row2' style={{marginBottom: '0px'}}>
        <Col className='col' xs={{ span: 24}} md={{ span: 8}}>
          <h6>Área del lote (m²)</h6>
          <NumberFormat 
                    type='text' 
                    value={lotsData.data.area} 
                    thousandSeparator={'.'} 
                    decimalSeparator={','} 
                    prefix={''}
                    disabled
                    style={{border: 'none', marginLeft: '10px', background: '#fff'}}
                  />
        </Col>
        <Col className='col' xs={{ span: 24}} md={{ span: 7, offset: 1 }}>
          <h6>Uso del lote (Tratamiento)</h6>
          <span>{lotsData.data.treatment}</span>
        </Col>
        <Col className='col' xs={{ span: 24}} md={{ span: 7, offset: 1 }}>
          <h6>Departamento</h6>
          <span>{lotsData.data.location.department}</span>
        </Col>
        <Col className='col' xs={{ span: 24}} md={{ span: 8}}>
          <h6>Precio del lote por m² ($COP)</h6>
          <NumberFormat 
                    type='text' 
                    value={lotsData.data.metersPrice} 
                    thousandSeparator={'.'} 
                    decimalSeparator={','} 
                    prefix={'$'}
                    disabled
                    style={{border: 'none', marginLeft: '10px', background: '#fff'}}
                  />
        </Col>
        <Col className='col' xs={{ span: 24}} md={{ span: 7, offset: 1 }}>
          <h6>Uso del lote (zona)</h6>
          <span>{lotsData.data.zone}</span>
        </Col>
        <Col className='col' xs={{ span: 24}} md={{ span: 7, offset: 1 }}>
          <h6>Ciudad</h6>
          <span>{lotsData.data.location.city}</span>
        </Col>
        <Col className='col' xs={{ span: 24}} md={{ span: 8}}>
          <h6>Precio total del lote ($COP)</h6>
          <NumberFormat 
                    type='text' 
                    value={lotsData.data.metersPrice * lotsData.data.area} 
                    thousandSeparator={'.'} 
                    decimalSeparator={','} 
                    prefix={'$'}
                    disabled
                    style={{border: 'none', marginLeft: '10px', background: '#fff'}}
                  />
        </Col>
        <Col className='col' xs={{ span: 24}} md={{ span: 7, offset: 1 }}>
          <h6>Tipo de negocio</h6>
          <span>{lotsData.data.businessType}</span>
        </Col>
        <Col className='col' xs={{ span: 24}} md={{ span: 7, offset: 1 }}>
          <h6>Zona</h6>
          <span>{lotsData.data.location.location}</span>
        </Col>
      </Row>
      {lotsData.data.observations && <Row className='rows' style={{marginBottom: '0px'}}>
        <Col className='col' xs={24}>
          <h6>Observaciones</h6>
          <span>{lotsData.data.observations}</span>
        </Col>
      </Row>}

      {lotsData.showStatus && <Col className='col' xs={24}>
          <h6>Estado Actual</h6>
          <span style={{border: 'none'}}>{ getStatus(lotsData.offerState) }</span>
      </Col>}
        
      {lotsData.data.escriturasDoc && <LandFileTypeView land={lotsData.data} />}

      <Row className='rows' style={{marginBottom: '0px'}}>
          {/* <span onClick={() => setOpen(!open)} className='collapse-title'>Ver ubicacion en mapa</span> */}
          <div className="container-collapse">
            <div className="accordions">
              <div className="accordion">
                <input type="checkbox" onChange={(e)=> {setChecked(e.target.checked)}} id="first" />
                <label htmlFor="first" className="acc-label">
                  Ver ubicación en mapa
                  <div className='bullet-point'></div>
                </label>
                <div className="acc-content">
                  <Maps marker={[lotsData.data.map.latitude, lotsData.data.map.longitude]} zoom={13} isVisible={checked}/>
                </div>
              </div>
            </div>
          </div>
      </Row>

      <div ref={bottomRef}>

      {isVisible.toggleState.type === 'land-requests' &&
        <Row className='rows'>
        <div className='button-group-lot'>
          <span className='button-lot-delete d-flex gap-3'>
            <ButtonApp disabled={disabledActions} content='RECHAZAR LOTE' onClick={handleRefused} primary cancel/>
            <ButtonApp disabled={disabledActions} content='ACEPTAR LOTE' onClick={handleAccepted} primary/>
          </span>
        </div>
      </Row>}
      {isVisible.toggleState.type === 'lands' && handleStateLots !== undefined &&
        <Row className='rows'>
        <div className='button-group-lot'>
          <span className='button-lot-delete'>
            <ButtonApp disabled={lotsData.data.state === 'SOLD_OUT' || lotsData.data.state === 'RETIRED'|| lotsData.data.state === 'BOUGHT'} content='ELIMINAR LOTE' onClick={handleDelete} primary cancel/>
          </span>
          <span className='button-lot-edit'>
            <ButtonApp disabled={lotsData.data.state === 'SOLD_OUT' || lotsData.data.state === 'RETIRED'|| lotsData.data.state === 'BOUGHT'} content='EDITAR LOTE' onClick={handleEdit} primary/>
          </span>
        </div>
      </Row>}

      </div>
    </div>
  )
}

export default LotsDetails;