import React, { useEffect, useRef, useState } from 'react';
import { Empty, Tooltip } from 'antd';
import { QuestionCircleOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import './index.css'
import { useHistory } from 'react-router-dom';
import useFetchFaqs from '../../hooks/useFetchFaqs';

export default function Faq({show, onClose}) {

  const [faqs, setFaqs] = useState([]);

  const {loading, response, lastPage, filter, setFilter} = useFetchFaqs();
  //const [selectedFaq, setSelectedFaq] = useState("");

  const history = useHistory();

  const listInnerRef = useRef();



  const toggleSelectedFaq = (faq) => {
    /*if(selectedFaq === faq._id) setSelectedFaq('');
    else setSelectedFaq(faq._id);*/
    const queryParams = new URLSearchParams();
    queryParams.set('page', filter.page);
    queryParams.set('question', faq._id);
    history.push('/app/faq?'+queryParams.toString());
    onClose();
  }

  const onScroll = () => {
    if (listInnerRef.current) {
    const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if(!lastPage){
          setFilter({...filter, page: filter.page + 1});
        }
      }
    }
  };

  useEffect(() => {
    setFaqs(prev => {
      if(prev && prev.length > 0) return [...prev, ...response.content]
      else return response.content
    });
  }, [response]);
  
  return (
    <>
      <div className={`profile-menu faq-container ${!show ? 'hidden' : ''}`}>

        <div className="d-flex justify-content-end">
          <Tooltip title="Cerrar">
            <CloseOutlined onClick={onClose} style={{ fontSise: '40px', color: '#39C0ED', cursor: 'pointer' }}/>
          </Tooltip>
        </div>

        <h6 className="d-flex justify-content-center align-items-center mb-4" style={{ color: '#000' }}>
          <QuestionCircleOutlined style={{marginRight: '.25rem'}} title="Preguntas Frecuentes" />
          FAQ
        </h6>

        <div 
        className="questions"
        onScroll={onScroll}
        ref={listInnerRef}>

          {faqs && faqs.length > 0 && faqs.map(faq => {
          
          return (<div key={faq._id} className="question">
            <span 
            className="question-title d-flex align-items-center"
            onClick={() => toggleSelectedFaq(faq)}>
              <QuestionCircleOutlined style={{marginRight: '.4rem'}} />
              <p>{faq.question}</p>
            </span>

            {/*selectedFaq === faq._id ? <p className='answer'>
              {faq.answer}
          </p> : null*/}
          </div>)})

          }

        {!loading && faqs.length === 0 && (
          <Empty 
          description={
          <span>
              No se encontraron datos
          </span>}
          image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}

        {loading && (
          <div className="d-flex justify-content-center">
            <LoadingOutlined style={{color: '#000'}} />
          </div>
        )}

        </div>
        
      </div>
    </>
  )
}
