import React, {useContext} from 'react';
import NumberFormat from "react-number-format";
import {Divider} from 'antd';

import RequestLandButton from '../RequestLandButton/RequestLandButton';
import { Context } from '../../../context/lotsContext';

function LandItem({item, structure, selectedLand, setSelectedLand}) {

    const {dispatch} = useContext(Context);

    const handleModal = (type, lot) => {
        dispatch({
          type: 'SET_IS_VISIBLE',
          payload:{
            toggleState: {
                type: "lands", 
                label: "Mis lotes",
            },
            option: true, 
            type,
            lot,
          }
        });
      };

    return (
        <React.Fragment key={item.registrationNumber}>
            <tr className='table-row-body-broker' onClick={() => handleModal('details', item)}>
                <td className='col-listUser-adminPanel col1' style={item.state && item.state === 'ON_SALE' ? {color: '#21B608'} : {color: '#757575'}} scope="row">{item.registrationNumber && item.registrationNumber}</td>
                {structure[1].item !== '!' && <td className='col-listUser-adminPanel col2' scope="row"><span className={item.state && item.state === 'ON_SALE' ? 't-badge-on-sale' : 't-badge-sold-out'}>{item.state && item.state === 'ON_SALE' ? 'En venta' : 'Vendido'}</span></td>}
                <td className='col-listUser-adminPanel col3' scope="row">{item.location && item.location.city}</td>
                <td className='col-listUser-adminPanel col4' scope="row">{item.location && item.location.neighborhood}</td>
                <td className='col-listUser-adminPanel col5' scope="row"><NumberFormat value={item.area} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}  /></td>
                {structure[5].item !== '!' &&<td className='col-listUser-adminPanel col6' scope="row"><NumberFormat value={item.price} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} prefix={'$ '} /></td>}
                <td style={{textAlign: "end"}} className='col-listUser-adminPanel col7' scope="row">
                    <RequestLandButton selectedLand={selectedLand} setSelectedLand={setSelectedLand} item={item} />
                </td>
            </tr>

            <tr>
                <td colSpan="7">
                    <Divider style={{margin: '4px', fontSize: '2rem'}}/>
                </td>
            </tr>
        </React.Fragment>
    )
}

export default LandItem
